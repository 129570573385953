import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonSelect, IonText, IonSelectOption, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
// import './SearchKeywordManage.css'
import './Admin.scss';
import {log, LogLevel} from '../utils/LogUtil';
import {getGlobal, GlobalKey} from '../utils/GlobalUtil';
import { BoardType } from '../store/board/types';
import { BoardContent, Attachment } from '../models/Model.Board';
import SeminarSelect from './component/seminar/SeminarSelect';
import { SeminarLecture } from '../models/Model.Seminar';
import { EMPTY_SEARCH_KEYWORD, SearchKeywordInfo } from '../models/Model.Search';
import SearchKeywordSelect from './component/search/SearchKeywordSelect';
import SearchKeywordEdit from './component/search/SearchKeywordEdit';
import { UIPopupType } from '../store/ui/types';

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  selected: SearchKeywordInfo;
}

class SearchKeywordManage extends Component<Props, State> {

  state = {
    selected: null, 
  };
  selectedlist = null;
  selectedEditor = null;

  onDone = (selected:SearchKeywordInfo) => {
    this.setState({selected:null});
    this.selectedlist.refresh();
  }

  onSelect = (selected:SearchKeywordInfo) => {
    if(!selected)
      selected = null;
    this.setState({selected});
  }

  render() {
    // log(LogLevel.UI_LIFECYCLE, "SearchKeywordManage.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                배너 관리
            </IonText>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">

            <div className="admin-container">
              <SearchKeywordSelect ref={(ref) => this.selectedlist = ref} onSelect={this.onSelect} progressPopup={this.props.progressPopup} />
            </div>
            <div id="admin-sub-conainer" className="admin-container">
              <SearchKeywordEdit ref={(ref) => this.selectedEditor = ref} selected={this.state.selected} onDone={this.onDone} progressPopup={this.props.progressPopup} toastPopup={this.props.toastPopup} filePath={this.props.filePath}/>
            </div>
          </div>


        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  filePath: state.board.filePath,
  progressPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchKeywordManage));