import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IonSpinner } from '@ionic/react'
import { SeminarLecture } from '../../../../models/Model.Seminar';
import { Attachment, BoardContent } from '../../../../models/Model.Board';
import Header from '../../../molecule/Header';
import Body from '../../../molecule/Body';
import Scroll from '../../../molecule/Scroll';
import '../../../../Common.css';
import { SeminarListState } from '../../../../store/seminar/types';
import SeminarListItem from '../../../organism/Seminar/SeminarListItem';


interface Props {
  seminarListState: SeminarListState,
  lectures: any,
  onSelectSeminar?: (seminar:SeminarLecture) => void
  onGoBack?: () => void
  onLoadMore?: () => void
}
const SeminarMyList: FC<Props> = ({
  seminarListState,
  lectures,
  onSelectSeminar,
  onGoBack,
  onLoadMore,
}) => {
  const scrollEl = useRef<Scroll>(null);

  const onBack = () => {
    scrollEl.current.clearAutoScroll();
    if(onGoBack)
      onGoBack();
  }

  return (
    <>
      <Header needBack={true} goBack={onBack} center={false} />
      <Body scrollable={false}>
        <Scroll ref={scrollEl} autoScrollName={"SeminarListView"} onLoadMore={onLoadMore}>
          {
            (seminarListState && seminarListState.lectures && seminarListState.lectures.length) &&
            seminarListState.lectures.map((lecture, index) => {
              return (
                <SeminarListItem key={index.toString()} seminar={lectures[lecture.id]} onClick={onSelectSeminar}/>
              );
            })
          }
          <div className="common-bottom-space"/>
        </Scroll>
      </Body>
    </>
  )
}

const Container: StyledComponent<'div', FC> = styled.div`
  
`

export default SeminarMyList
