import {BannerInfo} from './../../models/Model.Banner'

export interface BannerState {
  banners: BannerInfo[];
}

export enum BannerActionTypes{
  BANNERS_FETCH = '@@event/BANNERS_FETCH',
  BANNERS_REQUEST = '@@event/BANNERS_REQUEST',
  BANNERS_SUCCESS = '@@event/BANNERS_SUCCESS',
  BANNERS_ERROR = '@@event/BANNERS_ERROR',
}
