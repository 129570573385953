import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import styled, { StyledComponent } from "styled-components";
import { IonSpinner } from "@ionic/react";
import { SeminarLecture, SeminarLectureType } from "../../../models/Model.Seminar";
import { Attachment } from "../../../models/Model.Board";
import Header from "../../molecule/Header";
import Body from "../../molecule/Body";
import StringUtil from "../../../utils/StringUtil";
import Scroll from "../../molecule/Scroll";
import { log, LogLevel } from "../../../utils/LogUtil";
import { ScrollAction } from "../../molecule/Scroll";
import Video from "../../atom/Video";
import VideoController from "../../cell/VideoController";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import IconButton from "../../atom/IconButton";
import Footer from "../../molecule/Footer";
import TextButton from "../../atom/TextButton";
import Badge from "../../atom/Badge";
import Text from "../../atom/Text";
import Icon from "../../atom/Icon";
import { extractTimeToJTag, extractTimeValue, getTimerString } from "../../../utils/TimeUtil";
import color from "../../_design/color";
import StarScore from "../../atom/StarScore";
import { TabBar } from "../../cell/TabBar";
import Spinner from "../../atom/Spinner";
import Avatar from "../../atom/Avatar";
import ReactHtmlParser from "react-html-parser";
import "./style.scss";
import ComponentGenerator from "../../../ivcomponent/ComponentGenerator";

interface Props {
  seminar: SeminarLecture;
  onVideoJumpTo?: (time: number) => void;
  onSelectAttachment?: (attachment: Attachment) => void;
}
const SeminarViewDetail: FC<Props> = ({ seminar, onVideoJumpTo, onSelectAttachment }) => {
  log(LogLevel.UI_LIFECYCLE, "SeminarViewDetail:", seminar);

  if (!seminar || (!seminar.id && seminar.loading)) return <Spinner />;

  const transform = (node, index) => {
    if (node.type == "tag" && node.name == "j") {
      let text = node.children[0].data;
      return (
        <Text
          key={index.toString()}
          color="Blue"
          onClick={(e) => {
            onVideoJumpTo(extractTimeValue(text));
          }}
        >
          {text}
        </Text>
      );
    } else if (node.type == "tag" && node.name == "span" && node.attribs.class && node.attribs.class == "components") {
      return ComponentGenerator.generate(node.attribs);
    }
    log(LogLevel.UI_EXCEPTION, "ViewerBody:transform: unknown", node);
    return undefined;
  };

  const [foldPaper, setFoldPaper] = useState<boolean>(true);
  const [foldPresentation, setFoldPresentation] = useState<boolean>(true);

  return (
    <>
      <div className="common-block-line" style={{ marginTop: "20px" }}>
        <Text type="Body1" color="Gray1">
          {ReactHtmlParser(extractTimeToJTag(seminar.description), { transform: transform })}
        </Text>
      </div>
      {seminar.type == SeminarLectureType.SEMINAR && seminar.ingredients && seminar.ingredients.length > 0 && (
        <div className="common-block-line" style={{ marginTop: "60px" }}>
          <div style={{ marginBottom: "12px" }}>
            <Text type="Headline" color="Secondary">
              성분
            </Text>
          </div>
          {seminar.ingredients.map((ingredient, index) => {
            return (
              <div key={index.toString()} style={{ marginBottom: "4px" }}>
                <Text type="Body1" color="Gray1">
                  ▪︎ {ingredient.textKor}({ingredient.textEng}){ingredient.volume ? ":" + ingredient.volume : ""}
                </Text>
              </div>
            );
          })}
        </div>
      )}
      {seminar.type == SeminarLectureType.SEMINAR && seminar.effect && (
        <div className="common-block-line" style={{ marginTop: "60px" }}>
          <div style={{ marginBottom: "12px" }}>
            <Text type="Headline" color="Secondary">
              효능·효과
            </Text>
          </div>
          <Text type="Body1" color="Gray1">
            {ReactHtmlParser(seminar.effect, {})}
          </Text>
        </div>
      )}

      {seminar.lecturers && seminar.lecturers.length > 0 && (
        <div className="common-block-line" style={{ marginTop: "60px" }}>
          <div style={{ marginBottom: "12px" }}>
            <Text type="Headline" color="Secondary">
              {seminar.type == SeminarLectureType.SEMINAR ? "강사" : "작성자"}
            </Text>
          </div>
          {seminar.lecturers.map((lecturer, index) => {
            return (
              <div className="common-block-sub" key={index.toString()} style={{ marginBottom: "12px" }}>
                <div style={{ marginBottom: "8px" }}>
                  <Text type="Headline" color="Secondary">
                    {lecturer.name}
                  </Text>
                </div>
                {(lecturer.career && lecturer.career.length>0) && lecturer.career.map((career, index2) => {
                  return (
                    <div key={index2.toString()} style={{ marginBottom: "4px" }}>
                      <Text type="Body2" color="Gray2">
                        {career}
                      </Text>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      {((seminar.references && seminar.references.length > 0) || (seminar.materials && seminar.materials.length)) && (
          <div className="common-block-line" style={{ marginTop: "60px" }}>
            <div style={{ marginBottom: "12px" }}>
              <Text type="Headline" color="Secondary">
                관련자료
              </Text>
            </div>
            {(seminar.references && seminar.references.length > 0) &&
              <div className="common-block-sub" style={{ marginBottom: "12px" }}>
                <div className="common-container-row common-flex-align-center" onClick={() => setFoldPaper(!foldPaper)}>
                  <div className="common-flex-grow">
                    <Text type="SubHead" color="Gray1">
                      관련 논문 ({seminar.references ? seminar.references.length : 0})
                    </Text>
                  </div>
                  <Icon width={22} height={22} name={foldPaper ? "CaretDown" : "CaretUp"} fill={color.Gray1} />
                </div>
                {!foldPaper &&
                  seminar.references &&
                  seminar.references.map((reference, index) => {
                    return (
                      <div
                        key={index.toString()}
                        className="common-container-row seminar-text-index"
                        style={{ marginTop: "15px" }}
                        onClick={(e) => {
                          if (onSelectAttachment) onSelectAttachment(reference);
                        }}
                      >
                        <Text type="Body2" color="Gray2">
                          ▪︎ <Text className="seminar-text-underline">{reference.name}</Text>
                        </Text>
                      </div>
                    );
                  })}
              </div>
            }
            {(seminar.materials && seminar.materials.length) &&
              <div className="common-block-sub">
                <div
                  className="common-container-row common-flex-align-center"
                  onClick={() => setFoldPresentation(!foldPresentation)}
                >
                  <div className="common-flex-grow">
                    <Text type="SubHead" color="Gray1">
                      세미나 자료 ({seminar.materials ? seminar.materials.length : 0})
                    </Text>
                  </div>
                  <Icon width={22} height={22} name={foldPresentation ? "CaretDown" : "CaretUp"} fill={color.Gray1} />
                </div>
                {!foldPresentation &&
                  seminar.materials &&
                  seminar.materials.map((reference, index) => {
                    return (
                      <div
                        key={index.toString()}
                        className="common-container-row seminar-text-index"
                        style={{ marginTop: "15px" }}
                        onClick={(e) => {
                          if (onSelectAttachment) onSelectAttachment(reference);
                        }}
                      >
                        <Text type="Body2" color="Gray2">
                          ▪︎ <Text className="seminar-text-underline">{reference.name}</Text>
                        </Text>
                      </div>
                    );
                  })}
              </div>
            }
          </div>
        )}
    </>
  );
};

export default React.memo(SeminarViewDetail, (props, nextProps) => {
  if (!props.seminar) {
    if (nextProps.seminar) return false;
  } else {
    if (props.seminar.id != nextProps.seminar.id || props.onVideoJumpTo != nextProps.onVideoJumpTo) return false;
  }
  return true;
});
