import { BoardContent, Attachment } from "../../../models/Model.Board";
import { LogLevel, log } from "../../../utils/LogUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import * as API from "../../../API.json";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
// import reactElementToJSXString from 'react-element-to-jsx-string';
import { ProductInfo } from "../../../models/Model.Sale";
import BoardUtil from "../../../utils/BoardUtil";
import Textarea from "react-textarea-autosize";

const ADMIN_API = {
  PRODUCT_DELETE: {
    method: "delete",
    path: "/admin/sale/product",
    contentType: "application/json",
  },
  PRODUCT_POST: {
    method: "post",
    path: "/admin/sale/product",
    contentType: "application/json",
  },
  PRODUCT_PUT: {
    method: "put",
    path: "/admin/sale/product",
    contentType: "application/json",
  },
};

export default class ProductUtil {
  static async fetchReplaceProduct(
    product: ProductInfo,
    waitPopup: any
  ): Promise<ProductInfo> {
    if (
      !product.content ||
      !product.content.cards ||
      !product.content.cards.length
    )
      throw new Error(
        `Product content is empty content(${!!product.content}), cards(${!!product
          .content.cards}), cards.length(${
          product.content.cards && product.content.cards.length
        })`
      );

    let token = getGlobal(GlobalKey.TOKEN);

    if (product.content) {
      let content = product.content;
      product.content = null;

      ///### step1: AddContent -----------------------------------------------------
      if (!content.subject) content.subject = product.name;
      else product.name = content.subject;

      if (!content.id) {
        content = await BoardUtil.fetchAddContentHtml(content, waitPopup);
      } else {
        content = await BoardUtil.fetchUpdateContentHtml(content, waitPopup);
      }

      if (waitPopup) waitPopup.setLabel("글 작성 중...");

      if (!content) return null;

      product.contentId = content.id;
      product.image = content.cards[0].url;

      ///### step4: Add Product -----------------------------------------------------
      if (!product.id) {
        let result = await fetchAPI(
          ADMIN_API.PRODUCT_POST,
          "",
          null,
          product,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error) {
          product.id = result.data;
          product.content = content;
        } else return null;
      } else {
        let result = await fetchAPI(
          ADMIN_API.PRODUCT_PUT,
          "",
          null,
          product,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error) {
          product.content = content;
        } else return null;
      }

      return product;
    }
  }
}
