export class JsonParser {
  fullJsonString = "";
  currentState = "START";
  stack = [];
  currentObject: any = {};
  rootObject: any = this.currentObject;
  currentKey: any = "";
  currentValue = "";
  isString = false;

  reset() {
    this.fullJsonString = "";
    this.currentState = "START";
    this.stack = [];
    this.currentObject = {};
    this.rootObject = this.currentObject;
    this.currentKey = "";
    this.currentValue = "";
    this.isString = false;
  }

  parses(jsonString) {
    for (let i = 0; i < jsonString.length; i++) {
      this.parse(jsonString.charAt(i));
    }
  }

  parse(character) {
    this.fullJsonString += character;
    if (this.isString && character !== '"') {
      this._handleString(character);
    } else {
      switch (character) {
        case "{":
          this._handleOpenBrace();
          break;
        case "}":
          this._handleCloseBrace();
          break;
        case "[":
          this._handleOpenBracket();
          break;
        case "]":
          this._handleCloseBracket();
          break;
        case '"':
          this._handleQuote();
          break;
        case ":":
          this._handleColon();
          break;
        case ",":
          this._handleComma();
          break;
        default:
          this._handleDefault(character);
      }
    }

    console.log(
      `Current state:${character}, ${this.currentKey}, ${this.currentValue}  , ${this.currentState}, ${this.isString}`
    );
  }

  _handleOpenBrace() {
    if (this.currentState === "VALUE" || this.currentState === "ARRAY") {
      this.stack.push(this.currentObject);
      this.currentObject[this.currentKey] = {};
      this.stack.push(this.currentKey);
      this.currentObject = this.currentObject[this.currentKey];
    } else if (this.currentState !== "START") {
      console.log("_handleOpenBrace() - Invalid state", this.currentState);
      this.stack.push(this.currentObject);
      this.currentObject = {};
    }
    this.currentState = "OBJECT";
  }

  _handleCloseBrace() {
    if (this.currentValue.trim() !== "") {
      this.currentObject[this.currentKey] = parseInt(this.currentValue);
      this.currentValue = "";
    }

    if (this.stack.length > 0) {
      const lastKey = this.stack.pop();
      const lastObject = this.stack.pop();
      lastObject[lastKey] = this.currentObject;
      this.currentObject = lastObject;
      if (Array.isArray(this.currentObject)) {
        this.currentState = "ARRAY";
        this.currentKey = lastKey;
      } else {
        this.currentState = "KEY";
        this.currentKey = "";
      }
    } else {
      this.currentState = "FINISH";
    }
  }

  _handleOpenBracket() {
    if (this.currentState === "VALUE") {
      this.stack.push(this.currentObject);
      this.currentObject[this.currentKey] = [];
      this.stack.push(this.currentKey);
      this.currentObject = this.currentObject[this.currentKey];
      this.currentKey = 0;
    }
    this.currentState = "ARRAY";
  }

  _handleCloseBracket() {
    if (this.currentState === "ARRAY") {
      if (this.currentValue.trim() !== "") {
        this.currentObject[this.currentKey] = parseInt(this.currentValue);
        this.currentValue = "";
      }
    }
    if (this.stack.length > 0) {
      const lastKey = this.stack.pop();
      const lastObject = this.stack.pop();
      lastObject[lastKey] = this.currentObject;
      this.currentObject = lastObject;
      this.currentState = "KEY";
      this.currentKey = "";
    } else {
      this.currentState = "OBJECT";
    }
  }

  _handleQuote() {
    if (this.isString) {
      if (this.currentState === "KEY") {
        // this.currentKey = this.currentValue;
        // console.log(`Extracted key: ${this.currentKey}`);
        this.currentState = "COLON";
      } else if (this.currentState === "VALUE") {
        // console.log(`Extracted value: ${this.currentValue}`);
        // this.addValue();
        this.currentState = "COMMA";
      }
      this.currentValue = "";
      this.isString = false;
    } else {
      this.isString = true;
      if (this.currentState === "OBJECT" || this.currentState === "COMMA") {
        this.currentState = "KEY";
        this.currentKey = "";
      } else if (
        this.currentState === "COLON" ||
        this.currentState === "ARRAY"
      ) {
        this.currentState = "VALUE";
      }
    }
  }

  _handleColon() {
    if (this.currentState === "COLON") {
      this.currentState = "VALUE";
    }
  }

  _handleComma() {
    if (this.currentState === "ARRAY") {
      if (this.currentValue.trim() !== "") {
        this.currentObject[this.currentKey] = parseInt(this.currentValue);
        this.currentValue = "";
      }
      this.currentKey++;
    } else if (this.currentState === "COMMA") {
      if (Array.isArray(this.currentObject)) {
        this.currentState = "ARRAY";
        this.currentKey++;
      } else {
        this.currentState = "OBJECT";
      }
    } else if (this.currentState === "VALUE" && !this.isString) {
      this.currentObject[this.currentKey] = parseInt(this.currentValue);
      this.currentValue = "";

      if (Array.isArray(this.currentObject)) {
        this.currentState = "ARRAY";
        this.currentKey++;
      } else {
        this.currentState = "OBJECT";
      }
    }
  }

  _handleDefault(character) {
    if (
      this.currentState === "VALUE" ||
      this.currentState === "ARRAY" ||
      this.isString
    ) {
      this.currentValue += character;
    }
  }

  _handleString(character) {
    if (this.currentState === "VALUE") {
      this.currentValue += character;
      if (!this.currentObject[this.currentKey]) {
        this.currentObject[this.currentKey] = "";
      }
      this.currentObject[this.currentKey] += character;
    } else if (this.currentState === "KEY") {
      this.currentKey += character;
    }
  }

  // _addValue() {
  //   if (this.currentState === "VALUE") {
  //     if (Array.isArray(this.currentObject)) {
  //       this.currentObject.push(this.currentValue);
  //     } else {
  //       this.currentObject[this.currentKey] = this.currentValue;
  //     }
  //     this.currentValue = "";
  //   }
  // }

  getJsonObject() {
    return this.rootObject;
  }
}
