import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../store";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "../../API.json";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import JobPostSettingTemplate from "../../components/templates/JobPostSetting";
import { UserInfo, UserWorkType, UserWorkTypeName } from "../../models/Model.User";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorCode } from "../../models/ErrorCode";
import { log, LogLevel } from "../../utils/LogUtil";
import { JobListType } from "../../store/jobpost/types";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobOfferType } from "../../models/Model.JobPost";
import { login, setToken } from "../../store/user/actions";

const JobPostSetting: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [searchedAddress, setSearchedAddress] = useState([]);
  const [searchedPharmacy, setSearchedPharmacy] = useState([]);
  const me = useSelector((state: RootState) => state.user.me);
  const dispatch = useDispatch();
  const { mode: initMode } = location.state || "home";

  // 주소 검색
  const searchAddress = (address: string) => {
    fetchAPI(API.USER_SEARCH_ADDRESS, "", { query: address }, null)
      .then((result) => {
        const { error } = result;

        if (error === ErrorCode.NO_ERROR) {
          const { data } = result;
          setSearchedAddress(data);
        } else {
          setSearchedAddress([]);
        }
      })
      .catch((err) => console.error(err));
  };

  // 약국 검색
  const searchPharmacy = async (pharmacy: string) => {
    let result = await fetchAPI(API.USER_SEARCH_PHARMACY, "", { query: pharmacy }, null);
    log(LogLevel.UI_DATA_LOAD, "SearchAddress:onPharmacySearchTextChange", result);
    if (!result.error) setSearchedPharmacy(result.data);
    else setSearchedPharmacy([]);
  };

  // 뒤로가기
  const goBack = () => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_UPDATE_SETTING", "구인구직_설정_취소");
    history.goBack();
  };

  // 구인구직 설정
  const updateOfferSetting = async (user: UserInfo, jobPostAlarm: boolean) => {
    try {
      const requestBodyData = {
        id: me.id,
        ...user,
      };
      const response = await fetchAPI(API.USER_ME_UPDATE, "", null, requestBodyData, getGlobal(GlobalKey.TOKEN));

      if (!response.error) {
        dispatch(actions.user.setMe(requestBodyData));
        dispatch(actions.user.login());

        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_UPDATE_SETTING", "구인구직_설정_완료", {
          userId: me.id,
          workType: UserWorkTypeName[user.workType],
          type: JobOfferType[user.jobpostStatus],
          alarm: user.workType !== UserWorkType.PHARMACY_OWNER && jobPostAlarm,
        });
      }
    } catch (err) {
      console.error(err);
      // log(LogLevel.UI_EXCEPTION, "LocalDealJobPostOfferComposer:exception: ", e);
    }
  };

  useEffect(() => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_CREATE", "구인구직_설정_진입", {});
  }, []);

  return (
    <JobPostSettingTemplate
      me={me}
      initMode={initMode}
      searchedAddress={searchedAddress}
      searchedPharmacy={searchedPharmacy}
      onSearchAddress={searchAddress}
      onSearchPharmacy={searchPharmacy}
      onUpdateOfferSetting={updateOfferSetting}
      onGoBack={goBack}
    />
  );
};

export default withRouter(JobPostSetting);
