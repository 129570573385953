import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
// import 'style.css';
import '../../Common.css';
import * as API from '../../API.json';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import {log, LogLevel} from '../../utils/LogUtil'
import {CONTRACT_VERSION} from '../../config.json'

import {getGlobal, GlobalKey, clearGlobal, setGlobal} from '../../utils/GlobalUtil'
import { UIPopupType, UIServiceType } from '../../store/ui/types';
import AnalyticsUtil from '../../utils/AnalyticsUtil';
import { SeminarLecture } from '../../models/Model.Seminar';
import SeminarList from '../../component/template/Seminar/SeminarList';
import { BannerInfo } from '../../models/Model.Banner';
import UserContractUpdate from '../../component/template/UserContractUpdate';
import UserPermanent from '../../component/template/UserPermanent';
import InformationViewerPopup from '../../component/template/InformationViewPopup';
import InformationContract from '../../component/organism/Information/InformationContract';
import InformationPrivacy from '../../component/organism/Information/InformationPrivacy';
import { UserInfo, UserPermanentType } from '../../models/Model.User';
import { fetchAPI } from '../../utils/APIUtil';
import InformationMarketing from '../../component/organism/Information/InformationMarketing';

const queryString = require('query-string');
const windowAny: any = window;

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
}

type State = {
  marketing: boolean
  permanent: boolean
  step: number
}

class UserContractUpdateSign extends Component<Props, State> {
  target = "/";
  popup = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      marketing: false,
      permanent: false,
      step: 0,
    }

    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "CONTRACT_UPDATE", '서비스_약관_재동의');
    if(this.props.location.state && this.props.location.state.from){
      this.target = this.props.location.state.from;
      if(this.props.location.state.search)
      this.target += this.props.location.state.search;
    }
    log(LogLevel.UI_LIFECYCLE, "UserContractUpdateSign:constructor", this.props.location, this.target);

  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "UserContractUpdateSign:componentDidMount");
  }
          
  componentDidUpdate(prevProps, prevState) {
  }
  
  componentWillUnmount(){
    log(LogLevel.UI_LIFECYCLE, "UserContractUpdateSign:componentWillUnmount");
  }

  onContractUpdateDone = async (marketing: boolean) => {
    log(LogLevel.UI_EVENT, "UserContractUpdateSign:onContractUpdateDone", marketing);
    this.setState({marketing});
    let newMe:UserInfo = {contractVersion:CONTRACT_VERSION}
    if(marketing){
      newMe.agreeMarketingAt = 1;
    }
    await fetchAPI(API.USER_ME_UPDATE, '', null, newMe, getGlobal(GlobalKey.TOKEN));

    let hasNext:boolean = !this.props.me.permenent;
    if(hasNext)
      this.setState({step:1});
    else{
      this.goBack();
    }
  }

  onClickContractDetail = () => {
    if(this.popup)
      this.popup.show(InformationContract);
  }

  onClickPrivacyDetail = () => {
    if(this.popup)
      this.popup.show(InformationPrivacy);
  }

  onClickMarketingDetail = () => {
    if(this.popup)
      this.popup.show(InformationMarketing);
  }

  onPermanentDone = async (permanent: boolean) => {
    let newMe:UserInfo = {
      permenent: permanent?UserPermanentType.PERMANENT:UserPermanentType.NORMAL,
    }
    this.setState({permanent});

    await fetchAPI(API.USER_ME_UPDATE, '', null, newMe, getGlobal(GlobalKey.TOKEN));

    this.goBack();
  }

  goBack = () => {
    let newMe:UserInfo = {
      permenent: this.state.permanent?UserPermanentType.PERMANENT:UserPermanentType.NORMAL,
      contractVersion:CONTRACT_VERSION,
    }
    if(this.state.marketing){
      newMe.agreeMarketingAt = 1;
    }
    this.props.setMe(newMe);
    this.props.login();
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "CONTRACT_UPDATE", '서비스_약관_재동의_완료', this.state);

    this.props.history.replace(this.target);
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "UserContractUpdateSign:render()", this.props);
    if(this.props.me.contractVersion == CONTRACT_VERSION){
      let forward = this.target?this.target:"/";
      return <Redirect to={forward}/>
    }

    let hasNext:boolean = !this.props.me.permenent;
    return (
      <>
        <div className="common-content">
          {this.state.step == 0 ?
            <UserContractUpdate onDone={this.onContractUpdateDone} hasNext={hasNext} onClickContractDetail={this.onClickContractDetail} onClickPrivacyDetail={this.onClickPrivacyDetail} onClickMarketingDetail={this.onClickMarketingDetail}/>
            :
            <UserPermanent onDone={this.onPermanentDone}/>          
          }
        </div>
        <InformationViewerPopup ref={(ref) => this.popup = ref}/>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {
  setMe: (me) => actions.user.setMe(me),
  login: () => actions.user.login(),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserContractUpdateSign));
 