import React, { Component } from 'react';
import './../../Common.css'
import './RegisterStage3.css';
import './RegisterStage.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonInput, IonIcon, IonTextarea, IonToast, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';;
import {UserInfo, RegisterRoute} from '../../models/Model.User'
import {log, LogLevel} from '../../utils/LogUtil'
// import * as loadImage from 'blueimp-load-image';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { loadImageBase64 } from '../../utils/ImageUtil';
import {MY_ADDRESS} from './../../config.json';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { UIPopupType } from '../../store/ui/types';
import kakaoIcon from './../../assets/icon/question_small_yellow_mobile_3X.png'
import DynamicCheckerInput from './../DynamicCheckerInput';
import AnalyticsUtil from './../../utils/AnalyticsUtil';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  user: UserInfo;
  onDone: (data: UserInfo) => void;
  onBack: (data: UserInfo) => void;
};

type State = {
  registerRoute: string;
  registerRouteEtc: string;
  registerInvitorS: string;
}

const windowAny: any = window;

class RegisterStage3a extends Component<Props, State> {
  popupPharmacySelect=null;

  componentDidMount(){
    this.props.register("invite", this.onUrlActionCallback)
  }

  componentWillUnmount(){
    this.props.unregister("invite");
  }

  constructor(props){
    super(props);
    let registerRoute: string = '-';
    if(this.props.user.registerRoute)
      registerRoute = this.props.user.registerRoute;

    let registerRouteEtc: string = '';
    if(this.props.user.registerRouteEtc)
      registerRouteEtc = this.props.user.registerRouteEtc;

    let registerInvitorS: string = '';
    if(this.props.user.registerInvitor)
      registerInvitorS = this.props.user.registerInvitor.toString().padStart(4,"0");

    this.state={
      registerRoute,
      registerRouteEtc,
      registerInvitorS,
    }
  }

  onUrlActionCallback = (data) => {
    log(LogLevel.UI_EVENT, "KakaoPlus: urlAction", data);
    this.onRouteChange(true, RegisterRoute.INVITE);
    this.setState({registerInvitorS:data})
  }

  onDone = () => {
    if(this.state.registerRoute == '-'){
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_5_2_FAIL", '회원가입_5-2_설문조사입력실패', {"이유":"누락"});
      this.props.toast.show("가입경로를 확인해주세요.");
      return;
    }

    let rvalue:UserInfo = {
      registerRoute: this.state.registerRoute,
      registerRouteEtc: (this.checkChecked(RegisterRoute.ETC) && this.state.registerRouteEtc)?this.state.registerRouteEtc:"",
      registerInvitor: (this.checkChecked(RegisterRoute.INVITE) && this.state.registerInvitorS)?parseInt(this.state.registerInvitorS):0,
    }
    this.props.onDone(rvalue);
  }

  onBack = () => {
    let rvalue:UserInfo = {
      registerRoute: this.state.registerRoute,
      registerRouteEtc: (this.checkChecked(RegisterRoute.ETC) && this.state.registerRouteEtc)?this.state.registerRouteEtc:"",
      registerInvitor: (this.checkChecked(RegisterRoute.INVITE) && this.state.registerInvitorS)?parseInt(this.state.registerInvitorS):0,
    }
    this.props.onBack(rvalue);

  }

  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    if(!os || os == "browser"){
      try{
        windowAny.Kakao.init('0d4139a6dc131b05b8109f629d7cc3f7');
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
      try{
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: '_Vxjhxgj' // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }else{  
      let plusFriendTemplate = {
        plusFriendId: '_Vxjhxgj',
      };
      try{
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  }

  checkChecked = (value:string) => {
    if(!value) return false;
    let re = new RegExp("[" + value + "]", "gi");
    return re.test(this.state.registerRoute);
  }

  onRouteChange = (checked:boolean, value:string) => {
    let previous = this.state.registerRoute;
    let re = new RegExp("[" + value + "]", "gi");
    let registerRoute = previous.replace(re, '');
    if(checked){
      registerRoute += value;
    }
    this.setState({registerRoute})
    log(LogLevel.UI_ACTION, "RegisterStage3a:onToggleChange", checked, value, this.state.registerRoute);
  }

  onInvitorChange = (e) => {
    let registerInvitorS = e.target.value;
    if (registerInvitorS > 5){
      registerInvitorS = registerInvitorS.slice(0, 5);
    }    
    log(LogLevel.UI_ACTION, "onInvitorChange", registerInvitorS);
    this.onRouteChange(true, RegisterRoute.INVITE)
    this.setState({registerInvitorS});
  }

  onRouteEtcChange = (e) => {
    let registerRouteEtc = e.target.value;
    log(LogLevel.UI_ACTION, "onInvitorChange", registerRouteEtc);
    this.onRouteChange(true, RegisterRoute.ETC)
    this.setState({registerRouteEtc});
  }

  canPass() {
    return (this.state.registerRoute != '-');
  }

  render() {

    return (
      <div className="register3-container">
        <div className="register-progress-container">
          <div className="register-progress-done">1</div>
          <div className="register-progress-done">2</div>
          <div className="register-progress-done">3</div>
          <div className="register-progress-done">4</div>
          <div className="register-progress-done">5</div>
        </div>
        <div className="register3-service-description">
          <div> </div>
          <div> </div>
        </div>
        <div className="register-chat-guide">
          <img className="register-chat-kakao" src={kakaoIcon} onClick={this.onChat}/>
        </div>
        <div className="register3-body-container">
          <div className="register-title register-survey-title-margin">
            어떤 경로로 가입하셨나요?
          </div>
          <div className="register3-survey-item-container">
            <div className="common-flex-row register3-survey-item">
              <IonCheckbox color="register3-checkbox" mode="md" checked={this.checkChecked(RegisterRoute.INVITE)} onIonChange={(e) => this.onRouteChange(!this.checkChecked(RegisterRoute.INVITE),RegisterRoute.INVITE)}/>
              <div className="register3-checkbox-text">지인 추천</div>
            </div>
          </div>
          <div className="register3-survey-item-container">
            <div className="common-flex-row">
              <IonCheckbox color="register3-checkbox" mode="md" checked={this.checkChecked(RegisterRoute.CONTENT)} onIonChange={(e) => this.onRouteChange(!this.checkChecked(RegisterRoute.CONTENT),RegisterRoute.CONTENT)}/>
              <div className="register3-checkbox-text">카톡으로 공유받은 콘텐츠</div>
            </div>
          </div>
          <div className="register3-survey-item-container">
            <div className="common-flex-row">
              <IonCheckbox color="register3-checkbox" mode="md" checked={this.checkChecked(RegisterRoute.FACEBOOK)} onIonChange={(e) => this.onRouteChange(!this.checkChecked(RegisterRoute.FACEBOOK),RegisterRoute.FACEBOOK)}/>
              <div className="register3-checkbox-text">페이스북</div>
            </div>
          </div>
          <div className="register3-survey-item-container">
            <div className="common-flex-row">
              <IonCheckbox color="register3-checkbox" mode="md" checked={this.checkChecked(RegisterRoute.YSGL)} onIonChange={(e) => this.onRouteChange(!this.checkChecked(RegisterRoute.YSGL),RegisterRoute.YSGL)}/>
              <div className="register3-checkbox-text">약사공론 약문약답 콘텐츠</div>
            </div>
          </div>
          <div className="register3-survey-item-container">
            <div className="common-flex-row">
              <IonCheckbox color="register3-checkbox" mode="md" checked={this.checkChecked(RegisterRoute.NEWS)} onIonChange={(e) => this.onRouteChange(!this.checkChecked(RegisterRoute.NEWS),RegisterRoute.NEWS)}/>
              <div className="register3-checkbox-text">온라인 뉴스 (약사공론 등)</div>
            </div>
          </div>
          <div className="register3-survey-item-container">
            <div className="common-flex-row">
              <IonCheckbox color="register3-checkbox" mode="md" checked={this.checkChecked(RegisterRoute.BAROPHARM)} onIonChange={(e) => this.onRouteChange(!this.checkChecked(RegisterRoute.BAROPHARM),RegisterRoute.BAROPHARM)}/>
              <div className="register3-checkbox-text">바로팜 </div>
            </div>
          </div>
          <div className="register3-survey-item-container">
            <div className="common-flex-row register3-survey-item">
              <IonCheckbox color="register3-checkbox" mode="md" checked={this.checkChecked(RegisterRoute.ETC)} onIonChange={(e) => this.onRouteChange(!this.checkChecked(RegisterRoute.ETC),RegisterRoute.ETC)}/>
              <div className="register3-checkbox-text">기타</div>
            </div>
            <div className="common-flex-row register3-survey-subitem">
              <input className="register3-checkbox-text-input" placeholder="기타경로 입력" value={this.state.registerRouteEtc} onChange={this.onRouteEtcChange}/>
            </div>
          </div>

        </div>
        {/* <div className="register-navigate-container">

          <IonButton color="register-navigate-inactive" onClick={this.onBack}>
            &lt; 이전 단계로
          </IonButton>
          <div className="register-navigate-space"/>
          <IonButton color={this.canPass()?"register-navigate-active":"register-navigate-inactive"}  onClick={this.onDone}>
            다음 단계로 &gt;
          </IonButton>
        </div>    */}
        <div className="register4-finalize-container">
          <IonButton expand="block" color={(this.canPass())?"register4-finalize" : "register4-finalize-inactive"} onClick={this.onDone}>
            가입신청 완료
          </IonButton>
        </div>   
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  toast : state.ui.popups[UIPopupType.TOAST_POPUP],

});

const mapDispatchToProps = {
  register: (action, callback) => actions.ui.registerUrlAction(action, callback),
  unregister: (action) => actions.ui.unregisterUrlAction(action),
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterStage3a);