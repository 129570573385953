import React, { Component } from "react";
import "./ViewerComments.css";
import { BoardContent, BoardComment, Reference } from "./../models/Model.Board";
import { log, LogLevel } from "../utils/LogUtil";
import ProfileImage from "./ProfileImage";
import { getDateTimeString } from "../utils/TimeUtil";
import { fileURLToPath } from "url";
import Attachment from "./Attachment";
import { IonActionSheet } from "@ionic/react";
import ViewerCommentComposer from "./ViewerCommentComposer";
import {
  getGlobal,
  GlobalKey,
  setGlobal,
  clearGlobal,
} from "../utils/GlobalUtil";
import ViewerCommentOpenComposer from "./ViewerCommentOpenComposer";
import { UIPopupType } from "../store/ui/types";
import { RootState, actions } from "../store";
import { connect } from "react-redux";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import BoardUtil from "../utils/BoardUtil";
import ReactHtmlParser from "react-html-parser";
import LinkMeta from "./LinkMeta";
import { timingSafeEqual } from "crypto";
import { MY_ADDRESS } from "./../config.json";
import { withRouter, RouteComponentProps } from "react-router";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import { UserLevel } from "../models/Model.User";
import StringUtil from "../utils/StringUtil";
import { COLOR_SYSTEM } from "./design/design-system";
import Text from "./atoms/Text";
import Button from "./atoms/Button";
import ToggleButton from "./atoms/ToggleButton/ToggleButton";
import Badge from "./atoms/Badge";
import { Flex } from "./atoms/Layout";
import Icon from "./atoms/Icon";

const windowAny: any = window;
const md5 = require("js-md5");

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    open?: boolean;
    comment?: BoardComment;
    key?: string;
    index?: number;
    onUserPressed?: (id: number) => any;
    onPartnerPressed?: (id: string) => void;
    onChange?: (noti: BoardComment, index: number) => any;
    onReply?: (noti: BoardComment, index: number) => any;
    onDelete?: (noti: BoardComment, index: number, isAdmin: boolean) => any;
    onReport?: (noti: BoardComment, type: number) => any;
    onLike?: (noti: BoardComment, index: number) => any;
    onDislike?: (noti: BoardComment) => any;
    confirmPopup?: any;
    lastViewedAt?: any;
    findFocus?: any;
    onFindFocusComponent?: (focused: any) => void;
    isDiscussion?: boolean;
    noComposer?: boolean;
    canDislike?: boolean;
    canAnonymous?: boolean;
    defaultAnonymous?: boolean;
    anonymousNames?: any;
    emphasisLike?: boolean;
    rootAuthorId?: number;
    rootAuthorName?: string;
  };

type State = {
  showMenuPopover: boolean;
  showDeletePopover: boolean;
  showDeleteAdminPopover: boolean;
  showReportPopover: boolean;
  onEdit: boolean;
  onReply: boolean;
};

class ViewerComment extends Component<Props, State> {
  focusRef: any;

  password = "";

  popoverMenu = [];
  menuModify = {
    text: "수정",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onModify();
    },
  };

  menuDelete = {
    text: "삭제",
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Delete clicked");
      this.onDelete();
    },
  };

  menuReport = {
    text: "신고하기",
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Report clicked");
      this.setState({ showReportPopover: true });
    },
  };

  menuAdminDelete = {
    text: "삭제(관리자)",
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Delete clicked");
      this.setState({ showDeleteAdminPopover: true });
    },
  };

  menuCancel = {
    text: "취소",
    role: "cancel",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Cancel clicked");
    },
  };

  onModify = () => {
    if (this.props.open) {
      if (this.props.confirmPopup) {
        this.props.confirmPopup.show({
          title: (
            <div className="common-container">
              <span className="common-color-highlight">비밀번호</span>를
              입력해주세요.
            </div>
          ),
          needInput: true,
          isPassword: true,
          onDone: this.onModifyChecked,
          doneText: "확인",
        });
      }
    } else {
      this.setState({ onEdit: true });
    }
  };

  onModifyChecked = async (text) => {
    this.password = md5(text);
    let result = await fetchAPI(API.COMMENT_OPEN_CHECK, "", null, {
      id: this.props.comment.id,
      password: this.password,
    });
    log(
      LogLevel.UI_ACTION,
      "ViewerComment:onModifyChecked",
      text,
      this.password,
      result
    );
    if (result && !result.error) {
      this.setState({ onEdit: true });
    }
  };

  onReply = (comment: BoardComment) => {
    log(LogLevel.UI_ACTION, "ViewerComment:onReply", comment);
    this.setState({ onReply: false });
    comment.boardsContentsId = this.props.comment.boardsContentsId;
    comment.boardsContentsGroupId = this.props.comment.boardsContentsGroupId;
    comment.groupParent = this.props.comment.id;
    if (this.props.onReply) {
      this.props.onReply(comment, this.props.index);
    }
  };

  onReplyCancel = () => {
    log(LogLevel.UI_ACTION, "ViewerComment:onReplyCancel");
    this.setState({ onReply: false });
  };

  onChange = (comment: BoardComment) => {
    this.setState({ onEdit: false });
    if (this.props.onChange) {
      if (this.password) comment.password = this.password;
      this.props.onChange(comment, this.props.index);
    }
  };

  onChangeCancel = () => {
    this.setState({ onEdit: false });
  };

  onDelete = () => {
    if (this.props.open) {
      if (this.props.confirmPopup) {
        this.props.confirmPopup.show({
          title: (
            <div className="common-container">
              <span className="common-color-highlight">비밀번호</span>를
              입력해주세요.
            </div>
          ),
          needInput: true,
          isPassword: true,
          onDone: this.onDeleteChecked,
          doneText: "확인",
        });
      }
    } else {
      this.setState({ showDeletePopover: true });
    }
  };

  onDeleteChecked = async (text) => {
    this.password = md5(text);
    let result = await fetchAPI(API.COMMENT_OPEN_CHECK, "", null, {
      id: this.props.comment.id,
      password: this.password,
    });
    log(
      LogLevel.UI_ACTION,
      "ViewerComment:onModifyChecked",
      text,
      this.password,
      result
    );
    if (result && !result.error) {
      this.setState({ showDeletePopover: true });
    }
  };

  onDeleteConfirmed = (isAdmin = false) => {
    if (this.props.onDelete) {
      let comment = this.props.comment;
      if (this.password) comment.password = this.password;
      this.props.onDelete(comment, this.props.index, isAdmin);
    }
  };

  onReport = (type: number) => {
    log(LogLevel.UI_ACTION, "ViewerComment:onReport", this.props.comment, type);
    if (this.props.onReport) this.props.onReport(this.props.comment, type);
  };

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ViewerComment.constructor");
    this.state = {
      showMenuPopover: false,
      showDeletePopover: false,
      showDeleteAdminPopover: false,
      showReportPopover: false,
      onEdit: false,
      onReply: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerComment.componentDidUpdate",
      this.props.findFocus != prevProps.findFocus,
      this.focusRef,
      this.props.onFindFocusComponent,
      this.props.comment,
      this.props.findFocus,
      this.props.findFocus.commentId,
      this.props.comment.id == this.props.findFocus.commentId
    );

    if (
      this.props.findFocus != prevProps.findFocus &&
      this.props.findFocus.commentId != prevProps.findFocus.commentId &&
      this.focusRef &&
      this.props.onFindFocusComponent &&
      this.props.comment &&
      this.props.findFocus &&
      this.props.findFocus.commentId &&
      this.props.comment.id == this.props.findFocus.commentId
    ) {
      this.props.onFindFocusComponent(this.focusRef.offsetTop);
    }
  }

  componentDidMount() {
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerComment.componentDidMount",
      !!this.props.onFindFocusComponent,
      this.props.comment.id,
      this.props.findFocus
    );
    if (
      this.focusRef &&
      this.props.onFindFocusComponent &&
      this.props.comment &&
      this.props.findFocus &&
      this.props.findFocus.commentId &&
      this.props.comment.id == this.props.findFocus.commentId
    ) {
      this.props.onFindFocusComponent(this.focusRef.offsetTop);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerComment.shouldComponentUpdate",
      nextProps,
      this.props
    );
    if (
      nextProps.findFocus != this.props.findFocus &&
      nextProps.findFocus.commentId != this.props.findFocus.commentId
    ) {
      return true;
    }

    if (
      this.state.showMenuPopover != nextState.showMenuPopover ||
      this.state.showDeletePopover != nextState.showDeletePopover ||
      this.state.showReportPopover != nextState.showReportPopover ||
      this.state.onEdit != nextState.onEdit ||
      this.state.onReply != nextState.onReply ||
      this.props.links != nextProps.links
    )
      return true;
    if (nextProps.comment == this.props.comment) {
      return false;
    }
    return true;
  }

  onUserPressed = () => {
    log(
      LogLevel.UI_ACTION,
      "ViewerComment:onUserPressed",
      this.props.comment.usersBizId,
      !!this.props.onPartnerPressed,
      this.props.comment.usersId,
      this.props.rootAuthorId,
      !!this.props.onUserPressed,
      this.props.comment.isAnonymous,
      !this.props.comment.isAnonymous &&
        this.props.comment.usersId > 0 &&
        this.props.comment.usersId != this.props.rootAuthorId &&
        this.props.onUserPressed
    );
    if (this.props.comment.usersBizId && this.props.onPartnerPressed) {
      this.props.onPartnerPressed(this.props.comment.usersBizId);
    } else if (
      !this.props.comment.isAnonymous &&
      this.props.comment.usersId > 0 &&
      this.props.comment.usersId != this.props.rootAuthorId &&
      this.props.onUserPressed
    )
      this.props.onUserPressed(this.props.comment.usersId);
  };

  onCommentPressed = () => {
    log(LogLevel.UI_ACTION, "ViewerComment:onCommentPressed");
    // if(!this.props.comment.imageUrl && !this.props.comment.fileUrl)
    // this.setState({showMenuPopover: true});
  };

  onCommentLike = (e) => {
    log(LogLevel.UI_ACTION, "ViewerComment:onCommentLike");
    if (this.props.onLike)
      this.props.onLike(this.props.comment, this.props.index);
    e.stopPropagation();
  };

  onCommentDislike = (e) => {
    log(LogLevel.UI_ACTION, "ViewerComment:onCommentDislike");
    if (this.props.onDislike) this.props.onDislike(this.props.comment);
    e.stopPropagation();
  };

  // handleButtonPress = () => {
  //   log(LogLevel.UI_ACTION, "ViewerComment:handleButtonPress", this.props.comment.id);
  //   this.setState({ backdropDismiss: true });
  //   setGlobal(GlobalKey.LONG_PRESS_TIMER, setTimeout(this.openMenu, 1000));
  // };

  openMenu = () => {
    if (!this.props.comment.deletedAt) this.setState({ showMenuPopover: true });
    log(LogLevel.UI_EVENT, "ViewerComment:Long Pressed", this.state);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SHOW_COMMENT_MENU",
      "댓글메뉴보이기",
      {
        게시물id: this.props.comment.boardsContentsGroupId,
      }
    );
  };

  // handleButtonRelease = () => {
  //   log(LogLevel.UI_ACTION, "ViewerComment:handleButtonRelease", this.props.comment.id);
  //   this.setState({ backdropDismiss: false });
  //   if (getGlobal(GlobalKey.LONG_PRESS_TIMER)) {
  //     clearTimeout(getGlobal(GlobalKey.LONG_PRESS_TIMER));
  //     clearGlobal(GlobalKey.LONG_PRESS_TIMER);
  //   }
  // };

  showImagePopup = () => {
    let os = getGlobal(GlobalKey.OS);
    if (os && os != "browser" && windowAny.PhotoViewer) {
      try {
        windowAny.PhotoViewer.show(
          StringUtil.convertFilePath(this.props.comment.imageUrl)
        );
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "showImagePopup.failed");
      }
    }
  };

  onPressAttachment = () => {
    log(LogLevel.UI_ACTION, "onPressAttachment", this.props.comment.fileUrl);
    let url = this.props.comment.fileUrl;
    let os = getGlobal(GlobalKey.OS);
    if (os == "android" && url.endsWith("pdf"))
      windowAny.open(
        "https://docs.google.com/viewer?url=" +
          StringUtil.convertFilePath(url) +
          "&embedded=true",
        "_system"
      );
    else if (
      url.endsWith("ppt") ||
      url.endsWith("pptx") ||
      url.endsWith("doc") ||
      url.endsWith("docx")
    )
      windowAny.open(
        "https://docs.google.com/viewer?url=" +
          StringUtil.convertFilePath(url) +
          "&embedded=true",
        "_system"
      );
    else windowAny.open(StringUtil.convertFilePath(url), "_system");
  };

  onClickLink = (url: string) => {
    log(LogLevel.UI_ACTION, "onClickLink", url);
    if (url.startsWith("&&&")) {
      if (getGlobal(GlobalKey.OS) != "browser") {
        this.props.history.push(url.substring(3));
      } else {
        window.open(
          url.replace("&&&", MY_ADDRESS),
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
      }
    } else
      window.open(
        url,
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );
  };

  onCommentReply = (e) => {
    log(LogLevel.UI_ACTION, "ViewerComment:onCommentReply");
    this.setState({ onReply: true });
    e.stopPropagation();
  };

  transform = (node, index) => {
    log(LogLevel.UI_DATA_LOAD, "ViewerComment transform", node);
    if (node.type == "text") {
      let str = node.data;
      let paragraphs = str.split("\n");
      log(
        LogLevel.UI_DATA_LOAD,
        "ViewerComment transform.paragraphs",
        paragraphs
      );
      let rvalue = paragraphs[0];
      for (let i = 1; i < paragraphs.length; i++) {
        rvalue += <br />;
        rvalue += paragraphs[i];
      }
      return rvalue;
    }
    if (node.type == "tag" && node.name == "br") {
      return <br key={`${index}`} />;
    }

    if (node.type == "tag" && node.name == "a") {
      let url = node.attribs.href;
      if (url.startsWith("&&&")) url = url.replace("&&&", MY_ADDRESS);

      if (
        node.attribs.description ||
        node.attribs.title ||
        node.attribs.images
      ) {
        log(LogLevel.UI_DATA_LOAD, "ViewerBody:transform: a tag", node.attribs);
        return (
          <div
            key={`${index}`}
            onClick={() => this.onClickLink(node.attribs.href)}
          >
            <div className="viewerbody-links">{url}</div>
            <LinkMeta
              link={node.attribs.href}
              title={node.attribs.title}
              description={node.attribs.description}
              image={node.attribs.images}
              onClick={this.onClickLink}
            />
          </div>
        );
      } else if (node.attribs.href && this.props.links[node.attribs.href]) {
        let reference: Reference = this.props.links[node.attribs.href];
        log(LogLevel.UI_DATA_LOAD, "ViewerBody:transform: a tag", reference);
        if (reference.description || reference.title || reference.image) {
          return (
            <div
              className="viewerbody-links-container"
              key={`${index}`}
              onClick={() => this.onClickLink(reference.url)}
            >
              <div className="viewerbody-links">{url}</div>
              <LinkMeta
                link={reference.url}
                title={reference.title}
                description={reference.description}
                image={reference.image}
                onClick={this.onClickLink}
              />
            </div>
          );
        } else {
          return (
            <div
              className="viewerbody-links-container"
              key={`${index}`}
              onClick={() => this.onClickLink(reference.url)}
            >
              {url}
            </div>
          );
        }
      } else {
        this.props.loadLink(
          node.attribs.href,
          this.props.comment.boardsContentsId
        );
        return (
          <div
            className="viewerbody-links-container"
            onClick={() => this.onClickLink(node.attribs.href)}
          >
            {url}
          </div>
        );
      }
    }

    log(LogLevel.UI_EXCEPTION, "ViewerComment transform: unknown", node);
    return undefined;
  };

  render() {
    let comment = this.props.comment;
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerComment.render",
      this.state,
      comment,
      this.props
    );

    if (!comment) return null;
    if (!comment.bodyText) comment.bodyText = "";
    if (!comment.fileUrl && !comment.imageUrl && !comment.bodyHtml) {
      BoardUtil.generateHtml(comment);
    }

    if (this.state.onEdit) {
      if (this.props.open)
        return (
          <ViewerCommentOpenComposer
            comment={this.props.comment}
            id={this.props.comment.boardsContentsId}
            profile={this.props.comment.usersProfileUrl}
            onDone={this.onChange}
            onCancel={this.onChangeCancel}
          />
        );
      else
        return (
          <ViewerCommentComposer
            canAnonymous={this.props.canAnonymous}
            comment={this.props.comment}
            id={this.props.comment.boardsContentsId}
            profile={this.props.comment.usersProfileUrl}
            onDone={this.onChange}
            onCancel={this.onChangeCancel}
          />
        );
    }

    let image;
    if (this.props.comment.imageUrl)
      image = (
        <img
          className="viewer-comment-image"
          src={StringUtil.convertFilePath(this.props.comment.imageUrl)}
          onClick={this.showImagePopup}
        />
      );

    let file;
    if (this.props.comment.fileUrl)
      file = (
        <div style={{ width: "100%" }}>
          <Attachment
            className="viewer-comment-file-container"
            url={this.props.comment.fileUrl}
            name={this.props.comment.fileName}
            size={this.props.comment.fileSize}
            onPress={this.onPressAttachment}
          />
        </div>
      );

    let isAdmin: boolean =
      this.props.me && this.props.me.level >= UserLevel.MANAGER;

    this.popoverMenu = [];
    if (this.props.open) {
      if (this.props.comment.usersId == -1) {
        this.popoverMenu.push(this.menuModify);
        this.popoverMenu.push(this.menuDelete);
      }
    } else if (this.props.comment.editable) {
      if (this.props.comment.bodyText) this.popoverMenu.push(this.menuModify);
      this.popoverMenu.push(this.menuDelete);
    } else if (isAdmin) {
      this.popoverMenu.push(this.menuAdminDelete);
      this.popoverMenu.push(this.menuReport);
    } else {
      this.popoverMenu.push(this.menuReport);
    }
    this.popoverMenu.push(this.menuCancel);

    let nickname = comment.usersNickname;
    let isAnonymous = comment.isAnonymous;
    let profile = comment.usersProfileUrl;
    if (comment.usersId == -1) {
      if (comment.usersCustomName) nickname = comment.usersCustomName;
      else if (comment.usersNickname) nickname = comment.usersNickname;
      else nickname = "익명약사";
    }
    if (comment.isAnonymous) {
      if (comment.usersCustomName) nickname = comment.usersCustomName;
      else {
        nickname = "익명약사";
        if (
          this.props.anonymousNames &&
          this.props.anonymousNames[comment.usersId]
        )
          nickname += this.props.anonymousNames[comment.usersId];
      }
    }
    if (this.props.rootAuthorId && this.props.rootAuthorId == comment.usersId) {
      profile = "";
      nickname = this.props.rootAuthorName;
    }

    let replyOnCommontButton;
    if (
      ABTestUtil.isTest(ABTestFeature.UI_COMMENT_REPLY) &&
      comment.groupDepth < 2 &&
      !this.props.open &&
      !this.state.onReply &&
      !this.props.noComposer
    ) {
      replyOnCommontButton = (
        // <span className="viewer-comment-action-disabled" onClick={this.onCommentReply}>
        //   댓글달기{" "}
        // </span>
        <Button
          color="Quaternary"
          size="Small"
          variant="Ghost"
          type="IconWithText"
          icon="Comment Filled"
          left
          onClick={this.onCommentReply}
        >
          댓글달기
        </Button>
      );
    }

    let doEditButton;
    if (comment.editable) {
      doEditButton = (
        <span
          className="viewer-comment-action-disabled"
          onClick={this.openMenu}
        >
          수정하기{" "}
        </span>
      );
    }

    let replyOnCommentEditor;
    if (this.state.onReply) {
      replyOnCommentEditor = (
        <ViewerCommentComposer
          depth={this.props.comment.groupDepth + 1}
          defaultAnonymous={this.props.defaultAnonymous}
          canAnonymous={this.props.canAnonymous}
          parentId={this.props.comment.id}
          groupId={this.props.comment.boardsContentsGroupId}
          id={this.props.comment.boardsContentsId}
          profile={this.props.profileUrl}
          onDone={this.onReply}
          onCancel={this.onReplyCancel}
        />
      );
    }

    let newMark;
    if (
      ABTestUtil.isTest(ABTestFeature.UI_VIEW_UPDATED) &&
      this.props.lastViewedAt
    ) {
      log(
        LogLevel.UI_LIFECYCLE,
        "ViewerComment:render newMark check",
        this.props.lastViewedAt,
        comment.createdAt
      );
      if (
        this.props.me.id != comment.usersId &&
        this.props.lastViewedAt < comment.createdAt
      ) {
        newMark = (
          <div style={{ position: "relative", top: "-9px" }}>
            <Badge color="Red" />
          </div>
        );
      }
    }

    let likeClass;
    if (this.props.comment.liked) likeClass = "viewer-comment-action-enabled";
    else if (
      this.props.emphasisLike &&
      this.props.comment.likeCnt &&
      (!this.props.comment.dislikeCnt ||
        this.props.comment.likeCnt > this.props.comment.dislikeCnt)
    )
      likeClass = "viewer-comment-action-empasis";
    else likeClass = "viewer-comment-action-disabled";

    let dislikeClass;
    if (this.props.comment.disliked)
      dislikeClass = "viewer-comment-action-enabled";
    else if (
      this.props.emphasisLike &&
      this.props.comment.dislikeCnt &&
      (!this.props.comment.likeCnt ||
        this.props.comment.likeCnt < this.props.comment.dislikeCnt)
    )
      dislikeClass = "viewer-comment-action-empasis";
    else dislikeClass = "viewer-comment-action-disabled";

    // let likeTerm = "좋아요";
    // if (this.props.isDiscussion) likeTerm = "찬성";

    let dislikeButton;
    if (this.props.canDislike) {
      let dislikeTerm = "싫어요";
      if (this.props.isDiscussion) dislikeTerm = "반대";
      dislikeButton = (
        <span className={dislikeClass} onClick={this.onCommentDislike}>
          {dislikeTerm} {this.props.comment.dislikeCnt}{" "}
        </span>
      );
    }

    let commentBody;
    if (comment.deletedAt) {
      commentBody = (
        <div className="viewer-comment-body-container">
          <div className="viewer-comment-body">작성자가 삭제한 댓글입니다.</div>
          <div className="viewer-comment-info-container" />
        </div>
      );
    } else if (comment.blocked) {
      commentBody = (
        <div className="viewer-comment-body-container">
          <div className="viewer-comment-body-blocked">
            <span
              className="viewer-comment-nickname"
              onClick={this.onUserPressed}
            >
              {nickname}
              {comment.usersPremiumType && (
                <img
                  src="/images/sprout2.png"
                  style={{ width: "14px", height: "14px", objectFit: "cover" }}
                />
              )}
              <span className="viewer-comment-nickname-blocked">(차단)</span>
            </span>
            차단된 사용자의 댓글입니다.
          </div>
        </div>
      );
    } else {
      commentBody = (
        <div className="viewer-comment-body-container">
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Text
              textType="Body2SemiBold"
              color={COLOR_SYSTEM.get("Gray")[900]}
              onClick={this.onUserPressed}
            >
              {nickname}
            </Text>
            {comment.usersPremiumType && (
              <img
                src="/images/sprout2.png"
                style={{ width: "14px", height: "14px", objectFit: "cover" }}
              />
            )}
            <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
              {getDateTimeString(comment.createdAt)}
            </Text>
            {newMark}
          </div>
          <div>
            <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[900]}>
              {ReactHtmlParser(comment.bodyHtml, { transform: this.transform })}
            </Text>
          </div>
          {image}
          {file}
          <Flex alignItems="center" gap="8px" customStyle={{ width: "100%" }}>
            <ToggleButton
              color="Red"
              size="Small"
              variant="Ghost"
              type="IconWithText"
              icon="Heart"
              active={this.props.comment.liked}
              left
              onClick={this.onCommentLike}
            >
              {comment.likeCnt > 0 ? comment.likeCnt : ""}
            </ToggleButton>
            {/* {dislikeButton} */}
            {replyOnCommontButton}
            {/* {doEditButton} */}
            {/* <span className={dislikeClass} onClick={this.onCommentDislike}>싫어요 {this.props.comment.dislikeCnt} </span> */}
            <div className="common-flex-grow" />
            <Button
              color={
                comment.usersId === this.props.me.id
                  ? "Secondary"
                  : "Quaternary"
              }
              size="Small"
              variant="Ghost"
              type="Icon"
              icon="More"
              onClick={this.openMenu}
            />
          </Flex>
        </div>
      );
    }

    return (
      <>
        <div
          ref={(ref) => {
            this.focusRef = ref;
          }}
          className={"viewer-comment-container"}
          // onTouchStart={this.handleButtonPress}
          // onTouchEnd={this.handleButtonRelease}
          // onMouseDown={this.handleButtonPress}
          // onMouseUp={this.handleButtonRelease}
          // onMouseLeave={this.handleButtonRelease}
          onClick={this.onCommentPressed}
          style={{ paddingLeft: 25 * comment.groupDepth }}
        >
          {/* {newMark} */}
          <ProfileImage
            className="viewer-comment-profile"
            profileUrl={profile}
            anonymous={isAnonymous}
            onClick={this.onUserPressed}
          />
          {commentBody}
          <IonActionSheet
            mode="ios"
            children
            isOpen={this.state.showMenuPopover}
            onDidDismiss={() =>
              this.setState(() => ({ showMenuPopover: false }))
            }
            buttons={this.popoverMenu}
            backdropDismiss={true}
          />
          <IonActionSheet
            mode="ios"
            children
            header="정말로 삭제하시겠습니까?"
            isOpen={
              this.state.showDeletePopover || this.state.showDeleteAdminPopover
            }
            onDidDismiss={() =>
              this.setState(() => ({
                showDeletePopover: false,
                showDeleteAdminPopover: false,
              }))
            }
            buttons={[
              {
                text: "삭제하겠습니다.",
                cssClass: "common-menu-red",
                handler: () => {
                  log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                  this.onDeleteConfirmed(this.state.showDeleteAdminPopover);
                },
              },
              {
                text: "취소",
                role: "cancel",
                cssClass: "common-menu-normal",
                handler: () => {
                  log(LogLevel.UI_ACTION, "Delete Menu Cancel clicked");
                },
              },
            ]}
            backdropDismiss={true}
          />
          <IonActionSheet
            mode="ios"
            children
            header="이 글을 신고하시는 이유는 무엇인가요?"
            isOpen={this.state.showReportPopover}
            onDidDismiss={() =>
              this.setState(() => ({ showReportPopover: false }))
            }
            buttons={[
              {
                text: "폭언, 비속어, 혐오발언",
                cssClass: "common-menu-normal",
                handler: () => {
                  log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                  this.onReport(1);
                },
              },
              {
                text: "홍보, 스팸, 광고",
                cssClass: "common-menu-normal",
                handler: () => {
                  log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                  this.onReport(2);
                },
              },
              {
                text: "근거가 없는 잘못된 정보",
                cssClass: "common-menu-normal",
                handler: () => {
                  log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                  this.onReport(3);
                },
              },
              {
                text: "취소",
                role: "cancel",
                cssClass: "common-menu-normal",
                handler: () => {
                  log(LogLevel.UI_ACTION, "Delete Menu Cancel clicked");
                },
              },
            ]}
            backdropDismiss={true}
          />
        </div>
        {replyOnCommentEditor}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
  links: state.board.links,
  profileUrl: state.user.me.profileUrl,
  me: state.user.me,
});

const mapDispatchToProps = {
  loadLink: (url: string, commentId: number) =>
    actions.board.loadCommentLink(url, commentId),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewerComment)
);
