import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useBottomSheet from "../../../hooks/useBottomSheet";
import { BoardAttribute } from "../../../models/Model.Board";
import { RootState } from "../../../store";
import { getBoard, sortBoard } from "../../../store/board/actions";
import {
  BoardListState,
  BoardSortType,
  BoardType,
} from "../../../store/board/types";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import { Absolute, Static } from "../../atoms/Layout";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";

interface BoardListUnionButtonProps {
  onChangeUnion: (categories: BoardType[]) => void;
  listType: BoardType;
}

const BoardListUnionButton: FC<BoardListUnionButtonProps> = ({
  onChangeUnion,
  listType,
}) => {
  const bottomSheet = useBottomSheet();
  const boards = useSelector((state: RootState) => state.board.boards);
  const [guided, setGuided] = useState<number>(
    getGlobal(GlobalKey.BOARD_UNION_GUIDED, true)
  );

  const handleChangeUnion = (categories: BoardType[]) => {
    onChangeUnion(categories);
    bottomSheet.close();
  };

  log(
    LogLevel.UI_ACTION,
    "BoardListUnionButton:render",
    listType,
    boards[listType]
  );

  const handleClickUnion = () => {
    if (!guided) {
      setGuided(1);
      setGlobal(GlobalKey.BOARD_UNION_GUIDED, Date.now(), true);
    }
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "BOARD_UNION",
      "관심게시판선택_진입",
      {}
    );
    bottomSheet.show({
      body: (
        <BoardListUnionPopupBody
          onChangeUnion={handleChangeUnion}
          listType={listType}
        />
      ),
    });
  };

  return (
    <Static style={{ position: "relative" }}>
      {!guided && (
        <Absolute top="-48px">
          <Static
            style={{
              background: COLOR_SYSTEM.get("Blue")[500],
              padding: "8px 12px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              borderRadius: "8px",
              gap: "12px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setGlobal(GlobalKey.BOARD_UNION_GUIDED, Date.now(), true);
              setGuided(1);
            }}
          >
            <Text textType={"Body2"} color={COLOR_SYSTEM.get("Gray")[0]}>
              모아보고싶은 관심 게시판을 설정해보세요!{" "}
            </Text>
            <Icon size={16} name="X" color={COLOR_SYSTEM.get("Blue")[100]} />
          </Static>
          <div
            style={{
              marginLeft: "20px",
              height: "8px",
              width: "12px",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderTop: `8px solid ${COLOR_SYSTEM.get("Blue")[500]}`,
            }}
          />
        </Absolute>
      )}
      <Static
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
          position: "relative",
        }}
        onClick={handleClickUnion}
      >
        <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[700]}>
          관심게시판
        </Text>
        <Tag variant={"Contained"} color={"Gray"}>
          {boards[listType].categories.length}
        </Tag>
        <Icon name={"CaretRight"} size={16} />
      </Static>
    </Static>
  );
};

const BoardListUnionPopupBody: FC<BoardListUnionButtonProps> = ({
  onChangeUnion,
  listType,
}) => {
  const boards = useSelector((state: RootState) => state.board.boards);
  const boardList = useSelector(
    (state: RootState) => state.board.selectableBoardList
  );

  const [categories, setCategories] = useState<BoardType[]>(
    boards[listType].categories
  );

  useEffect(() => {
    setCategories(boards[listType].categories);
  }, [listType]);

  log(
    LogLevel.UI_ACTION,
    "BoardListUnionPopupBody:render",
    categories,
    listType,
    boards[listType]
  );

  const handleClick = (board: BoardType) => {
    let newCategory = [...categories];
    let index = newCategory.indexOf(board);
    if (index >= 0) {
      newCategory.splice(index, 1);
    } else {
      newCategory = [...categories, board];
    }
    log(
      LogLevel.UI_ACTION,
      "BoardListUnionPopupBody:handleClick",
      categories,
      newCategory,
      board
    );

    setCategories(newCategory);
  };

  return (
    <>
      <Static style={{ display: "flex", justifyContent: "space-between" }}>
        <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[700]}>
          관심 게시판 선택
        </Text>
        <Tag
          variant={categories.length ? "Contained" : "Tinted"}
          color={categories.length ? "Black" : "Red"}
          size="Small"
        >
          {categories.length} / {boardList.length}
        </Tag>
      </Static>
      <Static
        style={{
          paddingTop: "4px",
          paddingBottom: "32px",
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
        }}
      >
        {categories && categories.length > 0 ? (
          <Text textType="Body2Medium" color={COLOR_SYSTEM.get("Gray")[500]}>
            모아 보고 싶은 관심 게시판을 선택해주세요
          </Text>
        ) : (
          <Text textType="Body2Medium" color={COLOR_SYSTEM.get("Red")[300]}>
            1개 이상의 게시판을 선택해주세요
          </Text>
        )}
      </Static>
      <Static style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
        {boardList &&
          boardList.length > 0 &&
          boardList.map((board, index) => {
            return (
              <ToggleButton
                key={index.toString()}
                color="Skyblue"
                size="Medium"
                variant="Tinted"
                type="IconWithText"
                icon="Check Circle"
                active={categories.includes(board)}
                disabled={[BoardType.ALLINONE, BoardType.NOTICE].includes(
                  board
                )}
                disabledColor={[BoardType.ALLINONE, BoardType.NOTICE].includes(
                  board
                )}
                left
                onClick={() => handleClick(board)}
                style={{
                  flex: "calc(50% - 6px)",
                  justifyContent: "normal",
                  maxWidth: "calc(50% - 6px)",
                }}
              >
                {boards[board].name}
              </ToggleButton>
            );
          })}
      </Static>
      <Button
        size={"Medium"}
        type={"Text"}
        variant={"Contained"}
        color={"Primary"}
        style={{ width: "100%", marginTop: "40px" }}
        onClick={(e) => onChangeUnion(categories)}
        disabled={!categories || !categories.length}
        disabledColor
      >
        선택 완료
      </Button>
    </>
  );
};

export default BoardListUnionButton;
