import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton } from "@ionic/react";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import logoIcon from "./../assets/icon/logo.png";
import kakaoIcon from "./../assets/icon/kakao_logo.png";
import RegisterStage0 from "../components/register/RegisterStage0";
import RegisterStage1 from "../components/register/RegisterStage1";
import RegisterStage2 from "../components/register/RegisterStage2";
import RegisterStage2a from "../components/register/RegisterStage2a";
import RegisterStage3 from "../components/register/RegisterStage3";
import RegisterStage4 from "../components/register/RegisterStage4";
import RegisterStage12 from "../components/register/RegisterStage12";
import SearchAddress from "./../components/SearchAddress";
import {
  UserInfo,
  UserLevel,
  UserSNSType,
  UserWorkType,
  UserWorkTypeName,
} from "../models/Model.User";
import { log, LogLevel } from "../utils/LogUtil";
import AnalyticsUtil from "./../utils/AnalyticsUtil";

import { on } from "cluster";

import {
  getGlobal,
  GlobalKey,
  clearGlobal,
  setGlobal,
} from "./../utils/GlobalUtil";
import { timerLap, timerStop, TimerId } from "../utils/TimeUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import RegisterStage3a from "../components/register/RegisterStage3a";
import { getDevice } from "../utils/DeviceUtil";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    kakao: any;
    private: boolean;
  };

type State = {
  stage: number; // 가입 단계
  user: UserInfo; // 유저 정보
};

const windowAny: any = window;

class Register extends Component<Props, State> {
  holding: boolean = false;

  state = {
    stage: 0,
    user: null,
  };

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "Register:constructor", props);
    if (props.me) {
      this.state.user = props.me;
      if (props.me.workType == UserWorkType.UNDERGRADUATE)
        this.state.stage = 12;
      else this.state.stage = 2;
    } else if (props.location.state && props.location.state.info) {
      this.state.user = {
        snsType: props.location.state.type || UserSNSType.KAKAO,
        snsId: props.location.state.info.id,
        email: props.location.state.info.email,
        nickname: props.location.state.info.nickname,
        profileUrl: props.location.state.info.profile,
        name: props.location.state.info.name,
        wantToGo:
          props.location.state && props.location.state.from
            ? props.location.state.from
            : "",
      };
    } else {
      // this.props.history.replace('/');
    }
  }

  componentDidMount() {
    log(LogLevel.UI_EVENT, "Register:componentDidMount backbutton register");
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
    // document.addEventListener("backbutton", this.goBack);

    let lastStageS = getGlobal(GlobalKey.REGISTRATION_PROGRESS, true);
    if (lastStageS) {
      let lastStage = parseInt(lastStageS);
      let lastUser = getGlobal(GlobalKey.REGISTRATION_DATA, true);
      if (
        lastUser &&
        this.state.user &&
        this.state.user.kakaoId == lastUser.kakaoId
      ) {
        if (this.props.confirmPopup) {
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "RESTRATION_RESUME_POPUP_SHOW",
            "회원가입_재개_팝업",
            {}
          );

          this.props.confirmPopup.show({
            title: (
              <div className="common-container">
                <span className="common-bold">
                  진행하던 가입정보가 있습니다.{" "}
                </span>
              </div>
            ),
            body: (
              <div className="common-container">이어서 진행하시겠습니까?</div>
            ),
            cancelText: "새로 가입하기",
            onCancel: this.fromStart,
            onDone: () => {
              this.continue(lastStage, lastUser);
            },
            doneText: "이어서 진행",
          });
        }
      } else {
        log(
          LogLevel.UI_EXCEPTION,
          "componentDidMount stored information is not for this user.",
          lastUser,
          this.state.user
        );
        clearGlobal(GlobalKey.REGISTRATION_PROGRESS, true);
        clearGlobal(GlobalKey.REGISTRATION_DATA, true);
      }
    }
  }

  continue = (stage, user) => {
    log(LogLevel.UI_ACTION, "Register:continue", stage, user);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_RESUME",
      "회원가입_재개_이어하기",
      {}
    );
    this.setState({ stage, user });
  };

  fromStart = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_FROM_START",
      "회원가입_재개_처음부터",
      {}
    );
    clearGlobal(GlobalKey.REGISTRATION_PROGRESS, true);
    clearGlobal(GlobalKey.REGISTRATION_DATA, true);
  };

  goBack = async (e) => {
    log(LogLevel.UI_ACTION, "Register:goBack", this.state.stage);
    if (this.state.stage == 0) {
      if (!this.props.me) {
        //Kakao Logout
        let windowAny: any = window;
        let os = getGlobal(GlobalKey.OS);
        log(LogLevel.UI_ACTION, "Login:logout", os);
        if (!os || os == "browser") {
          try {
            windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
          } catch (e) {
            log(LogLevel.NONE, e);
          }
          windowAny.Kakao.Auth.logout((result) => {
            log(
              LogLevel.REDUX_USER,
              "users windowAny.Kakao.Auth.logout: DONE",
              result
            );
          });
        } else {
          await windowAny.KakaoCordovaSDK.logout();
        }
      }

      this.props.history.goBack();
    } else {
      this.setState({ stage: this.state.stage - 1 });
    }
  };

  componentWillUnmount() {
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    log(
      LogLevel.UI_EVENT,
      "Register:componentWillUnmount backbutton unregister"
    );

    // document.removeEventListener("backbutton", this.goBack);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
  }

  renderView() {
    log(LogLevel.UI_LIFECYCLE, "Register:render stage ", this.state.stage);
    switch (this.state.stage) {
      case 0:
        log(LogLevel.UI_LIFECYCLE, "Register:render case ", this.state.stage);
        // return <RegisterStage3 user={this.state.user} onDone={this.onPhase4Callback} onBack={this.onPhase3BackCallback}/>
        return <RegisterStage0 onSelected={this.onPhase0Callback} />;
      case 1:
        return (
          <RegisterStage1
            onDone={this.onPhase1Callback}
            user={this.state.user}
            onBack={this.onPhase1BackCallback}
          />
        );
      case 2:
        log(LogLevel.UI_LIFECYCLE, "Register:render case ", this.state.stage);
        return (
          <RegisterStage2
            private={this.props.private}
            user={this.state.user}
            onDone={this.onPhase2Callback}
            onAddressSearch={this.onAddressSearch}
            onBack={this.onPhase2BackCallback}
          />
        );
      case 21:
        return (
          <RegisterStage2a
            private={this.props.private}
            user={this.state.user}
            onDone={this.onPhase2aCallback}
            onBack={this.onPhase2aBackCallback}
          />
        );
      case 3:
        return (
          <RegisterStage3
            private={this.props.private}
            user={this.state.user}
            onDone={this.onPhase3Callback}
            onBack={this.onPhase3BackCallback}
          />
        );
      case 31:
        return (
          <RegisterStage3a
            user={this.state.user}
            onDone={this.onPhase3aCallback}
            onBack={this.onPhase3aBackCallback}
          />
        );
      case 4:
        return (
          <RegisterStage4
            private={this.props.private}
            user={this.state.user}
            onDone={this.onPhase4Callback}
            onBack={this.onPhase4BackCallback}
          />
        );
      case 12:
        return (
          <RegisterStage12
            private={this.props.private}
            user={this.state.user}
            onDone={this.onPhase2Callback}
            onBack={this.onPhase2BackCallback}
          />
        );
      case 20:
        let type = "manual";
        if (this.state.user.workType < 3) {
          type = "pharmacy";
        }
        return (
          <SearchAddress
            onSelected={this.onSearchAddressCallback}
            onCanceled={this.onSearchAddressCancel}
            searchType={type}
          />
        );
      default:
        log(
          LogLevel.UI_LIFECYCLE,
          "Register:render default case ",
          this.state.stage
        );
        return <RegisterStage0 onSelected={this.onPhase0Callback} />;
    }
    return null;
  }

  render() {
    if (getGlobal(GlobalKey.OS, true) == "browser" || getDevice() == "iPad")
      return (
        <div className="common-content common-flex-row common-flex-align-center">
          <div className="common-content common-container-column common-flex-align-center">
            <div className="common-flex-grow" />
            <div className="common-small-outer-container common-scroll">
              {this.renderView()}
            </div>
            <div className="common-flex-grow" />
          </div>
        </div>
      );
    else {
      return this.renderView();
    }
  }

  onPhase0Callback = (isStudent: boolean) => {
    log(LogLevel.UI_ACTION, "onPhase0Callback isStudent ", isStudent);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_2_DONE",
      "회원가입_2_회원구분완료",
      {
        소요시간: timerLap(),
        직업: isStudent ? "학생" : "약사",
      }
    );
    // //windowAny.FS.event('회원가입_2_회원구분완료', {});
    let user: UserInfo = { ...this.state.user };
    if (isStudent) user.workType = UserWorkType.UNDERGRADUATE;

    this.setState({ stage: 4, user });
  };

  onPhase1Callback = (data: UserInfo) => {
    log(
      LogLevel.UI_ACTION,
      "onPhase1Callback ",
      data.name,
      data.phone,
      data.birthGender,
      data.gender,
      data.birthday
    );
    let user: UserInfo = { ...this.state.user };
    user.name = data.name;
    user.phone = data.phone;
    user.birthGender = data.birthGender;
    user.gender = data.gender;
    user.birthday = data.birthday;
    user.uniqueKey = data.uniqueKey;

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_3_DONE",
      "회원가입_3_실명인증완료",
      {
        소요시간: timerLap(),
      }
    );
    //windowAny.FS.event('회원가입_3_실명인증완료', {"소요시간":timerLap()});
    // if (this.state.isStudent) this.setState({ stage: 12, user: user });
    // else
    if (this.state.user.workType == UserWorkType.UNDERGRADUATE)
      this.setState({ stage: 12, user: user });
    else this.setState({ stage: 2, user: user });

    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 2, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase1BackCallback = () => {
    log(LogLevel.UI_ACTION, "onPhase2BackCallback ");
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_3_CANCEL",
      "회원가입_3_실명인증취소",
      {
        소요시간: timerLap(),
      }
    );
    //windowAny.FS.event('회원가입_3_실명인증취소', {});
    this.setState({ stage: 4 });
  };

  onPhase2Callback = (data: UserInfo) => {
    log(LogLevel.UI_ACTION, "onPhase2Callback ", data);
    let user: UserInfo = { ...this.state.user, ...data };

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_4_DONE",
      "회원가입_4_개인정보입력완료",
      {
        소요시간: timerLap(),
        근무정보: UserWorkTypeName[user.workType],
      }
    );
    //windowAny.FS.event('회원가입_4_개인정보입력완료', {});
    if (user.workType == UserWorkType.UNDERGRADUATE)
      this.setState({ stage: 3, user: user });
    else this.setState({ stage: 21, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 21, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase2aCallback = (data: any) => {
    log(LogLevel.UI_ACTION, "onPhase2Callback ", data);
    // email: this.state.email,
    // workPlace: this.state.workPlace,
    // workPlaceId: this.state.workPlaceId,
    // workType: this.state.workType,
    // license: this.state.license,
    // licenseImage: this.state.licenseImage,
    let user: UserInfo = { ...this.state.user };
    // user.license= data.license,
    // user.workPlaceAddress= data.workPlace,
    // user.workPlaceId= data.workPlaceId,
    // user.workPlaceId= data.workPlaceId,
    // user.workPlaceAddress= data.workPlaceAddress,
    // user.workPlaceRegion= data.workPlaceRegion,
    // user.workPlaceName= data.workPlaceName,
    // user.workPlacePhone= data.workPlacePhone,
    (user.licenseImageBase64 = data.licenseImageBase64),
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "RESTRATION_4_1_DONE",
        "회원가입_4-1_면허정보입력완료",
        {
          소요시간: timerLap(),
        }
      );
    //windowAny.FS.event('회원가입_4-1_면허정보입력완료', {});
    this.setState({ stage: 3, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 3, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onSearchAddressCallback = (
    address: string,
    region: string,
    name: string,
    phone: string,
    position: any
  ) => {
    log(
      LogLevel.UI_ACTION,
      "onSearchAddressCallback ",
      address,
      region,
      name,
      phone,
      position
    );
    let user: UserInfo = { ...this.state.user };
    user.workPlaceAddress = address;
    user.workPlaceRegion = region;
    user.workPlaceName = name;
    user.workPlacePhone = phone;
    user.workPlacePosition = position;
    if (this.state.user.workType == UserWorkType.UNDERGRADUATE)
      this.setState({ stage: 12, user: user });
    else this.setState({ stage: 2, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 2, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onSearchAddressCancel = () => {
    if (this.state.user.workType == UserWorkType.UNDERGRADUATE)
      this.setState({ stage: 12 });
    else this.setState({ stage: 2 });
  };

  onAddressSearch = (data: any) => {
    let user: UserInfo = { ...this.state.user };
    // user.email= data.email,
    // user.workPlaceAddress= data.workPlace,
    // user.workPlaceId= data.workPlaceId,
    // user.workPlaceId= data.workPlaceId,
    user.email = data.email;
    user.license = data.license;
    user.workType = data.workType;
    // user.workPlaceAddress= data.workPlaceAddress,
    // user.workPlaceRegion= data.workPlaceRegion,
    // user.workPlaceName= data.workPlaceName,
    // user.workPlacePhone= data.workPlacePhone,
    // user.workType= data.workType,
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_4_WORK_DONE",
      "회원가입_4_근무지입력완료",
      {
        소요시간: timerStop(TimerId.TIMER_SUB),
      }
    );
    //windowAny.FS.event("회원가입_4_근무지입력완료", {});
    this.setState({ stage: 20, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 2, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase2BackCallback = (data: any) => {
    log(LogLevel.UI_ACTION, "onPhase2BackCallback ", data);
    let user: UserInfo = { ...this.state.user };
    (user.email = data.email),
      (user.license = data.license),
      // user.workPlaceId= data.workPlaceId,
      (user.workType = data.workType),
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "RESTRATION_4_CANCEL",
        "회원가입_4_개인정보입력취소",
        {
          소요시간: timerLap(),
        }
      );
    //windowAny.FS.event('회원가입_4_개인정보입력취소', {});
    this.setState({ stage: 1, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 2, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase2aBackCallback = (data: any) => {
    log(LogLevel.UI_ACTION, "onPhase2BackCallback ", data);
    let user: UserInfo = { ...this.state.user };
    // user.license= data.license,
    (user.licenseImageBase64 = data.licenseImageBase64),
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "RESTRATION_4_1_CANCEL",
        "회원가입_4-1_면허정보입력취소",
        {
          소요시간: timerLap(),
        }
      );
    //windowAny.FS.event('회원가입_4_개인정보입력취소', {});
    this.setState({
      stage: this.state.user.workType == UserWorkType.UNDERGRADUATE ? 12 : 2,
      user: user,
    });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 2, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase3Callback = (data: UserInfo) => {
    log(LogLevel.UI_ACTION, "onPhase2Callback ", data);
    let user: UserInfo = { ...this.state.user };
    user.nickname = data.nickname;
    user.profileImageBase64 = data.profileImageBase64;
    user.description = data.description;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_5_DONE",
      "회원가입_5_프로필정보입력완료",
      {
        소요시간: timerLap(),
      }
    );
    //windowAny.FS.event('회원가입_5_프로필정보입력완료', {});
    this.setState({ stage: 31, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 3, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase3BackCallback = (data: UserInfo) => {
    log(LogLevel.UI_ACTION, "onPhase3BackCallback ", data);
    let user: UserInfo = { ...this.state.user };
    user.nickname = data.nickname;
    user.profileImageBase64 = data.profileImageBase64;
    user.description = data.description;

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_5_CANCEL",
      "회원가입_5_프로필정보입력취소",
      {
        소요시간: timerLap(),
      }
    );
    //windowAny.FS.event('회원가입_5_프로필정보입력취소', {});
    if (this.state.user.workType == UserWorkType.UNDERGRADUATE)
      this.setState({ stage: 12, user: user });
    else this.setState({ stage: 21, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 3, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase3aCallback = (data: UserInfo) => {
    log(LogLevel.UI_ACTION, "onPhase2aCallback ", data);
    let user: UserInfo = { ...this.state.user, ...data };
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_5_2_DONE",
      "회원가입_5-2_설문조사입력완료",
      {
        소요시간: timerLap(),
        유입경로: data.registerRoute,
        추천인: data.registerInvitor,
      }
    );
    //windowAny.FS.event('회원가입_5-2_설문조사입력완료', {});
    // this.setState({stage: 4, user:user});
    this.finalize(user);
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 31, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  onPhase3aBackCallback = (data: UserInfo) => {
    log(LogLevel.UI_ACTION, "onPhase3aBackCallback ", data);
    let user: UserInfo = { ...this.state.user, ...data };

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_5_2_CANCEL",
      "회원가입_5-2_설문조사입력취소",
      {
        소요시간: timerLap(),
      }
    );
    //windowAny.FS.event('회원가입_5-2_설문조사입력취소', {});
    this.setState({ stage: 3, user: user });
    setGlobal(GlobalKey.REGISTRATION_PROGRESS, 3, true);
    setGlobal(GlobalKey.REGISTRATION_DATA, user, true);
  };

  finalize = async (user: UserInfo) => {
    if (this.holding) return;
    this.holding = true;
    this.props.waitingPopup.show();
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_DONE",
      "회원가입_최종신청" + user.name ? "" : "_오류",
      {
        소요시간: timerLap(),
        snsType: user.snsType,
        snsId: user.snsId,
        name: user.name,
      }
    );

    let token = "";

    if (this.props.private) {
      this.props.waitingPopup.setLabel("사용자 업데이트 중...");

      try {
        token = getGlobal(GlobalKey.TOKEN);
        let newUser = { ...user };
        newUser.level = UserLevel.WAITING_USER;
        newUser.licenseImageBase64 = "";
        newUser.profileImageBase64 = "";
        let result = await fetchAPI(
          API.USER_ME_UPDATE,
          "",
          null,
          newUser,
          token
        );
        if (result && !result.error) {
          log(LogLevel.UI_ACTION, "Register Success:", result.data);
          token = result.data.token;
          setGlobal(GlobalKey.TOKEN, token, true);
          this.props.setUserToken(token);
          this.props.loginUser();
        } else {
          log(LogLevel.UI_EXCEPTION, "onDone failed", result);
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    } else {
      this.props.waitingPopup.setLabel("사용자 생성 중...");
      try {
        let newUser = { ...user };
        newUser.licenseImageBase64 = "";
        newUser.profileImageBase64 = "";
        let result = await fetchAPI(API.REGISTER_REQUEST, "", null, newUser);
        if (!result.error && result.data) {
          log(LogLevel.UI_ACTION, "Register Success:", result.data);
          token = result.data.token;
          setGlobal(GlobalKey.TOKEN, token, true);
          this.props.setUserToken(token);
          this.props.loginUser();
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    }

    if (token) {
      let additionalUser: UserInfo = null;

      if (user.profileImageBase64) {
        this.props.waitingPopup.setLabel("프로파일 업로드 중...");
        log(LogLevel.UI_ACTION, "Register 프로파일 이미지:");

        let data: any = { path: "profile" };
        data.base64 = user.profileImageBase64;
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            log(LogLevel.UI_ACTION, "onDone upload : ", result.file);
            if (!additionalUser) additionalUser = {};
            additionalUser.profileUrl = url;
          } else {
            log(LogLevel.UI_EXCEPTION, "onDone failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_EXCEPTION, "onDone : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }

      if (user.licenseImageBase64) {
        this.props.waitingPopup.setLabel("라이선스 업로드 중...");
        log(LogLevel.UI_ACTION, "Register 라이선스 이미지:");

        let data: any = { path: "license" };
        data.base64 = user.licenseImageBase64;
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            log(LogLevel.UI_ACTION, "onDone upload : ", result.file);
            if (!additionalUser) additionalUser = {};
            additionalUser.licenseUrl = url;
          } else {
            log(LogLevel.UI_EXCEPTION, "onDone failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_EXCEPTION, "onDone : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }

      if (additionalUser) {
        try {
          let result = await fetchAPI(
            API.USER_ME_UPDATE,
            "",
            null,
            additionalUser,
            token
          );
          if (result && !result.error) {
            this.props.waitingPopup.hide();
            this.props.confirmPopup.show({
              title: (
                <div className="common-container">
                  <span className="common-color-highlight">
                    가입신청이 완료
                  </span>
                  되었습니다.
                </div>
              ),
              body: (
                <div className="common-container">
                  가입이 승인되면{" "}
                  <span className="common-bold">카카오톡과 앱 알림</span>으로
                  안내드립니다. <br />
                  제출하신 면허증/학생증은 회원가입{" "}
                  <span className="common-bold">승인 즉시 폐기</span> 됩니다.
                </div>
              ),
              onDone: this.onFinalized,
              backDropDismiss: false,
              doneText: "네, 알겠습니다",
            });
          } else {
            log(LogLevel.NONE, "onDone failed", result);
          }
        } catch (error) {
          log(LogLevel.NONE, "onDone : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      } else {
        this.props.waitingPopup.hide();
        this.props.confirmPopup.show({
          title: (
            <div className="common-container">
              <span className="common-color-highlight">가입신청이 완료</span>
              되었습니다.
            </div>
          ),
          body: (
            <div className="common-container">
              가입이 승인되면 <span className="common-bold">앱 알림</span>으로
              안내드립니다. <br />
              제출하신 면허증/학생증은 회원가입{" "}
              <span className="common-bold">승인 즉시 폐기</span> 됩니다.
            </div>
          ),
          onDone: this.onFinalized,
          doneText: "네, 알겠습니다",
          backDropDismiss: false,
        });
      }
    }
  };

  onFinalized = (text: string) => {
    log(LogLevel.NONE, "onFinalized popup");
    this.holding = true;
    // this.popupFinalize.hide();
    clearGlobal(GlobalKey.REGISTRATION_PROGRESS, true);
    clearGlobal(GlobalKey.REGISTRATION_DATA, true);
    setTimeout(() => {
      this.props.history.replace("/");
    }, 300);
  };

  onPhase4Callback = (data: any) => {
    log(LogLevel.UI_ACTION, "onPhase2Callback ", data);
    let user: UserInfo = { ...this.state.user, ...data };
    this.setState({ stage: 1, user: user });
    // this.setState({stage: 0});
    //this.props.history.replace("/");
  };

  onPhase4BackCallback = (data: any) => {
    log(LogLevel.UI_ACTION, "onPhase2BackCallback ", data);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "RESTRATION_6_CANCEL",
      "회원가입_6_약관동의취소",
      {
        소요시간: timerLap(),
      }
    );
    this.setState({ stage: 0, user: this.state.user });
  };
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  setUserToken: (token: string) => actions.user.setToken(token),
  loginUser: () => actions.user.login(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
