const LargeTitle = `
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
`

const H1 = `
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`

const H2 = `
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
`

const H3 = `
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`

const H4 = `
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
`

const Headline = `
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
`

const SubHead = `
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
`

const Body1 = `
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
`

const Body2 = `
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`

const Body2Bold = `
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
`

const ButtonL = `
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
`

const ButtonM = `
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`

const ButtonS = `
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
`

const Caption1 = `
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`

const Caption2 = `
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`

const Badge = `
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
`

const Label = `
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
`


export default {
  H1,
  H2,
  H3,
  H4,
  Headline,
  SubHead,
  Body1,
  Body2,
  Body2Bold,
  ButtonL,
  ButtonM,
  ButtonS,
  Caption1,
  Caption2,
  Badge,
  Label,
}
