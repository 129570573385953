import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LogLevel, log } from "../../../utils/LogUtil";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { UserInfo } from "../../../models/Model.User";
import { Flex, Header, Static } from "../../atoms/Layout";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Button from "../../atoms/Button";
import Footer from "../../cell/Footer";
import Body from "../../cell/Body";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import { UserPremiumPurchasedInfo } from "../../../models/Model.User.Premium";
import Spinner from "../../atoms/Spinner";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { on } from "cluster";

export interface PremiumUserPurchaseHistoryProps {
  onGoBack: () => void;
  onViewReceipt: (purchase: UserPremiumPurchasedInfo) => void;
  onInquiry: (purchase: UserPremiumPurchasedInfo) => void;
  loading?: boolean;
  purchaseList?: UserPremiumPurchasedInfo[];
}

const PremiumUserPurchaseHistory: React.FC<PremiumUserPurchaseHistoryProps> = ({
  onGoBack,
  loading,
  purchaseList,
  onInquiry,
  onViewReceipt,
}) => {
  useEffect(() => {
    log(LogLevel.UI_ACTION, "PremiumUserPurchaseHistory:useEffect mount");
  }, []);

  return (
    // <div className="common-content common-position-relative">
    //   <Header>
    //     <TopAppBar
    //       titleType="H4"
    //       title="후원 내역"
    //       leftButtons={[
    //         <Button
    //           color="Tertiary"
    //           icon="CaretLeft"
    //           onClick={onGoBack}
    //           size="Medium"
    //           type="Icon"
    //           variant="Ghost"
    //         />,
    //       ]}
    //       rightButtons={[]}
    //     />
    //   </Header>
    <Body>
      <Static customStyle={{ padding: "20px" }}>
        {loading
          ? () => <Spinner />
          : purchaseList.map((item, index) => {
              return (
                <Flex
                  key={index.toString()}
                  direction="column"
                  gap="12px"
                  customStyle={{ marginBottom: "28px" }}
                >
                  <Text
                    textType="Body2Medium"
                    color={COLOR_SYSTEM.get("Gray")[600]}
                  >
                    {getDateStringFromToday({
                      date: item.paidAt,
                      separator: ".",
                    })}
                  </Text>
                  <Flex justifyContent="space-between">
                    <Text
                      textType="Body1Medium"
                      color={COLOR_SYSTEM.get("Gray")[900]}
                    >
                      약문약답 정기 후원
                    </Text>
                    <Text
                      textType="Body1Medium"
                      color={COLOR_SYSTEM.get("Gray")[900]}
                    >
                      {item.payAmount.toLocaleString()}원
                    </Text>
                  </Flex>
                  <Flex gap="8px">
                    <Button
                      color="Secondary"
                      size="Small"
                      type="Text"
                      variant="Outlined"
                      style={{ flex: 1 }}
                      onClick={() => onInquiry(item)}
                    >
                      문의하기
                    </Button>
                    <Button
                      color="Secondary"
                      size="Small"
                      type="Text"
                      variant="Outlined"
                      style={{ flex: 1 }}
                      onClick={() => onViewReceipt(item)}
                    >
                      영수증 보기
                    </Button>
                  </Flex>
                </Flex>
              );
            })}
      </Static>
    </Body>
    // </div>
  );
};

export default PremiumUserPurchaseHistory;
