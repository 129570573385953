import { ReactElement, ReactNode } from "react";
import { ButtonUsage, ButtonVariant } from "../../components/atoms/Button";
import { Typography } from "../../components/design/typography";

const SHOW_CONFIRM = "confirm/show";
const HIDE_CONFIRM = "confirm/hide";
const SET_RESOLVE = "confirm/set_resolve";

type ConfirmIconUIProps = {
  name: string;
  width: number;
  height: number;
  color: string;
};

type ConfirmButtonUIProps = {
  text: string;
  color?: ButtonUsage;
  variant?: ButtonVariant;
};

export interface ConfirmButton {
  cancel?: ConfirmButtonUIProps;
  confirm: ConfirmButtonUIProps;
}

export interface ConfirmWarning {
  title?: string;
  descriptions?: string[];
}

export interface ConfirmUIOptions {
  width?: string;
  icon?: ConfirmIconUIProps | null;
  img?: ReactNode;
  title: string;
  description?: ReactNode;
  descriptionType?: Typography | null;
  warning?: ConfirmWarning;
  button: ConfirmButton;
}

export const showConfirm = (options: Partial<ConfirmUIOptions>) => ({
  type: SHOW_CONFIRM,
  payload: {
    ...options,
  },
});

export const hideConfirm = () => ({
  type: HIDE_CONFIRM,
});

export const setResolveCallback = (res: (value: unknown) => void) => ({
  type: SET_RESOLVE,
  payload: {
    resolveCallback: res,
  },
});

type UnknownFn = (...args: any) => any;

export interface ConfirmState {
  show: boolean;
  width?: string;
  title: string;
  icon?: ConfirmIconUIProps | null;
  img?: any;
  description: string;
  descriptionType?: Typography | null;
  warning: ConfirmWarning | null;
  button: ConfirmButton;
  resolveCallback: UnknownFn | null;
}

const initialState: ConfirmState = {
  width: "",
  icon: null,
  img: null,
  title: "",
  description: "",
  descriptionType: null,
  warning: null,
  button: {
    cancel: {
      text: "",
      color: "Secondary",
    },
    confirm: {
      text: "",
      color: "Primary",
    },
  },
  show: false,
  resolveCallback: null,
};

export interface ConfirmAction {
  type: string;
  payload: Partial<ConfirmState>;
}

const reducer = (state = initialState, action: ConfirmAction) => {
  const { type } = action;

  switch (type) {
    case SHOW_CONFIRM: {
      const { width = "", img = null, icon = null, title, description,descriptionType=null, warning = null, button } = action.payload;
      return {
        ...state,
        width,
        img,
        icon,
        title,
        description,
        descriptionType,
        warning,
        button,
        show: true,
      };
    }
    case HIDE_CONFIRM:
      return {
        ...state,
        ...initialState,
      };
    case SET_RESOLVE:
      return {
        ...state,
        resolveCallback: action.payload.resolveCallback,
      };
    default:
      return state;
  }
};

export default reducer;
