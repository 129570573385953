import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

const SelectorListMain = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px 24px 0;
  background: ${COLOR_SYSTEM.get("Gray")[0]};
  /* text-align: ${({ page }) => (page === "Ask" ? "center" : "left")}; */
  /* ${({ page }) => {
    if (page === "Finish") {
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
      `;
    }
  }} */
`;

const IsSuccessSelectorList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  height: calc(100% - 100px);
  overflow-y: scroll;

  & > * + * {
    margin-top: 24px;
  }
`;

const IsSuccessSelectorItem = styled.li`
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  display: flex;
  align-items: center;
  cursor: pointer;

  & > * + * {
    margin-left: 16px;
  }

  img {
    width: 32px;
  }
`;

const FinishMain = styled.div`
  background: ${COLOR_SYSTEM.get("Gray")[0]};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -85%);
  text-align: center;

  & > * + * {
    margin-top: 20px;
  }
`;

const FinishFooter = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 0 24px 8px;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

export { SelectorListMain, IsSuccessSelectorList, IsSuccessSelectorItem, FinishMain, FinishFooter };
