import React from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { OrderInfo } from '../../../models/Model.Sale'
import SaleOrderListItem from '../../organism/SaleOrderListItem'
import { IonSpinner } from '@ionic/react'
import Text from './../../atom/Text'
import Icon from './../../atom/Icon'
import COLOR from './../../_design/color'

interface Props {
  orders:any,
  orderList:OrderInfo[],
  loading?:boolean,
  onClickDetail?: (order:OrderInfo) => void
  onClickButtonCancel?: (order:OrderInfo) => void
  onClickButtonReturn?: (order:OrderInfo) => void
  onClickButtonInquiry?: (order:OrderInfo) => void
  onClickButtonTracking?: (order:OrderInfo) => void
  className?: string
}
const SaleOrderList: FC<Props> = ({
  orders,
  orderList,
  loading=false,
  onClickDetail,
  onClickButtonCancel,
  onClickButtonReturn,
  onClickButtonInquiry,
  onClickButtonTracking,
  className="",
}) => {
  if(!orderList || !orderList.length){
    return (
      <Container className={className}>
          <div className="common-container-column common-flex-align-center">
            <div style={{marginTop:"60px", marginBottom:"60px"}}>
            <Icon width={80} height={80} fill={COLOR.Gray6} name="Comment"/>
          </div>
          <Text color="Gray5" type="H3">주문 내역이 없습니다.</Text>
        </div>
      </Container>
    )
  }
  return (
    <Container className={className}>
      {orderList.map((order, index)=>{
        return(
          <SaleOrderListItem 
            key={index.toString()} 
            order={orders[order.id]}
            onClickDetail={onClickDetail}
            onClickButtonCancel={onClickButtonCancel}
            onClickButtonReturn={onClickButtonReturn}
            onClickButtonInquiry={onClickButtonInquiry}
            onClickButtonTracking={onClickButtonTracking}
          />
        )
      })}
      {loading && (
          <IonSpinner name="crescent"/>
      )}
    </Container>
  )
}

const Container: StyledComponent<'div', FC> = styled.div`
  
`

export default SaleOrderList
