import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "./../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import Text from "../../../components/atoms/Text";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import { UserWorkType } from "../../../models/Model.User";
import * as API from "../../../API.json";
import DownloadUtil from "../../../utils/DownloadUtil";
import { JobOfferName } from "../../../models/Model.JobPost";

const AdminAPI = {
  USER_LIST: {
    method: "GET",
    path: "/admin/user/users",
    contentType: "application/json",
  },
};

const USER_ROW_CNT = 30;

const DP_MESSAGE = `안녕하세요 약사님,\n약문약답 앱 운영진 JR입니다.\n\n집 주변의 약국에서 구인공고를 등록하면 카톡으로 알려드리는 ⏰ 알림 기능을 안 쓰고 계셔서 설정방법을 알려드리고자 연락드렸습니다. \n\n알림기능 설정방법은 아래와 같습니다.\n\n1. 아래 링크를 통해 앱 설정페이지 진입\nhttps://app.stg.ymyd.onjourney.co.kr/jobpost/setting\n2. 화면 하단의 "구인 알림 수신" ON\n3. 알림옵션 설정\n4. 희망거리 설정\n\n구인공고 알림 기능이 약사님께 도움되기를 바랍니다 ^^ \n\n앱을 쓰다가 궁금한 점이 있으시면 언제든 물어봐주세요.\n감사합니다.\n\n※ 약문약답에 만족하시면 지인 약사님들께 추천부탁드립니다 😁`;
const MESSAGE = `안녕하세요 #{name} 약사님,\n약문약답 서비스 운영진입니다.`;
type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (user: UserInfo) => void;
    level?: UserLevel;
    onClose?: () => void;
    me?: UserInfo;
  };

type State = {
  search: string;
  level: UserLevel;
  users: UserInfo[];
  statistics: any;
};

class UserSelect extends Component<Props, State> {
  finished = false;
  loading = false;
  userList = null;

  state = {
    search: "",
    level: this.props.level || UserLevel.WAITING_USER,
    users: [],
    statistics: null,
    selected: null,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "UserSelect:onKeyPress", e.keyCode, e.shiftKey);
    if (e.keyCode == 13) {
      this.fetchData();
      e.preventDefault();
    }
  };

  onScroll = (event) => {
    let target: any = event.target;
    // log(LogLevel.UI_EVENT, "BoardList:scroll", event.detail.scrollTop, target.clientHeight, this.contentList.clientHeight);
    if (
      !this.loading &&
      target.scrollTop + target.clientHeight > this.userList.clientHeight * 0.9
    ) {
      log(
        LogLevel.UI_EVENT,
        "UserSelect:onScroll",
        target.scrollTop,
        target.clientHeight,
        this.userList.clientHeight
      );
      this.fetchDataMore();
    }
  };

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({ search: keywords });
  };

  onLevelChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let level: UserLevel = parseInt(e.detail.value);
    this.changeLevel(level);
  };

  changeLevel = (level: UserLevel) => {
    this.setState({ level: level });
    setTimeout(() => {
      this.fetchData();
    }, 100);
  };

  refresh = () => {
    this.fetchData();
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:UserSelect:fetchData");
    this.loading = true;
    fetchAPI(
      AdminAPI.USER_LIST,
      "",
      {
        start: 0,
        count: USER_ROW_CNT,
        query: 0,
        level: this.state.level,
        search: this.state.search,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(LogLevel.UI_DATA_LOAD, "Admin:UserSelect:fetchData result", result);
        if (result.level == this.state.level) {
          if (result.statistics) {
            this.setState({ statistics: result.statistics });
          }
          this.finished = false;
          if (result.data && result.data.length < USER_ROW_CNT)
            this.finished = true;

          this.setState({ users: result.data });
          this.loading = false;
        }
      } else {
        log(LogLevel.UI_DATA_LOAD, "Admin:UserSelect:fetchData result", result);
        if (result && result.level == this.state.level)
          if (result.statistics) {
            this.setState({ statistics: result.statistics });
          }
        this.finished = true;
        this.setState({ users: [] });
      }
    });
  };

  fetchDataMore = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:UserSelect:fetchDataMore", this.finished);
    this.loading = true;
    if (!this.finished) {
      fetchAPI(
        AdminAPI.USER_LIST,
        "",
        {
          start: this.state.users.length,
          count: USER_ROW_CNT,
          query: 0,
          level: this.state.level,
          search: this.state.search,
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:UserSelect:fetchData result",
            result
          );
          if (result.level == this.state.level) {
            if (result.statistics) {
              this.setState({ statistics: result.statistics });
            }
            this.finished = false;
            if (result.data && result.data.length < USER_ROW_CNT)
              this.finished = true;

            this.setState({ users: [...this.state.users, ...result.data] });
            this.loading = false;
          }
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:UserSelect:fetchData result",
            result
          );
          if (result.level == this.state.level) this.finished = true;
          this.loading = false;
        }
      });
    }
  };

  clearSearch = () => {
    this.setState({ search: "" });
  };

  onSelect = (user: UserInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", user);
    this.props.onDone(user);
  };

  onChange = () => {
    this.fetchData();
  };

  download = async () => {
    this.props.waitingPopup.show();
    try {
      this.props.waitingPopup.setLabel("조회 이력 저장 중...");

      fetchAPI(
        API.USER_CTA_RECORD,
        "",
        null,
        {
          userId: this.props.me.id,
          gsid: "1oTy-ntmWLvJZB62Pzfrg2BmXIkpWA0Ac3pPaMFHW-qQ",
          gstid: "약문약답유저Download", // google sheet tab name
        },
        getGlobal(GlobalKey.TOKEN)
      );

      let text =
        "ID\t이름\tNickname\t면허번호\tEmail\t전화번호\tbirthGender\t근무유형\t근무지\t근무지주소\t프리미엄\t면허취득년\t마케팅동의여부\t구인알림설정\n";

      let page = 0;
      let count = 1000;
      while (true) {
        this.props.waitingPopup.setLabel(
          `사용자 다운로드 중... ${count * page}건`
        );
        let result = await fetchAPI(
          AdminAPI.USER_LIST,
          "",
          {
            start: page * count,
            count: count,
            query: 0,
            level: this.state.level,
            search: this.state.search,
          },
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error && result.data && result.data.length > 0) {
          result.data.map((item: UserInfo) => {
            text += `${item.id}\t${item.name}\t${item.nickname}\t${
              item.license
            }\t${item.email}\t${item.phone}\t${item.birthGender}\t${
              UserWorkTypeName[item.workType]
            }\t${item.workPlaceName}\t${item.workPlaceAddress}\t${
              item.premiumType ? "O" : "X"
            }\t${item.licensedAt}\t${item.agreeMarketingAt ? "O" : "X"}\t${
              item.jobpostStatus && item.jobpostStatus.startsWith("+")
                ? `${JobOfferName[item.jobpostStatus]}(${item.jobpostDistance})`
                : "-"
            }\n`;
          });

          if (result.data.length < count) {
            break;
          }
          page++;
        } else {
          break;
        }
      }
      this.props.waitingPopup.setLabel(
        `사용자 파일 저장 중... ${count * page}건`
      );
      DownloadUtil.downloadCsv(
        text,
        `Users-${UserLevelName[this.state.level]}-${
          this.state.search
        }-${new Date().toISOString().substring(0, 10).replace(/-/gi, "")}.csv`
      );
    } catch (e) {
      alert("다운로드실패");
      log(LogLevel.UI_EXCEPTION, "Admin:UserSelect:download", e);
    }

    this.props.waitingPopup.hide();
  };

  onDownload = () => {
    this.props.confirmPopup.show({
      iconImage: null,
      title: (
        <div className="common-container">
          <span className="common-bold">
            회원정보 다운로드에{" "}
            <span className="common-bold common-color-caution">
              주의를 기울이십시오.
            </span>
          </span>
        </div>
      ),
      body: (
        <div className="common-container">
          회원정보 다운로드 받는 기록이 서버에 남습니다.
          <br />
          사용된 정보는 반드시 삭제하시고, <br /> 파일의 보안에 철저히
          신경쓰세요.
        </div>
      ),
      onDone: () => {
        this.download();
      },
      doneText: "네",
    });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "Admin:UserSelect render", this.state);
    return (
      <div className="admin-container" onScroll={this.onScroll}>
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  사용자선택
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="user-search-container">
          <IonInput
            class="user-search-input"
            placeholder="검색"
            value={this.state.search}
            onIonChange={this.onSearchChange}
            onKeyDown={this.onKeyPress}
          />
          <IonButton fill="clear" color="primary" onClick={this.clearSearch}>
            <IonIcon name="close" />
          </IonButton>
          {this.props.me && this.props.me.level >= UserLevel.ADMIN && (
            <IonButton color="primary" onClick={this.onDownload}>
              다운로드
            </IonButton>
          )}
        </div>
        <div className="register2-work-type-container">
          <div
            className={
              this.state.level == UserLevel.ALL
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.ALL)}
          >
            {UserLevelName[UserLevel.ALL]}{" "}
            {this.state.statistics && this.state.statistics[UserLevel.ALL]
              ? this.state.statistics[UserLevel.ALL]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.WAITING_USER
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.WAITING_USER)}
          >
            {UserLevelName[UserLevel.WAITING_USER]}{" "}
            {this.state.statistics &&
            this.state.statistics[UserLevel.WAITING_USER]
              ? this.state.statistics[UserLevel.WAITING_USER]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.LICENSE_CHECK
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.LICENSE_CHECK)}
          >
            {UserLevelName[UserLevel.LICENSE_CHECK]}{" "}
            {this.state.statistics &&
            this.state.statistics[UserLevel.LICENSE_CHECK]
              ? this.state.statistics[UserLevel.LICENSE_CHECK]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.DENIED_USER
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.DENIED_USER)}
          >
            {UserLevelName[UserLevel.DENIED_USER]}{" "}
            {this.state.statistics &&
            this.state.statistics[UserLevel.DENIED_USER]
              ? this.state.statistics[UserLevel.DENIED_USER]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.NORMAL_USER
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.NORMAL_USER)}
          >
            {UserLevelName[UserLevel.NORMAL_USER]}{" "}
            {this.state.statistics &&
            this.state.statistics[UserLevel.NORMAL_USER]
              ? this.state.statistics[UserLevel.NORMAL_USER]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.USER_PHARMACIST
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.USER_PHARMACIST)}
          >
            {UserLevelName[UserLevel.USER_PHARMACIST]}{" "}
            {this.state.statistics &&
            this.state.statistics[UserLevel.USER_PHARMACIST]
              ? this.state.statistics[UserLevel.USER_PHARMACIST]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.USER_UNDERGRADUATE
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.USER_UNDERGRADUATE)}
          >
            {UserLevelName[UserLevel.USER_UNDERGRADUATE]}{" "}
            {this.state.statistics &&
            this.state.statistics[UserLevel.USER_UNDERGRADUATE]
              ? this.state.statistics[UserLevel.USER_UNDERGRADUATE]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.MANAGER
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.MANAGER)}
          >
            {UserLevelName[UserLevel.MANAGER]}{" "}
            {this.state.statistics && this.state.statistics[UserLevel.MANAGER]
              ? this.state.statistics[UserLevel.MANAGER]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.ADMIN
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.ADMIN)}
          >
            {UserLevelName[UserLevel.ADMIN]}{" "}
            {this.state.statistics && this.state.statistics[UserLevel.ADMIN]
              ? this.state.statistics[UserLevel.ADMIN]
              : 0}
          </div>
          <div
            className={
              this.state.level == UserLevel.DELETED
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeLevel(UserLevel.DELETED)}
          >
            {UserLevelName[UserLevel.DELETED]}
          </div>
        </div>
        <div className="user-admin-list-container">
          <IonList
            ref={(ref) => {
              this.userList = ref;
            }}
          >
            {this.state.users.map((item: UserInfo, index: number) => {
              let route = [];
              if (item.registerRoute) {
                if (item.registerRoute.includes(RegisterRoute.DP_RECRUIT))
                  route.push(
                    <div key="8" className="admin-toggle common-color-caution">
                      {REGISTER_ROUTE_NAME[RegisterRoute.DP_RECRUIT]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.KAKAOTALK))
                  route.push(
                    <div key="1" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.KAKAOTALK]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.EXPO))
                  route.push(
                    <div key="2" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.EXPO]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.NEWS))
                  route.push(
                    <div key="3" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.NEWS]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.CONTENT))
                  route.push(
                    <div key="4" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.CONTENT]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.INVITE))
                  route.push(
                    <div key="5" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.INVITE]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.FACEBOOK))
                  route.push(
                    <div key="6" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.FACEBOOK]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.YSGL))
                  route.push(
                    <div key="7" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.YSGL]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.BAROPHARM))
                  route.push(
                    <div key="9" className="admin-toggle">
                      {REGISTER_ROUTE_NAME[RegisterRoute.BAROPHARM]}
                    </div>
                  );
                if (item.registerRoute.includes(RegisterRoute.ETC))
                  route.push(
                    <div key="10" className="admin-toggle">
                      {item.registerRouteEtc}
                    </div>
                  );
              }

              return (
                <div
                  key={index.toString()}
                  className="common-container-row common-flex-align-center"
                  onClick={() => this.onSelect(item)}
                >
                  <ProfileImage
                    className="user-admin-list-item-profile"
                    profileUrl={item.profileUrl}
                  />
                  <div className="user-admin-list-item-container common-flex-grow">
                    <div className="common-flex-row common-flex-align-center">
                      {route}
                    </div>
                    <div>
                      ID{item.id}{" "}
                      {item.premiumType ? (
                        <img
                          src="/images/sprout2.png"
                          style={{
                            width: "12px",
                            height: "15px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      / {item.name}/ {item.nickname} / {item.license} /{" "}
                      {item.phone}
                    </div>
                    <div>
                      <Text
                        textType={
                          item.workType == UserWorkType.UNDERGRADUATE
                            ? "Body2Bold"
                            : "Body2"
                        }
                        color={
                          item.workType == UserWorkType.UNDERGRADUATE
                            ? COLOR_SYSTEM.get("Blue")[400]
                            : COLOR_SYSTEM.get("Gray")[800]
                        }
                      >
                        {UserWorkTypeName[item.workType]}
                      </Text>{" "}
                      / {item.workPlaceRegion} {item.workPlaceName}{" "}
                    </div>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <IconButton
                      size="M"
                      variant="Outlined"
                      color="Primary"
                      iconName="Call"
                      onClick={() => callPhone(item.phone)}
                    />
                  </div>
                  <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <IconButton
                      size="M"
                      variant="Outlined"
                      color="Primary"
                      iconName="Comment"
                      onClick={() =>
                        sendSMSMessage(
                          item.phone,
                          StringUtil.createStringFromTemplate(
                            item.registerRoute.includes(
                              RegisterRoute.DP_RECRUIT
                            )
                              ? DP_MESSAGE
                              : MESSAGE,
                            item
                          )
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
          </IonList>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UserSelect);
