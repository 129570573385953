import React, { useCallback, useEffect } from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import { CompanyType, CompanyTypeName } from "../../../models/Model.User";
import Text from "../../atoms/Text";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import "./../../../Common.css";
import Icon from "../../atoms/Icon";
import Button from "../../atoms/Button";
import { BoardContent } from "../../../models/Model.Board";
import PostListItem from "../../organisms/AllInOne/PostListItem";
import AllinOneHotContentsList from "../../organisms/AllInOne/PartnerHotContentsList";
import { Flex } from "../../atoms/Layout";
import CardView from "../../../component/molecule/CardView";
import { BizUserInfo } from "../../../models/Mode.Biz";
import styled from "styled-components";
import Separator from "../../atoms/Separator";
import {
  BoardListState,
  BoardSortType,
  partnerThumbnail,
} from "../../../store/board/types";
import Avatar from "../../atoms/Avatar";
import Tag from "../../atoms/Tag";
import { useDispatch } from "react-redux";
import useBottomSheet from "../../../hooks/useBottomSheet";
import { getBizBoard, sortBizBoard } from "../../../store/board/actions";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";
import { LogLevel, log } from "../../../utils/LogUtil";

const sortName = [
  "최신순",
  "최신순",
  "예전글 순",
  "좋아요 순",
  "스크랩 순",
  "나도궁금해요 순",
  "조회수 순",
  "답변수 순",
  "댓글수 순",
];

export interface PartnersHomeProps {
  banners: any[];
  partners: any;
  partnerList: any;
  hotList: any;
  newList: any;
  contents: any;
  category: BoardListState;
  categories: any; // [id]: BoardList
  categoryList: CompanyType[];
  onClickIntroduction: () => void;
  onClickPartner: (patner: BizUserInfo) => void;
  onClickPost: (post: BoardContent) => void;
  onClickCategory: (index: number) => void;
  onRecommendPartner: () => void;
  onClickBanner?: (item: any, index: number, position: any) => void;
  onShowBanner: (item: any, index: number) => void;
}

const PartnerHomeWrapper = styled.div`
  padding: 12px 0px 40px 0px;
  background: #fff;

  .partners-banner-cardview {
    --cardview-card-border-radius: 12px;
    --cardview-card-padding: 0px 0px;
  }

  .partners-card-container {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    padding: 24px 0px 24px 24px;
    height: 300px;

    .partners-card-title {
      margin-bottom: 8px;
    }

    .partners-card-description-img {
      position: absolute;
      right: -15px;
      bottom: 0px;
      width: 60%;
    }

    .partners-card-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      position: absolute;
      bottom: 24px;
      left: 24px;
    }
  }

  .partners-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 40px;

    .partners-card-title {
      margin-bottom: 10px;
    }
  }

  .partners-post-container {
    background-color: #fff;
    padding: 20px;
  }

  .partners-suggest-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 12px;
  }

  .partner-content-categories {
    border-radius: 10px;
    object-fit: cover;
    min-width: 60px;
    min-height: 60px;
    transform: translateZ(0);
    z-index: 0;
  }

  .partner-new-content {
    /* border-radius: 10px; */
    object-fit: cover;
    min-width: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    border: 1px solid #e0e0e0;
    transform: translateZ(0);
    z-index: 0;
  }

  .partner-card {
    width: 148px;
    min-width: 148px;
    height: 212px;
    /* border-radius : 10px; */
    padding: 20px;
    border: 1px solid #e0e0e0;
    transform: translateZ(10);
    z-index: 10;
    position: relative;
    overflow: hidden;
    /* background-color: red; */
  }
`;

const PartnersHome: React.FC<PartnersHomeProps & RouteComponentProps> = ({
  categories,
  categoryList,
  category,
  contents,
  hotList,
  newList,
  banners,
  partners,
  partnerList,
  history,
  // contents,
  // selectedCategoryIndex,
  // onClickIntroduction,
  onClickPartner,
  onClickPost,
  onClickBanner,
  onShowBanner,
  onClickCategory,
  onRecommendPartner,
}) => {
  try {
    const dispatch = useDispatch();

    // const boardAttribute: string = useMemo(() => boards[boardList[tab]].attribute, [boards, boardList, tab]);
    const bottomSheet = useBottomSheet();

    const checkSelectedSort = useCallback(
      (sort: BoardSortType) => {
        if (!category.sort && sort === BoardSortType.TIME_DESC) return true;

        return category.sort === sort;
      },
      [category]
    );

    useEffect(() => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARNTERS_MAIN",
        "파트너스메인진입",
        {}
      );
    }, []);

    const handleClickSortItem = (sort: BoardSortType) => () => {
      dispatch(sortBizBoard(category, sort));
      dispatch(getBizBoard(category, 15));
      bottomSheet.close();

      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "BOARD_SORT", "게시판정렬", {
        Sort: BoardSortType[sort],
        게시판이름: category.name,
      });
    };

    const handleClickSort = () => {
      bottomSheet.show({
        title: {
          value: "정렬 기준",
          align: "left",
        },
        body: (
          <BottomSheetList>
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      checkSelectedSort(BoardSortType.TIME_DESC) ? 700 : 400
                    ]
                  }
                >
                  최신순
                </Text>
              }
              right={
                checkSelectedSort(BoardSortType.TIME_DESC) ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem(BoardSortType.TIME_DESC)}
            />
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      checkSelectedSort(BoardSortType.LIKE) ? 700 : 400
                    ]
                  }
                >
                  좋아요 순
                </Text>
              }
              right={
                checkSelectedSort(BoardSortType.LIKE) ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem(BoardSortType.LIKE)}
            />
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      checkSelectedSort(BoardSortType.VIEW) ? 700 : 400
                    ]
                  }
                >
                  조회수 순
                </Text>
              }
              right={
                checkSelectedSort(BoardSortType.VIEW) ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem(BoardSortType.VIEW)}
            />
          </BottomSheetList>
        ),
      });
    };
    return (
      <PartnerHomeWrapper>
        <Flex direction="column" gap="24px" customStyle={{ padding: "24px" }}>
          {banners && banners.length > 0 && (
            <CardView
              className="partners-banner-cardview"
              randomStart={true}
              style="normal"
              infinite={true}
              items={banners}
              indexType="dot"
              imageUrlKey="imageUrl"
              imageSrcKey="imageSrc"
              indexPosition="left"
              autoScrollInterval={10000}
              onClick={onClickBanner}
              onShow={onShowBanner}
            />
          )}
          <Flex direction="column" gap="24px">
            <Flex style={{}}>
              <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
                {" "}
                이번주 많이 보는 콘텐츠
              </Text>
            </Flex>

            <Flex
              gap="24px"
              customStyle={{
                overflowX: "scroll",
                overscrollBehaviorX: "none",
                alignItem: "left",
              }}
            >
              <Flex
                direction="row"
                customStyle={{
                  height: "260px",
                  maxWidth: "100%",
                  display: "flex",
                  flexFlow: "column wrap",
                  gap: "20px 40px",
                }}
              >
                {hotList &&
                  hotList.contents.length > 0 &&
                  hotList.contents.map((content, index) => (
                    <AllinOneHotContentsList
                      content={content}
                      contents={contents}
                      onClick={() => onClickPost(contents[content.id])}
                    />
                  ))}
              </Flex>
            </Flex>
          </Flex>
          <Flex style={{}}>
            <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
              {" "}
              NEW! 새로 나온 콘텐츠
            </Text>
          </Flex>
          <Flex
            gap="10px"
            customStyle={{
              overflowX: "scroll",
              overscrollBehaviorX: "none",
              alignItem: "left",
            }}
          >
            {newList &&
              newList.contents.length > 0 &&
              newList.contents.map((content, index) => (
                <div onClick={() => onClickPost(contents[content.id])}>
                  <Flex
                    direction="column"
                    customStyle={{
                      width: "209px",
                      height: "212px",
                      maxWidth: "100%",
                      borderRadius: "10px",
                      display: "flex",
                    }}
                  >
                    <Flex
                      customStyle={{
                        width: "209px",
                        height: "100px",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="partner-new-content"
                        width="400px"
                        height="100px"
                        src={
                          contents[content.id].image
                            ? contents[content.id].image
                            : partnerThumbnail(
                                contents[content.id].subCategories &&
                                  contents[content.id].subCategories.length > 0
                                  ? contents[content.id].subCategories[0].name
                                  : ""
                              )
                        }
                        onError={(e: any) =>
                          (e.target.src = partnerThumbnail("default_partners"))
                        }
                      />
                    </Flex>
                    <Flex
                      direction="column"
                      customStyle={{
                        width: "209px",
                        height: "100%",
                        border: "1px solid  #e0e0e0",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        padding: "12px",
                        gap: "20px",
                      }}
                    >
                      <Text
                        numberOfLines={2}
                        textType="Body2SemiBold"
                        color={COLOR_SYSTEM.get("Gray")[800]}
                      >
                        {contents[content.id].subject}
                      </Text>
                      <Flex customStyle={{ gap: "4px", alignItems: "center" }}>
                        <Avatar
                          size="XSmall"
                          url={contents[content.id].usersProfileUrl}
                          border={true}
                        />
                        <Text
                          numberOfLines={1}
                          textType="CaptionBold"
                          color={COLOR_SYSTEM.get("Gray")[300]}
                        >
                          {contents[content.id].usersNickname}{" "}
                        </Text>
                        <Tag size="Small" variant="Ghost" color="Blue">
                          {contents[content.id].subCategories &&
                          contents[content.id].subCategories.length > 0
                            ? contents[content.id].subCategories[0].name
                            : ""}
                        </Tag>
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              ))}
          </Flex>

          <Flex style={{}}>
            <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
              {" "}
              추천 파트너
            </Text>
          </Flex>

          <Flex
            direction="row"
            gap="11px"
            customStyle={{
              overflowX: "scroll",
              overscrollBehaviorX: "none",
              alignItem: "left",
              transform: "translateZ(0)",
              zIndex: "0",
            }}
          >
            {partners &&
              Object.keys(partners)
                .slice(0, 5)
                .map((key, index) => (
                  <Flex
                    key={index.toString()}
                    className="partner-card"
                    direction="column"
                    onClick={() => {
                      history.push(`/partners/detail?id=${key}`);
                    }}
                  >
                    <Flex
                      customStyle={{
                        justifyContent: "center",
                        padding: "0px 0px 20px 0px",
                      }}
                    >
                      <Avatar
                        size="XLarge"
                        url={partners[key].logoImageUrl}
                        border={true}
                      />
                    </Flex>
                    <Flex customStyle={{ justifyContent: "center" }}>
                      <Text
                        numberOfLines={1}
                        textType="Body2Bold"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        {partners[key].companyName
                          ? partners[key].companyName
                          : partners[key].companyProfileName}{" "}
                      </Text>
                    </Flex>
                    <Flex
                      customStyle={{
                        justifyContent: "center",
                        padding: "0px 0px 20px 0px",
                      }}
                    >
                      <Tag size="Small" variant="Ghost" color="Blue">
                        {" "}
                        {}
                        {CompanyTypeName[partners[key].companyType]}{" "}
                      </Tag>
                    </Flex>
                    <Flex customStyle={{ height: "36px", width: "100%" }}>
                      <Text
                        numberOfLines={2}
                        textType="Caption"
                        color={COLOR_SYSTEM.get("Gray")[600]}
                      >
                        {partners[key].introductionText
                          ? String(partners[key].introductionText).substring(
                              0,
                              30
                            )
                          : ""}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap="12px"
            customStyle={{
              background: COLOR_SYSTEM.get("Gray")[15],
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <div className="common-flex-grow">
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[900]}>
                약사님께서 직접 이용 후 <br />
                만족스러웠던 업체를 추천해주세요!
              </Text>
            </div>
            <Button
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Tertiary"}
              onClick={onRecommendPartner}
            >
              추천하기
            </Button>
          </Flex>
        </Flex>

        <Separator type="Block" />
        {category && category.contents && category.contents.length > 0 && (
          <div className="partners-post-container">
            <Flex customStyle={{ justifyContent: "right" }}>
              <Button
                color="Secondary"
                size="Small"
                variant="Ghost"
                type="IconWithText"
                icon="Swap Vertical"
                left
                onClick={handleClickSort}
              >
                {category.sort ? sortName[category["sort"]] : "최신순"}
              </Button>
            </Flex>
            {category.contents.map((post, index) => (
              <PostListItem
                key={index.toString()}
                showProfile={true}
                content={contents[post.id]}
                partner={partners[contents[post.id].usersBizId]}
                onClick={() => onClickPost(contents[post.id])}
              />
            ))}
          </div>
        )}
      </PartnerHomeWrapper>
    );
  } catch (error) {
    log(
      LogLevel.UI_EXCEPTION,
      "PartnersHomeTemplate",
      error.message,
      error.stack
    );
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PartnersHomeTemplate",
      errorMessage: error,
    });
  }
};

export default withRouter(PartnersHome);
