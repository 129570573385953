import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import './../../../Common.css'
import { rippleEvent } from '../../_util/RrppleEffect'
import TextToggleButton from '../TextToggleButton'
import typography from './../../_design/typography'

type Type = 'Ghost' | 'Outlined' | 'ContainedS' | 'ContainedL'

const TYPE = {
  Ghost: {
    containerPadding: "4px",
    containerHeight: "32px",
    minWidth: '56px',
    height: '24px',
    borderRadius: 'none',
    padding: '3px 15px',
    typography: 'Caption1',
    border: 'none',
    backgroundColor: COLOR.Transparent,
  },
  Outlined: {
    containerPadding: "4px",
    containerHeight: "32px",
    minWidth: '56px',
    height: '24px',
    borderRadius: '6px',
    padding: '1px 10px 5px 10px',
    typography: 'ButtonS',
    border: `1px solid ${COLOR.Gray6}`,
    backgroundColor: COLOR.Transparent,
  },
  ContainedS: {
    containerPadding: "4px",
    containerHeight: "32px",
    minWidth: '56px',
    height: '24px',
    borderRadius: '36px',
    padding: '3px 15px',
    typography: 'Caption1',
    border: 'none',
    backgroundColor: COLOR.Gray8,
  },
  ContainedL: {
    containerPadding: "4px",
    containerHeight: "47px",
    minWidth: '100px',
    height: '39px',
    borderRadius: '36px',
    padding: '8px 20px',
    typography: 'SubHead',
    border: 'none',
    backgroundColor: COLOR.Gray8,
  },
}

interface WrapperProps {
  type: Type
  block: boolean
}

const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  z-index: 0;
  width: ${(props) => props.block?"100%":"unset"};
  height: ${(props) => TYPE[props.type].containerHeight};
  padding: ${(props) => TYPE[props.type].containerPadding};
  border-radius: ${(props) => TYPE[props.type].borderRadius};
  background-color: ${(props) => TYPE[props.type].backgroundColor};
  display:inline-block;
  position: relative;
  white-space: nowrap;
  align-items: center;
  border: ${(props) => TYPE[props.type].border};

`

interface Props {
  type?: Type
  value?: any
  onClick?: (value:any) => void
  block?: boolean
  values?: any[]
}
const SegmentedControl: React.FC<Props> = ({
  type = 'ContainedS',
  children,
  block = false,
  onClick,
  value,
  values,
}) => {
  return (
    <Wrapper
      type={type}
      block={block}
    >
      {values.map((aValue, index) => {
        return ([
          <SegmentedControlItem key={index.toString()} type={type} value={aValue} checked={value == ((aValue && aValue.value)?aValue.value:aValue)} onClick={onClick}/>,
          ((!type.startsWith("Contained") && index < values.length-1)?<Text key={"d"} type="ButtonS" color="Gray5">|</Text>:null)

        ]);
      })}
    </Wrapper>
  )
}

export default SegmentedControl


interface WrapperItemProps {
  type: Type
  block: boolean
  checked: boolean
}

const WrapperItem: StyledComponent<'button', React.FC, WrapperItemProps> = styled.button<WrapperItemProps>`
  z-index: 0;
  height: ${(props) => TYPE[props.type].height};
  min-width: ${(props) => TYPE[props.type].minWidth};
  width: ${(props) => props.block?"100%":"auto"};
  padding: ${(props) => TYPE[props.type].padding};
  border-radius: ${(props) => TYPE[props.type].borderRadius};
  background-color: ${(props) => (props.checked?COLOR.White:COLOR.Transparent)};
  color: ${(props) => (props.checked?COLOR.Black:COLOR.Gray4)};
  outline: none;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  position: relative;
  ${(props) => typography[TYPE[props.type].typography]};
`

interface ItemProps {
  type: Type,
  checked?: boolean
  onClick?: (value:any) => void
  block?: boolean
  value?: any
}
const SegmentedControlItem: React.FC<ItemProps> = ({
  type = 'ContainedS',
  children,
  checked = false,
  block,
  onClick,
  value,
}) => {
  if(type == "Ghost")
    return (
      <TextToggleButton size="S" variant="Ghost" color="Secondary" checked={checked} value={value} onClick={(e, value) => {if(onClick) onClick(value);}}>{(value && value.name)?value.name:value}</TextToggleButton>
    );
  const el = useRef(null);
  const rippleEl = useRef(null);
  return (
    <WrapperItem
      ref={el} 
      type={type}
      block={block}
      checked = {checked}
      onClick={(e) => {
        if(!checked){
          // rippleEvent(e, el.current, rippleEl.current);
          if(onClick) onClick((value && value.value)?value.value:value);
        }
      }}
    >
      <Text type={TYPE[type].typography}>
        {(value && value.name)?value.name:value}
      </Text>

    </WrapperItem>
  )
}

