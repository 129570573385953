import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonCheckbox,
} from "@ionic/react";
import "./BoardManage.css";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import BoardContentManagePopup from "./component/BoardContentManagePopup";
import BoardContentEdit from "./component/BoardContentEdit";
import { setGlobal, GlobalKey, getGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import { BoardContent } from "../models/Model.Board";
import { fetchAPI } from "../utils/APIUtil";
import Truncate from "react-truncate";
import * as API from "./../API.json";
import { getDateTimeString } from "../utils/TimeUtil";
const queryString = require("query-string");
const AdminAPI = {
  MOVE: { method: "PUT", path: "/admin/board/contents/category", contentType: "application/json" },
};
type Props = RouteComponentProps<{ tab: string }> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

type State = {
  category: number;
  destination: number;
  contents: BoardContent[];
  selects: number[];
  selected: number;
  page: number;
};

class BoardContentMoveManage extends Component<Props, State> {
  popup = null;

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "BoardContentMoveManage:constructor", this.props.location.search);
    let search = "";
    let qs = queryString.parse(this.props.location.search);
    if (qs.id) search = qs.id;

    this.state = {
      category: 1,
      destination: 12,
      contents: [],
      selects: [],
      selected: -1,
      page: getGlobal("intAdminBoardContentMoveManagePage", true),
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  onPageChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let page = Number(e.detail.value);
    this.setState({ page });
  };

  onCategoryChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let category: number = parseInt(e.detail.value);
    this.setState({ category: category });
  };

  onDestinationChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let destination: number = parseInt(e.detail.value);
    this.setState({ destination });
  };

  onSelect = (content: BoardContent) => {
    log(LogLevel.UI_EVENT, "onSelect", content);
    var x = document.getElementById("admin-sub-conainer");
    let selects = [...this.state.selects];
    let itsIndex = selects.indexOf(content.id);
    if (itsIndex == -1) selects.push(content.id);
    else selects.splice(itsIndex, 1);
    this.setState({ selected: content.id, selects });

    log(LogLevel.UI_DATA_LOAD, "onSelect");
  };

  fetchData = async () => {
    setGlobal("intAdminBoardContentMoveManagePage", this.state.page, true);

    let result = await fetchAPI(
      API.BOARD_LIST_REQUEST,
      this.state.category,
      { start: this.state.page, count: 100 },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    console.log(result);
    if (result && !result.error) {
      this.setState({ contents: result.data ? result.data : [], selects: [] });
    } else {
      this.setState({ contents: [], selects: [] });
    }

    if(this.scrollRef)
      this.scrollRef.scrollTo(0,0);
  };

  onMove = async () => {
    if(this.state.category == this.state.destination) return;
    let result = await fetchAPI(
      AdminAPI.MOVE,
      "",
      null,
      { contentIds: this.state.selects, newCategory: this.state.destination },
      getGlobal(GlobalKey.TOKEN)
    );
    this.setState({page: this.state.page + 100 - this.state.selects.length});
    console.log(result);
    this.fetchData();
  };

  onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({ selected: null });
    this.fetchData();
  };

  scrollRef = null;
  render() {
    log(LogLevel.UI_LIFECYCLE, "Dashboard.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">게시글 이동</IonText>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container" ref={(ref) => {this.scrollRef = ref;}}>
              <div className="admin-board-search-container">
                <IonButton fill="clear" color="primary" onClick={() => this.setState({ page: (this.state.page - 100)<0?0:this.state.page - 100 })}>
                  &lt;
                </IonButton>
                <IonInput
                  class="admin-board-search-input"
                  placeholder="Page"
                  type="number"
                  value={this.state.page.toString()}
                  onIonChange={this.onPageChange}
                />
                <IonButton fill="clear" color="primary" onClick={() => this.setState({ page: this.state.page + 100 })}>
                  &gt;
                </IonButton>
                <IonSelect
                  class="admin-board-search-level"
                  value={this.state.category.toString()}
                  onIonChange={this.onCategoryChange}
                >
                  <IonSelectOption value={"1"}>학술</IonSelectOption>
                  <IonSelectOption value={"12"}>약사라이프</IonSelectOption>
                  <IonSelectOption value={"13"}>모임</IonSelectOption>
                  <IonSelectOption value={"14"}>약사장터</IonSelectOption>
                  <IonSelectOption value={"2"}>자유게시판</IonSelectOption>
                  <IonSelectOption value={"3"}>공지사항</IonSelectOption>
                  <IonSelectOption value={"4"}>FAQ</IonSelectOption>
                  <IonSelectOption value={"5"}>버그게시판</IonSelectOption>
                  <IonSelectOption value={"6"}>Test</IonSelectOption>
                  <IonSelectOption value={"7"}>제품</IonSelectOption>
                  <IonSelectOption value={"8"}>이슈토론</IonSelectOption>
                </IonSelect>

                <IonButton fill="solid" color="primary" onClick={this.onDone}>
                  로딩
                </IonButton>
              </div>
              <div className="admin-board-search-container admin-board-search-top-fixed">
                {this.state.selects.length} 게시물 이동 TO
                <IonSelect
                  class="admin-board-search-level"
                  value={this.state.destination.toString()}
                  onIonChange={this.onDestinationChange}
                >
                  <IonSelectOption value={"1"}>학술</IonSelectOption>
                  <IonSelectOption value={"12"}>약사라이프</IonSelectOption>
                  <IonSelectOption value={"13"}>모임</IonSelectOption>
                  <IonSelectOption value={"14"}>약사장터</IonSelectOption>
                  <IonSelectOption value={"2"}>자유게시판</IonSelectOption>
                  <IonSelectOption value={"3"}>공지사항</IonSelectOption>
                  <IonSelectOption value={"4"}>FAQ</IonSelectOption>
                  <IonSelectOption value={"5"}>버그게시판</IonSelectOption>
                  <IonSelectOption value={"6"}>Test</IonSelectOption>
                  <IonSelectOption value={"7"}>제품</IonSelectOption>
                  <IonSelectOption value={"8"}>이슈토론</IonSelectOption>
                </IonSelect>
                <IonButton fill="solid" color="primary" onClick={this.onMove}>
                  이동
                </IonButton>
              </div>

              <div className="admin-board-list-container">
                <IonList>
                  {this.state.contents.map((item: BoardContent, index: number) => (
                    <div key={index.toString()} className="common-container-row common-flex-align-center" onClick={() => this.onSelect(item)}>
                      <IonCheckbox
                        color="html-composer-checkbox"
                        checked={this.state.selects.includes(item.id)}
                      />
                      <div className="admin-board-list-item-container" >
                        <div
                          className={
                            "admin-row" +
                            (this.state.selected && item.id == this.state.selected
                              ? " common-color-highlight common-bold"
                              : "")
                          }
                        >
                          <div className="admin-grow">{item.id} - {getDateTimeString(item.createdAt)}</div>
                        </div>
                        <div>{item.subject} </div>
                        <Truncate lines={3} ellipsis="...">
                          {item.bodyText}
                        </Truncate>
                      </div>
                    </div>
                  ))}
                </IonList>
              </div>
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardContentMoveManage));
