import { action, createAction, createAsyncAction } from 'typesafe-actions';
import {BannerActionTypes} from './types'

export const getBanners = createAction(BannerActionTypes.BANNERS_FETCH, resolve =>
  () => resolve()
);

export const fetchBanners = createAsyncAction(
  BannerActionTypes.BANNERS_REQUEST,
  BannerActionTypes.BANNERS_SUCCESS,
  BannerActionTypes.BANNERS_ERROR,
)<any, any, any>(); // <string, BannerList, Error>();
