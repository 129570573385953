import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton, IonHeader, IonToolbar, IonButtons, IonIcon, IonLabel, IonContent, IonSegment, IonSegmentButton } from '@ionic/react';
import './MyProfile.css';
import {fetchAPI} from "../utils/APIUtil"
import * as API from './../API.json';
import logoIcon from './../assets/icon/logo.png'
import kakaoIcon from './../assets/icon/kakao_logo.png'
import profile from './../assets/image/profile_m_1.png'
import SimpleBoardList from './../components/SimpleBoardList'
import {BoardType} from './../store/board/types'
import {log, LogLevel} from '../utils/LogUtil'
import SwipeableViews from 'react-swipeable-views';
import bagIcon from './../assets/icon/shopping_bag.svg'

import {getGlobal, GlobalKey, setGlobal} from './../utils/GlobalUtil'
import { UserInfo } from '../models/Model.User';
import Profile from '../components/Profile';
import { loadImageBase64 } from '../utils/ImageUtil';
import { action } from 'typesafe-actions';
import BoardContentList from '../components/BoardContentList';
const queryString = require('query-string');
import BoardContentListSimpleItem from '../components/BoardContentListSimpleItem';
import AnalyticsUtil from './../utils/AnalyticsUtil';
import ABTestUtil, { ABTestFeature } from '../utils/ABTestUtil';
import { UIServiceType } from '../store/ui/types';

const SegmentList = [
  {name:"작성글", type:BoardType.MY_POST}, 
  {name:"답글", type:BoardType.MY_REPLY}, 
  {name:"좋아요", type:BoardType.MY_LIKE}, 
  {name:"스크랩", type:BoardType.MY_SCRAP}]

const windowAny : any = window;

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  segmentIndex : number,
}

class MyProfile extends Component<Props, State> {
  userId = 0;
  contentScroll = [null, null, null, null];
  
  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
    let segmentIndex = getGlobal(GlobalKey.MY_SEGMENT);
    this.state = {
      segmentIndex,
    }
    if(!this.props.boards[SegmentList[0].type] || this.props.boards[SegmentList[0].type].needReload)
      this.props.loadBoard(SegmentList[0].type);
    if(!this.props.boards[SegmentList[1].type] || this.props.boards[SegmentList[1].type].needReload)
      this.props.loadBoard(SegmentList[1].type);
    if(!this.props.boards[SegmentList[2].type] || this.props.boards[SegmentList[2].type].needReload)
      this.props.loadBoard(SegmentList[2].type);
    if(!this.props.boards[SegmentList[3].type] || this.props.boards[SegmentList[3].type].needReload)
      this.props.loadBoard(SegmentList[3].type);
  }

  componentDidMount () {
    this.autoScroll();
    if(this.props.backKeyControl) this.props.backKeyControl.setListener(this.onBack);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "MY_PROFILE", '내프로필진입');

  }

  componentDidUpdate (lastProps, lastState){
    log(LogLevel.UI_EVENT, "MyProfile.componentDidUpdate", this.props.boards);

    if(this.props.boards[SegmentList[0].type] && this.props.boards[SegmentList[0].type].needReload)
      this.props.loadBoard(SegmentList[0].type);
    if(this.props.boards[SegmentList[1].type] && this.props.boards[SegmentList[1].type].needReload)
      this.props.loadBoard(SegmentList[1].type);
    if(this.props.boards[SegmentList[2].type] && this.props.boards[SegmentList[2].type].needReload)
      this.props.loadBoard(SegmentList[2].type);
    if(this.props.boards[SegmentList[3].type] && this.props.boards[SegmentList[3].type].needReload)
      this.props.loadBoard(SegmentList[3].type);
  }

  onBack = () => {
    this.props.history.goBack();
  }

  onPressSetting = () => {
    this.props.history.push('/main/user/setting');
  }
  
  onChangeProfile = () => {
    this.props.history.push('/main/user/profile');
  
  }

  onOrderHistory = () => {
    this.props.history.push('/order');
  }

  onImageSelected = (e) => {
    let loadOption = {orientation: true, canvas:true, maxWidth: 200, maxHeight:200, cover:true};
    log(LogLevel.UI_ACTION, "MyProfile.onImageSelected", e.target.files[0], loadOption);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0], 200, true).then((base64) => {this.onImageLoad(base64)});
    }
  }
  
  onImageLoad = async (base64) => {
    // this.setState({profileImageBase64:e.target.result});
    log(LogLevel.UI_EVENT, "MyProfile.onImageLoad", base64.length);
    let data:any = {path:"profile"}
    data.base64 = base64;
    try {
      let result = await fetchAPI(API.UPLOAD_BASE64, '', null, data, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error){
          let url = result.file;
          log(LogLevel.UI_ACTION, 'onDone upload : ', result.file);
          let newMe:UserInfo = {...this.props.me,
            profileUrl: url,
          }
          result = await fetchAPI(API.USER_ME_UPDATE, '', null, newMe, getGlobal(GlobalKey.TOKEN))
          if(result && !result.error) {
            this.props.updateMe(newMe);
          }

      }else{
          log(LogLevel.UI_EXCEPTION, "onDone failed", result);
      }

    } catch (error) {
        log(LogLevel.UI_EXCEPTION, "onDone : failed", error);
        // this.props.navigation.push("AuthRegister");
    }
  }


  updateSegment = (e: CustomEvent) => {
    let name: string = e.detail.value;
    let type:BoardType = parseInt(name);
    let index = parseInt(name);

    log(LogLevel.UI_ACTION, "MyProfile:updateSegment", name, type, index);
    this.handleChangeIndex(index);
  }

  handleChangeIndex = (index: number) => {
    this.setState({segmentIndex: index});
    setGlobal(GlobalKey.MY_SEGMENT, index);
  }


  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = () => {
    log(LogLevel.UI_ACTION, "OtherProfile:autoScroll");
    setTimeout( async () => {
      try{
        let scroll = getGlobal(GlobalKey.SCROLL+"my0");
        if(scroll){
          let scrollDone = false;
          if(this.contentScroll[0]){
            log(LogLevel.UI_ACTION, "OtherProfile:autoScroll 0 jump to " + scroll);
            scrollDone = await this.contentScroll[0].scrollTo(scroll);
          }
          if(!scrollDone) {
            this.autoScrollTry++;
            if(this.autoScrollTry<this.autoScrollTryMax){
              this.autoScroll();
              return;
            }
          }  
        }
        scroll = getGlobal(GlobalKey.SCROLL+"my1");
        if(scroll){
          let scrollDone = false;
          if(this.contentScroll[1]){
            log(LogLevel.UI_ACTION, "OtherProfile:autoScroll 1 jump to " + scroll);
            scrollDone = await this.contentScroll[1].scrollTo(scroll);
          }
          if(!scrollDone) {
            this.autoScrollTry++;
            if(this.autoScrollTry<this.autoScrollTryMax){
              this.autoScroll();
              return;
            }
          }  
        }
        scroll = getGlobal(GlobalKey.SCROLL+"my2");
        if(scroll){
          let scrollDone = false;
          if(this.contentScroll[2]){
            log(LogLevel.UI_ACTION, "OtherProfile:autoScroll 2 jump to " + scroll);
            scrollDone = await this.contentScroll[2].scrollTo(scroll);
          }
          if(!scrollDone) {
            this.autoScrollTry++;
            if(this.autoScrollTry<this.autoScrollTryMax){
              this.autoScroll();
              return;
            }
          }  
        }
        scroll = getGlobal(GlobalKey.SCROLL+"my3");
        if(scroll){
          let scrollDone = false;
          if(this.contentScroll[3]){
            log(LogLevel.UI_ACTION, "OtherProfile:autoScroll 3 jump to " + scroll);
            scrollDone = await this.contentScroll[3].scrollTo(scroll);
          }
          if(!scrollDone) {
            this.autoScrollTry++;
            if(this.autoScrollTry<this.autoScrollTryMax){
              this.autoScroll();
              return;
            }
          }  
        }

      }catch(e){
        log(LogLevel.UI_EXCEPTION, "BoardList:autoScroll failed", e)
        this.autoScrollTry++;
        if(this.autoScrollTry<this.autoScrollTryMax){
          this.autoScroll();
          return;
        }
      }   
    }, 100);    
  }

  onScroll = (index, event) => {
    let target:any = event.target;
    log(LogLevel.UI_ACTION, "OtherProfile:onScroll " , index, target.scrollTop);
    setGlobal(GlobalKey.SCROLL+"my"+index.toString(), target.scrollTop);
  }

  onClickItem = (name, id) => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SELECT_CONTENT", '게시물선택', {"게시물id":id, "게시판이름":("내프로필>" + name)});
  }

  render() {
    log(LogLevel.NONE, "MyProfile:render");

    let cnt: number = 0;
    if(this.props.boards[SegmentList[this.state.segmentIndex].type] && this.props.boards[SegmentList[this.state.segmentIndex].type].contents)
      cnt = this.props.boards[SegmentList[this.state.segmentIndex].type].contents.length;

    return (
      <>
        <IonHeader class="myprofile-header" no-border>
          <IonToolbar color="myprofile-toolbar">
            <IonButtons slot="start">
              <IonButton class="myprofiletoolbar-button" onClick={this.onBack}>
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel>내프로필</IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton class="myprofiletoolbar-button" onClick={this.onPressSetting}>
                <IonIcon name="settings"/>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="myprofile-content">
          <div className="myprofile-card">
            <Profile user={this.props.me} onImageSelected={this.onImageSelected} showInviteCnt={true}/>
            <IonButton expand="block" color="profile-change-button" onClick={this.onChangeProfile}>
              프로필 수정
            </IonButton>
          </div>
          <div>
            <IonSegment value={this.state.segmentIndex.toString()} mode="md" slot="start" color="myprofile-segment" onIonChange={this.updateSegment}>
              {SegmentList.map((item, index) => {
                log(LogLevel.NONE, "MyProfile Loading segment", item, index);
                return (
                  <IonSegmentButton key={index.toString()} mode="md" class="myprofile-segment-button" value={index.toString()} checked={this.state.segmentIndex == index}>
                    <div className="myprofile-segment-button-text">{item.name}</div>
                  </IonSegmentButton>
                );
              })}
            </IonSegment>
            <div className="myprofile-segment-title">
              <span className="myprofile-segment-title-highlight">{cnt}개</span>의 {SegmentList[this.state.segmentIndex].name}이 있습니다.
            </div>
          </div>
          <div className="myprofile-list-container">
            <SwipeableViews className="myprofile-swipe" enableMouseEvents index={this.state.segmentIndex} onChangeIndex={this.handleChangeIndex}>
              {SegmentList.map((item, index) => {
                log(LogLevel.UI_LIFECYCLE, "MyProfile Loading lists", item, index);
                if(this.props.boards[SegmentList[index].type] && this.props.boards[SegmentList[index].type].contents)
                  return (
                    <BoardContentList key={index.toString()} ref={(ref) => {this.contentScroll[index] = ref}} id={index} onScroll={this.onScroll} loading={this.props.boards[SegmentList[index].type].loading} contents={this.props.boards[SegmentList[index].type].contents} checksum={this.props.boards[SegmentList[index].type].checksum} component={BoardContentListSimpleItem} onClickItem={(id) => {this.onClickItem(SegmentList[index].name, id)}}/>
                  );
                else 
                  return null;

              })}          
            </SwipeableViews>
          </div>

        </div>
      </>

    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  boards : state.board.boards,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadUser: (id:number) => actions.user.fetchUserInfo,
  updateMe: (user:UserInfo) => actions.user.setMe(user),
  loadFilePath: () => actions.board.getFilePath(),
  loadBoard: (id:number) => actions.board.getBoard(id),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfile));