import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'
import {setGlobal, GlobalKey, getGlobal} from './../utils/GlobalUtil'
import { fetchAPI } from '../utils/APIUtil';
import Truncate from 'react-truncate';
import { SeminarLecture, SeminarLectureAttribute } from '../models/Model.Seminar';
import { UIPopupType } from '../store/ui/types';
import { STATUS_CODES } from 'http';
import { PollInfo, PollItemInfo, PollResponseInfo, PollResponseItemInfo } from '../models/Model.Poll';
import PollSelect from './component/poll/PollSelect';
import { idText } from 'typescript';
import DailyPharmRecruitCompanyList from './component/dp/DailyPharmRecruitCompanyList';
import DailyPharmRecruitDetail from './component/dp/DailyPharmRecruitDetail';
import UserProfile from './component/user/UserProfile';
import JobpostDetail from './component/jobpost/JobpostDetail';

const AdminAPI = {        
  "GET_LIST" : {"method":"POST", "path":"/admin/seminar/lectures", "contentType":"application/json"}
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  recruit : any,
  userId: number,
  offerId: number,
}

class DailyPharmRecruitMain extends Component<Props, State> {
  lecturesList = null;
  lectureEditor = null;
  popup = null;
  state = {
    recruit: null,
    userId: 0,
    offerId: 0,
  };

  constructor(props: Props) {
    super(props);

  }

  componentDidMount() {
  }

  onSelectRecruit = (recruit:string) => {
    this.setState({recruit});
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "DailyPharmRecruitMain.render", this.props, this.state);
    
    let detail;

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
              DailyPharm Recruit
            </IonText>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            <div className="admin-grow-container">
              <DailyPharmRecruitCompanyList onSelectRecruit={this.onSelectRecruit} onSelectOfferId={(offerId) => {this.setState({offerId})}} onSelectUserId={(userId) => {this.setState({userId})}}/>
            </div>
            {
              this.state.recruit &&
                <div id="admin-sub-conainer" className="admin-container">
                  <DailyPharmRecruitDetail recruit={this.state.recruit} onClose={() => this.setState({recruit:""})}/>
                </div>
            }
            {
              this.state.userId > 0 &&
                <div id="admin-sub-conainer" className="admin-container">
                  <UserProfile userId={this.state.userId} onCancel={() => this.setState({userId:0})}/>
                </div>
            }
            {
              this.state.offerId > 0 &&
                <div id="admin-sub-conainer" className="admin-container">
                  <JobpostDetail offerId={this.state.offerId} onBack={() => this.setState({offerId:0})}/>
                </div>
            }

          </div>

        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures : state.seminar.lectures,
  progressPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath : state.board.filePath,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId:number) => actions.seminar.getSeminarLecture(lectureId),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DailyPharmRecruitMain));