import React, { Component } from "react";
import styled, { StyledComponent } from 'styled-components'
import COLOR from "../../../component/_design/color";
import { COLOR_SYSTEM } from "../../design/design-system";

interface WrapperProps {
  style?: React.CSSProperties;
}
const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`

  width: 100%;
  height: 16px;
  progress {
    width: 100%;
    height: 100%;
    color: ${COLOR_SYSTEM.get("Skyblue")[400]};
    background: ${COLOR_SYSTEM.get("Gray")[15]};
    border-radius: 1000px;
  }

  progress::-webkit-progress-bar {
    /* Chrome, Safari, Opera의 배경 */
    background: ${COLOR_SYSTEM.get("Gray")[15]};
    border-radius: 1000px;
  }
  progress::-webkit-progress-value {
    /* Chrome, Safari, Opera의 진행 바 */
    background:  ${COLOR_SYSTEM.get("Skyblue")[400]};
    border-radius: 1000px;
  }
  progress::-moz-progress-bar {
    /* Firefox의 진행 바 */
    background:  ${COLOR_SYSTEM.get("Skyblue")[400]};
    border-radius: 1000px;
  }
  ${({ style }) => style}
`

export interface Props {
  max?: number;
  value?: number;
  style?: React.CSSProperties;
}

type State = {
  value: number;
};

class ProgressBar extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value || 0,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.value !== this.props.value) {
      this.animateProgressBar(this.props.value, 500)
    }
  }

  animateProgressBar = (newValue, duration) => {
    let progressBar:any = document.getElementById("quiz-progress")
    if(progressBar === null) {
      this.setState({value: newValue})
      return;
    }
    let startValue = progressBar.value
    let changeInValue = newValue - startValue
    let startTime = null
  
    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime
      let timeElapsed = currentTime - startTime
      let progress = timeElapsed / duration
  
      if (progress > 1) progress = 1 // 애니메이션 종료 조건
  
      progressBar.value = startValue + changeInValue * progress
  
      if (timeElapsed < duration) {
        requestAnimationFrame(animate)
      }
      else{
        this.setState({value: newValue})
      }
    }
  
    requestAnimationFrame(animate)
  }

  render () {

    return (
      <Wrapper style={this.props.style}>
        <progress id="quiz-progress" max={this.props.max} value={this.state.value}/>
      </Wrapper>
  
    );
  }
}

export default ProgressBar
