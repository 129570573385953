import React, { memo, useMemo } from "react";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import styled from "styled-components";
import { SHADOW } from "../../design/effects";
import { Seminar } from "../../../store/search";
import { getDateTimeStringShort } from "../../../utils/TimeUtil";
import ReactHtmlParser from "react-html-parser";
import Icon from "../../atoms/Icon";

interface SearchedSeminarCardProps {
  seminar: Seminar;
}

interface IFlexProps {
  gap?: "string";
}

const FlexRowDiv = styled.div<IFlexProps>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
`;

const CardLi = styled.li`
  list-style: none;
  padding: 16px;
  border-radius: 8px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  ${SHADOW.get("1")};
  cursor: pointer;
`;

const TopDiv = styled(FlexRowDiv)`
  justify-content: space-between;
  margin-bottom: 12px;
`;

const PostSubjectDiv = styled.div`
  & em {
    font-style: normal;
    font-weight: 700;
    color: ${COLOR_SYSTEM.get("Skyblue")[400]};
  }
`;

const BodyTextDiv = styled.div`
  & em {
    font-style: normal;
    font-weight: 400;
    color: ${COLOR_SYSTEM.get("Skyblue")[400]};
  }
`;

const BottomDiv = styled(FlexRowDiv)`
  margin-top: 16px;
`;

const SearchedSeminarCard: React.FC<SearchedSeminarCardProps> = ({
  seminar,
}) => {
  const [title, description] = useMemo(() => {
    let title;
    let description;

    if (seminar.titleHighlight) {
      title = ReactHtmlParser(seminar.titleHighlight);
      description = seminar.bodyHighlight
        ? ReactHtmlParser(seminar.bodyHighlight)
        : seminar.subtitle;

      return [title, description];
    }

    if (seminar.title) {
      title = seminar.title;
      description = seminar.bodyHighlight
        ? ReactHtmlParser(seminar.bodyHighlight)
        : seminar.subtitle;

      return [title, description];
    }

    if (seminar.subtitle) {
      title = seminar.subtitle;
      description = seminar.bodyHighlight
        ? ReactHtmlParser(seminar.bodyHighlight)
        : seminar.description;

      return [title, description];
    }
  }, [seminar]);

  return (
    <CardLi>
      <TopDiv>
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <Tag variant="Tinted" color="Gray">
            세미나
          </Tag>
        </div>
        <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
          {getDateTimeStringShort(seminar.createdAt)}
        </Text>
      </TopDiv>
      <PostSubjectDiv>
        <Text
          textType="Body1Bold"
          color={COLOR_SYSTEM.get("Gray")[900]}
          numberOfLines={1}
        >
          {title}
        </Text>
      </PostSubjectDiv>
      <BodyTextDiv>
        <Text
          textType="Body2"
          color={COLOR_SYSTEM.get("Gray")[600]}
          numberOfLines={2}
        >
          {description}
        </Text>
      </BodyTextDiv>
      <BottomDiv gap="12px">
        <FlexRowDiv gap="2px">
          <Icon
            name="Star"
            width={12}
            height={12}
            color={COLOR_SYSTEM.get("Yellow")[400]}
          />
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[600]}>
            {seminar.scoreAverage}
          </Text>
          <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
            ({seminar.scoreCount})
          </Text>
        </FlexRowDiv>
        <div>
          <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
            시청{" "}
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[600]}>
              {seminar.viewCount}
            </Text>
          </Text>
        </div>
      </BottomDiv>
    </CardLi>
  );
};

export default memo(SearchedSeminarCard);
