import React, { FC, forwardRef, useRef, useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Icon from '../../atom/Icon'
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from './../../atom/Text'
import Input from '../../atom/Input'
import IconButton from '../../atom/IconButton'
import IconFileButton from '../../atom/IconFileButton'
import Textarea from 'react-textarea-autosize';

interface ContainerProps {
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  position: absolute;
  bottom: 0px;
  align-items: center;

  .footer-editor-input-container{
    padding: 7px 16px 7px 24px;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${COLOR.Gray8};
  }

  .footer-editor-reply-to-container{
    padding: 12px 12px 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${COLOR.Secondary};
    opacity: 80%;

    .footer-editor-reply-to-body{
      flex-grow: 1;
      max-width: calc(100% - 43px);
    }
  }

  textarea {
    padding: 8px 0px;
    width: 100%;
    outline: none;
    outline-style: none;
    background: none;
    color: ${COLOR.Black};

    appearance: none;
    border: none;

    &::placeholder {
      color: ${COLOR.Gray4};
    }

    ${TYPOGRAPY["Body1"]}
  }
`

export interface Props {
  replyTitle?: string;
  replyDescription?: string;
  onCancelReply?: () => void;
  onDone?: (result:FooterEditorResult) => void;
  placeholder?: string;
}

export interface FooterEditorResult {
  text?: string;
  file?: File;
}

const FooterEditor: FC<Props> = ({
  replyTitle, 
  replyDescription, 
  onCancelReply,
  onDone,
  placeholder,
}, ref) => {
  const [text, setText] = useState<string>("");

  const onFileSelect = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if(onDone)
        onDone({file: e.target.files[0]})
    }
  }

  const onClickDone = () => {
    if(onDone)
      onDone({text});
    setText("");
  }

  return (
    <Container ref={ref}>
      {(!!replyTitle || !!replyDescription) && 
        <div className="footer-editor-reply-to-container">
          <div className="footer-editor-reply-to-body">
            {(!!replyTitle) &&
              <div>
                <Text type="Caption1" color="Gray7" numberOfLines={1} >{replyTitle}</Text>
              </div>
            }
            {(!!replyDescription) &&
              <div>
                <Text type="Body2" color="Gray5" numberOfLines={1} >{replyDescription}</Text>
              </div>
            }
          </div>
          <IconButton size="M" variant="Ghost" colorValue={COLOR.White} iconName="X" onClick={onCancelReply}/>
        </div>
      }
      <div className="footer-editor-input-container">
        <div className="common-flex-grow">
          <Textarea 
            minRows={1} maxRows={5} 
            placeholder={placeholder}
            onChange={(e) => {setText(e.target.value)}}
            value={text} 
            onClick={(e)=>{e.stopPropagation();}}
          />
        </div>
        {(text == "") &&
          <IconFileButton size="M" variant="Ghost" color="Gray" iconName="Clip" onFileSelect={onFileSelect} multiple={false}/>
        }
        <IconButton size="M" variant="Ghost" color="Primary" iconName="Paperplane" disabled={(text == "")} onClick={onClickDone}/>
      </div>
    </Container>
  )
}

export default forwardRef(FooterEditor);
