import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IonSpinner } from '@ionic/react'
import { SeminarLecture } from '../../../models/Model.Seminar';
import { Attachment } from '../../../models/Model.Board';
import Header from '../../molecule/Header';
import Body from '../../molecule/Body';
import StringUtil from '../../../utils/StringUtil';
import Scroll from '../../molecule/Scroll';
import { log, LogLevel } from '../../../utils/LogUtil';
import { ScrollAction } from '../../molecule/Scroll';
import Video from '../../atom/Video';
import VideoController from '../../cell/VideoController';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import IconButton from '../../atom/IconButton';
import Footer from '../../molecule/Footer';
import TextButton from '../../atom/TextButton';
import Badge from '../../atom/Badge';
import Text from '../../atom/Text';
import Icon from '../../atom/Icon';
import { extractTimeToJTag, extractTimeValue, getTimerString } from '../../../utils/TimeUtil';
import color from '../../_design/color';
import StarScore from '../../atom/StarScore';
import { TabBar } from '../../cell/TabBar';
import Spinner from '../../atom/Spinner';
import Avatar from '../../atom/Avatar';
import ReactHtmlParser from 'react-html-parser'
import SeminarViewReview from './SeminarViewReview';
import SeminarListItem from './SeminarListItem';
import COLOR from '../../_design/color';


interface Props {
  seminars:any
  seminar:SeminarLecture,
  onClick?: (seminar: SeminarLecture) => void,
}
const SeminarViewSeries: FC<Props> = ({
  seminars,
  seminar,
  onClick,
}) => {
  const [showCount, setShowCount] = useState<number>(10);
  const showCountUnit = 10;

  if(!seminar.seriesId || !seminar.series){
    return null;
  }
  
  if(!seminar || seminar.loading)
    return (
      <Spinner/>
    )
  log(LogLevel.UI_LIFECYCLE, "SeminarViewSeries:", seminar, showCount);

  return (
    <>
      <div className="common-block-line" style={{marginBottom:"12px"}}>
        <Text type="H3" color="Secondary">
          {seminar.series.title} 시리즈 이어보기
        </Text>
      </div>
      { (seminar.series.lectures && seminar.series.lectures.length > 0) &&
        seminar.series.lectures.map((lecture, index) => {
          return (
            <div className="common-container" style={{marginBottom:"12px", background:(seminar.id == lecture.id)?COLOR.Gray8:COLOR.White, opacity:(seminar.id == lecture.id)?0.5:1}}>
              <SeminarListItem key={index.toString()} seminar={seminars[lecture.id]} onClick={onClick}/>
            </div>
          )
        })

      }
    </>
  )
}


export default SeminarViewSeries
