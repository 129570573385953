import React from 'react';
import { RouteComponentProps } from "react-router";
import { IonList, IonSpinner } from '@ionic/react';
import './BoardContentList.css'
import './../Common.css'
import {BoardContent} from './../models/Model.Board'
import {log, LogLevel} from '../utils/LogUtil'
import { connect } from 'react-redux';
import { RootState } from '../store';


type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  contents: BoardContent[];
  checksum: number;
  loading?: boolean;
  id?: number;
  onScroll?: (id, event) => void;
  props?: any;
  onClickItem?: (id) => void;
}

class BoardContentList extends React.Component<Props> {
  contentScroll = null;

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "BoardContentList:constructor");
  }

  componentDidMount(){
    log(LogLevel.UI_LIFECYCLE, "BoardContentList:componentDidMount");
    
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.loading != nextProps.loading){
      log(LogLevel.UI_LIFECYCLE, "BoardContentList:shouldComponentUpdate update")
      return true;
    }
    else if(this.props.checksum == nextProps.checksum){
      log(LogLevel.UI_LIFECYCLE, "BoardContentList:shouldComponentUpdate skip")
      return false;
    }
    log(LogLevel.UI_LIFECYCLE, "BoardContentList:shouldComponentUpdate update")
    return true;
  }

  scrollTo = async (scrollTop: number) => {
    log(LogLevel.UI_ACTION, "BoardContentList:scrollTo done", scrollTop)
    if(this.contentScroll){
      let result = await this.contentScroll.scrollTo(0, scrollTop);
      log(LogLevel.UI_ACTION, "BoardContentList:scrollTo done", scroll, result)
      return true;
    }
    else return false;
  }

  onScroll = (event) => {
    if(this.props.onScroll)
      this.props.onScroll(this.props.id, event);
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardContentList:render", this.props);
    const Component = this.props.component;
    if((!this.props.contents || this.props.contents.length == 0) && this.props.loading)
      return (
        <div className="board-content-list-waiting-container">
          <IonSpinner name="crescent"/>
        </div>
      ) 
    return (
      <div className="common-list" ref={(ref)=>{this.contentScroll=ref}} onScroll={this.onScroll}>
        { this.props.contents.map((item, index: number) => {
            return React.createElement(Component, {key:index.toString(), content:this.props.allContents[item.id], index:index, ...this.props.props, onClick:this.props.onClickItem});
          }
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  allContents: state.board.contents,
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(BoardContentList);