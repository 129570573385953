import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IonSpinner } from '@ionic/react'
import { SeminarLecture } from '../../../../models/Model.Seminar';
import { Attachment, BoardContent } from '../../../../models/Model.Board';
import Header from '../../../molecule/Header';
import Body from '../../../molecule/Body';
import StringUtil from '../../../../utils/StringUtil';
import Scroll from '../../../molecule/Scroll';
import { log, LogLevel } from '../../../../utils/LogUtil';
import { ScrollAction } from '../../../molecule/Scroll';
import Video from '../../../atom/Video';
import VideoController from '../../../cell/VideoController';
import { getGlobal, GlobalKey, setGlobal } from '../../../../utils/GlobalUtil';
import IconButton from '../../../atom/IconButton';
import Footer from '../../../molecule/Footer';
import TextButton from '../../../atom/TextButton';
import './style.scss';
import './../../../../Common.css';
import Badge from '../../../atom/Badge';
import Text from '../../../atom/Text';
import Icon from '../../../atom/Icon';
import { getTimerString } from '../../../../utils/TimeUtil';
import color from '../../../_design/color';
import StarScore from '../../../atom/StarScore';
import { TabBar, TabBarItem } from '../../../cell/TabBar';
import SeminarViewDetail from '../../../organism/Seminar/SeminarViewDetail';
import Spinner from '../../../atom/Spinner';
import SeminarViewReviews from '../../../organism/Seminar/SeminarViewReviews';
import SeminarViewQnA from '../../../organism/Seminar/SeminarViewQnA';
import { BannerInfo } from '../../../../models/Model.Banner';
import { SeminarListState } from '../../../../store/seminar/types';
import CardView from '../../../molecule/CardView';
import TextToggleButton from '../../../atom/TextToggleButton';
import SeminarListItem from '../../../organism/Seminar/SeminarListItem';
import FloatingButton from '../../../atom/FloatingButton';
import SegmentedControl from '../../../atom/SegmentedControl';


interface Props {
  banners?: BannerInfo[]
  seminarListStates: SeminarListState[],
  lectures: any,
  onSelectBanner?: (banner:BannerInfo) => void
  onSelectSeminar?: (seminar:SeminarLecture) => void
  onGoBack?: () => void
  onSort?: (id, options) => void
  onLoadMore?: (id:number) => void
}
const SeminarList: FC<Props> = ({
  banners,
  seminarListStates,
  lectures,
  onSelectBanner,
  onSelectSeminar,
  onSort,
  onGoBack,
  onLoadMore,
}) => {
  const scrollEl = useRef<Scroll>(null);
  const [index, setIndex] = useState<number>(getGlobal(GlobalKey.SEMINAR_SEGMENT));

  const onBack = () => {
    scrollEl.current.clearAutoScroll();
    if(onGoBack)
      onGoBack();
  }

  const onClickBanner = (item:BannerInfo, index:number) => {
    if(onSelectBanner)
      onSelectBanner(item);
  }

  const onClickSort = (sort: "viewCount" | "createdAt") => {
    let options = seminarListStates[index].options;
    options.orderBy = sort;
    if(onSort)
      onSort(index, options);
  }

  const onIndexChanged = (index: number) => {
    setGlobal(GlobalKey.SEMINAR_SEGMENT, index);
    setIndex(index);
  }
  log(LogLevel.UI_EVENT, "SeminarList: ", seminarListStates);

  return (
    <>
      <Header needBack={false} name="콘텐츠" center={false} />
      <Body scrollable={false}>
        <TabBar level="1" onIndexChanged={onIndexChanged} index={index}>
          {seminarListStates && seminarListStates.map((listItem, index) => {
            return (<Text key={index.toString()}>{listItem.categoryName}</Text>);
          })}
        </TabBar>
        <div className="seminar-list-container">
          <Scroll ref={scrollEl} autoScrollName={"SeminarListView"} onLoadMore={() => onLoadMore(index)}>
            {(index==0 && banners && banners.length>0) &&
              <div className="common-container" style={{marginTop:"12px", marginBottom:"20px"}}>
                <CardView className="seminar-list-cardview" randomStart={true}  style="centered" infinite={true} items={banners} imageUrlKey="imageUrl" indexPosition="centered-left" autoScrollInterval={15000} onClick={onClickBanner}/>
              </div>
            }
            {(index!=3) &&
              <div className="common-block-line" style={{marginTop:"12px"}}>
                <SegmentedControl
                  type="Outlined"
                  onClick={onClickSort}
                  value={(seminarListStates[index] && seminarListStates[index].options) && seminarListStates[index].options.orderBy}
                  values={[
                    {name:"최신순", value:"createdAt"},
                    {name:"조회순", value:"viewCount"}
                  ]}
                />
              </div>
            }     

            {
              (seminarListStates && seminarListStates[index] && seminarListStates[index].lectures && seminarListStates[index].lectures.length>0) &&
              seminarListStates[index].lectures.map((lecture, index2) => {
                return (
                  <SeminarListItem key={index2.toString()} category={(seminarListStates[index] && seminarListStates[index].options)?seminarListStates[index].options.category:null} seminar={lectures[lecture.id]} onClick={onSelectSeminar}/>
                );
              })
            }
            <div className="common-bottom-space"/>
          </Scroll>
        </div>
      </Body>
    </>
  )
}

const Container: StyledComponent<'div', FC> = styled.div`
  
`

export default SeminarList
