import React, { Component } from "react";
import "./../../../Common.css";

type Props = {};

const InformationCompany: React.SFC<Props> = ({}) => {
  return (
    <div className="common-block">
      사업자 정보 <br />
      <br />
      사명 : 약문약답 주식회사 <br />
      <br />
      대표 : 조정래 <br />
      <br />
      사업자 등록번호 : 847-88-01973 <br />
      <br />
      직업정보제공사업 신고번호 : J1516020210001{" "}
      <a
        style={{ marginLeft: "20px" }}
        onClick={() => {
          open(
            "https://www.moel.go.kr/info/defaulter/defaulterList.do",
            "_blank"
          );
        }}
      >
        체불사업자 명단 확인하기
      </a>
      <br />
      <br />
      통신판매업 : 제2021-성남분당C-1394호
      <br />
      <br />
      본점 소재지 : 경기도 성남시 수정구 대왕판교로 815, 8층 837호(시흥동,
      판교창조경제밸리) <br />
      <br />
      고객센터 : 070-4801-1200 <br />
      <br />
      메일주소 : hello@ymyd.co.kr <br />
    </div>
  );
};

export default InformationCompany;
