import { Attachment, BoardContent } from "./Model.Board";
import { Ingredient } from "./Model.Medicine";
import { ProductInfo } from "./Model.Sale";


export interface SeminarFeaturedLecture{
    id?: number;
    title?: string;
    link?: string;
    imageUrl?: string;
    imageBase64?: any;
    startAt?: any;
    endAt?: any;
    createdAt?: object,
    deletedAt?: any,
}

export interface SeminarLecture{
    id?: number;
    type?: SeminarLectureType;
    seriesId?: number;
    series?: SeminarSeries;
    title?: string;
    titleHighlight?: string;
    bodyHighlight?: string;
    subtitle?: string;
    description?: string;
    hidden?: string;

    effect?: string;
    scoreAverage?: number;
    scoreCount?: number;
    viewCount?: number;
    commentCount? : number,
    playtime?: number;
    attribute?: number;
    categories?: any[];
    
    lecturer?: SeminarLecturer,
    lecturers?: SeminarLecturer[],

    imageUrl?: string;
    previewUrl?: string;
    bannerUrl?: string;
    shareUrl?: string;
    videoUrl?: string;
    videoUrlHighResolution?: string;
    thumbnailUrl?: string;

    inBanners?:Attachment[];
    cards?: Attachment[], 

    references?:Attachment[];
    materials?: Attachment[];
    referencesLocal?:any[];
    materialsLocal?: any[];

    ingredients?: Ingredient[];

    myReview?: SeminarReview;

    passingMark?: number;
    
    viewed?: boolean;
    viewValue?: number;
    lastPlayedTime?: number;

    products?: ProductInfo[];
    
    advertiserCode?: string;
    advertiserProductCode?: string;

    createdAt?: any,
    deletedAt?: any,
    loading?: boolean,

    score?: number,

    comments?: SeminarLectureComment[],

}

export interface SeminarLecturer{
    name?: string;
    imageUrl?: string;
    imageBase64?: any;
    career?: string[];
}

export interface SeminarSeries{
    id?: number;
    title?: string;
    subtitle?: string;
    description?: string;
    lectures?: SeminarLecture[],
    
    imageUrl?: string;
}

export interface SeminarLectureComment{
    id?: number;
    lectureId?: number;
    userId?: number;
    userNickname?: string;
    userProfileUrl?: string;
    // isAnonymous?: boolean; // 익명 게시 여부

    groupParent?:number;
    groupDepth?: number;
    groupOrder?: number; 
    groupCount?: number; 

    createdAt?: object,
    deletedAt?: any,
    likeCnt?: number,
    bodyText?: string; // 본문 텍스트
    fileUrl?: string;
    fileName?: string;
    fileSize?: number;
    imageUrl?: string;
    liked?: boolean;
    editable?: boolean,
}

export interface SeminarReview{
    id?: number;
    lectureId?: number;
    type?: number;
    userId?: number;
    userProfileUrl?: string;
    comments?: string;
    score?: number;
    createdAt?: any;
    editable?: boolean;
    images?: string[];
}


export const SeminarReviewMent = [
    "",
    "아쉬워요 😔",
    "그저 그래요 🙂",
    "도움이 됐어요 😀",
    "유익했어요 😄",
    "완전 유익해요 😆",
]

export enum SeminarLecturePartialLoadOption {
    NONE = 0x00,
    ALL = 0xffff,
    BODY = 0x01,
    COMMENTS = 0x02,
    ATTACHMENTS = 0x08,
    INGREDIENTS = 0x10,
    VIEW = 0x40,
    REVIEW = 0x80,
    SERIES = 0x100,
    ADMIN = 0x10000,
}

export enum SeminarLectureAttribute {
    NONE = 0x00,
    // HAS_REWARD = 0x01,
    // HAS_PRODUCT_REVIEW = 0x02,
    // HAS_CONSULT = 0x04,
    HAS_SALES = 0x08,
}


export enum SeminarLectureType {
    NONE = 0,
    SEMINAR = 1,
    CARDNEWS = 2,
    ALL = 0,
}

export const SeminarLectureTypeName = [
    "알수없음",
    "세미나",
    "카드뉴스",
]
