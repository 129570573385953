import React, { Component } from 'react';
import './Profile.css';
import { IonPopover, IonTitle, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import {log, LogLevel} from '../utils/LogUtil'
import profileImg from '../assets/image/profile_m_1.png'
import profileAnonymous from '../assets/image/profile_anonymous.png'
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import StringUtil from '../utils/StringUtil';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  className : string;
  profileBase64? : string;
  defaultSrc?: any;
  profileUrl? : string;
  anonymous? : boolean;
  onClick? : () => any;
};

const ProfileImage: React.SFC<Props> = ({className, profileBase64, profileUrl, anonymous, onClick, defaultSrc}) => {
  if(anonymous){
    return (
      <img className={className} src={profileAnonymous} onClick={() => {if(onClick)onClick()}}  onError={(e)=>{let targetAny:any = e.target; targetAny.src=profileImg}}/>
    );
  }
  else if (profileBase64){
    return (
      <img className={className} src={profileBase64}  onClick={() =>{if(onClick)onClick()}} onError={(e)=>{let targetAny:any = e.target; targetAny.src=profileImg}}/>
    );
  }
  else if (profileUrl) {
    let profilePath = profileUrl;
    profilePath = StringUtil.convertFilePath(profilePath);

    if(profilePath.startsWith("http://k.kakaocdn.net/")){
      profilePath = profilePath.replace("http://k.kakaocdn.net/", "https://k.kakaocdn.net/");
    }
    return (
      <img className={className} src={profilePath}  onClick={() => {if(onClick)onClick()}} onError={(e)=>{let targetAny:any = e.target; targetAny.src=profileImg}}/>
    );
  }
  else if (defaultSrc){
    return (
      <img className={className} src={defaultSrc}  onClick={() => {if(onClick)onClick()}} onError={(e)=>{let targetAny:any = e.target; targetAny.src=profileImg}}/>
    );
  }
  else {
    return (
      <img className={className} src={profileImg}  onClick={() => {if(onClick)onClick()}} onError={(e)=>{let targetAny:any = e.target; targetAny.src=profileImg}}/>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  filePath: state.board.filePath
});

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);