import React, { Component } from 'react';
import './../../../Common.css'
import './style.scss'
import { IonModal } from '@ionic/react';
import {log, LogLevel} from '../../../utils/LogUtil'
import Header from '../../molecule/Header';
import Body from '../../molecule/Body';

type Props = {

};

type State = {
  visible: boolean;
  component: any
}

class InformationViewPopup extends Component<Props, State> {
  state={
    visible: false,
    component: null,
  };

  isShown = () => {
    return this.state.visible;
  }

  show = (component: any) => {
    this.setState({visible:true, component});
  }

  hide = () => {
    this.setState({visible: false});
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "InformationViewPopup: render");
    return (
      <IonModal cssClass="information-view-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <Header needBack={false} needClose={true} goBack={this.hide}/>
        <Body scrollable={true}>
          {this.state.component!=null && 
            React.createElement(this.state.component)
          }
        </Body>
      </IonModal>
    );
  }
}

export default InformationViewPopup;