import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton } from '@ionic/react';
import {fetchAPI} from "../utils/APIUtil"
import * as API from './../API.json';
import logoIcon from './../assets/icon/logo.png'
import {UserInfo} from '../models/Model.User'
import {log, LogLevel} from '../utils/LogUtil'
import {getGlobal, GlobalKey, setGlobal} from './../utils/GlobalUtil'
import WelcomeStage0 from '../components/WelcomeStage0';
import WelcomeStage1 from '../components/WelcomeStage1';

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
    stage:number,
}

class Welcome extends Component<Props, State> {
    state = {
        stage: 0,
    }
    
    constructor(props: Props) {
        super(props);
        log(LogLevel.UI_LIFECYCLE, "Welcome:constructor",  props);
        setGlobal(GlobalKey.WELCOME_DONE, true, true);
    }

    componentDidMount() { 
    }

    onUpdateInterest= (interest: string) => {
        log(LogLevel.UI_ACTION, "onUpdateInterest", interest);
        fetchAPI(API.USER_ME_UPDATE, '', null, {interest}, getGlobal(GlobalKey.TOKEN));
        let me = {...this.props.me, interest};
        this.props.updateMe(me);
    };

    onDone= () => {
        // fetchAPI(API.USER_ME_UPDATE, '', null, {guided:1}, getGlobal(GlobalKey.TOKEN));
        // setGlobal(GlobalKey.WELCOME_DONE, true, true);
        if(this.props.me.wantToGo){
            this.props.history.replace("/");
            setTimeout(() => {
                this.props.history.push(this.props.me.wantToGo);
            }, 200)
            fetchAPI(API.USER_ME_UPDATE, '', null, {wantToGo:""}, getGlobal(GlobalKey.TOKEN));
            let me = {...this.props.me, wantToGo:""};
            this.props.updateMe(me);
            
        }else{
            this.props.history.replace('/main/search');
        }
    };

    render() {
        log(LogLevel.NONE, "Welcome stage: ", this.state.stage);
        switch(this.state.stage){
            case 0 : 
                // return <RegisterStage3 user={this.state.user} onDone={this.onPhase4Callback} onBack={this.onPhase3BackCallback}/>
                return <WelcomeStage0 me={this.props.me} onDone={this.onDone}/>
            case 1 : 
                return <WelcomeStage1 interest={this.props.me.interest} onUpdateInterest={this.onUpdateInterest} onDone={this.onDone}/>
            // case 2 : 
            //     return <RegisterStage2 private={this.props.private} user={this.state.user} onDone={this.onPhase2Callback} onBack={this.onPhase2BackCallback}/>
            // case 3 : 
            //     return <RegisterStage3 private={this.props.private} user={this.state.user} onDone={this.onPhase3Callback}  onBack={this.onPhase3BackCallback}/>
            // case 4 : 
            //     return <RegisterStage4 private={this.props.private} user={this.state.user} onDone={this.onPhase4Callback}  onBack={this.onPhase4BackCallback}/>
            // case 12 : 
            //     return <RegisterStage12 private={this.props.private} user={this.state.user} onDone={this.onPhase2Callback} onBack={this.onPhase2BackCallback}/>
            // default:
            //     return <RegisterStage0 onSelected={this.onPhase0Callback}/>
        }
        return null;
    }

    onPhase0Callback = () =>{
        log(LogLevel.UI_ACTION, "onPhase0Callback ")
        this.setState({stage: 1});
    }

    onPhase1Callback = (interest: string) => {

    }


    // onPhase1Callback = (name: string, phoneNumber: string, birthdayGender:string) =>{
    //     log(LogLevel.UI_ACTION, "onPhase1Callback ", name, phoneNumber, birthdayGender);
    //     let user = this.state.user;
    //     user.name = name;
    //     user.phone = phoneNumber;
    //     user.birthGender = birthdayGender;
    //     if(this.state.isStudent)
    //         this.setState({stage: 12, user:user});
    //     else
    //         this.setState({stage: 2, user:user});
    // }

    // onPhase1BackCallback = (data:any) => {
    //     log(LogLevel.UI_ACTION, "onPhase2BackCallback ", data);
    //     this.setState({stage: 0});        
    // }

    // onPhase2Callback = (data: any) =>{
    //     log(LogLevel.UI_ACTION, "onPhase2Callback ", data);
    //     // email: this.state.email,
    //     // workPlace: this.state.workPlace,
    //     // workPlaceId: this.state.workPlaceId,
    //     // workType: this.state.workType,
    //     // license: this.state.license,
    //     // licenseImage: this.state.licenseImage,
    //     let user:UserInfo = {...this.state.user};
    //     user.email= data.email,
    //     // user.workPlaceAddress= data.workPlace,
    //     // user.workPlaceId= data.workPlaceId,
    //     // user.workPlaceId= data.workPlaceId, 
    //     user.workPlaceAddress= data.workPlaceAddress,
    //     user.workPlaceRegion= data.workPlaceRegion, 
    //     user.workPlaceName= data.workPlaceName,
    //     user.workPlacePhone= data.workPlacePhone,
    //     user.workType= data.workType,
    //     user.license= data.license,
    //     user.licenseImageBase64= data.licenseImageBase64,        
    //     this.setState({stage: 3, user:user});
    // }

    // onPhase2BackCallback = (data:any) => {
    //     log(LogLevel.UI_ACTION, "onPhase2BackCallback ", data);
    //     let user:UserInfo = {...this.state.user};
    //     user.email= data.email,
    //     // user.workPlaceId= data.workPlaceId, 
    //     user.workPlaceAddress= data.workPlaceAddress,
    //     user.workPlaceRegion= data.workPlaceRegion, 
    //     user.workPlaceName= data.workPlaceName,
    //     user.workPlacePhone= data.workPlacePhone,
    //     user.workType= data.workType,
    //     user.license= data.license,
    //     user.licenseImageBase64= data.licenseImageBase64,        
    //     this.setState({stage: 1, user:user});  
    // }

    // onPhase3Callback = (data: UserInfo) =>{
    //     log(LogLevel.UI_ACTION, "onPhase2Callback ", data);
    //     let user:UserInfo = {...this.state.user};
    //     user.nickname = data.nickname;
    //     user.profileImageBase64 = data.profileImageBase64;
    //     user.description = data.description;
  
    //     this.setState({stage: 4, user:user});
    // }

    // onPhase3BackCallback = (data: UserInfo) => {
    //     log(LogLevel.UI_ACTION, "onPhase3BackCallback ", data);
    //     let user:UserInfo = {...this.state.user};
    //     user.nickname = data.nickname;
    //     user.profileImageBase64 = data.profileImageBase64;
    //     user.description = data.description;
  
    //     if(this.state.isStudent)
    //         this.setState({stage: 12, user:user});
    //     else
    //         this.setState({stage: 2, user:user});       
    // }


    // onPhase4Callback = (data: any) =>{
    //     log(LogLevel.UI_ACTION, "onPhase2Callback ", data);
    //     // this.setState({stage: 0});
    //     setTimeout(()=>{this.props.history.replace("/");}, 300);
    //     //this.props.history.replace("/");
    // }

    // onPhase4BackCallback = (data: any) =>{
    //     log(LogLevel.UI_ACTION, "onPhase2BackCallback ", data);
    //     this.setState({stage: 3});
    // }

}


const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {
  logInUser: () => actions.user.login(),
  updateMe: (me:UserInfo) => actions.user.setMe(me),


}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Welcome));