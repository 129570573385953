import React, { useCallback, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { JobOfferInfo, JobOfferName } from "../../../models/Model.JobPost";
import { UserInfo, UserLevel } from "../../../models/Model.User";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { paintBottomSheet } from "../../../store/modal/bottomsheet";
import { useDispatch } from "react-redux";
import ShareList from "../../organisms/ShareList";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import API from "../../../API.json";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { MY_ADDRESS } from "../../../config.json";
import useClipboard from "../../../hooks/useClipboard";
import useShare from "../../../hooks/useShare";

interface HeaderProps {
  preview?: boolean;
  post?: JobOfferInfo;
  me?: UserInfo;
  isMyPost?: boolean;
  onClose?: () => void;
  handleGoBackClick?: () => void;
  handleMoreClick?: () => void;
}

const windowAny: any = window;

const Header: React.FC<RouteComponentProps & HeaderProps> = ({
  preview,
  post,
  me,
  isMyPost,
  onClose,
  handleGoBackClick,
  handleMoreClick,
}) => {
  const dispatch = useDispatch();
  const { jobPostShare } = useShare({ jobPost: post }, me);

  const handleClickShare = useCallback(() => {
    dispatch(
      paintBottomSheet({
        title: {
          value: "공유하기",
          align: "center",
        },
        body: (
          <ShareList
            onKakao={jobPostShare.handleClickKakao}
            onSMS={jobPostShare.handleClickSMS}
            onLink={jobPostShare.handleClickLink}
          />
        ),
      })
    );
  }, []);

  return (
    <header>
      <TopAppBar
        leftButtons={
          preview
            ? [<Button color="Tertiary" icon="X" onClick={onClose} size="Medium" type="Icon" variant="Ghost" />]
            : [
                <Button
                  color="Secondary"
                  icon="CaretLeft"
                  onClick={handleGoBackClick}
                  size="Medium"
                  type="Icon"
                  variant="Ghost"
                />,
              ]
        }
        rightButtons={[
          !isMyPost && (
            <Button
              color="Secondary"
              icon="Share01"
              size="Medium"
              type="Icon"
              variant="Ghost"
              onClick={handleClickShare}
            />
          ),
          !preview && me && (me.id === post.ownerId || me.level >= UserLevel.MANAGER) && (
            <Button color="Secondary" icon="More" onClick={handleMoreClick} size="Medium" type="Icon" variant="Ghost" />
          ),
        ]}
        title={preview && "공고 미리보기"}
        titleType="H4"
      />
      {!preview && post.endedAt && (
        <div style={{ textAlign: "center", backgroundColor: COLOR_SYSTEM.get("Gray")[10], padding: "20px" }}>
          <Text textType="Subhead1" color={COLOR_SYSTEM.get("Gray")[600]}>
            채용이 종료된 공고입니다.
          </Text>
        </div>
      )}
      {preview && (
        <div style={{ textAlign: "center", backgroundColor: COLOR_SYSTEM.get("Gray")[10], padding: "20px" }}>
          <Text textType="Subhead1" color={COLOR_SYSTEM.get("Gray")[600]}>
            구직중인 약사님들께 공개될 화면이에요
            <br />
            내용을 확인하시고 등록하기 버튼을 눌러주세요
          </Text>
        </div>
      )}
    </header>
  );
};

export default withRouter(Header);
