

import React, { Component } from 'react';
import './../Admin.scss';
import './../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../models/Model.User';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../models/Model.Board';
import Truncate from 'react-truncate';
const queryString = require('query-string');

const AdminAPI = {        
  "GET_LIST" : {"method":"GET", "path":"/admin/board/contents", "contentType":"application/json"}
};

type Props = RouteComponentProps & {
  onDone: (content:BoardContent) => void;
  aliveOnly?: boolean
};

type State = {
  search: string,
  category: number,
  contents: BoardContent[],
}

class BoardContentSelect extends Component<Props, State> {
  finished: boolean = false



  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "BoardContentSelect:constructor", this.props.location.search);
    let search = "";
    let qs = queryString.parse(this.props.location.search);
    if(qs.id)
      search = qs.id;

    this.state = {
      search,
      category: 1,
      contents: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.aliveOnly != nextProps.aliveOnly) return true;
    if(this.state.search != nextState.search) return true;
    if(this.state.category != nextState.category) return true;
    if(this.state.contents != nextState.contents) return true;
    return false

  }

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({search: keywords});
    this.fetchData();
    
  }
  
  onCategoryChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let category: number = parseInt(e.detail.value);
    this.setState({category: category});
    this.fetchData();
    
  }

  onSelect = (content: BoardContent) => {
    log(LogLevel.UI_EVENT, "BoardContentSelect:onSelect", content);
    this.props.onDone(content);
  }

  fetchData = async () => {
    let result;
    if(this.props.aliveOnly)
      result = await fetchAPI(API.BOARD_SIMPLE_LIST, this.state.category.toString(), {start:0, count: 100, query:this.state.search, rootOnly: true}, null, getGlobal(GlobalKey.TOKEN));
    else
      result = await fetchAPI(AdminAPI.GET_LIST, "", {start:0, count: 100, board:this.state.category, query:this.state.search}, null, getGlobal(GlobalKey.TOKEN));
    console.log(result, this.props.aliveOnly);
    if(result && !result.error){
      if(!result.data || result.data.length <100)
        this.finished = true;
      this.setState({contents: (result.data?result.data:[])});

      if(result.data && result.data.length == 1)
        this.onSelect(result.data[0])
    }
    else{
      this.finished = true;
      this.setState({contents: []});
    }
  }

  fetchDataMore = async () => {
    let result;
    if(this.props.aliveOnly)
      result = await fetchAPI(API.BOARD_SIMPLE_LIST, this.state.category.toString(), {start:this.state.contents.length, count: 100, query:this.state.search, rootOnly: true}, null, getGlobal(GlobalKey.TOKEN));
    else
      result = await fetchAPI(AdminAPI.GET_LIST, "", {start:this.state.contents.length, count: 100, board:this.state.category, query:this.state.search}, null, getGlobal(GlobalKey.TOKEN));
    console.log(result, this.props.aliveOnly);
    if(result && !result.error){
      if(!result.data || result.data.length <100)
        this.finished = true;
      let addResult = result.data?result.data:[]
      this.setState({contents: [...this.state.contents, ...addResult]});
    }
  }

  clearSearch = () => {
    this.setState({search: ''});
  }

  onChange = () => {
    this.fetchData();
  }


  render() {
    log(LogLevel.UI_EVENT, "BoardContentSelect:render");
    let loadMore;
    if(!this.finished)
      loadMore = (
        <IonButton color="primary" onClick={this.fetchDataMore}>
          더 불러오기
        </IonButton>
      );

    return (
      <div className="admin-container">
        <div className="admin-board-search-container">
          <IonInput class="admin-board-search-input" placeholder="검색" value={this.state.search} onIonChange={this.onSearchChange}/>
          <IonButton fill="clear" color="primary" onClick={this.clearSearch}>
            <IonIcon name="close"/>
          </IonButton>
          <IonSelect class="admin-board-search-level" value={this.state.category.toString()} onIonChange={this.onCategoryChange}>
            <IonSelectOption value={"1"}>Q&A</IonSelectOption>
            <IonSelectOption value={"2"}>자유게시판</IonSelectOption>
            <IonSelectOption value={"3"}>공지사항</IonSelectOption>
            <IonSelectOption value={"4"}>FAQ</IonSelectOption>
            <IonSelectOption value={"5"}>버그게시판</IonSelectOption>
            <IonSelectOption value={"8"}>이슈토론</IonSelectOption>
            <IonSelectOption value={"6"}>Test</IonSelectOption>
          </IonSelect>
        </div>
        <div className="admin-board-list-container">
          <IonList>
            { this.state.contents.map((item:BoardContent, index: number) => {
              let counts;
              if(!item.groupParent){
                counts = <div>View {item.viewCnt} - Comment {item.commentCnt} - Like {item.likeCntAll} - Metoo {item.meTooCnt} - Reply {item.replyCnt}</div>
              }
              return (
                <div key={index.toString()} className="admin-board-list-item-container" onClick={() => this.onSelect(item)}>
                  <div className="admin-row"> 
                    <div className="admin-grow">{item.id} - {item.groupParent} - {item.groupId} {item.deletedAt?"[삭제]":""}</div>
                    <IonIcon name={(!item.managedAt || (item.modifiedAt && (item.managedAt < item.modifiedAt)))?"help":"checkmark"}/>
                  </div>
                  <div>{item.subject} </div>
                  <Truncate lines={3} ellipsis="...">
                    {item.bodyText}
                  </Truncate>
                  {counts}
                </div>
              );
            })}              
          </IonList>
          {loadMore}
        </div>
      </div>
    );
  }
}

export default withRouter(BoardContentSelect);