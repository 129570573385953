import { SearchKeywordInfo } from '../../models/Model.Search';
import {BoardContent} from './../../models/Model.Board'

export interface SearchState {
  searchText: string;
  total: number;
  loading: boolean;
  searched: any[];
  hotKeywords: SearchKeywordInfo[];
  suggestedKeywords: SearchKeywordInfo[];
  keywordsResults: any;
  order: string;
}

export enum SearchActionTypes{
  SEARCH_SET = '@@search/SEARCH_SET',
  SEARCH_RESET = '@@search/SEARCH_RESET',
  SEARCH_REQUEST = '@@search/SEARCH_REQUEST',
  SEARCH_SUCCESS = '@@search/SEARCH_SUCCESS',
  SEARCH_ERROR = '@@search/SEARCH_ERROR',
  SEARCH_SORT = '@@search/SEARCH_SORT',
  SEARCH_KEYWORD_LOAD = '@@search/SEARCH_KEYWORD_LOAD',
  SEARCH_KEYWORD_REQUEST = '@@search/SEARCH_KEYWORD_REQUEST',
  SEARCH_KEYWORD_SUCCESS = '@@search/SEARCH_KEYWORD_SUCCESS',
  SEARCH_KEYWORD_ERROR = '@@search/SEARCH_KEYWORD_ERROR',
  SEARCH_KEYWORD_RELATIVES_REQUEST = '@@search/SEARCH_KEYWORD_RELATIVES_REQUEST',
  SEARCH_KEYWORD_RELATIVES_SUCCESS = '@@search/SEARCH_KEYWORD_RELATIVES_SUCCESS',
  SEARCH_KEYWORD_RELATIVES_ERROR = '@@search/SEARCH_KEYWORD_RELATIVES_ERROR',
}
