import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { hideBottomSheet } from "../store/modal/bottomsheet";
import { UIPopupType } from "../store/ui/types";

const useClipboard = () => {
  const dispatch = useDispatch();
  const { popups } = useSelector((state: RootState) => state.ui);

  const copyToClipboard = useCallback(
    (value: string) => async () => {
      if (window.isSecureContext && navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(value);
          popups[UIPopupType.TOAST_POPUP].show("클립보드에 복사되었습니다.");
          dispatch(hideBottomSheet());
        } catch (err) {
          console.error("Unable to copy to clipboard", err);

          const textArea = document.createElement("textarea");
          textArea.value = value;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand("copy");
            popups[UIPopupType.TOAST_POPUP].show("클립보드에 복사되었습니다.");
            dispatch(hideBottomSheet());
          } catch (err) {
            console.error("Unable to copy to clipboard", err);
          }
          document.body.removeChild(textArea);
        }
      } else {
        // This is unsecured copy
        const textArea = document.createElement("textarea");
        textArea.value = value;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          popups[UIPopupType.TOAST_POPUP].show("클립보드에 복사되었습니다.");
          dispatch(hideBottomSheet());
        } catch (err) {
          console.error("Unable to copy to clipboard", err);
        }
        document.body.removeChild(textArea);
      }
    },
    []
  );

  return { copyToClipboard };
};

export default useClipboard;
