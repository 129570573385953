

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonCheckbox, IonIcon, IonToggle } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import { timeout, async } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName, RegisterRoute } from '../../../models/Model.User';
import { getGlobal, GlobalKey, setGlobal } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import { UIPopupType } from '../../../store/ui/types';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { getDateTimeString } from '../../../utils/TimeUtil';

const API = {        
  "USER_STATISTICS" : {"method":"POST", "path":"/admin/user/statistics", "contentType":"application/json"},
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  user: UserInfo;
};

type State = {
  fold: boolean;
  loading: boolean;
  dateStart: string;
  dateStartWarn : boolean;
  dateEnd: string;
  dateEndWarn : boolean;
  periodOption: number; // 0: all, 1: day, 2: week, 3: month, 4: year
  searchOptions: number; 
  showOptions: number;
  showGraph: boolean;
  graphSmooth : boolean;
  statistics: UserStatistics;
}


interface UserStatistics{
  id?: number,
  unit?: number, // 0: all, 1: day, 2: week, 3: month, 4: year
  count?: number,
  startDate?:string,
  endDate?:string,
  selection?: number,
  items?: UserStatisticsItem[],
}

export enum UserStatisticsSelection{
  NONE = 0,
  VISIT = 0x01,
  WRITE = 0x02,
  ACTION = 0x04,
  ACTED = 0x08,
  ALL = 0x0f,
}

export interface UserStatisticsItem{
  startDate?:string,
  endDate?:string,
  date?:string,
  visit?: number,
  ask?: number,
  write?: number,
  answer? : number,
  post?: number,
  view?: number,
  react?: number,
  like?: number,
  share?: number,
  metoo?: number,
  scrap? : number,
  comment?: number,
  reacted?: number,
  liked?: number,
  shared?: number,
  metooed?: number,
  scrapped?: number,
  commented?: number,
}

enum UserStatisticsShowSelection{
  NONE = 0,
  VISIT = 0x01,
  ASK = 0x02,
  ANSWER = 0x04,
  POST = 0x08,
  VIEW = 0x10,
  LIKE = 0x20,
  SHARE = 0x40,
  METOO = 0x80,
  SCRAP = 0x100,
  LIKED = 0x200,
  SHARED = 0x400,
  METOOED = 0x800,
  SCRAPPED = 0x1000,
  REACT = 0x2000,
  REACTED = 0x4000,
  COMMENT = 0x8000,
  COMMENTED = 0x10000,
  WRITE = 0x20000,
  ALL = 0x1ffff,
}

const showfilters = [
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.VISIT], showSelection:UserStatisticsShowSelection.VISIT, searchOption: UserStatisticsSelection.VISIT, field:"visit", color:"red"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.WRITE],   showSelection:UserStatisticsShowSelection.WRITE, searchOption: UserStatisticsSelection.WRITE, field:"write", color:"#0b6600"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.ASK],   showSelection:UserStatisticsShowSelection.ASK, searchOption: UserStatisticsSelection.WRITE, field:"ask", color:"#0b6623"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.ANSWER], showSelection:UserStatisticsShowSelection.ANSWER, searchOption: UserStatisticsSelection.WRITE, field:"answer", color:"#01796f"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.POST], showSelection:UserStatisticsShowSelection.POST, searchOption: UserStatisticsSelection.WRITE, field:"post", color:"#50c878"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.VIEW], showSelection:UserStatisticsShowSelection.VIEW, searchOption: UserStatisticsSelection.ACTION, field:"view", color:"#000080"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.REACT], showSelection:UserStatisticsShowSelection.REACT, searchOption: UserStatisticsSelection.ACTION, field:"react", color:"#d50032"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.LIKE], showSelection:UserStatisticsShowSelection.LIKE, searchOption: UserStatisticsSelection.ACTION, field:"like", color:"#e03c31"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.SHARE], showSelection:UserStatisticsShowSelection.SHARE, searchOption: UserStatisticsSelection.ACTION, field:"share", color:"#ea7600"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.METOO], showSelection:UserStatisticsShowSelection.METOO, searchOption: UserStatisticsSelection.ACTION, field:"metoo", color:"#93272c"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.SCRAP], showSelection:UserStatisticsShowSelection.SCRAP, searchOption: UserStatisticsSelection.ACTION, field:"scrap", color:"#651d32"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.COMMENT], showSelection:UserStatisticsShowSelection.COMMENT, searchOption: UserStatisticsSelection.ACTION, field:"comment", color:"#651d00"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.REACTED], showSelection:UserStatisticsShowSelection.REACTED, searchOption: UserStatisticsSelection.ACTED, field:"reacted", color:"#002855"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.LIKED], showSelection:UserStatisticsShowSelection.LIKED, searchOption: UserStatisticsSelection.ACTED, field:"liked", color:"#0097a9"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.SHARED], showSelection:UserStatisticsShowSelection.SHARED, searchOption: UserStatisticsSelection.ACTED, field:"shared", color:"#8db9ca"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.METOOED], showSelection:UserStatisticsShowSelection.METOOED, searchOption: UserStatisticsSelection.ACTED, field:"metooed", color:"#a4dbe8"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.SCRAPPED], showSelection:UserStatisticsShowSelection.SCRAPPED, searchOption: UserStatisticsSelection.ACTED, field:"scrapped", color:"#003d4c"},
  {name:UserStatisticsShowSelection[UserStatisticsShowSelection.COMMENTED], showSelection:UserStatisticsShowSelection.COMMENTED, searchOption: UserStatisticsSelection.ACTED, field:"commented", color:"#003d00"},
];

class UserStatisticsView extends Component<Props, State> {

  constructor(props:Props){
    super(props);
    log(LogLevel.UI_DATA_LOAD, "UserStatisticsView constructor", this.props.user);
    let date = new Date(Date.now() + 9*60*60*1000);
    let fromDate = new Date(Date.now() + 9*60*60*1000);
    let toDate = new Date(Date.now() + 9*60*60*1000);
    fromDate.setDate(date.getDate() - 27);
    toDate.setDate(date.getDate() + 1);

    let showOptions = getGlobal(GlobalKey.ADMIN_STATISTICS_USER_SHOW_OPTION, true);

    this.state = {
      fold: true,
      loading: false,
      dateStart: fromDate.toISOString().substring(0,10),
      dateStartWarn : false,
      dateEnd: toDate.toISOString().substring(0,10),
      dateEndWarn : false,
      periodOption: 1,
      searchOptions: UserStatisticsSelection.ALL,
      showOptions,
      statistics: null,
      showGraph: false,
      graphSmooth: false,

    };
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.user && (!this.props.user || this.props.user.id != nextProps.user.id)){
      this.setState({statistics:null, fold:false})
      log(LogLevel.UI_DATA_LOAD, "UserStatisticsView:componentWillUpdate", nextProps.user, this.state);
    }
  }

  fetchStatistics = async () => {
    if(this.state.loading){
      this.props.toastPopup.show("로딩중입니다.");
      return;
    }
    if(this.state.dateStartWarn || this.state.dateEndWarn || !this.state.searchOptions){
      this.props.toastPopup.show("검색옵션을 확인해주세요.");
      return;
    }

    let request:UserStatistics = {};
    request.id = this.props.user.id;
    request.unit = this.state.periodOption;
    request.startDate = this.state.dateStart;
    request.endDate = this.state.dateEnd;
    request.selection = this.state.searchOptions;

    fetchAPI(API.USER_STATISTICS, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error && result.data){
        log(LogLevel.UI_DATA_LOAD, "UserStatisticsView Fetch success", request, result);
        this.setState({statistics:result.data});
      }
      else{
        log(LogLevel.UI_EXCEPTION, "UserStatisticsView Fetch failed", request, result);
      }
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "UserStatisticsView Fetch excepted", request, e);
    })

  }

  onChangeShowOption = (option) => {
    let showOptions = this.state.showOptions ^ option;
    this.setState({showOptions})
    setGlobal(GlobalKey.ADMIN_STATISTICS_USER_SHOW_OPTION, showOptions, true);
  }

  onDateStartChange = (e) => {
    let dateStart = e.target.value;
    let dateStartWarn = false;
    try{
      let date = Date.parse(dateStart);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "UserStatisticsView:onDateStartChange", dateStart);
        dateStartWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "UserStatisticsView:onDateStartChange", dateStart, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "UserStatisticsView:onDateStartChange", dateStart, err);
      console.log(err);
      dateStartWarn = true;
    }
    this.setState({dateStart, dateStartWarn});
  }

  onDateEndChange = (e) => {
    let dateEnd = e.target.value;
    let dateEndWarn = false;
    try{
      let date = Date.parse(dateEnd);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "UserStatisticsView:onDateEndChange", dateEnd);
        dateEndWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "UserStatisticsView:onDateEndChange", dateEnd, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "UserStatisticsView:onDateEndChange", dateEnd, err);
      dateEndWarn = true;
    }
    this.setState({dateEnd, dateEndWarn});
  }

  setPeriod = (pid) => {
    let date = new Date(Date.now() + 9*60*60*1000);
    let fromDate = new Date(Date.now() + 9*60*60*1000);
    let toDate = new Date(Date.now() + 9*60*60*1000);
    switch(pid){
      case 1: // 4주
        fromDate.setDate(date.getDate() - 27);
        toDate.setDate(date.getDate() + 1);
        break;
      case 2: // 12주
        fromDate.setDate(date.getDate() - 83);
        toDate.setDate(date.getDate() + 1);
        break;
      case 3: // 서비스개시부터
        fromDate = new Date("2019-07-10");
        toDate.setDate(date.getDate() + 1);
        break;
      case 4: // 가일일부터
        fromDate = new Date(this.props.user.createdAt?this.props.user.createdAt.substring(0,10):"2019-07-10");
        toDate.setDate(date.getDate() + 1);
        break;
    }
    this.setState({
      dateStart: fromDate.toISOString().substring(0,10),
      dateStartWarn : false,
      dateEnd: toDate.toISOString().substring(0,10),
      dateEndWarn : false,
    })
  }

  onChangeSelectOption = (item) => {
    let searchOptions = this.state.searchOptions ^ item; 
    this.setState({searchOptions});
  }

  onDownload = () => {
    if(!this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return;
    
    let csv = ",";
    csv += this.state.statistics.items.map((item) => item.date).join(",");
    csv += "\r\n";
    csv += showfilters.map((item, index) => {
      let results = this.state.statistics;
      if(!(results.selection & item.searchOption) || !(this.state.showOptions & item.showSelection))
        return "";
      return (
        item.name + "," + this.state.statistics.items.map((item2) => item2[item.field].toString()).join(",") + "\r\n"
      );
    }).join("");

    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    pom.setAttribute('download', `User-Statistics-${this.props.user.id}-${this.props.user.name}-${this.props.user.nickname}-${this.state.statistics.startDate.replace(/-/ig, '')}-${this.state.statistics.endDate.replace(/-/ig, '')}-${this.state.statistics.unit}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
  }

  renderTable = () => {
    if(this.state.showGraph || !this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return null;
    
    let label = (
      <tr>
        <td/>
        {this.state.statistics.items.map((item, index) => 
            <td className="admin-table-label-x" key={index.toString()}>{item.date}</td>
        )}
      </tr>
    );

    let data = showfilters.map((item, index) => {
      let results = this.state.statistics;
      if(!(results.selection & item.searchOption) || !(this.state.showOptions & item.showSelection))
        return null;
      return (
        <tr key={index.toString()}>
          <td className="admin-table-label-y">{item.name}</td>
          {this.state.statistics.items.map((item2, index2) => {
            let value = item2[item.field];
            if(!value && value != 0){
              log(LogLevel.UI_EXCEPTION, "renderTable", item, item2)
              value = 0;
            }
            return (
              <td className="admin-table-value" key={index2.toString()}>{value.toString()}</td>
            );

          })}
        </tr>
      );

    });

    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <table className="admin-table">
            <tbody>
              {label}
              {data}
            </tbody>
          </table>
        </div>
        <IonButton color="primary" onClick={this.onDownload}>
          다운로드
        </IonButton>
      </div>
    );
    

  }

  renderGraph = () => {
    if(!this.state.showGraph || !this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return null;

    let width = 150 * this.state.statistics.items.length;
    if(width > 800)
      width = 800;
    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <LineChart width={800} height={500} data={this.state.statistics.items}>
            {showfilters.map((item, index) => {
                let results = this.state.statistics;
                if(!(results.selection & item.searchOption) || !(this.state.showOptions & item.showSelection))
                  return null;
                return (
                  <Line key={index.toString()} type={this.state.graphSmooth?"monotone":"linear"} dataKey={item.field} stroke={item.color} />
                );
          
            })}
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
          </LineChart>
        </div>
        <IonButton color="primary" onClick={this.onDownload}>
          다운로드
        </IonButton>
      </div>
    );
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "UserStatisticsView:render", this.props, this.state);

    if(!this.props.user)
      return (
        <div className="common-container"/>
      );

    if(this.state.fold){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({fold:false})}>
            <div className="common-flex-row">
              <div>Individual</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 

      );
    }

    let smooth;
    if(this.state.showGraph)
      smooth = (
        <div className="common-flex-row">
          <IonCheckbox checked={this.state.graphSmooth} onIonChange={(e) => this.setState({graphSmooth:!this.state.graphSmooth})}/>
          <div className="admin-margin-right">Smooth Line</div>
        </div>
      );

    let routeSocial, routeRecommend, routeConference, routeShare, routeNews, routeEtc, routeFacebook;
    if(this.props.user.registerRoute){
      if(this.props.user.registerRoute.includes(RegisterRoute.KAKAOTALK))
        routeSocial = (<div className="admin-toggle">카톡방</div>);
      if(this.props.user.registerRoute.includes(RegisterRoute.CONTENT))
        routeShare = (<div className="admin-toggle">공유컨텐츠</div>);
      if(this.props.user.registerRoute.includes(RegisterRoute.EXPO))
        routeConference = (<div className="admin-toggle">부스방문</div>);
      if(this.props.user.registerRoute.includes(RegisterRoute.INVITE))
        routeRecommend = (<div className="admin-toggle">친추</div>);
      if(this.props.user.registerRoute.includes(RegisterRoute.NEWS))
        routeNews = (<div className="admin-toggle">뉴스</div>);
      if(this.props.user.registerRoute.includes(RegisterRoute.FACEBOOK))
        routeFacebook = (<div className="admin-toggle">Facebook</div>);
      if(this.props.user.registerRoute.includes(RegisterRoute.ETC))
        routeEtc = (<div>{this.props.user.registerRouteEtc}</div>);
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({fold:true})}>
          <div className="common-flex-row">
            <div>Individual</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="admin-normal-text">ID : {this.props.user.id}</div>
        <div className="admin-normal-text">닉네임 : {this.props.user.nickname}</div>
        <div className="admin-normal-text">연락처 : {this.props.user.phone}</div>
        <div className="admin-normal-text">이메일 : {this.props.user.email}</div>
        <div className="admin-normal-text">근무정보 : {UserWorkTypeName[this.props.user.workType]}</div>
        <div className="admin-normal-text">근무지 : {this.props.user.workPlaceRegion} {this.props.user.workPlaceName} </div>
        <div className="admin-normal-text">이름 : {this.props.user.name} </div>
        <div className="admin-normal-text">면허번호 : {this.props.user.license} </div>
        <div className="admin-normal-text">생년/성별 : {this.props.user.birthGender} </div>
        <div className="admin-normal-text">가입일 : {getDateTimeString(this.props.user.createdAt)} </div>
        <div className="common-flex-row">
            <div className="admin-normal-text">가입경로 : </div>
            {routeSocial}
            {routeRecommend}
            {routeConference}
            {routeShare}
            {routeNews}
            {routeFacebook}
            {routeEtc}
          </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Search Option</div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <input className={(this.state.dateStartWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.dateStart} onChange={this.onDateStartChange}/>
                <div>~</div>
                <input className={(this.state.dateEndWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.dateEnd} onChange={this.onDateEndChange}/>
                <div className="admin-text-button" onClick={() => this.setPeriod(1)}>4주</div>
                <div className="admin-text-button" onClick={() => this.setPeriod(2)}>12주</div>
                <div className="admin-text-button" onClick={() => this.setPeriod(3)}>서비스개시부터</div>
                <div className="admin-text-button" onClick={() => this.setPeriod(4)}>가입일부터</div>
              </div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <div>단위기간</div>
                <div className={"admin-toggle" + ((this.state.periodOption==0)?" admin-toggle-selected":"")} onClick={() => this.setState({periodOption:0})}>All</div>
                <div className={"admin-toggle" + ((this.state.periodOption==1)?" admin-toggle-selected":"")} onClick={() => this.setState({periodOption:1})}>Daily</div>
                <div className={"admin-toggle" + ((this.state.periodOption==2)?" admin-toggle-selected":"")} onClick={() => this.setState({periodOption:2})}>Weekly</div>
                <div className={"admin-toggle" + ((this.state.periodOption==3)?" admin-toggle-selected":"")} onClick={() => this.setState({periodOption:3})}>Monthly</div>
              </div>
              <div className="common-container-row-wrap">
                <div className="common-flex-row">
                  <IonCheckbox checked={(this.state.searchOptions & UserStatisticsSelection.VISIT)!=0} onClick={(e) => {this.setState({searchOptions:(this.state.searchOptions ^ UserStatisticsSelection.VISIT)});}}/>
                  <div className="admin-margin-right">방문일수</div>
                </div>
                <div className="common-flex-row">
                  <IonCheckbox checked={(this.state.searchOptions & UserStatisticsSelection.WRITE)!=0} onClick={(e) => {this.setState({searchOptions:(this.state.searchOptions ^ UserStatisticsSelection.WRITE)});}}/>
                  <div className="admin-margin-right">작성글수</div>
                </div>
                <div className="common-flex-row">
                  <IonCheckbox checked={(this.state.searchOptions & UserStatisticsSelection.ACTION)!=0} onClick={(e) => {this.setState({searchOptions:(this.state.searchOptions ^ UserStatisticsSelection.ACTION)});}}/>
                  <div className="admin-margin-right">액션수</div>
                </div>
                <div className="common-flex-row">
                  <IonCheckbox checked={(this.state.searchOptions & UserStatisticsSelection.ACTED)!=0} onClick={(e) => {this.setState({searchOptions:(this.state.searchOptions ^ UserStatisticsSelection.ACTED)});}}/>
                  <div className="admin-margin-right">피액션수</div>
                </div>
              </div>              
            </div>
          </div>
          <IonButton onClick={this.fetchStatistics} disabled={this.state.loading}>Load</IonButton>
        </div>
        <div className="common-container-row admin-show-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Display Option</div>
              <div className="common-container-row">
                <div className="common-container-column">
                  <div className="search-options-container">
                    <div className={(this.state.showGraph)?"search-option-text-inactive":"search-option-text-active"} onClick={(e) => {this.setState({showGraph:false})}}>Table</div>
                    <IonToggle class="search-options-toggle" disabled={!this.state.statistics} mode="ios" checked={this.state.showGraph} onClick={(e) => this.setState({showGraph:(!this.state.showGraph)})}/>
                    <div className={(this.state.showGraph)?"search-option-text-active":"search-option-text-inactive"} onClick={(e) => {this.setState({showGraph:true})}}>Graph</div>
                  </div>
                  {smooth}
                </div>
                <div className="common-flex-row-wrap common-flex-grow">
                  {showfilters.map((item, index) => 
                    <div className="common-flex-row" key={index.toString()}>
                      <IonCheckbox disabled={!this.state.statistics || !(this.state.statistics.selection & item.searchOption)} checked={!!(this.state.showOptions & item.showSelection)} onIonChange={(e) => this.onChangeShowOption(item.showSelection)}/>
                      <div className="admin-margin-right" style={{color:item.color, fontWeight:"bold"}}>{item.name}</div>
                    </div>
                  )}
                </div>
              </div>              
            </div>
          </div>
        </div>
        {this.renderTable()}
        {this.renderGraph()}

      </div>
    );


  }
}


const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup : state.ui.popups[UIPopupType.TOAST_POPUP]
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
}
export default connect(mapStateToProps, mapDispatchToProps)(UserStatisticsView);