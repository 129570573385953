import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../store";
import { fetchPost, setScrollPos, sortSearch } from "../../../store/search";
import Spinner from "../../atoms/Spinner";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import SearchedBoardCard from "./SearchedBoardCard";
import styled from "styled-components";
import SearchedSeminarCard from "./SearchedSeminarCard";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Flex } from "../../atoms/Layout";
import SearchedPartnerCard from "./SearchedPartnerCard";
import useBottomSheet from "../../../hooks/useBottomSheet";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";
import { BoardSortType } from "../../../store/board/types";
import Button from "../../atoms/Button";
// import { fetchSearch, setSearchText, sortSearch } from ""
import Icon from "../../atoms/Icon";

const SortName = {
  createdAt: "최신순",
  score: "연관도 순",
  view: "조회수 순",
};

const SearchedBoardListContainer = styled.div`
  height: calc(100% - 108px);
  padding: 20px 0 40px 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  overflow-y: auto;
`;

const TotalCountDiv = styled.div`
  /* margin-bottom: 16px; */
  align-items: center;
  /* padding-left: 24px; */
`;
const FilterDiv = styled.div`
  /* margin-bottom: 16px; */
  /* padding-left: 24px; */
`;

const LoadMoreLoadingDiv = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchedBoardListUl = styled.ul`
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SearchedBoardList = () => {
  try {
    const dispatch = useDispatch();
    const bottomSheet = useBottomSheet();

    const searchedList = useSelector(
      (state: RootState) => state.newSearch.post.searchedList
    );
    const searchedCount = useSelector(
      (state: RootState) => state.newSearch.post.searchedCount
    );
    const scrollPosition = useSelector(
      (state: RootState) => state.newSearch.post.scrollPosition
    );

    const selectedSort = useSelector(
      (state: RootState) => state.newSearch.post.sort
    );

    const listEl = useRef<HTMLDivElement>();

    const [partnerCount] = useState(
      searchedList.filter((content) => content.partner).length
    );

    const handleClickSortItem = (sort: string) => () => {
      dispatch(sortSearch(sort));
      dispatch(fetchPost(true));

      bottomSheet.close();

      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEARCH_SORT", "", {
        Sort: BoardSortType[sort],
        // 게시판이름: category.name,
      });
    };

    useEffect(() => {
      if (listEl.current) {
        listEl.current.scrollTop = scrollPosition;
      }

      return () => {
        dispatch(setScrollPos("Post", listEl.current.scrollTop));
      };
    }, [listEl]);

    const handleClickSort = useCallback(() => {
      bottomSheet.show({
        title: {
          value: "정렬 기준",
          align: "left",
        },
        body: (
          <BottomSheetList>
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      selectedSort === "createdAt" ? 700 : 400
                    ]
                  }
                >
                  최신순
                </Text>
              }
              right={
                selectedSort == "createdAt" ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem("createdAt")}
            />
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      selectedSort == "score" ? 700 : 400
                    ]
                  }
                >
                  연관도 순
                </Text>
              }
              right={
                selectedSort == "score" ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem("score")}
            />
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[selectedSort == "view" ? 700 : 400]
                  }
                >
                  조회수 순
                </Text>
              }
              right={
                selectedSort == "view" ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem("view")}
            />
          </BottomSheetList>
        ),
      });
    }, [selectedSort]);

    return (
      <SearchedBoardListContainer ref={listEl}>
        <SearchedBoardListUl>
          {searchedList.filter((content) => content.partner).length > 0 && (
            <SearchedPartnerCard
              partners={searchedList.filter((content) => content.partner)}
            />
          )}
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <TotalCountDiv>
              <Text
                textType="Body2Medium"
                color={COLOR_SYSTEM.get("Gray")[800]}
              >
                총 <b>{searchedCount - partnerCount}</b>건이 검색되었습니다.
              </Text>
            </TotalCountDiv>
            <FilterDiv>
              <Flex customStyle={{ justifyContent: "right" }}>
                <Button
                  color="Secondary"
                  size="Small"
                  variant="Ghost"
                  type="IconWithText"
                  icon="Swap Vertical"
                  left
                  onClick={handleClickSort}
                >
                  {SortName[selectedSort]}
                </Button>
              </Flex>
            </FilterDiv>
          </Flex>
          {searchedList.map((content) =>
            content.posting ? (
              <Link
                to={`/boards/view?id=${content.posting.id}`}
                key={content.posting.id}
              >
                <SearchedBoardCard board={content.posting} />
              </Link>
            ) : content.seminar ? (
              <Link
                to={`/lecture?id=${content.seminar.id}`}
                key={content.seminar.id}
              >
                <SearchedSeminarCard seminar={content.seminar} />
              </Link>
            ) : (
              <></>
            )
          )}
        </SearchedBoardListUl>
        {searchedList.length < searchedCount && (
          <ReactVisibilitySensor
            onChange={(visible) => {
              if (visible) dispatch(fetchPost());
            }}
          >
            <LoadMoreLoadingDiv>
              <Spinner />
            </LoadMoreLoadingDiv>
          </ReactVisibilitySensor>
        )}
      </SearchedBoardListContainer>
    );
  } catch (err) {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "Search_Error",
      "검색통합에러",
      {
        page: "Search",
        where: "SearchedBoardList",
        errorMessage: err,
      }
    );
  }
};

export default SearchedBoardList;
