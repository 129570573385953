import React, { Component } from 'react';
import {log, LogLevel} from '../../utils/LogUtil'
import "./PollSimplePage.css"
import "./Common.css"
import ReactHtmlParser from 'react-html-parser'
import { PollResponseItemInfo, PollItemInfo } from '../../models/Model.Poll';
import Textarea from 'react-textarea-autosize';


// type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
type Props = {
    key?: string,
    item: PollItemInfo,
    response?: PollResponseItemInfo,
    index: number,
    onReponse?: (pollItemindex: number, response: PollResponseItemInfo) => void,
}

type State = {
    index: number;
    text: string;
}

class PollSimplePageItemSelection extends Component<Props, State> {
    input = null;
    
    constructor(props: Props) {
        super(props);
        log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemText:constructor",  props);
        let index = -2, text = "";
        if(this.props.response){
            index = this.props.item.candidates.indexOf(this.props.response.value);
            if(index == -1 && this.props.item.needInput)
                text = this.props.response.value;
        }
        this.state = {
            index, text
        };
    }

    shouldComponentUpdate(nextProv, nextState){
        if(this.props.item.id != nextProv.item.id || this.state.text != nextState.text || this.state.index != nextState.index)
            return true;

        return false;
    }

    componentDidMount() { 
    }

    onTextChange = (e) => {
        this.setState({text:e.target.value})
        if(this.props.onReponse)
            this.props.onReponse(this.props.index, {
                ...this.props.response,
                pollItemId: this.props.item.id,
                value: e.target.value,
            })
    }

    onKeyPress = (e) => {
        // log(LogLevel.UI_ACTION, "PollSimplePageItemText:onKeyPress", e.keyCode, e.shiftKey)
        if(e.keyCode == 13){
            e.preventDefault();
        }
    }

    onSelect = (e, index) => {
        this.setState({index});
        if(index >= 0)
            this.props.onReponse(this.props.index, {
                ...this.props.response,
                pollItemId: this.props.item.id,
                value: this.props.item.candidates[index],
            })
        else{
            this.props.onReponse(this.props.index, {
                ...this.props.response,
                pollItemId: this.props.item.id,
                value: this.state.text,
            })
            if(this.input)
                this.input.focus;
            e.stopPropagation();
        }
    }

    render() {
        log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemText render: ", this.props.item, this.props.response);

        if(!this.props.item.candidates || !this.props.item.candidates.length)
            return null;

        let descriptionElement;
        if(this.props.item.description)
            descriptionElement = (
                <div className="poll-simple-page-item-text poll-simple-page-item-margin">{ReactHtmlParser(this.props.item.description,{})}</div>
            );

        let inputElement, title, prefix, postfix;
        if(this.props.item.needInput){
            if(this.props.item.inputTitle)
                title = <div className={"poll-simple-page-item-text" + (this.state.index == -1?" common-bold":"")} style={{marginRight:"15px"}}>{this.props.item.inputTitle}</div>
            if(this.props.item.inputPrefix)
                prefix = <div className={"poll-simple-page-item-text"} style={{marginRight:"5px"}}>{this.props.item.inputPrefix}</div>
            if(this.props.item.inputPostfix)
                postfix = <div className={"poll-simple-page-item-text"} style={{marginLeft:"5px"}}>{this.props.item.inputPostfix}</div>

            inputElement = (
                <div className="common-container-row common-flex-align-center poll-simple-page-item-margin" onClick={(e) => this.onSelect(e, -1)}>
                    <input className="poll-simple-page-radio" type="radio" checked={this.state.index == -1}  onChange={() => {}}/>
                    {title}
                    {prefix}
                    <Textarea inputRef={(ref) => {this.input = ref}} minRows={1} maxRows={1} className="poll-simple-page-input-inline" onChange={this.onTextChange} value={this.state.text} onClick={(e)=>{e.stopPropagation();}}  onKeyDown={(e) => this.onKeyPress(e)}/>
                    {postfix}
                </div>
            );
        }

        return (
            <div className="poll-simple-page-item-container">
                <div className="poll-simple-page-item-question">{this.props.item.question}</div>
                {descriptionElement}
                {this.props.item.candidates.map((item,index) => {
                    return (
                        <div key={index.toString()} className="common-container-row common-flex-align-center poll-simple-page-item-margin" onClick={(e) => this.onSelect(e, index)}>
                            <input className="poll-simple-page-radio" type="radio" checked={this.state.index == index} onChange={() => {}}/>
                            <div className={"poll-simple-page-item-text" + (this.state.index == index?" common-bold":"")}>{item}</div>
                        </div>
                    );
                })}
                {inputElement}
            </div>
        );

        return null;
    }
}

// const mapStateToProps = (state: RootState) => ({
//   me: state.user.me,
//   informationPopup : state.ui.popups[UIPopupType.INFORMATION_POPUP],
// });

// const mapDispatchToProps = {

// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollSimplePageItemSelection));
export default PollSimplePageItemSelection;