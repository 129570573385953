import React, { Component } from 'react';
import './CheckToggleButton.css';
import { IonButton, IonIcon, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import {log, LogLevel} from '../utils/LogUtil'

/**
 * status: 0:unchecked 1:checking 2:checked 3:warning
 */
type Props = {
  onClick? : (checked:boolean)=>void;
  checked? : boolean;
};

class CheckToggleButton extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    log(LogLevel.NONE, this.props)
    if(this.props.checked)
      return(
        <IonButton fill="clear" color="toggle-checked" onClick={() => {if(this.props.onClick)this.props.onClick(!this.props.checked)}}>
          <IonIcon class="check-toggle-icon" name="checkmark"/>
        </IonButton>
      );
    else
      return(
        <IonButton fill="clear" color="toggle-unchecked" onClick={() => {if(this.props.onClick)this.props.onClick(!this.props.checked)}}>
          <IonIcon class="check-toggle-icon" name="checkmark"/>
        </IonButton>
      );
  }
}

export default CheckToggleButton;