import styled from "styled-components";
import { CardType } from ".";
import { COLOR_SYSTEM } from "../../design/design-system";

interface JobPostCardStyledProps {
  ended: boolean;
  cardType: CardType;
  isPremium: boolean;
}

const JobPostCardWrapper = styled.div<JobPostCardStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: ${({ isPremium }) =>
    isPremium
      ? `1px solid ${COLOR_SYSTEM.get("Gray")[700]}`
      : `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`};
  border-radius: 16px;
  /* padding: ${({ cardType }) =>
    cardType === "Me" ? "20px" : "20px 20px 10px 20px"}; */
  padding: 20px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: relative;
  transform: translateZ(0);
`;

const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
`;

const CardTopLeft = styled.div`
  display: flex;

  & > * + * {
    margin-left: 8px;
  }
`;

const CardMain = styled.div<JobPostCardStyledProps>`
  width: 100%;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  opacity: ${({ ended }) => (ended ? 0.5 : 1)};

  & > * + * {
    margin-top: 8px;
  }
`;

const CardMainDetails = styled.dl`
  display: flex;
  flex-direction: column;
`;

const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;

  & > * + * {
    margin-left: 12px;
  }
`;

const DetailsTitle = styled.dt`
  display: inline-flex;
  align-items: center;
`;

const DetailsDescription = styled.dd`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  span {
    flex: 1;
  }
`;

const CardMainBottom = styled.div`
  width: 100%;
`;

const BottomLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Tags = styled.div<JobPostCardStyledProps>`
  display: flex;
  opacity: ${({ ended }) => (ended ? 0.5 : 1)};

  & > * + * {
    margin-left: 8px;
  }
`;

const BottomButtons = styled.div`
  margin-top: 20px;

  button {
    flex: 1;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1 1 33.33%;
  }
`;

export {
  JobPostCardWrapper,
  CardTop,
  CardTopLeft,
  CardMain,
  CardMainBottom,
  CardMainDetails,
  DetailContainer,
  DetailsDescription,
  DetailsTitle,
  BottomLeft,
  BottomButtons,
  ButtonsContainer,
  Tags,
};
