import React, { Component } from 'react';
import './InformationPopup.css';
import './../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';;
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import DynamicCheckerInput, { CheckStatus } from './../DynamicCheckerInput';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';

type Props = {
};

type State = {
  visible: boolean;
}

const windowAny:any = window;

interface InformationPopupOptions{
  body?: any,
  position?: any, //{x, y}
  parent?: any,
}

class InformationPopup extends Component<Props, State> {
  popup = null;
  state={
    visible: false,
  };

  initialOptions:InformationPopupOptions = {
    body : null,
    position: null,
    parent:null,

  }

  options:InformationPopupOptions = {...this.initialOptions};

  show = (options:InformationPopupOptions = {}) => {
    log(LogLevel.UI_ACTION, "InformationPopup:show", options.position)
    this.options = {...this.initialOptions, ...options,}
    if(this.options.body)
      this.setState({visible:true});
  }

  hide = () => {
    log(LogLevel.NONE, "InformationPopup:hide")
    this.setState({visible: false});
  }

  isShown = () => {
    return this.state.visible;
  }

  render() {
    let positionStyle:any = {};
    let bodyStyle: any = {};

    // positionStyle.left = "0px";
    // bodyStyle.width = "100%";

    if(this.options && this.options.position){

      if(this.options.parent){
        if(this.options.position.y < this.options.parent.clientHeight/2 ){
          positionStyle.top = this.options.position.y + "px";
        }else{
          positionStyle.bottom = (this.options.parent.clientHeight-this.options.position.y) + "px";
        }

        if(this.options.position.x < this.options.parent.clientWidth/3)
          positionStyle.alignItems = "flex-start";
        else if(this.options.position.x < this.options.parent.clientWidth * 2/3)
          positionStyle.alignItems = "center";
        else
          positionStyle.alignItems = "flex-end";
      }else{
        if(this.options.position.y < windowAny.innerHeight/2 ){
          positionStyle.top = this.options.position.y + "px";
        }else{
          positionStyle.bottom = (windowAny.innerHeight-this.options.position.y) + "px";
        }

        if(this.options.position.x < windowAny.innerWidth/3)
          positionStyle.alignItems = "flex-start";
        else if(this.options.position.x < windowAny.innerWidth * 2/3)
          positionStyle.alignItems = "center";
        else
          positionStyle.alignItems = "flex-end";

        positionStyle.left = "0px";
        bodyStyle.minWidth = "40%";
      }
    }
    else{
      positionStyle.top = "30%";
      positionStyle.left = "0px";
      bodyStyle.width = "100%";
    }
    
    return (
      <IonModal cssClass="common-modal-full-popup-container"
        
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="common-modal-popup-inner" onClick={(e) => {this.setState({ visible: false }); e.stopPropagation();}}>
          <div className="common-modal-popup-backdrop"/>
          <div className="information-popup-container" style={positionStyle} >
            <div className="information-popup-body" style={bodyStyle}>
              {this.options.body}
            </div>
          </div>
        </div>
      </IonModal>
    );
  }
}

export default InformationPopup;