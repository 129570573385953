import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonSelect, IonText, IonSelectOption, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonCheckbox } from '@ionic/react';
import './Poc.css'
import './../Common.css'
import {log, LogLevel} from '../utils/LogUtil';
import {getGlobal, GlobalKey} from './../utils/GlobalUtil';
import { BoardType, BoardActionTypes } from '../store/board/types';
import { BoardContent, Attachment, BoardAttribute, QNA_PRESET } from '../models/Model.Board';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';
import InformationPopup from '../components/popup/InformationPopup';
import {MY_ADDRESS} from './../config.json';

type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
}

const windowAny:any = window;

class JumpUrlScheme extends Component<Props, State> {

  popup = null;
  
  constructor(props: Props) {
    super(props);
    this.state={
    }
  }

  componentDidMount() {

  }


  componentWillUnmount() {
  }

  onUrlScheme = () => {
    window.open(`com.onjourney.pharmacycafe://open?url=${MY_ADDRESS}`, "_system");
    
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "JumpUrlScheme.render", this.props, this.state);

    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                  <IonButton>
                  <IonIcon name="arrow-back" mode="ios"/>
                  </IonButton>
                  <IonLabel class="common-header-title">Test</IonLabel>
              </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="common-content-with-header common-scroll">
          <div onClick={this.onUrlScheme}>앱으로 시작하기</div>
        </div>

      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JumpUrlScheme));