import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonModal, IonText, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './Dashboard.css'
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'

import {setGlobal, GlobalKey} from './../utils/GlobalUtil'
import { BoardType } from '../store/board/types';

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  index: number,
}

class Dashboard extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "Dashboard.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                Dashboard
            </IonText>
        </IonHeader>

        <IonContent>

        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));