import * as loadImage from "blueimp-load-image";
import { LogLevel, log } from "./LogUtil";

export function loadImageBase64(
  file: any,
  maxWidth: number = 1920,
  isProfile: boolean = false
) {
  return new Promise((resolve, reject) => {
    let loadOption = { orientation: true, canvas: true };
    log(LogLevel.UI_ACTION, "MyProfile.onImageSelected", file, loadOption);
    try {
      if (file) {
        if (file.type == "image/gif") {
          let reader = new FileReader();
          // convert the file to base64 text
          reader.readAsDataURL(file);
          // on reader load somthing...
          reader.onload = (event) => {
            //console.log(event);
            let targetAny: any = event.target;
            resolve(targetAny.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        } else {
          loadImage(
            file,
            (sourceCanvas) => {
              // console.log(sourceCanvas)

              let sourceWidth = sourceCanvas.width;
              let sourceHeight = sourceCanvas.height;
              let cropX = 0,
                cropY = 0,
                cropW = sourceWidth,
                cropH = sourceHeight;

              let destWidth = sourceWidth,
                destHeight = sourceHeight;
              if (isProfile) {
                let longer =
                  sourceWidth > sourceHeight ? sourceWidth : sourceHeight;
                let size = maxWidth > longer ? longer : maxWidth;

                destWidth = size;
                destHeight = size;

                if (sourceWidth > sourceHeight) {
                  cropW = sourceHeight;
                  cropH = sourceHeight;
                  cropX = (sourceWidth - sourceHeight) / 2;
                  cropY = 0;
                } else {
                  cropW = sourceWidth;
                  cropH = sourceWidth;
                  cropX = 0;
                  cropY = (sourceHeight - sourceWidth) / 2;
                }
              } else {
                let size = maxWidth > sourceWidth ? sourceWidth : maxWidth;

                if (sourceWidth != size) {
                  destWidth = size;
                  destHeight = (size * sourceHeight) / sourceWidth;
                } else {
                  resolve(sourceCanvas.toDataURL("image/jpeg", 0.9));
                }
              }

              let destCanvas = document.createElement("canvas");
              let ctx = destCanvas.getContext("2d");
              ctx.canvas.width = destWidth;
              ctx.canvas.height = destHeight;
              ctx.fillStyle = "white";
              ctx.fillRect(0, 0, destWidth, destHeight);
              log(
                LogLevel.UI_ACTION,
                "loadImageBase64",
                sourceCanvas,
                cropX,
                cropY,
                cropW,
                cropH,
                0,
                0,
                destWidth,
                destHeight
              );

              ctx.drawImage(
                sourceCanvas,
                cropX,
                cropY,
                cropW,
                cropH,
                0,
                0,
                destWidth,
                destHeight
              );
              resolve(destCanvas.toDataURL("image/jpeg", 0.8));
            },
            loadOption
          );
        }
      }
    } catch (e) {
      reject(e);
    }
  });
}

export function loadImageUrlBase64(
  url: string,
  maxSize: number = 1920,
  isProfile: boolean = false
) {
  return new Promise((resolve, reject) => {
    var req = new XMLHttpRequest();
    req.setRequestHeader("Access-Control-Allow-Origin", "*");
    req.onload = function () {
      //console.log("imageLoad", url);
      var i = new Image();
      try {
        i.onload = () => {
          try {
            //console.log(i.width, i.height);
            let sourceWidth = i.width;
            let sourceHeight = i.height;

            let longer =
              sourceWidth > sourceHeight ? sourceWidth : sourceHeight;
            let size = maxSize > longer ? longer : maxSize;

            let destWidth = sourceWidth,
              destHeight = sourceHeight;
            if (sourceWidth > sourceHeight) {
              destWidth = size;
              destHeight = (size * sourceHeight) / sourceWidth;
            } else {
              destWidth = (size * sourceWidth) / sourceHeight;
              destHeight = size;
            }

            let destCanvas = document.createElement("canvas");
            let ctx = destCanvas.getContext("2d");
            ctx.canvas.width = destWidth;
            ctx.canvas.height = destHeight;
            ctx.drawImage(
              i,
              0,
              0,
              sourceWidth,
              sourceHeight,
              0,
              0,
              destWidth,
              destHeight
            );
            resolve(destCanvas.toDataURL("image/jpeg", 0.8));
          } catch (e) {
            reject(e);
          }
        };
        i.onerror = (err) => {
          reject(err);
        };
        // i.setAttribute('crossOrigin', 'anonymous');
        i.src = URL.createObjectURL(req.response);
      } catch (e) {
        reject(e);
      }
    };
    req.open("get", url, true);
    req.responseType = "blob";
    req.send();
  });
}

export function resizeBase64(base64: string, maxSize: number = 1920) {
  return new Promise((resolve, reject) => {
    var i = new Image();
    try {
      i.onload = () => {
        try {
          //console.log(i.width, i.height);
          let sourceWidth = i.width;
          let sourceHeight = i.height;

          let longer = sourceWidth > sourceHeight ? sourceWidth : sourceHeight;

          let size = maxSize > longer ? longer : maxSize;

          let destWidth = sourceWidth,
            destHeight = sourceHeight;
          if (sourceWidth > sourceHeight) {
            destWidth = size;
            destHeight = (size * sourceHeight) / sourceWidth;
          } else {
            destWidth = (size * sourceWidth) / sourceHeight;
            destHeight = size;
          }

          let destCanvas = document.createElement("canvas");
          let ctx = destCanvas.getContext("2d");
          ctx.canvas.width = destWidth;
          ctx.canvas.height = destHeight;
          log(
            LogLevel.UI_ACTION,
            "resizeBase64",
            sourceWidth,
            sourceHeight,
            0,
            0,
            destWidth,
            destHeight
          );

          ctx.drawImage(
            i,
            0,
            0,
            sourceWidth,
            sourceHeight,
            0,
            0,
            destWidth,
            destHeight
          );
          resolve(destCanvas.toDataURL("image/jpeg", 0.8));
        } catch (e) {
          reject(e);
        }
      };
      i.src = base64;
    } catch (e) {
      reject(e);
    }
  });
}
