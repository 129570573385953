import React, { Component } from "react";
import "./SearchAddress.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonIcon,
  IonInput,
  IonList,
  IonItem,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { fetchAPI } from "./../utils/APIUtil";
import { ADDRESS_KEY } from "./../config.json";
import * as API from "./../API.json";
import { timeout } from "q";
import { ALPN_ENABLED } from "constants";
import { LogLevel, log } from "../utils/LogUtil";
import GeocodeUtil from "../utils/GeocodeUtil";

const windowAny: any = window;
const naver: any = windowAny.naver;
const $: any = windowAny.$;
type Props = {
  title?: string;
  searchType?: string;
  onSelected: (
    address: string,
    region: string,
    name: string,
    phone: string,
    position: any,
    postalCode: string
  ) => void;
  onCanceled: () => void;
  withoutDetail?: boolean;
};

type State = {
  pharmacySearchText: string;
  pharmacySearched: any[];
  addressSearchText: string;
  addressSearched: any[];
  searchType: string;
  address: string;
  region: string;
  name: string;
  phone: string;
  postalCode: string;
  enabled: boolean;
};

class SearchAddress extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "SearchAddress:constructor", props);
    let searchType = "pharmacy";
    if (props.searchType) searchType = props.searchType;
    this.state = {
      pharmacySearchText: "",
      pharmacySearched: [],
      addressSearchText: "",
      addressSearched: [],
      searchType,
      address: "",
      region: "",
      name: "",
      phone: "",
      postalCode: "",
      enabled: false,
    };
  }

  onPharmacySearchTextChange = async (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    let keywords = e.detail.value;
    this.setState({ pharmacySearchText: keywords });
    if (!keywords) this.setState({ pharmacySearched: [] });
    else {
      let result = await fetchAPI(
        API.USER_SEARCH_PHARMACY,
        "",
        { query: keywords },
        null
      );
      log(
        LogLevel.UI_DATA_LOAD,
        "SearchAddress:onPharmacySearchTextChange",
        result
      );
      if (!result.error) this.setState({ pharmacySearched: result.data });
      else this.setState({ pharmacySearched: [] });
      // log(LogLevel.NONE, result);
    }
  };

  onCancel = () => {
    this.setState({ pharmacySearchText: "", pharmacySearched: [] });
    this.props.onCanceled();
  };

  addressSearchTextValidate = (address: string) => {
    if (address.length > 1) {
      //특수문자 제거
      var expText = /[%=><]/;
      if (expText.test(address) == true) {
        address = address.split(expText).join("");
        return false;
      }

      //특정문자열(sql예약어의 앞뒤공백포함) 제거
      var sqlArray = new Array(
        //sql 예약어
        "OR",
        "SELECT",
        "INSERT",
        "DELETE",
        "UPDATE",
        "CREATE",
        "DROP",
        "EXEC",
        "UNION",
        "FETCH",
        "DECLARE",
        "TRUNCATE"
      );

      var regex;
      for (var i = 0; i < sqlArray.length; i++) {
        regex = new RegExp(sqlArray[i], "gi");

        if (regex.test(address)) {
          // alert("\"" + sqlArray[i]+"\"와(과) 같은 특정문자로 검색할 수 없습니다.");
          address = address.replace(regex, "");
          return false;
        }
      }

      return true;
    }

    return false;
  };

  onAddressSearchTextChange = async (e) => {
    let addressSearchText = e.detail.value;

    this.setState({ addressSearchText });
    if (this.addressSearchTextValidate(addressSearchText)) {
      let result = await fetchAPI(
        API.USER_SEARCH_ADDRESS,
        "",
        { query: addressSearchText },
        null
      );
      // console.log(result)
      if (!result.error) {
        if (result.query == addressSearchText)
          this.setState({ addressSearched: result.data });
      } else this.setState({ addressSearched: [] });
      //console.log("valid query", addressSearchText);
    } else {
      //console.log("not valid query", addressSearchText);
      this.setState({ addressSearched: [] });
    }
  };

  onPharmacySelected = async (
    address: string,
    region: string,
    name: string,
    phone: string,
    position: any,
    postalCode: string
  ) => {
    if (!position || !position.x || !position.y)
      position = await GeocodeUtil.getGPSByAddress(address);
    this.props.onSelected(address, region, name, phone, position, postalCode);
  };

  onAddressSelected = async (
    address: string,
    region: string,
    name: string,
    phone: string,
    position: any,
    postalCode: string
  ) => {
    log(
      LogLevel.UI_DATA_LOAD,
      "SearchAddress:onAddressSelected",
      address,
      region,
      name,
      phone,
      postalCode,
      this.props.withoutDetail
    );
    if (!this.props.withoutDetail)
      this.setState({
        address,
        region,
        name,
        phone,
        addressSearchText: "",
        addressSearched: [],
        postalCode,
      });
    else {
      let position = await GeocodeUtil.getGPSByAddress(address);
      this.props.onSelected(address, region, name, phone, position, postalCode);
    }
  };

  didShow = () => {
    log(LogLevel.UI_LIFECYCLE, "ProgressPopup:didShow");
  };

  onDone = async () => {
    let position = await GeocodeUtil.getGPSByAddress(this.state.address);
    this.props.onSelected(
      this.state.address,
      this.state.region,
      this.state.name,
      this.state.phone,
      position,
      this.state.postalCode
    );
  };

  render() {
    let addresses;
    if (this.state.addressSearched && this.state.addressSearched.length > 0) {
      addresses = (
        <div className="search-address-list-container">
          <IonList class="search-address-list" lines="none">
            {this.state.addressSearched.map((item, index: number) => (
              <SearchAddressItem
                {...this.props}
                onSelected={this.onAddressSelected}
                key={index.toString()}
                item={item}
              />
            ))}
          </IonList>
        </div>
      );
    }
    return (
      <div className="search-address-container">
        <div className="search-address-title-container">
          <div className="search-address-title">
            {this.props.title ? this.props.title : "근무지 주소 입력"}
          </div>
          <div className="search-address-segment-container">
            <IonSegment
              value={this.state.searchType}
              mode="md"
              slot="start"
              color="search-address-segment"
              onIonChange={(e) => this.setState({ searchType: e.detail.value })}
            >
              <IonSegmentButton
                mode="md"
                class="search-address-segment-button"
                value="pharmacy"
                checked={this.state.searchType === "pharmacy"}
              >
                <div className="search-address-segment-button-text">
                  약국명 검색
                </div>
              </IonSegmentButton>
              <IonSegmentButton
                mode="md"
                class="search-address-segment-button"
                value="manual"
                checked={this.state.searchType === "manual"}
              >
                <div className="search-address-segment-button-text">
                  주소 검색
                </div>
              </IonSegmentButton>
            </IonSegment>
          </div>
          <IonButton
            fill="clear"
            class="search-address-close"
            onClick={this.onCancel}
          >
            <IonIcon class="search-address-close-icon" name="close" />
          </IonButton>
        </div>
        <div
          className="search-address-type-container"
          style={this.state.searchType == "pharmacy" ? { display: "none" } : {}}
        >
          <div className="search-address-input-description">
            1. 지번 or 도로명 or 건물명으로 검색
            <br />
            2. 회사명과 전화번호를 입력
          </div>
          <div className="register-search-input-container">
            <IonInput
              class="search-address-input"
              placeholder="예) 판교로 "
              value={this.state.addressSearchText}
              onIonChange={this.onAddressSearchTextChange}
            />
            <IonIcon
              class="register-popup-search-icon"
              name="search"
              mode="ios"
            />
          </div>
          {addresses}
          {!this.props.withoutDetail && (
            <div className="search-address-manual-item-container">
              <div className="search-address-manual-item-name">주소</div>
              <IonInput
                class="search-address-manual-item-input"
                value={this.state.address}
                disabled
              />
            </div>
          )}
          {!this.props.withoutDetail && (
            <div className="search-address-manual-item-container">
              <div className="search-address-manual-item-name">회사명</div>
              <IonInput
                class="search-address-manual-item-input"
                value={this.state.name}
                onIonChange={(e) => {
                  this.setState({
                    name: e.detail.value,
                    enabled:
                      e.detail.value && this.state.address ? true : false,
                  });
                }}
              />
            </div>
          )}
          {!this.props.withoutDetail && (
            <div className="search-address-manual-item-container">
              <div className="search-address-manual-item-name">전화번호</div>
              <IonInput
                class="search-address-manual-item-input"
                value={this.state.phone}
                onIonChange={(e) => {
                  this.setState({ phone: e.detail.value });
                }}
              />
            </div>
          )}
          {!this.props.withoutDetail && (
            <IonButton
              color="serach-address-manual-done-button"
              onClick={this.onDone}
              disabled={!this.state.enabled}
            >
              주소 입력 완료
            </IonButton>
          )}
        </div>

        <div
          className="search-address-type-container"
          style={this.state.searchType == "manual" ? { display: "none" } : {}}
        >
          <div className="search-address-input-description">
            근무하는 약국명을 입력해주세요.
            <br />
            약국명이 없으면, 주소 검색을 해 주세요
          </div>
          <div className="register-search-input-container">
            <IonInput
              class="search-address-input"
              placeholder="예) 판교로 늘푸른약국"
              value={this.state.pharmacySearchText}
              onIonChange={this.onPharmacySearchTextChange}
            />
            <IonIcon
              class="register-popup-search-icon"
              name="search"
              mode="ios"
            />
          </div>
          <div className="search-address-list-container">
            <IonList class="search-address-list" lines="none">
              {this.state.pharmacySearched.map((item, index: number) => (
                <SearchPharmacyItem
                  {...this.props}
                  onSelected={this.onPharmacySelected}
                  key={index.toString()}
                  item={item}
                />
              ))}
            </IonList>
          </div>
        </div>
      </div>
    );
  }
}

type ItemProps = {
  onSelected: (
    address: string,
    region: string,
    name: string,
    phone: string,
    position: any,
    postalCode: string
  ) => void;
  item: any;
  key: string;
};

class SearchPharmacyItem extends Component<ItemProps> {
  onClick = () => {
    this.props.onSelected(
      this.props.item.address,
      this.props.item.dosigugun,
      this.props.item.name,
      this.props.item.phone,
      {
        x: this.props.item.location.coordinates[0],
        y: this.props.item.location.coordinates[1],
      },
      this.props.item.postal.padStart(5, "0")
    );
  };

  render() {
    return (
      <IonItem
        no-border
        class="search-address-item-container"
        onClick={this.onClick}
      >
        <div className="search-address-item-inner">
          <div className="search-address-item-name">{this.props.item.name}</div>
          <div className="search-address-item-address">
            {this.props.item.address}
          </div>
          <div className="search-address-item-phone-container">
            <div className="search-address-item-phone-title">전화번호</div>
            <div className="search-address-item-phone">
              {this.props.item.phone}
            </div>
          </div>
        </div>
      </IonItem>
    );
  }
}

export class SearchAddressItem extends Component<ItemProps> {
  onClick = () => {
    this.props.onSelected(
      this.props.item.address,
      this.props.item.region,
      "",
      "",
      null,
      this.props.item.postalCode
    );
  };

  render() {
    return (
      <IonItem
        no-border
        class="search-address-item-container"
        onClick={this.onClick}
      >
        <div className="search-address-item-inner">
          <div className="search-address-item-address">
            {this.props.item.address}
          </div>
          <div className="search-address-item-phone-container">
            <div className="search-address-item-phone-title">우편번호</div>
            <div className="search-address-item-phone">
              {this.props.item.postalCode}
            </div>
          </div>
        </div>
      </IonItem>
    );
  }
}

export default SearchAddress;
