import React, { Component } from 'react';
import './ComponentBanner.css';
import { func } from 'prop-types';
import {log, LogLevel} from '../utils/LogUtil'
import { RootState, actions } from '../store';
import { connect } from 'react-redux';
import { Reference } from '../models/Model.Board';
import {MY_ADDRESS} from './../config.json';
import { withRouter, RouteComponentProps } from "react-router";
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';
import { IonButton } from '@ionic/react';
import { timingSafeEqual } from 'crypto';
import { UserInfo } from '../models/Model.User';
import ProfileSimple from '../components/ProfileSimple';
import { action } from 'typesafe-actions';
import StringUtil from '../utils/StringUtil';
import AnalyticsUtil from '../utils/AnalyticsUtil';
import VisibilitySensor from "react-visibility-sensor";
import { fetchAPI } from '../utils/APIUtil';
import * as API from './../API.json';


type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  component?: Reference;
  src? : string;
  path?: string;
  advertiserInfo?: any;
};

const windowAny : any = window;

class ComponentBanner extends Component<Props> {

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ComponentBanner: constructor ", props);
  }

  // onVisibleChange = (visible:boolean) => {
  //   log(LogLevel.UI_EVENT, "ComponentBanner: onBannerVisibleChange", visible)
  //   if(visible && this.props.advertiserInfo){
  //     AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_EXPOSURE", '광고노출', {"type":"컨텐츠 배너", ...this.props.advertiserInfo});
  //   }
  // }

  onPress = () => {
    log(LogLevel.UI_EVENT, "ComponentBanner: onPress", this.props.advertiserInfo)
    if(this.props.advertiserInfo){
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_EXPOSURE", '광고고객반응', {"type":"컨텐츠 배너", ...this.props.advertiserInfo});

      if(this.props.advertiserInfo.gsid){
        fetchAPI(API.USER_CTA_RECORD, "", null, {
          gsid: this.props.advertiserInfo.gsid,
          gstid: this.props.advertiserInfo.gstid, // google sheet tab name
          shid: this.props.advertiserInfo.shid, // slack hook url
          stid: this.props.advertiserInfo.stid,
          stfields: this.props.advertiserInfo.stfields,
          data: this.props.advertiserInfo.code + "-" + (this.props.advertiserInfo.productCode?this.props.advertiserInfo.productCode:"") + "-" + this.props.advertiserInfo.contentType  + "-" + this.props.advertiserInfo.contentId, // additionalData 
        }, getGlobal(GlobalKey.TOKEN));
      }
    }

    if(this.props.me && this.props.path){
      if(this.props.path.startsWith("/")){
        this.props.history.push(this.props.path);
      }else{
        window.open(this.props.path, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
      }
    }else{
      this.props.history.push("/");
    }
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ComponentBanner: render ", this.props);
    if(this.props.src)
      // if(this.props.advertiserInfo){
      //   return (
      //     <VisibilitySensor onChange={this.onVisibleChange}>
      //       <div className="common-container" onClick={this.onPress}>
      //         <img className="component-banner-img" src={StringUtil.convertFilePath(this.props.src)}/>
      //       </div>
      //     </VisibilitySensor>
      //   );

      // }else{
        return (
          <div className="common-container" onClick={this.onPress}>
            <img className="component-banner-img" src={StringUtil.convertFilePath(this.props.src)}/>
          </div>
        );
      // }
    else 
      return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentBanner));