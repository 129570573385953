import React, { Component } from 'react';
import {TARGET} from './../config.json';
import {VERSION,BUILD_DATE} from './../version.json';
import ABTestUtil from './../utils/ABTestUtil';
import { log, LogLevel } from '../utils/LogUtil';
import { getOS } from '../utils/DeviceUtil';

const windowAny:any = window;
const TargetFlag: React.SFC = () => {
    // return null;

    if(ABTestUtil.isTest()){
        // //windowAny.FS.event(`개발서버${TARGET.toUpperCase()}`, {target:TARGET.toUpperCase()});
        log(LogLevel.UI_DATA_LOAD, "TargetFlag:", window.navigator);

        document.title = `약문약답(${TARGET.toUpperCase()})`
        return <div className="common-menu-red common-color-caution common-always-message common-unselectable">{TARGET.toUpperCase()} {VERSION}.{BUILD_DATE} {getOS().toUpperCase()}</div>
    }
    return null;
}
  
  export default TargetFlag;