import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonText,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonContent,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import {
  AllInOnePartnerInfo,
  UserType,
  UserTypeName,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import MessageSelect from "./component/inappNoti/MessageSelect";
import CreateInAppMessage from "./component/inappNoti/MessageCreateManager";
import GetUserConditionedDistributionView from "./component/inappNoti/GetUserConditionedView";


type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const InAppManage: React.FC<Props> = (props) => {
  const [selected, setSelected] = useState<AllInOnePartnerInfo | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedService, setSelectedService] = useState<UserType>(UserType.BIZ_CC);
  const listRef = useRef(null);

  useEffect(() => {
    props.loadFilePath();
  }, [props]);

  const onSelect = (partner: AllInOnePartnerInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", partner);
    setSelected(partner);
  };

  const onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", selected);
    if (listRef.current) listRef.current.refresh();
    setSelected(null);
  };

  const onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", selected);
    setSelected(null);
  };

  log(LogLevel.UI_LIFECYCLE, "AllInOnePartnerManage.render", props);

  return (
    <>
      <IonHeader class="admin-header" no-border>
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <IonText slot="middle">커스텀 FCM 메세지 관리</IonText>
      </IonHeader>
      <IonContent>
        <div className="admin-main-container">
          <div className="admin-container">
            <MessageSelect
              key={refreshKey}
              onSelect={onSelect}
              setSelectedService={setSelectedService}
              selectedService={selectedService}
              ref={listRef}
            />
          </div>
          <div id="admin-sub-conainer" className="admin-container">
            <div>
            <CreateInAppMessage
              onUserCreated={() => setRefreshKey(prevKey => prevKey + 1)} 
              selectedService={selectedService}
            />
            </div>
          </div>
        </div>
      </IonContent>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InAppManage)
);
