import React, { useCallback, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import JobPostBizDetailTemplate from "../../components/templates/JobPostBizDetail";
import { Attachment } from "../../models/Model.Board";
import { JobOfferInfo } from "../../models/Model.JobPost";
import { CompanyType } from "../../models/Model.User";
import { fetchAPI } from "../../utils/APIUtil";
import DownloadUtil from "../../utils/DownloadUtil";
import * as API from "./../../API.json";

interface MatchParams {
  token: string;
}

const JobPostPublicDetail: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { token } = match.params;
  const [post, setPost] = useState<JobOfferInfo | null>(null);

  const loadPost = useCallback(async () => {
    try {
      const res = await fetchAPI(API.JOBPOST_OFFER_GET_PUBLIC, token, null, null, null);
      if (!res.error) setPost(res.data);
    } catch (err) {
      alert("공고를 불러오는데 실패했습니다.");
    }
  }, [token]);

  const handleShareClick = useCallback(() => {}, [post]);

  // 파일 다운로드
  const handleDownloadClick = useCallback(
    (file: Attachment) => () => {
      DownloadUtil.downloadDocument(file.url, file.name);
    },
    []
  );

  const setCompanyTypeTagColor = () => {
    switch (post.companyType) {
      case CompanyType.HOSPITAL:
        return "Blue";
      case CompanyType.PHARMACEUTICAL_COMPANY:
        return "Green";
      case CompanyType.DISTRIBUTOR:
        return "Brown";
      case CompanyType.ETC:
      default:
        return "Gray";
    }
  };

  const handleLinkClick = (type: any, url?: string) => () => {
    switch (type) {
      case "homepage":
        window.open(url, "_system");
        break;
      case "kakaoMap":
        window.open(
          `https://map.kakao.com/link/map/${post.companyName},${post.companyPosition.y},${post.companyPosition.x}`,
          "_system"
        );
        break;
      case "naverMap":
        window.open(
          `https://map.naver.com/?dlevel=13&pinTitle=${post.companyAddress}&lat=${post.companyPosition.y}&lng=${post.companyPosition.x}`,
          "_system"
        );
        break;
      default:
        break;
    }
  };

  if (!post) {
    loadPost();
    return null;
  }

  console.log(post);

  return (
    <JobPostBizDetailTemplate
      post={post}
      isPrivate={false}
      handleDownloadClick={handleDownloadClick}
      handleShareClick={handleShareClick}
      setCompanyTypeTagColor={setCompanyTypeTagColor}
      handleLinkClick={handleLinkClick}
    />
  );
};

export default withRouter(JobPostPublicDetail);
