import React from "react";
import IngredientListItem, { MedicineIngredient } from "./IngredientListItem";
import styled from "styled-components";

interface IngredientListProps {
  ingredients: MedicineIngredient[];
}

const FlexColUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const IngredientList: React.FC<IngredientListProps> = ({ ingredients }) => {
  return (
    <FlexColUl>
      {ingredients.map((ingredient) => (
        <IngredientListItem ingredient={ingredient} />
      ))}
    </FlexColUl>
  );
};

export default IngredientList;
