

import React, { Component } from 'react';
import '../../Admin.scss';
import '../../SeminarManage.css';
import '../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import ProfileImage from '../../../components/ProfileImage';
import { SeminarLecture, SeminarSeries } from '../../../models/Model.Seminar';
import { defaultNewLecture } from '../../SeminarManage';
import ToggleSwitch from '../../../component/atom/ToggleSwitch';
import Text from '../../../component/atom/Text';
import Input from '../../../component/atom/Input';
import IconButton from '../../../component/atom/IconButton';

const API = {        
  "SERIES_LIST" : {"method":"GET", "path":"/admin/seminar/series", "contentType":"application/json"},
  "SERIES_ADD" : {"method":"POST", "path":"/admin/seminar/series", "contentType":"application/json"},
  "SERIES_UPDATE" : {"method":"PUT", "path":"/admin/seminar/series", "contentType":"application/json"},
  "SERIES_DELETE" : {"method":"DELETE", "path":"/admin/seminar/series", "contentType":"application/json"},
};

const ROW_CNT = 20;

export const defaultNewSeries:SeminarSeries = {
  id: 0,
  title: '문어게임',
  subtitle: '오징어게임보다 재밌다.',
  description: "",
  imageUrl: '',
  lectures:[],
}

type Props = {
};

type State = {
  series: SeminarSeries[],
  seriesEdit: SeminarSeries,
  addLectureId: number,
}

class SeminarSeriesManage extends Component<Props, State> {
  finished = false;
  loading = false;
  lectureList = null;

  state = {
    series: [],
    seriesEdit: null,
    addLectureId: 0,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSeriesManage:fetchData");
    this.loading = true;
    let options:any = {start:0, count: ROW_CNT};

    fetchAPI(API.SERIES_LIST, "", options, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSeriesManage:fetchData result", result);
        this.finished = false;
        if(result.data && result.data.length < ROW_CNT)
          this.finished = true;

        this.setState({series: result.data});
        this.loading = false;
      }
      else{
        log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSeriesManage:fetchData result", result);
        this.finished = true;
        this.setState({series: []});
      }
    });
  }

  fetchDataMore = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSeriesManage:fetchDataMore", this.finished);
    this.loading = true;
    let options:any = {start:this.state.series.length, count: ROW_CNT};
    if(!this.finished){
      fetchAPI(API.SERIES_LIST, "", options, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSeriesManage:fetchData result", result);
          this.finished = false;
          if(result.data && result.data.length < ROW_CNT)
            this.finished = true;

          this.setState({series: [...this.state.series, ...result.data]});
          this.loading = false;
        }
        else{
          log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSeriesManage:fetchData result", result);
          this.finished = true;
          this.loading = false;
        }
      });
    }

  }

  onSelect = (series: SeminarSeries) => {
    this.setState({seriesEdit:{...series}})
  }

  onAdd = () => {
    this.setState({seriesEdit:{...defaultNewSeries}})
  }

  onItemChange = (name:string, e) => {
    let seriesEdit:SeminarSeries = {...this.state.seriesEdit};
    seriesEdit[name] = e.target.value;
    this.setState({seriesEdit});
  }

  onSave = async () => {
    let api = API.SERIES_ADD;
    if(this.state.seriesEdit.id)
      api = API.SERIES_UPDATE;

    let series:SeminarSeries = {...this.state.seriesEdit};
    series.lectures = series.lectures.map((item, index) => {return {id:item.id, seriesId:item.seriesId}})
    await fetchAPI(api, "", null, this.state.seriesEdit, getGlobal(GlobalKey.TOKEN));
    this.setState({seriesEdit:null});
    this.fetchData();
  }

  onAddLecture = () => {
    if(!this.state.addLectureId)
      return;
    let seriesEdit:SeminarSeries = {...this.state.seriesEdit};

    let lectures = seriesEdit.lectures?[...seriesEdit.lectures]:[];
    lectures.push({id:this.state.addLectureId});
    seriesEdit.lectures = lectures;
    this.setState({seriesEdit}); 
  }

  onDeleteLecture = (index) => {
    let seriesEdit:SeminarSeries = {...this.state.seriesEdit};

    let lectures = [...seriesEdit.lectures];
    lectures.splice(index,1);
    seriesEdit.lectures = lectures;
    this.setState({seriesEdit});
  }

  onDefaultSeries = (index) => {
    let seriesEdit:SeminarSeries = {...this.state.seriesEdit};

    let lectures = [...seriesEdit.lectures];
    lectures[index].seriesId = 1;
    seriesEdit.lectures = lectures;
    this.setState({seriesEdit});
  }

  onExchangeLecture = (indexA, indexB) => {

    let seriesEdit:SeminarSeries = {...this.state.seriesEdit};

    let lectures = [...seriesEdit.lectures];

    let length = lectures.length;
    if(indexA < 0 || indexA >= length || indexB < 0 || indexB >= length )
      return;

    lectures[indexA] = seriesEdit.lectures[indexB];
    lectures[indexB] = seriesEdit.lectures[indexA];
    seriesEdit.lectures = lectures;
    this.setState({seriesEdit});
  }

  render() {
    return (
      <div className="admin-container">
        <div className="common-list">
          {this.state.seriesEdit != null &&
            <div className="common-container">
              <div className="common-container-row common-flex-align-center">
                <IonButton onClick={() => this.setState({seriesEdit:null})}>취소</IonButton>
                <IonButton onClick={this.onSave}>저장</IonButton>

              </div>
              <div className="admin-title">id: {this.state.seriesEdit.id}</div>
              <div className="common-container-row common-flex-align-center">
                <Text>제목</Text>
                <div className="common-flex-grow">
                  <Input value={this.state.seriesEdit.title} onChange={e => this.onItemChange("title", e)}/>
                </div>
              </div>
              <div className="common-container-row common-flex-align-center">
                <Text>부제</Text>
                <div className="common-flex-grow">
                  <Input value={this.state.seriesEdit.subtitle} onChange={e => this.onItemChange("subtitle", e)}/>
                </div>
              </div>
              <Text>세미나리스트</Text>
              <div className="common-container-row common-flex-align-center">
                <Input type="number" value={this.state.addLectureId.toString()} onChange={e => this.setState({addLectureId:parseInt(e.target.value)})}/>
                <IconButton size="M" color="Primary" iconName="Plus" onClick={() => this.onAddLecture()}/>
              </div>
              {(this.state.seriesEdit.lectures && this.state.seriesEdit.lectures.length>0) &&
                this.state.seriesEdit.lectures.map((item:SeminarLecture, index) => {
                  return (
                    <div key={index.toString()} className="common-container-row common-flex-align-center">
                      <ToggleSwitch checked={!!item.seriesId} onClick={() => this.onDefaultSeries(index)}/>
                      <div className="common-flex-grow">
                        <Text>
                          {item.id} - {item.title}
                        </Text>
                      </div>
                      <IconButton size="M" variant="Outlined" color="Primary" iconName="CaretUp" onClick={() => this.onExchangeLecture(index-1, index)}/>
                      <IconButton size="M" variant="Outlined" color="Primary" iconName="CaretDown" onClick={() => this.onExchangeLecture(index, index+1)}/>
                      <IconButton size="M" variant="Outlined" color="Primary" iconName="X" onClick={() => this.onDeleteLecture(index)}/>
                    </div>
                  );
                })
                }
            </div>

          } 

          <IonButton expand="full" onClick={this.onAdd}>
            <IonIcon name="add"/>
          </IonButton>
          <IonList ref={(ref) => {this.lectureList = ref}} >
            { this.state.series.map((item:SeminarLecture, index: number) => (
              <div key={index.toString()} className="common-container-row common-flex-align-center" onClick={() => this.onSelect(item)}>
                <div className="common-flex-grow">
                  <div className={"admin-title" + (item.deletedAt?" common-color-caution":"")}>{item.id} - {item.title}</div>
                </div>
                <IonButton color="primary" onClick={(e) => this.onSelect(item)}><IonIcon mode="ios" name="arrow-forward"/></IonButton>
              </div>
            ))}         
            {!this.finished && 
              <IonButton expand="full" onClick={this.onAdd}>
                <IonIcon name="add"/>
              </IonButton>     
            }
          </IonList>
        </div>
      </div>  
    );
  }
}

export default SeminarSeriesManage;