import React, { useState } from 'react';
import { FC } from 'react'
import Text from '../../atom/Text';
import TextButton from '../../atom/TextButton';
import Body from '../../molecule/Body';
import Footer from '../../molecule/Footer';
import Header from '../../molecule/Header';
import './style.scss';
import './../../../Common.css';
import Icon from '../../atom/Icon';
import COLOR from '../../_design/color';
import IconButton from '../../atom/IconButton';
import imageCrown from './../../../assets/3d/Crown.png'

interface Props {
  onDone?: (permanent: boolean) => void;
}

const UserContractUpdate: FC<Props> = ({
  onDone,
}) => {
  return (
    <>
      <Header needBack={false} needClose={false} name="" center={false} />
      <Body>
        <div className="common-block">
          <div style={{marginBottom:"12px"}}>
            <Text type="H1" color="Secondary">약문약답의 <br/> 평생 회원이 되어주세요</Text>
          </div>
          <Text type="Body1" color="Gray3">평생 회원이 되시면 한동안 앱을 사용하지 않아도 자동 탈퇴처리가 되지 않아 내가 스크랩한 컨텐츠, 댓글 등 나의 활동 기록이 안전하게 보존됩니다.</Text>
        </div>
        <div className="common-block common-flex-column common-flex-align-center">
          <img className="user-permanent-image" src={imageCrown}/>
        </div>
        <div className="common-bottom-footer-space"/>
        <Footer>
          <div className="common-container-column">
            <TextButton block={true} color="Primary" size="L" onClick={() => {if(onDone) onDone(true);}}>평생회원으로 약문약답 시작하기</TextButton>
            <div style={{height:"10px"}}/>
            <TextButton block={true} color="Gray" size="L" onClick={() => {if(onDone) onDone(false);}}>일반회원으로 약문약답 시작하기</TextButton>
          </div>
        </Footer>
      </Body>
    </>
  )
}

export default UserContractUpdate
