import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonCheckbox, IonToggle, IonItem } from '@ionic/react';
import './NotificationManage.css'
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'

import {setGlobal, GlobalKey, getGlobal} from '../utils/GlobalUtil'
import { BoardType } from '../store/board/types';
import { UserLevel, UserWorkType, UserInfo, UserWorkTypeName, UserLevelName } from '../models/Model.User';
import { fetchAPI } from '../utils/APIUtil';
import * as API from './../API.json';;
import UserSelect from './component/user/UserSelect';
import BoardContentSelect from './component/BoardContentSelect';
import { BoardContent } from '../models/Model.Board';

const AdminAPI = {        
  "SEND_NOTIFICATION" : {"method":"POST", "path":"/admin/notification", "contentType":"application/json"}
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  title: string;
  text: string;
  ids: string;
  recipients: UserInfo[];
  toSomeUser: boolean;
  linkContent: number;
  selectionMode: number; // 0 none, 1 user, 2 content
}

class NotificationManage extends Component<Props, State> {

  state = {
    title :'',
    text:'',
    recipients: [],
    toSomeUser: false,
    linkContent: 0,
    selectionMode: 0,
    ids:'',
  };

  constructor(props: Props) {
    super(props);

  }

  componentDidMount() {
  }

  onUserAdd = () => {
    log(LogLevel.UI_ACTION, "NotificationManage.onUserAdd")
    this.setState({selectionMode:1});
  }
  onUserAdded = (user: UserInfo) => {
    if(!this.state.recipients.includes(user)){
      log(LogLevel.UI_ACTION, "NotificationManage.onUserAdded", user) 
      this.setState({recipients:[...this.state.recipients, user]});
    }
  }
  
  onUserRemove = (index:number) => {
    log(LogLevel.UI_ACTION, "NotificationManage.onUserRemove", index) 
    let recipients = [...this.state.recipients];
    recipients.splice(index, 1);
    this.setState({recipients});
  }

  onSetContent = () => {
    log(LogLevel.UI_ACTION, "NotificationManage.onSetContent")
    this.setState({selectionMode:2});
  }
  
  onContentSelected = (content:BoardContent) => {
    this.setState({linkContent:content.id});
  }
  
  onSend = async () => {
    let ids = this.state.recipients.map((user) => user.id);
    let result = await fetchAPI(AdminAPI.SEND_NOTIFICATION, "", null, {ids, title:this.state.title, text:this.state.text, content:this.state.linkContent}, getGlobal(GlobalKey.TOKEN))
    log(LogLevel.UI_ACTION, "NotificationManage.onSend", ids, result)
    if(result && !result.error) {
      this.setState({title:'',text:'',recipients:[],linkContent:0});
    }
  }

  onCheckDisabled = () => {
    if(this.state.recipients.length == 0)
      return true;
    if(!this.state.title || !this.state.text)
      return true;
    return false;
  }

  onIdKeyPress = async (e) => {
    log(LogLevel.UI_ACTION, "NotificationManage:onIdKeyPress", e.keyCode)
    if(e.keyCode == 13){
      let ids:string[] = this.state.ids.split(/[, ]/);
      for(let i=0; i<ids.length; i++){
        let id = ids[i];
        if(id){
          let exists = false;
          for(let j = 0; j<this.state.recipients.length; j++){
            if(this.state.recipients[j].id == id){
              exists = true;
              break;
            }
          }
          if(!exists){
            let result = await fetchAPI(API.USER_INFO_REQUEST, id, null, null, getGlobal(GlobalKey.TOKEN) )
            if(result && !result.error)
            this.setState({recipients:[...this.state.recipients, result.data], toSomeUser:true})
          }
        }
      }
      this.setState({ids:''});

    }
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "NotificationManage.render", this.props, this.state);
    let recipients;
    if(this.state.toSomeUser){
      recipients = 
        <div className="admin-notification-recipient-container">
          <IonButton onClick={this.onUserAdd}><IonIcon name="add"/></IonButton>
          {this.state.recipients.map((recipient:UserInfo, index) => 
            <IonItem key={index.toString()}>
              <IonLabel>{recipient.nickname}</IonLabel>
              <IonButton onClick={() => this.onUserRemove(index)}><IonIcon name="close"/></IonButton>
            </IonItem>
          )}
        </div>
    }

    let selection;
    if(this.state.selectionMode == 1)
      selection = <UserSelect onDone={this.onUserAdded}/>
    else if(this.state.selectionMode == 2)
      selection = <BoardContentSelect onDone={this.onContentSelected}/>

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                앱알림 메시지보내기
            </IonText>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <div className="admin-item-container">
                <div className="board-new-item-title">제 목</div>
                <IonInput class="board-composer-input" value={this.state.title} placeholder="제목" onIonChange={(e) => {this.setState({title:e.detail.value})}}/>
              </div>
              <div className="admin-item-container">
                <div className="board-new-item-title">본 문</div>
                <IonInput class="board-composer-input" value={this.state.text} placeholder="본문" onIonChange={(e) => {this.setState({text:e.detail.value})}}/>
              </div>

              <div className="admin-item-container">
                <div className="board-new-item-title">링크글 아이디</div>
                <IonInput class="board-composer-input" value={this.state.linkContent.toString()} placeholder="링크글 아이디" onIonFocus = {this.onSetContent} onIonChange={(e) => {this.setState({linkContent:parseInt(e.detail.value?e.detail.value:'0')})}}/>
              </div>

              <div className="admin-item-container">
                <div className="board-new-item-title">수신인 ID</div>
                <IonInput class="viewer-comment-input" placeholder="1, 35, 24, 36" value={this.state.ids} onIonChange={(e) => {this.setState({ids:e.detail.value})}} onKeyDown={this.onIdKeyPress}/>
              </div>

              <div className="admin-item-container">
                <div className="board-new-item-title">수 신</div>
                <div>전체사용자 보내기</div> 
                <IonToggle checked={this.state.toSomeUser} onClick={() => this.setState({toSomeUser:!this.state.toSomeUser})}/>
                <div> 지정 사용자 보내기 </div>
              </div>
              {recipients}
              <div className="common-container">
                <IonButton expand="full" onClick={this.onSend} disabled={this.onCheckDisabled()}>전송하기</IonButton>
              </div>
            </div>
            <div id="admin-sub-conainer" className="admin-container">
              {selection}
            </div>
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationManage));