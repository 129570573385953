import React, { Component } from "react";
import "./../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonActionSheet,
  IonCheckbox,
} from "@ionic/react";
import { log, LogLevel } from "../../utils/LogUtil";
import "react-quill/dist/quill.snow.css"; // ES6
import {
  BannerInfo,
  EMPTY_BANNER,
  BannerType,
} from "../../models/Model.Banner";
import FileInputButton from "../../components/FileInputButton";
import { loadImageBase64 } from "../../utils/ImageUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import * as API from "./../../API.json";
import { connect } from "react-redux";
import { RootState } from "../../store";
import StringUtil from "../../utils/StringUtil";
import Textarea from "react-textarea-autosize";
import {
  CONDITION_USER_OWNER,
  CONDITION_USER_EMPLOYEE,
} from "../../utils/ConditionCheckerUtil";
import { UIPopupType } from "../../store/ui/types";
import { CompanyType, CompanyTypeName } from "../../models/Model.User";
import { getDateStringFromToday } from "../../utils/TimeUtil";

const ADMIN_API = {
  BANNER_ADD: {
    method: "post",
    path: "/admin/banner",
    contentType: "application/json",
  },
  BANNER_UPDATE: {
    method: "put",
    path: "/admin/banner",
    contentType: "application/json",
  },
  BANNER_DELETE: {
    method: "delete",
    path: "/admin/banner",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (banner: BannerInfo) => void;
    banner?: BannerInfo;
    // filePath?: string;
  };

type State = {
  id?: number;
  name?: string;
  type?: any;
  imageUrl?: string;
  imageBase64: string;
  targetUrl?: string;
  priority?: number;
  startAt?: any;
  endAt?: any;
  dateStartWarn: boolean;
  dateEndWarn: boolean;
  edited: boolean;
  createdAt?: any;
  deletedAt?: any;
  condition?: any;
  companyType?: CompanyType;
  advertiserInfo?: any; //{code?: string; productCode?: string; logData?: any};
  etcStr?: string;
};

class BannerEdit extends Component<Props, State> {
  bannerPopupPreview: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...EMPTY_BANNER,
      imageBase64: "",
      edited: false,
      dateStartWarn: false,
      dateEndWarn: false,
      etcStr: "",
    };
  }

  setBannerInfo(banner: BannerInfo) {
    log(LogLevel.UI_LIFECYCLE, "BannerEdit:setBannerInfo", banner);

    let etcStr = "";
    let etc;
    if (banner.condition) {
      if (!etc) etc = {};
      etc.condition = banner.condition;
    }
    if (banner.advertiserInfo) {
      if (!etc) etc = {};
      etc.advertiserInfo = banner.advertiserInfo;
    }
    etcStr = JSON.stringify(etc, null, "\t");

    this.setState({
      ...banner,
      imageBase64: "",
      etcStr,
      edited: false,
      dateStartWarn: false,
      dateEndWarn: false,
      condition: null,
      advertiserInfo: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    log(
      LogLevel.UI_LIFECYCLE,
      "BannerEdit:componenDidUpdate",
      this.props,
      prevProps,
      this.state,
      prevState
    );

    if (
      this.props.banner &&
      (!prevProps.banner ||
        prevProps.banner.id != this.props.banner.id ||
        prevProps.banner.type != this.props.banner.type)
    ) {
      let etcStr = "";
      let etc;
      if (this.props.banner.condition) {
        if (!etc) etc = {};
        etc.condition = this.props.banner.condition;
      }
      if (this.props.banner.advertiserInfo) {
        if (!etc) etc = {};
        etc.advertiserInfo = this.props.banner.advertiserInfo;
      }
      if (etc) etcStr = JSON.stringify(etc, null, "\t");

      this.setState({
        ...this.props.banner,
        startAt: getDateStringFromToday({ date: this.props.banner.startAt }),
        endAt: getDateStringFromToday({ date: this.props.banner.endAt }),
        edited: false,
        dateStartWarn: false,
        dateEndWarn: false,
        etcStr,
        condition: null,
        advertiserInfo: null,
      });
      log(
        LogLevel.UI_LIFECYCLE,
        "BannerEdit:componenDidUpdate",
        this.props.banner,
        prevState,
        etcStr,
        this.state
      );
    } else if (!prevProps.banner && this.props.banner) {
      this.setState({
        ...EMPTY_BANNER,
        imageBase64: "",
        edited: false,
        dateStartWarn: false,
        dateEndWarn: false,
        etcStr: "",
        condition: null,
        advertiserInfo: null,
      });
      log(
        LogLevel.UI_LIFECYCLE,
        "BannerEdit:componenDidUpdate",
        this.props.banner,
        prevState,
        this.props,
        this.state
      );
    }
  }

  onCancel = () => {
    this.props.onDone(null);
  };

  onDateStartChange = (e) => {
    let dateStart = e.target.value;
    let dateStartWarn = false;
    try {
      let date = Date.parse(dateStart);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:BannerEdit:onDateStartChange",
          dateStart
        );
        dateStartWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:BannerEdit:onDateStartChange",
          dateStart,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:BannerEdit:onDateStartChange",
        dateStart,
        err
      );
      console.log(err);
      dateStartWarn = true;
    }
    this.setState({ startAt: dateStart, dateStartWarn, edited: true });
  };

  onDateEndChange = (e) => {
    let dateEnd = e.target.value;
    let dateEndWarn = false;
    try {
      let date = Date.parse(dateEnd);
      if (!date) {
        log(LogLevel.UI_EXCEPTION, "Admin:BannerEdit:onDateEndChange", dateEnd);
        dateEndWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:BannerEdit:onDateEndChange",
          dateEnd,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:BannerEdit:onDateEndChange",
        dateEnd,
        err
      );
      dateEndWarn = true;
    }
    this.setState({ endAt: dateEnd, dateEndWarn, edited: true });
  };

  onDone = async () => {
    log(LogLevel.UI_EXCEPTION, "Admin:BannerEdit:onDone", this.state);
    let etc = {};
    if (this.state.etcStr) {
      try {
        etc = JSON.parse(this.state.etcStr);
      } catch (e) {
        this.props.toastPopup.show("JSON Parsing Error");
      }
    }

    if (this.state.imageBase64) {
      try {
        let data: any = { path: "banner" };
        data.base64 = this.state.imageBase64;
        let result = await fetchAPI(
          API.UPLOAD_BASE64,
          "",
          null,
          data,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error) {
          let url = result.file;
          this.setState({ imageUrl: url, imageBase64: "" });
          log(LogLevel.UI_ACTION, "BannerEdit.onDone upload : ", result.file);
          // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        } else {
          log(LogLevel.UI_ACTION, "BannerEdit.onDone failed", result);
        }
      } catch (error) {
        log(LogLevel.UI_ACTION, "BannerEdit.onDone : failed", error);
        // this.props.navigation.push("AuthRegister");
      }
    }

    if (this.state.id) {
      let banner = { ...this.state, ...etc };
      banner.imageBase64 = null;
      let result = await fetchAPI(
        ADMIN_API.BANNER_UPDATE,
        "",
        null,
        banner,
        getGlobal(GlobalKey.TOKEN)
      );
      this.props.onDone(banner);
    } else {
      let banner = { ...this.state, ...etc };
      banner.imageBase64 = null;
      let result = await fetchAPI(
        ADMIN_API.BANNER_ADD,
        "",
        null,
        banner,
        getGlobal(GlobalKey.TOKEN)
      );
      this.setState({ id: result.data });
      banner.id = result.data;
      this.props.onDone(banner);
    }
  };

  onDelete = async () => {
    //delete
    if (this.state.id) {
      if (this.props.banner.deletedAt)
        await fetchAPI(
          ADMIN_API.BANNER_DELETE,
          "/" + this.state.id,
          { revive: true },
          null,
          getGlobal(GlobalKey.TOKEN)
        );
      else
        await fetchAPI(
          ADMIN_API.BANNER_DELETE,
          "/" + this.state.id,
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
      this.props.onDone(null);
    }
  };

  onImageSelected = async (e) => {
    // console.dir(e);
    log(
      LogLevel.UI_ACTION,
      "ViewerCommentComposer:onImageSelected",
      e.target.files[0]
    );
    console.dir(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0]).then((base64: string) => {
        this.setState({ imageBase64: base64 });
      });
    }
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "BannerEdit:render",
      this.props.banner,
      this.state
    );
    let imageChanger;
    if (this.state.imageBase64) {
      imageChanger = (
        <div className="admin-image-show-n-change-container">
          <img
            className="admin-image-show-n-change-image"
            src={this.state.imageBase64}
          />
          <input
            className="admin-image-show-n-change-file"
            multiple={false}
            type="file"
            accept="image/*"
            onChange={this.onImageSelected}
          />
        </div>
      );
    } else if (this.state.imageUrl) {
      imageChanger = (
        <div className="admin-image-show-n-change-container">
          <img
            className="admin-image-show-n-change-image"
            src={StringUtil.convertFilePath(this.state.imageUrl)}
          />
          <input
            className="admin-image-show-n-change-file"
            multiple={false}
            type="file"
            accept="image/*"
            onChange={this.onImageSelected}
          />
        </div>
      );
    } else {
      imageChanger = (
        <input
          multiple={false}
          type="file"
          accept="image/*"
          onChange={this.onImageSelected}
        />
      );
    }

    let deleted: boolean = false;
    if (this.props.banner && this.props.banner.deletedAt) deleted = true;

    const size = ["1920x480", "1200x1200", "960x300"];
    return (
      <div className="admin-content-manage-container">
        <div
          className={
            "user-manage-title" + (deleted ? " common-color-caution" : "")
          }
        >
          ID: {this.state.id}
          {deleted ? "[삭제]" : ""}
        </div>
        <div
          className={
            "user-manage-title" + (deleted ? " common-color-caution" : "")
          }
        >
          TYPE: {BannerType[this.state.type]}
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">이름 :</div>
          <IonInput
            class="user-search-input"
            placeholder="이벤트명"
            value={this.state.name}
            onIonChange={(e) =>
              this.setState({ name: e.detail.value, edited: true })
            }
          />
        </div>
        <div className="user-manage-title">이미지 :{size[this.state.type]}</div>
        {imageChanger}
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">TargetUrl :</div>
          <IonInput
            class="user-search-input"
            placeholder="표시 URL ex. /"
            value={this.state.targetUrl}
            onIonChange={(e) =>
              this.setState({ targetUrl: e.detail.value, edited: true })
            }
          />
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">우선순위 :</div>
          <IonInput
            class="user-search-input"
            placeholder="0"
            type="number"
            value={this.state.priority.toString()}
            onIonChange={(e) =>
              this.setState({
                priority: parseInt(e.detail.value),
                edited: true,
              })
            }
          />
        </div>
        <div className="admin-title">게시기간</div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.startAt}
            onChange={this.onDateStartChange}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.endAt}
            onChange={this.onDateEndChange}
          />
        </div>
        {this.state.type == BannerType.PARTNERS && (
          <>
            <div className="admin-title">카테고리타입</div>
            <div className="common-container-row-wrap admin-margin-bottom admin-margin-top">
              {Object.keys(CompanyType)
                .filter((key) => !isNaN(Number(key)))
                .map((item: any, index) => (
                  <div
                    key={index.toString()}
                    className={
                      "admin-toggle" +
                      (this.state.companyType == item
                        ? " admin-toggle-selected"
                        : "")
                    }
                    onClick={() => this.setState({ companyType: item })}
                  >
                    {CompanyTypeName[item]
                      ? CompanyTypeName[item]
                      : CompanyType[item]}
                  </div>
                ))}
            </div>
          </>
        )}
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">조건 (Oscar 문의)</div>
          <div
            className="admin-text-button"
            onClick={() =>
              this.setState({
                etcStr: JSON.stringify(
                  { condition: CONDITION_USER_OWNER },
                  null,
                  "\t"
                ),
              })
            }
          >
            약국장
          </div>
          <div
            className="admin-text-button"
            onClick={() =>
              this.setState({
                etcStr: JSON.stringify(
                  { condition: CONDITION_USER_EMPLOYEE },
                  null,
                  "\t"
                ),
              })
            }
          >
            근무약사
          </div>
          <div
            className="admin-text-button"
            onClick={() =>
              this.setState({
                etcStr: JSON.stringify(
                  {
                    advertiserInfo: {
                      code: "",
                      productCode: "",
                      contentType: "",
                      contentId: 1,
                      contentTitle: "",
                    },
                  },
                  null,
                  "\t"
                ),
              })
            }
          >
            광고정보
          </div>
        </div>
        <Textarea
          className="admin-text-area"
          minRows={3}
          maxRows={15}
          value={this.state.etcStr}
          onChange={(e) => {
            this.setState({ etcStr: e.target.value });
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />

        <div className="admin-content-manage-button-container">
          <IonButton
            color="admin-content-manage-cancel-button"
            onClick={this.onCancel}
          >
            취소
          </IonButton>
          <IonButton color="admin-content-manage-button" onClick={this.onDone}>
            저장
          </IonButton>
          <IonButton
            color="admin-content-delete-button"
            onClick={this.onDelete}
          >
            삭제{" "}
            {this.props.banner && this.props.banner.deletedAt ? "취소" : ""}
          </IonButton>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  // filePath : state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BannerEdit);
