

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonLabel } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import * as API from './../../../API.json';
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../../models/Model.Board';
import Truncate from 'react-truncate';
import { BannerInfo, EMPTY_BANNER, BannerType } from '../../../models/Model.Banner';
import { PollInfo, EMPTY_POLL, PollItemInfo, PollResponseInfo } from '../../../models/Model.Poll';
import PollEdit from './PollEdit';
import PollResponse from './PollResponse';
import { getDateStringFromToday } from '../../../utils/TimeUtil';

const AdminAPI = {        
  "RESPONSE_LIST" : {"method":"GET", "path":"/admin/poll/", "contentType":"application/json"},
};

type Props = {
  onBack: () => void,
  // onPop: () => void,
  onNext: (component:any, depth: number, data:any) => void, 
  selectedPoll: PollInfo;
  depth: number;
  // selectedResponse: PollResponseInfo;
};

type State = {
  reponses: PollResponseInfo[],
  end: boolean;
}

class PollResponses extends Component<Props, State> {
  state = {
    reponses: [],
    end: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData(true);
  }

  componentDidUpdate(prevProps){
    log(LogLevel.UI_LIFECYCLE, "PollResponses:componentDidUpdate", prevProps.selectedPoll, this.props.selectedPoll);
    if(this.props.selectedPoll && this.props.selectedPoll.id && (!prevProps.selectedPoll || !prevProps.selectedPoll.id || prevProps.selectedPoll.id != this.props.selectedPoll.id))
      this.fetchData(true);
  }

  onSelect = (response: PollResponseInfo) => {
    log(LogLevel.UI_EVENT, "PollResponses:onSelect", response);
    this.props.onNext(PollResponse, this.props.depth, {selectedResponse: response});
  }

  fetchData = async ( refresh:boolean = false) => {
    if(!this.props.selectedPoll || !this.props.selectedPoll.id)
      return;

    let result = await fetchAPI(AdminAPI.RESPONSE_LIST, this.props.selectedPoll.id + "/responses", {start:(refresh?0:this.state.reponses.length), count:10}, null, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error && result.data){
      let end = !!(result.data.length < 10)
      log(LogLevel.UI_EVENT, "PollResponses:fetchData", result.data, end, refresh);
      this.setState({reponses: [...(refresh?[]:this.state.reponses), ...result.data], end});
    }
    else{
      log(LogLevel.UI_EVENT, "PollResponses:fetchData failed", result, refresh);
      this.setState({reponses: [...(refresh?[]:this.state.reponses)], end:true});
    }
  }

  
  onAdd = () => {
    this.props.onNext(PollEdit, this.props.depth, {selectedPoll: {...EMPTY_POLL}});
  }

  render() {
    log(LogLevel.UI_EVENT, "PollResponses:render", this.state);
    let more;
    if(!this.state.end){
      more = (
        <IonButton expand="full" onClick={(e) => this.fetchData()}>
          <IonIcon name="add"/>
        </IonButton>
      );
    }
    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button" onClick={this.props.onBack}>
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="viewer-title" >설문 답변 목록</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="admin-board-list-container">
          <IonList>
            { this.state.reponses.map((item:PollResponseInfo, index: number) => {
                return (
                  <div key={index.toString()} className="admin-board-list-item-container" onClick={() => this.onSelect(item)}>
                    <div className="admin-row"> 
                      <div className="admin-grow">{item.id} - {(item.user && item.user.name)?item.user.name:item.userId.toString()} - {getDateStringFromToday({date:item.createdAt})}</div>
                    </div>
                  </div>
                );
              }
            )}              
          </IonList>
          {more}
        </div>
      </div>
    );
  }
}

export default PollResponses;