import React from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

interface SpinnerProps {
  innerColor?: string;
}

const SpinnerContainer = styled.div<SpinnerProps>`
  .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid transparent;
    background-image: ${({ innerColor = COLOR_SYSTEM.get("Gray")[0] }) => `
      linear-gradient(${innerColor}, ${innerColor}),
      conic-gradient(
        from 90deg at 50% 50%,
        rgba(39, 171, 174, 0) 0deg,
        rgba(178, 245, 226, 0) 0.04deg,
        ${COLOR_SYSTEM.get("Skyblue")[400]} 360deg
      )
    `};
    border-radius: 50%;
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: spinner 0.7s linear infinite;
    @keyframes spinner {
      from {
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

const Spinner: React.FC<SpinnerProps> = ({ innerColor }) => {
  return (
    <SpinnerContainer innerColor={innerColor}>
      <div className="spinner"></div>
    </SpinnerContainer>
  );
};

export default Spinner;
