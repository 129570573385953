import { getGlobal, GlobalKey } from "./GlobalUtil";

const DeliveryTrackingURL = {
    "우체국": "http://service.epost.go.kr/trace.RetrieveRegiPrclDeliv.postal?sid1=###",
    "CJ대한통운": "https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=###",
    "현대": "http://www.hlc.co.kr/hydex/jsp/tracking/trackingViewCus.jsp?InvNo=###",
    "한진": "http://www.hanjin.co.kr/Delivery_html/inquiry/result_waybill.jsp?wbl_num=###",
    "로젠": "http://d2d.ilogen.com/d2d/delivery/invoice_tracesearch_quick.jsp?slipno=###",
    "동부익스프레스": "http://www.dongbups.com/newHtml/delivery/dvsearch.jsp?mode=SEARCH&search_type=1&sellNum=Y&search_item_no=###",
    "옐로우캡": "http://www.yellowcap.co.kr/custom/inquiry_result.asp?invoice_no=###",
    "경동": "http://kdexp.com/basicNewDelivery.kd?barcode==###",
    "대신": "http://home.daesinlogistics.co.kr/daesin/jsp/d_freight_chase/d_general_process2.jsp?billno1=###",
    "이노지스": "http://www.innogis.net/trace02.asp?invoice=###",
    "편의점": "http://www.doortodoor.co.kr/jsp/cmn/TrackingCVS.jsp?pTdNo=###",
    "KGB": "http://www.kgbls.co.kr//sub5/trace.asp?f_slipno=###",
    "합동": "https://hdexp.co.kr/basic_delivery.hd?barcode=###",
    "일양로지스": "http://www.ilyanglogis.com/functionality/card_form_waybill.asp?hawb_no=###",
    "천일": "http://www.cyber1001.co.kr/kor/taekbae/HTrace.jsp?transNo=###",
    "용마로지스": "http://yeis.yongmalogis.co.kr/trace/etrace_yongma.asp?OrdCode=###",
    "GTX로지스": "http://www.gtxlogis.co.kr/tracking/default.asp?awblno=###",
    "고려": "http://www.klogis.kr/03_business/01_tracking_detail_bcno.asp?bcno=###",
    "다젠": "http://www.dazen.co.kr/admin/search/trace_view.asp?buy_no=###",
    "퀵퀵닷컴": "http://www.quickquick.com/q/MTrack.php?hawb=###",
    "KG로지스": "http://www.kglogis.co.kr/delivery/delivery_result.jsp?item_no=### ",
    "CU편의점": "https://www.cupost.co.kr/postbox/delivery/localResult.cupost?invoice_no=###",
    "롯데": "https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=###",
    "홈픽": "https://www.homepick.com/order/deliveryInfoView/###",
    "WARPEX": "http://packing.warpex.com/api/warpexTrack?wbl=###",
    "WIZWA": "http://www.wizwa.co.kr/tracking_exec.php?invoice_no=###",
    "EMS": "http://service.epost.go.kr/trace.RetrieveEmsTrace.postal?ems_gubun=E&POST_CODE=###",
    "DHL": "http://www.dhl.co.kr/ko/express/tracking.html?brand=DHL&AWB=###",
    "FEDEX": "http://www.fedex.com/Tracking?cntry_code=kr&language=korean&action=track&tracknumbers=###",
    "FHL Express": "http://www.cjusa.net/sub5/delivery_pod.asp?s_no=###",
    "TNT Express": "http://www.tnt.com/webtracker/tracking.do?respCountry=kr&respLang=ko&searchType=CON&cons=###",
    "UPS Korea": "http://www.ups.com/WebTracking/track?loc=ko_KR&InquiryNumber1=###",
    "USPS": "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=###"
}

export function jumpToDeliveryTracker(company, id){
    if(!company || !id)
        return;
    if(DeliveryTrackingURL[company]){
        let url = DeliveryTrackingURL[company];
        url = url.replace(/###/ig, id);
        window.open(url, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
    }
}