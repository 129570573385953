
import React, { Component } from 'react';
import './../UserManage.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';;
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel } from '../../models/Model.User';
import BoardContentEdit from './BoardContentEdit';
import { BoardContent } from '../../models/Model.Board';

type Props = {
  onDone: () => void;
};

type State = {
  content: BoardContent;

}

class UserManagePopup extends Component<Props, State> {
  state={
    content: null,
  };

  show = (content: BoardContent) => {
    log(LogLevel.UI_EVENT, "UserManagePopup:show")
    this.setState({content:content});
  }

  hide = () => {
    log(LogLevel.UI_EVENT, "UserManagePopup:hide")
    this.setState({content: null});
  }

  onDone = () => {
    this.hide();
    this.props.onDone();
  }

  onCancel = () => {
    this.hide();
  }

  render() {
    return (

      <IonModal cssClass="user-manage-popup-container"
        isOpen={this.state.content?true:false}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ content: null }))}
      >
        <BoardContentEdit content={this.state.content} onDone={this.onDone} onCancel={this.onCancel}/>
      </IonModal>
    );
  }
}

export default UserManagePopup;