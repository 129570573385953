

import React, { Component } from 'react';
import '../../Admin.scss';
import '../../SeminarManage.css';
import '../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import ProfileImage from '../../../components/ProfileImage';
import { SeminarLecture } from '../../../models/Model.Seminar';
import { defaultNewLecture } from '../../SeminarManage';

const API = {        
  "CATEGORY_LIST" : {"method":"GET", "path":"/admin/seminar/categories", "contentType":"application/json"},
  "CHECK_CATEGORY" : {"method":"GET", "path":"/admin/seminar/check/category", "contentType":"application/json"},
  "ADD_CATEGORY" : {"method":"POST", "path":"/admin/seminar/category", "contentType":"application/json"},
  "UPDATE_CATEGORY" : {"method":"PUT", "path":"/admin/seminar/category", "contentType":"application/json"},
  "DELETE_CATEGORY" : {"method":"DELETE", "path":"/admin/seminar/category", "contentType":"application/json"},
};

const ROW_CNT = 50;

type Props = {
  onSelect: (category:any) => void;
  selected?: SeminarLecture,
};

type State = {
  categories: any[],
  newCategoryTitle:string,
}

class SeminarCategorySelect extends Component<Props, State> {
  finished = false;
  loading = false;
  lectureList = null;

  state = {
    categories: [],
    newCategoryTitle:"",
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onScroll = (event) => {
    let target:any = event.target;
    // log(LogLevel.UI_EVENT, "BoardList:scroll", event.detail.scrollTop, target.clientHeight, this.contentList.clientHeight);
    if(!this.loading && (target.scrollTop + target.clientHeight) > (this.lectureList.clientHeight * 0.9)){
      log(LogLevel.UI_EVENT, "SeminarCategorySelect:onScroll", target.scrollTop, target.clientHeight, this.lectureList.clientHeight);
      this.fetchDataMore();
    }
  }

  refresh = () =>{
    this.fetchData();
  }

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SeminarCategorySelect:fetchData");
    this.loading = true;
    fetchAPI(API.CATEGORY_LIST, "", {start:0, count: ROW_CNT, query:0}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        log(LogLevel.UI_DATA_LOAD, "Admin:SeminarCategorySelect:fetchData result", result);
        this.finished = false;
        if(result.data && result.data.length < ROW_CNT)
          this.finished = true;

        this.setState({categories: result.data});
        this.loading = false;
      }
      else{
        log(LogLevel.UI_DATA_LOAD, "Admin:SeminarCategorySelect:fetchData result", result);
        this.finished = true;
        this.setState({categories: []});
      }
    });
  }

  fetchDataMore = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SeminarCategorySelect:fetchDataMore", this.finished);
    this.loading = true;
    if(!this.finished){
      fetchAPI(API.CATEGORY_LIST, "", {start:this.state.categories.length, count: ROW_CNT}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          log(LogLevel.UI_DATA_LOAD, "Admin:SeminarCategorySelect:fetchData result", result);
          this.finished = false;
          if(result.data && result.data.length < ROW_CNT)
            this.finished = true;

          this.setState({categories: [...this.state.categories, ...result.data]});
          this.loading = false;
        }
        else{
          log(LogLevel.UI_DATA_LOAD, "Admin:SeminarCategorySelect:fetchData result", result);
          this.finished = true;
          this.loading = false;
        }
      });
    }

  }

  onSelect = (category: SeminarLecture) => {
    log(LogLevel.UI_EVENT, "onSelect", category);
    this.props.onSelect(category);
  }

  onAdd = async () => {
    // this.props.onDone({...defaultNewLecture});
    if(this.state.categories && this.state.categories.length >0){
      for(let i=0; i<this.state.categories.length; i++){
        if(this.state.newCategoryTitle == this.state.categories[i].title){
          this.setState({newCategoryTitle:""});
          return;
        }
      }
    }
    await fetchAPI(API.ADD_CATEGORY, "", null, {title:this.state.newCategoryTitle}, getGlobal(GlobalKey.TOKEN));
    this.setState({newCategoryTitle:""});
    this.onChange();
  }

  onChange = () => {
    this.fetchData();
  }

  render() {
    return (
      <div className="admin-container" onScroll={this.onScroll}>
        <div className="common-list">
          <div className="common-flex-row">
            <IonInput class="user-search-input" placeholder="이름" value={this.state.newCategoryTitle} onIonChange={(e) => {this.setState({newCategoryTitle:e.detail.value})}}/>
            <IonButton onClick={this.onAdd}>
              <IonIcon name="add"/>
            </IonButton>
          </div>

          <IonList ref={(ref) => {this.lectureList = ref}} >
            { this.state.categories.map((item:any, index: number) => (
              <SeminarCategoryItem key={index.toString()} category={item} onClick={this.onSelect} selected={this.props.selected.categories && this.props.selected.categories.includes(item)} onChange={this.onChange}/>
            ))}              
          </IonList>
        </div>
      </div>  
    );
  }
}

  
type CategoryItemProps = {
  category:any;
  onClick: (category:any) => void,
  onChange: () => void,
  key: string;
  selected: boolean,
};

type CategoryItemState = {
  onEdit: boolean,
  title: string,
  checked: number,
}

class SeminarCategoryItem extends Component<CategoryItemProps, CategoryItemState> {

  state = {
    onEdit: false,
    title: "",
    checked: 0,
  };


  constructor(props: CategoryItemProps) {
    super(props);
  }

  onClick = (e) => {
    e.stopPropagation();
    if(!this.state.onEdit && this.props.onClick && !this.props.selected) {
      this.props.onClick(this.props.category);
    }
  }

  onChangeCategoryChecked = (e) => {
    let value = parseInt(e.target.value);
    if(!value)
      value = 0;
    else if (value < 0)
      value = 0;
    else if (value > 16)
      value = 16;

    this.setState({checked: value});
  }

  onChangeCategoryTitle = (e) => {
    this.setState({title:e.detail.value});
  }

  onDelete = async (e) => {
    e.stopPropagation();
    let cnt = 0;
    let result = await fetchAPI(API.CHECK_CATEGORY, "", {id:this.props.category.id}, null, getGlobal(GlobalKey.TOKEN));

    if(result && !result.error){
      cnt = result.data;
    }

    result = confirm("삭제하시겠습니까?" + (cnt?cnt+"번 참조되고 있습니다.":""));
    if(result){
      await fetchAPI(API.DELETE_CATEGORY, "", {id:this.props.category.id}, null, getGlobal(GlobalKey.TOKEN));
      this.props.onChange();
    }

    this.setState({onEdit:false})

  }

  onSave = async (e) => {
    e.stopPropagation();
    if(this.props.category.checked != this.state.checked || this.props.category.title != this.state.title){
      let data = {...this.props.category, checked:this.state.checked, title:this.state.title}
      await fetchAPI(API.UPDATE_CATEGORY, "", null, data, getGlobal(GlobalKey.TOKEN));
      this.props.onChange();
    }
    this.setState({onEdit:false})

  }
  
  render() {
    if(this.state.onEdit){
      return (
        <div className="common-flex-row">
          <IonButton onClick={this.onDelete}>
              <IonIcon name="trash"/>
          </IonButton>
          <input style={{width:"100px"}} placeholder="표시순위" value={this.state.checked.toString()} onChange={this.onChangeCategoryChecked}/>
          <IonInput class="user-search-input" placeholder="이름" value={this.state.title} onIonChange={this.onChangeCategoryTitle}/>
          <IonButton onClick={this.onSave}>
              <IonIcon name="save"/>
          </IonButton>
        </div>  
      );
    }
    return (
      <div className="common-flex-row" onClick={this.onClick}>
        <div className={"admin-title" + (this.props.category.checked?" common-color-caution":"")} style={{width:"100px"}}>{this.props.category.checked}</div>
        <div className="admin-title common-flex-grow">{this.props.category.id} - {this.props.category.title}</div>
        <IonButton onClick={(e) => {e.stopPropagation(); this.setState({onEdit:true, title:this.props.category.title, checked:this.props.category.checked})}}>
            <IonIcon name="menu"/>
        </IonButton>
      </div>  
    );
  }
}

export default SeminarCategorySelect;