

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonContent, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import {log, LogLevel} from '../../../utils/LogUtil'
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { loadImageBase64 } from '../../../utils/ImageUtil';
import { BoardContent, Attachment, BoardContentCommercialType } from '../../../models/Model.Board';
import * as API from './../../../API.json';
import { EMPTY_SEARCH_KEYWORD, SearchKeywordInfo, SearchKeywordRelatedContentInfo, SearchKeywordType } from '../../../models/Model.Search';
import ImageViewNSelect from '../ImageViewNSelect';
import { start } from 'repl';


const AdminAPI = {        
  SEARCH_KEYWORD_POST : {"method":"POST", "path":"/admin/search/keyword", "contentType":"application/json"},
  SEARCH_KEYWORD_PUT : {"method":"PUT", "path":"/admin/search/keyword", "contentType":"application/json"},
  SEARCH_KEYWORD_DELETE : {"method":"DELETE", "path":"/admin/search/keyword", "contentType":"application/json"},
};

type Props = {
  onDone?: (keyword:SearchKeywordInfo) => void;
  selected?:SearchKeywordInfo;
  progressPopup : any,
  toastPopup: any,
  filePath : any,
};

type State = {
  id?: number,
  keyword?: string,
  type?: SearchKeywordType,
  order?: number,
  startAt?: any,
  endAt?: any,
  startAtWarn : boolean,
  endAtWarn : boolean,

  deletedAt?: any,

  commercialType?: BoardContentCommercialType,
  advertiserCode?: string;
  advertiserProductCode?: string;

  relatedContents?: SearchKeywordRelatedContentInfo[]
  
  edited: boolean,
  loadSeminar: boolean,
  ids: string,
}

class SearchKeywordEdit extends Component<Props, State> {
  id:number = 0;
  doneProcessing: boolean = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...EMPTY_SEARCH_KEYWORD,
      ...this.props.selected, 
      edited:false, 
      startAtWarn : false,
      endAtWarn : false,
      loadSeminar: false,
      ids:"",
    };
  }

  componentDidMount() {
    if(this.props.selected && this.props.selected.id && this.props.selected.type == SearchKeywordType.RECOMMENDED_RESULTS){
      this.props.progressPopup.show();
      fetchAPI(API.SEACH_KEYWORD_RELATIVE_GET, "", {id:this.props.selected.id}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          this.setState({relatedContents: result.data});
        }
      }).catch((e) => {
        log(LogLevel.UI_EXCEPTION, "SearchKeywordEdit:componentDidMount fetch exception", e);
      })
      this.props.progressPopup.hide();

    }
  }
  
  componentWillUpdate(nextProps:Props, nextState){
    log(LogLevel.UI_DATA_LOAD, "SearchKeywordEdit:componentWillUpdate", nextProps, this.props);
    if(nextProps.selected && (!this.props.selected || this.props.selected.id != nextProps.selected.id || this.props.selected.type != nextProps.selected.type)){
      this.props.progressPopup.show();
      let startAt = nextProps.selected.startAt;
      if(startAt.length > 10){
        let date = new Date(startAt);
        date.setHours(date.getHours() + 9);
        startAt = date.toISOString().substring(0, 10);
      }
      let endAt = nextProps.selected.endAt;
      if(endAt.length > 10){
        let date = new Date(endAt);
        date.setHours(date.getHours() + 9);
        endAt = date.toISOString().substring(0, 10);
      }
      this.setState({
        ...EMPTY_SEARCH_KEYWORD,
        ...nextProps.selected, 
        edited:false, 
        startAt,
        startAtWarn : false,
        endAt,
        endAtWarn : false,
      });

      

      if(nextProps.selected.id && nextProps.selected.type == SearchKeywordType.RECOMMENDED_RESULTS){
        fetchAPI(API.SEACH_KEYWORD_RELATIVE_GET, "", {id:nextProps.selected.id}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
          if(result && !result.error){
            this.setState({relatedContents: result.data});
          }
        }).catch((e) => {
          log(LogLevel.UI_EXCEPTION, "SearchKeywordEdit:componentWillUpdate fetch exception", e);
        })
      }
      this.props.progressPopup.hide();
    }
  }

  onDone = async () => {
    
    if(!this.state.edited || this.doneProcessing)
      return;
    this.doneProcessing = true;
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("키워드 저장 시작...");
    let keyword:SearchKeywordInfo = {
      id: this.state.id,
      keyword: this.state.keyword,
      type: this.state.type,
      order: this.state.order,
      startAt: this.state.startAt,
      endAt: this.state.endAt,
      deletedAt: this.state.deletedAt,
      
      commercialType: this.state.commercialType,
      advertiserCode: this.state.advertiserCode,
      advertiserProductCode: this.state.advertiserProductCode,
    }

    this.props.progressPopup.setLabel("이미지 업로드 중...");
    if(this.state.relatedContents){
      let relatedContents: SearchKeywordRelatedContentInfo[] = [...this.state.relatedContents];
      for(let i = 0; i<relatedContents.length; i++){
        if(relatedContents[i].imageBase64){
          let data:any = {path:"keyword"};
          data.base64 = relatedContents[i].imageBase64;
          try {
            let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:keyword.id.toString() + "_cl"}, data, getGlobal(GlobalKey.TOKEN));
            if(result && !result.error){
                relatedContents[i].imageBase64 = "";
                relatedContents[i].imageUrl = result.file;
                log(LogLevel.UI_ACTION, 'SearchKeywordEdit:onDone upload : ', result.file);
            }else{
                log(LogLevel.UI_ACTION, "SearchKeywordEdit:onDone failed", result);
                this.props.progressPopup.hide();
                this.doneProcessing = false;
                this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
                return;
            }

          } catch (error) {
            log(LogLevel.UI_ACTION, "SearchKeywordEdit:onDone : failed", error);
            this.props.progressPopup.hide();
            this.doneProcessing = false;
            this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
            return;
          }
        }
      }
      keyword.relatedContents = relatedContents;
    }

    if(keyword.id){
      let result = await fetchAPI(AdminAPI.SEARCH_KEYWORD_PUT, "", null, keyword, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error){
        this.props.onDone(keyword);
      }
      else{
        this.props.progressPopup.hide();
        this.doneProcessing = false;
        this.props.toastPopup.show("키워드 정보 업데이트가 실패했습니다.");
        return;
      }
    }else{
      let result = await fetchAPI(AdminAPI.SEARCH_KEYWORD_POST, "", null, keyword, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error){
        this.props.onDone(keyword);
      }
      else{
        this.props.progressPopup.hide();
        this.doneProcessing = false;
        this.props.toastPopup.show("키워드 정보 생성이 실패했습니다.");
        return;
      }
    }


    this.props.progressPopup.hide();
    this.doneProcessing = false;
  }

  onImageSelected = async (index, e) => {
    log(LogLevel.UI_ACTION, "SearchKeywordEdit:onImageSelected",e.target.files)
    if(index <0)
      return;
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let base64 = await loadImageBase64(e.target.files[0]);
      if(base64){
        let updates = {edited:true, relatedContents:[...this.state.relatedContents]};
        updates.relatedContents[index].imageBase64 = base64;
        this.setState(updates);
      }
    }
    this.props.progressPopup.hide();
  }

  onChangeRelatives = (index, name, value) => {
    log(LogLevel.UI_ACTION, "SearchKeywordEdit:onChangeRelatives",index, name, value)
    let updates = {edited:true, relatedContents:[...this.state.relatedContents]};
    updates.relatedContents[index][name] = value;
    this.setState(updates);
  }

  onDateChange = (name, e) => {
    let data = e.target.value;
    let updates:any = {edited:true};

    updates[name] = data;

    try{
      let date = Date.parse(data);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "Admin:SearchKeywordEdit:onDateStartChange", data);
        updates[name+"Warn"] = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "Admin:SearchKeywordEdit:onDateStartChange", data, date);
        updates[name+"Warn"] = false;
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "Admin:SearchKeywordEdit:onDateStartChange", data, err);
      console.log(err);
      updates[name+"Warn"] = true;
    }
    this.setState(updates);
  }


  onIdKeyPress = async (e) => {
    log(LogLevel.UI_ACTION, "DailyDigest:onIdKeyPress", this.state.ids, e.keyCode)
    if(e.keyCode == 13){
      this.props.progressPopup.show();
      let ids:string[] = this.state.ids.split(/[, ]/);
      log(LogLevel.UI_ACTION, "DailyDigest:onIdKeyPress", ids)

      let contents: SearchKeywordRelatedContentInfo[] = [];
      for(let i=0; i<ids.length; i++){
        let id = parseInt(ids[i]);
        if(id){
          let exists = false;
          if(contents){
            for(let j = 0; j<contents.length; j++){
              if(!this.state.loadSeminar && contents[j].contentId == id){
                exists = true;
                break;
              }
              else if(this.state.loadSeminar && contents[j].seminarId == id){
                exists = true;
                break;
              }
            }
          }
          if(!exists){
            if(!this.state.loadSeminar) {
              let result = await fetchAPI(API.BOARD_DETAIL_REQUEST, id, null, null, getGlobal(GlobalKey.TOKEN) )
              if(result && !result.error){
                let content: SearchKeywordRelatedContentInfo = {
                  contentId: id,
                  content: result.data,
                  order: 0,
                }
                contents.push(content);
              }
            }else{
              let result = await fetchAPI(API.SEMINAR_LECTURE_GET, '', {id}, null, getGlobal(GlobalKey.TOKEN) )
              if(result && !result.error){
                let content: SearchKeywordRelatedContentInfo = {
                  seminarId: id,
                  seminar: result.data,
                  order: 0,
                }
                contents.push(content);
              }
            }
          }
        }
      }
      if(contents.length)
        this.setState({ids:'', relatedContents:[...(this.state.relatedContents?this.state.relatedContents:[]), ...contents], edited: true});
      else
        this.setState({ids:''});
      this.props.progressPopup.hide();
    }
  }

  onDelete = async () => {
    try {
      fetchAPI(AdminAPI.SEARCH_KEYWORD_DELETE, '', {id:this.state.id}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error)
          this.props.onDone(null);
      })
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "SearchKeywordEdit:onDelete revival failed", e);
    }  
  }

  deleteRelatedContents = (index:number) => {
    let relatedContents;
    if(!this.state.relatedContents)
      relatedContents = [];
    else 
      relatedContents = [...this.state.relatedContents];

    if(index >= relatedContents.length)
      return;
    relatedContents.splice(index, 1);
    this.setState({relatedContents, edited:true})
  }

  render() {
    if(!this.props.selected)
      return null;

    log(LogLevel.UI_LIFECYCLE, "SearchKeywordEdit:render", this.state);

    return (
      <div className="admin-container">
        <IonContent class="common-content common-scroll">
          <IonToolbar color="html-composer-toolbar">
            <IonButtons slot="end">
              <IonButton onClick={() => this.onDelete()}>
                <div className="html-composer-button">삭제</div>
              </IonButton>
              <IonButton disabled={!this.state.edited} onClick={this.onDone}>
                <div className="html-composer-button">완료</div>
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <div className="admin-title">ID : {this.state.id} </div>
          <div className="admin-title">Type : {SearchKeywordType[this.state.type]} </div>
          <div className="common-container-row common-flex-align-center">
              <div className="admin-title">키워드 :</div>
              <IonInput class="admin-input" value={this.state.keyword} onIonChange={(e) => this.setState({keyword: e.detail.value, edited:true})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
              <div className="admin-title">순서 (0~) :</div>
              <IonInput class="admin-input" type="number" value={this.state.order.toString()} onIonChange={(e) => this.setState({order: parseInt(e.detail.value), edited:true})}/>
          </div>
          <div className="admin-title">게시기간</div>
          <div className="common-container-row-wrap admin-margin-bottom">
            <input className={(this.state.startAtWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.startAt} onChange={(e) => this.onDateChange("startAt", e)}/>
            <div>~</div>
            <input className={(this.state.endAtWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.endAt} onChange={(e) => this.onDateChange("endAt", e)}/>
          </div>
          <div className="admin-margin-bottom"/>
          {(this.state.type!=SearchKeywordType.HOT) && 
            <div className="common-container-row-wrap common-flex-align-center">
              <div className={"common-toggle" + ((this.state.commercialType==BoardContentCommercialType.NONE)?" common-toggle-selected":"")} onClick={() => this.setState({commercialType:BoardContentCommercialType.NONE})}>NONE</div>
              <div className={"common-toggle" + ((this.state.commercialType==BoardContentCommercialType.ADVERTISE)?" common-toggle-selected":"")} onClick={() => this.setState({commercialType:BoardContentCommercialType.ADVERTISE})}>AD</div>
              <div className={"common-toggle" + ((this.state.commercialType==BoardContentCommercialType.SPONSORED)?" common-toggle-selected":"")} onClick={() => this.setState({commercialType:BoardContentCommercialType.SPONSORED})}>SPONSORED</div>
              {(this.state.commercialType!=BoardContentCommercialType.NONE) && [
                <IonInput key="1" class="admin-input" value={this.state.advertiserCode} placeholder="advertiserCode(ex.YMYD)" onIonChange={(e) => this.setState({advertiserCode:e.detail.value})}  onClick={(e) => {e.stopPropagation()}}/>,
                <IonInput key="2" class="admin-input" value={this.state.advertiserProductCode} placeholder="advertiserProductCode(ex.APP)" onIonChange={(e) => this.setState({advertiserProductCode:e.detail.value})}  onClick={(e) => {e.stopPropagation()}}/>
              ]}
            </div>
          }
          {(this.state.type==SearchKeywordType.RECOMMENDED_RESULTS) && 
            <div className="admin-item-container">
              <div className="admin-title">지정컨텐츠</div>
              <div className={"admin-toggle" + ((!this.state.loadSeminar)?" admin-toggle-selected":"")} onClick={() => this.setState({loadSeminar:false})}>컨텐츠</div>
              <div className={"admin-toggle" + ((this.state.loadSeminar)?" admin-toggle-selected":"")} onClick={() => this.setState({loadSeminar:true})}>세미나</div>
              <IonInput class="board-composer-input" placeholder="1, 35, 24, 36" value={this.state.ids} onIonChange={(e) => {this.setState({ids:e.detail.value})}} onKeyDown={this.onIdKeyPress}/>
            </div>
          }
          {(this.state.relatedContents && this.state.relatedContents.length) && 
            <div className="common-container">
              {this.state.relatedContents.map((content: SearchKeywordRelatedContentInfo, i)=>
                <div key={i.toString()} className="common-container-row">
                  <div className="common-flex-grow admin-container-border">
                    <div className="admin-title">{content.id} - {content.contentId?"C":"S"} - {content.contentId?content.contentId:content.seminarId}</div>
                    {content.content &&
                        <div className="admin-title">{content.content.subject}</div>
                    }
                    {content.seminar &&
                        <div className="admin-title">{content.seminar.title}</div>
                    }
                    <div className="common-container-row common-flex-align-center">
                      <div className="admin-title">순서 :</div>
                      <IonInput class="admin-input" type="number" value={content.order.toString()} onIonChange={(e) => this.onChangeRelatives(i, "order", parseInt(e.detail.value))}/>
                    </div>
                    <ImageViewNSelect base64={content.imageBase64} url={content.imageUrl} onChange={(e) => this.onImageSelected(i, e)} filePath={this.props.filePath}/>
                  </div>
                  <IonButton fill="clear" color="primary" onClick={() => this.deleteRelatedContents(i)}>
                    <IonIcon name="close"/>
                  </IonButton>
                </div>
              )}
            </div>
          }
        </IonContent>
      </div>  
    );
  }
}

export default SearchKeywordEdit;