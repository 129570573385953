import React, { Component } from 'react';
import './../../../Common.css';


type Props = {
};

const InformationMarketing: React.SFC<Props> = ({}) => {
  return (
  <div className="common-block">

마케팅 정보 수신 동의 <br/>
<br/>
1. 광고성 정보의 이용목적<br/>
약문약답이 제공하는 이용자 맞춤형 서비스 및 상품 추천, 각종 경품 행사, 이벤트 등의 광고성 정보를 전자우편이나 서신우편, 문자(SMS 또는 카카오 알림톡), 푸시, 전화 등을 통해 이용자에게 제공합니다.<br/>
<br/>
- 마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할 수 있습니다. 동의를 거부하시더라도 약문약답이 제공하는 서비스의 이용에 제한이 되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.<br/>
<br/>
2. 미동의 시 불이익 사항<br/>
개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스 이용에 제한되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.<br/>
<br/>
3. 마케팅 정보 수신 동의 철회<br/>
마케팅 정보를 원하지 않을 경우 ‘환경 설정’에서 철회를 요청할 수 있습니다. 
  </div>
  );
}

export default InformationMarketing;