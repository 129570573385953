import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { fetchAPI } from "../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../models/Model.Seminar";
import { UIPopupType } from "../store/ui/types";
import { STATUS_CODES } from "http";
import {
  PollInfo,
  PollItemInfo,
  PollResponseInfo,
  PollResponseItemInfo,
} from "../models/Model.Poll";
import PollSelect from "./component/poll/PollSelect";
import { idText } from "typescript";
import DailyPharmRecruitCompanyList from "./component/dp/DailyPharmRecruitCompanyList";
import DailyPharmRecruitDetail from "./component/dp/DailyPharmRecruitDetail";
import UserProfile from "./component/user/UserProfile";
import JobpostDetail from "./component/bizjobpost/JobpostDetail";
import DailyPharmRecruitBizList from "./component/dp/DailyPharmRecruitBizList";
import BizCouponEdit from "./component/biz/BizCouponEdit";
import { EMPTY_COUPON, FREE_COUPON } from "../models/Mode.Biz";

const AdminAPI = {};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  recruit: any;
  userBizId: string;
  offerId: number;
};

class DailyPharmRecruitBizMain extends Component<Props, State> {
  lecturesList = null;
  lectureEditor = null;
  popup = null;
  state = {
    recruit: null,
    userBizId: "",
    offerId: 0,
  };

  constructor(props: Props) {
    super(props);

    // set browser title and description
    document.title = "DailyPharm Recruit Biz";
  }

  componentDidMount() {}

  onSelectRecruit = (recruit: string) => {
    this.setState({ recruit });
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyPharmRecruitBizMain.render",
      this.props,
      this.state
    );

    let detail;

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">DailyPharm Recruit Biz</IonText>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            <div className="admin-grow-container">
              <DailyPharmRecruitBizList
                onSelectRecruit={this.onSelectRecruit}
                onAddCoupon={(userBizId: string) =>
                  this.setState({ userBizId })
                }
                onSelectOfferId={(offerId: number) =>
                  this.setState({ offerId })
                }
                selectedRecruit={this.state.recruit}
              />
            </div>
            {this.state.recruit && (
              <div id="admin-sub-conainer" className="admin-container">
                <DailyPharmRecruitDetail
                  recruit={this.state.recruit}
                  onClose={() => this.setState({ recruit: "" })}
                />
              </div>
            )}
            {this.state.offerId && (
              <div id="admin-sub-conainer" className="admin-container">
                <JobpostDetail
                  offerId={this.state.offerId}
                  onBack={() => this.setState({ offerId: 0 })}
                />
              </div>
            )}
            {this.state.userBizId && (
              <div id="admin-sub-conainer" className={"admin-container"}>
                <BizCouponEdit
                  onDone={() => this.setState({ userBizId: "" })}
                  onCancel={() => this.setState({ userBizId: "" })}
                  coupon={{
                    ...EMPTY_COUPON,
                    ...FREE_COUPON,
                    bizUserId: this.state.userBizId,
                  }}
                />
              </div>
            )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId: number) =>
    actions.seminar.getSeminarLecture(lectureId),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailyPharmRecruitBizMain)
);
