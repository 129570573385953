import React from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import styled from "styled-components";
import Text from "../../atoms/Text";

export const JobPostBizDetailFieldLayout = styled.div`
  display: flex;
  flex-direction: column;

  .field__title {
    display: block;
    margin-bottom: 12px;
  }
`;

interface JobPostBizDetailFieldProps {
  title?: string;
  children: React.ReactNode;
}

const JobPostBizDetailField: React.FC<JobPostBizDetailFieldProps> = ({ title, children }) => {
  return (
    <JobPostBizDetailFieldLayout>
      {title && (
        <Text element="h4" textType="H4" color={COLOR_SYSTEM.get("Gray")[800]} className="field__title">
          {title}
        </Text>
      )}
      {children}
    </JobPostBizDetailFieldLayout>
  );
};

export default React.memo(JobPostBizDetailField);
