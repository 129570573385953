import { Map } from "immutable";

export type Typography =
  | "LargeTitle"
  | "LargeTitle2"
  | "LargeTitle3"
  | "H1"
  | "H2"
  | "H3"
  | "H4"
  | "Headline"
  | "Subhead1"
  | "Subhead2"
  | "Body1"
  | "Body1Medium"
  | "Body1SemiBold"
  | "Body1Bold"
  | "Body2"
  | "Body2Medium"
  | "Body2SemiBold"
  | "Body2Bold"
  | "Caption"
  | "CaptionBold"
  | "Caption2SemiBold"
  | "InputXXLarge"
  | "InputXLarge"
  | "InputLarge"
  | "InputMedium"
  | "InputSmall"
  | "InputXSmall";

export const typography = Map<Typography, string>({
  LargeTitle: `
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;    
  `,
  LargeTitle2: `
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;    
  `,
  LargeTitle3: `
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;    
  `,
  H1: `
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;    
  `,
  H2: `
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;    
  `,
  H3: `
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;    
  `,
  H4: `
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;    
  `,
  Headline: `
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;    
  `,
  Subhead1: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;    
  `,
  Subhead2: `
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;    
  `,
  Body1: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;    
  `,
  Body1Medium: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;    
  `,
  Body1SemiBold: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;    
  `,
  Body1Bold: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;    
  `,
  Body2: `
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;    
  `,
  Body2Medium: `
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;    
  `,
  Body2SemiBold: `
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;  
  `,
  Body2Bold: `
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;    
  `,
  Caption: `
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;    
  `,
  CaptionBold: `
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;    
  `,
  Caption2SemiBold: `
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;    
  `,
  InputXXLarge: `
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;    
  `,
  InputXLarge: `
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;    
  `,
  InputLarge: `
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;    
  `,
  InputMedium: `
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;    
  `,
  InputSmall: `
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;    
  `,
  InputXSmall: `
    font-size: 10px;
    line-height: 18px;
    font-weight: 500;    
  `,
});
