

import React, { Component } from 'react';
import './../Admin.scss';
import './../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonCheckbox, IonIcon, IonToggle } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import { timeout, async } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../models/Model.User';
import { getGlobal, GlobalKey, setGlobal } from '../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { UIPopupType } from '../../store/ui/types';
import { ComposedChart, Bar, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const API = {        
  "USER_STATISTICS" : {"method":"POST", "path":"/admin/user/register/statistics", "contentType":"application/json"},
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
};

type State = {
  fold: boolean;
  loading: boolean;
  dateStart: string;
  dateStartWarn : boolean;
  dateEnd: string;
  dateEndWarn : boolean;
  periodOption: number; // 1: day, 2: week, 3: month, 4: year
  showOptions: number;
  showGraph: boolean;
  graphSmooth : boolean;
  statistics: UserStatistics;
}


interface UserStatistics{
  unit?: number, // 0: all, 1: day, 2: week, 3: month, 4: year
  startDate?:string,
  endDate?:string,
  items?: UserStatisticsItem[],
}

export interface UserStatisticsItem{
  startDate?:string,
  endDate?:string,
  date?:string,
  register?:number,
  registerAccumulated?:number,
  active?: number,
  activeRate?: number,
}

enum UserStatisticsShowSelection{
  REGISTER = 0x01,
  REGISTER_ACCUMULATED = 0x02,
  ALL = 0x3,
}

class KeyMeasureStatisticsRegisterView extends Component<Props, State> {

  constructor(props:Props){
    super(props);
    log(LogLevel.UI_DATA_LOAD, "KeyMeasureStatisticsRegisterView constructor");
    let date = new Date(Date.now() + 9*60*60*1000);
    let fromDate = new Date(Date.now() + 9*60*60*1000);
    let toDate = new Date(Date.now() + 9*60*60*1000);
    fromDate.setMonth(date.getMonth() - 4);
    fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1, 9);
    toDate.setDate(date.getDate() + 1);

    this.state = {
      fold: true,
      loading: false,
      dateStart: fromDate.toISOString().substring(0,10),
      dateStartWarn : false,
      dateEnd: toDate.toISOString().substring(0,10),
      dateEndWarn : false,
      periodOption: 3,
      showOptions : UserStatisticsShowSelection.REGISTER,
      statistics: null,
      showGraph: true,
      graphSmooth: true,

    };
  }

  componentDidMount() {
    this.fetchStatistics();
  }

  componentWillUpdate(nextProps, nextState){
  }

  fetchStatistics = async () => {
    if(this.state.loading){
      this.props.toastPopup.show("로딩중입니다.");
      return;
    }
    if(this.state.dateStartWarn || this.state.dateEndWarn){
      this.props.toastPopup.show("검색옵션을 확인해주세요.");
      return;
    }

    let request:UserStatistics = {};
    request.unit = this.state.periodOption;
    request.startDate = this.state.dateStart;
    request.endDate = this.state.dateEnd;

    fetchAPI(API.USER_STATISTICS, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error && result.data){
        log(LogLevel.UI_DATA_LOAD, "KeyMeasureStatisticsRegisterView Fetch success", request, result);
        this.setState({statistics:result.data});
      }
      else{
        log(LogLevel.UI_EXCEPTION, "KeyMeasureStatisticsRegisterView Fetch failed", request, result);
      }
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "KeyMeasureStatisticsRegisterView Fetch excepted", request, e);
    })

  }

  onChangeShowOption = (option) => {
    let showOptions = this.state.showOptions ^ option;
    this.setState({showOptions})
  }

  onDateStartChange = (e) => {
    let dateStart = e.target.value;
    let dateStartWarn = false;
    try{
      let date = Date.parse(dateStart);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "KeyMeasureStatisticsRegisterView:onDateStartChange", dateStart);
        dateStartWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "KeyMeasureStatisticsRegisterView:onDateStartChange", dateStart, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "KeyMeasureStatisticsRegisterView:onDateStartChange", dateStart, err);
      console.log(err);
      dateStartWarn = true;
    }
    this.setState({dateStart, dateStartWarn});
  }

  onDateEndChange = (e) => {
    let dateEnd = e.target.value;
    let dateEndWarn = false;
    try{
      let date = Date.parse(dateEnd);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "KeyMeasureStatisticsRegisterView:onDateEndChange", dateEnd);
        dateEndWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "KeyMeasureStatisticsRegisterView:onDateEndChange", dateEnd, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "KeyMeasureStatisticsRegisterView:onDateEndChange", dateEnd, err);
      dateEndWarn = true;
    }
    this.setState({dateEnd, dateEndWarn});
  }

  setPeriod = (pid) => {
    let date = new Date(Date.now() + 9*60*60*1000);
    let fromDate = new Date(Date.now() + 9*60*60*1000);
    let toDate = new Date(Date.now() + 9*60*60*1000);
    toDate.setDate(date.getDate() + 1);
    switch(pid){
      case 11: // 일-2주
        fromDate.setDate(date.getDate() - 13);
        break;
      case 12: // 일-4주
        fromDate.setDate(date.getDate() - 27);
        break;
      case 13: // 일-8주
        fromDate.setDate(date.getDate() - 55);
        break;
      case 14: // 일-12주
        fromDate.setDate(date.getDate() - 83);
        break;
      case 21: // 주-4주
        fromDate.setDate(date.getDate() - 27 - date.getDay());
        break;
      case 22: // 주-8주
        fromDate.setDate(date.getDate() - 55 - date.getDay());
        break;
      case 23: // 주-12주
        fromDate.setDate(date.getDate() - 83 - date.getDay());
        break;
      case 24: // 주-서비스개시
        fromDate = new Date("2019-07-07T09:00:00");
        break;
      case 31: // 월-4개월
        fromDate.setMonth(date.getMonth() - 4);
        fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1, 9);
        break;
      case 32: // 월-12개월
        fromDate.setMonth(date.getMonth() - 12);
        fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1, 9);
        break;
      case 33: // 월-서비스개시
        fromDate = new Date("2019-07-01T09:00:00");
        break;
    }
    this.setState({
      dateStart: fromDate.toISOString().substring(0,10),
      dateStartWarn : false,
      dateEnd: toDate.toISOString().substring(0,10),
      dateEndWarn : false,
    })
  }

  onDownload = () => {
    if(!this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return;
    
    let csv = ",";
    csv += this.state.statistics.items.map((item) => item.date).join(",");
    csv += "\r\n";
    csv += "Register," + this.state.statistics.items.map((item) => item.register.toString()).join(",") + "\r\n"
    csv += "Accumulated," + this.state.statistics.items.map((item) => item.registerAccumulated.toString()).join(",") + "\r\n"

    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    pom.setAttribute('download', `User-Statistics-Register-${this.state.statistics.startDate.replace(/-/ig, '')}-${this.state.statistics.endDate.replace(/-/ig, '')}-${this.state.statistics.unit}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
  }

  renderTable = () => {
    if(this.state.showGraph || !this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return null;
    
    let label = (
      <tr>
        <td/>
        {this.state.statistics.items.map((item, index) => 
            <td className="admin-table-label-x" key={index.toString()}>{item.date}</td>
        )}
      </tr>
    );

    let data = [
        <tr>
          <td className="admin-table-label-y">가입</td>
          {this.state.statistics.items.map((item, index) => {
            let value = item.register;
            if(!value && value != 0){
              value = 0;
            }
            return (
              <td className="admin-table-value" key={index.toString()}>{value.toString()}</td>
            );
          })}
        </tr>,
        <tr>
          <td className="admin-table-label-y">누적</td>
          {this.state.statistics.items.map((item, index) => {
            let value = item.registerAccumulated;
            if(!value && value != 0){
              value = 0;
            }
            return (
              <td className="admin-table-value" key={index.toString()}>{value.toString()}</td>
            );
          })}
        </tr>,
    ];

    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <table className="admin-table">
            <tbody>
              {label}
              {data}
            </tbody>
          </table>
        </div>
        <IonButton color="primary" onClick={this.onDownload}>
          다운로드
        </IonButton>
      </div>
    );
    

  }

  renderGraph = () => {
    if(!this.state.showGraph || !this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return null;

    let width = 150 * this.state.statistics.items.length;
    if(width > 1000)
      width = 1000;

    let registerLine, accumulatedLine;
    if(!!(this.state.showOptions & UserStatisticsShowSelection.REGISTER))
      registerLine = <Bar yAxisId="left" dataKey="register" fill="blue" barSize={20}/>
    if(!!(this.state.showOptions & UserStatisticsShowSelection.REGISTER_ACCUMULATED))
      accumulatedLine = <Area yAxisId="right" type={this.state.graphSmooth?"monotone":"linear"} dataKey="registerAccumulated"  fill="#ff000033" stroke="red" />
    
    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <ComposedChart width={width} height={500} data={this.state.statistics.items}>
            {registerLine}
            {accumulatedLine}
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" scale="auto"/>
            <YAxis yAxisId="right" orientation="right" scale="auto"/>
            <Tooltip />
            <Legend />
          </ComposedChart>
        </div>

      </div>
    );
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "KeyMeasureStatisticsRegisterView:render", this.props, this.state);

    if(this.state.fold){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({fold:false})}>
            <div className="common-flex-row">
              <div>가입자 현황</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
          {this.renderGraph()}
        </div> 

      );
    }

    let smooth;
    if(this.state.showGraph)
      smooth = (
        <div className="common-flex-row">
          <IonCheckbox checked={this.state.graphSmooth} onIonChange={(e) => this.setState({graphSmooth:!this.state.graphSmooth})}/>
          <div className="admin-margin-right">Smooth Line</div>
        </div>
      );
    
    let preset;
    switch(this.state.periodOption){
      case 1:
        preset = (
          <div className="common-container-row-wrap admin-margin-bottom">
            <div className="admin-text-button" onClick={() => this.setPeriod(11)}>지난2주</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(12)}>지난4주</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(13)}>지난8주</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(14)}>지난12주</div>
          </div>
        );
        break;
      case 2:
        preset = (
          <div className="common-container-row-wrap admin-margin-bottom">
            <div className="admin-text-button" onClick={() => this.setPeriod(21)}>지난4주</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(22)}>지난8주</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(23)}>지난12주</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(24)}>서비스개시부터</div>
          </div>
        );
        break;
      case 3:
        preset = (
          <div className="common-container-row-wrap admin-margin-bottom">
            <div className="admin-text-button" onClick={() => this.setPeriod(31)}>지난4개월</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(32)}>지난12개월</div>
            <div className="admin-text-button" onClick={() => this.setPeriod(33)}>서비스개시부터</div>
          </div>
        );
        break;
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({fold:true, showGraph:true})}>
          <div className="common-flex-row">
            <div>가입자 현황</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Search Option</div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <div>단위기간</div>
                <div className={"admin-toggle" + ((this.state.periodOption==1)?" admin-toggle-selected":"")} onClick={() => this.setState({periodOption:1})}>Daily</div>
                <div className={"admin-toggle" + ((this.state.periodOption==2)?" admin-toggle-selected":"")} onClick={() => this.setState({periodOption:2})}>Weekly</div>
                <div className={"admin-toggle" + ((this.state.periodOption==3)?" admin-toggle-selected":"")} onClick={() => this.setState({periodOption:3})}>Monthly</div>
              </div>
              <div className="common-container-row admin-margin-bottom">
                <input className={(this.state.dateStartWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.dateStart} onChange={this.onDateStartChange}/>
                <div>~</div>
                <input className={(this.state.dateEndWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.dateEnd} onChange={this.onDateEndChange}/>
              </div>
              {preset}
            </div>
          </div>
          <IonButton onClick={this.fetchStatistics} disabled={this.state.loading}>Load</IonButton>
        </div>
        <div className="common-container-row admin-show-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Display Option</div>
              <div className="common-container-row">
                <div className="common-container-column">
                  <div className="search-options-container">
                    <div className={(this.state.showGraph)?"search-option-text-inactive":"search-option-text-active"} onClick={(e) => {this.setState({showGraph:false})}}>Table</div>
                    <IonToggle class="search-options-toggle" disabled={!this.state.statistics} mode="ios" checked={this.state.showGraph} onClick={(e) => this.setState({showGraph:(!this.state.showGraph)})}/>
                    <div className={(this.state.showGraph)?"search-option-text-active":"search-option-text-inactive"} onClick={(e) => {this.setState({showGraph:true})}}>Graph</div>
                  </div>
                  {smooth}
                </div>
              </div>              
              <div className="common-flex-row-wrap common-flex-grow common-flex-align-center">
                <IonButton disabled={!this.state.statistics} color="primary" onClick={this.onDownload}>
                  다운로드
                </IonButton>
                <div className="common-flex-row">
                  <IonCheckbox disabled={!this.state.statistics} checked={!!(this.state.showOptions & UserStatisticsShowSelection.REGISTER)} onIonChange={(e) => this.onChangeShowOption(UserStatisticsShowSelection.REGISTER)}/>
                  <div className="admin-margin-right" style={{color:"blue", fontWeight:"bold"}} title="가입회원수">가입자</div>
                </div>
                <div className="common-flex-row">
                  <IonCheckbox disabled={!this.state.statistics} checked={!!(this.state.showOptions & UserStatisticsShowSelection.REGISTER_ACCUMULATED)} onIonChange={(e) => this.onChangeShowOption(UserStatisticsShowSelection.REGISTER_ACCUMULATED)}/>
                  <div className="admin-margin-right" style={{color:"red", fontWeight:"bold"}} title="누적회원수">누적</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderTable()}
        {this.renderGraph()}

      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup : state.ui.popups[UIPopupType.TOAST_POPUP]
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
}
export default connect(mapStateToProps, mapDispatchToProps)(KeyMeasureStatisticsRegisterView);