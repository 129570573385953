

import React, { Component } from 'react';
import '../../Admin.scss';
import '../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonContent, IonCheckbox } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import ReactQuill from 'react-quill';
import ComponentComposePopup from '../../../ivcomponent/ComponentComposePopup';
import { loadImageBase64 } from '../../../utils/ImageUtil';
import { BoardContent, Attachment } from '../../../models/Model.Board';
import { SeminarLecture, SeminarReview, SeminarLectureAttribute, SeminarLecturer, SeminarLectureType } from '../../../models/Model.Seminar';
import { Ingredient } from '../../../models/Model.Medicine';
import AttachmentViewer from '../../../components/Attachment';
import VideoPlayer from '../../../components/VideoPlayer';
import { getTimerString } from '../../../utils/TimeUtil';
import * as API from '../../../API.json';import ImageViewNSelect from '../ImageViewNSelect';
import Textarea from 'react-textarea-autosize';
import ComposeCardnewsPopup from '../../../components/popup/ComposeCardnewsPopup';
import SegmentedControl from '../../../component/atom/SegmentedControl';
import HtmlComposer from '../../../components/HtmlComposer';


const AdminAPI = {        
  LECTURE_POST : {"method":"POST", "path":"/admin/seminar/lecture", "contentType":"application/json"},
  LECTURE_PUT : {"method":"PUT", "path":"/admin/seminar/lecture", "contentType":"application/json"},
  LECTURE_DELETE : {"method":"DELETE", "path":"/admin/seminar/lecture", "contentType":"application/json"},
};

type Props = {
  onDone?: (lecture:SeminarLecture) => void;
  onPreview?: (lecture:SeminarLecture) => void;
  onSelectIngredient?: () => void;
  onSelectCategory?: () => void;
  onStatistics?: () => void;
  onReward?: () => void;
  lecture?:SeminarLecture;
  progressPopup : any,
  toastPopup: any,
  confirmPopup: any,
  filePath : any,
};

type State = {
  id?: number;
  type?: SeminarLectureType,
  title?: string;
  subtitle?: string;
  description?: string;
  hidden?: string;
  effect?: string;
  scoreAverage?: number;
  playtime?: number;
  attribute?: number;

  lecturers?: SeminarLecturer[],
  
  companyLogoUrl?: string;
  imageUrl?: string;
  previewUrl?: string;
  bannerUrl?: string;
  shareUrl?: string;
  thumbnailUrl?: string;
  videoUrl?: string;
  videoUrlHighResolution?: string;

  imageBase64?: any;
  previewBase64?: any;
  bannerBase64?: any;
  shareBase64?: any;
  thumbnailBase64?: any;

  references?:Attachment[];
  materials?: Attachment[];
  referencesLocal?:any[];
  materialsLocal?: any[];
  referencesLocalDescription?:string[];
  materialsLocalDescription?: string[];

  ingredients?: Ingredient[];
  categories?: any[];

  passingMark?: number;

  viewed?: boolean;
  viewValue?: number;

  createdAt?: object,
  deletedAt?: any,
  edited: boolean,
  editedIngredient: boolean,
  editedCategory:boolean,
  editedAttachment: boolean,
  videoPreviewUrl?: string,

  inBanners:Attachment[];
  cards?: Attachment[],

  advertiserCode?: string;
  advertiserProductCode?: string;

  foldLectureInfo: boolean,
  foldLecturer: boolean,
  foldLectureDetail: boolean,
  foldImages: boolean,
  foldAttachment: boolean,
  foldIngredient: boolean,
  foldVideo: boolean,
  foldConsult: boolean,
  foldInBanner: boolean,
  foldDocument: boolean,
}

const keywordTypeName = ['','성분','의약품','용어','부작용','동물의약품'];

class SeminarEdit extends Component<Props, State> {
  doneProcessing = false;
  ivCardsComposerPopup;
  ivBannersComposerPopup;
  id:number = 0;

  static modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'color': []}, {'background': []}],
      ['link'],
      ['clean']
    ]
    // toolbar: [
    //   [{ 'header': [1, 2, false] }, { 'font': [] }],
    //   ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //   [{'list': 'ordered'}, {'list': 'bullet'}, 
    //    {'indent': '-1'}, {'indent': '+1'}],
    //   ['link', 'image', 'video'],
    //   ['clean']
    // ]
  }

  static formats = [
    'header', 'font', 'size',  'color', 'background',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'link', 'image', 'video'
  ]

  constructor(props: Props) {
    super(props);
    let lecturers: SeminarLecturer[] = [];
    if(props.lecture && props.lecture.lecturer){
      lecturers.push({});
      if(props.lecture.lecturer.name)
        lecturers[0].name = props.lecture.lecturer.name;
      if(props.lecture.lecturer.imageUrl)
        lecturers[0].imageUrl = props.lecture.lecturer.imageUrl;
      if(props.lecture.lecturer.career)
        lecturers[0].career = props.lecture.lecturer.career;
    }else if(props.lecture && props.lecture.lecturers){
      lecturers = props.lecture.lecturers;
    }
    

    this.state = {
      ...this.props.lecture, 
      lecturers,
      edited:false, 
      editedIngredient:false, 
      editedAttachment:false,
      editedCategory:false,
      referencesLocalDescription:[],
      materialsLocalDescription:[],
      foldLectureInfo: false,
      foldLecturer: false,
      foldLectureDetail: false,
      foldImages: false,
      foldAttachment: false,
      foldIngredient: false,
      foldVideo: false,
      foldConsult: false,
      inBanners:[],
      foldInBanner: false,
      foldDocument: false,
      advertiserCode:"",
      advertiserProductCode:"",
    };
    this.id = this.props.lecture.id;
    this.fetchData();
  }

  componentDidMount() {
  }
  
  componentWillUpdate(nextProps, nextState){
    log(LogLevel.UI_DATA_LOAD, "SeminarEdit:componentWillUpdate", nextProps, this.props);
    if(nextProps.lecture && (!this.props.lecture || this.props.lecture.id != nextProps.lecture.id)){
      let lecturers: SeminarLecturer[] = [];
      if(nextProps.lecture && nextProps.lecture.lecturer){
        lecturers.push({});
        if(nextProps.lecture.lecturer.name)
          lecturers[0].name = nextProps.lecture.lecturer.name;
        if(nextProps.lecture.lecturer.imageUrl)
          lecturers[0].imageUrl = nextProps.lecture.lecturer.imageUrl;
        if(nextProps.lecture.lecturer.career)
          lecturers[0].career = nextProps.lecture.lecturer.career;
      }else if(nextProps.lecture && nextProps.lecture.lecturers){
        lecturers = nextProps.lecture.lecturers;
      }

      let inBanners:Attachment[] = []
      if(nextProps.lecture.inBanners)
        inBanners = [...nextProps.lecture.inBanners];
      if(inBanners && inBanners.length){
        for(let i=0; i<inBanners.length; i++){
          if(inBanners[i].logData)
            inBanners[i].logDataJson = JSON.stringify(inBanners[i].logData, null, '\t');
        }
      }

      this.setState({
        referencesLocal:[],
        references:[],
        materialsLocal:[],
        materials:[],
        referencesLocalDescription:[],
        materialsLocalDescription:[],
        categories:[],
        saleItems:[],
        rewardDocumentId:"",
        rewardDocumentTabName:"",
        consultDocumentId:"",
        consultDocumentTabName:"",
        advertiserCode:"",
        advertiserProductCode:"",
        ...nextProps.lecture, 
        inBanners,
        edited:false, 
        editedIngredient:false, 
        editedAttachment:false,
        videoPreviewUrl:'',
        companyLogoBase64: '',
        imageBase64: '',
        previewBase64: '',
        bannerBase64: '',
        thumbnailBase64:"",
        lecturers,
      });
      this.id = nextProps.lecture.id;
      this.fetchData();
    }
  }

  fetchData = async () => {
    if(!this.id)
      return;
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("로딩중...");
    try {
      fetchAPI(API.SEMINAR_LECTURE_GET, '', {id:this.id}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          log(LogLevel.REDUX_BOARD, "SeminarEdit:fetchData getSeminars success", result.data)

          let lecturerName: string = "";
          let lecturerImageUrl: string = "";
          let lecturerImageBase64: string = "";
          let lecturerCareer: string[] = [];
          if(result.data.lecturer){
            if(result.data.lecturer.name)
              lecturerName = result.data.lecturer.name;
            if(result.data.lecturer.imageUrl)
              lecturerImageUrl = result.data.lecturer.imageUrl;
            if(result.data.lecturer.career)
              lecturerCareer = result.data.lecturer.career;
          }


          let inBanners:Attachment[] = []
          if(result.data.inBanners)
            inBanners = [...result.data.inBanners];
          if(inBanners && inBanners.length){
            for(let i=0; i<inBanners.length; i++){
              if(inBanners[i].logData)
                inBanners[i].logDataJson = JSON.stringify(inBanners[i].logData, null, '\t');
            }
          }

          this.setState({
            referencesLocal:[],
            references:[],
            materialsLocal:[],
            materials:[],
            categories:[],
            referencesLocalDescription:[],
            materialsLocalDescription:[],
            rewardDocumentId:"",
            rewardDocumentTabName:"",
            rewardSlackHookUrl:"",
            consultDocumentId:"",
            consultDocumentTabName:"",
            consultSlackHookUrl:"",
            advertiserCode:"",
            advertiserProductCode:"",
            ...result.data, 
            inBanners,
            editedIngredient:false, 
            editedAttachment:false,
            edited:false, 
            videoPreviewUrl:'',
            companyLogoBase64: '',
            imageBase64: '',
            previewBase64: '',
            bannerBase64: '',
            thumbnailBase64:"",
            lecturerName,
            lecturerImageUrl,
            lecturerImageBase64,
            lecturerCareer,
          });
        }
        else{
          log(LogLevel.REDUX_BOARD, "SeminarEdit:fetchData getSeminars failed", result);
        }
      }).catch((e) => {
        log(LogLevel.REDUX_BOARD, "SeminarEdit:fetchData getSeminars failed", e);
      })
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "SeminarEdit:fetchData getSeminars failed", e);
    }  
    this.props.progressPopup.hide();
  }

  onDone = async () => {
    
    if(this.doneProcessing)
      return;
    this.doneProcessing = true;
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("강좌 저장 시작...");
    let lecture:SeminarLecture = {
      id: this.state.id,
      title: this.state.title,
      subtitle: this.state.subtitle,
    }

    if(!this.state.id){
      this.props.progressPopup.setLabel("신규 강좌 등록중...");
      let result = await fetchAPI(AdminAPI.LECTURE_POST, "", null, lecture, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error){
        lecture.id = result.data;
        this.setState({id:lecture.id});
      }
      else{
        this.props.progressPopup.hide();
        this.doneProcessing = false;
        this.props.toastPopup.show("신규등록이 실패했습니다.");
        return;
      }
    }
    lecture.description =  this.state.description;
    const empty = ["<div style=\"user-select: auto;\"><br></div>", "<div style=\"user-select: auto;\"></div>","<div><br></div>","<div></div>"]
    if(empty.includes(this.state.effect))
      lecture.effect =  "";
    else
      lecture.effect =  this.state.effect;
    lecture.playtime =  this.state.playtime;
    lecture.attribute =  this.state.attribute;
    lecture.passingMark = this.state.passingMark;
    if(!lecture.passingMark)
      lecture.passingMark = 0;

    if(!lecture.lecturer)
      lecture.lecturer = {};
    
    lecture.advertiserCode = this.state.advertiserCode;
    lecture.advertiserProductCode = this.state.advertiserProductCode;
    lecture.hidden = this.state.hidden;
    lecture.type = this.state.type;

    this.props.progressPopup.setLabel("이미지 업로드 중...");

    if(this.state.thumbnailBase64){
      let data:any = {path:"lecture"}
      data.base64 = this.state.thumbnailBase64;
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_tn"}, data, getGlobal(GlobalKey.TOKEN));
        if(result && !result.error){
            lecture.thumbnailUrl = result.file;
            log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        }else{
            log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
            this.props.progressPopup.hide();
            this.doneProcessing = false;
            this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
            return;
        }

      } catch (error) {
          log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
          this.props.progressPopup.hide();
          this.doneProcessing = false;
          this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
          return;
      }
    }else if(this.state.thumbnailUrl)
      lecture.thumbnailUrl = this.state.thumbnailUrl;

    if(this.state.lecturers){
      lecture.lecturers = [...this.state.lecturers];
      for(let i=0; i<lecture.lecturers.length; i++){
        if(lecture.lecturers[i].imageBase64){
          let data:any = {path:"lecture"}
          data.base64 = lecture.lecturers[i].imageBase64;
          try {
            let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_le" + i.toString()}, data, getGlobal(GlobalKey.TOKEN));
            if(result && !result.error){
                lecture.lecturers[i].imageBase64 = null;
                lecture.lecturers[i].imageUrl = result.file;
                log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
                // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
            }else{
                log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
                this.props.progressPopup.hide();
                this.doneProcessing = false;
                this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
                return;
            }
          } catch (error) {
              log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
              this.props.progressPopup.hide();
              this.doneProcessing = false;
              this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
              return;
          }
        }
      }
    }

    if(this.state.imageBase64){
      let data:any = {path:"lecture"}
      data.base64 = this.state.imageBase64;
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_im"}, data, getGlobal(GlobalKey.TOKEN));
        if(result && !result.error){
            lecture.imageUrl = result.file;
            log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        }else{
            log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
            this.props.progressPopup.hide();
            this.doneProcessing = false;
            this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
            return;
        }

      } catch (error) {
          log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
          this.props.progressPopup.hide();
          this.doneProcessing = false;
          this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
          return;
      }
    }else if(this.state.imageUrl)
      lecture.imageUrl = this.state.imageUrl;

    if(this.state.inBanners){
      let inBanners = [];
      for(let i=0; i<this.state.inBanners.length; i++){
        if(!this.state.inBanners[i].base64){
          inBanners.push(this.state.inBanners[i]);
          continue;
        }
        let data:any = {path:"lecture"}
        data.base64 = this.state.inBanners[i].base64;
        let card:Attachment={
          name:this.state.inBanners[i].name,
          description:this.state.inBanners[i].description,
          link:this.state.inBanners[i].link,
        }
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_ca"}, data, getGlobal(GlobalKey.TOKEN));
          if(result && !result.error){
              let url = result.file;
              card.url = url;
              inBanners.push(card);
              log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
              // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          }else{
              log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
          }

        } catch (error) {
            log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
            // this.props.navigation.push("AuthRegister");
        }
      }
      lecture.inBanners = inBanners;
    }


    if(this.state.cards){
      let cards = [];
      for(let i=0; i<this.state.cards.length; i++){
        if(!this.state.cards[i].base64){
          cards.push(this.state.cards[i]);
          continue;
        }
        let data:any = {path:"lecture"}
        data.base64 = this.state.cards[i].base64;
        let card:Attachment={
          name:this.state.cards[i].name,
          description:this.state.cards[i].description,
          link:this.state.cards[i].link,
        }
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_ca"}, data, getGlobal(GlobalKey.TOKEN));
          if(result && !result.error){
              let url = result.file;
              card.url = url;
              cards.push(card);
              log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
              // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          }else{
              log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
          }

        } catch (error) {
            log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
            // this.props.navigation.push("AuthRegister");
        }
      }
      lecture.cards = cards;
    }
    

    if(this.state.previewBase64){
      let data:any = {path:"lecture"}
      data.base64 = this.state.previewBase64;
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_pv"}, data, getGlobal(GlobalKey.TOKEN));
        if(result && !result.error){
            lecture.previewUrl = result.file;
            log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        }else{
            log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
            this.props.progressPopup.hide();
            this.doneProcessing = false;
            this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
            return;
        }

      } catch (error) {
          log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
          this.props.progressPopup.hide();
          this.doneProcessing = false;
          this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
          return;
      }
    }else if(this.state.previewUrl)
      lecture.previewUrl = this.state.previewUrl;

    if(this.state.bannerBase64){
      let data:any = {path:"lecture"}
      data.base64 = this.state.bannerBase64;
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_bn"}, data, getGlobal(GlobalKey.TOKEN));
        if(result && !result.error){
            lecture.bannerUrl = result.file;
            log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        }else{
            log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
            this.props.progressPopup.hide();
            this.doneProcessing = false;
            this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
            return;
        }

      } catch (error) {
          log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
          this.props.progressPopup.hide();
          this.doneProcessing = false;
          this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
          return;
      }
    }else if(this.state.bannerUrl)
      lecture.bannerUrl = this.state.bannerUrl;

    if(this.state.shareBase64){
      let data:any = {path:"lecture"}
      data.base64 = this.state.shareBase64;
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, '', {name:lecture.id.toString() + "_ks"}, data, getGlobal(GlobalKey.TOKEN));
        if(result && !result.error){
            lecture.shareUrl = result.file;
            log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        }else{
            log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
            this.props.progressPopup.hide();
            this.doneProcessing = false;
            this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
            return;
        }

      } catch (error) {
          log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
          this.props.progressPopup.hide();
          this.doneProcessing = false;
          this.props.toastPopup.show("이미지 업로드가 실패했습니다.");
          return;
      }
    }else if(this.state.shareUrl)
      lecture.shareUrl = this.state.shareUrl;

    lecture.videoUrl = this.state.videoUrl;
    lecture.videoUrlHighResolution = this.state.videoUrlHighResolution;
    
    if(this.state.editedAttachment){
      this.props.progressPopup.setLabel("파일 업로드 중...");
      if(this.state.referencesLocal && this.state.referencesLocal.length > 0){
        for(let i=0; i<this.state.referencesLocal.length; i++){
          const data = new FormData();
          log(LogLevel.UI_ACTION, this.state.referencesLocal[i]);
          data.append('file', this.state.referencesLocal[i]);
          try {
            let result = await fetchAPI(API.UPLOAD_FILE, 'lecture', {name:lecture.id.toString() + "_ref_" + i.toString()}, data, getGlobal(GlobalKey.TOKEN));
            if(result && result.message && !result.error){
                let url = result.file;
                log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
                this.state.references.push({url: url, name:this.state.referencesLocal[i].name, size:this.state.referencesLocal[i].size, description:this.state.referencesLocalDescription[i]});
            }else{
                log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
                this.props.progressPopup.hide();
                this.doneProcessing = false;
                this.props.toastPopup.show("파일 업로드가 실패했습니다.");
            }
  
          } catch (error) {
              log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
              // this.props.navigation.push("AuthRegister");
              this.props.progressPopup.hide();
              this.doneProcessing = false;
              this.props.toastPopup.show("파일 업로드가 실패했습니다.");
          }
        }
      }
      lecture.references = this.state.references;
      this.setState({referencesLocal:[]});
  
      if(this.state.materialsLocal && this.state.materialsLocal.length > 0){
        for(let i=0; i<this.state.materialsLocal.length; i++){
          const data = new FormData();
          log(LogLevel.UI_ACTION, this.state.materialsLocal[i]);
          data.append('file', this.state.materialsLocal[i]);
          try {
            let result = await fetchAPI(API.UPLOAD_FILE, 'lecture', {name:lecture.id.toString() + "_mat_" + i.toString()}, data, getGlobal(GlobalKey.TOKEN));
            if(result && result.message && !result.error){
                let url = result.file;
                log(LogLevel.UI_ACTION, 'SeminarEdit:onDone upload : ', result.file);
                this.state.materials.push({url: url, name:this.state.materialsLocal[i].name, size:this.state.materialsLocal[i].size, description:this.state.materialsLocalDescription[i]});
            }else{
                log(LogLevel.UI_ACTION, "SeminarEdit:onDone failed", result);
                this.props.progressPopup.hide();
                this.doneProcessing = false;
                this.props.toastPopup.show("파일 업로드가 실패했습니다.");
            }
  
          } catch (error) {
              log(LogLevel.UI_ACTION, "SeminarEdit:onDone : failed", error);
              this.props.progressPopup.hide();
              this.doneProcessing = false;
              this.props.toastPopup.show("파일 업로드가 실패했습니다.");
              // this.props.navigation.push("AuthRegister");
          }
        }
      }
      lecture.materials = this.state.materials;
      this.setState({materialsLocal:[]});
    }

    this.props.progressPopup.setLabel("강좌 정보 업데이트중...");
    if(this.state.editedIngredient)
      lecture.ingredients = this.state.ingredients;

    if(this.state.editedCategory)
      lecture.categories = this.state.categories;

    let result = await fetchAPI(AdminAPI.LECTURE_PUT, "", null, lecture, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error){
      this.props.onDone(lecture);
    }
    else{
      this.props.progressPopup.hide();
      this.doneProcessing = false;
      this.props.toastPopup.show("강좌 정보 업데이트가 실패했습니다.");
      return;
    }
    this.props.progressPopup.hide();
    this.doneProcessing = false;

    this.fetchData();
  }

  onPreview = () => {
    if(this.props.onPreview){
      this.props.onPreview({...this.state});
    }
  }

  onImageSelected = async (name, e, max=1920, profile = false) => {
    // console.dir(e);
    log(LogLevel.UI_ACTION, "SeminarEdit:onImageSelected",e.target.files)
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let base64 = await loadImageBase64(e.target.files[0], max, profile);
      if(base64){
        let updates = {edited:true};
        updates[name] = base64;
        this.setState(updates);
      }
    }
    this.props.progressPopup.hide();
  }

  onImageSelectedInBanner = async (index, e) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onImageSelected",e.target.files)
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let base64 = await loadImageBase64(e.target.files[0]);
      if(base64){
        let updates = {edited:true, inBanners:[...this.state.inBanners]};
        if(index < 0){
          let newBanner: Attachment = {
            base64
          }
          updates.inBanners.push(newBanner);
        }
        else{
          updates.inBanners[index].base64 = base64;
        } 
        this.setState(updates);
      }
    }
    this.props.progressPopup.hide();
  }

  onImageSelectedInLecturer = async (index, e) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onImageSelected",e.target.files)
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let base64 = await loadImageBase64(e.target.files[0]);
      if(base64){
        let updates = {edited:true, lecturers:[...this.state.lecturers]};

        updates.lecturers[index].imageBase64 = base64;
        this.setState(updates);
      }
    }
    this.props.progressPopup.hide();
  }

  onFileSelected = async (name, e) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onImageSelected",e.target.files)
    if (e.target.files && e.target.files.length > 0) {
      this.props.progressPopup.show();
      this.props.progressPopup.setLabel("파일 로딩 중...");
      let filesLocal = [];
      let filesLocalDescription = [];
      if(this.state[name]){
        filesLocal = [...this.state[name]];
      }
      if(this.state[name+"Description"]){
        filesLocalDescription = [...this.state[name+"Description"]];
      }
      let targets = [...e.target.files];
      e.target.value = null;
      for(let i=0; i<targets.length; i++){
        filesLocal.push(targets[i]);
        filesLocalDescription.push("");
      }
      let updates = {editedAttachment:true, edited:true};
      updates[name] = filesLocal;
      updates[name+"Description"] = filesLocalDescription;

      this.setState(updates);

      this.props.progressPopup.hide();
    }
  }

  onRemoveFile = (name, i) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onRemoveFile",i)
    let files = [...this.state[name]];
    files.splice(i, 1);

    let updates = {editedAttachment:true, edited:true};
    updates[name] = files;

    if(this.state[name+"Description"]){
      let filesDescriptions = [...this.state[name+"Description"]];
      filesDescriptions.splice(i, 1);
      updates[name+"Description"] = filesDescriptions;
    }

    this.setState(updates);
  }

  onChangeAttachmentDescription = (e, name, i) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onChangeAttachmentDescription",name, this.state[name])
    let files = [...this.state[name]];
    files[i].description = e.detail.value;
    let updates = {editedAttachment:true, edited:true};
    updates[name] = files;
    this.setState(updates);
  }

  onChangeInBanner = (value, column, i) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onChangeInBannerLink")
    let inBanners = [...this.state.inBanners];
    inBanners[i][column] = value;
    let updates = {edited:true, inBanners};
    this.setState(updates);
  }



  onChangeAttachmentLocalDescription = (e, name, i) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onChangeAttachmentLocalDescription",name, this.state[name])
    let files = [...this.state[name]];
    files[i] = e.detail.value;
    let updates = {editedAttachment:true, edited:true};
    updates[name] = files;
    this.setState(updates);
  }

  onVideoReady = (time) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onVideoReady",time)
    this.setState({playtime:time});
  }

  onDelete = async (revive) => {
    if(revive){
      try {
        fetchAPI(AdminAPI.LECTURE_DELETE, '', {id:this.id, rollback:true}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
          if(result && !result.error)
            this.fetchData();
          this.props.onDone(null);
        })
      } catch (e) {
        log(LogLevel.REDUX_BOARD, "SeminarEdit:onDelete revival failed", e);
      }  
    }else{
      this.props.confirmPopup.show({
        title:(
          <div className="common-container">
            <span className="common-bold"> 강좌를 삭제</span> 시키시겠습니까?
          </div>
        ),
        onDone: () => {
          try {
            fetchAPI(AdminAPI.LECTURE_DELETE, '', {id:this.id}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
              if(result && !result.error)
                this.fetchData();
            this.props.onDone(null);
          })
          } catch (e) {
            log(LogLevel.REDUX_BOARD, "SeminarEdit:onDelete revival failed", e);
          }  
        },
        doneText: "네",
      });
      
    }
  }

  onBannerItemsDone = (inBanners: Attachment[]) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onBannerItemsDone", inBanners);
    this.setState({inBanners});
  }

  onCardItemsDone = (cards: Attachment[]) => {
    log(LogLevel.UI_ACTION, "SeminarEdit:onSalesItemsDone", cards);
    this.setState({cards});
  }

  addIngredient = (ingredient:Ingredient) => {
    let ingredients = this.state.ingredients;
    if(!ingredients)
      ingredients = []
    this.setState({ingredients:[...ingredients, ingredient], editedIngredient:true, edited:true})
  }

  deleteIngredient = (index:number) => {
    let ingredients = this.state.ingredients;
    if(!ingredients)
      ingredients = []
    if(index >= ingredients.length)
      return;
    ingredients.splice(index, 1);
    this.setState({ingredients, editedIngredient:true, edited:true})
  }

  addCategory = (category:any) => {
    let categories = this.state.categories;
    if(!categories)
      categories = []
    this.setState({categories:[...categories, category], editedCategory:true, edited:true})
  }
  
  deleteCategory = (index:number) => {
    let categories = this.state.categories;
    if(!categories)
      categories = []
    if(index >= categories.length)
      return;
    categories.splice(index, 1);
    this.setState({categories, editedIngredient:true, edited:true})
  }

  onAddLecturer = () => {
    let lecturers = [...this.state.lecturers];
    lecturers.push({
      name: "",
      imageUrl: "",
      imageBase64: "",
      career: [],
    });
    this.setState({lecturers, edited:true});
  }

  onDeleteLecturer = (lecturerIndex) => {
    let lecturers = [...this.state.lecturers];
    lecturers.splice(lecturerIndex, 1);

    this.setState({lecturers, edited:true});
  }

  onChangeLecturer = (e, lecturerIndex, colume) => {
    let lecturers = [...this.state.lecturers];
    lecturers[lecturerIndex][colume] = e.detail.value;
    this.setState({lecturers, edited:true});
  }

  onChangeLecturerCareer = (e, lecturerIndex, index) => {
    let lecturers = [...this.state.lecturers];
    lecturers[lecturerIndex].career[index] = e.detail.value;
    this.setState({lecturers, edited:true});
  }

  onAddLecturerCareer = (lecturerIndex) => {
    let lecturers = [...this.state.lecturers];
    lecturers[lecturerIndex].career.push("");
    this.setState({lecturers, edited:true});
  }

  onDeleteLecturerCareer = (lecturerIndex, index) => {
    let lecturers = [...this.state.lecturers];
    lecturers[lecturerIndex].career.splice(index, 1);
    this.setState({lecturers, edited:true});
  }

  renderLectureInfo () {
    if(this.state.foldLectureInfo){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({foldLectureInfo:false})}>
            <div className="common-flex-row">
              <div>강의정보</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    let deletedStatus;
    if(this.state.id){
      if(this.state.deletedAt){
        deletedStatus = (
          <span className="common-color-caution">(삭제됨)</span>
        );
      }
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({foldLectureInfo:true})}>
          <div className="common-flex-row">
            <div>강의정보</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="admin-title">ID : {this.state.id}{deletedStatus}</div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">제목 :</div>
            <IonInput class="user-search-input" placeholder="제목" value={this.state.title} onIonChange={(e) => this.setState({title:e.detail.value, edited:true})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">종류 :</div>
            <SegmentedControl type="ContainedS" value={this.state.type} values={[{name:"세미나", value:SeminarLectureType.SEMINAR}, {name:"카드뉴스", value:SeminarLectureType.CARDNEWS}]} onClick={(value) => {this.setState({type:value})}}/>
          </div>
          
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">부제 :</div>
            <IonInput class="user-search-input" placeholder="부제" value={this.state.subtitle} onIonChange={(e) => this.setState({subtitle:e.detail.value, edited:true})}/>
          </div>
          <div className="common-container-row-wrap admin-margin-bottom">
            <div className="admin-title">속성 :</div>
            {Object.values(SeminarLectureAttribute).filter(v => (typeof v === 'number' && v > 0)).map((item:any, index) => (
              <div className="common-flex-row" key={index.toString()}>
                <IonCheckbox checked={(this.state.attribute & item)!=0} onClick={(e) => {this.setState({edited:true, attribute:(this.state.attribute ^ item)});}}/>
                <div className="admin-margin-right">{SeminarLectureAttribute[item]}</div>
              </div>
            ))}
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">광고주코드 :</div>
            <IonInput class="user-search-input" placeholder="부제" value={this.state.advertiserCode} onIonChange={(e) => this.setState({advertiserCode:e.detail.value, edited:true})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">광고제품코드 :</div>
            <IonInput class="user-search-input" placeholder="부제" value={this.state.advertiserProductCode} onIonChange={(e) => this.setState({advertiserProductCode:e.detail.value, edited:true})}/>
          </div>
      </div>
    );
  }

  renderLecturer () {
    if(this.state.foldLecturer){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({foldLecturer:false})}>
            <div className="common-flex-row">
              <div>강연자 정보</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({foldLecturer:true})}>
          <div className="common-flex-row">
            <div>강연자 정보</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <IonButton onClick={() => this.onAddLecturer()}><IonIcon name="add"/></IonButton>
        {this.state.lecturers.map((lecturer, lecturerIndex) => {
          return (
            <div key={lecturerIndex.toString()} className="common-container-row">
              <div className="common-flex-grow">
                <div className="common-container-row common-flex-align-center">
                  <div className="admin-title">이름 :</div>
                  <IonInput class="user-search-input" placeholder="부제" value={lecturer.name} onIonChange={(e) => this.onChangeLecturer(e, lecturerIndex, "name")}/>
                </div>
                <div className="common-container-row common-flex-align-center">
                  <div className="admin-title">이미지 :</div>
                  <ImageViewNSelect base64={lecturer.imageBase64} url={lecturer.imageUrl} onChange={(e) => this.onImageSelectedInLecturer(lecturerIndex, e)} filePath={this.props.filePath}/>
                </div>
                <div className="admin-title"></div>
                <div className="common-container-row common-flex-align-center">
                  <div className="admin-title">이력</div>
                  <div className="admin-title" onClick={() => {this.onAddLecturerCareer(lecturerIndex)}}>&nbsp;&nbsp;+&nbsp;&nbsp;</div>
                </div>
                {(this.state.lecturers[lecturerIndex].career && this.state.lecturers[lecturerIndex].career.length>0) && this.state.lecturers[lecturerIndex].career.map((item, index) => {
                  return (
                    <div key={index.toString()} className="common-container-row common-flex-align-center">
                      <IonInput class="user-search-input" placeholder="부제" value={this.state.lecturers[lecturerIndex].career[index]} onIonChange={(e) => this.onChangeLecturerCareer(e, lecturerIndex, index)}/>
                      <div className="admin-title" onClick={() => {this.onDeleteLecturerCareer(lecturerIndex, index)}}>&nbsp;&nbsp;-&nbsp;&nbsp;</div>
                    </div>
                  );
                })}
              </div>
              <IonButton fill="clear" color="primary" onClick={() => this.onDeleteLecturer(lecturerIndex)}>
                <IonIcon name="close"/>
              </IonButton>
            </div>
          );
        })}


      </div>
    );
  }

  renderLectureDetail () {
    if(this.state.foldLectureDetail){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({foldLectureDetail:false})}>
            <div className="common-flex-row">
              <div>강의 상세 설명</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({foldLectureDetail:true})}>
          <div className="common-flex-row">
            <div>강의 상세 설명</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="admin-title">강의 설명</div>
        <div className="common-container admin-editor common-scroll admin-margin-bottom">
          <HtmlComposer mini={true} body={this.state.description} onBodyChanged={(body) =>{this.setState({description:body, edited:true})}} />
        </div>
        <div className="admin-title">강의 hidden 설명</div>
        <div className="common-container admin-editor common-scroll admin-margin-bottom">
          <Textarea className="admin-text-area" minRows={3} maxRows={15} value={this.state.hidden?this.state.hidden:""} onChange={(e) => this.setState({hidden:e.target.value})} onClick={(e)=>{e.stopPropagation();}}/>  
        </div>
        <div className="admin-title">효능/효과</div>
        <div className="common-container admin-editor common-scroll admin-margin-bottom">
          <ReactQuill
            theme={'snow'}
            onChange={(value, delta, source, editor) => {this.setState({effect:value, edited:true})}}
            value={this.state.effect}
            modules={SeminarEdit.modules}
            formats={SeminarEdit.formats}
            placeholder="test"
          />
        </div>
      </div>
    );
  }

  renderLectureImage () {
    if(this.state.foldImages){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({foldImages:false})}>
            <div className="common-flex-row">
              <div>강의 이미지</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({foldImages:true})}>
          <div className="common-flex-row">
            <div>강의 이미지</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="admin-title">썸네일</div>
        <ImageViewNSelect base64={this.state.thumbnailBase64} url={this.state.thumbnailUrl} onChange={(e) => this.onImageSelected("thumbnailBase64", e)} filePath={this.props.filePath}/>
        <div className="admin-margin-bottom"/>
        <div className="admin-title">대표이미지 (1280x720)</div>
        <ImageViewNSelect base64={this.state.imageBase64} url={this.state.imageUrl} onChange={(e) => this.onImageSelected("imageBase64", e)} filePath={this.props.filePath}/>
        <div className="admin-margin-bottom"/>
        <div className="admin-title">Preview (1280x720)</div>
        <ImageViewNSelect base64={this.state.previewBase64} url={this.state.previewUrl} onChange={(e) => this.onImageSelected("previewBase64", e)} filePath={this.props.filePath}/>
        <div className="admin-margin-bottom"/>
        <div className="admin-title">배너이미지 (2400x600)</div>
        <ImageViewNSelect base64={this.state.bannerBase64} url={this.state.bannerUrl} onChange={(e) => this.onImageSelected("bannerBase64", e)} filePath={this.props.filePath}/>
        <div className="admin-margin-bottom"/>
        <div className="admin-title">카카오공유이미지 (400x400)</div>
        <ImageViewNSelect base64={this.state.shareBase64} url={this.state.shareUrl} onChange={(e) => this.onImageSelected("shareBase64", e)} filePath={this.props.filePath}/>
      </div>
    );
  }

  renderLectureAttachment () {
    if(this.state.foldAttachment){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({foldAttachment:false})}>
            <div className="common-flex-row">
              <div>첨부파일</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    let referencesList;
    if(this.state.references){
      referencesList = (
        <div className="common-container">
          {this.state.references.map((file, i)=>
            <div key={i.toString()} className="common-container">
              <AttachmentViewer name={file.name} size={file.size} onDelete={() => this.onRemoveFile("references", i)}/>
              <IonInput class="user-search-input" placeholder="부제" value={file.description} onIonChange={(e) => this.onChangeAttachmentDescription(e, "references", i)}/>
            </div>
          )}
        </div>
      );
    }
    let referencesLocalList;
    if(this.state.referencesLocal){
      referencesLocalList = (
        <div className="common-container">
          {this.state.referencesLocal.map((file, i)=>
            <div key={i.toString()} className="common-container">
              <AttachmentViewer name={file.name} size={file.size} onDelete={() => this.onRemoveFile("referencesLocal", i)}/>
              <IonInput class="user-search-input" placeholder="부제" value={this.state.referencesLocalDescription[i]} onIonChange={(e) => this.onChangeAttachmentLocalDescription(e, "referencesLocalDescription", i)}/>
            </div>
          )}
        </div>
      );
    }

    let materialsList;
    if(this.state.materials){
      materialsList = (
        <div className="common-container">
          {this.state.materials.map((file, i)=>
            <div key={i.toString()} className="common-container">
              <AttachmentViewer name={file.name} size={file.size} onDelete={() => this.onRemoveFile("materials", i)}/>
              <IonInput class="user-search-input" placeholder="부제" value={file.description} onIonChange={(e) => this.onChangeAttachmentDescription(e, "materials", i)}/>
            </div>
          )}
        </div>
      );
    }

    let materialsLocalList;
    if(this.state.materialsLocal){
      materialsLocalList = (
        <div className="common-container">
          {this.state.materialsLocal.map((file, i)=>
            <div key={i.toString()} className="common-container">
              <AttachmentViewer name={file.name} size={file.size} onDelete={() => this.onRemoveFile("materialsLocal", i)}/>
              <IonInput class="user-search-input" placeholder="부제" value={this.state.materialsLocalDescription[i]} onIonChange={(e) => this.onChangeAttachmentLocalDescription(e, "materialsLocalDescription", i)}/>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({foldAttachment:true})}>
          <div className="common-flex-row">
            <div>첨부파일</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="admin-title">관련 논문 (PDF)</div>
          <div className="admin-image-show-n-change-container">
            <div className="admin-title">&nbsp;&nbsp;+&nbsp;&nbsp;</div>
            <input className="admin-image-show-n-change-file" multiple={true} type="file" accept="application/pdf" onChange={(e) => this.onFileSelected("referencesLocal", e)}/>
          </div>
        </div>
        {referencesList}
        {referencesLocalList}
        <div className="admin-margin-bottom"/>
        <div className="common-container-row common-flex-align-center">
          <div className="admin-title">세미나 자료 (PDF)</div>
          <div className="admin-image-show-n-change-container">
            <div className="admin-title">&nbsp;&nbsp;+&nbsp;&nbsp;</div>
            <input className="admin-image-show-n-change-file" multiple={true} type="file" accept="application/pdf" onChange={(e) => this.onFileSelected("materialsLocal", e)}/>
          </div>
        </div>
        {materialsList}
        {materialsLocalList}
      </div>
    );
  }

  renderLectureIngredient () {
    if(this.state.foldIngredient){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({foldIngredient:false})}>
            <div className="common-flex-row">
              <div>성분 정보</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    let ingredientList;
    if(this.state.ingredients){
      ingredientList = (
        <div className="common-container">
          {this.state.ingredients.map((item, i)=>
            <div key={i.toString()} className="admin-keyword-item-container">
              <div className="admin-keyword-item-type"> {keywordTypeName[item.type]}</div>
              <div className="admin-keyword-item-info"> 
                <div className="admin-keyword-item-name">{item.textKor} {item.volume?" : "+item.volume:""}</div> 
              </div>
              <IonButton fill="clear" color="primary" onClick={() => this.deleteIngredient(i)}>
                <IonIcon name="close"/>
              </IonButton>
            </div>          
          )}
        </div>
      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({foldIngredient:true})}>
          <div className="common-flex-row">
            <div>성분 정보</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="admin-title">성분 </div>
          <IonButton onClick={() => this.props.onSelectIngredient()}><IonIcon name="add"/></IonButton>
        </div>
        {ingredientList}
      </div>
    );
  }

  renderLectureCategory () {

    let categoryList;
    if(this.state.categories){
      categoryList = (
        <div className="common-container">
          {this.state.categories.map((item, i)=>
            <div key={i.toString()} className="admin-keyword-item-container">
              <div className="admin-keyword-item-info"> 
                <div className="admin-keyword-item-name">{item.title} </div> 
              </div>
              <IonButton fill="clear" color="primary" onClick={() => this.deleteCategory(i)}>
                <IonIcon name="close"/>
              </IonButton>
            </div>          
          )}
        </div>
      );
    }

    return (
      <div className="common-container">     
        <div className="common-container-row common-flex-align-center">
          <div className="admin-title">카테고리 </div>
          <IonButton onClick={() => this.props.onSelectCategory()}><IonIcon name="add"/></IonButton>
        </div>
        {categoryList}
      </div>
    );
  }

  renderLectureVideo () {
    if(this.state.foldVideo){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({foldVideo:false})}>
            <div className="common-flex-row">
              <div>강의 비디오 정보</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    let videoPlayer;
    if(this.state.videoPreviewUrl){
      let srcPreview = this.state.previewBase64;
      if(!srcPreview)
        srcPreview = this.state.previewUrl;
      videoPlayer = (
        <div className="common-container common-position-relative">
          <VideoPlayer src={this.state.videoUrl} onDuration={this.onVideoReady} srcHighResolution={this.state.videoUrlHighResolution} srcPreview={srcPreview} highResolution={(this.state.videoUrlHighResolution == this.state.videoPreviewUrl)} />
          <IonIcon class="seminar-edit-close" name="close" onClick={() => {this.setState({videoPreviewUrl:""})}}/>
        </div>
      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({foldVideo:true})}>
          <div className="common-flex-row">
            <div>강의 비디오 정보</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row common-flex-align-center">
            <div className="admin-title">video url(저화질) :</div>
            <IonInput class="user-search-input" placeholder="video url(저화질)" value={this.state.videoUrl} onIonChange={(e) => this.setState({videoUrl:e.detail.value, edited:true})}/>
            <IonButton onClick={() => this.setState({videoPreviewUrl:this.state.videoUrl})}><IonIcon name="play"/></IonButton>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">video url(고화질) :</div>
            <IonInput class="user-search-input" placeholder="video url(고화질)" value={this.state.videoUrlHighResolution} onIonChange={(e) => this.setState({videoUrlHighResolution:e.detail.value, edited:true})}/>
            <IonButton onClick={() => this.setState({videoPreviewUrl:this.state.videoUrlHighResolution})}><IonIcon name="play"/></IonButton>
          </div>
          <div className="admin-title">Playtime : {getTimerString(this.state.playtime)}</div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">패스기준(%) :</div>
            <IonInput class="user-search-input" placeholder="패스기준" type="number" value={this.state.passingMark.toString()} onIonChange={(e) => this.setState({passingMark:parseInt(e.detail.value), edited:true})}/>
          </div>
          {videoPlayer}
      </div>
    );
  }

  render() {
    if(!this.props.lecture)
      return null;

    log(LogLevel.UI_LIFECYCLE, "SeminarEdit:render", this.state);

    let deleteButton;
    if(this.state.id){
      if(this.state.deletedAt){
        deleteButton = (
          <IonButton onClick={() => this.onDelete(true)}>
            <div className="html-composer-button">삭제취소</div>
          </IonButton>
        );
      }else{
        deleteButton = (
          <IonButton onClick={() => this.onDelete(false)}>
            <div className="html-composer-button">삭제</div>
          </IonButton>
        );
      }
    }

    let rewardButton;
    if(this.state.id){
      rewardButton = (
        <IonButton onClick={() => this.props.onReward()}>
          <div className="html-composer-button">보상관리</div>
        </IonButton>
      );
    }

    let statisticsButton;
    if(this.state.id){
      statisticsButton = (
        <IonButton onClick={() => this.props.onStatistics()}>
          <div className="html-composer-button">통계보기</div>
        </IonButton>
      );
    }

    return (
      <div className="admin-container">
        <IonContent class="common-content common-scroll">
          <IonToolbar color="html-composer-toolbar">
            <IonButtons slot="start">
              {rewardButton}
              {statisticsButton}
            </IonButtons>
            <IonButtons slot="end">
              {deleteButton}
              <IonButton onClick={this.onPreview}>
                <div className="html-composer-button">미리보기</div>
              </IonButton>
              <IonButton disabled={!this.state.edited} onClick={this.onDone}>
                <div className="html-composer-button">완료</div>
              </IonButton>
            </IonButtons>
          </IonToolbar>
          {this.renderLectureInfo()}
          {this.renderLectureCategory()}
          <div className="admin-margin-bottom"/>
          {this.renderLecturer()}
          <div className="admin-margin-bottom"/>
          {this.renderLectureDetail()}
          <div className="admin-margin-bottom"/>
          {this.renderLectureImage()}
          <div className="admin-margin-bottom"/>
          {this.renderLectureAttachment()}
          <div className="admin-margin-bottom"/>
          {this.renderLectureIngredient()}
          <div className="admin-margin-bottom"/>
          {this.renderLectureVideo()}
          <div className="admin-margin-bottom"/>
          <IonButton onClick={() => {this.ivCardsComposerPopup.show()}}>카드 항목 {(this.state.cards && this.state.cards.length)?"변경":"등록"}</IonButton>
          <IonButton onClick={() => {this.ivBannersComposerPopup.show()}}>배너 항목 {(this.state.cards && this.state.cards.length)?"변경":"등록"}</IonButton>
          <div className="admin-margin-bottom"/>
        </IonContent>
        <ComposeCardnewsPopup ref={ref => {this.ivCardsComposerPopup = ref}} progressPopup={this.props.progressPopup} cardnews={this.state.cards} onDone={this.onCardItemsDone} />
        <ComposeCardnewsPopup ref={ref => {this.ivBannersComposerPopup = ref}} progressPopup={this.props.progressPopup} cardnews={this.state.inBanners} onDone={this.onBannerItemsDone} editName={true} editDescription={true}/>

      </div>  
    );
  }
}

export default SeminarEdit;