import React, { Component } from 'react';
import './ComponentUserInfo.css';
import { func } from 'prop-types';
import {log, LogLevel} from '../utils/LogUtil'
import { RootState, actions } from '../store';
import { connect } from 'react-redux';
import { Reference } from '../models/Model.Board';
import {MY_ADDRESS} from './../config.json';
import { withRouter, RouteComponentProps } from "react-router";
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';
import { IonButton } from '@ionic/react';
import { timingSafeEqual } from 'crypto';
import { UserInfo } from '../models/Model.User';
import ProfileSimple from '../components/ProfileSimple';
import { action } from 'typesafe-actions';

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  component?: Reference;
  userid? : string;
  type?: string;
};

const windowAny : any = window;

class ComponentUserInfo extends Component<Props> {

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ComponentUserInfo: constructor ", props);
    let userId = parseInt(this.props.userid);
    if(userId && !this.props.users[userId]){
      this.props.loadUser(userId);
    }
  }

  onPress = (id:number) => {
    this.props.history.push("/user?id="+id);
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ComponentUserInfo: render ", this.props);
    let userInfo:UserInfo;
    let userId = 0;
    try{
      userId = parseInt(this.props.userid);
    }
    catch(e){
      log(LogLevel.UI_EXCEPTION, "ComponentUserInfo: render ", e);
    }

    if(!userId)
      userInfo = this.props.me;
    else if(this.props.users[userId])
      userInfo = this.props.users[userId];

    if(userInfo)
      return (
        <ProfileSimple 
          name= {userInfo.nickname}
          userId= {userInfo.id}
          profileUrl= {userInfo.profileUrl}
          onPress= {this.onPress}
        />
      );
    else 
      return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  users : state.user.users,
  me: state.user.me,
});

const mapDispatchToProps = {
  loadUser: (userId) => actions.user.getUser(userId),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentUserInfo));