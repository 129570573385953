

import React, { Component } from 'react';
import './../Admin.scss';
import './../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../models/Model.User';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../models/Model.Board';
import Truncate from 'react-truncate';
import { EventInfo } from '../../models/Model.Event';
import { getDateStringFromToday } from '../../utils/TimeUtil';

const AdminAPI = {        
  "EVENT_LIST" : {"method":"GET", "path":"/admin/event", "contentType":"application/json"}
};

type Props = {
  onSelect: (event:EventInfo) => void;
};

type State = {
  events: EventInfo[],
  search: string,
  category: number,
}

class EventSelect extends Component<Props, State> {
  state = {
    events: [],
    search: '',
    category: 0,
  };


  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({search: keywords});
    this.fetchData();
    
  }
  
  onCategoryChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let category: number = parseInt(e.detail.value);
    this.setState({category: category});
    this.fetchData();
    
  }

  onSelect = (event: EventInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", event);
    this.props.onSelect(event);
  }

  fetchData = async () => {
    let result = await fetchAPI(AdminAPI.EVENT_LIST, "", null, null, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error){
      this.setState({events: result.data?result.data:[]});
    }
    else{
      this.setState({events: []});
    }
  }
  
  clearSearch = () => {
    this.setState({search: ''});
  }

  render() {
    return (
      <div className="admin-container">
        <div className="admin-board-search-container">
          <IonInput class="admin-board-search-input" placeholder="검색" value={this.state.search} onIonChange={this.onSearchChange}/>
          <IonButton fill="clear" color="primary" onClick={this.clearSearch}>
            <IonIcon name="close"/>
          </IonButton>
          <IonSelect class="admin-board-search-level" value={this.state.category.toString()} onIonChange={this.onCategoryChange}>
            <IonSelectOption value={"0"}>활성화</IonSelectOption>
            <IonSelectOption value={"1"}>전체</IonSelectOption>
            <IonSelectOption value={"2"}>전체(삭제포함)</IonSelectOption>
          </IonSelect>
        </div>
        <div className="admin-board-list-container">
          <IonList>
            { this.state.events.map((item:EventInfo, index: number) => (
              <div key={index.toString()} className="admin-board-list-item-container" onClick={() => this.onSelect(item)}>
                <div className="admin-row"> 
                  <div className="admin-grow">{item.id} - {item.name} - {getDateStringFromToday({date:item.startDate})}~{getDateStringFromToday({date:item.endDate})} {item.deletedAt?"[삭제]":""}</div>
                </div>
              </div>
            ))}              
          </IonList>
        </div>
      </div>
    );
  }
}

export default EventSelect;