import React from "react";
import styled from "styled-components";
import Button, { ButtonSize } from "../../atoms/Button";
import { COLOR_SYSTEM } from "../../design/design-system";

interface KakaoShareButtonProps {
  size: ButtonSize;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const KakaoShareButtonContainer = styled.div`
  button {
    background-color: #fee500;
    color: #191919;
  }
`;

const KakaoShareButton: React.FC<KakaoShareButtonProps> = ({ size, onClick }) => {
  return (
    <KakaoShareButtonContainer onClick={onClick}>
      <Button
        color="Secondary"
        size={size}
        type="IconWithText"
        icon="Kakao"
        iconColor={COLOR_SYSTEM.get("Gray")[900]}
        left
        variant="Ghost"
      >
        공유하기
      </Button>
    </KakaoShareButtonContainer>
  );
};

export default KakaoShareButton;
