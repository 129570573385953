import React from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { typography } from "../../design/typography";

export interface DropdownProps {
  placeholder?: string;
  value?: string;
  label?: string;
  required?: boolean;
  error?: boolean;
  hint?: string;
  onClick?: (params?: any) => any;
}

interface DropdownStyledProps {
  error?: boolean;
}

const DropdownField = styled.div`
  display: block;
  min-width: 70px;
  height: 100%;
  appearance: none;
  outline: none;
  border: none;
  flex: 1;
  background-color: inherit;
  color: ${COLOR_SYSTEM.get("Gray")[800]};
  ${typography.get("InputLarge")};
  overflow: hidden;

  &::placeholder {
    color: ${COLOR_SYSTEM.get("Gray")[300]};
  }

  &:focus {
    background-color: ${COLOR_SYSTEM.get("Gray")[20]};
  }
`;

const DropdownWrapper = styled.div<DropdownStyledProps>`
  height: 48px;
  padding: 11.5px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ error }) =>
    COLOR_SYSTEM.get(error ? "Red" : "Gray")[10]};
  box-sizing: border-box;
  border-radius: 12px;
  transform: translateZ(0);
  border: ${({ error }) =>
    error
      ? `1px solid ${COLOR_SYSTEM.get("Red")[50]}`
      : "1px solid transparent"};

  &:focus-within {
    border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
    background-color: ${COLOR_SYSTEM.get("Gray")[20]};
  }
`;

const Label = styled.label`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;

  & > * + * {
    margin-left: 4px;
  }
`;

const Wrapper = styled.div`
  min-width: 120px;

  &:focus-within ${Label} {
    span:first-child {
      color: ${COLOR_SYSTEM.get("Skyblue")[500]};
    }
  }
`;

const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  value,
  label,
  required,
  error = false,
  hint,
  onClick,
}) => {
  return (
    <Wrapper>
      {label && (
        <Label>
          <Text
            textType="InputSmall"
            color={
              error
                ? COLOR_SYSTEM.get("Red")[300]
                : COLOR_SYSTEM.get("Gray")[600]
            }
          >
            {label}
          </Text>
          {required && (
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[300]}>
              *
            </Text>
          )}
        </Label>
      )}
      <DropdownWrapper error={error} onClick={onClick}>
        <DropdownField>{value}</DropdownField>
        <div
          onClick={(e) => {
            console.log(e);
            e.stopPropagation();
            onClick();
          }}
        >
          <Icon
            width={24}
            height={24}
            name={"PolygonDown"}
            fill={COLOR_SYSTEM.get("Gray")[400]}
          />
        </div>
      </DropdownWrapper>
      {hint && (
        <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[400]}>
          {hint}
        </Text>
      )}
    </Wrapper>
  );
};

export default Dropdown;
