import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { SHADOW } from "../../design/effects";
import { typography } from "../../design/typography";
import { ButtonType } from "../Button";
import { FloatingButtonSize, FloatingButtonUsage } from ".";

interface FloatingButtonWrapperProps {
  buttonType: ButtonType;
  size: FloatingButtonSize;
  color: FloatingButtonUsage;
  left?: boolean;
  right?: boolean;
  style?: React.CSSProperties;
}

const FloatingButtonWrapper = styled.button<FloatingButtonWrapperProps>`
  min-width: ${({ size }) => {
    switch (size) {
      case "XLarge":
        return "72px";
      case "Large":
        return "48px";
      case "Medium":
      default:
        return "40px";
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case "XLarge":
        return "72px";
      case "Large":
        return "48px";
      case "Medium":
      default:
        return "40px";
    }
  }};
  background-color: ${({ color }) => {
    switch (color) {
      case "Primary":
        return COLOR_SYSTEM.get("Skyblue")[400];
      case "Secondary":
        return COLOR_SYSTEM.get("Gray")[700];
      case "Tertiary":
      default:
        return COLOR_SYSTEM.get("Gray")[0];
    }
  }};

  // Font
  ${({ size }) => {
    switch (size) {
      case "XLarge":
        return typography.get("InputXLarge");
      case "Large":
        return typography.get("InputLarge");
      case "Medium":
      default:
        return typography.get("InputMedium");
    }
  }};
  color: ${({ color }) =>
    color === "Tertiary"
      ? COLOR_SYSTEM.get("Gray")[400]
      : COLOR_SYSTEM.get("Gray")[0]};

  // Border
  border: ${({ color }) =>
    color === "Tertiary"
      ? `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`
      : "none"};
  border-radius: ${({ size, buttonType }) => {
    if (buttonType === "Icon") {
      return "50%";
    } else {
      switch (size) {
        case "XLarge":
          return "60px";
        case "Large":
        case "Medium":
        default:
          return "48px";
      }
    }
  }};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: relative;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;

  ${SHADOW.get("3")};

  .ripple {
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    animation: 1s ripple linear;
    transform: scale(0);
  }

  @keyframes ripple {
    from {
      transform: scale(0);
    }

    to {
      opacity: 0;
      transform: scale(8);
    }
  }

  ${({ style }) => style};
`;

export const IconFloatingButtonWrapper = styled(FloatingButtonWrapper)`
  padding: ${({ size }) => {
    switch (size) {
      case "XLarge":
        return "22px";
      case "Large":
        return "10px";
      case "Medium":
      default:
        return "8px";
    }
  }};
  ${({ style }) => style};
`;

export const TextFloatingButtonWrapper = styled(FloatingButtonWrapper)`
  padding: ${({ size }) => {
    switch (size) {
      case "Large":
        return "11.5px 20px";
      case "Medium":
      default:
        return "8.5px 16px";
    }
  }};
  ${({ style }) => style};
`;

export const IconWithTextFloatingButtonWrapper = styled(FloatingButtonWrapper)`
  padding: ${({ size, left, right }) => {
    if (left) {
      switch (size) {
        case "Large":
          return "11.5px 20px 11.5px 10px";
        case "Medium":
        default:
          return "8.5px 16px 8.5px 8px";
      }
    } else if (right) {
      switch (size) {
        case "Large":
          return "11.5px 10px 11.5px 20px";
        case "Medium":
        default:
          return "8.5px 8px 8.5px 16px";
      }
    }
  }};

  .right-button__icon {
    margin-left: ${({ size }) => {
      switch (size) {
        case "Large":
          return "10px";
        case "Medium":
        default:
          return "8px";
      }
    }};
  }

  .left-button__icon {
    margin-right: ${({ size }) => {
      switch (size) {
        case "Large":
          return "10px";
        case "Medium":
        default:
          return "8px";
      }
    }};
  }
  ${({ style }) => style};
`;
