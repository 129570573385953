

import React, { Component } from 'react';
import '../../Admin.scss';
import '../../SeminarManage.css';
import '../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import ProfileImage from '../../../components/ProfileImage';
import { SeminarLecture } from '../../../models/Model.Seminar';
import { defaultNewLecture } from '../../SeminarManage';
import ToggleSwitch from '../../../component/atom/ToggleSwitch';
import Text from '../../../component/atom/Text';

const API = {        
  "LECTURE_LIST" : {"method":"GET", "path":"/admin/seminar/lectures", "contentType":"application/json"},
};

const ROW_CNT = 20;

type Props = {
  onDone: (lecture:SeminarLecture) => void;
  onPreview?: (lectureId:number) => void;
  selected?: SeminarLecture,
};

type State = {
  lectures: SeminarLecture[],
  full: boolean;
}

class SeminarSelect extends Component<Props, State> {
  finished = false;
  loading = false;
  lectureList = null;

  state = {
    lectures: [],
    full: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onScroll = (event) => {
    let target:any = event.target;
    // log(LogLevel.UI_EVENT, "BoardList:scroll", event.detail.scrollTop, target.clientHeight, this.contentList.clientHeight);
    if(!this.loading && (target.scrollTop + target.clientHeight) > (this.lectureList.clientHeight * 0.9)){
      log(LogLevel.UI_EVENT, "SeminarSelect:onScroll", target.scrollTop, target.clientHeight, this.lectureList.clientHeight);
      this.fetchDataMore();
    }
  }

  refresh = () =>{
    this.fetchData();
  }

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSelect:fetchData");
    this.loading = true;
    let options:any = {start:0, count: ROW_CNT};
    if(this.state.full)
      options.full = true;
    fetchAPI(API.LECTURE_LIST, "", options, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSelect:fetchData result", result);
        this.finished = false;
        if(result.data && result.data.length < ROW_CNT)
          this.finished = true;

        this.setState({lectures: result.data});
        this.loading = false;
      }
      else{
        log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSelect:fetchData result", result);
        this.finished = true;
        this.setState({lectures: []});
      }
    });
  }

  fetchDataMore = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSelect:fetchDataMore", this.finished);
    this.loading = true;
    let options:any = {start:this.state.lectures.length, count: ROW_CNT};
    if(this.state.full)
      options.full = true;
    if(!this.finished){
      fetchAPI(API.LECTURE_LIST, "", options, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSelect:fetchData result", result);
          this.finished = false;
          if(result.data && result.data.length < ROW_CNT)
            this.finished = true;

          this.setState({lectures: [...this.state.lectures, ...result.data]});
          this.loading = false;
        }
        else{
          log(LogLevel.UI_DATA_LOAD, "Admin:SeminarSelect:fetchData result", result);
          this.finished = true;
          this.loading = false;
        }
      });
    }

  }

  onSelect = (lecture: SeminarLecture) => {
    log(LogLevel.UI_EVENT, "onSelect", lecture);
    this.props.onDone(lecture);
  }

  onAdd = () => {
    this.props.onDone({...defaultNewLecture});
  }

  onChange = () => {
    this.fetchData();
  }

  fowardTo = (id, e) => {
    window.open('/lecture?id=' + id, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
    e.stopPropagation();
  }

  onFull = () => {
    this.setState({full:!this.state.full});
    setTimeout(() => this.fetchData(), 100);
  }

  render() {
    return (
      <div className="admin-container" onScroll={this.onScroll}>
        <div className="common-list">
          <div className="common-flex-row  common-flex-align-center">
            <ToggleSwitch checked={this.state.full} onClick={this.onFull}/> 
            <Text>삭제포함</Text>
          </div>
          <IonButton expand="full" onClick={this.onAdd}>
            <IonIcon name="add"/>
          </IonButton>
          <IonList ref={(ref) => {this.lectureList = ref}} >
            { this.state.lectures.map((item:SeminarLecture, index: number) => (
              <div key={index.toString()} className="common-container-row common-flex-align-center" onClick={() => this.onSelect(item)}>
                <div className="common-flex-grow">
                  <div className={"admin-title" + (item.deletedAt?" common-color-caution":"")}>{item.id} - {item.title}</div>
                </div>
                <IonButton color="primary" onClick={(e) => this.fowardTo(item.id, e)}><IonIcon mode="ios" name="arrow-forward"/></IonButton>
              </div>
            ))}              
          </IonList>
        </div>
      </div>  
    );
  }
}

export default SeminarSelect;