import React from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import { PopupFooter, RaiseErrorPopupMain } from "./style";

interface RaiseErrorPopupProps extends PopupProps {}

const RaiseErrorPopup: React.FC<RaiseErrorPopupProps> = ({ onClose }) => {
  return (
    <Popup onClose={onClose}>
      <RaiseErrorPopupMain>
        <Icon width={50} height={50} name={"Exclamation Mark Filled"} fill={COLOR_SYSTEM.get("Red")[300]} />
        <Text element="h4" textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          내일 다시 시도해주세요
        </Text>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
          끌어올리기는 하루 한번만 가능하며
          <br />
          내일 00:00에 다시 할 수 있어요
        </Text>
      </RaiseErrorPopupMain>
      <PopupFooter>
        <Button color="Secondary" size="Large" type="Text" variant="Tinted" onClick={onClose}>
          닫기
        </Button>
      </PopupFooter>
    </Popup>
  );
};

export default RaiseErrorPopup;
