import React, { Component } from 'react';
import './KeywordPopup.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';;
import Skeleton from 'react-loading-skeleton';
import smileIcon from './../assets/icon/smile.svg'
import { KeywordType, Medicine, Ingredient } from '../../models/Model.Medicine';
import {compose} from 'redux'
import { connect, connectAdvanced } from 'react-redux';
import { RootState, actions} from '../../store';
import {log, LogLevel} from '../../utils/LogUtil'
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import AnalyticsUtil from './../../utils/AnalyticsUtil';
import StringUtil from '../../utils/StringUtil';
import { UIServiceType } from '../../store/ui/types';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
};

type State = {
  visible: boolean;
  type: KeywordType;
  id: number;
}
const windowAny : any = window;


class KeywordPopup extends Component<Props, State> {
  state={
    visible: false,
    type: 0,
    id: 0,
  };


  showIngredient = (ingredient:Ingredient) => {

  }

  show = (type: KeywordType, id: number) => {
    log(LogLevel.NONE, "KeywordPopup:show")
    this.setState({type, id, visible:true});
    if(type == KeywordType.MEDICINE || type == KeywordType.INGRADIENT || type == KeywordType.ANIMAL_MEDICINE) {
      // if(!this.props.medicine.medicines[id]) {
        this.props.fetchKeyword(type, id);
      // }
    }else {
      return;
    }
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SHOW_KEYWORD_POPUP", '키워드팝업',{"medical_type":type, "medical_id": id});
    this.props.backKeyControl.pushListener(() => {
      this.hide();
      return true;
    });
  }

  hide = () => {
    log(LogLevel.NONE, "KeywordPopup:hide")
    this.setState({visible: false});
    this.props.backKeyControl.popListener();
  }

  onDone = () => {
    this.hide();
  }

  isShown = () => {
    return this.state.visible;
  }

  onClick = (url:string, data:any = null, isKorean:boolean = true, ingredientIndex:number = -1) => {
    if(!url)
      return;

    url = StringUtil.convertFilePath(url);
    let os = getGlobal(GlobalKey.OS);
    if(os == "android" && url.endsWith("pdf"))
      windowAny.open('https://docs.google.com/viewer?url='+ url + '&embedded=true', ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
    else
      windowAny.open(url, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));

    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SHOW_KEYWORD_DETAIL", '키워드팝업_상세보기',{"medical_type":this.state.type, "medical_id": this.state.id, "name": data.textKor, "isKorean": isKorean, "ingredientIndex":ingredientIndex});
  }

  renderIngredients = (data) => {
    if(!data.ingradients)
      return null;
  
    return (
      <div className="keyword-ingredients-container">
        {data.ingradients.map((ingredient, index) => (
          <div className="keyword-ingredient-container" key={index.toString()}>
            <div className="keyword-ingredient-info"><span className="keyword-ingredient-name">{`${ingredient.textKor}(${ingredient.textEng})`}</span>{`: ${ingredient.volume}`}</div>
            <div className="keyword-ingredient-link-container">
              <div className={ingredient.linkKor?"keyword-ingredient-link":"keyword-ingredient-link-disabled"}  onClick={() => this.onClick(ingredient.linkKor, ingredient, true, index)}>
              한
              </div>
            </div>
            <div className="keyword-ingredient-link-container">
              <div className={ingredient.linkEng?"keyword-ingredient-link":"keyword-ingredient-link-disabled"}  onClick={() => this.onClick(ingredient.linkEng, ingredient, false, index)}>
                E
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    let data;
    if(this.state.type == KeywordType.MEDICINE) {
      data = this.props.medicine.medicines[this.state.id];
    }else if(this.state.type == KeywordType.INGRADIENT) {
      data = this.props.medicine.ingredients[this.state.id];
    }else if(this.state.type == KeywordType.ANIMAL_MEDICINE) {
      data = this.props.medicine.animalMedicines[this.state.id];
    }  
    if(!data)
      data = {};
    log(LogLevel.UI_LIFECYCLE, "KeywordPopup: render", this.props.medicine, data);

    return (
      <IonModal cssClass="keyword-info-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="keyword-info-popup-inner">
          <div className="keyword-info-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
          <div className="keyword-info-container">
            <div className="keyword-title-container">
              <div className="keyword-name-container">
                <div className="keyword-name-kor">{data.textKor || <Skeleton />}</div>
                <div className="keyword-name-eng">{data.textEng || <Skeleton />}</div>
              </div>
              <IonButton color="keyword-popup-close" onClick={() => this.setState(() => ({ visible: false }))}>
                <IonIcon class="keyword-popup-close-icon" name="close"/>
              </IonButton>
            </div>
            {this.renderIngredients(data)}

            <div className="keyword-buttons-container">
              <div className="keyword-link-container">
                <div className={data.linkKor?"keyword-link":"keyword-link-disabled"} onClick={() => this.onClick(data.linkKor, data, true)}>
                국내허가사항
                  <div className="keyword-link-icon"><IonIcon name="arrow-forward" mode="ios"/></div>
                </div>
              </div>
              <div className="keyword-link-container">
                <div className={data.linkEng?"keyword-link":"keyword-link-disabled"} onClick={() => this.onClick(data.linkEng, data, false)}>
                  해외자료
                  <div className="keyword-link-icon"><IonIcon name="arrow-forward" mode="ios"/></div>
                </div>
              </div>
            </div>
          </div>
          <div className="keyword-info-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
        </div>
      </IonModal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  medicine : state.medicine,
  filePath: state.board.filePath,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  fetchKeyword: (type: KeywordType, id:number) => actions.medicine.getMedicine(type, id),
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(KeywordPopup);