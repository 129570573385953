import React, { Component } from 'react';
import './Welcome.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton } from '@ionic/react';
import profileM from './../assets/image/profile_m_4.png'
import profileF from './../assets/image/profile_w_1.png'

import { UserInfo, UserInterests } from '../models/Model.User';
import SliderIndex from './SliderIndex';
import InterestSelectToggleButton from './InterestSelectToggleButton';

type Props = {
  interest: string;
  onUpdateInterest: (interest: string) => void;
  onNext: () => void;
};

type State = {
}

class WelcomeStage1Interest extends Component<Props, State> {

  onCheckAll = (code:string, checked:boolean) => {
    let interest;
    if(checked)
      interest = '-';
    else 
      interest = 'ABCDGHJPLMNRSV';
    this.props.onUpdateInterest(interest);
  }

  onCheck = (code:string, checked:boolean) => {
    let interest;
    if(checked)
      interest = this.props.interest.replace(code, '');
    else {
      interest = this.props.interest + code;
      interest = interest.replace('-', '');
    }
    if(!interest)
      interest = '-';
    this.props.onUpdateInterest(interest);
  }

  render() {  
    return (
      <div className="welcome-container">
        <div className="welcome-1-title">관심분야 선택</div>
        <div className="welcome-1-description-container">
          <img className="welcome-1-profile" src={profileF}/>
          <div className="welcome-1-description">
            관심분야의 정보를 놓치지 마세요. <br/> 관심분야의 글이 등록되면 알려드립니다.
          </div>
          <img className="welcome-1-profile" src={profileM}/>
        </div>
        <div className="welcome-1-subtitle">관심분야 선택(ATC 코드 분류)</div>
        <div className="welcome-1-items-container">
          <InterestSelectToggleButton name="모두" code="ALL" checked={this.props.interest.length >= 14} onClick={this.onCheckAll} />
          <div className="common-container">
            {UserInterests.map((item, index) => 
              <InterestSelectToggleButton key={index.toString()} name={item.name} code={item.code} checked={this.props.interest.includes(item.code)} onClick={this.onCheck} />
            )}
          </div>
        </div>
        <div className="welcome-navigate-container">
          <div className="welcome-navigate-space"/>
          <IonButton color="welcome-navigate-active" onClick={this.props.onNext}>
            다음 단계로 &gt;
          </IonButton>
        </div>
      </div>   
    );
  }
}

export default WelcomeStage1Interest;