

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonCheckbox, IonIcon, IonToggle } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import { timeout, async } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey, setGlobal } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import { UIPopupType } from '../../../store/ui/types';
import { getDateStringFromToday, SERVICE_START_DATE } from '../../../utils/TimeUtil';

const API = {        
  "USER_RANK_STATISTICS" : {"method":"POST", "path":"/admin/user/rank/statistics", "contentType":"application/json"},
  "USER_GET" : {"method":"GET", "path":"/admin/user/user", "contentType":"application/json"},
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onSelect?: (user:UserInfo) => void;
};

type State = {
  fold: boolean;
  loading: boolean;
  dateStart: string;
  dateStartWarn : boolean;
  dateEnd: string;
  dateEndWarn : boolean;
  count: string;
  countWarn : boolean;
  showOption: number;
  statistics?: UserRankStatistics,
}

interface UserRankStatistics{
  startDate?:string,
  endDate?:string,
  count?: number,
  asker?: UserRankStatisticsItem[],
  answerer?: UserRankStatisticsItem[],
  viewer?: UserRankStatisticsItem[],
  liked?: UserRankStatisticsItem[],
}

interface UserRankStatisticsItem{
  id?: number,
  email?: string,
  name?: string,
  nickname?: string,
  phone?: string,
  count?: number,
  visit?: number,
  ask?: number,
  write?: number,
  answer? : number,
  post?: number,
  view?: number,
  react?: number,
  like?: number,
  share?: number,
  metoo?: number,
  scrap? : number,
  comment?: number,
  reacted?: number,
  liked?: number,
  shared?: number,
  metooed?: number,
  scrapped?: number,
  commented?: number,
}

enum UserRankStatisticsShowSelection{
  NONE = 0,
  ASKER = 0x01,
  ANSWERER = 0x02,
  VIEWER = 0x04,
  ALL = 0x07,
}

class UserRankStatisticsView extends Component<Props, State> {

  constructor(props:Props){
    super(props);
    log(LogLevel.UI_DATA_LOAD, "UserRankStatisticsView constructor");
    let showOptions = getGlobal(GlobalKey.ADMIN_STATISTICS_USER_SHOW_OPTION, true);

    this.state = {
      fold: true,
      loading: false,
      dateStart: getDateStringFromToday({days:-28}),
      dateStartWarn : false,
      dateEnd: getDateStringFromToday({days:1}),
      dateEndWarn : false,
      showOption: UserRankStatisticsShowSelection.ALL,
      count : "10",
      countWarn: false,
    };
  }

  componentWillUpdate(nextProps, nextState){
  }

  fetchStatistics = async () => {
    if(this.state.loading){
      this.props.toastPopup.show("로딩중입니다.");
      return;
    }
    if(this.state.dateStartWarn || this.state.dateEndWarn || this.state.countWarn){
      this.props.toastPopup.show("검색옵션을 확인해주세요.");
      return;
    }

    let request:UserRankStatistics = {};
    request.startDate = this.state.dateStart;
    request.endDate = this.state.dateEnd;
    request.count = parseInt(this.state.count);

    fetchAPI(API.USER_RANK_STATISTICS, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error && result.data){
        log(LogLevel.UI_DATA_LOAD, "UserRankStatisticsView Fetch success", request, result);
        this.setState({statistics:result.data});
      }
      else{
        log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView Fetch failed", request, result);
      }
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView Fetch excepted", request, e);
    })

  }

  onDateStartChange = (e) => {
    let dateStart = e.target.value;
    let dateStartWarn = false;
    try{
      let date = Date.parse(dateStart);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView:onDateStartChange", dateStart);
        dateStartWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "UserRankStatisticsView:onDateStartChange", dateStart, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView:onDateStartChange", dateStart, err);
      console.log(err);
      dateStartWarn = true;
    }
    this.setState({dateStart, dateStartWarn});
  }

  onDateEndChange = (e) => {
    let dateEnd = e.target.value;
    let dateEndWarn = false;
    try{
      let date = Date.parse(dateEnd);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView:onDateEndChange", dateEnd);
        dateEndWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "UserRankStatisticsView:onDateEndChange", dateEnd, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView:onDateEndChange", dateEnd, err);
      dateEndWarn = true;
    }
    this.setState({dateEnd, dateEndWarn});
  }

  onCountChange = (e) => {
    let countWarn = false;
    let count = e.target.value
    try{
      let countint = parseInt(e.target.value);
      if(!countint && countint != 0){
        log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView:onCountChange", e.target.value);
        countWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "UserRankStatisticsView:onCountChange", e.target.value);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView:onCountChange", e.target.value, err);
      countWarn = true;
    }
    this.setState({count, countWarn});
  }

  setPeriod = (pid) => {
    let dateStart;
    let dateEnd;
    switch(pid){
      case 1: // 4주
        dateStart = getDateStringFromToday({days:-27});
        dateEnd = getDateStringFromToday({days:1});
        break;
      case 2: // 이번달
        dateStart = getDateStringFromToday({byMonth: true});
        dateEnd = getDateStringFromToday({byMonth: true, months:1});
        break;
      case 3: // 지난달
        dateStart = getDateStringFromToday({byMonth: true, months:-1});
        dateEnd = getDateStringFromToday({byMonth: true});
        break;
      case 4: // 12주
        dateStart = getDateStringFromToday({days:-83});
        dateEnd = getDateStringFromToday({days:1});
        break;
      case 5: // 서비스개시일
        dateStart = SERVICE_START_DATE;
        dateEnd = getDateStringFromToday({days:1});
        break;
    }
    this.setState({
      dateStart,
      dateStartWarn : false,
      dateEnd,
      dateEndWarn : false,
    })
  }


  onDownload = () => {
    if(!this.state.statistics.viewer && !this.state.statistics.answerer && !this.state.statistics.viewer)
      return;

    let csv = "type,rank,count,id,nikname,name,phone\r\n";
    
    if(this.state.statistics.asker && this.state.statistics.asker.length){
      csv += this.state.statistics.asker.map((item, index) => `asker,${index+1},${item.count},${item.id},"${item.nickname}","${item.name}","${item.phone}"`).join("\r\n");
      csv += "\r\n";
    }
    if(this.state.statistics.answerer && this.state.statistics.answerer.length){
      csv += this.state.statistics.answerer.map((item, index) => `answerer,${index+1},${item.count},${item.id},"${item.nickname}","${item.name}","${item.phone}"`).join("\r\n");
      csv += "\r\n";
    }
    if(this.state.statistics.viewer && this.state.statistics.viewer.length){
      csv += this.state.statistics.viewer.map((item, index) => `viewer,${index+1},${item.count},${item.id},"${item.nickname}","${item.name}","${item.phone}"`).join("\r\n");
      csv += "\r\n";
    }
    if(this.state.statistics.liked && this.state.statistics.liked.length){
      csv += this.state.statistics.liked.map((item, index) => `liked,${index+1},${item.count},${item.id},"${item.nickname}","${item.name}","${item.phone}"`).join("\r\n");
      csv += "\r\n";
    }

    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    pom.setAttribute('download', `User-Rank-Statistics-${this.state.statistics.startDate.replace(/-/ig, '')}-${this.state.statistics.endDate.replace(/-/ig, '')}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
  }

  onSelect = (id) => {
    log(LogLevel.UI_ACTION, "UserRankStatisticsView:onSelect", id);
    fetchAPI(API.USER_GET, "", {id}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error && result.data){
        this.props.onSelect(result.data);
      }
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "UserRankStatisticsView:onSelect Failed", e);
    });


  }

  renderTable = () => {
    if(!this.state.statistics || (!this.state.statistics.viewer && !this.state.statistics.answerer && !this.state.statistics.viewer))
      return;
    
    let label = (
      <tr>
        <td className="admin-table-label-x">Type</td>
        <td className="admin-table-label-x">Rank</td>
        <td className="admin-table-label-x">Count</td>
        <td className="admin-table-label-x">Id</td>
        <td className="admin-table-label-x">Nickname</td>
        <td className="admin-table-label-x">Name</td>
        <td className="admin-table-label-x">Phone</td>
      </tr>
    );

    let data = [];
    if(this.state.statistics.asker && this.state.statistics.asker.length && (this.state.showOption & UserRankStatisticsShowSelection.ASKER)){
      data.push(this.state.statistics.asker.map((item, index, list) => {
        let type;
        if(index == 0){
          type = (
            <td className="admin-table-label-y" rowSpan={list.length}>Asker</td>
          );
        }
        return(
          <tr key={index.toString()} onClick={() => this.onSelect(item.id)}>
            {type}
            <td className="admin-table-value">{(index+1).toString()}</td>
            <td className="admin-table-value">{item.count}</td>
            <td className="admin-table-value">{item.id}</td>
            <td className="admin-table-value">{item.nickname}</td>
            <td className="admin-table-value">{item.name}</td>
            <td className="admin-table-value">{item.phone}</td>
          </tr>
        );
      }));
    }

    if(this.state.statistics.answerer && this.state.statistics.answerer.length && (this.state.showOption & UserRankStatisticsShowSelection.ANSWERER)){
      data.push(this.state.statistics.answerer.map((item, index, list) => {
        let type;
        if(index == 0){
          type = (
            <td className="admin-table-label-y" rowSpan={list.length}>Answerer</td>
          );
        }
        return(
          <tr key={index.toString()} onClick={() => this.onSelect(item.id)}>
            {type}
            <td className="admin-table-value">{(index+1).toString()}</td>
            <td className="admin-table-value">{item.count}</td>
            <td className="admin-table-value">{item.id}</td>
            <td className="admin-table-value">{item.nickname}</td>
            <td className="admin-table-value">{item.name}</td>
            <td className="admin-table-value">{item.phone}</td>
          </tr>
        );
      }));
    }

    if(this.state.statistics.viewer && this.state.statistics.viewer.length && (this.state.showOption & UserRankStatisticsShowSelection.VIEWER)){
      data.push(this.state.statistics.viewer.map((item, index, list) => {
        let type;
        if(index == 0){
          type = (
            <td className="admin-table-label-y" rowSpan={list.length}>Viewer</td>
          );
        }
        return(
          <tr key={index.toString()} onClick={() => this.onSelect(item.id)}>
            {type}
            <td className="admin-table-value">{(index+1).toString()}</td>
            <td className="admin-table-value">{item.count}</td>
            <td className="admin-table-value">{item.id}</td>
            <td className="admin-table-value">{item.nickname}</td>
            <td className="admin-table-value">{item.name}</td>
            <td className="admin-table-value">{item.phone}</td>
          </tr>
        );
      }));
    }

    if(this.state.statistics.liked && this.state.statistics.liked.length && (this.state.showOption & UserRankStatisticsShowSelection.VIEWER)){
      data.push(this.state.statistics.liked.map((item, index, list) => {
        let type;
        if(index == 0){
          type = (
            <td className="admin-table-label-y" rowSpan={list.length}>Liked</td>
          );
        }
        return(
          <tr key={index.toString()} onClick={() => this.onSelect(item.id)}>
            {type}
            <td className="admin-table-value">{(index+1).toString()}</td>
            <td className="admin-table-value">{item.count}</td>
            <td className="admin-table-value">{item.id}</td>
            <td className="admin-table-value">{item.nickname}</td>
            <td className="admin-table-value">{item.name}</td>
            <td className="admin-table-value">{item.phone}</td>
          </tr>
        );
      }));
    }

    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <table className="admin-table">
            <tbody>
              {label}
              {data}
            </tbody>
          </table>
        </div>
        <IonButton color="primary" onClick={this.onDownload}>
          다운로드
        </IonButton>
      </div>
    );
    

  }

  onChangeShowOption = (option) => {
    let showOption = this.state.showOption ^ option;
    this.setState({showOption})
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "UserRankStatisticsView:render", this.props, this.state);

    if(this.state.fold){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({fold:false})}>
            <div className="common-flex-row">
              <div>Rank</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 

      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({fold:true})}>
          <div className="common-flex-row">
            <div>Rank</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Search Option</div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <input className={(this.state.dateStartWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.dateStart} onChange={this.onDateStartChange}/>
                <div>~</div>
                <input className={(this.state.dateEndWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.dateEnd} onChange={this.onDateEndChange}/>
                <div className="admin-text-button" onClick={() => this.setPeriod(1)}>4주</div>
                <div className="admin-text-button" onClick={() => this.setPeriod(2)}>이번달</div>
                <div className="admin-text-button" onClick={() => this.setPeriod(3)}>지난달</div>
                <div className="admin-text-button" onClick={() => this.setPeriod(4)}>12주</div>
                <div className="admin-text-button" onClick={() => this.setPeriod(5)}>서비스개시일</div>
              </div>
              <input className={(this.state.dateEndWarn?"common-color-caution":"")} placeholder="시작일시" type="number" value={this.state.count} onChange={this.onCountChange}/>
            </div>
          </div>
          <IonButton onClick={this.fetchStatistics} disabled={this.state.loading}>Load</IonButton>
        </div>
        <div className="common-container-row admin-show-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Display Option</div>
              <div className="common-container-row">
                <div className="common-flex-row-wrap common-flex-grow">
                  <div className="common-flex-row">
                    <IonCheckbox disabled={!this.state.statistics || !this.state.statistics.asker} checked={!!(this.state.showOption & UserRankStatisticsShowSelection.ASKER)} onIonChange={(e) => this.onChangeShowOption(UserRankStatisticsShowSelection.ASKER)}/>
                    <div className="admin-margin-right">ASKER</div>
                  </div>
                  <div className="common-flex-row">
                    <IonCheckbox disabled={!this.state.statistics || !this.state.statistics.answerer} checked={!!(this.state.showOption & UserRankStatisticsShowSelection.ANSWERER)} onIonChange={(e) => this.onChangeShowOption(UserRankStatisticsShowSelection.ANSWERER)}/>
                    <div className="admin-margin-right">ANSWERER</div>
                  </div>
                  <div className="common-flex-row">
                    <IonCheckbox disabled={!this.state.statistics || !this.state.statistics.viewer} checked={!!(this.state.showOption & UserRankStatisticsShowSelection.VIEWER)} onIonChange={(e) => this.onChangeShowOption(UserRankStatisticsShowSelection.VIEWER)}/>
                    <div className="admin-margin-right">VIEWER</div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
        {this.renderTable()}

      </div>
    );


  }
}


const mapStateToProps = (state: RootState) => ({
  toastPopup : state.ui.popups[UIPopupType.TOAST_POPUP]
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
}
export default connect(mapStateToProps, mapDispatchToProps)(UserRankStatisticsView);