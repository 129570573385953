import styled from "styled-components";
import { JobListType } from "../../../store/jobpost/types";
import { COLOR_SYSTEM } from "../../design/design-system";

interface PostListStyledProps {
  searching?: boolean;
  isOnline?: boolean;
  postType?: JobListType;
  noSearchedPost?: boolean;
  noMyLikePost?: boolean;
  noMyPost?: boolean;
}

interface FixedButtonProps {
  left?: boolean;
  center?: boolean;
  right?: boolean;
}

const Header = styled.header`
  width: 100%;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: fixed;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
`;

const TopAppBarRight = styled.div`
  position: relative;
`;

const NewChatAlertBox = styled.div`
  position: absolute;
  top: 5px;
  left: 20px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${COLOR_SYSTEM.get("Red")[500]};
`;

const JobPostListWrapper = styled.div`
  position: relative;

  .no-padding {
    padding: 0;
  }
`;

const Main = styled.main<PostListStyledProps>`
  height: calc(100vh - 48px);
  padding: ${({ searching, postType }) => {
    if (searching) {
      return "73px 0 24px 0";
    } else {
      return postType === JobListType.SEARCHED
        ? "49px 0 131px 0"
        : "108px 0 131px 0";
    }
  }};
  overflow-y: auto;
  position: relative;
  background-color: ${({ searching }) =>
    searching ? COLOR_SYSTEM.get("Gray")[0] : COLOR_SYSTEM.get("Gray")[10]};

  .recent-searched__title {
    padding: 0 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .recent-searched__list {
    padding: 0 24px;
  }

  .recent-searched__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .searched-result__no-result-container {
    padding: 0 4px;
    margin-top: 16px;
  }

  .no-result__title {
    margin-bottom: 8px;
  }

  .no-result__example-list {
    margin-top: 40px;
  }

  .no-result__example-item {
    list-style: disc;
    margin-left: 24px;

    &::marker {
      color: ${COLOR_SYSTEM.get("Gray")[400]};
    }
  }
`;

const Filter = styled.div`
  width: 100%;
  padding: 24px 20px 8px 20px;

  & > * + * {
    margin-top: 8px;
  }

  .filter-box {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;

    &.space-between {
      justify-content: space-between;
    }

    & > * + * {
      margin-left: 8px;
    }

    button {
      flex-shrink: 0;
    }

    .hide-post-switch {
      flex-shrink: 0;
    }
  }

  .bottom-sheet__button {
    background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  }
`;

const PostList = styled.section<PostListStyledProps>`
  padding: ${({ postType }) =>
    `${
      postType === JobListType.PHARMACY ||
      postType === JobListType.BIZ ||
      postType === JobListType.SEARCHED
        ? "0"
        : "20px"
    } 20px 0px 20px`};
  display: flex;
  flex-direction: column;
  /* position: relative; */

  & > * + * {
    margin-top: 12px;
  }
`;

const FixedButton = styled.div<FixedButtonProps>`
  position: fixed;
  left: ${({ center, left }) => {
    if (center) return "50%";
    else if (left) return "20px";
  }};
  right: ${({ right }) => {
    if (right) return "20px";
  }};
  bottom: 24px;
  transform: ${({ center }) => {
    if (center) return "translateX(-50%)";
  }};
  z-index: 101;
`;

const NoPosts = styled.div<PostListStyledProps>`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: ${({ postType }) =>
    `translate(0%, ${postType === JobListType.ALL ? "-90%" : "-70%"})`};
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 24px;
  }

  span,
  h2 {
    text-align: center;
  }
`;

const PullUpModalMain = styled.main`
  display: flex;
  flex-direction: column;
  text-align: center;

  & > * + * {
    margin-top: 12px;
  }
`;

const PullUpModalFooter = styled.footer`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

const PullUpErrorModalMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > * + * {
    margin-top: 12px;
  }
`;

const PullUpErrorModalFooter = styled.footer`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

const FinishPostPopupMain = styled.main`
  text-align: center;
  width: 100%;
`;

const FinishPostPopupCadidates = styled.div`
  width: 100%;
`;

const FinishPostPopupCadidateItem = styled.div<{ last?: boolean }>`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;

  .detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  ${({ last }) => {
    if (!last)
      return `border-bottom: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};`;
  }}
`;

const FinishPostPopupFooter = styled.footer`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

const RecruitTemplateListTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &.mg-top {
    margin-top: 24px;
  }
`;

export {
  JobPostListWrapper,
  Header,
  TopAppBarRight,
  NewChatAlertBox,
  Main,
  Filter,
  PostList,
  FixedButton,
  NoPosts,
  PullUpErrorModalFooter,
  PullUpErrorModalMain,
  PullUpModalFooter,
  PullUpModalMain,
  FinishPostPopupCadidates,
  FinishPostPopupCadidateItem,
  FinishPostPopupMain,
  FinishPostPopupFooter,
  RecruitTemplateListTitleRow,
};
