import React, { Component } from "react";
import "./SelectRegionPopup.css";
import "./../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonIcon,
  IonInput,
  IonList,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "./../utils/APIUtil";
import * as API from "./../API.json";
import { timeout } from "q";
import { ALPN_ENABLED } from "constants";
import { LogLevel, log } from "../utils/LogUtil";
import SearchAddress from "./SearchAddress";
import { getGlobal, GlobalKey, setGlobal } from "../utils/GlobalUtil";
import { connect } from "react-redux";
import { RootState } from "../store";
import { UIServiceType } from "../store/ui/types";

const windowAny: any = window;

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelected: (address: string) => void;
  };

type State = {
  visible: boolean;
  selectedKey: string;
};

class SelectRegionPopup extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      selectedKey: "서울",
    };
  }

  show = () => {
    let selectedKey = getGlobal(GlobalKey.JOBPOST_SEARCH_PROVICE, true);
    if (!selectedKey) selectedKey = "서울";

    this.setState({ visible: true, selectedKey });
    this.props.backKeyControl.pushListener(() => {
      this.hide();
      return true;
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.visible != nextState.visible) return true;
    if (this.state.selectedKey != nextState.selectedKey) return true;
    return false;
  }

  hide = () => {
    this.setState({ visible: false });
    this.props.backKeyControl.popListener();
  };

  didShow = () => {
    log(LogLevel.UI_LIFECYCLE, "SelectRegionPopup:didShow");
  };

  onProvinceSelect = (key) => {
    setGlobal(GlobalKey.JOBPOST_SEARCH_PROVICE, key, true);
    this.setState({ selectedKey: key });
  };

  onRegionSelect = (search) => {
    this.props.onSelected(search);
    this.hide();
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "SelectRegionPopup:render",
      this.state.selectedKey,
      REGIONS,
      REGIONS[this.state.selectedKey],
      Object.keys(REGIONS),
      REGIONS[this.state.selectedKey].region
    );
    return (
      <IonModal
        cssClass="common-modal-full-popup-container"
        isOpen={this.state.visible}
        backdropDismiss={true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="common-modal-popup-inner">
          <div className="common-content">
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start">
                  <IonButton
                    class="common-header-toolbar-button"
                    onClick={this.hide}
                  >
                    <IonIcon name="arrow-back" mode="ios" />
                  </IonButton>
                  <IonLabel class="common-header-title" onClick={this.hide}>
                    지역 선택
                  </IonLabel>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <div className="common-content-with-header select-region-popup-container">
              <div className="select-region-popup-province-container">
                {Object.keys(REGIONS).map((item, index) => {
                  if (this.state.selectedKey == item)
                    return (
                      <div
                        key={index.toString()}
                        className="common-container-column common-flex-align-center select-region-popup-province-item-container common-color-highlight"
                      >
                        <div
                          className="common-flex-row common-flex-align-center"
                          style={{ height: "100%" }}
                        >
                          <div>{item} </div>
                        </div>
                      </div>
                    );
                  else
                    return (
                      <IonButton
                        key={index.toString()}
                        color="select-region-popup-province-item-container"
                        onClick={() => this.onProvinceSelect(item)}
                      >
                        {item}
                      </IonButton>
                    );
                })}
              </div>
              <div className="select-region-popup-region-container">
                {REGIONS[this.state.selectedKey].region.map((item, index) => {
                  return (
                    <IonButton
                      key={index.toString()}
                      color="select-region-popup-region-item-container"
                      onClick={() => this.onRegionSelect(item.search)}
                    >
                      <div className="common-container-row common-flex-align-center">
                        <div className="common-flex-grow">{item.name}</div>
                        <IonIcon
                          class="select-region-popup-region-item-icon"
                          name="arrow-forward"
                          mode="ios"
                        />
                      </div>
                    </IonButton>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </IonModal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  medicine: state.medicine,
  filePath: state.board.filePath,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SelectRegionPopup);

export const REGIONS = {
  전체: {
    name: "전체",
    region: [
      {
        name: "전체",
        search: "",
      },
    ],
  },
  서울: {
    name: "서울특별시",
    region: [
      {
        name: "전체",
        search: "서울특별시",
      },
      {
        name: "강남구",
        search: "서울특별시 강남구",
      },
      {
        name: "강동구",
        search: "서울특별시 강동구",
      },
      {
        name: "강북구",
        search: "서울특별시 강북구",
      },
      {
        name: "강서구",
        search: "서울특별시 강서구",
      },
      {
        name: "관악구",
        search: "서울특별시 관악구",
      },
      {
        name: "광진구",
        search: "서울특별시 광진구",
      },
      {
        name: "구로구",
        search: "서울특별시 구로구",
      },
      {
        name: "금천구",
        search: "서울특별시 금천구",
      },
      {
        name: "노원구",
        search: "서울특별시 노원구",
      },
      {
        name: "도봉구",
        search: "서울특별시 도봉구",
      },
      {
        name: "동대문구",
        search: "서울특별시 동대문구",
      },
      {
        name: "동작구",
        search: "서울특별시 동작구",
      },
      {
        name: "마포구",
        search: "서울특별시 마포구",
      },
      {
        name: "서대문구",
        search: "서울특별시 서대문구",
      },
      {
        name: "서초구",
        search: "서울특별시 서초구",
      },
      {
        name: "성동구",
        search: "서울특별시 성동구",
      },
      {
        name: "성북구",
        search: "서울특별시 성북구",
      },
      {
        name: "송파구",
        search: "서울특별시 송파구",
      },
      {
        name: "양천구",
        search: "서울특별시 양천구",
      },
      {
        name: "영등포구",
        search: "서울특별시 영등포구",
      },
      {
        name: "용산구",
        search: "서울특별시 용산구",
      },
      {
        name: "은평구",
        search: "서울특별시 은평구",
      },
      {
        name: "종로구",
        search: "서울특별시 종로구",
      },
      {
        name: "중구",
        search: "서울특별시 중구",
      },
      {
        name: "중랑구",
        search: "서울특별시 중랑구",
      },
    ],
  },
  부산: {
    name: "부산광역시",
    region: [
      {
        name: "전체",
        search: "부산광역시",
      },
      {
        name: "강서구",
        search: "부산광역시 강서구",
      },
      {
        name: "금정구",
        search: "부산광역시 금정구",
      },
      {
        name: "기장군",
        search: "부산광역시 기장군",
      },
      {
        name: "남구",
        search: "부산광역시 남구",
      },
      {
        name: "동구",
        search: "부산광역시 동구",
      },
      {
        name: "동래구",
        search: "부산광역시 동래구",
      },
      {
        name: "부산진구",
        search: "부산광역시 부산진구",
      },
      {
        name: "북구",
        search: "부산광역시 북구",
      },
      {
        name: "사상구",
        search: "부산광역시 사상구",
      },
      {
        name: "사하구",
        search: "부산광역시 사하구",
      },
      {
        name: "서구",
        search: "부산광역시 서구",
      },
      {
        name: "수영구",
        search: "부산광역시 수영구",
      },
      {
        name: "연제구",
        search: "부산광역시 연제구",
      },
      {
        name: "영도구",
        search: "부산광역시 영도구",
      },
      {
        name: "중구",
        search: "부산광역시 중구",
      },
      {
        name: "해운대구",
        search: "부산광역시 해운대구",
      },
    ],
  },
  대구: {
    name: "대구광역시",
    region: [
      {
        name: "전체",
        search: "대구광역시",
      },
      {
        name: "남구",
        search: "대구광역시 남구",
      },
      {
        name: "달서구",
        search: "대구광역시 달서구",
      },
      {
        name: "달성군",
        search: "대구광역시 달성군",
      },
      {
        name: "동구",
        search: "대구광역시 동구",
      },
      {
        name: "북구",
        search: "대구광역시 북구",
      },
      {
        name: "서구",
        search: "대구광역시 서구",
      },
      {
        name: "수성구",
        search: "대구광역시 수성구",
      },
      {
        name: "중구",
        search: "대구광역시 중구",
      },
    ],
  },
  인천: {
    name: "인천광역시",
    region: [
      {
        name: "전체",
        search: "인천광역시",
      },
      {
        name: "강화군",
        search: "인천광역시 강화군",
      },
      {
        name: "계양구",
        search: "인천광역시 계양구",
      },
      {
        name: "남동구",
        search: "인천광역시 남동구",
      },
      {
        name: "동구",
        search: "인천광역시 동구",
      },
      {
        name: "미추홀구",
        search: "인천광역시 미추홀구",
      },
      {
        name: "부평구",
        search: "인천광역시 부평구",
      },
      {
        name: "서구",
        search: "인천광역시 서구",
      },
      {
        name: "연수구",
        search: "인천광역시 연수구",
      },
      {
        name: "옹진군",
        search: "인천광역시 옹진군",
      },
      {
        name: "중구",
        search: "인천광역시 중구",
      },
    ],
  },
  광주: {
    name: "광주광역시",
    region: [
      {
        name: "전체",
        search: "광주광역시",
      },
      {
        name: "광산구",
        search: "광주광역시 광산구",
      },
      {
        name: "남구",
        search: "광주광역시 남구",
      },
      {
        name: "동구",
        search: "광주광역시 동구",
      },
      {
        name: "북구",
        search: "광주광역시 북구",
      },
      {
        name: "서구",
        search: "광주광역시 서구",
      },
    ],
  },
  대전: {
    name: "대전광역시",
    region: [
      {
        name: "전체",
        search: "대전광역시",
      },
      {
        name: "대덕구",
        search: "대전광역시 대덕구",
      },
      {
        name: "동구",
        search: "대전광역시 동구",
      },
      {
        name: "서구",
        search: "대전광역시 서구",
      },
      {
        name: "유성구",
        search: "대전광역시 유성구",
      },
      {
        name: "중구",
        search: "대전광역시 중구",
      },
    ],
  },
  울산: {
    name: "울산광역시",
    region: [
      {
        name: "전체",
        search: "울산광역시",
      },
      {
        name: "남구",
        search: "울산광역시 남구",
      },
      {
        name: "동구",
        search: "울산광역시 동구",
      },
      {
        name: "북구",
        search: "울산광역시 북구",
      },
      {
        name: "울주군",
        search: "울산광역시 울주군",
      },
      {
        name: "중구",
        search: "울산광역시 중구",
      },
    ],
  },
  세종: {
    name: "세종특별자치시",
    region: [
      {
        name: "전체",
        search: "세종특별자치시",
      },
    ],
  },
  경기: {
    name: "경기도",
    region: [
      {
        name: "전체",
        search: "경기도",
      },
      {
        name: "가평군",
        search: "경기도 가평군",
      },
      {
        name: "고양시",
        search: "경기도 고양시",
      },
      {
        name: "과천시",
        search: "경기도 과천시",
      },
      {
        name: "광명시",
        search: "경기도 광명시",
      },
      {
        name: "광주시",
        search: "경기도 광주시",
      },
      {
        name: "구리시",
        search: "경기도 구리시",
      },
      {
        name: "군포시",
        search: "경기도 군포시",
      },
      {
        name: "김포시",
        search: "경기도 김포시",
      },
      {
        name: "남양주시",
        search: "경기도 남양주시",
      },
      {
        name: "동두천시",
        search: "경기도 동두천시",
      },
      {
        name: "부천시",
        search: "경기도 부천시",
      },
      {
        name: "성남시",
        search: "경기도 성남시",
      },
      {
        name: "수원시",
        search: "경기도 수원시",
      },
      {
        name: "시흥시",
        search: "경기도 시흥시",
      },
      {
        name: "안산시",
        search: "경기도 안산시",
      },
      {
        name: "안성시",
        search: "경기도 안성시",
      },
      {
        name: "안양시",
        search: "경기도 안양시",
      },
      {
        name: "양주시",
        search: "경기도 양주시",
      },
      {
        name: "양평군",
        search: "경기도 양평군",
      },
      {
        name: "여주시",
        search: "경기도 여주시",
      },
      {
        name: "연천군",
        search: "경기도 연천군",
      },
      {
        name: "오산시",
        search: "경기도 오산시",
      },
      {
        name: "용인시",
        search: "경기도 용인시",
      },
      {
        name: "의왕시",
        search: "경기도 의왕시",
      },
      {
        name: "의정부시",
        search: "경기도 의정부시",
      },
      {
        name: "이천시",
        search: "경기도 이천시",
      },
      {
        name: "파주시",
        search: "경기도 파주시",
      },
      {
        name: "평택시",
        search: "경기도 평택시",
      },
      {
        name: "포천시",
        search: "경기도 포천시",
      },
      {
        name: "하남시",
        search: "경기도 하남시",
      },
      {
        name: "화성시",
        search: "경기도 화성시",
      },
    ],
  },
  강원: {
    name: "강원특별자치도",
    region: [
      {
        name: "전체",
        search: "강원특별자치도",
      },
      {
        name: "강릉시",
        search: "강원특별자치도 강릉시",
      },
      {
        name: "고성군",
        search: "강원특별자치도 고성군",
      },
      {
        name: "동해시",
        search: "강원특별자치도 동해시",
      },
      {
        name: "삼척시",
        search: "강원특별자치도 삼척시",
      },
      {
        name: "속초시",
        search: "강원특별자치도 속초시",
      },
      {
        name: "양구군",
        search: "강원특별자치도 양구군",
      },
      {
        name: "양양군",
        search: "강원특별자치도 양양군",
      },
      {
        name: "영월군",
        search: "강원특별자치도 영월군",
      },
      {
        name: "원주시",
        search: "강원특별자치도 원주시",
      },
      {
        name: "인제군",
        search: "강원특별자치도 인제군",
      },
      {
        name: "정선군",
        search: "강원특별자치도 정선군",
      },
      {
        name: "철원군",
        search: "강원특별자치도 철원군",
      },
      {
        name: "춘천시",
        search: "강원특별자치도 춘천시",
      },
      {
        name: "태백시",
        search: "강원특별자치도 태백시",
      },
      {
        name: "평창군",
        search: "강원특별자치도 평창군",
      },
      {
        name: "홍천군",
        search: "강원특별자치도 홍천군",
      },
      {
        name: "화천군",
        search: "강원특별자치도 화천군",
      },
      {
        name: "횡성군",
        search: "강원특별자치도 횡성군",
      },
    ],
  },
  충북: {
    name: "충청북도",
    region: [
      {
        name: "전체",
        search: "충청북도",
      },
      {
        name: "괴산군",
        search: "충청북도 괴산군",
      },
      {
        name: "단양군",
        search: "충청북도 단양군",
      },
      {
        name: "보은군",
        search: "충청북도 보은군",
      },
      {
        name: "영동군",
        search: "충청북도 영동군",
      },
      {
        name: "옥천군",
        search: "충청북도 옥천군",
      },
      {
        name: "음성군",
        search: "충청북도 음성군",
      },
      {
        name: "제천시",
        search: "충청북도 제천시",
      },
      {
        name: "증평군",
        search: "충청북도 증평군",
      },
      {
        name: "진천군",
        search: "충청북도 진천군",
      },
      {
        name: "청주시",
        search: "충청북도 청주시",
      },
      {
        name: "충주시",
        search: "충청북도 충주시",
      },
    ],
  },
  충남: {
    name: "충청남도",
    region: [
      {
        name: "전체",
        search: "충청남도",
      },
      {
        name: "계룡시",
        search: "충청남도 계룡시",
      },
      {
        name: "공주시",
        search: "충청남도 공주시",
      },
      {
        name: "금산군",
        search: "충청남도 금산군",
      },
      {
        name: "논산시",
        search: "충청남도 논산시",
      },
      {
        name: "당진시",
        search: "충청남도 당진시",
      },
      {
        name: "보령시",
        search: "충청남도 보령시",
      },
      {
        name: "부여군",
        search: "충청남도 부여군",
      },
      {
        name: "서산시",
        search: "충청남도 서산시",
      },
      {
        name: "서천군",
        search: "충청남도 서천군",
      },
      {
        name: "아산시",
        search: "충청남도 아산시",
      },
      {
        name: "예산군",
        search: "충청남도 예산군",
      },
      {
        name: "천안시",
        search: "충청남도 천안시",
      },
      {
        name: "청양군",
        search: "충청남도 청양군",
      },
      {
        name: "태안군",
        search: "충청남도 태안군",
      },
      {
        name: "홍성군",
        search: "충청남도 홍성군",
      },
    ],
  },
  전북: {
    name: "전북특별자치도",
    region: [
      {
        name: "전체",
        search: "전북특별자치도",
      },
      {
        name: "고창군",
        search: "전북특별자치도 고창군",
      },
      {
        name: "군산시",
        search: "전북특별자치도 군산시",
      },
      {
        name: "김제시",
        search: "전북특별자치도 김제시",
      },
      {
        name: "남원시",
        search: "전북특별자치도 남원시",
      },
      {
        name: "무주군",
        search: "전북특별자치도 무주군",
      },
      {
        name: "부안군",
        search: "전북특별자치도 부안군",
      },
      {
        name: "순창군",
        search: "전북특별자치도 순창군",
      },
      {
        name: "완주군",
        search: "전북특별자치도 완주군",
      },
      {
        name: "익산시",
        search: "전북특별자치도 익산시",
      },
      {
        name: "임실군",
        search: "전북특별자치도 임실군",
      },
      {
        name: "장수군",
        search: "전북특별자치도 장수군",
      },
      {
        name: "전주시",
        search: "전북특별자치도 전주시",
      },
      {
        name: "정읍시",
        search: "전북특별자치도 정읍시",
      },
      {
        name: "진안군",
        search: "전북특별자치도 진안군",
      },
    ],
  },
  전남: {
    name: "전라남도",
    region: [
      {
        name: "전체",
        search: "전라남도",
      },
      {
        name: "강진군",
        search: "전라남도 강진군",
      },
      {
        name: "고흥군",
        search: "전라남도 고흥군",
      },
      {
        name: "곡성군",
        search: "전라남도 곡성군",
      },
      {
        name: "광양시",
        search: "전라남도 광양시",
      },
      {
        name: "구례군",
        search: "전라남도 구례군",
      },
      {
        name: "나주시",
        search: "전라남도 나주시",
      },
      {
        name: "담양군",
        search: "전라남도 담양군",
      },
      {
        name: "목포시",
        search: "전라남도 목포시",
      },
      {
        name: "무안군",
        search: "전라남도 무안군",
      },
      {
        name: "보성군",
        search: "전라남도 보성군",
      },
      {
        name: "순천시",
        search: "전라남도 순천시",
      },
      {
        name: "신안군",
        search: "전라남도 신안군",
      },
      {
        name: "여수시",
        search: "전라남도 여수시",
      },
      {
        name: "영광군",
        search: "전라남도 영광군",
      },
      {
        name: "영암군",
        search: "전라남도 영암군",
      },
      {
        name: "완도군",
        search: "전라남도 완도군",
      },
      {
        name: "장성군",
        search: "전라남도 장성군",
      },
      {
        name: "장흥군",
        search: "전라남도 장흥군",
      },
      {
        name: "진도군",
        search: "전라남도 진도군",
      },
      {
        name: "함평군",
        search: "전라남도 함평군",
      },
      {
        name: "해남군",
        search: "전라남도 해남군",
      },
      {
        name: "화순군",
        search: "전라남도 화순군",
      },
    ],
  },
  경북: {
    name: "경상북도",
    region: [
      {
        name: "전체",
        search: "경상북도",
      },
      {
        name: "경산시",
        search: "경상북도 경산시",
      },
      {
        name: "경주시",
        search: "경상북도 경주시",
      },
      {
        name: "고령군",
        search: "경상북도 고령군",
      },
      {
        name: "구미시",
        search: "경상북도 구미시",
      },
      {
        name: "군위군",
        search: "경상북도 군위군",
      },
      {
        name: "김천시",
        search: "경상북도 김천시",
      },
      {
        name: "문경시",
        search: "경상북도 문경시",
      },
      {
        name: "봉화군",
        search: "경상북도 봉화군",
      },
      {
        name: "상주시",
        search: "경상북도 상주시",
      },
      {
        name: "성주군",
        search: "경상북도 성주군",
      },
      {
        name: "안동시",
        search: "경상북도 안동시",
      },
      {
        name: "영덕군",
        search: "경상북도 영덕군",
      },
      {
        name: "영양군",
        search: "경상북도 영양군",
      },
      {
        name: "영주시",
        search: "경상북도 영주시",
      },
      {
        name: "영천시",
        search: "경상북도 영천시",
      },
      {
        name: "예천군",
        search: "경상북도 예천군",
      },
      {
        name: "울릉군",
        search: "경상북도 울릉군",
      },
      {
        name: "울진군",
        search: "경상북도 울진군",
      },
      {
        name: "의성군",
        search: "경상북도 의성군",
      },
      {
        name: "청도군",
        search: "경상북도 청도군",
      },
      {
        name: "청송군",
        search: "경상북도 청송군",
      },
      {
        name: "칠곡군",
        search: "경상북도 칠곡군",
      },
      {
        name: "포항시",
        search: "경상북도 포항시",
      },
    ],
  },
  경남: {
    name: "경상남도",
    region: [
      {
        name: "전체",
        search: "경상남도",
      },
      {
        name: "거제시",
        search: "경상남도 거제시",
      },
      {
        name: "거창군",
        search: "경상남도 거창군",
      },
      {
        name: "고성군",
        search: "경상남도 고성군",
      },
      {
        name: "김해시",
        search: "경상남도 김해시",
      },
      {
        name: "남해군",
        search: "경상남도 남해군",
      },
      {
        name: "밀양시",
        search: "경상남도 밀양시",
      },
      {
        name: "사천시",
        search: "경상남도 사천시",
      },
      {
        name: "산청군",
        search: "경상남도 산청군",
      },
      {
        name: "양산시",
        search: "경상남도 양산시",
      },
      {
        name: "의령군",
        search: "경상남도 의령군",
      },
      {
        name: "진주시",
        search: "경상남도 진주시",
      },
      {
        name: "창녕군",
        search: "경상남도 창녕군",
      },
      {
        name: "창원시",
        search: "경상남도 창원시",
      },
      {
        name: "통영시",
        search: "경상남도 통영시",
      },
      {
        name: "하동군",
        search: "경상남도 하동군",
      },
      {
        name: "함안군",
        search: "경상남도 함안군",
      },
      {
        name: "함양군",
        search: "경상남도 함양군",
      },
      {
        name: "합천군",
        search: "경상남도 합천군",
      },
    ],
  },
  제주: {
    name: "제주특별자치도",
    region: [
      {
        name: "전체",
        search: "제주특별자치도",
      },
      {
        name: "서귀포시",
        search: "제주특별자치도 서귀포시",
      },
      {
        name: "제주시",
        search: "제주특별자치도 제주시",
      },
    ],
  },
};
