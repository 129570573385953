import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import JobPostRegistrationTemplate from "../../components/templates/JobPostRegistration";
import JobPostDetailTemplate from "../../components/templates/JobPostDetail";
import {
  ApplyMethod,
  applyMethodCoupler,
  JobOfferInfo,
  JobOfferLicenseType,
  JobOfferName,
  JobOfferNotificationOrderInfo,
  JobOfferSalaryType,
  JobOfferStatus,
  JobOfferTaxType,
  JobOfferType,
  JobPostRegistrationForm,
  machineNames,
  softwareNames,
  welfareTagsName,
} from "../../models/Model.JobPost";
import { actions, RootState } from "../../store";
import { JobListType } from "../../store/jobpost/types";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import {
  convertMultiSelectToRequestData,
  convertWorkdayArrToStr,
} from "../../utils/JobPostUtil";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import LoadJobPostHistory from "../../components/organisms/LoadJobPostHistory";
import { log, LogLevel } from "../../utils/LogUtil";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import moment from "moment";
import { JOBOFFER_ALIM_FREE_MAX } from "./../../config.json";

type PageMode = "Write" | "Load" | "Preview" | "AddressSearch";

const JobPostRegistration: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  let loading = false;

  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.user.me);
  const myHistory = useSelector(
    (state: RootState) =>
      state.jobpost.lists[JobListType.OFFER | JobListType.HISTORY]
  );
  const offers = useSelector((state: RootState) => state.jobpost.offers);
  const { workType } = location.state || JobOfferType.INVALID;

  const [mode, setMode] = useState<PageMode>("Write");
  const [form, setForm] = useState<JobPostRegistrationForm>({
    workType: workType,
    title: "",
    workDayOfWeek: new Array(7).fill(false),
    canNegoWorkDayOfWeek: false,
    workTime: "",
    canNegoWorkTime: false,
    salaryType: JobOfferSalaryType.NONE,
    taxType: JobOfferTaxType.NONE,
    salaryRangeLow: "0",
    salaryRangeHigh: "0",
    canNegoSalary: false,
    needLicense: JobOfferLicenseType.INVALID,
    applyMethod: ApplyMethod.None,
    phonePersonInCharge: "",
    message: "",
    pharmacistCnt: "",
    employeeCnt: "",
    welfare: new Array(12).fill(false),
    welfareDetail: "",
    sw: "",
    swEtc: "",
    atc: "",
    machine: new Array(5).fill(false),
    machineEtc: "",
    name: me.workPlaceName,
    phone: me.phone,
    region: me.workPlaceRegion,
    address: me.workPlaceAddress,
    position: me.workPlacePosition,
  });

  // 알림톡 보내기
  const sendAlimTalk = (postId: number) => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_SEND_ALIM",
      "구인구직_공고_작성페이지_알림톡보내기",
      {
        offerId: postId,
      }
    );
    history.replace(`/jobpost/order/alimtalk?id=${postId}`, {
      returnUrl: "/main/jobpost",
    });
  };
  // 알림톡 보내기
  const onPremiumAd = (postId: number) => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_SEND_ALIM",
      "구인구직_공고_작성페이지_프리미엄등록하기",
      {
        offerId: postId,
      }
    );
    history.replace(`/jobpost/order/premium?id=${postId}`, {
      returnUrl: "/main/jobpost",
    });
  };

  const showMap = () => {
    let pharmacyPosition;
    if (form.position) pharmacyPosition = form.position;

    log(
      LogLevel.UI_ACTION,
      "LocalDealJobPostOfferDetail:showMap: ",
      pharmacyPosition
    );

    if (!pharmacyPosition || !pharmacyPosition.x || !pharmacyPosition.y) return;

    if (getGlobal(GlobalKey.OS) == "browser") {
      // history.push(url.substring(3));

      window.open(
        `http://map.naver.com/?dlevel=13&lat=${pharmacyPosition.y}&lng=${pharmacyPosition.x}`,
        "_blank"
      );
      // window.open(`https://map.kakao.com/link/map/${offer.pharmacy.name},${pharmacyPosition.x},${pharmacyPosition.y}`,"_blank");
    } else {
      // window.open(`nmap://route/public?slat=37.4640070&slng=126.9522394&sname=%EC%84%9C%EC%9A%B8%EB%8C%80%ED%95%99%EA%B5%90&dlat=37.5209436&dlng=127.1230074&dname=%EC%98%AC%EB%A6%BC%ED%94%BD%EA%B3%B5%EC%9B%90&appname=com.onjourney.pharmacycafe`,"_system");
      window.open(
        `nmap://place?lat=${pharmacyPosition.y}&lng=${
          pharmacyPosition.x
        }&name=${encodeURIComponent(
          form.name
        )}&appname=com.onjourney.pharmacycafe`,
        "_system"
      );
    }
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_PHARMACIST_MAIN_MAP_VIEW",
      "구인구직_공고_작성페이지_지도_이동",
      {}
    );
  };

  const showMapPath = () => {
    let myPosition;
    if (me && me.homePosition) myPosition = me.homePosition;
    let pharmacyPosition;
    if (form.position) pharmacyPosition = form.position;

    log(
      LogLevel.UI_ACTION,
      "JobPostDetail:showMap: ",
      myPosition,
      pharmacyPosition
    );

    if (
      !myPosition ||
      !myPosition.x ||
      !myPosition.y ||
      !pharmacyPosition ||
      !pharmacyPosition.x ||
      !pharmacyPosition.y
    )
      return;

    if (getGlobal(GlobalKey.OS) == "browser") {
      // history.push(url.substring(3));
      // window.open(`https://map.kakao.com/link/to/우리집,126.9522394,37.4640070`,"_system");
      window.open(
        `https://map.kakao.com/link/to/${encodeURIComponent(form.name)},${
          pharmacyPosition.y
        },${pharmacyPosition.x}`,
        "_blank"
      );
    } else {
      // window.open(`nmap://route/public?slat=37.4640070&slng=126.9522394&sname=%EC%84%9C%EC%9A%B8%EB%8C%80%ED%95%99%EA%B5%90&dlat=37.5209436&dlng=127.1230074&dname=%EC%98%AC%EB%A6%BC%ED%94%BD%EA%B3%B5%EC%9B%90&appname=com.onjourney.pharmacycafe`,"_system");
      window.open(
        `nmap://route/public?slat=${myPosition.y}&slng=${
          myPosition.x
        }&sname=${encodeURIComponent("우리집")}&dlat=${
          pharmacyPosition.y
        }&dlng=${pharmacyPosition.x}&dname=${encodeURIComponent(
          form.name
        )}&appname=com.onjourney.pharmacycafe`,
        "_system"
      );
    }
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_PHARMACIST_MAIN_PATH_VIEW",
      "구인구직_공고_작성페이지_경로_이동",
      {}
    );
  };

  // 공고 불러오기
  const handleReuse = (offer: JobOfferInfo) => {
    log(LogLevel.UI_EXCEPTION, "JobPostRegistration.handleReuse", offer);

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_LOAD_OFFER",
      "구인구직_공고_작성페이지_공고불러오기",
      {
        offerId: offer.id,
        workType: JobOfferName[offer.workType],
      }
    );

    const workDayOfWeek: boolean[] = new Array(7).fill(false);
    if (offer.workDayOfWeek) {
      if (offer.workDayOfWeek.includes("월")) workDayOfWeek[0] = true;
      if (offer.workDayOfWeek.includes("화")) workDayOfWeek[1] = true;
      if (offer.workDayOfWeek.includes("수")) workDayOfWeek[2] = true;
      if (offer.workDayOfWeek.includes("목")) workDayOfWeek[3] = true;
      if (offer.workDayOfWeek.includes("금")) workDayOfWeek[4] = true;
      if (offer.workDayOfWeek.includes("토")) workDayOfWeek[5] = true;
      if (offer.workDayOfWeek.includes("일")) workDayOfWeek[6] = true;
    }

    const machine: boolean[] = new Array(5).fill(false);
    machineNames.forEach((machineName, index) => {
      if (offer.pharmacy.machine.split("▒")[0].includes(machineName))
        machine[index] = true;
    });
    if (offer.pharmacy.machine.split("▒")[1])
      machine[machine.length - 1] = true;

    const welfare: boolean[] = new Array(12).fill(false);
    welfareTagsName.forEach((welfareTag, index) => {
      if (offer.welfare.split("▒")[0].includes(welfareTag))
        welfare[index] = true;
    });

    // log(LogLevel.UI_EXCEPTION, "JobPostRegistration.handleReuse", offer, welfare, welfareDetail);

    setForm({
      workType: offer.workType,
      title: offer.title,
      workDayOfWeek: workDayOfWeek,
      canNegoWorkDayOfWeek: offer.canNegoWorkDayOfWeek,
      workTime: offer.workTime,
      canNegoWorkTime: offer.canNegoWorkTime,
      salaryType: offer.salaryType,
      taxType: offer.taxType,
      salaryRangeLow: (offer.salaryRangeLow * 10000).toLocaleString(),
      salaryRangeHigh: (offer.salaryRangeHigh * 10000).toLocaleString(),
      canNegoSalary: offer.canNegoSalary,
      needLicense: offer.needLicense,
      applyMethod: offer.applyMethod || ApplyMethod.ALL,
      phonePersonInCharge: offer.phonePersonInCharge || "",
      message: offer.message,
      pharmacistCnt: offer.pharmacy.pharmacistCnt.toString() || "0",
      employeeCnt: offer.pharmacy.employeeCnt.toString() || "0",
      welfare: welfare,
      welfareDetail: offer.welfare.split("▒")[1] || "",
      sw: softwareNames.includes(offer.pharmacy.sw)
        ? offer.pharmacy.sw
        : "기타",
      swEtc: softwareNames.includes(offer.pharmacy.sw) ? "" : offer.pharmacy.sw,
      atc: offer.pharmacy.atc,
      machine: machine,
      machineEtc: offer.pharmacy.machine.split("▒")[1] || "",
      name: me.workPlaceName,
      phone: me.phone,
      region: me.workPlaceRegion,
      address: me.workPlaceAddress,
      position: me.workPlacePosition,
    });

    setMode("Write");
  };

  // 공고등록하기
  const registerPost = async (form: JobPostRegistrationForm) => {
    const requestBodyData: JobOfferInfo = {
      title: form.title.trim(),
      status: JobOfferStatus.POSTED,
      pharmacy: {
        name: form.name,
        region: form.region,
        address: form.address,
        phone: me.workPlacePhone,
        position: form.position,
        pharmacistCnt: Number(form.pharmacistCnt),
        employeeCnt: Number(form.employeeCnt),
        sw: form.sw === "기타" ? form.swEtc.trim() : form.sw,
        atc: form.atc,
        machine: convertMultiSelectToRequestData(
          form.machine,
          form.machineEtc,
          machineNames
        ),
      },
      owner: me,
      workType: form.workType,
      workDayOfWeek: convertWorkdayArrToStr(form.workDayOfWeek),
      canNegoWorkDayOfWeek: form.canNegoWorkDayOfWeek,
      workTime: form.workTime.trim(),
      canNegoWorkTime: form.canNegoWorkTime,
      needLicense: form.needLicense,
      applyMethod: form.applyMethod || ApplyMethod.ALL,
      phonePersonInCharge: form.phonePersonInCharge,
      salaryType: form.salaryType,
      taxType: form.taxType,
      salaryRangeLow: Number(form.salaryRangeLow.replace(/,/g, "")) / 10000,
      salaryRangeHigh: Number(form.salaryRangeHigh.replace(/,/g, "")) / 10000,
      canNegoSalary: form.canNegoSalary,
      welfare: convertMultiSelectToRequestData(
        form.welfare,
        form.welfareDetail,
        welfareTagsName
      ),
      message: form.message.trim(),
    };

    try {
      const result = await fetchAPI(
        API.JOBPOST_OFFER_ADD,
        "",
        null,
        requestBodyData,
        getGlobal(GlobalKey.TOKEN)
      );

      if (result && !result.error && result.data) {
        if (result.data.initCandidateCnt < JOBOFFER_ALIM_FREE_MAX) {
          let order: JobOfferNotificationOrderInfo = {
            notiCount: result.data.initCandidateCnt,
            payAmount: 0,
            includeDuplicate: false,
            distance: 0,
            offerId: result.data.id,
            userId: me.id,
          };

          fetchAPI(
            API.JOBPOST_NOTIFICATION_ORDER,
            "",
            null,
            order,
            getGlobal(GlobalKey.TOKEN)
          ).then((result) => {
            if (result && !result.error) {
              fetchAPI(
                API.JOBPOST_NOTIFICATION_SEND,
                "",
                { id: result.data },
                null,
                getGlobal(GlobalKey.TOKEN)
              );
            }
          });
        }

        dispatch(actions.jobpost.updateOffer(result.data));
        dispatch(
          actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.ALL)
        );
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.HISTORY
          )
        );

        if (requestBodyData.status === JobOfferStatus.POSTED)
          dispatch(
            actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY)
          );

        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_CREATE",
          "구인구직_약국_공고_게시",
          {
            ownerId: result.data.ownerId,
            offerId: result.data.id,
            type: JobOfferName[result.data.workType],
            createdAt: moment().format("LLL"),
            applyMethod: applyMethodCoupler[result.data.applyMethod],
          }
        );

        return result.data;
      } else {
        dispatch(actions.jobpost.updateOffer(requestBodyData));
      }
    } catch (err) {
      console.log(err);
      //   log(LogLevel.UI_EXCEPTION, "LocalDealJobPostOfferComposer:exception: ", err);
    }
  };

  const closeMode = () => {
    setMode("Write");
  };

  // 이전 공고 더 불러오기
  const getMorePosts = () => {
    if (loading) return;
    loading = true;
    dispatch(actions.jobpost.loadList(JobListType.OFFER | JobListType.HISTORY));
    loading = false;
  };

  useEffect(() => {
    if (mode === "Load") setGlobal("register-scroll-position", 0);
  }, [mode]);

  useEffect(() => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_CREATE",
      "구인구직_약국_공고_작성_진입",
      {}
    );

    return () => {
      setGlobal("register-scroll-position", 0);
    };
  }, []);

  return (
    <>
      {mode === "Load" && (
        <LoadJobPostHistory
          myHistory={myHistory}
          onClose={closeMode}
          offers={offers}
          onSelect={handleReuse}
          onGetMorePosts={getMorePosts}
        />
      )}
      {mode === "Write" && (
        <JobPostRegistrationTemplate
          mode={mode}
          form={form}
          onChangeForm={setForm}
          onChangeMode={setMode}
          myHistory={myHistory}
        />
      )}
      {mode === "Preview" && (
        <JobPostDetailTemplate
          preview
          me={me}
          form={form}
          onClose={closeMode}
          onSendAlimTalk={sendAlimTalk}
          onPremiumAd={onPremiumAd}
          onRegisterPost={registerPost}
          onMapPath={showMapPath}
          onMap={showMap}
        />
      )}
    </>
  );
};

export default withRouter(JobPostRegistration);
