import React, { Component } from "react";
import "./Attachment.css";
import fileIcon from "./../assets/image/write_fileimage_box.png";
import { getFileSizeString } from "../utils/FileUtil";
import { IonIcon, IonButton } from "@ionic/react";
import { LogLevel, log } from "../utils/LogUtil";
import { Flex, Static } from "./atoms/Layout";
import { COLOR_SYSTEM } from "./design/design-system";
import Icon from "./atoms/Icon";
import Text from "./atoms/Text";
import Button from "./atoms/Button";

const windowAny: any = window;

type Props = {
  key?: string;
  name?: string;
  size?: string;
  url?: string;
  index?: number;
  className?: string;
  onPress?: (index: number) => void;
  onDelete?: () => any;
};

const Attachment: React.SFC<Props> = ({ name, size, url, index, onPress, onDelete, className }) => {
  log(LogLevel.UI_LIFECYCLE, "Attachment: render", name, size, url);
  let deleteButton;
  if (onDelete) {
    deleteButton = (
      <IonButton color="attachment-delete" onClick={onDelete}>
        <IonIcon class="attachment-delete-icon" name="close" />
      </IonButton>
    );
  }
  let containerClass = "attachment-container";
  if (className) containerClass = containerClass + " " + className;
  let displayName = name;
  if (displayName.length > 25) {
    let extPoint = displayName.lastIndexOf(".");
    if (extPoint == -1) {
      displayName = displayName.substring(0, 25) + "...";
    } else {
      let lastName = displayName.substring(displayName.lastIndexOf("."));
      displayName = displayName.substring(0, 25 - lastName.length) + ".." + lastName;
    }
  }

  return (
    // <div className={containerClass} onClick={() =>{if(onPress)onPress(index)}}>
    //   <img className="attachment-icon" src={fileIcon}/>
    //   <div className="attachment-info-container">
    //     <div className="attachment-info-filename">{displayName}</div>
    //     <div className="attachment-info-size">{getFileSizeString(size)}</div>
    //   </div>
    //   {deleteButton}
    // </div>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      customStyle={{
        padding: "16px 8px 16px 16px",
        backgroundColor: COLOR_SYSTEM.get("Gray")[10],
        border: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
        borderRadius: "12px",
        transform: "translateZ(0)",
      }}
      onClick={() => {
        if (onPress) onPress(index);
      }}
    >
      <Icon name="File" width={24} height={24} color={COLOR_SYSTEM.get("Gray")[400]} />
      <Static
        customStyle={{
          flex: 1,
          marginLeft: "12px",
        }}
      >
        <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[700]}>
          {displayName}
        </Text>
        <br />
        <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
          {getFileSizeString(size)}
        </Text>
      </Static>
      {onDelete ? (
        <Button color="Secondary" size="Medium" variant="Ghost" type="Icon" icon="X" onClick={onDelete} />
      ) : (
        <Button color="Secondary" size="Medium" variant="Ghost" type="Icon" icon="Download" />
      )}
    </Flex>
  );
};

export default Attachment;
