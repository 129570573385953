export interface MedicineState {
  medicines: any;
  ingredients: any;
  animalMedicines: any;
}

export enum MedicineActionTypes{
  MEDICINE_REQUEST = '@@medicine/MEDICINE_REQUEST',
  MEDICINE_SUCCESS = '@@medicine/MEDICINE_SUCCESS',
  MEDICINE_ERROR = '@@medicine/MEDICINE_ERROR',
  MEDICINE_GET = '@@medicine/MEDICINE_GET',
  MEDICINE_SET = '@@medicine/MEDICINE_SET',
}
