import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton } from "@ionic/react";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "../../API.json";
import logoIcon from "./../assets/icon/logo.png";
import kakaoIcon from "./../assets/icon/kakao_logo.png";
import RegisterStage0 from "../../components/register/RegisterStage0";
import RegisterStage1 from "../../components/register/RegisterStage1";
import RegisterStage2 from "../../components/register/RegisterStage2";
import RegisterStage2a from "../../components/register/RegisterStage2a";
import RegisterStage3 from "../../components/register/RegisterStage3";
import RegisterStage4 from "../../components/register/RegisterStage4";
import RegisterStage12 from "../../components/register/RegisterStage12";
import SearchAddress from "../../components/SearchAddress";
import {
  UserInfo,
  UserLevel,
  UserSNSType,
  UserState,
  UserWorkType,
  UserWorkTypeName,
} from "../../models/Model.User";
import { log, LogLevel } from "../../utils/LogUtil";
import AnalyticsUtil from "../../utils/AnalyticsUtil";

import { on } from "cluster";

import {
  getGlobal,
  GlobalKey,
  clearGlobal,
  setGlobal,
} from "../../utils/GlobalUtil";
import {
  timerLap,
  timerStop,
  TimerId,
  getDateStringFromToday,
} from "../../utils/TimeUtil";
import { UIPopupType, UIServiceType } from "../../store/ui/types";
import RegisterStage3a from "../../components/register/RegisterStage3a";
import { getDevice } from "../../utils/DeviceUtil";
import { finished } from "stream";
import RegisterStageLicenseCheck from "../../components/templates/Registration/RegisterStageLicenseCheck";
import ChannelTalkService from "../../utils/ChannelTalkService";
import { id } from "date-fns/locale";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {};

type State = {
  user: UserInfo; // 유저 정보
  finished: boolean;
};

const windowAny: any = window;

class LicenseCheck extends Component<Props, State> {
  state = {
    user: null,
    finished: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    log(
      LogLevel.UI_EVENT,
      "LicenseCheck:componentDidMount backbutton register"
    );
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
  }

  goBack = async () => {
    log(LogLevel.UI_ACTION, "LicenseCheck:goBack");
    this.props.history.goBack();
  };

  componentWillUnmount() {
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    log(
      LogLevel.UI_EVENT,
      "LicenseCheck:componentWillUnmount backbutton unregister"
    );

    // document.removeEventListener("backbutton", this.goBack);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
  }

  render() {
    return (
      <div className="common-content common-flex-row common-flex-align-center">
        <RegisterStageLicenseCheck
          onDone={this.finalize}
          onBack={this.goBack}
          onInquiry={this.inquiry}
          licenseCheckOnly={true}
          user={{ ...this.props.me }}
        />
      </div>
    );
  }

  inquiry = () => {
    ChannelTalkService.show();
  };

  finalize = async (user: UserInfo) => {
    this.props.waitingPopup.show();

    if (!this.props.me || !this.props.me.id) {
      return;
    } else {
      user.id = this.props.me.id;
    }

    if (!user.license) return;

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "LICENSE_CHECK_DONE",
      "약사인증_최종신청 ",
      {
        소요시간: timerLap(),
        id: user.id,
      }
    );

    let token = getGlobal(GlobalKey.TOKEN);

    this.props.waitingPopup.setLabel("사용자 업데이트 중...");

    try {
      let newUser = { ...user };
      if (this.props.me.workType == UserWorkType.UNDERGRADUATE) {
        newUser.state = UserState.LICENSE_CHECK;
      } else if (this.props.me.level == UserLevel.DENIED_USER) {
        newUser.state = UserState.LICENSE_CHECK;
        newUser.level = UserLevel.WAITING_USER;
      }

      this.props.waitingPopup.setLabel("사용자 면허 확인 중...");

      // license check
      let result = await fetchAPI(
        API.USER_FIELD_VALIDATE,
        "license",
        null,
        { value: user.license },
        token
      );

      if (result && result.error) {
        newUser.memo = `+add:[${getDateStringFromToday()}] 면허번호 중복확인 필요(${
          newUser.license
        })`;
        newUser.managerMessage = "이미 가입된 면허번호입니다.";
      }

      // license image upload
      if (newUser.licenseImageBase64) {
        this.props.waitingPopup.setLabel("라이선스 업로드 중...");
        log(LogLevel.UI_ACTION, "Register 라이선스 이미지:");

        let data: any = { path: "license" };
        data.base64 = newUser.licenseImageBase64;
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            log(LogLevel.UI_ACTION, "onDone upload : ", result.file);
            newUser.licenseUrl = url;
            delete newUser.licenseImageBase64;
          } else {
            log(LogLevel.UI_EXCEPTION, "onDone failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_EXCEPTION, "onDone : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }

      this.props.waitingPopup.setLabel("사용자 업데이트 중...");
      // update user info
      result = await fetchAPI(API.USER_ME_UPDATE, "", null, newUser, token);
      if (result && !result.error) {
        this.props.loginUser();

        this.props.waitingPopup.hide();
        this.props.confirmPopup.show({
          title: "약사인증신청이 완료 되었습니다.",
          body: (
            <div className="common-container">
              약사인증이 완료되면 카카오톡과 <br />앱 알림으로 안내드립니다.{" "}
              <br />
              <br />
              제출하신 면허증/학생증은 회원가입 승인 즉시 폐기됩니다.
            </div>
          ),
          onDone: this.onFinalized,
          backDropDismiss: false,
          doneText: "네, 알겠습니다",
        });
      } else {
        log(LogLevel.NONE, "onDone failed", result);
      }
    } catch (error) {
      log(LogLevel.NONE, "onDone : failed", error);
      // this.props.navigation.push("AuthRegister");
    }
  };

  onFinalized = (text: string) => {
    log(LogLevel.NONE, "onFinalized popup");
    setTimeout(() => {
      this.goBack();
    }, 300);
  };
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  setUserToken: (token: string) => actions.user.setToken(token),
  loginUser: () => actions.user.login(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LicenseCheck)
);
