import React, { Component } from 'react';
import './NumberSelector.css';
import fileIcon from './../assets/image/write_fileimage_box.png'
import {getFileSizeString} from '../utils/FileUtil'
import { IonIcon, IonButton } from '@ionic/react';
import { LogLevel, log } from '../utils/LogUtil';

const windowAny : any = window;

type Props = {
  value: number;
  prefix?: string;
  postfix?: string;
  step?: number;
  onChange: (value) => void;
  min?: number;
  max?: number;
  style?: any;

};

const NumberSelector: React.SFC<Props> = ({value, prefix, postfix, step, min, max, style, onChange}) => {
  log(LogLevel.UI_LIFECYCLE, "NumberSelector: render", value, prefix, postfix, step, min, max, onChange);
  if(!prefix) prefix = "";
  if(!postfix) postfix = "";
  if(!step) step = 1;
  if(!min && min!=0) min = 0;

  return (
  <div className="number-selector-container" style={style}>
    <IonIcon class="number-selector-icon-remove" name="remove"/>
    <div className={"number-selector-info common-nowrap" + (value?"":" number-selector-info-disabled")}>
      {prefix + (value?value.toString():"0") + postfix}
    </div>
    <IonIcon class="number-selector-icon-add" name="add"/>
    <div className="number-selector-remove" onClick={() => {
      log(LogLevel.UI_ACTION, "NumberSelector: onClick remove");
      let newValue = value-step;
      if(newValue >= min)
        onChange(newValue)
    }}/>
    <div className="number-selector-add" onClick={() => {
      log(LogLevel.UI_ACTION, "NumberSelector: onClick add");
      let newValue = value+step;
      if(!max || newValue <= max)
        onChange(newValue)
    }}/>
  </div>
  );
}

export default NumberSelector;