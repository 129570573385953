import React, { Component } from "react";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import { BoardAttribute } from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { loadImageBase64, loadImageUrlBase64 } from "../../../utils/ImageUtil";
import ImageViewNSelect from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import {
  BizUserInfo,
  ORDER_BASIC12_TEMPLATE,
  ORDER_BASIC3_TEMPLATE,
  ORDER_PREMIUM12_TEMPLATE,
  ORDER_PREMIUM3_TEMPLATE,
  ORDER_PRO12_TEMPLATE,
  ORDER_PRO3_TEMPLATE,
  ORDER_TEMPLATE,
  OrderInfo,
} from "../../../models/Mode.Biz";
import { Flex } from "../../../components/atoms/Layout";

const AdminAPI = {
  PARTNER_ORDER_ADD: {
    method: "POST",
    path: "/admin/allinone/order",
    contentType: "application/json",
  },
  PARTNER_ORDER_UPDATE: {
    method: "PUT",
    path: "/admin/allinone/order",
    contentType: "application/json",
  },
  PARTNER_ORDER_DELETE: {
    method: "DELETE",
    path: "/admin/allinone/order/",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone?: () => void;
    onCancel?: () => void;
    showPartners?: () => void;
    order?: OrderInfo;
  };

type State = OrderInfo & {
  edited?: boolean;
};

class AllInOneOrderEdit extends Component<Props, State> {
  state: State = { ...ORDER_TEMPLATE, edited: false };
  constructor(props: Props) {
    super(props);
    if (props.order) {
      let order = { ...props.order };
      if (order.paidAt)
        order.paidAt = getDateStringFromToday({
          date: order.paidAt,
        });
      if (order.details && order.details.length) {
        if (order.details[0].startAt)
          order.details[0].startAt = getDateStringFromToday({
            date: order.details[0].startAt,
          });
        if (order.details[0].endAt)
          order.details[0].endAt = getDateStringFromToday({
            date: order.details[0].endAt,
          });
      }
      log(
        LogLevel.UI_DATA_LOAD,
        "AllInOneOrderEdit: constructor",
        this.props.order
      );
      this.state = { ...order, edited: false };
    }
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.order &&
      (!prevProps ||
        !prevProps.order ||
        this.props.order.id != prevProps.order.id)
    ) {
      log(
        LogLevel.UI_DATA_LOAD,
        "AllInOneOrderEdit: componentDidUpdate",
        this.props.order
      );
      this.setState({
        ...this.props.order,

        edited: false,
      });
    }
  }

  onSelectPartner = (user: BizUserInfo) => {
    console.log("AllInOneOrderEdit:onSelectPartner", user);
    this.setState({ bizUserId: user.id, bizUserInfo: user });
  };

  onBack = () => {
    if (this.state.edited) {
      this.props.confirmPopup.show({
        title: (
          <div className="common-container">
            <span className="common-bold">수정을 그만두시겠습니까?</span>
          </div>
        ),
        body: <div className="common-container">수정사항이 있습니다.</div>,
        onDone: this.props.onCancel,
        doneText: "네",
      });
    } else {
      this.props.onCancel();
    }
  };

  onDelete = () => {
    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">삭제하시겠습니까?</span>
        </div>
      ),
      body: <div className="common-container">복구가 안됩니다.</div>,
      onDone: this.doDelete,
      doneText: "네",
    });
  };

  doDelete = async () => {
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("약사 파트너 주문 삭제 시작...");

    let order = { ...this.state };
    order.details[0].code = order.productCode;
    order.details[0].title = order.productTitle;
    order.details[0].price = order.payAmount;

    let api = AdminAPI.PARTNER_ORDER_DELETE;

    let result = await fetchAPI(
      api,
      `${this.state.id}`,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(
        LogLevel.UI_EXCEPTION,
        "AllInOnePartnerManageProfile Update failed",
        result
      );
      this.props.progressPopup.hide();
      this.props.toastPopup.show("사용자 정보 저장이 실패했습니다.");
    }

    this.props.progressPopup.hide();
    this.props.onDone();
  };
  setPreset = (preset: OrderInfo) => {
    let order = { ...preset };
    if (
      order.details &&
      order.details.length &&
      this.props.order.details &&
      this.props.order.details.length
    ) {
      order.details[0].id = this.props.order.details[0].id;
    }

    this.setState(order);
  };

  onDone = async () => {
    if (!this.state.edited) return;
    log(
      LogLevel.UI_ACTION,
      "AllInOnePartnerManageProfile onDone start",
      this.state
    );

    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("약사 파트너 주문 저장 시작...");

    let order = { ...this.state };
    order.details[0].code = order.productCode;
    order.details[0].title = order.productTitle;
    order.details[0].price = order.payAmount;

    let api = AdminAPI.PARTNER_ORDER_UPDATE;
    if (!this.props.order || !this.props.order.id)
      api = AdminAPI.PARTNER_ORDER_ADD;
    let result = await fetchAPI(
      api,
      "",
      null,
      order,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(
        LogLevel.UI_EXCEPTION,
        "AllInOnePartnerManageProfile Update failed",
        result
      );
      this.props.progressPopup.hide();
      this.props.toastPopup.show("사용자 정보 저장이 실패했습니다.");
    }

    this.props.progressPopup.hide();
    this.props.onDone();
  };

  render() {
    console.log("AllInOneOrderEdit:render ", this.state);
    if (this.props.order) {
      return (
        <div className="admin-container">
          {this.props.onCancel && (
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start" class="common-container">
                  <IonButton
                    class="common-header-toolbar-button"
                    onClick={this.onBack}
                  >
                    <IonIcon name="arrow-back" mode="ios" />
                  </IonButton>
                  <IonLabel class="common-header-title common-flex-grow">
                    주문정보
                  </IonLabel>
                  <Button
                    disabled={!this.state.edited}
                    onClick={this.onDone}
                    size={"Small"}
                    type={"Text"}
                    variant={"Contained"}
                    color={"Primary"}
                    style={{ marginRight: "10px" }}
                  >
                    저장
                  </Button>
                  {this.state.id && (
                    <Button
                      onClick={this.onDelete}
                      size={"Small"}
                      type={"Text"}
                      variant={"Contained"}
                      color={"Negative"}
                    >
                      삭제
                    </Button>
                  )}
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          )}
          <Flex gap="4px" customStyle={{ flexWrap: "wrap" }}>
            <Button
              onClick={() => {
                this.setPreset(ORDER_BASIC3_TEMPLATE);
              }}
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Primary"}
            >
              Basic3
            </Button>
            <Button
              onClick={() => {
                this.setPreset(ORDER_BASIC12_TEMPLATE);
              }}
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Primary"}
            >
              Basic12
            </Button>
            <Button
              onClick={() => {
                this.setPreset(ORDER_PRO3_TEMPLATE);
              }}
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Tertiary"}
            >
              Pro3
            </Button>
            <Button
              onClick={() => {
                this.setPreset(ORDER_PRO12_TEMPLATE);
              }}
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Tertiary"}
            >
              Pro12
            </Button>
            <Button
              onClick={() => {
                this.setPreset(ORDER_PREMIUM3_TEMPLATE);
              }}
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Quaternary"}
            >
              Primium3
            </Button>
            <Button
              onClick={() => {
                this.setPreset(ORDER_PREMIUM12_TEMPLATE);
              }}
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Quaternary"}
            >
              Primium12
            </Button>
          </Flex>

          <div className="admin-title">ID : {this.props.order.id}</div>

          <div className="admin-item-container">
            <div className="admin-title">법인 : {}</div>
            <IonInput
              class="admin-input"
              placeholder="ID"
              value={this.state.bizUserId}
              onIonChange={(e) =>
                this.setState({
                  bizUserId: e.detail.value,
                  edited: true,
                })
              }
              readonly
              onClick={this.props.showPartners}
            />
          </div>
          <div className="admin-title">
            회사이름 :{" "}
            {this.state.bizUserInfo ? this.state.bizUserInfo.companyName : ""}{" "}
          </div>
          <div className="admin-title">
            회사프로필 :{" "}
            {this.state.bizUserInfo
              ? this.state.bizUserInfo.companyProfileName
              : ""}{" "}
          </div>

          <div className="admin-item-container">
            <div className="admin-title">상품코드 : </div>
            <IonInput
              class="admin-input"
              placeholder="회사전화번호"
              value={this.state.productCode}
              onIonChange={(e) =>
                this.setState({
                  productCode: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>

          <div className="common-container-row-wrap  admin-item-container">
            <div className="admin-title">상품명 : </div>
            <IonInput
              class="admin-input"
              placeholder="회사전화번호"
              value={this.state.productTitle}
              onIonChange={(e) =>
                this.setState({
                  productTitle: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>

          <div className="admin-item-container">
            <div className="admin-title">결제액 : </div>
            <IonInput
              type="number"
              class="admin-input"
              placeholder="담당자이름"
              value={this.state.payAmount.toString()}
              onIonChange={(e) =>
                this.setState({
                  payAmount: Number(e.detail.value),
                  edited: true,
                })
              }
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">결제일 : </div>
            <IonInput
              class="admin-input"
              placeholder="담당자전화번호"
              value={this.state.paidAt}
              onIonChange={(e) =>
                this.setState({
                  paidAt: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">결제방식 : </div>
            <IonInput
              class="admin-input"
              placeholder="담당자전화번호"
              value={this.state.payMethod}
              onIonChange={(e) =>
                this.setState({
                  payMethod: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">시작일 : </div>
            <IonInput
              class="admin-input"
              placeholder="담당자전화번호"
              value={this.state.details[0].startAt}
              onIonChange={(e) => {
                const details = [
                  { ...this.state.details[0], startAt: e.detail.value },
                ];
                console.log(
                  "AllInOneOrderEdit:onChange startAt ",
                  this.state,
                  details
                );
                this.setState({
                  details,
                  edited: true,
                });
              }}
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">종료일 : </div>
            <IonInput
              class="admin-input"
              placeholder="담당자전화번호"
              value={this.state.details[0].endAt}
              onIonChange={(e) => {
                const details = [
                  { ...this.state.details[0], endAt: e.detail.value },
                ];
                console.log(
                  "AllInOneOrderEdit:onChange endAt ",
                  this.state,
                  details
                );

                this.setState({
                  details,
                  edited: true,
                });
              }}
            />
          </div>
          <div className="admin-title">
            payMerchantId : {this.state.payMerchantId}
          </div>
          <div className="admin-title">
            payApplyNumber : {this.state.payApplyNumber}
          </div>
          <div className="admin-title">
            payRecieptUrl : {this.state.payRecieptUrl}
          </div>
          <div className="admin-title">payUid : {this.state.payUid}</div>
          <div className="admin-title">
            payCardName : {this.state.payCardName}
          </div>
          <div className="admin-title">createdAt : {this.state.createdAt}</div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AllInOneOrderEdit);
