import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import {log, LogLevel} from '../../utils/LogUtil'
import InformationContract from '../../component/organism/Information/InformationContract';
import InformationPrivacy from '../../component/organism/Information/InformationPrivacy';
import InformationCompany from '../../component/organism/Information/InformationCompany';
import Header from '../../component/molecule/Header';
import Body from '../../component/molecule/Body';

type Props = RouteComponentProps

class InformationViewer extends Component<Props> {
  type = "contract";
  preference = {
    "contract" : {
      title:"이용약관",
      component: InformationContract, 
    },
    "privacy" : {
      title:"개인정보처리방침",
      component: InformationPrivacy,
    },
    "company" : {
      title:"사업자 정보",
      component: InformationCompany,
    }
  }
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "InformationViewer constructor", props, props.match);
    let params:any = props.match.params;
    this.type = params.type;
  }

  onBack = () => {
    this.props.history.goBack();
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "InformationViewer:render", this.type);
    let title = this.preference[this.type].title;
    return (
      <>
        <Header needBack={true} name={title} goBack={this.onBack}/>
        <Body scrollable={true}>
          {React.createElement(this.preference[this.type].component)}
        </Body>
      </>

    );
  }
}

export default withRouter(InformationViewer);