// type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
// }
import React, { Component } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../../store";
import { useCallback, useEffect, useState } from "react";
import { LogLevel, log } from "../../utils/LogUtil";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { UserPremiumPurchasedInfo } from "../../models/Model.User.Premium";
import PremiumUserPurchaseHistory from "../../components/templates/PremiumUserPurchaseHistory";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import { GlobalKey, getGlobal } from "../../utils/GlobalUtil";
import ChannelTalkService from "../../utils/ChannelTalkService";

// type State = {
// }

// const windowAny : any = window;

// class PremiumUserPurchaseHistoryPage extends Component<Props, State> {
//   constructor(props: Props) {
//     super(props);
//     this.state ={

//     }
//   }

//   scrollRef:ScrollAction = null;

//   componentDidMount() {
//     this.props.backKeyControl.setListener(() => {
//       this.goBack();
//       return false;
//     });

//     this.props.refreshOrders();
//     this.props.loadOrders();
//   }

//   componentWillUnmount() {
//     // this.props.backKeyControl.popListener();
//   }

//   componentDidUpdate(prevProps, prevState) {

//   }

//   goBack = () => {
//     log(LogLevel.UI_ACTION, "PremiumUserPurchaseHistoryPage:goBack");
//     if(ChannelTalkService.isShown){
//       ChannelTalkService.hide();
//       return;
//     }
//     this.props.history.goBack();
//   }

//   onClickDetail = (order:OrderInfo) => {
//     this.props.history.push("/sale/myorder?id="+order.id);
//   }

//   onClickButtonCancel = (order:OrderInfo) => {
//     this.props.history.push("/sale/cancel?id="+order.id);

//   }

//   onClickButtonReturn = (order:OrderInfo) => {
//     this.props.history.push("/sale/return?id="+order.id);
//   }

//   onClickButtonInquiry = (order:OrderInfo) => {
//     if(!ChannelTalkService.isShown)
//       ChannelTalkService.show();
//   }

//   onClickButtonTracking = (order:OrderInfo) => {
//     log(LogLevel.UI_LIFECYCLE, "PremiumUserPurchaseHistoryPage.onClickButtonTracking", order);
//     if(order){
//       jumpToDeliveryTracker(order.deliveryCompany, order.deliveryId);
//     }
//   }

//   render() {
//     log(LogLevel.UI_LIFECYCLE, "PremiumUserPurchaseHistoryPage.render", this.props.orders);

//     return (
//       <>
//         <Header goBack={this.goBack} name="주문 내역"/>
//         <Body scrollable={false}>
//           <Scroll ref={ref => this.scrollRef=ref} onLoadMore={this.props.loadOrders} needMoveToTop={true} autoScrollName="MyOrders">
//             <SaleOrderList
//               className="common-block"
//               orderList={this.props.orderList.orders}
//               orders={this.props.orders}
//               loading={this.props.orderList.loading}
//               onClickDetail={this.onClickDetail}
//               onClickButtonCancel={this.onClickButtonCancel}
//               onClickButtonReturn={this.onClickButtonReturn}
//               onClickButtonInquiry={this.onClickButtonInquiry}
//               onClickButtonTracking={this.onClickButtonTracking}
//             />
//           </Scroll>
//         </Body>
//       </>
//     );
//   }
// }

// const mapStateToProps = (state: RootState) => ({
//   me: state.user.me,
//   confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
//   waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
//   orders: state.sale.orders,
//   orderList: state.sale.orderList,
//   links: state.board.links,
//   backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
// });

// const mapDispatchToProps = {
//   refreshOrders: () => actions.sale.refreshOrders(),
//   loadOrders: () => actions.sale.getOrders(0),
// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PremiumUserPurchaseHistoryPage));

const PremiumUserPurchaseHistoryPage: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.user.me);

  const [purchaseList, setPurchaseList] = useState<UserPremiumPurchasedInfo[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(() => {
    log(
      LogLevel.UI_ACTION,
      "PremiumUserPurchaseHistoryPage:useEffect mount",
      me
    );

    const fetchData = async () => {
      try {
        await fetchAPI(
          API.USER_PREMIUM_PURCHASED_LIST,
          "",
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        )
          .then((res) => {
            if (res && !res.error && res.data) {
              setPurchaseList(res.data);
            }
            setFinished(true);
            setLoading(false);
          })
          .catch((err) => {
            setFinished(true);
            setLoading(false);
          });
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PREMIUM_PAGE",
          "유료회원_후원내역_진입",
          {}
        );
      } catch (error) {
        log(
          LogLevel.UI_EXCEPTION,
          "PremiumUserPurchaseHistoryPage:exception",
          error.message,
          error.stack
        );
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "UI_Rendering_Error",
          "에러",
          {
            page: "PremiumUserPurchaseHistoryPage",
            errorMessage: error,
          }
        );
      }
    };

    fetchData();
  }, []);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onViewReceipt = (purchase: UserPremiumPurchasedInfo) => {
    window.open(
      purchase.payRecieptUrl,
      getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
    );
  };
  const onInquiry = (purchase: UserPremiumPurchasedInfo) => {
    ChannelTalkService.show();
  };
  try {
    return (
      <PremiumUserPurchaseHistory
        onGoBack={goBack}
        loading={loading}
        purchaseList={purchaseList}
        onViewReceipt={onViewReceipt}
        onInquiry={onInquiry}
      />
    );
  } catch (error) {
    log(
      LogLevel.UI_EXCEPTION,
      "PremiumUserPurchaseHistoryPage:exception",
      error.message,
      error.stack
    );
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PremiumUserPurchaseHistoryPage",
      errorMessage: error,
    });

    return null;
  }
};

export default withRouter(PremiumUserPurchaseHistoryPage);
