import React from "react";
import { JobOfferInfo } from "../../../models/Model.JobPost";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import { FinishPostPopupFooter, FinishPostPopupMain } from "./style";
import { RouteComponentProps, withRouter } from "react-router";

interface FinishJobPostPopupProps extends PopupProps {
  passed: number[];
  setPassed: React.Dispatch<React.SetStateAction<number[]>>;
  post: JobOfferInfo;
}

export type Page = "Ask" | "Success" | "List" | "Finish";

const FinishJobPostPopup: React.FC<RouteComponentProps & FinishJobPostPopupProps> = ({ history, post, onClose }) => {
  return (
    <Popup onClose={onClose}>
      <FinishPostPopupMain page={"Ask"}>
        <Text element="h4" textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          공고를 종료할까요?
        </Text>
      </FinishPostPopupMain>
      <FinishPostPopupFooter>
        <Button color="Secondary" size="Large" type="Text" variant="Tinted" onClick={onClose}>
          취소
        </Button>
        <Button
          color="Negative"
          size="Large"
          type="Text"
          variant="Tinted"
          onClick={() => {
            history.push("/jobpost/success/survey", { postId: post.id });
          }}
        >
          종료하기
        </Button>
      </FinishPostPopupFooter>
    </Popup>
  );
};

export default withRouter(FinishJobPostPopup);
