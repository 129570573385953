import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import Switch from "../../atoms/Switch/Switch";
import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import DistanceBottomSheetModal from "../../organisms/DistanceBottomSheetModal/DistanceBottomSheetModal";
import {
  getPayString,
  JobOfferInfo,
  JobOfferName,
  JobOfferNotificationOrderInfo,
} from "../../../models/Model.JobPost";
import { getDistanceString } from "../../../utils/LocationUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import Popup from "../../molecules/Popup";
import ReactHtmlParser from "react-html-parser";
import StringUtil from "../../../utils/StringUtil";
import { JOBOFFER_ALIM_PRICE, JOBOFFER_ALIM_MIN } from "../../../config.json";
import { CouponInfo, OrderInfo } from "../../../models/Mode.Biz";
import { Flex, Static } from "../../atoms/Layout";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import { start } from "repl";
import { getDateStringFromToday } from "../../../utils/TimeUtil";

export interface JobPostPremiumOrderProps {
  /** 알림톡 보내는 구인공고 */
  offer: JobOfferInfo;
  loading: boolean;
  onBack: () => void;
  onOrder: (order: OrderInfo) => void;
  payDone: boolean;
  onFinalize: () => void;
  coupon: CouponInfo;
}

interface JobPostPremiumOrderNumberInputProps {
  error?: boolean;
}

const JobPostPremiumOrderWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Main = styled.main`
  height: calc(100% - 108px);
  padding: 60px 0px 24px 0px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
`;

const SelectorList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  height: calc(100% - 100px);
  overflow-y: scroll;

  & > * + * {
    margin-top: 24px;
  }
`;

const SelectorItem = styled.li<{ selected: boolean }>`
  padding: 0px 20px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > * + * {
    margin-left: 16px;
  }

  img {
    width: 32px;
  }

  ${({ selected }) =>
    selected &&
    `
    border: 2px solid ${COLOR_SYSTEM.get("Skyblue")[400]};
    background-color: ${COLOR_SYSTEM.get("Skyblue")[10]};
  `}
`;

const Body = styled.div`
  margin-top: 24px;
  padding: 0px 24px;
  height: 100%;
  .error-text {
    margin-top: 6px;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  margin-bottom: 16px;
  align-items: center;
  .grow {
    flex-grow: 1;
  }
`;

const Footer = styled.footer`
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  left: 0;
`;

const FooterButton = styled.div`
  display: flex;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};

  & > * + * {
    margin-left: 20px;
  }

  button:last-child {
    flex: 1;
  }
`;
const PreviewBody = styled.div`
  overflow: scroll;
  max-height: 400px;
  padding-bottom: 88px;
`;
const DonePopupBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 88px;

  .title {
    margin-top: 25px;
  }

  .body {
    margin-top: 12px;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const ModalFooter = styled.footer`
  position: absolute;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  bottom: 0;
  width: 100%;
  padding: 20px;
  left: 0;

  button {
    width: 100%;
  }
`;

const JOBPOST_PREMIUM_DAY_PRICE = 20000;

const JOBPOST_PREMIUM_PRODUCTS: ProductInfo[] = [
  {
    name: "3일",
    days: 3,
    discount: 0,
  },
  {
    name: "5일",
    days: 5,
    discount: 5,
  },
  {
    name: "7일",
    days: 7,
    discount: 10,
  },
  {
    name: "10일",
    days: 10,
    discount: 15,
  },
];

interface ProductInfo {
  name: string;
  days: number;
  discount: number;
  endAt?: string;
}

const JobPostPremiumOrder = ({
  offer,
  loading,
  payDone,
  coupon,
  onBack,
  onOrder,
  onFinalize,
}: JobPostPremiumOrderProps) => {
  const [product, setProduct] = useState<ProductInfo>(null);
  const [useCoupon, setUseCoupon] = useState<boolean>(true);
  const inputRef = useRef(null);

  const products: ProductInfo[] = useMemo<ProductInfo[]>(() => {
    return JOBPOST_PREMIUM_PRODUCTS.map((item: ProductInfo) => {
      item.endAt = getDateStringFromToday({
        date: offer && offer.order && offer.order[0].endAt,
        days: item.days,
        withoutYear: true,
        separator: "/",
      });
      return item;
    });
  }, [offer]);

  const payAmount = useMemo(() => {
    let payAmount = 0;
    if (product) {
      payAmount =
        (product.days * JOBPOST_PREMIUM_DAY_PRICE * (100 - product.discount)) /
          100 -
        (coupon && useCoupon ? coupon.priceDiscount : 0);
      if (payAmount < 0) {
        payAmount = 0;
      }
    }
    return payAmount;
  }, [product, coupon, useCoupon]);

  const onDoneClick = useCallback(() => {
    if (!product) return;

    const productCode = `PHARMACY.${product.days}`;
    const productTitle = `약국 프리미엄 ${product.days}일`;
    let order: OrderInfo = {
      contentType: "jobpost",
      contentId: offer.id,
      productCode: "PHARMACY",
      productTitle,
      payAmount: payAmount,
      details: [],
    };
    let startAt = getDateStringFromToday();
    let endAt = getDateStringFromToday({ days: product.days }) + " 23:59:59";

    if (offer && offer.order && offer.order[0].endAt) {
      startAt = getDateStringFromToday({ date: offer.order[0].endAt, days: 1 });
      endAt =
        getDateStringFromToday({
          date: offer.order[0].endAt,
          days: product.days,
        }) + " 23:59:59";
    }

    order.details.push({
      type: 0,
      code: productCode,
      title: productTitle,
      price:
        (product.days * JOBPOST_PREMIUM_DAY_PRICE * (100 - product.discount)) /
        100,
      contentType: "jobpost",
      contentId: offer.id,
      startAt,
      endAt,
    });

    if (coupon && useCoupon) {
      order.details.push({
        type: 2,
        code: coupon.name,
        price: -coupon.priceDiscount,
        couponId: coupon.id,
        contentType: "jobpost",
        contentId: offer.id,
      });
    }

    onOrder(order);
  }, [product, useCoupon]);

  // log(LogLevel.UI_LIFECYCLE, "JobPostPremiumOrder:template draw", doneCnt);

  return (
    <JobPostPremiumOrderWrapper>
      <header
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <TopAppBar
          title={"Premium 광고" + (offer.order ? " 연장" : "") + "하기"}
          titleType="H4"
          leftButtons={[
            <Button
              color="Tertiary"
              icon="X"
              onClick={() => {
                console.log("onBack");
                onBack();
              }}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
        />
      </header>

      <Main>
        <Body
          onClick={() => {
            if (inputRef.current) inputRef.current.blur();
          }}
        >
          <Flex
            direction="column"
            gap="14px"
            customStyle={{ marginTop: "40px" }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text textType="H1" color={COLOR_SYSTEM.get("Gray")[800]}>
                더 많은 구직약사에게 <br />
                공고를 노출하세요.
              </Text>
              <Icon name="PremiumIcon" size={72} />
            </Flex>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[900]}>
              구인구직 첫화면인 “Premium” 탭의
              <br />
              Pharmacy 항목으로 노출이 되어요.
            </Text>
          </Flex>
          <SelectorList>
            {products.map((item, index) => (
              <SelectorItem
                key={index}
                onClick={() => {
                  setProduct(item);
                }}
                selected={item == product}
              >
                <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[800]}>
                  {item.name}
                  {item.endAt && (
                    <Text
                      textType="Body2"
                      color={COLOR_SYSTEM.get("Gray")[700]}
                    >
                      &nbsp;(~{item.endAt})
                    </Text>
                  )}
                </Text>
                <Flex alignItems="center" gap="12px">
                  <Flex direction="column" alignItems="flex-end">
                    {item.discount > 0 && (
                      <Text
                        textType="CaptionBold"
                        color={COLOR_SYSTEM.get("Red")[400]}
                        style={{}}
                      >
                        {item.discount}% 할인
                      </Text>
                    )}
                    <Flex alignItems="center" gap="8px">
                      {item.discount > 0 && (
                        <Text
                          textType="Body2"
                          color={COLOR_SYSTEM.get("Gray")[600]}
                          style={{
                            /*cancel line style*/ textDecoration:
                              "line-through",
                          }}
                        >
                          {(
                            item.days * JOBPOST_PREMIUM_DAY_PRICE
                          ).toLocaleString()}
                          원
                        </Text>
                      )}
                      <Text
                        textType="InputLarge"
                        color={COLOR_SYSTEM.get("Gray")[800]}
                      >
                        {(
                          (item.days *
                            JOBPOST_PREMIUM_DAY_PRICE *
                            (100 - item.discount)) /
                          100
                        ).toLocaleString()}
                        원
                      </Text>
                    </Flex>
                  </Flex>
                  <Icon
                    name="Check Circle Filled"
                    size={24}
                    color={
                      item == product
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </Flex>
              </SelectorItem>
            ))}
          </SelectorList>
        </Body>
      </Main>
      <Footer>
        {coupon && (
          <Flex
            justifyContent="space-between"
            customStyle={{ padding: "14px 0px" }}
            onClick={() => setUseCoupon(!useCoupon)}
          >
            <CheckBox checked={useCoupon} onClick={() => {}} size={"Small"}>
              쿠폰 적용
            </CheckBox>
            <Text
              textType={"Body1SemiBold"}
              color={COLOR_SYSTEM.get("Gray")[useCoupon ? 900 : 400]}
            >
              -{coupon.priceDiscount.toLocaleString()}원
            </Text>
          </Flex>
        )}
        <PriceInfo>
          <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[700]}>
            총 결제금액 &nbsp;
          </Text>
          <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[900]}>
            {payAmount.toLocaleString()}원
          </Text>
        </PriceInfo>
        <FooterButton>
          <Button
            color="Primary"
            onClick={onDoneClick}
            size="Large"
            type="Text"
            variant="Contained"
            disabled={!product || loading}
            disabledColor={!product || loading}
          >
            {product ? "프리미엄 광고하기" : "결제하기"}
          </Button>
        </FooterButton>
      </Footer>
      {payDone && (
        <Popup
          onClose={() => {
            onFinalize();
          }}
        >
          <DonePopupBody>
            <Icon
              size={60}
              name={"Check Circle"}
              color={COLOR_SYSTEM.get("Skyblue")[300]}
            />
            <div className="title">
              <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
                결제가 완료됐어요
              </Text>
            </div>
          </DonePopupBody>
          <ModalFooter>
            <Button
              color="Secondary"
              onClick={() => {
                onFinalize();
              }}
              size="Large"
              type="Text"
              variant="Tinted"
            >
              {/* <Button color="Secondary" onClick={()=>{setShowPreview(false);}} size="Large" type="Text" variant="Tinted"> */}
              닫기
            </Button>
          </ModalFooter>
        </Popup>
      )}
    </JobPostPremiumOrderWrapper>
  );
};

export default JobPostPremiumOrder;
