import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { OrderInfo, OrderStatus } from "../models/Model.Sale";
import { log, LogLevel } from "../utils/LogUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import Header from "../component/molecule/Header";
import Body from "../component/molecule/Body";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import SaleOrderItemBody from "../component/organism/SaleOrderItemBody";
import Input from "../component/atom/Input";
import TextRectangleButton from "../component/cell/TextRectangleButton";
import Footer from "../component/molecule/Footer";
import Text from "../component/atom/Text";
import COLOR from "../component/_design/color";
import Icon from "../component/atom/Icon";
import { fetchAPI } from "../utils/APIUtil";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import * as API from "./../API.json";
import { Static } from "../components/atoms/Layout";

const queryString = require("query-string");

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    orderId?: number;
  };

type State = {
  step: number;
  count: string;
  reason: string;
};

const windowAny: any = window;

class ProductSaleMyOrderReturn extends Component<Props, State> {
  id: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      step: 0,
      count: "",
      reason: "",
    };

    let qs = queryString.parse(this.props.location.search);
    let newId = 0;
    try {
      newId = parseInt(qs.id);
    } catch (err) {
      newId = 0;
    }
    if (!newId && this.props.orderId) {
      newId = this.props.orderId;
    }

    log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID ", newId);
    if (this.id != newId) {
      log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID DETECTED", newId);
      this.id = newId;
      this.props.loadOrder(this.id);

      if (this.props.orders) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ORDER_DETAIL_START",
          "주문상세 시작",
          { 주문id: this.id }
        );
      }
    }
  }

  componentDidMount() {
    this.props.backKeyControl.setListener(() => {
      this.goBack();
      return false;
    });

    if (!this.props.orders[this.id]) {
      this.props.waitingPopup.show();
    } else {
      this.setState({
        count: (this.props.orders[this.id].amount || 0).toString(),
      });
    }
  }

  componentWillUnmount() {
    // this.props.backKeyControl.popListener();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.orders[this.id] &&
      this.props.orders[this.id] &&
      this.props.waitingPopup.isShown()
    ) {
      this.props.waitingPopup.hide();
      this.setState({
        count: this.props.orders[this.id].amount
          ? this.props.orders[this.id].amount.toString()
          : "",
      });
    }
  }

  goBack = () => {
    log(LogLevel.UI_ACTION, "ProductSaleMyOrderReturn:goBack");
    this.props.history.goBack();
  };

  onReturn = () => {
    let order: OrderInfo = this.props.orders[this.id];
    if (!order) return;

    let disabled: boolean = !this.state.count || !this.state.reason;
    if (disabled) return;

    log(LogLevel.UI_LIFECYCLE, "ProductSaleMyOrderReturn.onReturn", this.state);
    this.props.waitingPopup.show();

    let newOrder: OrderInfo = {
      id: order.id,
      status: OrderStatus.RETURNING,
      returnAmount: parseInt(this.state.count),
      returnReason: this.state.reason,
    };

    fetchAPI(
      API.SALE_ORDER_CHANGE,
      "",
      null,
      newOrder,
      getGlobal(GlobalKey.TOKEN)
    )
      .then((result) => {
        if (result && !result.error) {
          this.props.setOrder(newOrder);
          this.setState({ step: 1 });
          this.props.waitingPopup.hide();
        } else {
          alert("취소 실패 : 약문약답 카카오플러스친구로 연락주세요.");
          this.props.waitingPopup.hide();
          return;
        }
      })
      .catch((e) => {
        log(
          LogLevel.UI_EXCEPTION,
          "ProductSaleMyOrderReturn:onReturn failed",
          e
        );
        alert("취소 실패 : 약문약답 카카오플러스친구로 연락주세요.");
        this.props.waitingPopup.hide();
        return;
      });
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "ProductSaleMyOrderReturn.render",
      this.props.orders
    );
    if (!this.id || !this.props.orders[this.id]) return null;

    let order: OrderInfo = this.props.orders[this.id];

    if (!order) return null;

    if (this.state.step == 0) {
      let disabled: boolean = !this.state.count || !this.state.reason;
      return (
        <>
          <Header goBack={this.goBack} name="반품 신청" />
          <Body>
            <div className="common-block">
              <div style={{ marginBottom: "40px" }}>
                <SaleOrderItemBody order={order} />
              </div>
              <div
                style={{
                  width: "50%",
                  minWidth: "150px",
                  marginBottom: "20px",
                }}
              >
                <Input
                  value={this.state.count}
                  type="number"
                  allowMultiline={false}
                  postfix="개"
                  placeholder="수량"
                  onChange={(e) => this.setState({ count: e.target.value })}
                />
              </div>
              <Static customStyle={{ marginBottom: "8px" }}>
                <Text color="Black" type="Body">
                  반품사유
                </Text>
              </Static>
              <Input
                value={this.state.reason}
                allowMultiline={true}
                placeholder="반품사유를 입력해주세요."
                minRows={5}
                onChange={(e) => this.setState({ reason: e.target.value })}
              />
            </div>
            <Footer>
              <TextRectangleButton
                size="F"
                level="3"
                disabled={disabled}
                onClick={this.onReturn}
              >
                신청하기
              </TextRectangleButton>
            </Footer>
          </Body>
        </>
      );
    } else if (this.state.step == 1) {
      return (
        <>
          <Header
            goBack={this.goBack}
            name={""}
            needClose={true}
            needBack={false}
          />
          <Body>
            <div className="common-container-column common-flex-align-center">
              <div style={{ marginTop: "60px", marginBottom: "60px" }}>
                <Icon
                  width={80}
                  height={80}
                  fill={COLOR.Green}
                  name="Check Circle"
                />
              </div>
              <Text color="Black" type="H3">
                반품신청이 완료되었습니다.
              </Text>
            </div>
            <Footer>
              <TextRectangleButton size="F" level="3" onClick={this.goBack}>
                완료
              </TextRectangleButton>
            </Footer>
          </Body>
        </>
      );
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  orders: state.sale.orders,
  links: state.board.links,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadOrder: (id) => actions.sale.getOrder(id),
  setOrder: (order: OrderInfo) => actions.sale.setOrder(order),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductSaleMyOrderReturn)
);
