import * as notifications from "./actions";
import { ActionType, getType } from "typesafe-actions";
import { NotificationState } from "./types";
import { log, LogLevel } from "../../utils/LogUtil";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import { getNotificationCount } from "../../models/Model.Notification";

const defaultState: NotificationState = {
  notifications: [],
  checked: null,
  notiCount: 0,
  loading: false,
  end: false,
  chatUnreadCount: 0,
};

export type NotificationAction = ActionType<typeof notifications>;

export default (
  state = defaultState,
  action: NotificationAction
): NotificationState => {
  let count = 0;
  switch (action.type) {
    case getType(notifications.initCheck):
      log(LogLevel.REDUX_NOTIFICATION, "Notification Reducer. ", action);
      let time = getGlobal(GlobalKey.NOTIFICATION_CHECKED_TIME, true);
      count = getNotificationCount(state.notifications, time);
      return {
        ...state,
        checked: time,
        notiCount: count,
      };
    case getType(notifications.check):
      log(LogLevel.REDUX_NOTIFICATION, "Notification Reducer. ", action);
      let now = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString();
      setGlobal(GlobalKey.NOTIFICATION_CHECKED_TIME, now, true);
      return {
        ...state,
        checked: now,
        notiCount: 0,
      };
    case getType(notifications.fetchNotificationLoad.request):
      // newUsers[0] = null;
      log(LogLevel.REDUX_NOTIFICATION, "Notification Reducer. ", action);
      return {
        ...state,
        loading: true,
      };
    case getType(notifications.fetchNotificationLoad.success):
      // newUsers[0] = null;
      let lasttime = state.checked;
      if (!lasttime)
        lasttime = getGlobal(GlobalKey.NOTIFICATION_CHECKED_TIME, true);

      count = getNotificationCount(action.payload, lasttime);
      log(
        LogLevel.REDUX_NOTIFICATION,
        "Notification Reducer. ",
        action,
        state,
        count,
        action.payload
      );
      return {
        ...state,
        checked: lasttime,
        notifications: [...state.notifications, ...action.payload],
        notiCount: count,
        end: action.payload.length < 100,
        loading: false,
      };
    case getType(notifications.refresh):
      // newUsers[0] = null;
      log(LogLevel.REDUX_NOTIFICATION, "Notification Reducer. ", action);
      lasttime = state.checked;
      if (!lasttime)
        lasttime = getGlobal(GlobalKey.NOTIFICATION_CHECKED_TIME, true);
      return {
        ...state,
        checked: lasttime,
        notifications: [],
        notiCount: 0,
        end: false,
        loading: false,
      };

    case getType(notifications.chatUnreadCountSet):
      // newUsers[0] = null;
      log(
        LogLevel.REDUX_NOTIFICATION,
        "Notification chatUnreadCountSet. ",
        action
      );
      return {
        ...state,
        chatUnreadCount: action.payload ? action.payload : 0,
      };

    case getType(notifications.fetchNotificationCheckItem.success):
      let newNotis = [...state.notifications];
      for (let i = 0; i < newNotis.length; i++) {
        if (newNotis[i].id == action.payload) {
          newNotis[i].checked = true;
          // log(LogLevel.REDUX_NOTIFICATION, "Notification Reducer. notis to change found", action, newNotis[i])
          break;
        }
      }
      log(
        LogLevel.REDUX_NOTIFICATION,
        "Notification Reducer. ",
        action,
        newNotis
      );
      return {
        ...state,
        notifications: newNotis,
      };
    default:
      return state;
  }
};
