import * as searches from "./actions";
import { SearchState } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import { log, LogLevel } from "../../utils/LogUtil";

import { getGlobal, GlobalKey, setGlobal } from "./../../utils/GlobalUtil";
import { SearchKeywordType } from "../../models/Model.Search";

export const fetchSearchMiddleware: Middleware<{}, SearchState> = ({ getState }) => (next) => async (
  action: ActionType<typeof searches>
) => {
  next(action);

  if (action.type == getType(searches.setSearchText)) {
    log(LogLevel.REDUX_SEARCH, "searches.setSearchText. Start Fetching");

    next(searches.fetchSearch.request());
    try {
      let state: any = getState();
      let query: any = {
        search: action.payload,
        count: 20,
        must: true,
        start: state.search.searched.length,
        sort: state.search.order,
        full: true,
      };
      log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearch try", query);

      fetchAPI(API.BOARD_SEARCH, "", null, query, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if (result) {
          log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearch result", query, result);
          if (!result.error) {
            log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearch result", result);
            next(searches.fetchSearch.success(result.data));
          } else {
            log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearch failed", result);
 
            let error: Error = {
              name: "Error Code" + result.error,
              message: "error code",
            };
            // error.name = "Error Code"  + result.error;
            next(searches.fetchSearch.failure(error));
          }
        }
      });
    } catch (e) {
      log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware QnA failed", e);
      next(searches.fetchSearch.failure(e));
    }
  } else if (action.type == getType(searches.loadSearchKeywords)) {
    log(LogLevel.REDUX_SEARCH, "searches.loadSearchKeywords. Start Fetching");

    next(searches.fetchSearchKeywords.request());

    try {
      fetchAPI(API.SEACH_KEYWORD_GET, "", { type: SearchKeywordType.HOT }, null, getGlobal(GlobalKey.TOKEN)).then(
        (result) => {
          if (result) {
            // log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearchKeywords result",  result);
            if (!result.error) {
              let keywords = {
                hotKeywords: result.data,
              };
              log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearchKeywords result", result);
              next(searches.fetchSearchKeywords.success(keywords));
            } else {
              log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearchKeywords failed", result);

              let error: Error = {
                name: "Error Code" + result.error,
                message: "error code",
              };
              // error.name = "Error Code"  + result.error;
              next(searches.fetchSearchKeywords.failure(error));
            }
          }
        }
      );
      fetchAPI(
        API.SEACH_KEYWORD_GET,
        "",
        { type: SearchKeywordType.RECOMMENDED_RESULTS },
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result) {
          log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearchKeywords result", result);
          if (!result.error) {
            let keywords: any = {
              suggestedKeywords: result.data,
              keywordsResults: {},
            };

            if (result.data && result.data.length) {
              for (let i = 0; i < result.data.length; i++) {
                fetchAPI(
                  API.SEACH_KEYWORD_RELATIVE_GET,
                  "",
                  { id: result.data[i].id },
                  null,
                  getGlobal(GlobalKey.TOKEN)
                ).then((result) => {
                  if (result && !result.error) {
                    log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearchKeywords result Recommends", result);
                    next(searches.fetchSearchKeywordRelatives.success(result.data));
                  }
                });
              }
            }
            next(searches.fetchSearchKeywords.success(keywords));
          } else {
            log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearchKeywords failed", result);

            let error: Error = {
              name: "Error Code" + result.error,
              message: "error code",
            };
            // error.name = "Error Code"  + result.error;
            next(searches.fetchSearchKeywords.failure(error));
          }
        }
      });
    } catch (e) {
      log(LogLevel.REDUX_SEARCH, "fetchSearchMiddleware fetchSearchKeywords failed", e);
      next(searches.fetchSearchKeywords.failure(e));
    }
  }
};
