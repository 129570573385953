import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import StringUtil from '../../../utils/StringUtil'
import profileImg from '../../../assets/image/profile_m_1.png'

const sizes =  {
  XL : `
    width: 72px;
    height: 72px;
    border-radius: 36px;
  `,

  L : `
    width: 60px;
    height: 60px;
    border-radius: 30px;
  `,

  M : `
    width: 48px;
    height: 48px;
    border-radius: 24px;
  `,

  S : `
    width: 24px;
    height: 24px;
    border-radius: 12px;
  `,
}

type SIZE =
"XL" | 
"L" | 
"M" | 
"S" 

interface ContainerProps {
  size: SIZE
}

const Container: StyledComponent<'img', FC, ContainerProps> = styled.img<ContainerProps>`
  object-fit: contain;
  overflow: hidden;
  
  ${(props) => sizes[props.size]}
`

export interface Props {
  size: SIZE
  className?: string
  src?: string
  onClick?: () => void
}
const Avatar: FC<Props> = ({ size, children, src, className, onClick }) => {
  let classname = "";
  if(className)
    classname = className;

  src = StringUtil.convertFilePath(src);
  return (
    <Container
      as='img'
      size={size}
      className={classname}
      src={src}
      onClick={() => {if(onClick)onClick()}} 
      onError={(e)=>{
        let targetAny:any = e.target; targetAny.src=profileImg
      }}
    >
      {children}
    </Container>
  )
}

export default Avatar
