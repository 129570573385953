import React, { useRef, useState } from "react";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import { log, LogLevel } from "../../../utils/LogUtil";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
interface DailyQuizDislikeReasonPopupProps {
  onDone: (text: string) => void;
}

const menu = [
  "퀴즈에 약학적 오류가 있습니다.",
  "정답이 틀렸습니다.",
  "문제가 불명확하거나 이해하기 어렵습니다.",
  "해설에 오류가 있습니다.",
];

export const DailyQuizDislikeReasonPopup: React.FC<DailyQuizDislikeReasonPopupProps> = ({
  onDone,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [customText, setCustomText] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <BottomSheetList customStyle={{ height: "440px" }}>
      {/* {menu.map((text, index) => (
        <BottomSheetListItem
          key={index.toString()}
          left={
            <Text
              textType="Body1Medium"
              color={
                COLOR_SYSTEM.get("Gray")[index == selectedIndex ? 900 : 400]
              }
            >
              {text.toLocaleString()}
            </Text>
          }
          right={
            <CheckBox
              checked={index == selectedIndex}
              size={"Small"}
              onClick={() => {}}
            />
          }
          onClick={() => {
            log(
              LogLevel.UI_EXCEPTION,
              "DailyQuizDislikeReasonPopup:BottomSheetListItem:onClick",
              index,
              selectedIndex
            );
            setSelectedIndex(index);
          }}
        />
      ))} */}
      {/* <BottomSheetListItem
        left={
          <Text
            textType="Body1Medium"
            color={COLOR_SYSTEM.get("Gray")[-1 == selectedIndex ? 900 : 400]}
          >
            직접 입력
          </Text>
        }
        right={
          <CheckBox
            checked={-1 == selectedIndex}
            size={"Small"}
            onClick={() => {}}
          />
        }
        onClick={() => {
          log(
            LogLevel.UI_EXCEPTION,
            "PremiumUserSubscribe:BottomSheetListItem:onClick",
            -1,
            selectedIndex
          );
          setSelectedIndex(-1);
          setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
          }, 100);
        }}
      />
      {selectedIndex == -1 && ( */}
      <Input
        inputRef={inputRef}
        placeholder={
          <div>
            오류의 내용을 입력해주세요 <br />
            <br /> ex) 퀴즈(해설)에 오류가 있어요.{" "}
          </div>
        }
        multiLine={true}
        value={customText}
        onChange={(e) => {
          setCustomText(e.target.value);
        }}
        style={{ height: "300px" }}
      />
      {/* )} */}
      <Button
        size={"Large"}
        type={"Text"}
        variant={"Contained"}
        color={"Primary"}
        style={{
          position: "absolute",
          bottom: "32px",
          width: "calc(100% - 48px)",
        }}
        disabled={selectedIndex == -1 && !customText}
        disabledColor={selectedIndex == -1 && !customText}
        onClick={() => {
          onDone(selectedIndex == -1 ? customText : menu[selectedIndex]);
        }}
      >
        선택 완료
      </Button>
    </BottomSheetList>
  );
};
