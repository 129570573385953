import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonSelect,
  IonText,
  IonSelectOption,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonInput,
} from "@ionic/react";
// import './DevRedirect.css'
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { getGlobal, GlobalKey } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import { BoardContent, Attachment } from "../models/Model.Board";
import { fetchAPI } from "./../utils/APIUtil";
import * as API from "./../API.json";
import BoardUtil from "../utils/BoardUtil";
import kakaoIcon from "./../assets/icon/question_small_yellow_mobile_3X.png";
import { MY_ADDRESS } from "./../config.json";

type Props = RouteComponentProps<{ tab: string }> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

type State = {
  address: string;
};

class DevRedirect extends Component<Props, State> {
  state: State = {
    address: "http://app.dev.ymyd.onjourney.co.kr",
  };

  popupProgress = null;

  constructor(props: Props) {
    super(props);
  }

  onKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "DevRedirect:onKeyPress", e.keyCode);
    if (e.keyCode == 13) this.jump();
  };

  jump = () => {
    window.location.replace(this.state.address + "?target=" + getGlobal(GlobalKey.OS));
  };

  onChat = async () => {
    log(LogLevel.UI_ACTION, "onKakaoShare");
    let os = getGlobal(GlobalKey.OS);

    if (!os || os == "browser") {
      let feed = {
        objectType: "feed",
        content: {
          title: "DEV 서버 연결",
          description: "앱을 종료하고 누르세요\n"+this.state.address,
          imageUrl: MY_ADDRESS + "/images/ymydgi_20220719.jpg",
          link: {
            androidExecParams: "url=" + this.state.address,
            iosExecParams: "url=" + this.state.address,
          },
        },
        buttons:[
          {        
            title: "DEV",
            link: {
              androidExecParams: "url=" + this.state.address,
              iosExecParams: "url=" + this.state.address,
            }
          },
          {        
            title: "REL",
            link: {
              androidExecParams: "url=https://app.stg.ymyd.onjourney.co.kr",
              iosExecParams: "url=https://app.stg.ymyd.onjourney.co.kr",
  
            }
          }
        ],
      };
      console.log("onKakaoShare", feed);
      let windowAny: any = window;
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
      windowAny.Kakao.Link.sendDefault(feed);
    }
  };

  onUrlScheme = () => {
    window.open("com.onjourney.pharmacycafe://open?url=" + this.state.address, "_system");
  };

  render() {
    // log(LogLevel.UI_LIFECYCLE, "DevRedirect.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">개발서버 연결</IonText>
        </IonHeader>

        <IonContent class="board-new">
          <div className="board-new-item-container">
            <IonInput
              class="admin-board-search-input"
              placeholder="192.168.0.25"
              value={this.state.address}
              onIonChange={(e) => this.setState({ address: e.detail.value })}
              onKeyDown={this.onKeyPress}
            />
            <IonButton fill="clear" color="primary" onClick={this.jump}>
              <IonIcon name="arrow-forward" />
            </IonButton>
          </div>
          <img className="register-waiting-kakao" src={kakaoIcon} onClick={this.onChat} />
          <div onClick={this.onUrlScheme}>앱으로 시작하기</div>
          <div className="common-container-column">
            <IonButton
              fill="clear"
              color="primary"
              onClick={() => this.setState({ address: "http://192.168.0.25:3000" })}
            >
              &lt; http://192.168.0.25:3000
            </IonButton>
            <IonButton
              fill="clear"
              color="primary"
              onClick={() => this.setState({ address: "http://192.168.1.13:3000" })}
            >
              &lt; http://192.168.1.13:3000
            </IonButton>
            <IonButton
              fill="clear"
              color="primary"
              onClick={() => this.setState({ address: "http://app.dev.ymyd.onjourney.co.kr" })}
            >
              &lt; http://app.dev.ymyd.onjourney.co.kr
            </IonButton>
            <IonButton
              fill="clear"
              color="primary"
              onClick={() => this.setState({ address: "https://app.stg.ymyd.onjourney.co.kr" })}
            >
              &lt; https://app.stg.ymyd.onjourney.co.kr
            </IonButton>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DevRedirect));
