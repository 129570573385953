
import * as sales from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { SaleState} from "./types";
import { EventInfo } from '../../models/Model.Event';
import {log, LogLevel} from '../../utils/LogUtil'
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { OrderInfo, ProductInfo } from '../../models/Model.Sale';
import { idText } from 'typescript';

const defaultState: SaleState = {
  products : {},
  orders: {},
  orderList: {orders:[], loading:false, end: false, needReload: true},
};

export default (state = defaultState, action: ActionType<typeof sales>): SaleState => {
  let products = {...state.products};
  let orders = {...state.orders};
  switch (action.type) {

    case getType(sales.fetchProduct.request):
      log(LogLevel.REDUX, "Reducer: sales.fetchProduct.request", action, state);
      if(action.payload){
        let id = action.payload;
        if(products[id]){
          products[id] = {
            ...products[id],
            loading:true,
          };
        }else{
          products[id] = {
            id,
            loading:true,
          }
        }
        return {...state, products};
      }
      break;
    case getType(sales.fetchProduct.success):
      log(LogLevel.REDUX, "Reducer: sales.fetchProduct.success", action, state);
      let product = {...action.payload};
      if(products[product.id]){
        products[product.id] = {
          ...products[product.id],
          ...product,
          loading:false,
        }
        return {...state, products};
      }else{
        products[product.id] = {
          ...product,
          loading:false,
        }
        return {...state, products};
      }
    case getType(sales.fetchProduct.failure):{
      log(LogLevel.REDUX, "Reducer: sales.fetchProduct.failure", action, state);
      let id = action.payload;

      if(products[id]){
        products[id] = {
          ...products[id],
          loading:false,
          error:true,
        }
        return {...state, products};
      }else{
        products[id] = {
          loading:false,
          error:true,
        }
        return {...state, products};
      }
    }

    case getType(sales.fetchOrder.request):
      log(LogLevel.REDUX, "Reducer: sales.fetchOrder.request", action, state);
      if(action.payload){
        let id = action.payload;
        if(orders[id]){
          orders[id] = {
            ...orders[id],
            loading:true,
          };
        }else{
          orders[id] = {
            id,
            loading:true,
          }
        }
        return {...state, orders};
      }
      break;
    case getType(sales.fetchOrder.success):{
      log(LogLevel.REDUX, "Reducer: sales.fetchOrder.success", action, state);
      let order = {...action.payload};
      if(orders[order.id]){
        orders[order.id] = {
          ...orders[order.id],
          ...order,
          loading:false,
        }
      }else{
        orders[order.id] = {
          ...order,
          loading:false,
        }
      }
      if(order.payMerchantId){
        if(orders[order.payMerchantId]){
          orders[order.payMerchantId] = {
            ...orders[order.id],
            ...order,
            loading:false,
          }
        }else{
          orders[order.payMerchantId] = {
            ...order,
            loading:false,
          }
        }
      }
      return {...state, orders};
    }

    case getType(sales.fetchOrder.failure):{
      log(LogLevel.REDUX, "Reducer: sales.fetchOrder.failure", action, state);
      let id = action.payload;

      if(orders[id]){
        orders[id] = {
          ...orders[id],
          loading:false,
          error:true,
        }
        return {...state, orders};
      }else{
        orders[id] = {
          loading:false,
          error:true,
        }
        return {...state, orders};
      }
    }
    case getType(sales.setOrder):{
      log(LogLevel.REDUX, "Reducer: sales.setOrder", action, state);
      let order = {...action.payload};
      if(orders[order.id]){
        orders[order.id] = {
          ...orders[order.id],
          ...order,
          loading:false,
        }
      }else{
        orders[order.id] = {
          ...order,
          loading:false,
        }
      }
      if(order.payMerchantId){
        if(orders[order.payMerchantId]){
          orders[order.payMerchantId] = {
            ...orders[order.payMerchantId],
            ...order,
            loading:false,
          }
        }else{
          orders[order.payMerchantId] = {
            ...order,
            loading:false,
          }
        }
      }
      return {...state, orders};
    }

    case getType(sales.refreshOrders):
      log(LogLevel.REDUX, "Reducer: sales.refreshOrders", state);

      let newOrdersList = {...state.orderList};
      if(newOrdersList){
        newOrdersList.needReload = true;
        return ({
          ...state, orderList:newOrdersList
        });        
      }
      break;

    case getType(sales.fetchOrders.success):
      log(LogLevel.REDUX, "Reducer: sales.fetchOrders.success start", action);
      let newState:SaleState= {...state, orderList:{...state.orderList}};
      newState.orderList = {...newState.orderList, ...action.payload};
      if(action.payload.orders){
        for(let i=0; i<action.payload.orders.length; i++) {
          let aContent:OrderInfo = action.payload.orders[i];
          if(aContent){
            newState.orders[aContent.id] = 
              {...state.orders[aContent.id], ...aContent};
          }
        }
        let newOrders = action.payload.orders.map((item, index) => {
          return {id:item.id};
        });
        newState.orderList.orders = newOrders;
      }
      log(LogLevel.REDUX, "Reducer: sales.fetchOrders.success end", action, newState, action.payload.id, newState.orderList.lastVisitedTime);
      return newState;     

    case getType(sales.fetchOrders.failure):
      newState= {...state, orderList:{...state.orderList}};
      newState.orderList.loading = false;
      log(LogLevel.REDUX, "Reducer: sales.fetchOrders.failure", action, state, newState);
      return newState;

    case getType(sales.fetchOrders.request):
      newState= {...state, orderList:{...state.orderList}};
      newState.orderList.loading = true;
      log(LogLevel.REDUX, "Reducer: sales.fetchOrders.request", action, state, newState);
      return newState;     
    default:
      return state;
  }

  return state;
}
