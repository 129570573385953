import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from "react-router";
// import './style.css';
import './../../../Common.css';
import * as API from './../../../API.json';
import { connect } from 'react-redux';
import { RootState, actions } from './../../../store';
import {log, LogLevel} from './../../../utils/LogUtil'
import {MY_ADDRESS} from './../../../config.json'

import {getGlobal, GlobalKey, clearGlobal, setGlobal} from './../../../utils/GlobalUtil'
import { UIPopupType, UIServiceType } from '../../../store/ui/types';
import AnalyticsUtil from './../../../utils/AnalyticsUtil';
import { SeminarLecture } from '../../../models/Model.Seminar';
import SeminarList from '../../../component/template/Seminar/SeminarList';
import { BannerInfo } from '../../../models/Model.Banner';

const queryString = require('query-string');
const windowAny: any = window;

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
}

type State = {
}

class SeminarListViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_VIEW", '세미나_리스트_진입');

  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "SeminarListViewer:componentDidMount backbutton register");
    // if(this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);

    this.props.loadSeminarLectures();
    this.props.loadSeminarFeatured();
    if(this.props.lists && this.props.lists.length >= 4)
      this.props.reloadLectures(3, this.props.lists[3].options);
  }
          
  componentDidUpdate(prevProps, prevState) {
  }

  // goBack = () => {
  //   log(LogLevel.UI_ACTION, "SeminarListViewer:goBack");

  //   this.props.history.goBack();
  //   return true;
  // }
  
  componentWillUnmount(){
    log(LogLevel.UI_EVENT, "SeminarListViewer:componentWillUnmount backbutton unregister");
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_VIEW", '세미나_리스트_이탈');
  }

  // onReview = () => {
  //   AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_FEEDBACK", '세미나_피드백남기기_진입', {"id":this.id});

  //   this.setState({ 
  //     overlayView: (
  //       <div className="common-content">
  //         <SeminarViewerRewards lectureId={this.id} onDone={this.onOverlayClosed}/>          
  //       </div>
  //     )
  //   });
  // }

  // onConsult = () => {
  //   AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_FEEDBACK", '세미나_상담하기_진입', {"id":this.id});
  //   this.setState({
  //     overlayView: (
  //       <div className="common-content">
  //         <SeminarViewerConsult lectureId={this.id} onDone={this.onConsultDone} onCancel={this.onOverlayClosed}/>          
  //       </div>
  //     )
  //   });
  // }

  // onProductReview = () => {
  //   AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", '세미나_제품리뷰_진입', {"id":this.id});
  //   this.setState({
  //     overlayView: (
  //       <div className="common-content">
  //         <SeminarViewerProductReview lectureId={this.id} onDone={this.onOverlayClosed}/>          
  //       </div>
  //     )
  //   });
  // }

  // onConsultDone = (consult:any = null) => {
  //   if(consult)
  //     this.consultOptions = consult;
  //   AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", '세미나_상담하기_진행', {"id":this.id, options:this.consultOptions.options});
  //   this.setState({
  //     overlayView: (
  //       <div className="common-content">
  //         <SeminarViewerConsultConfirm lectureId={this.id} consultOption={this.consultOptions} onAddressChange={this.onAddressChange} onDone={this.onOverlayClosed} onCancel={this.onConsult}/>          
  //       </div>
  //     )
  //   });
    
  // }

  // onAddressChange = () => {
  //   AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", '세미나_상담하기_주소변경_진입', {"id":this.id});
  //   this.setState({
  //     overlayView: (
  //       <div className="common-content">
  //         <SearchAddress title="약국정보 수정" searchType="pharmacy" onSelected={this.onAddressChanged} onCanceled={this.onConsultDone}/>          
  //       </div>
  //     )
  //   });
  // }

  // onAddressChanged = async (address:string, region:string, name:string, phone:string) => {
  //   AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", '세미나_상담하기_주소변경_완료', {"id":this.id});
  //   let newMe = {
  //     workPlaceAddress: address,
  //     workPlaceRegion: region,
  //     workPlaceName: name,
  //     workPlacePhone: phone,
  //   }

  //   let result = await fetchAPI(API.USER_ME_UPDATE, '', null, newMe, getGlobal(GlobalKey.TOKEN))
  //   if(result && !result.error) {
  //     let token = result.data.token;
  //     setGlobal(GlobalKey.TOKEN, token, true);
  //     this.props.loginUser();
  //   }

  //   this.onConsultDone();
  // }

  onSelectBanner = (banner:BannerInfo) => {
    log(LogLevel.UI_EVENT, "SeminarListViewer:onSelectBanner", banner);
    if(banner.advertiserInfo){
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_CLICK", '광고클릭', {"type":"세미나메인화면 배너", ...banner.advertiserInfo});
    }

    if(banner.targetUrl.startsWith("&&&")){
      if(getGlobal(GlobalKey.OS)!="browser"){
        this.props.history.push(banner.targetUrl.substring(3));
      }else{
        window.open(banner.targetUrl.replace("&&&", MY_ADDRESS), ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
      }
    }
    else if(banner.targetUrl.startsWith(MY_ADDRESS)){
      if(getGlobal(GlobalKey.OS)!="browser"){
        this.props.history.push(banner.targetUrl);
      }else{
        window.open(banner.targetUrl, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
      }
    }
    else if(banner.targetUrl.startsWith("/")){
      this.props.history.push(banner.targetUrl);
    }
    else
      window.open(banner.targetUrl, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
  }

  onSelectSeminar = (seminar:SeminarLecture) => {
    log(LogLevel.UI_EVENT, "SeminarListViewer:onSelectSeminar", seminar);
    this.props.history.push("/lecture?id=" + seminar.id);
  }

  onLoadMore = (id) => {
    log(LogLevel.UI_EVENT, "SeminarListViewer:onLoadMore", );
    this.props.loadLectures(id);
  }

  onSort = (id, options) => {
    this.props.reloadLectures(id, options);
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "SeminarListViewer:render()", this.props);

    return (
      <>
        <div className={"common-content"}>
          <SeminarList
            banners={this.props.featureds}
            seminarListStates= {this.props.lists}
            lectures={this.props.lectures}
            onSelectBanner={this.onSelectBanner}
            onSelectSeminar= {this.onSelectSeminar}
            // onGoBack= {this.goBack}
            onSort={this.onSort}
            onLoadMore= {this.onLoadMore}
          />          
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  featureds: state.seminar.features,
  lists: state.seminar.lists,
  lectures: state.seminar.lectures,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  // logOutUser: () => actions.user.logout(),
  // refreshBoards: () => actions.board.refreshBoards(),
  // loadBoard: (board:BoardType) => actions.board.getBoard(board.toString(), 50),
  // loginUser: () => actions.user.login(),
  reloadLectures: (id, options) => actions.seminar.reloadList(options, id),
  loadLectures: (id) => actions.seminar.getSeminarLectures(id),
  loadSeminarLectures: () => actions.seminar.init(),
  loadSeminarFeatured: () => actions.seminar.getSeminarFeatured(),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeminarListViewer));
 