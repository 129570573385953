import React from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from 'react-router';
import { format } from 'date-fns';
import { IonLabel, IonButton, IonIcon, IonItemSliding, IonItem, IonItemOptions, IonItemOption, IonAlert } from '@ionic/react';
import './BoardContentListFoldItem.css';
import qustionIcon from './../assets/image/question.png'
import {getDateTimeString} from './../utils/TimeUtil'
import commentIcon from './../assets/icon/comment.svg'
import viewIcon from './../assets/icon/view.svg'
import scappedIcon from './../assets/image/scrapped.png'
import unscappedIcon from './../assets/image/unscrapped.png'
import {BoardContent} from './../models/Model.Board'
import { fetchAPI } from '../utils/APIUtil';
import * as API from './../API.json';
import {BoardType} from './../store/board/types'
import {getGlobal, GlobalKey} from './../utils/GlobalUtil'
import Truncate from 'react-truncate';

type Props = RouteComponentProps & {
  key : string
  content: BoardContent
  index: number
  showDate? :boolean
  onClick?: (id) => void;
}

type States = {
  folded: boolean
}

class BoardContentListFoldItem extends React.Component<Props> {
  state = {
    folded : true,
  }

  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.content && (!this.props.content || this.props.content.id != nextProps.content.id))
      return true;
    return false;
  }
  
  fold = () => {
    let folded = !this.state.folded;
    this.setState({folded})
  }

  render() {
    let posting:BoardContent = this.props.content;

    if(!posting || !posting.id)
      return null;
    let title = "제목없음"
    if(posting.subject.length > 0)
      title = posting.subject;

    let icon = "arrow-down";
    let body;
    let titleStyle = "boardcontentlist-folditem-title"
    if(!this.state.folded){
      body = <div className="boardcontentlist-folditem-body" dangerouslySetInnerHTML={{ __html: posting.bodyHtml }}/>
      icon = "arrow-up";
      titleStyle = "boardcontentlist-folditem-title-unfold"
    }

    let date;
    if(this.props.showDate){
      date = <div className="boardcontentlist-folditem-date" >{getDateTimeString(posting.createdAt)}</div>
    }
    
    return (
      <div className="boardcontentlist-folditem-container"  onClick={this.fold}>
        <div className="boardcontentlist-folditem-head-container">
          <div className="boardcontentlist-folditem-head">
            {date}
            <div className={titleStyle}>
              {title}
            </div>
          </div>
          <IonButton color="boardcontentlist-folditem-arrow">
            <IonIcon name={icon} mode="ios"/>
          </IonButton>
        </div>
        {body}
      </div>

    );
  }
}
export default withRouter(BoardContentListFoldItem);
  
