import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import { rippleEvent } from '../../_util/RrppleEffect'
import { Attachment } from '../../../models/Model.Board'
import Icon from '../../atom/Icon'
import { getFileSizeString } from '../../../utils/FileUtil'
import DownloadUtil from '../../../utils/DownloadUtil'

interface WrapperProps {
}

const Wrapper: StyledComponent<'button', React.FC, WrapperProps> = styled.button<WrapperProps>`
  z-index: 0;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  background-color: ${COLOR.White};
  border: 1px solid ${COLOR.Gray6};
`

interface Props {
  attachment?: Attachment,
  actionOnClick?:"download" | "jump" | "none",
  onClick?: (item:Attachment) => void;
}
const ViewerAttachment: React.FC<Props> = ({
  attachment,
  actionOnClick,
  onClick,
}) => {

  let displayName = attachment.name;
  if(displayName.length > 25)
  {
    let extPoint = displayName.lastIndexOf(".");
    if(extPoint == -1){
      displayName = displayName.substring(0, 25) + "...";
    }else {
      let lastName = displayName.substring(displayName.lastIndexOf("."));
      displayName = displayName.substring(0, 25-lastName.length) + ".." + lastName;
    }
  }

  const onClickItem = () => {
    if(onClick)
      onClick(attachment);

    if(!actionOnClick || actionOnClick == "none")
      return;
    
    if(actionOnClick == "download"){
      DownloadUtil.downloadDocument(attachment.url, attachment.name);
    }
  }

  return (
    <Wrapper onClick={onClickItem}>
      <div className="common-container-row common-flex-align-center">
        <div style={{marginRight:"16px"}}>
          <Icon width={24} height={24} fill={COLOR.Gray1} name="File"/>
        </div>
        <div className="common-flex-grow common-flex-column common-flex-align-start">
          <div>
            <Text type="Body2" color="Gray1">{displayName}</Text>
          </div>
          <div>
            <Text type="Caption2" color="Gray3">{getFileSizeString(attachment.size)}</Text>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ViewerAttachment
