import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonIcon,
  IonButton,
  IonContent,
  IonToggle,
} from "@ionic/react";
import "./HtmlComposer.css";
import "./../Common.css";
import Profile from "../components/ProfileSimple";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import MagicUrl from "quill-magic-url";
import Mention from "quill-mention";
import AttachmentViewer from "../components/Attachment";
import qustionIcon from "./../assets/image/question.png";
import waitImage from "./../assets/image/wait.png";
import {
  BoardContent,
  Attachment,
  BoardAttribute,
  BoardContentCommercialType,
  SUBCATEGORY_QNA_PRESET,
  SUBCATEGORY_ETC_PRESET,
  SUBCATEGORY_CONSULT_PRESET,
  SUBCATEGORY_ALL_PRESET,
} from "../models/Model.Board";
import { log, LogLevel } from "../utils/LogUtil";
import {
  getGlobal,
  GlobalKey,
  clearGlobal,
  setGlobal,
} from "../utils/GlobalUtil";
import BoardUtil from "../utils/BoardUtil";
import { isIOS } from "react-device-detect";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import { timerStart, timerStop } from "../utils/TimeUtil";
import { loadImageBase64 } from "../utils/ImageUtil";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import { BoardType } from "../store/board/types";
import { UserLevel } from "../models/Model.User";
import ViewerBodyPopup from "../components/ViewerBodyPopup";
import ComponentComposePopup from "../ivcomponent/ComponentComposePopup";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import Textarea from "react-textarea-autosize";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import { SeminarLecturePartialLoadOption } from "../models/Model.Seminar";
import StringUtil from "../utils/StringUtil";
import ComposerRecommends from "../components/ComposerRecommends";
import ComposeCardnewsPopup from "../components/popup/ComposeCardnewsPopup";
import ComposeHiddenTextPopup from "../components/popup/ComposeHiddenTextPopup";
import Button from "../components/atoms/Button";
import CheckBox from "../components/atoms/CheckBox/CheckBox";
import Text from "../components/atoms/Text";
import { COLOR_SYSTEM } from "../components/design/design-system";
import { Flex, Relative, Static } from "../components/atoms/Layout";
import ToggleButton from "../components/atoms/ToggleButton/ToggleButton";
import withBottomSheet from "../hoc/withBottomSheet";
import { BottomSheetState } from "../store/modal/bottomsheet";
import ViewerBody from "../components/ViewerBody";
import Icon from "../components/atoms/Icon";
import handshake from "../assets/image/handshake.png";
import memo from "../assets/image/memo.png";
import prohibited from "../assets/image/prohibited.png";

var Block = Quill.import("blots/block");
Block.tagName = "div";
Quill.register(Block);

var Inline = Quill.import("blots/inline");

var Embed = Quill.import("blots/embed");

class Keywords extends Embed {
  static create(value) {
    let node = super.create(value);
    log(LogLevel.UI_DATA_LOAD, "Keywords:value: ", value);
    node.setAttribute("keywordtype", value.type);
    node.setAttribute("keywordid", value.id);
    const keywords = document.createElement("span");
    keywords.className = "ql-mention-denotation-char";
    keywords.setAttribute("contenteditable", "false");
    keywords.textContent = value.text;

    node.appendChild(keywords);
    // node.textContent = '';
    // node.innerHTML += value.html;
    return Keywords.setDataValues(node, value);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }

  static formats(domNode) {
    return {
      type: domNode.getAttribute("keywordtype"),
      id: domNode.getAttribute("keywordid"),
    };
  }
}

Keywords.blotName = "keywords";
Keywords.className = "keywords";
Keywords.tagName = "SPAN";

class Components extends Embed {
  static blotName = "components";
  static className = "components";
  static tagName = "SPAN";

  static create(value) {
    let node = super.create(value);
    log(LogLevel.UI_DATA_LOAD, "Components:create: ", node, value);
    // for(let val in value){
    //   node.setAttribute(val, value[val]);
    // }

    const keywords = document.createElement("span");
    keywords.className = "html-composer-component";
    keywords.setAttribute("contenteditable", "false");
    keywords.textContent = "Component:" + value.component;

    node.appendChild(keywords);
    // node.textContent = '';
    // node.innerHTML += value.html;
    return Components.setDataValues(node, value);
    // return node;
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    log(LogLevel.UI_DATA_LOAD, "Components:setDataValues: ", domNode, data);
    return domNode;
  }

  static value(domNode) {
    log(LogLevel.UI_DATA_LOAD, "Components:value: ", domNode);
    return domNode.dataset;
  }

  static formats(domNode) {
    let rvalue: any = {};
    // let atts = domNode.attributes;
    // for (var i = 0; i < atts.length; i++){
    //   rvalue[atts[i].nodeName] = atts[i].nodeValue;
    // }
    log(LogLevel.UI_DATA_LOAD, "Components:formats: ", rvalue);
    return rvalue;
  }
}

Quill.register(Keywords);
Quill.register(Components);
Quill.register("modules/magicUrl", MagicUrl);
Quill.register(Mention);

const keywordTypeName = ["", "성분", "의약품", "용어", "부작용", "동물의약품"];

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    type?: string;
    contentId?: number;
    parentId?: number;
    categoryId?: number;
    bottomSheet?: {
      show: (options?: Partial<BottomSheetState>) => void;
      close: () => void;
    };
  };

type State = {
  title: string;
  body: string;
  bodyText: string;
  bodyHidden: string;
  images: string[];
  imagesBase64: any[];
  files: Attachment[];
  filesLocal: any[];
  anonymous: boolean;
  userCustomName: string;
  recommendStatus: number;
  showRecommends: boolean;
  cardnews: Attachment[];
  commercialType: BoardContentCommercialType;
  advertiserCode: string;
  advertiserProductCode: string;
  usersBizId: string;
  foldSubcategory: boolean;
  subcategories: any[];
  inviteBiz: boolean;
  videoUrl: string;
};

const boardTypePreference = {
  "1": {
    categoryId: BoardType.QNA,
    menuTitle: "글쓰기",
    titlePlaceholder: "제목을 완결된 문장으로 써주세요",
    bodyPlaceholder: (
      <div>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
          답변자가 판단에 참고할 수 있도록 환자정보와 복약현황을 가급적 상세히
          작성해주세요.
        </Text>
        <Static customStyle={{ marginTop: "20px" }}>
          <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
            TIP. 성분명으로 질문하시면, 답변 받을 확률이 👍높아져요.
          </Text>
        </Static>
        <Static as="ul" customStyle={{ marginLeft: "20px", marginTop: "20px" }}>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              서로 존중하며 소통해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              게시판의 목적에 맞는 글을 작성해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              광고, 홍보성 글은 올리지 말아주세요.(공익을 위한 글이라면 운영진과
              먼저 상의해주세요.)
            </Text>
          </Static>
        </Static>
      </div>
    ),
    //bodyPlaceholderText: "답변자가 판단에 참고할 수 있도록 환자정보와 복약현황을 가급적 상세히 작성해주세요.",
    api: BoardUtil.fetchAddContentHtml,
    subCategories: SUBCATEGORY_QNA_PRESET,
  },
  "2": {
    categoryId: BoardType.POST,
    menuTitle: "글쓰기",
    titlePlaceholder: "",
    bodyPlaceholder: (
      <div>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
          어떤 이야기를 나누고 싶으세요?
        </Text>
        <Static as="ul" customStyle={{ marginLeft: "20px", marginTop: "20px" }}>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              서로 존중하며 소통해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              게시판의 목적에 맞는 글을 작성해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              광고, 홍보성 글은 올리지 말아주세요.(공익을 위한 글이라면 운영진과
              먼저 상의해주세요.)
            </Text>
          </Static>
        </Static>
      </div>
    ),
    api: BoardUtil.fetchAddContentHtml,
    subCategories: SUBCATEGORY_ETC_PRESET,
  },
  "3": {
    categoryId: BoardType.NOTICE,
    menuTitle: "공지사항 글쓰기",
    titlePlaceholder: <span>공지사항 제목</span>,
    bodyPlaceholder: <span>공지사항 본문.</span>,
    api: BoardUtil.fetchAddContentHtml,
  },
  "4": {
    categoryId: BoardType.FAQ,
    menuTitle: "FAQ 작성",
    titlePlaceholder: <span>FAQ 질문 제목</span>,
    bodyPlaceholder: <span>FAQ 답변</span>,
    api: BoardUtil.fetchAddContentHtml,
  },
  "5": {
    categoryId: BoardType.REPORT,
    menuTitle: "버그 리포트 작성",
    titlePlaceholder: "",
    bodyPlaceholder: (
      <span>
        좀 더 나은 서비스를 위해, 원하는 동작인, 이상현상에 대해 상세한 설명을
        해주세요.
      </span>
    ),
    bodyPlaceholderText:
      "좀 더 나은 서비스를 위해, 원하는 동작인, 이상현상에 대해 상세한 설명을 해주세요.",
    api: BoardUtil.fetchAddContentHtml,
  },
  "6": {
    categoryId: BoardType.TEST,
    menuTitle: "테스트 작성",
    titlePlaceholder: "",
    bodyPlaceholder: (
      <span>테스트용 게시판으로 뭐든지 가학적으로 써보세요.</span>
    ),
    bodyPlaceholderText: "테스트용 게시판으로 뭐든지 가학적으로 써보세요.",
    api: BoardUtil.fetchAddContentHtml,
  },
  "8": {
    categoryId: BoardType.DISCUSSION,
    menuTitle: "토론 글쓰기",
    titlePlaceholder:
      "제목 :(예시)약문약답 이슈 토론이 약사들의 여론 창구가 될 수 있을까요?",
    bodyPlaceholder: (
      <span>
        이슈 토론의 목적은 <br />
        구성원들이 다양한 관점으로 논의해서 충분한 이해를 바탕으로 종합적인
        결론을 도출하는 것입니다. <br />
        토론 주제를 제안하는 발제는 다양한 의견이 논의될 수 있도록 아래의 순서에
        따라 작성해 주세요. <br />
        <br />
        <span style={{ backgroundColor: "yellow" }}>배경</span>
        <br />
        이슈에 대한 배경과 설명 <br />
        <br />
        <span style={{ backgroundColor: "yellow" }}>사례</span>
        <br />
        이슈의 구체적인 사례 <br />
        <br />
        <span style={{ backgroundColor: "yellow" }}>발제</span>
        <br />
        작성자가 제안하는 논의 주제 <br />
        <span style={{ color: "orange", fontWeight: "bold" }}>
          ※ 결론을 유도하는 발제는 지양해주세요
        </span>
        <br />
      </span>
    ),
    bodyPlaceholderText:
      "약사님들과 어떤 주제에 대해서 이야기 하고 싶으신가요?",
    api: BoardUtil.fetchAddContentHtml,
  },
  "9": {
    categoryId: BoardType.SEMINAR_QNA,
    menuTitle: "질문 작성",
    titlePlaceholder: "세미나에서 궁금한 점을 작성해주세요.",
    api: BoardUtil.fetchAddContentHtml,
  },
  "12": {
    categoryId: 12,
    menuTitle: "글쓰기",
    titlePlaceholder: "제목을 완결된 문장으로 써주세요",
    bodyPlaceholder: (
      <div>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
          약사님들과 어떤 주제에 대해서 이야기 하고 싶으신가요?
        </Text>
        <Static as="ul" customStyle={{ marginLeft: "20px", marginTop: "20px" }}>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              서로 존중하며 소통해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              게시판의 목적에 맞는 글을 작성해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              광고, 홍보성 글은 올리지 말아주세요.(공익을 위한 글이라면 운영진과
              먼저 상의해주세요.)
            </Text>
          </Static>
        </Static>
      </div>
    ),
    api: BoardUtil.fetchAddContentHtml,
    subCategories: SUBCATEGORY_CONSULT_PRESET,
  },
  "13": {
    categoryId: BoardType.PARTY,
    menuTitle: "모임 글쓰기",
    titlePlaceholder: "[모집중/모집완료] 모임 제목",
    bodyPlaceholder: (
      <div>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
          모임 게시물 작성 가이드
        </Text>
        <Static as="ul" customStyle={{ marginLeft: "20px", marginTop: "20px" }}>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              모임 정보를 구체적으로 작성해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              연락방법을 알려주세요. (카카오톡아이디/ 전화번호/ 이메일)
            </Text>
          </Static>
        </Static>
      </div>
    ),
    template: `
    <div>
        • 기간: <br/>
        • 장소: <br/>
        • 모집인원: <br/>
        • 안내사항 및 참여조건 ↓ <br/>
        <br/>
        <br/>
        <br/>
        ※ 등록 불가한 경우 <br/>
        1. 타 서비스나 커뮤니티 홍보 글<br/>
        2. 회원 모집을 목적으로 하는 모임/행사<br/>
    </div>
    `,
    api: BoardUtil.fetchAddContentHtml,
    subCategories: SUBCATEGORY_CONSULT_PRESET,
  },
  "14": {
    categoryId: BoardType.MARKET,
    menuTitle: "약사장터 글쓰기",
    titlePlaceholder: "[팝니다/삽니다/완료] 물품명",
    bodyPlaceholder: (
      <div>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
          약사장터 게시물 작성 가이드
        </Text>
        <Static as="ul" customStyle={{ marginLeft: "20px", marginTop: "20px" }}>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              물품 정보를 구체적으로 작성해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              연락방법을 알려주세요. (카카오톡아이디/ 전화번호/ 이메일)
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              필요 시 사진을 첨부해주세요.
            </Text>
          </Static>
        </Static>
      </div>
    ),
    template: `
    <div>
      • 가격 :  <br/>
      • 거래방법 :  <br/>
      • 연락처 : 카톡아이디 or 전화번호 <br/>
      • 제품설명 ↓ <br/><br/>&nbsp;
    </div>
    `,
    api: BoardUtil.fetchAddContentHtml,
    subCategories: SUBCATEGORY_CONSULT_PRESET,
  },
  default: {
    categoryId: 2,
    menuTitle: "글쓰기",
    titlePlaceholder: "",
    bodyPlaceholder: (
      <div>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
          어떤 이야기를 나누고 싶으세요?
        </Text>
        <Static as="ul" customStyle={{ marginLeft: "20px", marginTop: "20px" }}>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              서로 존중하며 소통해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              게시판의 목적에 맞는 글을 작성해주세요.
            </Text>
          </Static>
          <Static as="li" customStyle={{ listStyle: "disc" }}>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[200]}>
              광고, 홍보성 글은 올리지 말아주세요.(공익을 위한 글이라면 운영진과
              먼저 상의해주세요.)
            </Text>
          </Static>
        </Static>
      </div>
    ),
    api: BoardUtil.fetchAddContentHtml,
    subCategories: SUBCATEGORY_ALL_PRESET,
  },
  reply: {
    categoryId: 0,
    menuTitle: "의견 쓰기",
    titlePlaceholder: "",
    bodyPlaceholder: (
      <div>
        의견 혹은 답변을 남겨주세요.
        <br />
        <br />
        <ul style={{ paddingInlineStart: "20px" }}>
          <li style={{ listStyle: "disc" }}>서로 존중하며 소통합니다.</li>
          <li style={{ listStyle: "disc" }}>
            게시판의 목적에 맞는 글을 작성합니다.
          </li>
          <li style={{ listStyle: "disc" }}>
            광고, 홍보성 글은 올리지 말아주세요. (공익을 위한 글이라면 운영진과
            먼저 상의해주세요)
          </li>
        </ul>
      </div>
    ),
    api: BoardUtil.fetchAddContentHtml,
  },
  "discussion-reply": {
    categoryId: 0,
    menuTitle: "의견 쓰기",
    titlePlaceholder: "",
    bodyPlaceholder: (
      <span>
        이슈에 대한 의견을 작성해주세요
        <br />
        <br />※ 지나친 표현(욕설, 인신공격)이 포함될 경우, 게시가 취소될 수
        있습니다.
      </span>
    ),
    bodyPlaceholderText:
      "의견 혹은 답변을 남겨주세요. 하단의 아이콘을 선택하시면 파일을 첨부할 수 있습니다.",
    api: BoardUtil.fetchAddContentHtml,
  },
  edit: {
    categoryId: 0,
    menuTitle: "게시물 수정",
    titlePlaceholder: "",
    bodyPlaceholder: (
      <div>
        의견 혹은 답변을 남겨주세요.
        <br />
        <br />
        <ul style={{ paddingInlineStart: "20px" }}>
          <li style={{ listStyle: "disc" }}>서로 존중하며 소통합니다.</li>
          <li style={{ listStyle: "disc" }}>
            게시판의 목적에 맞는 글을 작성합니다.
          </li>
          <li style={{ listStyle: "disc" }}>
            광고, 홍보성 글은 올리지 말아주세요. (공익을 위한 글이라면 운영진과
            먼저 상의해주세요)
          </li>
        </ul>
      </div>
    ),
    api: BoardUtil.fetchUpdateContentHtml,
  },
};

const queryString = require("query-string");
const globalAny: any = global;
const $: any = globalAny.$;

const windowAny: any = window;

class HtmlComposer extends Component<Props, State> {
  mounted = false;
  preference: any = null;
  parentId: number = 0;
  seminarId: number = 0;
  id: number = 0;
  content: BoardContent = null;
  titleInput = null;

  reactQuillRef: any = null;
  ivComponentComposerPopup: any = null;
  ivCardnewsComposerPopup: any = null;
  ivHiddenTextComposerPopup: any = null;
  currentIndex: number = 0;
  keywordSearch: string = "";

  contentLoadWait: boolean = false;

  showParentPopup = null;
  doneProcessing = false;

  autoSaveTimer = null;
  autoSaved: any = null;

  categoryId: number = null;
  contentAttribute: string = "";

  static modules = {
    magicUrl: true,
    // toolbar: [
    //   ['bold', 'underline', 'blockquote'],
    //   [{ 'color': "#2D9CDB" }, { 'background': "#FFFCAB" }],
    //   ['clean']
    // ],
    toolbar: {
      container: "#toolbar",
    },
    mention: {
      allowedChars: /\S/,
      mentionDenotationChars: ["@"],
      source: (searchTerm, renderList, mentionChar) => {
        if (searchTerm.length == 0) {
          renderList([], searchTerm);
          return;
        }
        fetchAPI(
          API.MEDICINE_DICTIONARY,
          "",
          { keyword: searchTerm, type: 0 },
          null,
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          log(LogLevel.UI_DATA_LOAD, "HtmlComposer.mention.source", searchTerm);

          if (result && !result.error) {
            let data = result.data.slice(0, 5);
            renderList(data, searchTerm);
          } else {
            renderList([], searchTerm);
          }
        });
      },
      renderItem: (item, searchTerm) => {
        // log(LogLevel.UI_DATA_LOAD, "HtmlComposer.mention.renderItem", item, searchTerm);
        let description = "";
        if (item.description)
          description = `<div class="html-composer-search-medicine-item-description">${item.description}</div>`;
        return `<div class="html-composer-search-medicine-item-container">
            <div class="html-composer-search-medicine-item-type"> ${
              keywordTypeName[item.type]
            }</div>
            <div class="html-composer-search-medicine-item-info"> 
              <div class="html-composer-search-medicine-item-name">${
                item.text
              }</div> 
              ${description}
            </div>
          </div>`;
      },
      onSelect: function (item, insertItem) {
        let newItem = { ...item };
        // newItem.value = `<span contentEditable='false'>${item.value}</span>`
        insertItem(newItem);
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PC_COMPOSER_SELECT_KEYWORD",
          "PC용_게시물_작성_키워드선택",
          {
            type: newItem.type,
            id: newItem.id,
            name: newItem.value,
          }
        );

        log(
          LogLevel.UI_DATA_LOAD,
          "HtmlComposer.mention.onSelect",
          item,
          newItem
        );
      },
      showDenotationChar: false,
      dataAttributes: ["id", "value", "type"],
    },
  };

  static formats = [
    "keywords",
    "mention",
    "components",
    "bold",
    "underline",
    "blockquote",
    "link",
    "image",
    "color",
    "background",
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      title: "",
      body: "",
      bodyText: "",
      bodyHidden: "",
      images: [],
      imagesBase64: [],
      files: [],
      filesLocal: [],
      anonymous: false,
      userCustomName: "",
      recommendStatus: 0,
      showRecommends: getGlobal(GlobalKey.SHOW_RECOMMEND_ON_COMPOSER, true),
      cardnews: [],
      commercialType: 0,
      foldSubcategory: false,
      subcategories: [],
      advertiserCode: "",
      advertiserProductCode: "",
      usersBizId: "",
      inviteBiz: false,
      videoUrl: "",
    };

    let qs = queryString.parse(location.search);

    log(
      LogLevel.UI_LIFECYCLE,
      "HtmlComposer:constructor",
      this.props.location.search,
      qs,
      this.props
    );
    timerStart();

    this.id = qs.id;
    if (!this.id && this.props.contentId) {
      this.id = this.props.contentId;
    }
    this.parentId = qs.parent;
    if (!this.parentId) {
      if (this.props.parentId) this.parentId = this.props.parentId;
      else this.parentId = this.id;
    }

    this.loadContent(true);

    this.categoryId = qs.category;
    if (!this.categoryId && this.props.categoryId)
      this.categoryId = this.props.categoryId;

    this.seminarId = qs.seminar;
    if (!this.seminarId) this.seminarId = 0;

    this.setInitalContents();

    if (this.props.type === "edit") {
      if (this.content) {
        // AMPLITUDE
        let body;
        if (this.content.subject) body = this.content.subject.substring(0, 10);
        else if (this.content.bodyText)
          body = this.content.bodyText.substring(0, 10);
        let name = "";
        if (this.content.usersNickname) name = this.content.usersNickname;
        else this.content.usersCustomName;
        name = this.content.usersCustomName;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_AMPLITUDE,
          "PC_COMPOSER_EDIT_START",
          "PC용_게시물_수정시작",
          {
            게시판이름: this.props.boards[this.content.boardsCategoriesId]
              ? this.props.boards[this.content.boardsCategoriesId].name
              : this.content.boardsCategoriesId,
            게시물id: this.content.id,
            게시물내용: body,
            글쓴이id: this.content.usersId,
            글쓴이닉네임: name,
          }
        );
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "COMPOSER_EDIT_START",
          "게시물_수정시작",
          {
            게시판이름: this.props.boards[this.content.boardsCategoriesId]
              ? this.props.boards[this.content.boardsCategoriesId].name
              : this.content.boardsCategoriesId,
            게시물id: this.content.id,
            게시물내용: body,
            글쓴이id: this.content.usersId,
            글쓴이닉네임: name,
          }
        );
      }
    } else if (this.props.type === "reply") {
      // AMPLITUDE
      let tempContent: BoardContent = this.props.contents[this.parentId];
      if (tempContent) {
        let body;
        if (tempContent.subject) body = tempContent.subject.substring(0, 10);
        else if (tempContent.bodyText)
          body = tempContent.bodyText.substring(0, 10);
        let name = "";
        if (tempContent.usersNickname) name = tempContent.usersNickname;
        else tempContent.usersCustomName;
        name = tempContent.usersCustomName;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_AMPLITUDE,
          "PC_COMPOSER_ANSWER_START",
          "PC용_게시물_답글작성시작",
          {
            게시판이름: this.props.boards[tempContent.boardsCategoriesId]
              ? this.props.boards[tempContent.boardsCategoriesId].name
              : tempContent.boardsCategoriesId,
            게시물id: tempContent.id,
            게시물내용: body,
            글쓴이id: tempContent.usersId,
            글쓴이닉네임: name,
          }
        );
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "COMPOSER_ANSWER_START",
          "게시물_답글작성시작",
          {
            게시판이름: this.props.boards[tempContent.boardsCategoriesId]
              ? this.props.boards[tempContent.boardsCategoriesId].name
              : tempContent.boardsCategoriesId,
            게시물id: tempContent.id,
            게시물내용: body,
            글쓴이id: tempContent.usersId,
            글쓴이닉네임: name,
          }
        );
      }
    } else if (this.props.type === "new") {
      // AMPLITUDE
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_AMPLITUDE,
        "PC_COMPOSER_NEW_START",
        "PC용_게시물_새글작성시작",
        {
          게시판이름: this.props.boards[this.categoryId]
            ? this.props.boards[this.categoryId].name
            : this.categoryId,
        }
      );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "COMPOSER_NEW_START",
        "게시물_새글작성시작",
        {
          게시판이름: this.props.boards[this.categoryId]
            ? this.props.boards[this.categoryId].name
            : this.categoryId,
        }
      );
    }

    this.setPreference();

    log(
      LogLevel.UI_LIFECYCLE,
      "HtmlComposer:constructor complete",
      this.preference
    );
  }

  loadContent = (fetch: boolean) => {
    if (!this.parentId) {
      this.content = null;
      return;
    }

    let tempContent: BoardContent = this.props.contents[this.parentId];

    if (!tempContent) {
      if (fetch) {
        this.props.loadContent(this.parentId);
        this.contentLoadWait = true;
        if (this.props.progressPopup) this.props.progressPopup.show();
      }
    } else {
      if (tempContent.id == this.id) {
        this.content = tempContent;
      } else if (tempContent.replies && tempContent.replies.length > 0) {
        for (let i = 0; i < tempContent.replies.length; i++) {
          if (tempContent.replies[i].id == this.id) {
            this.content = { ...tempContent.replies[i] };
            break;
          }
        }
      }
    }
  };

  // autoSave = () => {
  //   // if composer empty
  //   if(!this.state.body || this.state.body == "<div></div>" || this.state.body == "<div><br></div>" || this.state.body == '<div style="user-select: auto;"><br></div>'){
  //     if(this.autoSaved){
  //       log(LogLevel.UI_LIFECYCLE, "HtmlComposer:autoSave remove", this.autoSaved, this.state)
  //       clearGlobal(GlobalKey.COMPOSER_AUTO_SAVE, true);
  //       this.autoSaved = null;
  //       return;
  //     }else{
  //       log(LogLevel.UI_LIFECYCLE, "HtmlComposer:autoSave skipped 2", this.autoSaved, this.state)
  //       return;
  //     }
  //   }else{
  //     if(this.autoSaved && this.state.title == this.autoSaved.subject && this.state.body == this.autoSaved.bodyHtml){
  //       log(LogLevel.UI_LIFECYCLE, "HtmlComposer:autoSave skipped 1", this.autoSaved)
  //       return;
  //     }
  //   }

  //   this.autoSaved = {
  //     type:this.props.type,
  //     groupParent:this.parentId,
  //     boardsCategoriesId: this.preference.categoryId, // 저장될 게시판 id
  //     isAnonymous: this.state.anonymous, // 익명 게시 여부
  //     subject: this.state.title, // 제목
  //     bodyHtml: this.state.body, // 본문 텍스트
  //     imagesBase64: this.state.imagesBase64,  // 첨부 이미지
  //     images: this.state.images,
  //     filesLocal: this.state.filesLocal,   // 첨부 파일
  //     files: this.state.files,
  //     usersCustomName: this.state.userCustomName,
  //   }

  //   if(this.props.type == "edit"){
  //     this.autoSaved.id = this.id;
  //   }

  //   setGlobal(GlobalKey.COMPOSER_AUTO_SAVE, this.autoSaved, true);

  //   log(LogLevel.UI_LIFECYCLE, "HtmlComposer:autoSave", this.autoSaved);
  //   this.props.toastPopup.show("Autosaved...");
  // }

  // startAutoSave = () => {
  //   if(!ABTestUtil.isTest(ABTestFeature.UI_COMPOSER_AUTO_SAVE))
  //     return;
  //   this.autoSaveTimer = setInterval(this.autoSave, 10000);
  // }

  // stopAutoSave = () => {
  //   if(this.autoSaveTimer){
  //     clearInterval(this.autoSaveTimer);
  //     this.autoSaveTimer = null;
  //   }
  //   clearGlobal(GlobalKey.COMPOSER_AUTO_SAVE, true);
  // }

  setInitalContents() {
    if (!this.content || this.autoSaved) return;

    log(LogLevel.UI_LIFECYCLE, "HtmlComposer:setInitalContents", this.content);
    if (this.props.type == "edit") {
      let body = this.content.bodyHtml;
      if (this.content.images && this.content.images.length > 0) {
        let images = this.content.images
          .map((url, index) => `<img src="${url}"/>`)
          .join("<br/>");
        body = body + "<br/>" + images;
        this.content.images = [];
      }

      body = StringUtil.convertFilePathFull(body);
      if (this.mounted) {
        this.setState({
          ...this.state,
          title: this.content.subject,
          body,
          bodyHidden: this.content.bodyHidden,
          files: this.content.files,
          images: this.content.images,
          anonymous: this.content.isAnonymous,
          userCustomName: this.content.usersCustomName,
          cardnews: this.content.cards,
          commercialType: this.content.commercialType,
          foldSubcategory: true,
          subcategories: this.content.subCategories
            ? this.content.subCategories
            : [],
          advertiserCode: this.content.advertiserCode
            ? this.content.advertiserCode
            : "",
          advertiserProductCode: this.content.advertiserProductCode
            ? this.content.advertiserProductCode
            : "",
          usersBizId: this.content.usersBizId ? this.content.usersBizId : "",
          inviteBiz: this.content.inviteBiz,
          videoUrl: this.content.videoUrl,
        });
      } else {
        this.state = {
          ...this.state,
          title: this.content.subject,
          body,
          bodyHidden: this.content.bodyHidden,
          files: this.content.files,
          images: this.content.images,
          anonymous: this.content.isAnonymous,
          userCustomName: this.content.usersCustomName,
          cardnews: this.content.cards,
          commercialType: this.content.commercialType,
          foldSubcategory: true,
          subcategories: this.content.subCategories
            ? this.content.subCategories
            : [],
          advertiserCode: this.content.advertiserCode
            ? this.content.advertiserCode
            : "",
          advertiserProductCode: this.content.advertiserProductCode
            ? this.content.advertiserProductCode
            : "",
          usersBizId: this.content.usersBizId ? this.content.usersBizId : "",
          inviteBiz: this.content.inviteBiz,
          videoUrl: this.content.videoUrl,
        };
      }
    } else if (this.props.type == "reply") {
      let defaultAnonymous = false;
      if (
        this.content.anonymousNames &&
        this.content.anonymousNames[this.props.user.me.id]
      )
        defaultAnonymous = true;

      if (this.mounted) {
        this.setState({
          anonymous: defaultAnonymous,
        });
      } else {
        this.state = {
          ...this.state,
          anonymous: defaultAnonymous,
        };
      }
    }
  }

  setPreference = () => {
    this.contentAttribute = "";
    if (this.content && this.content.boardsCategoriesAttribute)
      this.contentAttribute = this.content.boardsCategoriesAttribute;
    if (
      !this.contentAttribute &&
      this.content &&
      this.props.boards[this.content.boardsCategoriesId] &&
      this.props.boards[this.content.boardsCategoriesId].attribute
    )
      this.contentAttribute = this.props.boards[
        this.content.boardsCategoriesId
      ].attribute;
    if (
      !this.contentAttribute &&
      this.categoryId &&
      this.props.boards[this.categoryId] &&
      this.props.boards[this.categoryId].attribute
    )
      this.contentAttribute = this.props.boards[this.categoryId].attribute;

    switch (this.props.type) {
      case "edit":
        this.preference = { ...boardTypePreference.edit };
        this.preference.needBody = true;
        if (this.contentAttribute) {
          let categoryPreference;
          if (!this.content.groupParent) {
            this.preference.needTitle = this.contentAttribute.includes(
              BoardAttribute.HAS_TITLE
            );
            this.preference.allowTitleNewLine = this.contentAttribute.includes(
              BoardAttribute.ALLOW_NEW_LINE_ON_TITLE
            );
            this.preference.needBody = this.contentAttribute.includes(
              BoardAttribute.HAS_BODY
            );
            this.preference.showSubCategory =
              ABTestUtil.isTest(ABTestFeature.SUBCATEGORY) &&
              this.contentAttribute.includes(BoardAttribute.HAS_SUBCATEGORY);
            this.preference.showProfile = this.contentAttribute.includes(
              BoardAttribute.UI_VIEWER_PROFILE_ON_POST
            );
            categoryPreference =
              boardTypePreference[this.content.boardsCategoriesId.toString()];

            this.preference.isCommercial = this.contentAttribute.includes(
              BoardAttribute.IS_COMMERCIAL
            );
            this.preference.hasCardnews = this.contentAttribute.includes(
              BoardAttribute.HAS_CARDNEWS
            );

            switch (this.content.boardsCategoriesId) {
              case BoardType.QNA:
                this.preference.subCategories = SUBCATEGORY_QNA_PRESET;
                break;
              case BoardType.CONSULT_QNA:
                this.preference.subCategories = SUBCATEGORY_CONSULT_PRESET;
                break;
              case BoardType.POST:
                this.preference.subCategories = SUBCATEGORY_ETC_PRESET;
                break;
              default:
                this.preference.subCategories = SUBCATEGORY_ALL_PRESET;
                break;
            }
          } else {
            this.preference.showProfile = this.contentAttribute.includes(
              BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY
            );
            if (this.contentAttribute.includes(BoardAttribute.IS_DISCUSSION))
              categoryPreference = boardTypePreference["discussion-reply"];
            else categoryPreference = boardTypePreference["reply"];
          }
          this.preference.canAnonymous = this.contentAttribute.includes(
            BoardAttribute.CAN_ANONYMOUS
          );
          this.preference.isQnA = this.contentAttribute.includes(
            BoardAttribute.IS_QNA
          );
          this.preference.isDiscussion = this.contentAttribute.includes(
            BoardAttribute.IS_DISCUSSION
          );
          this.preference.categoryId = this.content.boardsCategoriesId;

          if (categoryPreference) {
            this.preference.titlePlaceholder =
              categoryPreference.titlePlaceholder;
            this.preference.bodyPlaceholder =
              categoryPreference.bodyPlaceholder;
          }
        }
        break;
      case "reply":
        if (this.contentAttribute.includes(BoardAttribute.IS_DISCUSSION)) {
          this.preference = { ...boardTypePreference["discussion-reply"] };
        } else {
          this.preference = { ...boardTypePreference.reply };
        }
        this.preference.needBody = true;
        if (this.contentAttribute) {
          this.preference.showProfile = this.contentAttribute.includes(
            BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY
          );
          this.preference.canAnonymous = this.contentAttribute.includes(
            BoardAttribute.CAN_ANONYMOUS
          );
          this.preference.isQnA = this.contentAttribute.includes(
            BoardAttribute.IS_QNA
          );
          this.preference.isDiscussion = this.contentAttribute.includes(
            BoardAttribute.IS_DISCUSSION
          );
          this.preference.categoryId = this.content.boardsCategoriesId;
        }

        break;
      case "new":
        if (boardTypePreference[this.categoryId.toString()])
          this.preference = {
            ...boardTypePreference[this.categoryId.toString()],
          };
        else {
          this.preference = { ...boardTypePreference.default };
          this.preference.categoryId = this.categoryId;
        }

        if (this.categoryId && this.contentAttribute) {
          this.preference.needTitle = this.contentAttribute.includes(
            BoardAttribute.HAS_TITLE
          );
          this.preference.allowTitleNewLine = this.contentAttribute.includes(
            BoardAttribute.ALLOW_NEW_LINE_ON_TITLE
          );
          this.preference.needBody = this.contentAttribute.includes(
            BoardAttribute.HAS_BODY
          );
          this.preference.isQnA = this.contentAttribute.includes(
            BoardAttribute.IS_QNA
          );
          this.preference.showProfile = this.contentAttribute.includes(
            BoardAttribute.UI_VIEWER_PROFILE_ON_POST
          );
          this.preference.canAnonymous = this.contentAttribute.includes(
            BoardAttribute.CAN_ANONYMOUS
          );
          this.preference.isDiscussion = this.contentAttribute.includes(
            BoardAttribute.IS_DISCUSSION
          );
          this.preference.isCommercial = this.contentAttribute.includes(
            BoardAttribute.IS_COMMERCIAL
          );
          this.preference.hasCardnews = this.contentAttribute.includes(
            BoardAttribute.HAS_CARDNEWS
          );
          this.preference.showSubCategory =
            ABTestUtil.isTest(ABTestFeature.SUBCATEGORY) &&
            this.contentAttribute.includes(BoardAttribute.HAS_SUBCATEGORY);
        }

        if (this.preference.template) {
          if (this.mounted) {
            this.setState({
              body: this.preference.template,
            });
          } else {
            this.state = {
              ...this.state,
              body: this.preference.template,
            };
          }
        }

        break;
    }
    log(
      LogLevel.UI_LIFECYCLE,
      "HtmlComposer:setPreference",
      this.props.type,
      this.categoryId,
      this.preference,
      this.contentAttribute,
      this.state
    );
  };

  async componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "HtmlComposer:componentDidMount");
    // 안드로이드 뒤로가기 버튼 탭 시
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
    // document.addEventListener("backbutton", this.goBack);
    this.mounted = true;
    if (this.titleInput)
      setTimeout(() => {
        this.titleInput.focus();
      }, 150);
    else if (this.reactQuillRef) {
      setTimeout(() => {
        // this.bodyInput.click();
        this.reactQuillRef.focus();
      }, 150);
    }

    if (
      this.props.type === "new" &&
      this.props.location.state &&
      this.props.location.state.title
    ) {
      log(
        LogLevel.UI_ACTION,
        "HtmlComposer:constructor has title",
        this.props.location.state.title
      );
      this.setState({ title: this.props.location.state.title });
    }
    // this.startAutoSave();
  }

  focus = () => {
    log(LogLevel.UI_ACTION, "HtmlComposer:focus touched empty place");
    if (this.reactQuillRef && this.state.body == "") {
      // setTimeout(() => {
      // this.bodyInput.click();
      this.reactQuillRef.focus();
      // },150);
    }
  };

  handleChange = (value, delta, source, editor) => {
    this.setState({ body: value, bodyText: editor.getText() });
  };

  handleChangeSelection = (range, source, editor) => {
    let str = "";
    if (range) {
      this.currentIndex = range.index;
    }
    // str = Quill.getText();
    // log(LogLevel.UI_ACTION, "handleChangeSelection", range, source, str);
  };

  handleBlur = (previousRange, source, editor) => {
    // log(LogLevel.UI_ACTION, "handleBlur", previousRange, source, editor);
  };

  handleFocus = (range, source, editor) => {
    // log(LogLevel.UI_ACTION, "handleFocus", range, source, editor);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
    log(
      LogLevel.UI_LIFECYCLE,
      "HtmlComposer:componentDidUpdate",
      this.mounted,
      this.props.type,
      !!this.content,
      this.parentId,
      this.id
    );
    if (!this.mounted || this.props.type == "new") {
      log(
        LogLevel.UI_LIFECYCLE,
        "HtmlComposer:componentDidUpdate attribute",
        this.contentAttribute,
        this.categoryId,
        this.props.boards[this.categoryId]
      );
      if (
        !this.contentAttribute &&
        this.categoryId &&
        this.props.boards[this.categoryId] &&
        this.props.boards[this.categoryId].attribute
      ) {
        this.setPreference();
        this.setState({ imagesBase64: [] });
      }
      return;
    }

    if (!this.content && this.props.contents[this.parentId]) {
      let tempContent: BoardContent = this.props.contents[this.parentId];
      if (tempContent.id == this.id) {
        this.content = tempContent;
      } else if (tempContent.replies && tempContent.replies.length > 0) {
        for (let i = 0; i < tempContent.replies.length; i++) {
          if (tempContent.replies[i].id == this.id) {
            this.content = { ...tempContent.replies[i] };
            break;
          }
        }
      }

      if (this.content) {
        this.setInitalContents();
        this.setPreference();
        if (this.contentLoadWait) {
          log(
            LogLevel.UI_LIFECYCLE,
            "HtmlComposer:contentLoadWait",
            this.state
          );
          this.props.progressPopup.hide();
        }
        log(
          LogLevel.UI_LIFECYCLE,
          "HtmlComposer:componentDidUpdate data load done",
          this.state
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.props.progressPopup) this.props.progressPopup.hide();
    // document.removeEventListener("backbutton", this.goBack);
    this.mounted = false;
    // this.stopAutoSave();
  }

  onDone = () => {
    if (!this.isEdited()) {
      if (this.preference.needTitle && !this.state.title) {
        this.props.toastPopup.show("질문 제목은 필수입니다.");
      }
      return;
    }

    let length =
      (this.state.title ? this.state.title.replace(/ /gi, "").length : 0) +
      (this.state.body ? this.state.body.replace(/ /gi, "").length : 0);
    log(
      LogLevel.UI_ACTION,
      "Composer:onDone",
      this.state,
      this.props.type,
      this.preference.isQnA,
      length
    );
    if (
      (this.props.type === "new" || this.props.type === "edit") &&
      this.preference.isQnA &&
      length < 10
    ) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_AMPLITUDE,
        "COMPOSER_SHORT_QUESTION_WARNING",
        "짧은질문경고",
        {}
      );
      this.props.confirmPopup.show({
        image: waitImage,
        title: (
          <div className="common-container">
            <span className="common-color-bold">
              질문의 길이가 너무 짧습니다.
            </span>{" "}
            <span style={{ fontWeight: "normal" }}>(10글자 미만)</span>
          </div>
        ),
        body: (
          <div className="common-container">
            <span className="common-color-highlight">
              "좋은 질문이 좋은 답을 낳는다."
            </span>
            는 격언이 있습니다. <br />
            답변자가 이해하기 좋게 질문을 작성해주세요~ 🙂
            <br />
            <br />
            <span className="common-color-bold">
              가급적 물음표로 끝나는 완성된 문장
            </span>
            으로 작성해 주세요.
          </div>
        ),
        iconImage: null,
        doneText: "질문 수정하기",
      });
      return;
    }

    this.doDone();
  };

  doDone = async () => {
    if (this.doneProcessing) return true;
    this.doneProcessing = true;

    log(LogLevel.UI_ACTION, "HtmlComposer:onDone");
    let showPopup = true;

    if (showPopup && this.props.progressPopup) this.props.progressPopup.show();

    let html = this.state.body;

    let lastContent: BoardContent;
    if (this.props.type == "edit") {
      lastContent = { ...this.content, replies: [] };
    }

    let post: BoardContent = {
      seminarLectureId: this.seminarId,
      ...lastContent,
      bodyHidden: this.state.bodyHidden,
      groupParent: this.parentId,
      boardsCategoriesId: this.preference.categoryId, // 저장될 게시판 id
      isAnonymous: this.state.anonymous, // 익명 게시 여부
      subject: this.state.title, // 제목
      bodyHtml: html, // 본문 텍스트
      imagesBase64: this.state.imagesBase64, // 첨부 이미지
      images: this.state.images,
      filesLocal: this.state.filesLocal, // 첨부 파일
      files: this.state.files,
      usersCustomName: this.state.userCustomName,
      subCategories: this.state.subcategories,
      advertiserCode: this.state.advertiserCode,
      advertiserProductCode: this.state.advertiserProductCode,
      usersBizId: this.state.usersBizId,

      inviteBiz: this.state.inviteBiz,
      videoUrl: this.state.videoUrl,
    };

    if (this.preference.isCommercial)
      post.commercialType = this.state.commercialType;

    if (
      this.preference.hasCardnews &&
      this.state.cardnews &&
      this.state.cardnews.length
    ) {
      post.cards = this.state.cardnews;
    }

    if (this.props.type == "reply") {
      post.usersId = 0;
    }

    let result = await this.preference.api(
      post,
      showPopup ? this.props.progressPopup : null
    );
    log(LogLevel.NONE, "onDone : ", result);

    post.boardsCategoriesAttribute = this.props.boards[
      this.preference.categoryId
    ].attribute;

    try {
      if (showPopup && this.props.progressPopup)
        this.props.progressPopup.hide();
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
    }

    let timeElapse = timerStop();
    if (result) {
      if (this.props.type == "reply") {
        result.usersNickname = this.props.user.me.nickname;
        result.usersProfileUrl = this.props.user.me.profileUrl;
        result.createdAt = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 16);
        let parent = this.props.contents[this.parentId];
        if (parent) {
          parent.replies.push(result);
          this.props.updateContent(parent);
        }
        this.props.loadContent(this.parentId);
        this.props.refreshBoards(BoardType.MY_REPLY);

        // AMPLITUDE
        let tempContent: BoardContent = this.props.contents[this.parentId];
        if (tempContent) {
          let body;
          if (tempContent.subject) body = tempContent.subject.substring(0, 10);
          else if (tempContent.bodyText)
            body = tempContent.bodyText.substring(0, 10);
          let name = "";
          if (tempContent.usersNickname) name = tempContent.usersNickname;
          else tempContent.usersCustomName;
          name = tempContent.usersCustomName;
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_AMPLITUDE,
            "PC_COMPOSER_ANSWER_DONE",
            "PC용_게시물_답글작성",
            {
              게시판이름: this.props.boards[tempContent.boardsCategoriesId]
                ? this.props.boards[tempContent.boardsCategoriesId].name
                : tempContent.boardsCategoriesId,
              게시물id: tempContent.id,
              게시물내용: body,
              글쓴이id: tempContent.usersId,
              글쓴이닉네임: name,
              소요시간: timeElapse,
            }
          );
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "COMPOSER_ANSWER_DONE",
            "게시물_답글작성",
            {
              게시판이름: this.props.boards[tempContent.boardsCategoriesId]
                ? this.props.boards[tempContent.boardsCategoriesId].name
                : tempContent.boardsCategoriesId,
              게시물id: tempContent.id,
              게시물내용: body,
              글쓴이id: tempContent.usersId,
              글쓴이닉네임: name,
              소요시간: timeElapse,
            }
          );
        }
        this.props.checkAccomplished();
        if (this.props.history.length > 1) this.props.history.goBack();
        else this.props.history.replace("/boards/view?id=" + this.parentId);
      } else if (this.props.type == "new") {
        result.usersNickname = this.props.user.me.nickname;
        result.usersProfileUrl = this.props.user.me.profileUrl;
        result.createdAt = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 16);
        this.props.refreshBoards();
        this.props.refreshBoards(BoardType.MY_POST);
        this.props.updateContent(result);
        this.props.loadContent(result.id);

        // AMPLITUDE
        let body;
        if (result.subject) body = result.subject.substring(0, 10);
        else if (result.bodyText) body = result.bodyText.substring(0, 10);
        let name = "";
        if (result.usersNickname) name = result.usersNickname;
        else result.usersCustomName;
        name = result.usersCustomName;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_AMPLITUDE,
          "PC_COMPOSER_NEW_DONE",
          "PC용_게시물_새글작성",
          {
            게시판이름: this.props.boards[this.preference.categoryId]
              ? this.props.boards[this.preference.categoryId].name
              : this.preference.categoryId,
            게시물내용: body,
            글쓴이id: result.usersId,
            글쓴이닉네임: name,
            소요시간: timeElapse,
          }
        );
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "COMPOSER_NEW_DONE",
          "게시물_새글작성",
          {
            게시판이름: this.props.boards[this.preference.categoryId]
              ? this.props.boards[this.preference.categoryId].name
              : this.preference.categoryId,
            게시물내용: body,
            글쓴이id: result.usersId,
            글쓴이닉네임: name,
            소요시간: timeElapse,
          }
        );
        this.props.checkAccomplished();
        this.props.history.replace(`/boards/view?id=${result.id}`);
      } else if (this.props.type == "edit") {
        this.props.updateContent(result);
        this.props.loadContent(this.id);

        // AMPLITUDE
        let body;
        if (this.content.subject) body = this.content.subject.substring(0, 10);
        else if (this.content.bodyText)
          body = this.content.bodyText.substring(0, 10);
        let name = "";
        if (this.content.usersNickname) name = this.content.usersNickname;
        else this.content.usersCustomName;
        name = this.content.usersCustomName;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_AMPLITUDE,
          "PC_COMPOSER_EDIT_DONE",
          "PC용_게시물_수정시작",
          {
            게시판이름: this.props.boards[this.content.boardsCategoriesId]
              ? this.props.boards[this.content.boardsCategoriesId].name
              : this.content.boardsCategoriesId,
            게시물id: this.content.id,
            게시물내용: body,
            글쓴이id: this.content.usersId,
            글쓴이닉네임: name,
            소요시간: timeElapse,
          }
        );
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "COMPOSER_EDIT_DONE",
          "게시물_수정시작",
          {
            게시판이름: this.props.boards[this.content.boardsCategoriesId]
              ? this.props.boards[this.content.boardsCategoriesId].name
              : this.content.boardsCategoriesId,
            게시물id: this.content.id,
            게시물내용: body,
            글쓴이id: this.content.usersId,
            글쓴이닉네임: name,
            소요시간: timeElapse,
          }
        );

        if (this.props.history.length > 1) this.props.history.goBack();
        else this.props.history.replace("/boards/view?id=" + this.parentId);
      }
    } else {
      log(LogLevel.UI_EXCEPTION, "HtmlComposer.onPressDone : fail - ", result);
      // this.props.navigation.push("AuthRegister");
    }

    this.doneProcessing = false;
  };

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:goBack()", e, this.state);
    if (
      !this.state.title &&
      this.state.filesLocal.length == 0 &&
      this.state.imagesBase64.length == 0 &&
      !this.state.body
    ) {
      this.confirmGoBack();
    } else if (
      !this.props.imageViewerPopup.isShown() &&
      !this.props.confirmPopup.isShown()
    ) {
      this.props.confirmPopup.show({
        title: (
          <Flex
            direction="column"
            gap="12px"
            customStyle={{ textAlign: "center" }}
          >
            <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
              정말로 나가실건가요?
            </Text>
            <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[600]}>
              입력하신 내용은 저장되지 않습니다.
            </Text>
          </Flex>
        ),
        body: null,
        iconImage: null,
        onCancel: this.confirmGoBack,
        buttons: [
          <Button
            color="Secondary"
            size="Large"
            variant="Tinted"
            type="Text"
            onClick={this.props.confirmPopup.hide}
          >
            취소
          </Button>,
          <Button
            color="Negative"
            size="Large"
            variant="Contained"
            type="Text"
            onClick={this.confirmGoBack}
          >
            나가기
          </Button>,
        ],
      });
    }
  };

  confirmGoBack = () => {
    this.props.confirmPopup.hide();
    log(LogLevel.UI_ACTION, "HtmlComposer:goBack()", this.props);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_AMPLITUDE,
      "PC_COMPOSER_EDIT_CANCEL",
      "PC용_게시물_작성중취소",
      {
        소요시간: timerStop(),
      }
    );
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "COMPOSER_EDIT_CANCEL",
      "게시물_작성중취소",
      { 소요시간: timerStop() }
    );
    if (this.props.history.length > 1) this.props.history.goBack();
    else this.props.history.replace("/boards/view?id=" + this.parentId);
  };

  onCustomNameChange = (userCustomName: string) => {
    this.setState({ userCustomName });
  };

  onTitleChange = (e) => {
    // console.dir(event);
    this.setState({ title: e.target.value });
  };

  onBodyChange = (e) => {
    let body = e.target.value;
    // for(let i = 0; i<body.length; i++){
    //   console.log("Character at ",i, body.charCodeAt(i));
    // }
    this.setState({ body });
  };

  onImageSelected = async (e) => {
    // console.dir(e);
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageSelected", e.target.files);
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let targets = [...e.target.files];
      e.target.value = null;
      for (let i = 0; i < targets.length; i++) {
        let base64 = await loadImageBase64(targets[i]);
        if (base64) this.onImageLoad(base64);
      }
    }
    this.props.progressPopup.hide();
  };

  onRemoveLocalImage = (i) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onRemoveFile", i);
    let files = [...this.state.imagesBase64];
    files.splice(i, 1);
    this.setState({ imagesBase64: files });
  };

  onRemoveImage = (i) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onRemoveFile", i);
    let files = [...this.state.images];
    files.splice(i, 1);
    this.setState({ images: files });
  };

  onImageLoad = (base64) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageLoad", base64.length);
    this.reactQuillRef
      .getEditor()
      .insertEmbed(this.currentIndex, "image", base64);
    this.reactQuillRef.getEditor().setSelection(this.currentIndex + 1);
    // this.setState({imagesBase64:[...this.state.imagesBase64, base64]});
  };

  onFileSelected = async (e) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageSelected", e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      this.props.progressPopup.show();
      this.props.progressPopup.setLabel("파일 로딩 중...");
      let filesLocal = [...this.state.filesLocal];
      let targets = [...e.target.files];
      e.target.value = null;
      for (let i = 0; i < targets.length; i++) {
        if (targets[i].type.startsWith("image")) {
          let base64 = await loadImageBase64(targets[i]);
          if (base64) this.onImageLoad(base64);
        } else {
          filesLocal.push(targets[i]);
        }
      }
      this.setState({ filesLocal });

      this.props.progressPopup.hide();
    }
  };

  onRemoveLocalFile = (i) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onRemoveFile", i);
    let files = [...this.state.filesLocal];
    files.splice(i, 1);
    this.setState({ filesLocal: files });
  };

  onRemoveFile = (i) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onRemoveFile", i);
    let files = [...this.state.files];
    files.splice(i, 1);
    this.setState({ files: files });
  };

  onEditorFocus = (e) => {
    log(LogLevel.UI_EVENT, "HtmlComposer:onEditorFocus", e);
    if (isIOS) {
      // $(".html-composer-footer-ios").show();
      // $(".html-composer-footer-container").addClass("html-composer-footer-container-onkeyboard");
      // $(".html-composer-footer-buttonbar-container").addClass("html-composer-footer-buttonbar-container-onkeyboard");
    }
  };

  onEditorBlur = (e) => {
    // console.log("blur", e)
    if (isIOS) {
      // $(".html-composer-footer-ios").hide();
      // $(".html-composer-footer-container").removeClass("html-composer-footer-container-onkeyboard");
      // $(".html-composer-footer-buttonbar-container").removeClass("html-composer-footer-buttonbar-container-onkeyboard");
    }
  };

  onCamera = () => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onCamera");
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") return;

    if (
      windowAny.navigator &&
      windowAny.navigator.camera &&
      windowAny.navigator.camera.getPicture
    ) {
      windowAny.navigator.camera.getPicture(
        this.onCameraSuccess,
        this.onCameraFail,
        {
          quality: 80,
          destinationType: windowAny.Camera.DestinationType.DATA_URL,
          encodingType: windowAny.Camera.EncodingType.JPEG,
          mediaType: windowAny.Camera.MediaType.PICTURE,
          correctOrientation: true,
          targetWidth: 1920,
          targetHeight: 1920,
        }
      );
    }
  };

  onCameraSuccess = (imageURI) => {
    // this.setState({imagesBase64:[...this.state.imagesBase64, "data:image/jpeg;base64," + imageURI]});
    this.onImageLoad("data:image/jpeg;base64," + imageURI);
  };

  onCameraFail = (message) => {
    log(
      LogLevel.UI_EXCEPTION,
      "HtmlComposer:onCameraFail Failed because: ",
      message
    );
  };

  onTitleKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onKeyPress", e.keyCode, e.shiftKey);
    if (!this.preference.allowTitleNewLine && e.keyCode == 13) {
      e.preventDefault();
    }
  };

  renderCommercialType() {
    if (this.preference.isCommercial) {
      return (
        <div className="common-container-row-wrap common-flex-align-center">
          <div
            className={
              "common-toggle" +
              (this.state.commercialType == BoardContentCommercialType.NONE
                ? " common-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ commercialType: BoardContentCommercialType.NONE })
            }
          >
            NONE
          </div>
          <div
            className={
              "common-toggle" +
              (this.state.commercialType == BoardContentCommercialType.ADVERTISE
                ? " common-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({
                commercialType: BoardContentCommercialType.ADVERTISE,
              })
            }
          >
            AD
          </div>
          <div
            className={
              "common-toggle" +
              (this.state.commercialType == BoardContentCommercialType.SPONSORED
                ? " common-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({
                commercialType: BoardContentCommercialType.SPONSORED,
              })
            }
          >
            SPONSORED
          </div>
          {this.state.commercialType != BoardContentCommercialType.NONE && [
            <IonInput
              key="3"
              class="html-composer-advertise-input"
              value={this.state.usersBizId}
              placeholder="partnerId"
              onIonChange={(e) => this.setState({ usersBizId: e.detail.value })}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />,
            <IonInput
              key="1"
              class="html-composer-advertise-input"
              value={this.state.advertiserCode}
              placeholder="광고코드(ex.YMYD)"
              onIonChange={(e) =>
                this.setState({ advertiserCode: e.detail.value })
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            />,
            <IonInput
              key="2"
              class="html-composer-advertise-input"
              value={this.state.advertiserProductCode}
              placeholder="광고상품(ex.APP)"
              onIonChange={(e) =>
                this.setState({ advertiserProductCode: e.detail.value })
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            />,
          ]}
        </div>
      );
    }
    return null;
  }

  renderTitle() {
    log(
      LogLevel.UI_ACTION,
      "HtmlComposer:renderTitle",
      this.preference.needTitle
    );
    if (this.preference.needTitle) {
      // let question;
      // if (this.preference.isQnA) question = <img className="html-composer-title-icon" src={qustionIcon} />;
      return (
        <div className="html-composer-title-container">
          {/* {question} */}
          <div className="html-composer-title-input-container">
            <Textarea
              minRows={1}
              maxRows={this.preference.allowTitleNewLine ? 30 : 4}
              inputRef={(ref) => {
                this.titleInput = ref;
              }}
              className="html-composer-title-input"
              value={this.state.title}
              placeholder={this.preference.titlePlaceholder}
              onChange={this.onTitleChange}
              onFocus={this.onEditorFocus}
              onBlur={this.onEditorBlur}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={this.onTitleKeyPress}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderImageAddButton() {
    return (
      <div className="html-composer-image-add-container">
        <IonButton color="html-composer-image-add-button" fill="clear">
          <IonIcon class="html-composer-image-add-icon" name="add" />
        </IonButton>
        <input
          className="html-composer-image-add-file"
          type="file"
          accept="image/*"
          multiple
          onChange={this.onImageSelected}
        />
      </div>
    );
  }

  renderCameraButton() {
    let camera;
    let os = getGlobal(GlobalKey.OS);
    if (os && os != "browser") {
      camera = (
        <IonButton
          color="html-composer-footer-button"
          fill="clear"
          onClick={this.onCamera}
        >
          <IonIcon class="html-composer-footer-button-icon" name="camera" />
        </IonButton>
      );
    }
    return camera;
  }

  renderSubCategory() {
    if (
      !this.preference.showSubCategory ||
      !this.preference.subCategories ||
      !this.preference.subCategories.length
    ) {
      return null;
    }
    if (this.state.foldSubcategory) {
      return (
        <Flex
          gap="8px"
          alignItems="center"
          justifyContent="space-between"
          customStyle={{
            padding: "12px",
            backgroundColor: COLOR_SYSTEM.get("Gray")[10],
            borderWidth: "1px 0",
            borderStyle: "solid",
            borderColor: COLOR_SYSTEM.get("Gray")[50],
            transform: "translateZ(0)",
          }}
        >
          <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
            카테고리
          </Text>
          <Static customStyle={{ flex: 1 }}>
            <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[800]}>
              {this.state.subcategories && this.state.subcategories.length
                ? this.state.subcategories.map((item) => item.name).join(", ")
                : "없음"}
            </Text>
          </Static>
          <Button
            color="Tertiary"
            variant="Ghost"
            size="Medium"
            type="Icon"
            icon="PolygonDown"
            onClick={(e) => {
              this.setState({ foldSubcategory: false });
              e.stopPropagation();
            }}
          />
        </Flex>

        // <div className="html-composer-subcategory-container">
        //   <div className="common-container-row">
        //     <div className="html-composer-subcategory-title">카테고리</div>
        //     <div className="html-composer-subcategory-body">
        //       {this.state.subcategories && this.state.subcategories.length
        //         ? this.state.subcategories.map((item) => item.name).join(", ")
        //         : "없음"}
        //     </div>
        //     <IonButton
        //       color="common-button"
        //       class="html-composer-subcategory-modify-button"
        //       onClick={(e) => {
        //         this.setState({ foldSubcategory: false });
        //         e.stopPropagation();
        //       }}
        //     >
        //       수정
        //     </IonButton>
        //   </div>
        // </div>
      );
    } else
      return (
        <Static
          customStyle={{
            padding: "14px 20px",
            backgroundColor: COLOR_SYSTEM.get("Gray")[10],
            borderWidth: "1px 0 1px 0",
            borderStyle: "solid",
            borderColor: COLOR_SYSTEM.get("Gray")[50],
          }}
        >
          <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
            카테고리
          </Text>
          <Flex
            gap="8px 12px"
            flexWrap="wrap"
            customStyle={{ marginTop: "14px" }}
          >
            {this.preference.subCategories.map((item, index) => {
              return (
                // <div
                //   className={
                //     "html-composer-subcategory-item-toggle" +
                //     (this.state.subcategories.findIndex((category, index) => {
                //       return item.id == category.id;
                //     }) > -1
                //       ? " html-composer-subcategory-item-toggle-selected"
                //       : "")
                //   }
                //   onClick={(e) => this.onClickSubcategory(item, e)}
                // >
                //   {item.name}
                // </div>
                <ToggleButton
                  color="Skyblue"
                  size="Medium"
                  variant={
                    this.state.subcategories.findIndex((category, index) => {
                      return item.id === category.id;
                    }) > -1
                      ? "OutlinedTinted"
                      : "Outlined"
                  }
                  active={
                    this.state.subcategories.findIndex((category, index) => {
                      return item.id === category.id;
                    }) > -1
                  }
                  onClick={(e) => this.onClickSubcategory(item, e)}
                >
                  {item.name}
                </ToggleButton>
              );
            })}
          </Flex>
          {ABTestUtil.isTest(ABTestFeature.UI_INVITE_BIZ) &&
            this.state.subcategories.findIndex((category, index) => {
              return ["세무", "노무", "경영"].includes(category.name);
            }) > -1 && (
              <div
                className="html-composer-subcategory-invite-container"
                onClick={(e) =>
                  this.setState({ inviteBiz: !this.state.inviteBiz })
                }
              >
                <CheckBox
                  size="Small"
                  checked={this.state.inviteBiz}
                  onClick={(e) => {}}
                />
                <div className="html-composer-subcategory-invite-description">
                  <div className="html-composer-subcategory-invite-description-title">
                    <Text
                      textType="Body2Medium"
                      color={COLOR_SYSTEM.get("Gray")[700]}
                    >
                      {this.state.subcategories
                        .filter((item) =>
                          ["세무", "노무", "경영"].includes(item.name)
                        )
                        .map((item) => item.name)
                        .join(", ")}{" "}
                      전문가 답변 허용
                    </Text>
                  </div>
                  <Text
                    textType="Caption"
                    color={COLOR_SYSTEM.get("Gray")[600]}
                  >
                    선택한 카테고리에 해당하는 외부 전문가(약사 외)에게 질문을
                    전달하고 답변을 받습니다. <br /> 해당 질문의 내용만을
                    전달하며 다른 약사의 댓글, 답변 등의 다른 모든 글들은 열람할
                    수 없습니다.
                  </Text>
                </div>
              </div>
            )}
          <Button
            style={{ width: "100%", marginTop: "12px" }}
            type="Text"
            variant="Contained"
            color="Secondary"
            size="Medium"
            onClick={(e) => {
              this.setState({ foldSubcategory: true });
              e.stopPropagation();
            }}
          >
            {" "}
            선택 완료
          </Button>
        </Static>
      );
  }

  onClickSubcategory = (item, e) => {
    let subcategories = [...this.state.subcategories];
    let index = subcategories.findIndex((category, index) => {
      return item.id == category.id;
    });
    // console.log(index);
    if (index == -1) {
      subcategories.push(item);

      if (
        ABTestUtil.isTest(ABTestFeature.UI_INVITE_BIZ) &&
        ["세무", "노무", "경영"].includes(item.name)
      ) {
        this.setState({ inviteBiz: true });
      }
    } else {
      subcategories.splice(index, 1);

      if (
        ABTestUtil.isTest(ABTestFeature.UI_INVITE_BIZ) &&
        this.state.subcategories.findIndex((category, index) => {
          return ["세무", "노무", "경영"].includes(category.name);
        }) == -1
      ) {
        this.setState({ inviteBiz: false });
      }
    }

    this.setState({ subcategories });

    e.stopPropagation();
  };

  onDeleteImage = (index: number) => {
    if (index < this.state.images.length) {
      let images = [...this.state.images];
      images.splice(index, 1);
      this.setState({ images });
    } else {
      let imagesBase64 = [...this.state.imagesBase64];
      imagesBase64.splice(index - this.state.images.length, 1);
      this.setState({ imagesBase64 });
    }
  };

  showImagePopup(index: number) {
    this.props.imageViewerPopup.setDeletable(this.onDeleteImage);
    this.props.imageViewerPopup.setImages([
      ...this.state.images,
      ...this.state.imagesBase64,
    ]);
    this.props.imageViewerPopup.show(index);
  }

  isEdited = () => {
    if (
      (this.preference.needTitle && this.state.title) ||
      (!this.preference.needTitle &&
        (this.state.body.length > 0 ||
          this.state.filesLocal.length > 0 ||
          this.state.imagesBase64.length > 0))
    )
      return true;
    return false;
  };

  // showParent = () => {
  //   log(LogLevel.UI_ACTION, "HtmlComposer:showParent");
  //   if (this.showParentPopup) this.showParentPopup.show();
  // };

  onSelectRecommends = (id) => {
    log(
      LogLevel.UI_ACTION,
      "HtmlComposer:onSelectRecommends",
      this.showParentPopup,
      id
    );
    if (this.showParentPopup) this.showParentPopup.show(id);
  };

  onJumpToViewer = (id) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onJumpToViewer", id);
    this.props.history.replace(`/boards/view?id=${id}`);
  };

  onAddComponent = (data) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onAddComponent", data);
    // this.reactQuillRef.getEditor().insertEmbed(range.index, 'keywords', {text:text, id:item.id, type:item.type});

    this.reactQuillRef
      .getEditor()
      .insertEmbed(this.currentIndex, "components", data);
    this.reactQuillRef.getEditor().insertText(this.currentIndex + 1, " ");
    this.reactQuillRef.getEditor().setSelection(this.currentIndex + 2);
  };

  onCardnewsDone = (cardnews: Attachment[]) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onCardnewsDone", cardnews);
    this.setState({ cardnews });
  };

  onHiddenTextDone = (bodyHidden: string) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onHiddenTextDone", bodyHidden);
    this.setState({ bodyHidden });
  };

  showComponentComposer = () => {
    let me = this.props.user.me;
    let isAdmin: boolean = me.level >= UserLevel.MANAGER;
    if (!isAdmin) return;
    if (this.ivComponentComposerPopup) this.ivComponentComposerPopup.show();
  };

  showCardnewsComposer = () => {
    if (!this.preference.hasCardnews) return;
    if (this.ivCardnewsComposerPopup) this.ivCardnewsComposerPopup.show();
  };

  showHiddenTextComposer = () => {
    let me = this.props.user.me;
    let isAdmin: boolean = me.level >= UserLevel.MANAGER;
    if (!isAdmin) return;
    if (this.ivHiddenTextComposerPopup) this.ivHiddenTextComposerPopup.show();
  };

  showVideoUrl = () => {
    let me = this.props.user.me;
    let isAdmin: boolean = me.level >= UserLevel.MANAGER;
    if (!isAdmin) return;
    if (this.props.confirmPopup)
      this.props.confirmPopup.show({
        title: "비디오의 URL을 입력해주세요.",
        onDone: (testInput: string) => {
          this.setState({ videoUrl: testInput });
        },
        needInput: true,
        defaultText: this.state.videoUrl,
        iconImage: null,
        doneText: "저장",
        cancelText: "취소",
      });
  };

  render() {
    let me = this.props.user.me;
    let isAdmin: boolean = me.level >= UserLevel.MANAGER;
    let edited: boolean = this.isEdited();
    log(
      LogLevel.UI_LIFECYCLE,
      "HtmlComposer.render",
      me,
      edited,
      this.state.anonymous,
      this.preference,
      this.state
    );

    let ivComponentButton;
    let ivComponentComposer;
    let ivCardnewsButton;
    let ivCardnewsComposer;
    let ivHiddenTextButton;
    let ivHiddenTextComposer;
    let ivVideoButton;

    if (isAdmin) {
      // if(false){
      ivComponentButton = (
        <div
          className="html-composer-toolbar-button-container common-color-caution common-bold"
          onClick={this.showComponentComposer}
        >
          Co
        </div>
      );
      ivComponentComposer = (
        <ComponentComposePopup
          ref={(ref) => {
            this.ivComponentComposerPopup = ref;
          }}
          onDone={this.onAddComponent}
        />
      );

      ivHiddenTextButton = (
        <div
          className={
            "html-composer-toolbar-button-container common-bold" +
            (this.state.bodyHidden ? " common-color-highlight" : "")
          }
          onClick={this.showHiddenTextComposer}
        >
          H
        </div>
      );
      ivHiddenTextComposer = (
        <ComposeHiddenTextPopup
          ref={(ref) => {
            this.ivHiddenTextComposerPopup = ref;
          }}
          progressPopup={this.props.progressPopup}
          hiddenText={this.state.bodyHidden}
          onDone={this.onHiddenTextDone}
        />
      );

      ivVideoButton = (
        <div
          className="html-composer-toolbar-button-container common-color-caution common-bold"
          onClick={this.showVideoUrl}
        >
          Vi
        </div>
      );
    }

    if (this.preference.hasCardnews) {
      ivCardnewsButton = (
        <div
          className="html-composer-toolbar-button-container common-color-highlight common-bold"
          onClick={this.showCardnewsComposer}
        >
          Ca
        </div>
      );
      ivCardnewsComposer = (
        <ComposeCardnewsPopup
          ref={(ref) => {
            this.ivCardnewsComposerPopup = ref;
          }}
          progressPopup={this.props.progressPopup}
          cardnews={this.state.cardnews}
          onDone={this.onCardnewsDone}
        />
      );
    }

    let toolbar;
    if (this.preference.needBody) {
      toolbar = (
        <div id="toolbar">
          <button className="ql-bold" />
          <button className="ql-underline" />
          <button className="ql-blockquote" />
          {isAdmin && <button className="ql-link" />}
          <button className="ql-color" value="blue" />
          <button className="ql-background" value="yellow" />
          <div className="html-composer-toolbar-button-container">
            <IonIcon class="html-composer-toolbar-button-icon" name="image" />
            <input
              className="html-composer-toolbar-button-file"
              multiple={true}
              type="file"
              accept="image/*"
              onChange={this.onImageSelected}
            />
          </div>
          <div className="html-composer-toolbar-button-container">
            <IonIcon class="html-composer-toolbar-button-icon" name="attach" />
            <input
              className="html-composer-toolbar-button-file"
              multiple={true}
              type="file"
              onChange={this.onFileSelected}
            />
          </div>
          {ivComponentButton}
          {ivCardnewsButton}
          {ivHiddenTextButton}
          {ivVideoButton}
        </div>
      );
    }

    let anonymous;
    if (this.preference.canAnonymous) {
      anonymous = (
        <div className="html-composer-anonymous-container">
          <IonToggle
            class="html-composer-anonymous-toggle"
            mode="ios"
            checked={this.state.anonymous}
            onClick={(e) => this.setState({ anonymous: !this.state.anonymous })}
          />
          <div
            className={
              this.state.anonymous
                ? "html-composer-anonymous-button"
                : "html-composer-anonymous-button-disabled"
            }
            onClick={(e) => this.setState({ anonymous: !this.state.anonymous })}
          >
            {" "}
            익명으로 작성{this.state.anonymous ? "중  " : "하기"}{" "}
          </div>
        </div>
      );
    }
    let showParentButton;
    // let showParentPopup;
    if (
      (this.props.type === "reply" && this.parentId) ||
      (this.props.type === "edit" && this.content && this.content.groupParent)
    ) {
      let seeParentTerm = "질문보기";
      if (this.preference.isDiscussion) seeParentTerm = "발제 보기";
      showParentButton = (
        // <IonButton color="html-composer-show-parent" onClick={this.showParent}>
        //   <div className="html-composer-show-parent">{seeParentTerm}</div>
        // </IonButton>
        <Button
          color="Secondary"
          variant="Ghost"
          size="Small"
          type="IconWithText"
          icon="CaretRight"
          right
          onClick={() => {
            this.props.bottomSheet.show({
              body: (
                <ViewerBody
                  postingId={this.parentId}
                  noComment={true}
                  open={true}
                  noToolbar={true}
                  noNavigate={true}
                  isOnlyQuestion
                />
              ),
            });
          }}
        >
          {seeParentTerm}
        </Button>
      );
      // showParentPopup = (
      //   <ViewerBodyPopup
      //     ref={(ref) => (this.showParentPopup = ref)}
      //     parentId={this.parentId}
      //     isDiscussion={this.preference.isDiscussion}
      //     noToolbar={true}
      //   />
      // );
    } else if (
      ABTestUtil.isTest(ABTestFeature.UI_COMPOSER_RECOMMEND) &&
      this.props.type == "new" &&
      this.preference.isQnA &&
      this.state.recommendStatus
    ) {
      let seeParentTerm = "유사글 보기";
      if (this.state.showRecommends) seeParentTerm = "유사글 숨기기";
      let newMark;
      if (this.state.recommendStatus == 2 && !this.state.showRecommends)
        newMark = <div className="html-composer-show-parent-new">N</div>;
      showParentButton = (
        <IonButton
          color="html-composer-show-parent"
          onClick={() => {
            this.setState({
              showRecommends: !this.state.showRecommends,
              recommendStatus: 3,
            });
            setGlobal(
              GlobalKey.SHOW_RECOMMEND_ON_COMPOSER,
              !this.state.showRecommends,
              true
            );
          }}
        >
          <div className="html-composer-show-parent">
            {seeParentTerm}
            {newMark}
          </div>
        </IonButton>
      );

      // showParentPopup = (
      //   <ViewerBodyPopup
      //     ref={(ref) => (this.showParentPopup = ref)}
      //     full={true}
      //     closeText="닫기"
      //   /> // onJumpTo={this.onJumpToViewer} />
      // );
    }

    let name = me.nickname;
    let profileUrl = me.profileUrl;
    if (
      this.props.type === "edit" &&
      this.content &&
      this.content.usersId !== me.id
    ) {
      name = this.content.usersNickname;
      profileUrl = this.content.usersProfileUrl;
    }
    let profile;
    if (this.preference.showProfile) {
      profile = (
        <div className="html-composer-profile-container">
          <Profile
            name={name}
            customName={this.state.userCustomName}
            withProfile={!this.state.anonymous}
            profileUrl={profileUrl}
            onCustomNameChanged={
              this.state.anonymous ? this.onCustomNameChange : null
            }
            anonymous={this.state.anonymous}
          />
          {anonymous}
        </div>
      );
    }

    let hasBody =
      (this.state.body.length > 0 &&
        this.state.body != "<div><br></div>" &&
        this.state.body != '<div style="user-select: auto;"><br></div>' &&
        this.state.body != '<div style="user-select:auto"><br></div>') ||
      this.state.filesLocal.length > 0 ||
      this.state.imagesBase64.length > 0;

    let bodyEditor;
    log(
      LogLevel.UI_LIFECYCLE,
      "HtmlComposer.render bodyEditor",
      this.preference,
      this.preference.needBody
    );
    if (this.preference.needBody) {
      bodyEditor = (
        <div
          className="html-composer-body-container"
          onClick={(e) => log(LogLevel.UI_ACTION, e, e.target)}
        >
          <ReactQuill
            style={{ width: "100%" }}
            ref={(el) => {
              this.reactQuillRef = el;
            }}
            theme={"snow"}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChangeSelection={this.handleChangeSelection}
            value={this.state.body}
            modules={HtmlComposer.modules}
            formats={HtmlComposer.formats}
            // toolbar={false}
            // placeholder={this.preference.bodyPlaceholderText}
          />
          {/* <span className={this.state.body.length?"html-composer-hidden":"html-composer-content-placeholder"}>{this.preference.bodyPlaceholder}</span> */}
          <span
            className={
              hasBody ? "composer-hidden" : "composer-content-placeholder"
            }
            onClick={() => this.focus()}
          >
            {this.preference.bodyPlaceholder}
          </span>
          <div className="html-composer-files-container">
            {this.state.files.map((file, i) => (
              <AttachmentViewer
                key={i.toString()}
                name={file.name}
                size={file.size}
                onDelete={() => this.onRemoveFile(i)}
              />
            ))}
            {this.state.filesLocal.map((file, i) => (
              <AttachmentViewer
                key={i.toString()}
                name={file.name}
                size={file.size}
                onDelete={() => this.onRemoveLocalFile(i)}
              />
            ))}
          </div>
        </div>
      );
    }

    return (
      <>
        {/* <IonHeader class="html-composer-header" no-border>
          <IonToolbar color="common-header-composer-toolbar">
            <IonButtons slot="start">
              <IonButton class="html-composer-goback" onClick={this.goBack}>
                <IonIcon class="html-composer-goback-icon" name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="html-composer-title">{this.preference.menuTitle}</IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              {showParentButton}
              <IonButton onClick={this.onDone}>
                <div className={edited ? "html-composer-button" : "html-composer-button-disabled"}>완료</div>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader> */}

        <header>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            customStyle={{
              height: "48px",
              padding: "0 8px",
            }}
          >
            <Button
              size="Medium"
              type="Text"
              color="Tertiary"
              variant="Ghost"
              onClick={this.goBack}
            >
              취소
            </Button>
            <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[700]}>
              {this.preference.menuTitle}
            </Text>
            <Button
              size="Medium"
              type="Text"
              color="Primary"
              variant="Ghost"
              disabled={!edited}
              disabledColor={!edited}
              onClick={this.onDone}
            >
              등록
            </Button>
          </Flex>
          {this.content && (
            <Flex
              alignItems="center"
              customStyle={{
                height: "52px",
                padding: "14px 20px",
                backgroundColor: COLOR_SYSTEM.get("Gray")[10],
                borderWidth: "1px 0",
                borderStyle: "solid",
                borderColor: COLOR_SYSTEM.get("Gray")[50],
              }}
            >
              <Text
                textType="Body2Bold"
                color={COLOR_SYSTEM.get("Gray")[800]}
                numberOfLines={1}
                style={{ flex: 1 }}
              >
                {this.content.subject}
              </Text>
              {showParentButton}
            </Flex>
          )}
        </header>

        <IonContent
          class="html-composer-content"
          onClick={(e) => {
            e.stopPropagation();
            // this.focus();
          }}
        >
          {/* {showParentPopup} */}
          {/* <div className="html-composer-container"> */}
          <div>
            {this.renderCommercialType()}
            {this.state.cardnews && this.state.cardnews.length > 0 && (
              <AttachmentViewer
                name="Cardnews"
                onPress={this.showCardnewsComposer}
              />
            )}
            {this.renderSubCategory()}
            {/* {profile} */}
            {this.renderTitle()}
            {toolbar}
            {bodyEditor}
            <Static
              customStyle={{
                margin: "20px 0",
                padding: "0 10px",
                transform: "translateZ(0)",
              }}
            >
              <Button
                color="Tertiary"
                variant="Outlined"
                size="Medium"
                type="IconWithText"
                icon="Exclamation Mark Filled"
                left
                onClick={(e) => {
                  e.stopPropagation();

                  this.props.bottomSheet.show({
                    title: {
                      value: "게시글 작성 원칙",
                      align: "left",
                    },
                    body: (
                      <div>
                        <Flex
                          direction="column"
                          gap="8px"
                          customStyle={{ marginTop: "16px" }}
                        >
                          <Flex gap="12px" alignItems="center">
                            <img
                              src={handshake}
                              alt=""
                              width={24}
                              height={24}
                            />
                            <Text
                              textType="Body1"
                              color={COLOR_SYSTEM.get("Gray")[900]}
                            >
                              서로 존중하며 소통합니다.
                            </Text>
                          </Flex>
                          <Flex gap="12px" alignItems="center">
                            <img src={memo} alt="" width={24} height={24} />
                            <Text
                              textType="Body1"
                              color={COLOR_SYSTEM.get("Gray")[900]}
                            >
                              게시판의 목적에 맞는 글을 작성합니다.
                            </Text>
                          </Flex>
                          <Flex gap="12px" alignItems="center">
                            <img
                              src={prohibited}
                              alt=""
                              width={24}
                              height={24}
                            />
                            <Text
                              textType="Body1"
                              color={COLOR_SYSTEM.get("Gray")[900]}
                            >
                              광고, 홍보성 글은 작성하지 않습니다.
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex
                          direction="column"
                          gap="4px"
                          customStyle={{
                            marginTop: "24px",
                            padding: "12px 12px 20px 12px",
                            backgroundColor: COLOR_SYSTEM.get("Gray")[15],
                            borderRadius: "12px",
                          }}
                        >
                          <Flex gap="4px" alignItems="center">
                            <Icon
                              name="Exclamation Mark Filled"
                              width={16}
                              height={16}
                              color={COLOR_SYSTEM.get("Red")[300]}
                            />
                            <Text
                              textType="Body1Bold"
                              color={COLOR_SYSTEM.get("Red")[300]}
                            >
                              작성원칙 위반 시
                            </Text>
                          </Flex>
                          <Flex
                            as="ul"
                            direction="column"
                            gap="4px"
                            customStyle={{
                              paddingInlineStart: "18px",
                              "& > li": {
                                listStyleType: "disc",
                                "&::marker": {
                                  fontSize: "10px",
                                },
                              },
                            }}
                          >
                            <li>
                              <Text
                                textType="Body2"
                                color={COLOR_SYSTEM.get("Gray")[900]}
                              >
                                커뮤니티 운영 원칙에 따라 계정이 정지되거나,
                                글이 숨겨질 수 있습니다.
                              </Text>
                            </li>
                          </Flex>
                        </Flex>
                      </div>
                    ),
                  });
                }}
              >
                게시글 작성 운영정책 확인하기
              </Button>
            </Static>
          </div>
          {/* <div className="html-composer-reommends-container">
            <ComposerRecommends
              title={this.state.title}
              body={this.state.bodyText}
              disabled={!ABTestUtil.isTest(ABTestFeature.UI_COMPOSER_RECOMMEND)}
              show={this.state.showRecommends}
              onStatusChanged={(status) => {
                this.setState({ recommendStatus: status });
              }} // 0: not enough, 1: searching, 2:ready, 3:checked
              onSelect={this.onSelectRecommends}
            />
          </div> */}
          {ivComponentComposer}
          {ivCardnewsComposer}
          {ivHiddenTextComposer}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  boards: state.board.boards,
  boardList: state.board.boardList,
  contents: state.board.contents,
  filePath: state.board.filePath,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  imageViewerPopup: state.ui.popups[UIPopupType.IMAGE_VIEWER_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  refreshBoards: (board: BoardType = BoardType.ALL) =>
    actions.board.refreshBoards(board),
  updateContent: (content: BoardContent) =>
    actions.board.updateContent(content),
  loadContent: (id: number) => actions.board.getContent(id),
  checkAccomplished: () => actions.user.checkUserAccomplished(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withBottomSheet(HtmlComposer))
);
