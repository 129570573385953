import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from "./../API.json";
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import { UserInterests } from "../models/Model.User";
import { TARGET } from "./../config.json";

const windowAny: any = window;

export default class DownloadUtil {
  // static downloadCsvOld(data, filename){
  //   var pom = document.createElement('a');
  //   pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
  //   pom.setAttribute('download', filename);

  //   if (document.createEvent) {
  //       var event = document.createEvent('MouseEvents');
  //       event.initEvent('click', true, true);
  //       pom.dispatchEvent(event);
  //   }
  //   else {
  //       pom.click();
  //   }
  // }

  static downloadCsv(data, filename) {
    var pom = document.createElement("a");

    var blob = new Blob(["\ufeff" + data], { type: "text/csv;charset=utf-8;" });
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
  }

  static parseDownloadCsv(data: [], filename: string, selectedItems, parseItem: (item, index) => string) {}

  static downloadDocument(url, filename = "", toastPopup = null) {
    if (!url) return;

    let os = getGlobal(GlobalKey.OS);
    if (os == "android" && url.endsWith("pdf"))
      windowAny.open(
        "https://docs.google.com/viewer?url=" + StringUtil.convertFilePath(url) + "&embedded=true",
        "_system"
      );
    else if (url.endsWith("ppt") || url.endsWith("pptx") || url.endsWith("doc") || url.endsWith("docx"))
      windowAny.open(
        "https://docs.google.com/viewer?url=" + StringUtil.convertFilePath(url) + "&embedded=true",
        os === "browser" ? "_blank" : "_system"
      );
    else if (
      filename &&
      os == "android" &&
      windowAny.cordova &&
      windowAny.cordova.plugins &&
      windowAny.cordova.plugins.DownloadManager &&
      windowAny.cordova.plugins.DownloadManager.download
    ) {
      if (toastPopup) toastPopup.show("첨부파일 다운로드 중...");
      windowAny.cordova.plugins.DownloadManager.download(
        StringUtil.convertFilePath(url),
        filename,
        filename + " 다운로드 중",
        () => {
          if (toastPopup) toastPopup.show("첨부파일 다운로드가 완료되었습니다.");
        },
        () => {
          if (toastPopup) toastPopup.show("첨부파일 다운로드가 실패하였습니다.");
        }
      );
    } else windowAny.open(StringUtil.convertFilePath(url), os === "browser" ? "_blank" : "_system");
  }
}
