import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import "./style.scss";
import "./../../../Common.css";
import Scroll from "../../cell/Scroll";
import Icon from "../../atoms/Icon";
import PartnersCard from "../../organisms/AllInOne/PartnersCard";
import allinoneCategoryAuto from "../../../assets/image/allinone-cate-auto.png";
import allinoneCategoryBank from "../../../assets/image/allinone-cate-bank.png";
import allinoneCategoryConsult from "../../../assets/image/allinone-cate-consult.png";
import allinoneCategoryEMR from "../../../assets/image/allinone-cate-emr.png";
import allinoneCategoryFranchize from "../../../assets/image/allinone-cate-frenchize.png";
import allinoneCategoryInsurance from "../../../assets/image/allinone-cate-insurance.png";
import allinoneCategoryInterior from "../../../assets/image/allinone-cate-interior.png";
import allinoneCategoryLabor from "../../../assets/image/allinone-cate-labor.png";
import allinoneCategoryLaw from "../../../assets/image/allinone-cate-law.png";
import allinoneCategoryScholar from "../../../assets/image/allinone-cate-scholar.png";
import allinoneCategoryTax from "../../../assets/image/allinone-cate-tax.png";
import Button from "../../atoms/Button";
import { BoardContent } from "../../../models/Model.Board";
import PostListItem from "../../organisms/AllInOne/PostListItem";
import { Fixed } from "../../atoms/Layout";
import FloatingButton from "../../atoms/FloatingButton";
import TabBar from "../../molecules/TabBar";
import CardView from "../../../component/molecule/CardView";

export interface AllInOneHomeProps {
  // me: UserInfo;
  partnerList: string[];
  partners: any;
  posts: BoardContent[];
  categories?: any[];
  banners: any[];
  selectedCategoryIndex: number;
  contents: any;
  onClickIntroduction: () => void;
  onLoadMorePosts: () => void;
  onClickPartner: (id: string) => void;
  onClickPost: (post: BoardContent) => void;
  onClickCategory: (index: number) => void;
  onRecommendPartner: () => void;
  onClickBanner: (item: any, index: number, position: any) => void;
}

const AllInOneHome: React.FC<AllInOneHomeProps & RouteComponentProps> = ({
  // history,
  // me,
  partnerList,
  partners,
  posts,
  categories,
  banners,
  contents,
  selectedCategoryIndex,
  onClickIntroduction,
  onLoadMorePosts,
  onClickPartner,
  onClickPost,
  onClickBanner,
  onClickCategory,
  onRecommendPartner,
}) => {
  try {
    // log(LogLevel.UI_LIFECYCLE, "AllInOneHomeTemplate:", posts, contents);
    log(
      LogLevel.UI_LIFECYCLE,
      "AllInOneHomeTemplate: hello world : ",
      posts,
      partnerList,
      banners,
      partners,
      contents
    );

    const handleTabBarOnClick = (index) => (e) => {
      e.stopPropagation();
      onClickCategory(index);
    };

    return (
      <div className="common-content common-position-relative common-background">
        <Scroll autoScrollName="allInOneHome" onLoadMore={onLoadMorePosts}>
          <TopAppBar title="약사파트너스" titleType="H1" />
          <TabBar
            size="Medium"
            type="Underlined"
            selectedIndex={selectedCategoryIndex}
            items={categories.map((category, index) => ({
              badge: false,
              onClick: handleTabBarOnClick(index),
              text: category.name,
            }))}
            scrollable
          />
          {/* <div className="allinone-container">
            {banners && banners.length > 0 && (
              <div className="common-container allinone-banner-container">
                <CardView
                  className="allinone-banner-cardview"
                  randomStart={true}
                  style="normal"
                  infinite={true}
                  items={banners}
                  indexType="dot"
                  imageUrlKey="imageUrl"
                  imageSrcKey="imageSrc"
                  indexPosition="left"
                  autoScrollInterval={10000}
                  onClick={onClickBanner}
                />
              </div>
            )}
            {partnerList && partnerList.length > 0 && (
              <div className="allinone-partners-container">
                {partnerList.map((partnerId: string, index: number) => {
                  if (!partnerId || !partners || !partners[partnerId])
                    return null
                  return (
                    <PartnersCard
                      key={index.toString()}
                      partner={partners[partnerId]}
                      onClick={() => {
                        if (onClickPartner) onClickPartner(partnerId)
                      }}
                    />
                  )
                })}
              </div>
            )}
          </div> */}

          <div
            className="allinone-suggest-container"
            style={{ background: COLOR_SYSTEM.get("Gray")[15] }}
          >
            <div className="common-flex-grow">
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[900]}>
                약사님께서 직접 이용 후 <br />
                만족스러웠던 업체를 추천해주세요!
              </Text>
            </div>
            <Button
              size={"Small"}
              type={"Text"}
              variant={"Contained"}
              color={"Tertiary"}
              onClick={onRecommendPartner}
            >
              추천하기
            </Button>
          </div>

          {posts && posts.length > 0 && (
            <div className="allinone-post-container">
              <div className="allinone-post-title">
                <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[700]}>
                  콘텐츠
                </Text>
              </div>

              {posts.map((post, index) => (
                <PostListItem
                  key={index.toString()}
                  showProfile={true}
                  content={contents ? contents[post.id] : post}
                  partner={
                    contents
                      ? partners[contents[post.id].usersBizId]
                      : partners[post.usersBizId]
                  }
                  onClick={() =>
                    onClickPost(contents ? contents[post.id] : post)
                  }
                />
              ))}
            </div>
          )}
        </Scroll>
      </div>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOneHomeTemplate",
      errorMessage: error,
    });
    return null;
  }
};

export default withRouter(AllInOneHome);
