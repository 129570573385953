
import React, { Component, SyntheticEvent } from 'react';
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonModal, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import {log, LogLevel} from '../utils/LogUtil'
import {setGlobal, GlobalKey, getGlobal} from '../utils/GlobalUtil'
import { UIPopupType } from '../store/ui/types';
import { UserWorkTypeName } from '../models/Model.User';
import Header from '../component/molecule/Header';
import Body from '../component/molecule/Body';
import Scroll from '../component/molecule/Scroll';
import ToggleSwitch from '../component/atom/ToggleSwitch';
import "./../Common.css";
import "./ComponentTest.scss";
import StarScore from '../component/atom/StarScore';
import Text from '../component/atom/Text';
import Separator from '../component/atom/Separator';
import COLOR from '../component/_design/color'
import TextButton from '../component/atom/TextButton';
import Icon from '../component/atom/Icon';
import Video from '../component/atom/Video';
import VideoController from '../component/cell/VideoController';
import IconButton from '../component/atom/IconButton';
import { TabBar, TabBarItem } from '../component/cell/TabBar';
import Badge from '../component/atom/Badge';
import Spinner from '../component/atom/Spinner';
import CardView from '../component/molecule/CardView';
import FloatingButton from '../component/atom/FloatingButton';
import StarScorer from '../component/atom/StarScorer';
import SegmentedControl from '../component/atom/SegmentedControl';

type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
    toggleSwitch:boolean

    highResolution:boolean
    speed: number

    tabIndex: number
    tabIndex2: number

    starScore: number;

    segmentValue: any;
}

const windowAny:any = window;

class ComponentTest extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            toggleSwitch:true,

            highResolution: false,
            speed: 1.0,

            tabIndex:0,
            tabIndex2:0,

            starScore: 0,
            segmentValue: 'A',
        }
    }

    video = null;

    elTabNames = ["button", "text", "switch", "icon"];
    componentDidMount() {
        log(LogLevel.UI_LIFECYCLE, "ComponentTest.componentDidMount", this.scrollEl, this.buttonEL, this.textEL, this.switchEL, this.iconEL);
        if(this.scrollEl){
            this.scrollEl.addTarget(this.elTabNames[0], this.buttonEL);
            this.scrollEl.addTarget(this.elTabNames[1], this.textEL);
            this.scrollEl.addTarget(this.elTabNames[2], this.switchEL);
            this.scrollEl.addTarget(this.elTabNames[3], this.badgeEL);
            this.scrollEl.subscribe("reach", this.onScrollReach)
            this.scrollEl.setMargin(this.tabEl.clientHeight + this.headerEl.clientHeight);
        }
    }

    componentWillUnmount () {

    }  

    onChangeResolution = (highResolution:boolean) => {
        this.setState({highResolution});
        if(this.video && this.video.setSrc){
            if(highResolution){
                this.video.setSrc("https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lectures/05_Ivywellness_OraPharm_211005_original_720.mp4" )
            }else{
                this.video.setSrc("https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lectures/05_Ivywellness_OraPharm_211005_original_360.mp4" )
            }
        }
    }

    onChangeSpeed = (speed: number) => {
        this.setState({speed});
        if(this.video && this.video.setSpeed){
            this.video.setSpeed(speed);
        }
    }

    scrollEl = null;
    buttonEL = null;
    textEL = null;
    switchEL = null;
    iconEL = null;
    badgeEL = null;
    tabEl = null;
    headerEl = null;

    onTabBarIndexChanged = (index) => {
        log(LogLevel.UI_LIFECYCLE, "ComponentTest.onTabBarIndexChanged", index);
        this.setState({tabIndex:index})
        if(this.scrollEl){
            this.scrollEl.scrollToTarget(this.elTabNames[index]);
        }
    }

    onScrollReach = (reach) => {
        log(LogLevel.UI_LIFECYCLE, "ComponentTest.onScrollReach", reach.targetId);
        let index = this.elTabNames.indexOf(reach.targetId);
        if(index >=0){
            this.setState({tabIndex:index})
        }
    }

    render() {
        log(LogLevel.UI_LIFECYCLE, "ComponentTest.render");

        let images = ["@@@/board/1_1635257671039", "@@@/board/1_1635257671151", "@@@/board/1_1635257671317", "@@@/board/1_1635257671465"]
        let images2 = [
            {
                url:"@@@/board/1_1635257671151",
                color: COLOR.Red,
            },
            {
                url:"@@@/board/1_1635257671317",
                color: COLOR.Green,
            },
            {
                url:"@@@/board/1_1635257671039",
                color: COLOR.Blue,
            },
            {
                url:"@@@/board/1_1635257671465",
                color: COLOR.Brown,
            },
        ] 
        return (
        <>
           <Header ref={ref => this.headerEl = ref} name="Component Test" needBack={true}/>
           <Body scrollable={false}>
                <div className="common-container">
                    <TabBar level="1" index={this.state.tabIndex2} onIndexChanged={(index) => this.setState({tabIndex2:index})}>
                        <Text>버튼</Text>
                        <Text>텍스트</Text>
                        <Text>스위치</Text>
                        <Text>Badge</Text>
                        <Text>Badge1</Text>
                        <Text>Badge2</Text>
                        <Text>Badge3</Text>
                        <Text>Badge4</Text>
                        <Text>Badge5</Text>
                        <Text>Badge6</Text>
                    </TabBar>
                </div>
               <Scroll ref={ref => this.scrollEl = ref} absolute>
                    <div className="common-block">
                        <div style={{marginBottom:"10px"}}>
                            <Text type="Headline">SegmentControl</Text>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <SegmentedControl 
                                type="ContainedS" 
                                value={this.state.segmentValue}
                                onClick={(segmentValue) => this.setState({segmentValue})} 
                                values={["A","B","C"]}
                            />
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <SegmentedControl 
                                type="ContainedL" 
                                value={this.state.segmentValue}
                                onClick={(segmentValue) => this.setState({segmentValue})} 
                                values={["A","B","C"]}
                            />
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <SegmentedControl 
                                type="Ghost" 
                                value={this.state.segmentValue}
                                onClick={(segmentValue) => this.setState({segmentValue})} 
                                values={["A","B","C"]}
                            />
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <SegmentedControl 
                                type="Outlined" 
                                value={this.state.segmentValue}
                                onClick={(segmentValue) => this.setState({segmentValue})} 
                                values={["최신순","조회순"]}
                            />
                        </div>

                    </div> 
                    <div className="common-block">
                        <div style={{marginBottom:"10px"}}>
                            <Text type="Headline">StartScore</Text>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <StarScorer size="XL" score={this.state.starScore} color="Primary" onScoreChange={(starScore) => this.setState({starScore})} debug={false}/>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <StarScore size="S" score={3.5}/>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <StarScore size="M" score={3.5}/>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <StarScore size="L" score={3.5}/>
                        </div>
                        <StarScore size="XL" score={2.7} color="Primary"/>
                    </div> 
                    <Separator type="Line"/>
                    <div className="common-container" style={{marginBottom:"10px"}}>
                        <Text type="Headline">Cardnews1</Text>
                        <CardView className="component-test-cardnews1" randomStart={true}  style="centered" infinite={true} items={images2} imageUrlKey="url" backgroundKey="color" indexPosition="left" autoScrollInterval={15000}/>
                    </div>
                    <div className="common-block" style={{marginBottom:"10px"}}>
                        <Text type="Headline">Cardnews2</Text>
                        <CardView items={images} indexPosition="left"/>
                    </div>
                    <Separator type="Line"/>
                    <div className="common-block">
                        <Text type="Headline">Spinner</Text>
                        <Spinner block size={100} color={COLOR.Primary}/>
                    </div>
                    <Separator type="Line"/>
                    <div style={{marginBottom:"10px"}}>
                        <Text type="Headline">Video Player</Text>
                    </div>
                    <div className="common-container" style={{marginBottom:"10px"}}>
                        <Video 
                            ref={ref => this.video = ref}
                            src="https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lectures/05_Ivywellness_OraPharm_211005_original_360.mp4" 
                            previewUrl="https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lecture/3_pv_1627379658649"
                        />
                        <VideoController highResolution={this.state.highResolution} onChangeResolution={this.onChangeResolution} speed={this.state.speed} onChangeSpeed={this.onChangeSpeed}/>
                    </div>
                    <div  className="common-block">
                        <span className="common-color-highlight" onClick={() =>{if(this.video) this.video.jumpTo(60)}}>01:00</span> 인사말 <br/>
                        <span className="common-color-highlight" onClick={() =>{if(this.video) this.video.jumpTo(780)}}>13:00</span> 마무리말
                    </div>
                    <Separator type="Line"/>
                    <div ref={ref => this.tabEl = ref} className="common-container" style={{position:"sticky", top:"0px", zIndex:10}}>
                        <TabBar level="3" index={this.state.tabIndex} onIndexChanged={this.onTabBarIndexChanged} splitEven>
                            <Text>버튼</Text>
                            <Text>텍스트</Text>
                            <Text>스위치</Text>
                            <Text>Badge</Text>
                        </TabBar>
                    </div>
                    <div className="common-container">
                        <TabBar level="1" index={this.state.tabIndex2} onIndexChanged={(index) => this.setState({tabIndex2:index})}>
                            <Text>버튼</Text>
                            <Text>텍스트</Text>
                            <Text>스위치</Text>
                            <Text>Badge</Text>
                            <Text>Badge1</Text>
                            <Text>Badge2</Text>
                            <Text>Badge3</Text>
                            <Text>Badge4</Text>
                            <Text>Badge5</Text>
                            <Text>Badge6</Text>
                        </TabBar>
                    </div>
                    <div  ref={ref => this.buttonEL = ref} className="common-block">
                        <div style={{marginBottom:"10px"}}>
                            <Text type="Headline">TextButton</Text>
                        </div>
                        <div className="common-container-row common-flex-align-center common-nowrap" style={{marginBottom:"10px"}}>
                            <TextButton size="S" variant="Ghost" color="Primary">Small  </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="S" variant="Ghost" color="Secondary"> Contained  </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="S" variant="Ghost" color="Gray">  Colored </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="S" variant="Ghost" color="Gray" disabled> Disabled</TextButton>
                        </div>
                        <div className="common-container-row common-flex-align-center common-nowrap" style={{marginBottom:"10px"}}>
                            <TextButton size="S" variant="Contained" color="Primary">Small  </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="S" variant="Contained" color="Secondary"> Contained  </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="S" variant="Contained" color="Gray">  Colored </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="S" variant="Contained" color="Gray" disabled> Disabled</TextButton>
                        </div>
                        <div className="common-container-row common-flex-align-center common-nowrap" style={{marginBottom:"10px"}}>
                            <TextButton size="M" variant="Outlined" color="Primary">Medium  </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="M" variant="Outlined" color="Secondary"> Outlined  </TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="M" variant="Outlined" color="Gray">Colored</TextButton>
                            <div style={{width:"10px"}}/>
                            <TextButton size="M" variant="Outlined" color="Gray" disabled> Disabled</TextButton>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <TextButton size="L" variant="Contained" color="Primary" block>Large Contained</TextButton>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <TextButton size="L" variant="Outlined" color="Primary" block>Large Outlined  </TextButton>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <TextButton size="L" variant="Outlined" color="Primary" block>Large Disabled  </TextButton>
                        </div>
                        <div className="common-container-row common-flex-align-center common-nowrap" style={{marginBottom:"10px"}}>
                            <IconButton size="M" variant="Ghost" color="Primary" iconName="PolygonUp"/>
                            <div style={{width:"10px"}}/>
                            <IconButton size="M" variant="Contained" color="Primary" iconName="PolygonUp"/>
                            <div style={{width:"10px"}}/>
                            <IconButton size="M" variant="Outlined" color="Primary" iconName="PolygonUp"/>
                            <div style={{width:"10px"}}/>
                            <IconButton size="M" variant="Outlined" disabled color="Primary" iconName="PolygonUp"/>
                        </div>
                    </div> 
                    <Separator type="Line"/>
                    <div ref={ref => this.textEL = ref} className="common-block">
                        <div style={{marginBottom:"10px"}}>
                            <Text type="Headline">Text</Text>
                        </div>
                        <Text type="Headline">Headline</Text><br/>
                        <Text type="SubHead" color="Orange">SubHead Orange</Text><br/>
                        <Text type="H1" color="Black">H1 Black</Text><br/>
                        <Text type="H2" color="Brown">H2 Brown</Text><br/>
                        <Text type="Body1" color="Gray1">Body1 Gray1</Text><br/>
                        <Text type="Body2" color="Gray3">Body2 Gray3</Text>
                    </div>
                    <Separator type="Line"/>
                    <div ref={ref => this.switchEL = ref} className="common-block">
                        <div style={{marginBottom:"10px"}}>
                            <Text type="Headline">ToggleSwitch</Text>
                        </div>
                        <ToggleSwitch checked={this.state.toggleSwitch} onClick={(e) => this.setState({toggleSwitch: !this.state.toggleSwitch})}/>
                    </div>
                    <Separator type="Line"/>

                    <div ref={ref => this.iconEL = ref} className="common-block">
                        <div style={{marginBottom:"10px"}}>
                            <Text type="Headline">Icons</Text>
                        </div>
                        <div className="common-container-row" style={{marginBottom:"10px"}}>
                            <Icon width="100" height="100" name="Star" fill={COLOR.White} style={{filter: "drop-shadow(0px 0px 10px #0008)" }}/>
                            <Icon width="50" height="50" name="Media" fill={COLOR.Brown}/>
                        </div>
                    </div> 
                    <Separator type="Line"/>
                    <div ref={ref => this.badgeEL = ref} className="common-block">
                        <div style={{marginBottom:"10px"}}>
                            <Text type="Headline">Badge</Text>
                        </div>
                        <div className="common-container-row common-flex-align-center common-nowrap" style={{marginBottom:"10px"}}>
                            <Badge size="S" variant="Contained" color="Blue" iconName="User Filled">Blue</Badge>
                            <div style={{width:"10px"}}/>
                            <Badge size="S" variant="Tinted" color="Red" iconName="User Filled">Red</Badge>
                            <div style={{width:"10px"}}/>
                            <Badge size="S" variant="Ghost" color="Brown">Brown</Badge>
                            <div style={{width:"10px"}}/>
                            <Badge size="S" variant="Contained" color="Gray1">Gray1</Badge>
                            <div style={{width:"10px"}}/>
                        </div>
                    </div>
                    <Separator type="Line"/>
                    
                </Scroll>
                <FloatingButton size="M" position="BottomCenter" color="Primary" badge="2">FloatingButton</FloatingButton>
           </Body>
        </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  filePath: state.board.filePath,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentTest));