import React from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from 'react-router';
import { format } from 'date-fns';
import { IonLabel, IonButton, IonIcon, IonItemSliding, IonItem, IonItemOptions, IonItemOption, IonAlert } from '@ionic/react';
import './RecommendContentListItem.css';
import qustionIcon from './../assets/image/question.png'
import {getDateTimeString} from './../utils/TimeUtil'
import commentIcon from './../assets/icon/comment.svg'
import viewIcon from './../assets/icon/view.svg'
import scappedIcon from './../assets/image/scrapped.png'
import unscappedIcon from './../assets/image/unscrapped.png'
import {BoardContent} from './../models/Model.Board'
import { fetchAPI } from '../utils/APIUtil';
import * as API from './../API.json';
import {BoardType} from './../store/board/types'
import {getGlobal, GlobalKey} from './../utils/GlobalUtil'
import Truncate from 'react-truncate';
import { LogLevel, log } from '../utils/LogUtil';
import { totalmem } from 'os';

type Props = {
  key : string
  content: BoardContent
  onSelect: (content:BoardContent) => void;
}

const RecommendContentListItem: React.SFC<Props> = ({content, onSelect}) => {
  let title = "인기 게시물";
  let titleClass = "suggestion-item-category";
  log(LogLevel.UI_LIFECYCLE, "RecommendContentListItem:render", content)
  if(content.replyCnt == 0 && content.boardsCategoriesId == BoardType.QNA){
    titleClass = "suggestion-item-category suggestion-item-category-highlight"
    title = "답변을 기다리는 질문"
  }
  else if(content.boardsCategoriesId == BoardType.NOTICE)
    title = "공지사항";
  else if(content.boardsCategoriesId == BoardType.QNA)
    title = "인기 Q&A";

  let info;
  if(content.boardsCategoriesId == BoardType.QNA)
    info = 
      <div className="suggestion-item-information-container">
        <div className="common-flex-grow"/>
        <div className="suggestion-item-information-title">답변</div>
        <div className="suggestion-item-information-count">{content.replyCnt}</div>
        <div className="suggestion-item-information-title">궁금해요</div>
        <div className="suggestion-item-information-count">{content.meTooCnt}</div>
        <div className="suggestion-item-information-title">좋아요</div>
        <div className="suggestion-item-information-count">{content.likeCntAll}</div>
        <div className="suggestion-item-information-title">조회수</div>
        <div className="suggestion-item-information-count">{content.viewCnt}</div>
      </div>
  else if (content.boardsCategoriesId == BoardType.POST)
    info = 
      <div className="suggestion-item-information-container">
        <div className="common-flex-grow"/>
        <div className="suggestion-item-information-title">댓글</div>
        <div className="suggestion-item-information-count">{content.commentCnt}</div>
        <div className="suggestion-item-information-title">좋아요</div>
        <div className="suggestion-item-information-count">{content.likeCntAll}</div>
        <div className="suggestion-item-information-title">조회수</div>
        <div className="suggestion-item-information-count">{content.viewCnt}</div>
      </div>

  log(LogLevel.UI_LIFECYCLE, "RecommendContentListItem", content);
  return (
    <div className="suggestion-item-container">
        <div className={titleClass}>{title}</div>
        <div className="suggestion-item-body-container" onClick={() => onSelect(content)}>
          <div className="suggestion-item-body-title">{content.subject}</div>
          <div className="suggestion-item-body">
            <Truncate lines={2} ellipsis="...">
              {content.bodyText}
            </Truncate>
          </div>
          {info}
        </div>
    </div>
  );
}

export default RecommendContentListItem;
