
import * as banners from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { BannerState} from "./types";
import { BannerInfo } from '../../models/Model.Banner';
import {log, LogLevel} from '../../utils/LogUtil'
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';

const defaultState: BannerState = {
  banners : [],
};

export default (state = defaultState, action: ActionType<typeof banners>): BannerState => {
  switch (action.type) {
    case getType(banners.fetchBanners.success):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchBanners.success", action, state);
      if(action.payload){
        let banners:BannerInfo[] = action.payload;
        return {...state, banners};
      }else{
        return {...state, banners:[]};
      }
    case getType(banners.fetchBanners.failure):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchBanners.failure", action, state);
      return {...state, banners:[]};

    default:
      return state;
  }

  return state;
}
