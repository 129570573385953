import React, { memo, useCallback } from "react";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { SHADOW } from "../../design/effects";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface SearchedMedicineCardProps {
  medicine: any;
}

const CardLi = styled.li`
  list-style: none;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  padding: 16px;
  margin: 0 12px;
  border-radius: 8px;
  ${SHADOW.get("1")};
  display: flex;
  gap: 12px;
  transform: translateZ(0);
  cursor: pointer;

  div,
  span {
    user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text;
  }
`;

const MedicineTypeDiv = styled.div`
  min-width: 60px;
`;

const SearchedMedicineCard: React.FC<
  SearchedMedicineCardProps & RouteComponentProps
> = ({ medicine, history }) => {
  const keywordTypeName = [
    "",
    "성분",
    "의약품",
    "용어",
    "부작용",
    "동물의약품",
  ];
  const currentSearchedKeyword = useSelector(
    (state: RootState) => state.newSearch.currentSearchedKeyword
  );

  const handleClickCard = useCallback(() => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_MEDICINE_SEARCHED_RESULT",
      "의약품검색결과선택",
      {
        검색모드: "MEDICINE",
        검색어: currentSearchedKeyword,
        의약품: medicine.text,
      }
    );

    history.push(`/medicine?id=${medicine.id}&type=${medicine.type}`);
  }, [medicine, currentSearchedKeyword]);

  return (
    <CardLi onClick={handleClickCard}>
      <MedicineTypeDiv>
        <Tag variant="Tinted" color="Gray">
          {keywordTypeName[medicine.type]}
        </Tag>
      </MedicineTypeDiv>
      <div>
        <div>
          <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[900]}>
            {medicine.text}
          </Text>
        </div>
        <div>
          <Text
            textType="Body2"
            color={COLOR_SYSTEM.get("Gray")[600]}
            numberOfLines={2}
          >
            {medicine.description || "-"}
          </Text>
        </div>
      </div>
    </CardLi>
  );
};

export default withRouter(memo(SearchedMedicineCard));
