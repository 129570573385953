import React, { Component } from 'react';
import './EventPopup.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';;
import Skeleton from 'react-loading-skeleton';
import smileIcon from './../assets/icon/smile.svg'
import { KeywordType, Medicine, Ingredient } from '../models/Model.Medicine';
import {compose} from 'redux'
import { connect, connectAdvanced } from 'react-redux';
import { RootState, actions} from '../store';
import {log, LogLevel} from '../utils/LogUtil'
import { EventInfo } from '../models/Model.Event';
import StringUtil from '../utils/StringUtil';
import ReactVisibilitySensor from 'react-visibility-sensor';
import AnalyticsUtil from '../utils/AnalyticsUtil';
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';

type Props = {
  onIgnore? : (event:EventInfo) => void;
  onClick? : (event:EventInfo) => void;
  filePath?: string;
};

type State = {
  visible: boolean;
  onClose:() => void;
  event: EventInfo;
}

class EventPopup extends Component<Props, State> {
  state={
    visible: false,
    event: null,
    onClose: null,
  };

  show = (event: EventInfo, onClose=null) => {
    log(LogLevel.NONE, "EventPopup:show")
    this.setState({event, visible:true, onClose});
  }

  hide = () => {
    log(LogLevel.NONE, "EventPopup:hide")
    this.setState({visible: false});
  }

  onDone = () => {
    this.hide();
  }

  isShown = () => {
    return this.state.visible;
  }

  ignore = () => {
    if(this.props.onIgnore)
      this.props.onIgnore(this.state.event);
    this.hide();
  }

  click = () => {
    if(this.props.onClick)
      this.props.onClick(this.state.event);
    this.hide();
  }

  onDismiss = () => {
    let onClose = this.state.onClose

    this.setState({ visible: false, onClose:null });

    if(onClose)
      onClose();
  }

  onVisibilityChange = (visible) => {
    if(visible && this.state.event && this.state.event.log && this.state.event.log.code){
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_EXPOSURE", '광고노출', {"type":"이벤트팝업", ...this.state.event.log});
    }
  }

  render() {
    let className = "event-info-popup-container";
    let containerClass = ""
    let backdrop;
    let image;
    let ignoreText = "다시 보지않기"
    let style:any = {};
    if(this.state.event){
      log(LogLevel.UI_LIFECYCLE, "EventPopup: render", this.state.event);
      if(this.state.event.isFull){
        className = "event-info-popup-container-full"
        containerClass = " event-info-container-full"
      }else{
        backdrop = <div className="event-info-popup-backdrop"/>
      }

      if(this.state.event.imageBase64){
        image = <img className="event-info-image" src={this.state.event.imageBase64} />
      }else if (this.state.event.imageUrl){
        image = <img className="event-info-image" src={StringUtil.convertFilePath(this.state.event.imageUrl)} />
      }

      if(this.state.event.ignoreDuration == 1){
        ignoreText="오늘 보지않기"
      }else if(this.state.event.ignoreDuration > 1){
        ignoreText = this.state.event.ignoreDuration + "일간 보지않기";
      }else if(this.state.event.ignoreText){
        ignoreText = this.state.event.ignoreText;
      }

      if(this.state.event.bgColor){
        style = {background:this.state.event.bgColor};
      }
    }

    let body = (
        <div className={"event-info-container" + containerClass}> 
          <div className="event-info-image-container" style={style} onClick={this.click}>
            <div className="common-flex-grow"/>
            {image}
            <div className="common-flex-grow"/>
          </div>
          <div className="event-info-buttons-container">
            <IonCheckbox onClick={this.ignore}/>
            <div className="event-info-button-text" onClick={this.ignore}>
              {ignoreText}
            </div>
            <div className="event-info-button-space"/>
            <IonIcon class="event-info-close-icon" name="close" onClick={this.hide}/>
            <div className="event-info-button-text" onClick={this.hide}>
              닫기
            </div>              
          </div>
        </div>
    );

    if(this.state.event && this.state.event.log && this.state.event.log.code){
      body = (
        <ReactVisibilitySensor onChange={this.onVisibilityChange}>
          {body}
        </ReactVisibilitySensor>
      );
    }

    return (
      <IonModal cssClass={className}
        isOpen={this.state.visible}
        backdropDismiss = {false}
        onDidDismiss={this.onDismiss}
      >
        <div className="event-info-popup-inner">
          {backdrop}
          {body}
          {backdrop}
        </div>
      </IonModal>
    );
  }
}

export default EventPopup;