import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonCheckbox, IonToggle, IonItem } from '@ionic/react';
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'

import {setGlobal, GlobalKey, getGlobal} from '../utils/GlobalUtil'
import { BoardType } from '../store/board/types';
import { UserLevel, UserWorkType, UserInfo, UserWorkTypeName, UserLevelName } from '../models/Model.User';
import { fetchAPI } from '../utils/APIUtil';
import * as API from '../API.json';
import {MY_ADDRESS} from '../config.json';
import UserSelect from './component/user/UserSelect';
import BoardContentSelect from './component/BoardContentSelect';
import { BoardContent } from '../models/Model.Board';
import { any } from 'prop-types';
import { UIPopupType } from '../store/ui/types';
import CheckToggleButton from '../components/CheckToggleButton';
import ProfileImage from '../components/ProfileImage';
import ProfileSimple from '../components/ProfileSimple';
import { getDateStringFromToday } from '../utils/TimeUtil';
import DownloadUtil from '../utils/DownloadUtil';

const AdminAPI = {        
  "GET_INVITES" : {"method":"GET", "path":"/admin/user/invites/", "contentType":"application/json"},
  "ADD_INVITE" : {"method":"POST", "path":"/admin/user/action/invite/", "contentType":"application/json"},
  "REMOVE_INVITE" : {"method":"DELETE", "path":"/admin/user/action/invite/", "contentType":"application/json"},
  
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  invites: any[];
  manual: boolean;
  manualUsers: UserInfo[],
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
}

class EventInvite extends Component<Props, State> {

  state = {
    invites: [],
    manual: false,
    manualUsers: [],
    dateStart: getDateStringFromToday({ weeks: -1 }),
    dateStartWarn: false,
    dateEnd: getDateStringFromToday({ days: 1 }),
    dateEndWarn: false,
  };

  constructor(props: Props) {
    super(props);

  }

  componentDidMount() {
    this.onFetch();
  }

  onFetch = async () => {
    if(this.state.dateStartWarn || this.state.dateEndWarn)
      return;

    let result = await fetchAPI(AdminAPI.GET_INVITES, "", {dateStart:this.state.dateStart, dateEnd: this.state.dateEnd}, null, getGlobal(GlobalKey.TOKEN));
    log(LogLevel.UI_DATA_LOAD, "EventInvite:onFetch:", result);
    if(result && !result.error){
      let invites =[];
      if(result.data)
        invites = result.data;
      this.setState({invites})
    }else{
      this.setState({invites:[]})
    }
  }


  onUserAdded = (user: UserInfo) => {
    if(!this.state.manualUsers.includes(user) && this.state.manualUsers.length < 2){
      log(LogLevel.UI_ACTION, "EventInviteRank.onUserAdded", user) 
      this.setState({manualUsers:[...this.state.manualUsers, user]});
    }
  }
    
  onUserRemove = (index:number) => {
    log(LogLevel.UI_ACTION, "EventInviteRank.onUserRemove", index) 
    let manualUsers = [...this.state.manualUsers];
    manualUsers.splice(index, 1);
    this.setState({manualUsers});
  }

  addInvite = async () => {
    if(this.state.manualUsers.length <2)
      return;
    let result = await fetchAPI(AdminAPI.ADD_INVITE, (this.state.manualUsers[0].id.toString() + "/" + this.state.manualUsers[1].id.toString()), null, null, getGlobal(GlobalKey.TOKEN));
    log(LogLevel.UI_DATA_LOAD, "EventInvite:addInvite:", result);
    if(result && !result.error){
      this.onFetch();
    }
  }

  removeInvite = async() => {
    if(this.state.manualUsers.length <2)
      return;
    let result = await fetchAPI(AdminAPI.REMOVE_INVITE, (this.state.manualUsers[0].id.toString() + "/" + this.state.manualUsers[1].id.toString()), null, null, getGlobal(GlobalKey.TOKEN));
    log(LogLevel.UI_DATA_LOAD, "EventInvite:removeInvite:", result);
    if(result && !result.error){
      this.onFetch();
    }
  }

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(LogLevel.UI_EXCEPTION, "EventInvite:onDateEndChange", dateString);
        dateWarn = true;
      } else {
        log(LogLevel.UI_DATA_LOAD, "EventInvite:onDateEndChange", dateString, date);
      }
    } catch (err) {
      log(LogLevel.UI_EXCEPTION, "EventInvite:onDateEndChange", dateString, err);
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString), (newState[field + "Warn"] = dateWarn), this.setState(newState);
  };


  renderList = () => {
    return (
      <table id="admin-table" className="admin-table">
        <thead>
          <tr>
            <td className="admin-table-label-x" rowSpan={2}>#</td>
            <td className="admin-table-label-x" rowSpan={2}>초대일시</td>
            <td className="admin-table-label-x" colSpan={3}>초대자</td>
            <td className="admin-table-label-x" colSpan={3}>피초대자</td>
          </tr>
          <tr>
            <td className="admin-table-label-x">ID</td>
            <td className="admin-table-label-x">이름</td>
            <td className="admin-table-label-x">전화번호</td>
            <td className="admin-table-label-x">ID</td>
            <td className="admin-table-label-x">이름</td>
            <td className="admin-table-label-x">전화번호</td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.invites.map((invite: any, index) => 
              <tr key={index.toString()}>
                <td className={"admin-table-value"}>
                  {index+1}
                </td>
                <td className={"admin-table-value"}>
                  {invite.createdAt ? getDateStringFromToday({ date: invite.createdAt }) : ""}
                </td>
                <td className={"admin-table-value"}>
                  {invite.who ? invite.who.id : ""}
                </td>
                <td className={"admin-table-value"}>
                  {invite.who ? invite.who.name : ""}
                </td>
                <td className={"admin-table-value"}>
                  {invite.who ? invite.who.phone : ""}
                </td>
                <td className={"admin-table-value"}>
                  {invite.whom ? invite.whom.id : ""}
                </td>
                <td className={"admin-table-value"}>
                  {invite.whom ? invite.whom.name : ""}
                </td>
                <td className={"admin-table-value"}>
                  {invite.whom ? invite.whom.phone : ""}
                </td>

              </tr>
          )}
        </tbody>
      </table>
    );
  };

  downloadList = () => {
    let csv =
      "#,초대일,초대자ID,초대이름,초대닉네임,초대전번,피초대자ID,피초대이름,피초대닉네임,피초대전번\r\n";
    csv += this.state.invites
      .map((invite: any, index) =>
        `${index+1},${
          invite.createdAt ? getDateStringFromToday({ date: invite.createdAt }) : ""
        },${
          invite.who ? invite.who.id : ""
        },${
          invite.who ? invite.who.name : ""
        },${
          invite.who ? invite.who.nickname : ""
        },${
          invite.who ? invite.who.phone : ""
        },${
          invite.whom ? invite.whom.id : ""
        },${
          invite.whom ? invite.whom.name : ""
        },${
          invite.whom ? invite.whom.nickname : ""
        },${
          invite.whom ? invite.whom.phone : ""
        }\r\n`
      )
      .join("");

    DownloadUtil.downloadCsv(
      csv,
      `JobPost-Offers-${this.state.dateStart}-${this.state.dateEnd}-${new Date()
        .toISOString()
        .substring(0, 10)
        .replace(/-/gi, "")}.csv`
    );
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "EventInvite.render", this.props, this.state);

    let selection;
    if(this.state.manual == true)
      selection = (
        <div id="admin-sub-conainer" className="admin-container">
          <UserSelect onDone={this.onUserAdded}/>
        </div>          
      );

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                친구초대 이벤트 List
            </IonText>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <div className="common-container-row-wrap admin-margin-bottom">
                <div className="admin-title">검색기간</div>
                <input
                  className={this.state.dateStartWarn ? "common-color-caution" : ""}
                  placeholder="시작일시"
                  value={this.state.dateStart}
                  onChange={(e) => this.onDateChange(e, "dateStart")}
                />
                <div>~</div>
                <input
                  className={this.state.dateEndWarn ? "common-color-caution" : ""}
                  placeholder="시작일시"
                  value={this.state.dateEnd}
                  onChange={(e) => this.onDateChange(e, "dateEnd")}
                />
                <IonButton onClick={this.onFetch}>불러오기</IonButton>
                <IonButton onClick={this.downloadList}>다운로드({this.state.invites.length}건)</IonButton>

                <div
                  className="admin-title common-color-highlight"
                  onClick={() => {
                    this.setState({
                      dateStart: getDateStringFromToday({ weeks: -1 }),
                      dateEnd: getDateStringFromToday({ days: 1 }),
                      dateStartWarn: false,
                      dateEndWarn: false,
                    });
                  }}
                >
                  1주
                </div>
                <div
                  className="admin-title common-color-highlight"
                  onClick={() => {
                    this.setState({
                      dateStart: getDateStringFromToday({ weeks: -4 }),
                      dateEnd: getDateStringFromToday({ days: 1 }),
                      dateStartWarn: false,
                      dateEndWarn: false,
                    });
                  }}
                >
                  4주
                </div>
                <div
                  className="admin-title common-color-highlight"
                  onClick={() => {
                    this.setState({
                      dateStart: getDateStringFromToday({ byMonth: true }),
                      dateEnd: getDateStringFromToday({ byMonth: true, months: 1 }),
                      dateStartWarn: false,
                      dateEndWarn: false,
                    });
                  }}
                >
                  이번달
                </div>
                <div
                  className="admin-title common-color-highlight"
                  onClick={() => {
                    this.setState({
                      dateStart: getDateStringFromToday({ byMonth: true, months: -1 }),
                      dateEnd: getDateStringFromToday({ days: 1 }),
                      dateStartWarn: false,
                      dateEndWarn: false,
                    });
                  }}
                >
                  지난달부터
                </div>
                <div
                  className="admin-title common-color-highlight"
                  onClick={() => {
                    this.setState({
                      dateStart: getDateStringFromToday({ byMonth: true, months: -1 }),
                      dateEnd: getDateStringFromToday({ byMonth: true }),
                      dateStartWarn: false,
                      dateEndWarn: false,
                    });
                  }}
                >
                  지난달
                </div>
                <div
                  className="admin-title common-color-highlight"
                  onClick={() => {
                    this.setState({
                      dateStart: getDateStringFromToday({ months: -6 }),
                      dateEnd: getDateStringFromToday({ days: 1 }),
                      dateStartWarn: false,
                      dateEndWarn: false,
                    });
                  }}
                >
                  6개월간
                </div>
              </div>
              <div className="common-container-row common-flex-align-center">
                <IonCheckbox color="html-composer-checkbox" checked={this.state.manual} onIonChange={(e) => {this.setState({manual:e.detail.checked});}}/>
                {this.state.manualUsers.map((user:UserInfo, index) => 
                  <div key={index.toString()} className="common-container-row common-flex-align-center">
                    <IonItem class="common-flex-grow" key={index.toString()}>
                      <IonLabel>{user.id}.{user.name}</IonLabel>
                      <IonButton onClick={() => this.onUserRemove(index)}><IonIcon name="close"/></IonButton>
                      <IonLabel>{index?"":" invites "}</IonLabel>
                    </IonItem>
                  </div>
                )}
                <IonButton disabled={!(this.state.manual && this.state.manualUsers.length == 2)} onClick={this.addInvite}>추가</IonButton>
                <IonButton disabled={!(this.state.manual && this.state.manualUsers.length == 2)} onClick={this.removeInvite}>삭제</IonButton>
              </div>
              {/* {this.state.invites && this.state.invites.length &&
                <div>총{this.state.invites.length}건</div>
              } */}
              {this.renderList()}
            </div>
            {selection}
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventInvite));