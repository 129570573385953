import React from "react";
import { useDispatch } from "react-redux";
import useConfirm from "../hooks/useConfirm";
import useChangeWorkTypeConfirm from "../hooks/useWorkTypeConfirm";
import { UserWorkType } from "../models/Model.User";
import { ConfirmUIOptions } from "../store/modal/confirm";

export interface WithConfirmProps {
  confirm?: (options: ConfirmUIOptions) => Promise<unknown>;
  workTypeConfirm?: {
    showCanNotChangeWorkTypeConfirm: (dday: number) => Promise<unknown>;
    showChangeWorkTypeWarningConfirm: (workType: UserWorkType) => Promise<unknown>;
  };
}

export default function withConfirm<P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithConfirmProps> {
  return function ({ ...props }: WithConfirmProps) {
    const { confirm: showConfirm } = useConfirm();
    const workTypeConfirm = useChangeWorkTypeConfirm();

    return <Component confirm={showConfirm} workTypeConfirm={workTypeConfirm} {...(props as P)} />;
  };
}
