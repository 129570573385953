import { createAction, createAsyncAction } from "typesafe-actions";
import { UserActionTypes } from "./types";
import { UserInfo } from "../../models/Model.User";
import { BoardComment } from "../../models/Model.Board";

export const socialLogin = createAction(
  UserActionTypes.USER_KAKAO_LOGIN,
  (resolve) => (kakaoId: string) => resolve(kakaoId)
);

export const login = createAction(UserActionTypes.USER_LOGIN, (resolve) => () =>
  resolve()
);

export const logout = createAction(
  UserActionTypes.USER_LOGOUT,
  (resolve) => () => resolve()
);

export const setToken = createAction(
  UserActionTypes.USER_TOKEN_SET,
  (resolve) => (token: string) => resolve(token)
);

export const updateBoardStatistic = createAction(
  UserActionTypes.USER_BOARD_STATISTICS,
  (resolve) => () => resolve()
);

export const fetchUserBoardStatistics = createAsyncAction(
  UserActionTypes.USER_REQUEST_BOARD_STATISTICS,
  UserActionTypes.USER_SUCCESS_BOARD_STATISTICS,
  UserActionTypes.USER_ERROR_BOARD_STATISTICS
)<void, UserInfo, Error>();

export const getLastRecievedComment = createAction(
  UserActionTypes.USER_RECIEVED_COMMENTS,
  (resolve) => () => resolve()
);

export const fetchLastRecievedComment = createAsyncAction(
  UserActionTypes.USER_REQUEST_RECIEVED_COMMENTS,
  UserActionTypes.USER_SUCCESS_RECIEVED_COMMENTS,
  UserActionTypes.USER_ERROR_RECIEVED_COMMENTS
)<void, BoardComment[], Error>();

export const fetchUserKakaoLogout = createAsyncAction(
  UserActionTypes.USER_REQUEST_KAKAO_LOGOUT,
  UserActionTypes.USER_SUCCESS_KAKAO_LOGOUT,
  UserActionTypes.USER_ERROR_KAKAO_LOGOUT
)<void, void, Error>();

export const fetchUserME = createAsyncAction(
  UserActionTypes.USER_REQUEST_ME,
  UserActionTypes.USER_SUCCESS_ME,
  UserActionTypes.USER_ERROR_ME
)<void, UserInfo, Error>();

export const setMe = createAction(
  UserActionTypes.USER_SET_ME,
  (resolve) => (user: UserInfo) => resolve(user)
);

export const getUser = createAction(
  UserActionTypes.USER_INFO,
  (resolve) => (id: number) => resolve(id)
);

export const checkUserAccomplished = createAction(
  UserActionTypes.USER_CHECK_ACCOMPLISHED,
  (resolve) => () => resolve()
);

export const fetchUserAccomplished = createAsyncAction(
  UserActionTypes.USER_REQUEST_ACCOMPLISHED,
  UserActionTypes.USER_SUCCESS_ACCOMPLISHED,
  UserActionTypes.USER_ERROR_ACCOMPLISHED
)<void, boolean, Error>();

export const fetchUserInfo = createAsyncAction(
  UserActionTypes.USER_REQUEST_INFO,
  UserActionTypes.USER_SUCCESS_INFO,
  UserActionTypes.USER_ERROR_INFO
)<void, UserInfo, Error>();

export const fetchFreeSummaryCount = createAsyncAction(
  UserActionTypes.USER_REQUEST_FREE_SUMMARY_COUNT,
  UserActionTypes.USER_SUCCESS_FREE_SUMMARY_COUNT,
  UserActionTypes.USER_ERROR_FREE_SUMMARY_COUNT
)<void, number, Error>();

export const getFreeSummaryCount = createAction(
  UserActionTypes.USER_GET_FREE_SUMMARY_COUNT,
  (resolve) => () => resolve()
);

export const decreaseFreeSummaryCount = createAction(
  UserActionTypes.USER_DECREASE_FREE_SUMMARY_COUNT,
  (resolve) => () => resolve()
);

export const increaseFreeSummaryCount = createAction(
  UserActionTypes.USER_INCREASE_FREE_SUMMARY_COUNT,
  (resolve) => (count: number) => resolve(count)
);
