import { createStore, applyMiddleware, compose, Middleware } from "redux";
import { fetchSearchMiddleware } from "./search/middleware";
import { fetchUserMiddleware } from "./user/middleware";
import { fetchMedicineMiddleware } from "./medicine/middleware";
import { fetchBoardMiddleware } from "./board/middleware";
import { fetchNotificationMiddleware } from "./notification/middleware";
import { fetchEventMiddleware } from "./event/middleware";
import { fetchSeminarMiddleware } from "./seminar/middleware";
import { fetchBannerMiddleware } from "./banner/middleware";
import { fetchGamificationMiddleware } from "./gamification/middleware";
import { fetchJobPostMiddleware } from "./jobpost/middleware";
import { fetchAllInOneMiddleware } from "./allinone/middleware";
import { searchMiddleware } from "./search";

import rootReducer from "./root-reducer";
import { fetchSaleMiddleware } from "./sale/middleware";
import { bottomSheetMiddleware } from "./modal/bottomsheet";

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares: Middleware[] = [
  fetchSearchMiddleware,
  fetchUserMiddleware,
  fetchMedicineMiddleware,
  fetchBoardMiddleware,
  fetchNotificationMiddleware,
  fetchEventMiddleware,
  fetchSeminarMiddleware,
  fetchBannerMiddleware,
  fetchGamificationMiddleware,
  fetchJobPostMiddleware,
  fetchSaleMiddleware,
  fetchAllInOneMiddleware,
  bottomSheetMiddleware,
  searchMiddleware,
];

function configureStore(initialState?: {}) {
  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
}

// pass an optional param to rehydrate state on app start
const store = configureStore();

// export store singleton instance
export default store;
