import React, { Component } from "react";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import {
  BoardAttribute,
  BoardContent,
  BoardContentSummary,
} from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { loadImageBase64, loadImageUrlBase64 } from "../../../utils/ImageUtil";
import ImageViewNSelect, { ImageSize } from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import BoardSummary from "../../../components/templates/BoardSummary";
import { Flex, Static } from "../../../components/atoms/Layout";
import { MY_ADDRESS } from "./../../../config.json";
import Text from "../../../components/atoms/Text";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import Separator from "../../../components/atoms/Separator";
import { BoardSummaryFetcher } from "../../../utils/BoardSummaryUtil";

const AdminAPI = {
  PROMPT_GET: {
    method: "GET",
    path: "/admin/summary/prompt/",
    contentType: "application/json",
  },
  SUMMARY_CREATE: {
    method: "POST",
    path: "/admin/summary/add",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: () => void;
    onCancel: () => void;
    summary: BoardContentSummary;
    onSearch: (option: any) => void;
  };

type State = AllInOnePartnerInfo & {
  editing?: boolean;
  summaryStr?: string;
  newSummary?: BoardContentSummary;
  newSummaryLoading?: boolean;
  prompt?: string;
  foldPrompt?: boolean;
  editError?: boolean;
};

class SummaryDetail extends Component<Props, State> {
  state: State = {
    editing: false,
    summaryStr: "",
    foldPrompt: true,
    prompt: "",
    newSummary: null,
    newSummaryLoading: false,
    editError: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.summary &&
      (!prevProps ||
        !prevProps.summary ||
        this.props.summary.id != prevProps.summary.id)
    ) {
      if (
        this.props.summary &&
        this.props.summary.keyword &&
        this.props.summary.keyword.length > 0
      ) {
        for (let i = 0; i < this.props.summary.keyword.length; i++) {
          this.props.getSummaryGroupCount(this.props.summary.keyword[i]);
        }
      }

      this.setState({
        editing: false,
        newSummary: null,
        summaryStr: "",
      });
    }
  }

  onBack = () => {
    this.props.onCancel;
  };

  onGPTAgain = async () => {
    log(LogLevel.UI_ACTION, "SummaryDetail onGPTAgain", this.props.summary);
    await fetchAPI(
      API.ADMIN_BOARD_SUMMARY_CLEAR,
      this.props.summary.contentId,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );

    let fetcher = new BoardSummaryFetcher(
      this.props.summary.contentId,
      (summary) => {
        this.setState({ newSummary: summary, newSummaryLoading: true });
      },
      (summary) => {
        this.setState({ newSummary: summary, newSummaryLoading: false });
        setTimeout(
          () =>
            this.props.onSearch({
              searchContentId: this.props.summary.contentId,
              searchKeyword: "",
            }),
          300
        );
      },
      (err) => {
        log(LogLevel.UI_ACTION, "SummaryDetail onGPTAgain failed", err);
      }
    );
  };

  onStartManualEdit = () => {
    log(
      LogLevel.UI_ACTION,
      "SummaryDetail onStartManualEdit",
      this.props.summary
    );
    this.setState({
      editing: true,
      summaryStr: JSON.stringify(
        this.state.newSummary ? this.state.newSummary : this.props.summary,
        null,
        2
      ),
      newSummary: { ...this.props.summary },
    });
  };

  onCancelManualEdit = () => {
    log(
      LogLevel.UI_ACTION,
      "SummaryDetail onCancelManualEdit",
      this.props.summary
    );
    this.setState({
      editing: false,
      summaryStr: "",
      newSummary: null,
    });
  };

  onSaveManualEdit = async () => {
    if (this.state.editError) {
      this.props.toastPopup.show("잘못된 JSON 형식입니다.");
      return;
    }

    let newSummary = { ...this.state.newSummary };
    delete newSummary.id;
    delete newSummary.createdAt;
    delete newSummary.deletedAt;
    newSummary.version = newSummary.version.replace("M", "") + "M";
    newSummary.contentId = this.props.summary.contentId.toString();

    await fetchAPI(
      AdminAPI.SUMMARY_CREATE,
      "",
      null,
      newSummary,
      getGlobal(GlobalKey.TOKEN)
    );

    this.setState({
      editing: false,
      summaryStr: "",
      newSummary,
    });
    this.onHistory();
  };

  onChangeManualEdit = (e) => {
    let text = e.target.value;
    let error = false;
    let newSummary = null;

    try {
      newSummary = JSON.parse(text);
    } catch (e) {
      error = true;
      this.props.toastPopup.show("잘못된 JSON 형식입니다.");
    }

    let newState: any = {
      summaryStr: text,
      editError: error,
    };
    if (newSummary) {
      newState.newSummary = newSummary;
    }
    this.setState(newState);
  };

  onHistory = () => {
    log(LogLevel.UI_ACTION, "SummaryDetail onHistory", this.props.summary);
    this.props.onSearch({
      searchContentId: this.props.summary.contentId,
      searchKeyword: "",
    });
  };

  onKeyword = (keyword: string) => {
    log(
      LogLevel.UI_ACTION,
      "SummaryDetail onKeyword",
      this.props.summary,
      keyword
    );
    this.props.onSearch({ searchKeyword: keyword, searchContentId: 0 });
  };

  onReference = (focus) => {
    log(
      LogLevel.UI_ACTION,
      "SummaryDetail onReference",
      this.props.summary,
      focus
    );
    let url = "/boards/view?id=" + this.props.summary.contentId;
    if (focus) {
      if (focus.commentId) {
        url += `&fcmt=${focus.commentId}`;
      } else if (focus.contentId) {
        url += `&fcnt=${focus.contentId}`;
      }
    }
    window.open(MY_ADDRESS + url, "_blank");
  };

  onShowPrompt = (show: boolean) => () => {
    this.setState({ foldPrompt: !show });
    if (!this.state.prompt) {
      fetchAPI(
        AdminAPI.PROMPT_GET,
        this.props.summary.version.replace("M", ""),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          if (result.data && result.data.system)
            this.setState({
              prompt: result.data.system,
            });
        })
        .catch((e) => this.setState({ prompt: "Error" }));
    }
  };

  render() {
    log(
      LogLevel.UI_ACTION,
      "SummaryDetail render",
      this.props.summary,
      this.state
    );
    if (!this.props.summary) {
      return null;
    }

    if (!this.state.editing) {
      return (
        <div className="admin-container">
          {this.props.onCancel && (
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start" class="common-container">
                  <IonButton
                    class="common-header-toolbar-button"
                    onClick={this.onBack}
                  >
                    <IonIcon name="arrow-back" mode="ios" />
                  </IonButton>
                  <IonLabel class="common-header-title common-flex-grow">
                    요약 정보
                  </IonLabel>
                  <Button
                    onClick={() => this.onHistory()}
                    size={"Small"}
                    type={"Text"}
                    variant={"Contained"}
                    color={"Primary"}
                    style={{ marginRight: "10px" }}
                  >
                    History보기
                  </Button>
                  <Button
                    onClick={() => this.onStartManualEdit()}
                    size={"Small"}
                    type={"Text"}
                    variant={"Contained"}
                    color={"Negative"}
                    style={{ marginRight: "10px" }}
                  >
                    Manual 수정하기
                  </Button>
                  <Button
                    onClick={() => this.onGPTAgain()}
                    size={"Small"}
                    type={"Text"}
                    variant={"Contained"}
                    color={"Negative"}
                    style={{ marginRight: "10px" }}
                  >
                    GPT 새로 요약하기
                  </Button>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          )}

          <Static customStyle={{ padding: "0px 20px" }}>
            <Text
              textType="Body1SemiBold"
              color={COLOR_SYSTEM.get("Gray")[900]}
            >
              Id: {this.props.summary.id} <br />
              ContentId: {this.props.summary.contentId}
              <br />
              Version: {this.props.summary.version}
              <br />
              CreatedAt:
              {getDateStringFromToday({ date: this.props.summary.createdAt })}
              <br />
              DeletedAt:
              {this.props.summary.deletedAt &&
                getDateStringFromToday({ date: this.props.summary.deletedAt })}
              <br />
            </Text>
            <BoardSummary
              summary={
                this.state.newSummary
                  ? this.state.newSummary
                  : this.props.summary
              }
              onClickKeyword={this.onKeyword}
              onClickReference={this.onReference}
              summaryGroups={this.props.summaryGroups}
            />
          </Static>

          <Static
            customStyle={{
              padding: "0px 20px",
              marginTop: "20%",
              marginBottom: "40px",
              borderRadius: "20px",
              border: "1px solid  #e0e0e0",
            }}
          >
            {this.state.foldPrompt ? (
              <Static
                customStyle={{ width: "100%" }}
                onClick={this.onShowPrompt(true)}
              >
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[600]}
                >
                  프롬프트 보기 ⬇️
                </Text>
              </Static>
            ) : (
              <Flex direction="column" gap="20px">
                <Static
                  customStyle={{ width: "100%" }}
                  onClick={this.onShowPrompt(false)}
                >
                  <Text
                    textType="Body1SemiBold"
                    color={COLOR_SYSTEM.get("Gray")[600]}
                  >
                    프롬프트 숨기기 ⬆️
                  </Text>
                </Static>

                <Separator type={"Line"} />
                <Textarea
                  minRows={5}
                  value={this.state.prompt}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{ padding: "20px" }}
                />
              </Flex>
            )}
          </Static>
        </div>
      );
    } else {
      return (
        <div className="admin-container">
          {this.props.onCancel && (
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start" class="common-container">
                  <IonButton
                    class="common-header-toolbar-button"
                    onClick={this.onBack}
                  >
                    <IonIcon name="arrow-back" mode="ios" />
                  </IonButton>
                  <IonLabel class="common-header-title common-flex-grow">
                    사용자정보 편집
                  </IonLabel>
                  <Button
                    onClick={() => this.onCancelManualEdit()}
                    size={"Small"}
                    type={"Text"}
                    variant={"Outlined"}
                    color={"Secondary"}
                    style={{ marginRight: "10px" }}
                  >
                    취소하기
                  </Button>
                  <Button
                    onClick={() => this.onSaveManualEdit()}
                    size={"Small"}
                    type={"Text"}
                    variant={"Contained"}
                    color={"Negative"}
                    style={{ marginRight: "10px" }}
                  >
                    저장하기
                  </Button>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          )}
          <Static
            customStyle={{
              padding: "20px 20px",
              marginBottom: "20px",
              borderRadius: "20px",
              border: `2px solid ${
                COLOR_SYSTEM.get(this.state.editError ? "Red" : "Gray")[50]
              }`,
            }}
          >
            <Textarea
              minRows={5}
              className="composer-body"
              onChange={this.onChangeManualEdit}
              value={this.state.summaryStr}
              style={{
                color: this.state.editError
                  ? COLOR_SYSTEM.get("Red")[400]
                  : COLOR_SYSTEM.get("Gray")[900],
                padding: "20px",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Static>
          {this.state.newSummary && (
            <Static customStyle={{ marginBottom: "40px", padding: "0px 20px" }}>
              <BoardSummary
                summary={this.state.newSummary}
                summaryGroups={this.props.summaryGroups}
              />
            </Static>
          )}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  summaryGroups: state.board.summaryGroups,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  getSummaryGroupCount: (keyword: string) =>
    actions.board.getSummaryGroupCount(keyword),
};
export default connect(mapStateToProps, mapDispatchToProps)(SummaryDetail);
