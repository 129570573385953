import React, { Component } from 'react';
import './FileInputButton.css';
import { IonPopover, IonTitle, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';

type Props = {
  accept?: string;
  className?: string;
  iconName?: string;
  onSelect? : (e) => void;
};

class FileInputButton extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    let accept = "*"
    if(this.props.accept)
      accept = this.props.accept;
    return (
      <div className={this.props.className}>
        <div className="file-input-button-file-container">
          <IonButton color="file-input-button" fill="clear">                
            <IonIcon class="file-input-button-icon" name={this.props.iconName}/>
          </IonButton>
          <input className="file-input-button-file" multiple={true} type="file" accept={accept} onChange={this.props.onSelect}/>
        </div>
      </div>
    );
  }
}

export default FileInputButton;