import { action, createAction, createAsyncAction } from 'typesafe-actions';
import { OrderInfo } from '../../models/Model.Sale';
import {SaleActionTypes} from './types'

export const getProduct = createAction(SaleActionTypes.SALE_PRODUCT_FETCH, resolve =>
  (id:number) => resolve(id)
);

export const getOrder = createAction(SaleActionTypes.SALE_ORDER_FETCH, resolve =>
  (id:number) => resolve(id)
);

export const refreshOrders = createAction(SaleActionTypes.SALE_ORDERS_REFRESH, resolve =>
  () => resolve()
);

export const getOrders = createAction(SaleActionTypes.SALE_ORDERS_FETCH, resolve =>
  (pid:number) => resolve(pid)
);

export const setOrder = createAction(SaleActionTypes.SALE_ORDER_SET, resolve =>
  (order:OrderInfo) => resolve(order)
);

export const fetchProduct = createAsyncAction(
  SaleActionTypes.SALE_PRODUCT_REQUEST,
  SaleActionTypes.SALE_PRODUCT_SUCCESS,
  SaleActionTypes.SALE_PRODUCT_ERROR,
)<any, any, any>(); // <string, EventList, Error>();

export const fetchOrder = createAsyncAction(
  SaleActionTypes.SALE_ORDER_REQUEST,
  SaleActionTypes.SALE_ORDER_SUCCESS,
  SaleActionTypes.SALE_ORDER_ERROR,
)<any, any, any>(); // <string, EventList, Error>();

export const fetchOrders = createAsyncAction(
  SaleActionTypes.SALE_ORDERS_REQUEST,
  SaleActionTypes.SALE_ORDERS_SUCCESS,
  SaleActionTypes.SALE_ORDERS_ERROR,
)<void, any, any>(); // <string, EventList, Error>();
