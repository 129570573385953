import { UserInfo } from "./../../models/Model.User";

export enum AuthStatus {
  UNKNOWN,
  REGISTERING,
  AUTHENTICATED,
  EXPIRED,
}

export interface UserState {
  me: UserInfo | null;
  authStatus: AuthStatus;
  token: string;
  isAccomplished: boolean;
  users?: any;
  freeSummaryCount?: number;
}

export enum UserActionTypes {
  USER_KAKAO_LOGIN = "@@user/USER_KAKAO_LOGIN",
  USER_REQUEST_KAKAO_LOGOUT = "@@user/USER_REQUEST_KAKAO_LOGOUT",
  USER_SUCCESS_KAKAO_LOGOUT = "@@user/USER_SUCCESS_KAKAO_LOGOUT",
  USER_ERROR_KAKAO_LOGOUT = "@@user/USER_ERROR_KAKAO_LOGOUT",
  USER_LOGOUT = "@@user/USER_LOGOUT",
  USER_LOGIN = "@@usser/USER_LOGIN",
  USER_ME = "@@user/USER_ME",
  USER_SET_ME = "@@user/USER_SET_ME",
  USER_REQUEST_ME = "@@user/USER_REQUEST_ME",
  USER_SUCCESS_ME = "@@user/USER_SUCCESS_ME",
  USER_ERROR_ME = "@@user/USER_ERROR_ME",
  USER_INFO = "@@user/USER_INFO",
  USER_REQUEST_INFO = "@@user/USER_REQUEST_INFO",
  USER_SUCCESS_INFO = "@@user/USER_SUCCESS_INFO",
  USER_ERROR_INFO = "@@user/USER_ERROR_INFO",
  USER_TOKEN_SET = "@@user/USER_TOKEN_SET",
  USER_CHECK_ACCOMPLISHED = "@@user/USER_CHECK_ACCOMPLISHED",
  USER_REQUEST_ACCOMPLISHED = "@@user/USER_REQUEST_ACCOMPLISHED",
  USER_SUCCESS_ACCOMPLISHED = "@@user/USER_SUCCESS_ACCOMPLISHED",
  USER_ERROR_ACCOMPLISHED = "@@user/USER_ERROR_ACCOMPLISHED",
  USER_BOARD_STATISTICS = "@@user/USER_BOARD_STATISTICS",
  USER_REQUEST_BOARD_STATISTICS = "@@user/USER_REQUEST_BOARD_STATISTICS",
  USER_SUCCESS_BOARD_STATISTICS = "@@user/USER_SUCCESS_BOARD_STATISTICS",
  USER_ERROR_BOARD_STATISTICS = "@@user/USER_ERROR_BOARD_STATISTICS",
  USER_RECIEVED_COMMENTS = "@@user/USER_RECIEVED_COMMENTS",
  USER_REQUEST_RECIEVED_COMMENTS = "@@user/USER_REQUEST_RECIEVED_COMMENTS",
  USER_SUCCESS_RECIEVED_COMMENTS = "@@user/USER_SUCCESS_RECIEVED_COMMENTS",
  USER_ERROR_RECIEVED_COMMENTS = "@@user/USER_ERROR_RECIEVED_COMMENTS",
  USER_GET_FREE_SUMMARY_COUNT = "@@user/USER_GET_FREE_SUMMARY_COUNT",
  USER_REQUEST_FREE_SUMMARY_COUNT = "@@user/USER_REQUEST_FREE_SUMMARY_COUNT",
  USER_SUCCESS_FREE_SUMMARY_COUNT = "@@user/USER_SUCCESS_FREE_SUMMARY_COUNT",
  USER_ERROR_FREE_SUMMARY_COUNT = "@@user/USER_ERROR_FREE_SUMMARY_COUNT",
  USER_DECREASE_FREE_SUMMARY_COUNT = "@@user/USER_DECREASE_FREE_SUMMARY_COUNT",
  USER_INCREASE_FREE_SUMMARY_COUNT = "@@user/USER_INCREASE_FREE_SUMMARY_COUNT",
}
