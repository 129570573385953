import React from "react";
import { JobOfferInfo } from "../../../models/Model.JobPost";
import { OptionType } from "../../../types/jobpost";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheet, { BottomSheetProps } from "../../molecules/BottomSheet/BottomSheet";
import { MoreBottomSheetContainer } from "./style";
import { JOBOFFER_ALIM_FREE_MAX } from "./../../../config.json";


interface OptionBottomSheetProps extends BottomSheetProps {
  isMyPost?: boolean;
  isManager: boolean;
  biz?: boolean;
  post?: JobOfferInfo;
  onSelect?: (optionType: OptionType) => () => void;
}

const OptionBottomSheet: React.FC<OptionBottomSheetProps> = ({
  active,
  isMyPost,
  isManager,
  post,
  biz = false,
  onClose,
  onSelect,
}) => {
  return (
    <BottomSheet active={active} onClose={onClose}>
      <MoreBottomSheetContainer>
        <ul>
          {isMyPost && (
            <>
              <li onClick={onSelect("raise")}>
                <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                  끌어올리기
                </Text>
              </li>
              {isMyPost && post.initCandidateCnt >= JOBOFFER_ALIM_FREE_MAX && (
                <li onClick={onSelect("alim")}>
                  <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                    알림톡 보내기
                  </Text>
                </li>
              )}
              <li onClick={onSelect("edit")}>
                <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                  공고 수정하기
                </Text>
              </li>
              <li onClick={onSelect("finish")}>
                <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                  공고 종료하기
                </Text>
              </li>
            </>
          )}

          {isManager && (
            <>
              {!biz && (
                <li onClick={onSelect("edit")}>
                  <Text textType="InputLarge" color={COLOR_SYSTEM.get("Blue")[800]}>
                    공고 수정하기 (관리자)
                  </Text>
                </li>
              )}
              <li onClick={onSelect("finish")}>
                <Text textType="InputLarge" color={COLOR_SYSTEM.get("Red")[800]}>
                  공고 종료하기 (관리자)
                </Text>
              </li>
              <li onClick={onSelect("URL")}>
                <Text textType="InputLarge" color={COLOR_SYSTEM.get("Blue")[800]}>
                  URL 생성하기
                </Text>
              </li>
            </>
          )}
        </ul>
      </MoreBottomSheetContainer>
    </BottomSheet>
  );
};

export default OptionBottomSheet;
