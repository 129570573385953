import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton, IonHeader, IonToolbar, IonButtons, IonIcon, IonLabel, IonContent, IonSegment, IonSegmentButton, IonList } from '@ionic/react';
import './../Common.css';
import './Notice.css';
import SimpleBoardList from './../components/SimpleBoardList'
import {BoardType} from './../store/board/types'
import {log, LogLevel} from '../utils/LogUtil'
import {Notification} from '../models/Model.Notification'

import {getGlobal, GlobalKey} from './../utils/GlobalUtil'
import NotificationListItem from '../components/NotificationListItem';
import BoardContentList from '../components/BoardContentList';
import BoardContentListSimpleItem from '../components/BoardContentListSimpleItem';
import BoardContentListFoldItem from '../components/BoardContentListFoldItem';
import BoardContentListNoticeItem from '../components/BoardContentListNoticeItem';
import { UserLevel } from '../models/Model.User';

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

class Notice extends Component<Props> {
  type = "notice";
  preference = {
    "notice" : {
      title:"공지사항",
      boardType: BoardType.NOTICE,
      props: {showDate: true},
      component: BoardContentListNoticeItem
    },
    "faq" : {
      title:"FAQ",
      boardType: BoardType.FAQ,
      props: null,
      component: BoardContentListFoldItem
    }
  }
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "Notice constructor", props);
    let params:any = props.match.params;
    this.type = params.type;
    this.props.loadBoard(this.preference[this.type].boardType);
  }

  onBack = () => {
    this.props.history.goBack();
  }

  onCompose = () => {
    this.props.history.push( `/new?category=${this.preference[this.type].boardType}`);
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "Notice:render");
    let title = this.preference[this.type].title;
    let boards = this.props.boards[this.preference[this.type].boardType];

    let admin;
    if(this.props.me.level >= UserLevel.MANAGER){
      admin = (
        <IonButtons slot="end">
          <IonButton class="boards-toolbar-admin" onClick={this.onCompose}>
            <IonIcon name="add" slot="icon-only" mode="md"/>
          </IonButton>
        </IonButtons>
      );
    }

    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button" onClick={this.onBack}>
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="common-header-title" onClick={this.onBack}>{title}</IonLabel>
            </IonButtons>
            {admin}
          </IonToolbar>
        </IonHeader>
        <IonContent class="common-content-with-header">
          <div className="notice-container common-scroll">
            <BoardContentList loading={boards.loading} contents={boards.contents} checksum={boards.checksum} component={this.preference[this.type].component} props={this.preference[this.type].props}/>
          </div>
        </IonContent>
      </>

    );
  }
}

const mapStateToProps = (state: RootState) => ({
  boards : state.board.boards,
  me: state.user.me,
});

const mapDispatchToProps = {
  loadBoard : (id: number) => actions.board.getBoard(id, 20),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notice));