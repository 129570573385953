import axios from "axios"
import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter } from "react-router-dom";
import {
  IonButton,
  IonInput,
  IonList,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { bizFetchAPI, fetchAPI } from "../../../utils/APIUtil";
import ProfileImage from "../../../components/ProfileImage";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { log, LogLevel } from "../../../utils/LogUtil";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { UserTypeName } from "../../../models/Model.User";

const USER_ROW_CNT = 30;


const API = {
  MEMBER_LIST: {
    method: "GET",
    path: "/cc/admin",
    contentType: "application/json",
  },
  UPDATE_MEMBER: {
    method: "PUT",
    path: "/cc/admin",
    contentType: "application/json",
  },
  DELETE_MEMBER: {
    method: "DELETE",
    path: "/cc/admin",
    contentType: "application/json",
  },
  CREATE_MEMBER: {
    method: "POST",
    path: "/cc/admin",
    contentType: "application/json",
  }
}



const CCCreateUser = (props) => {
  const [search, setSearch] = useState("");
  const [partners, setPartners] = useState([]);
  const [showPartners, setShowPartners] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const itemsPerPage = 10;
  
  const { selectedService } = props;

  // 현재 페이지에 맞게 파트너 리스트를 분할하는 로직
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = partners.slice(indexOfFirstItem, indexOfLastItem);

  // 페이지 변경 핸들러
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const userListRef = useRef(null);
  let loading = false;
  let finished = false;


  useEffect(() => {
    fetchData();
  }, [search, showPartners]);

  const onSearchChange = (e) => {
    setSearch(e.detail.value);
  };

const onCreateUser = (e) => {
  bizFetchAPI(
    API.CREATE_MEMBER,
    '',
    null,
    {
      id: e.id,
      userName: e.userName,
      email: e.email,
      phoneNumber: e.phone,
      division: e.division,
      password: e.password,
      userType: selectedService
    },
    getGlobal(GlobalKey.TOKEN)
  )
  .then((response) => {
    // Show a success toast
    setToastMessage(null);
    setToastMessage('유져 생성완료');

    fetchData();
    props.onUserCreated();
  })
  .catch((error) => {
    console.error('Error:', error);
    // Show an error toast
    setToastMessage(null);
    setToastMessage('Error saving changes');
  });
}


  const fetchData = async () => {
    if (loading) return;
    loading = true;

    const result = await bizFetchAPI(
      API.MEMBER_LIST,
      '',
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );

    log(LogLevel.ALL, "fetchData", result.data)

    if (result) {
      finished = Array.isArray(result.data.data) && result.data.data.length < USER_ROW_CNT;
      setPartners(Array.isArray(result.data.data) ? result.data.data : []);
    } else {
      finished = true;
      setPartners([]);
    }

    loading = false;
  };


  return (
    <div className="admin-container">
        {toastMessage && <Toast message={toastMessage} duration={2000} />}
      <IonList ref={userListRef}>
        <div className="partners-list">
            <UserInsertForm  onSave={onCreateUser} setToastMessage={setToastMessage} selectedService={selectedService} />
        </div>
      </IonList>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CCCreateUser);



function UserInsertForm({ onSave, setToastMessage, selectedService}) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handlePasswordChange = (e) => {
        handleFieldChange('password', e.target.value)
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    };
    const [passwordsMatch, setPasswordsMatch] = useState(false);


    useEffect(() => {
        if (password && confirmPassword) {
          if (password !== confirmPassword) {
            setToastMessage(null);
            setToastMessage('Passwords do not match');
            setPasswordsMatch(false);
          } else {
            setPasswordsMatch(true);
          }
        }
      }, [password, confirmPassword]);

    
    const [editFields, setEditFields] = useState({
        userName: "",
        email: "",
        division: "",
        phone: "",
        password: "",
        userType: selectedService
    });

    const handleFieldChange = (field, value) => {
        setEditFields((prevFields) => ({
        ...prevFields,
        [field]: value,
        }));
    };

    const handleSaveClick = () => {
        onSave(editFields);
    };


  return (
    <PartnerDetailContainer>
        <ButtonContainer>
            <Button onClick={handleSaveClick} disabled={!passwordsMatch}>유져생성</Button>
        </ButtonContainer>
        <EditableField>
            <h1>{UserTypeName[selectedService]}</h1>
        </EditableField>
        <EditableField>
            <strong>Name:</strong>
            <Input
                value={editFields.userName}
                onChange={(e) => handleFieldChange('userName', e.target.value)}
            />
        </EditableField>
        <EditableField>
            <strong>Email:</strong>
            <Input
                value={editFields.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
            />
        </EditableField>
        <EditableField>
            <strong>Division:</strong>
            <Input
                value={editFields.division}
                onChange={(e) => handleFieldChange('division', e.target.value)}
            />
        </EditableField>
        <EditableField>
            <strong>Phone:</strong>
            <Input
                value={editFields.phone}
                onChange={(e) => handleFieldChange('phone', e.target.value)}
            />
        </EditableField>
        <EditableField>
            <strong>Password:</strong>
            <Input
                type="password"
                value={password}
                onChange={handlePasswordChange}
            />
        </EditableField>
        <EditableField>
            <strong>Confirm Password:</strong>
            <Input
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
            />
        </EditableField>
    </PartnerDetailContainer>
  );
}



interface ToastProps {
  message: string;
  duration?: number;
}

const Toast: React.FC<ToastProps> = ({ message, duration = 2000 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  if (!visible) return null;

  return (
    <div style={{ position: 'fixed', top: '50px', right: 0, backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: '5px' }}>
      {message}
    </div>
  );
};

const PartnerDetailContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  padding-top: 60px;
  position: relative;
  margin-bottom: 20px;
  width: 90%; // Adjust this to your preferred width
  max-width: 1200px; // Adjust this to your preferred maximum width
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px
`;

const EditableField = styled.div`
  margin: 5px 0;
  &:hover {
    background-color: #f0f0f0;
  }
`;



const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  border: none;
  background: none;
  padding: 0; // Remove the default padding
  padding-left: 20px;
  outline: none;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease;
  background-color: ${props => props.disabled ? 'grey' : 'green'};
  color: white;

  &:hover {
    background-color: #0056b3; // Change this to your preferred hover color
  }

  &:focus {
    outline: none;
  }
`;