import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import {
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import "./../theme.css";
import "./Home.css";
import logoIcon from "./../assets/image/logo_text.png";
import kakaoIcon from "./../assets/icon/question_small_yellow_mobile_3X.png";
import { BoardType, BoardListState } from "./../store/board/types";
import { log, LogLevel } from "../utils/LogUtil";

import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import { UserInfo, UserLevel } from "../models/Model.User";
import NotificationBell from "../components/NotificationBell";
import { BoardContent } from "../models/Model.Board";
import RecommendContentListItem from "../components/RecommendContentListItem";
import AnalyticsUtil from "./../utils/AnalyticsUtil";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {};

const windowAny: any = window;

class Home extends Component<Props> {
  contentScroll = null;
  constructor(props: Props) {
    super(props);
    if (!getGlobal(GlobalKey.WELCOME_DONE, true) && !this.props.me.guided) {
      log(LogLevel.UI_LIFECYCLE, "Home:constructor jump to manual");
      this.props.history.replace("/welcome");
    } else this.fetchData();
  }

  fetchData(props = this.props) {
    let thisBoardList: BoardListState = props.board;
    if (!thisBoardList || thisBoardList.needReload) {
      log(LogLevel.UI_DATA_LOAD, "Home:fetchData LoadBoard", thisBoardList);
      props.loadRecommend();
    }
  }

  scroll = (event: CustomEvent) => {
    // log(LogLevel.UI_EVENT, "Scroll", event.detail.scrollTop);
    setGlobal(GlobalKey.SCROLL + "Home", event.detail.scrollTop);
  };

  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = () => {
    setTimeout(async () => {
      try {
        let scroll = getGlobal(GlobalKey.SCROLL + "Home");
        if (scroll) {
          if (this.contentScroll) {
            log(
              LogLevel.UI_EVENT,
              "Home:autoScroll",
              this.contentScroll,
              scroll,
              this.contentScroll && scroll
            );
            await this.contentScroll.scrollToPoint(null, scroll);
          } else {
            this.autoScrollTry++;
            if (this.autoScrollTry < this.autoScrollTryMax) {
              log(
                LogLevel.UI_EVENT,
                "Home:autoScroll Try ",
                this.autoScrollTry,
                this.contentScroll,
                scroll
              );
              this.autoScroll();
            } else {
              log(
                LogLevel.UI_EVENT,
                "Home:autoScroll Try FINISHED FAILED",
                this.autoScrollTry,
                this.contentScroll,
                scroll
              );
            }
          }
        } else {
          log(LogLevel.UI_EVENT, "Home:autoScroll no scroll ");
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "Home:autoScroll failed", e);
        this.autoScrollTry++;
        if (this.autoScrollTry < this.autoScrollTryMax) {
          log(
            LogLevel.UI_EVENT,
            "Home:autoScroll Try ",
            this.autoScrollTry,
            this.contentScroll,
            scroll
          );
          this.autoScroll();
        } else {
          log(
            LogLevel.UI_EVENT,
            "Home:autoScroll Try FINISHED FAILED",
            this.autoScrollTry,
            this.contentScroll,
            scroll
          );
        }
      }
    }, 100);
  };

  componentDidMount() {
    this.autoScroll();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.board &&
      this.props.board &&
      this.props.board.checksum == nextProps.board.checksum
    ) {
      log(LogLevel.UI_LIFECYCLE, "Home:shouldComponentUpdate skip");
      return false;
    }
    log(LogLevel.UI_LIFECYCLE, "Home:shouldComponentUpdate update");
    return true;
  }

  componentWillUpdate(nextProps) {
    this.fetchData(nextProps);
    log(LogLevel.UI_LIFECYCLE, "SimpleBoardList.componentWillUpdate");
  }

  onSearch = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "HOME_TO_SEARCH",
      "홈에서 검색진입"
    );
    this.props.history.push("/main/search");
  };

  onSelectItem = (content: BoardContent) => {
    this.props.history.push(`/boards/view?id=${content.id}`);
  };

  onNew = () => {
    this.props.history.push(`/new?category=1`);
  };

  doAsk = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "HOME_TO_ASK",
      "홈에서 질문작성연결"
    );
    this.props.history.push(`/new?category=1`);
  };

  doPost = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "HOME_TO_POST",
      "홈에서 새글쓰기연결"
    );
    this.props.history.push(`/new?category=2`);
  };

  doScrap = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "HOME_TO_SCRAP",
      "홈에서 스크랩페이지연결"
    );
    this.props.history.push(`/main/bookmark`);
  };

  doChat = async () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "HOME_TO_PLUSFRIEND",
      "홈에서 플러스친구연결"
    );
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") {
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
      try {
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: "_Vxjhxgj", // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    } else {
      let plusFriendTemplate = {
        plusFriendId: "_Vxjhxgj",
      };
      try {
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "Home:render");

    let list: BoardContent[] = [];
    if (
      this.props.board &&
      this.props.board.contents &&
      this.props.board.contents.length > 0
    )
      list = this.props.board.contents;

    let admin;
    if (this.props.me.level >= UserLevel.MANAGER) {
      admin = (
        <IonButton class="home-admin" href="/admin">
          <IonIcon name="cog" slot="icon-only" />
        </IonButton>
      );
    }

    let suggestion, fab;
    if (list.length) {
      suggestion = (
        <IonList class="home-suggestion-list">
          {list.map((item: BoardContent, index: number) => (
            <RecommendContentListItem
              key={index.toString()}
              content={item}
              onSelect={this.onSelectItem}
            />
          ))}
        </IonList>
      );

      fab = (
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton color="home-fab" onClick={this.onNew}>
            <IonLabel class="home-fab-text">
              질문
              <br />
              하기
            </IonLabel>
          </IonFabButton>
        </IonFab>
      );
    } else {
      suggestion = (
        <div className="home-suggestion-container">
          <div className="home-suggestion-finished-text">
            추천 게시물을 모두 읽으셨습니다.{" "}
          </div>
          <div className="home-suggestion-activity">추천 활동</div>
          <div className="home-suggestion-activity-item-container">
            <div className="home-suggestion-activity-item-text">
              커뮤니티에 기여하는 가장 좋은 방법,
              <br />
              짧은 글이라도 게시물을 작성해보세요.
            </div>
            <IonButton
              color="home-suggestion-activity-item-button"
              onClick={this.doAsk}
            >
              질문
              <br />
              하기
            </IonButton>
            <IonButton
              color="home-suggestion-activity-item-button"
              onClick={this.doPost}
            >
              글남
              <br />
              기기
            </IonButton>
          </div>
          <div className="home-suggestion-activity-item-container">
            <div className="home-suggestion-activity-item-text">
              스크랩북에 보관된 컨텐츠를 다시 읽어보세요.
            </div>
            {/* <IonButton color="home-suggestion-activity-icon-button" onClick={this.doScrap}><img className="home-suggestion-activity-icon" src={scrapIcon}/></IonButton> */}
          </div>
          <div className="home-suggestion-activity-item-container">
            <div className="home-suggestion-activity-item-text">
              약문약답의 발전을 위해 피드백을 남겨주세요.
              <br />
              (불편한점, 좋은점, 기능 제안 등)
            </div>
            <IonButton
              color="home-suggestion-activity-icon-button"
              onClick={this.doChat}
            >
              <img
                className="home-suggestion-activity-icon-kakao"
                src={kakaoIcon}
              />
            </IonButton>
          </div>
        </div>
      );
    }

    return (
      <>
        <IonContent
          class="home-content"
          ref={(ref) => {
            this.contentScroll = ref;
          }}
          scrollEvents={true}
          onIonScroll={this.scroll}
        >
          <div className="home-logo-container">
            <img className="home-logo" src={logoIcon} />
            <div className="home-alarm">
              {admin}
              <NotificationBell />
            </div>
          </div>
          <div className="home-qustion-suggest" onClick={this.onSearch}>
            <div className="home-qustion-suggest-text">
              게시물 또는 의약품 상세정보 검색
            </div>
            <IonIcon class="home-qustion-suggest-icon" name="search" />
          </div>
          {suggestion}
        </IonContent>
        {fab}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  board: state.board.boards[BoardType.RECOMMEND],
  me: state.user.me,
});

const mapDispatchToProps = {
  loadRecommend: () => actions.board.getBoard(BoardType.RECOMMEND),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
