import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import JobPostSuccessSurveyTemplate from "../../components/templates/JobPostSuccessSurvey";
import { JobOfferResult, JobOfferInfo, JobOfferName, JobOfferStatus } from "../../models/Model.JobPost";
import { actions, RootState } from "../../store";
import { JobListType } from "../../store/jobpost/types";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";
import * as API from "./../../API.json";

const JobPostSuccessSurvey: React.FC<RouteComponentProps> = ({ history, location }) => {
  if (!location.state) {
    history.push("/main/jobpost");
    return null;
  }

  const offer = useSelector((state: RootState) => state.jobpost.offers[location.state.postId]);
  const dispatch = useDispatch();

  // 공고 종료하기
  const finalizePost = async (passed: number[], jobOfferResult: JobOfferResult) => {
    log(LogLevel.UI_ACTION, "JobPostDetail:finalizePost: ", offer);
    const offerNew: JobOfferInfo = {
      id: offer.id,
      status: JobOfferStatus.ENDED,
      passCnt: 0,
    };

    switch (jobOfferResult) {
      case JobOfferResult.Success:
        if (!passed.includes(-1)) {
          offerNew.passCnt = passed.length;
          for (let i = 0; i < passed.length; i++)
            await fetchAPI(API.JOBPOST_PASS, "", { id: passed[i] }, null, getGlobal(GlobalKey.TOKEN));
        } else {
          offerNew.passCnt = 999;
        }
        break;
      case JobOfferResult.OtherService:
        offerNew.passCnt = -1;
        break;
      case JobOfferResult.Fail:
      default:
        offerNew.passCnt = 0;
        break;
    }

    fetchAPI(API.JOBPOST_OFFER_UPDATE, "", null, offerNew, getGlobal(GlobalKey.TOKEN))
      .then((result) => {
        if (result && !result.error) {
          let spendDay = 0;

          if (offer && offer.startedAt) {
            let startedAt = new Date(offer.startedAt).valueOf();
            let endsAt = Date.now();
            spendDay = Math.floor((endsAt - startedAt) / (24 * 60 * 60 * 1000));
          }

          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "JOBPOST_PHARMACY_FINALIZE_DONE",
            "구인구직_약국_공고_종료_완료",
            {
              offerId: offer.id,
              type: JobOfferName[offer.workType],
              surveyReponse: false,
              spendDay,
              region: offer && offer.pharmacy && offer.pharmacy.region ? offer.pharmacy.region : "",
              applyCnt: offer.chats ? offer.chats.length : 0,
              successCnt: offerNew.passCnt,
              success: jobOfferResult === JobOfferResult.Success,
              jobOfferResult: JobOfferResult[jobOfferResult],
            }
          );
          dispatch(actions.jobpost.updateOffer(offer));
          dispatch(actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.ALL));
          dispatch(actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY));
        }
      })
      .catch((e) => {
        log(LogLevel.UI_EXCEPTION, "JobPostDetail:exception: ", e);
      });
  };

  return <JobPostSuccessSurveyTemplate jobPost={offer} onFinalize={finalizePost} />;
};

export default withRouter(JobPostSuccessSurvey);
