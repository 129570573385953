import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
  AllInOnePartnerInfo,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import AllInOnePartnerManageProfile from "./component/allinone/AllInOnePartnerManageProfile";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import AllInOnePartnerSelect from "./component/allinone/AllInOnePartnerSelect";
import ToggleSwitch from "../component/atom/ToggleSwitch";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selected: AllInOnePartnerInfo;
};

class AllInOnePartnerManage extends Component<Props, State> {
  popup = null;
  list = null;

  state = {
    selected: null,
    showPartners: true,
  };

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
    document.title = "법인회원 관리";
  }

  componentDidMount() {}

  onSelect = (partner: AllInOnePartnerInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", partner);
    var x = document.getElementById("admin-sub-conainer");
    this.setState({ selected: partner });
  };

  onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    if (this.list) this.list.refresh();
    this.setState({ selected: null });
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({ selected: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "AllInOnePartnerManage.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">법인회원 관리</IonText>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <AllInOnePartnerSelect
                onDone={this.onSelect}
                ref={(ref) => {
                  this.list = ref;
                }}
              />
            </div>
            {this.state.selected && (
              <div id="admin-sub-conainer" className="admin-container">
                <AllInOnePartnerManageProfile
                  partner={this.state.selected}
                  onDone={this.onDone}
                  onCancel={this.onCancel}
                />
              </div>
            )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllInOnePartnerManage)
);
