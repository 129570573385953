import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { JobOfferInfo, JobOfferName } from "../../../models/Model.JobPost";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { COLOR_SYSTEM } from "../../design/design-system";
import * as API from "../../../API.json";
import { MY_ADDRESS } from "../../../config.json";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useClipboard from "../../../hooks/useClipboard";
import ShareButton from "../ShareButton";

const ShareListLayout = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

type SharePath = {
  kakao?: boolean;
  sms?: boolean;
  link?: boolean;
};

interface ShareListProps {
  onKakao?: React.MouseEventHandler<HTMLElement>;
  onSMS?: React.MouseEventHandler<HTMLElement>;
  onLink?: React.MouseEventHandler<HTMLElement>;
  sharePath?: SharePath;
  offer?: JobOfferInfo;
}

const windowAny: any = window;

const ShareList: React.FC<ShareListProps> = ({ sharePath, offer, onKakao, onSMS, onLink }) => {
  const { Kakao, KakaoCordovaSDK } = windowAny;
  const me = useSelector((state: RootState) => state.user.me);
  // const { id, pharmacy, workType, likeCnt, viewCnt } = offer;
  // const { region: pharmacyRegion } = pharmacy;
  const { name: userName } = me;
  const { copyToClipboard } = useClipboard();
  const isBiz = useMemo(() => window.location.pathname.includes("biz"), []);

  const handleClickShare = useCallback(
    (path: keyof SharePath) => () => {
      const linkUrl = window.location.href;
      const methods = {
        kakao() {
          if (!offer || !offer.pharmacy) return;

          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_RAISE", "구인구직_공고_상세보기_공유하기", {
            공고id: offer.id,
            type: "카카오",
          });

          fetchAPI(API.JOBPOST_SHARE, offer.id, null, null, getGlobal(GlobalKey.TOKEN));
          const os = getGlobal(GlobalKey.OS);
          const title = isBiz
            ? `${offer.companyName || offer.pharmacy.name}(${offer.companyRegion}) 약사 구인 추천`
            : `${offer.pharmacy.region} ${JobOfferName[offer.workType]} 약사 구인 추천`;
          const description = isBiz
            ? `${userName} 약사님이 추천한 구인공고를 지금 확인해보세요 ^^`
            : `${userName} 약사님이 추천한 약국 구인공고를 지금 확인해보세요 ^^`;
          const executionParams = isBiz ? `type=jobpost&bizOfferId=${offer.id}` : `type=jobpost&offerId=${offer.id}`;

          if (!os || os === "browser") {
            const feed = {
              objectType: "feed",
              content: {
                title,
                description,
                imageUrl: `${MY_ADDRESS}/images/ymyd_jobpost_kakao_share.jpg`,
                link: {
                  androidExecParams: executionParams,
                  iosExecParams: executionParams,
                },
              },
              social: {
                likeCount: offer.likeCnt,
                viewCount: offer.viewCnt,
              },
              buttons: [
                {
                  title: "App에서 보기",
                  link: {
                    androidExecParams: executionParams,
                    iosExecParams: executionParams,
                  },
                },
              ],
            };

            try {
              Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
            } catch (e) {}
            Kakao.Link.sendDefault(feed);
          } else if (KakaoCordovaSDK) {
            const feed = {
              objectType: "feed",
              content: {
                title,
                desc: description,
                imageURL: MY_ADDRESS + "/images/ymyd_jobpost_kakao_share.jpg",
                link: {
                  androidExecutionParams: executionParams,
                  iosExecutionParams: executionParams,
                },
              },
              social: {
                likeCount: offer.likeCnt,
                viewCount: offer.viewCnt,
              },
              buttons: [
                {
                  title: "App에서 보기",
                  link: {
                    androidExecutionParams: executionParams,
                    iosExecutionParams: executionParams,
                  },
                },
              ],
            };
            KakaoCordovaSDK.sendLinkFeed(
              feed,
              (res) => {
                console.log("ViewerBody: doKakaoShare: Kakao share success", res, feed);
              },
              (res) => {
                console.log("ViewerBody: doKakaoShare: Kakao share fail", res, feed);
              }
            );
          }
        },
        sms() {
          const title = isBiz
            ? `${offer.companyName || offer.pharmacy.name}(${offer.companyRegion}) 약사 구인 추천`
            : `${offer.pharmacy.region} ${JobOfferName[offer.workType]} 약사 구인 추천`;
          const description = isBiz
            ? `${userName} 약사님이 추천한 구인공고를 지금 확인해보세요 ^^`
            : `${userName} 약사님이 추천한 약국 구인공고를 지금 확인해보세요 ^^`;
          const isApple =
            navigator.userAgent.toLocaleLowerCase().includes("iphone") ||
            navigator.userAgent.toLocaleLowerCase().includes("mac");

          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_RAISE", "구인구직_공고_상세보기_공유하기", {
            공고id: offer.id,
            type: "문자메세지",
          });

          window.location.href = `sms:${isApple ? "&" : "?"}body=${title}%0D%0A${description}%0D%0A${linkUrl}`;
        },
        link() {
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_RAISE", "구인구직_공고_상세보기_공유하기", {
            공고id: offer.id,
            type: "링크복사",
          });

          copyToClipboard(linkUrl)();
        },
      };

      if (methods[path]) methods[path]();
    },
    [offer, me]
  );

  return (
    <ShareListLayout>
      {onKakao && (
        <ShareButton
          backgroundColor="#FEE500"
          iconWidth={22}
          iconHeight={20}
          icon="Kakao"
          iconColor="#000000"
          title="카카오톡"
          // onClick={handleClickShare("kakao")}
          onClick={onKakao}
        />
      )}

      {onSMS && (
        <ShareButton
          backgroundColor={COLOR_SYSTEM.get("Green")[400]}
          iconWidth={22}
          iconHeight={20}
          icon="EnvelopeOutLined"
          iconColor="#ffffff"
          title="문자"
          onClick={onSMS}
        />
      )}

      {onLink && (
        <ShareButton
          backgroundColor={COLOR_SYSTEM.get("Gray")[15]}
          iconWidth={22}
          iconHeight={20}
          icon="Link"
          iconColor={COLOR_SYSTEM.get("Gray")[900]}
          title="링크 복사"
          onClick={onLink}
        />
      )}
    </ShareListLayout>
  );
};

export default ShareList;
