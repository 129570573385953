
import React, { Component, SyntheticEvent } from 'react';
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonModal, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonToggle } from '@ionic/react';
import './VideoPlayer.css';
import {log, LogLevel} from '../utils/LogUtil'
import {setGlobal, GlobalKey, getGlobal} from './../utils/GlobalUtil'
import { UIPopupType } from '../store/ui/types';
import caretDownIcon from './../assets/icon/caret-down.svg'
import caretUpIcon from './../assets/icon/caret-up.svg'
import AnalyticsUtil from '../utils/AnalyticsUtil';
import StringUtil from '../utils/StringUtil';

type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
    lectureId?:number,
    src?:string,
    srcHighResolution?: string,
    srcPreview?: string,
    onProgress?: () => void,
    onPlay?:() => void,
    onReady?: () => void,
    onDuration?: (playtime: number) => void,
    onEnd?: () => void,
    onSpeedChange?: (speed: number) => void,
    highResolution?: boolean,
}

type State = {
    ready: boolean,
    playing:boolean,
    highResolution: boolean,
    speed: number,
}

class VideoPlayer extends Component<Props, State> {
    player = null;
    playingTimer;
    playingTime = 0;
    duration: number = 0.0;
    lastPlayedTime: number = 0;

    constructor(props: Props) {
        super(props);
        log(LogLevel.UI_EVENT, "VideoPlayer:constructor", props);
 

        this.state = {
            ready: false,
            playing: false,
            highResolution: true,
            speed: 1,
        };

    }

    componentDidMount() {
        if(getGlobal(GlobalKey.OS) == "android")
            document.getElementById("video-player").addEventListener('webkitfullscreenchange', this.onFullScreen)
        this.lastPlayedTime = getGlobal(GlobalKey.SEMINAR_PLAYED_TIME + this.props.lectureId, true);
        if(!this.lastPlayedTime)
            this.lastPlayedTime = 0;
        this.player.playbackRate = 1.0;
    }

    componentWillUnmount () {
        if(this.player){
            setGlobal(GlobalKey.SEMINAR_PLAYED_TIME + this.props.lectureId, Math.floor(this.player.currentTime), true);
        }

        if(getGlobal(GlobalKey.OS) == "android")
            document.getElementById("video-player").removeEventListener('webkitfullscreenchange', this.onFullScreen)
    }

    componentWillUpdate (nextProps, nextState){
        if(this.props.src != nextProps.src || this.props.srcHighResolution != nextProps.srcHighResolution){
            let source = nextProps.src;
            if(!source || this.state.highResolution)
                source = nextProps.srcHighResolution;
            if(source)
                source = StringUtil.convertFilePath(source);
    
            if(this.player)
                this.player.src = source;
        }
    }

    onFullScreen = (e) => {
        let documentAny:any = document;
        let globalAnt:any = global;
        var isFullscreenNow = (documentAny.webkitFullscreenElement !== null)
        if(getGlobal(GlobalKey.OS) == "android" && screen.orientation && screen.orientation.unlock && screen.orientation.lock){
            if(isFullscreenNow){
                screen.orientation.unlock();
            }else{
                screen.orientation.lock("portrait");
            }
        }
        log(LogLevel.UI_EVENT, "VideoPlayer:onFullScreen", documentAny.webkitFullscreenElement, isFullscreenNow);
    }

    onDurationReady = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onDurationReady", this.player.duration);
        if(this.lastPlayedTime > this.player.duration * 0.95)
            this.lastPlayedTime = 0;
        this.player.currentTime = this.lastPlayedTime;
        this.duration = this.player.duration;
        if(this.props.onDuration)
            this.props.onDuration(this.duration);
        this.setState({ready:true});
    }

    onPlayerReady = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerReady");
        if(this.props.onReady)
            this.props.onReady();
    }

    ratioBefore = 0;
    onPlayerPlay = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerStart");
        this.setState({playing:true});
        if(!this.playingTimer) {
            this.playingTimer = setInterval(() => {
                log(LogLevel.UI_EVENT, "VideoPlayer:intervalTimeout" );
                let before = this.playingTime;
                this.playingTime += this.state.speed;
                if(this.duration && this.props.onProgress){
                    let ratio = Math.floor(this.playingTime * 100 / this.duration);
                    if(Math.floor(this.ratioBefore/4) != Math.floor(ratio/4)){
                        log(LogLevel.UI_EVENT, "VideoPlayer:intervalTimeout notify", before, this.playingTime, this.ratioBefore, ratio );
                        this.props.onProgress();
                        if(this.player)
                            setGlobal(GlobalKey.SEMINAR_PLAYED_TIME + this.props.lectureId, Math.floor(this.player.currentTime), true);
                    }
                    this.ratioBefore = ratio;
                }
            }, 1000);
        }
        if(this.props.onPlay)
            this.props.onPlay();
    }

    onPlayerPause = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerPause");
        this.setState({playing:false});
        if(this.playingTimer) {
            clearInterval(this.playingTimer);
            this.playingTimer = 0;
        }

        setGlobal(GlobalKey.SEMINAR_PLAYED_TIME + this.props.lectureId, Math.floor(this.player.currentTime), true);
    }

    onPlayTimeUpdate = () => {
        // log(LogLevel.UI_EVENT, "VideoPlayer:onPlayTimeUpdate", this.player.currentTime, this.player.duration);
        if(!this.player.duration)
            return;
        this.lastPlayedTime = this.player.currentTime;
    }

    onPlayerEnd = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerEnd");
        setGlobal(GlobalKey.SEMINAR_PLAYED_TIME + this.props.lectureId, Math.floor(this.player.currentTime), true);
    }

    onPlayerError = (e) => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerError", e.type);
    }

    doPlay = () => {
        if(this.state.playing){
            this.player.pause();
        }
        else {
            this.player.play();
        }
        this.setState({playing:!this.state.playing});
    }

    onEvent=(name, e:SyntheticEvent = null) => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onEvent",name, e.type, this.player.readyState, this.player.networkState, this.player.duration, this.player.ended, this.player.currentTime , this.player.buffered, this.player.src);
    }

    changeResolution = (highResolution) => {
        if(this.state.highResolution == highResolution)
            return;

        this.lastPlayedTime = this.player.currentTime;
        if(highResolution && this.props.srcHighResolution){
            this.player.src = StringUtil.convertFilePath(this.props.srcHighResolution);
        }else if(this.props.src){
            this.player.src = StringUtil.convertFilePath(this.props.src);
        }
        if(this.state.playing)
            this.player.play();

        this.setState({highResolution});
        log(LogLevel.UI_EVENT, "VideoPlayer:changeResolution", highResolution, this.lastPlayedTime);
    }

    changeSpeed = (speed) => {
        if(this.state.speed == speed)
            return;
        this.player.playbackRate = speed;
        AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", '세미나_속도_변경', {"id":this.props.lectureId, "speed":speed});
        this.setState({speed});
    }

    onSpeedDown = () => {
        switch(this.state.speed){
            case 0.8:
                return;
            case 1.0:
                this.changeSpeed(0.8);
                return;
            case 1.2:
                this.changeSpeed(1);
                return;
            case 1.5:
                this.changeSpeed(1.2);
                return;
            case 2:
                this.changeSpeed(1.5);
                return;
        }
        this.changeSpeed(1);
        return;
    }

    onSpeedUp = () => {
        switch(this.state.speed){
            case 0.8:
                this.changeSpeed(1);
                return;
            case 1.0:
                this.changeSpeed(1.2);
                return;
            case 1.2:
                this.changeSpeed(1.5);
                return;
            case 1.5:
                this.changeSpeed(2);
                return;
            case 2:
                return;
        }
        this.changeSpeed(1);
        return;
    }

    temp = 1;
    onDownload = () => {
        // let windowAny:any = window;
        // windowAny.open("https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/documents/animal_medicine/A00200045.pdf", "_system");
        this.props.toast.show(this.temp.toString());
        this.temp++;
    }

    onPlayerReference = (ref) => {
        log(LogLevel.UI_LIFECYCLE, "VideoPlayer.onPlayerReference", ref );
        this.player = ref;
        let source = this.props.src;
        if(!source)
            source = this.props.srcHighResolution;
        if(source)
            source = StringUtil.convertFilePath(source);

        if(this.player)
            this.player.src = source;
    }

    render() {
        log(LogLevel.UI_LIFECYCLE, "VideoPlayer.render", this.duration, this.state, this.props );
        // let url = StringUtil.convertFilePath(this.lectureUrl);
        let resolution;
        if(this.props.src && this.props.srcHighResolution)
            resolution = (
                <div className={"common-flex-row"}>
                    <div className={"video-play-toolbar-resolution" + ((!this.state.highResolution)?" video-play-toolbar-resolution-selected":"")} onClick={(e) => this.changeResolution(false)}>보통</div>
                    <IonToggle class="video-play-toolbar-resolution-toggle" mode="ios" checked={this.state.highResolution} onClick={(e) =>  this.changeResolution(!this.state.highResolution)}/>
                    <div className={"video-play-toolbar-resolution" + ((this.state.highResolution)?" video-play-toolbar-resolution-selected":"")} onClick={(e) =>  this.changeResolution(true)}>고화질</div>
                </div>
            );

        let thumbnail;
        if(!this.state.playing && this.player && !this.player.currentTime && this.props.srcPreview){
            thumbnail = (
                <div className="common-content common-position-absolute" style={{top:"0px", left:"0px"}} onClick={() => this.player.play()}>
                    <div className="common-content common-position-relative">
                        <IonIcon class="video-play-icon" name="play"/>
                        <img className="common-container" src={StringUtil.convertFilePath(this.props.srcPreview)}/>
                    </div>
                </div>
            );
        }

        return (
            <div className="common-container">
                <div className="common-container common-position-relative">
                    <video className="video-play-player" 
                        ref={this.onPlayerReference} 
                        id="video-player"
                        poster={(this.props.srcPreview?StringUtil.convertFilePath(this.props.srcPreview):"")}
                        playsInline
                        controlsList="nodownload"
                        preload="auto"
                        webkit-playsinline={1}
                        controls={true}
                        loop={false}
                        onCanPlay={this.onPlayerReady}
                        autoPlay={false}
                        // onCanPlayThrough={(e) => this.onEvent("onCanPlayThrough", e)}
                        onPlay={this.onPlayerPlay}
                        onPause={this.onPlayerPause}
                        onEnded={this.onPlayerEnd}
                        onProgress={(e) => this.onEvent("onProgress", e)}
                        onDurationChange={this.onDurationReady}
                        onSeeking={(e) => this.onEvent("onSeeking", e)}
                        onSeeked={(e) => this.onEvent("onSeeked", e)}
                        onWaiting={(e) => this.onEvent("onWaiting", e)}
                        onAbort={(e) => this.onPlayerError}
                        onError={(e) => this.onPlayerError}
                        onLoadStart={(e) => this.onEvent("onLoadStart", e)}
                        onTimeUpdate={this.onPlayTimeUpdate}
                    />
                    {thumbnail}
                </div>
                <div className="video-play-toolbar">
                    {resolution}
                    <div className="common-flex-grow"/>
                    <IonIcon class="video-play-toolbar-speed-down-button" src={caretDownIcon} onClick={this.onSpeedDown}/>
                    <div className="video-play-toolbar-speed">{this.state.speed.toString()}배속</div>
                    <IonIcon class="video-play-toolbar-speed-up-button" src={caretUpIcon} onClick={this.onSpeedUp}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  filePath: state.board.filePath,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoPlayer));