import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { fetchMedicine, SearchType, setSearchedKeyword, fetchPost, switchSearchType } from "../../../store/search";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import TabBar from "../../molecules/TabBar";

const SearchTabBar = () => {
  const dispatch = useDispatch();
  const searchType = useSelector((state: RootState) => state.newSearch.searchType);
  const currentSearchedKeyword = useSelector((state: RootState) => state.newSearch.currentSearchedKeyword);
  const postSearchedKeyword = useSelector((state: RootState) => state.newSearch.post.searchedKeyword);
  const medicineSearchedKeyword = useSelector((state: RootState) => state.newSearch.medicine.searchedKeyword);

  const handleClickTab = useCallback(
    (tab: SearchType) => () => {
      try {
        if (tab === "Post") {
          if (postSearchedKeyword !== currentSearchedKeyword) {
            dispatch(setSearchedKeyword("Post", currentSearchedKeyword));
            dispatch(fetchPost(true));
          }
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEARCH_BOARD_START", "게시판검색진입", {});
        } else {
          if (medicineSearchedKeyword !== currentSearchedKeyword) {
            dispatch(setSearchedKeyword("Medicine", currentSearchedKeyword));
            dispatch(fetchMedicine(currentSearchedKeyword));
          }
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEARCH_MEDICAL_START", "의약품검색진입", {});
        }

        dispatch(switchSearchType(tab));
      } catch (err) {
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "Search_Error", "검색통합에러", {
          page: "Search",
          where: "SearchTabBar/handleClickTab",
          errorMessage: err,
        });
      }
    },
    [postSearchedKeyword, medicineSearchedKeyword, currentSearchedKeyword]
  );

  return (
    <TabBar
      size="Large"
      type="Underlined"
      items={[
        {
          active: searchType === "Post",
          badge: false,
          text: "게시글 검색",
          onClick: handleClickTab("Post"),
        },
        {
          active: searchType === "Medicine",
          badge: false,
          text: "의약품 검색",
          onClick: handleClickTab("Medicine"),
        },
      ]}
      scrollable
      sameWidth
    />
  );
};

export default SearchTabBar;
