import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";
import { ButtonSize, ButtonUsage, ButtonVariant } from ".";

interface ButtonWrapperProps {
  color: ButtonUsage;
  size: ButtonSize;
  variant: ButtonVariant;
  disabled?: boolean;
  disabledColor?: boolean;
  left?: boolean;
  right?: boolean;
  state?: boolean;
}

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  height: ${({ size }) => {
    switch (size) {
      case "Large":
        return "48px";
      case "Medium":
        return "40px";
      case "Small":
        return "32px";
      case "XSmall":
        return "16px";
      default:
        return;
    }
  }};
  background-color: ${({ color, variant, state }) => {
    if (state) {
      return COLOR_SYSTEM.get("Skyblue")[10];
    } else {
      if (variant === "Contained") {
        switch (color) {
          case "Primary":
            return COLOR_SYSTEM.get("Skyblue")[400];
          case "Secondary":
            return COLOR_SYSTEM.get("Gray")[900];
          case "Tertiary":
            return COLOR_SYSTEM.get("Gray")[400];
          case "Quaternary":
            return COLOR_SYSTEM.get("Gray")[200];
          case "Negative":
            return COLOR_SYSTEM.get("Red")[300];
          default:
            return;
        }
      } else if (variant === "Tinted") {
        switch (color) {
          case "Primary":
            return COLOR_SYSTEM.get("Skyblue")[10];
          case "Secondary":
            return COLOR_SYSTEM.get("Gray")[20];
          case "Tertiary":
          case "Quaternary":
            return COLOR_SYSTEM.get("Gray")[10];
          case "Negative":
            return COLOR_SYSTEM.get("Red")[10];
          default:
            return;
        }
      } else if (variant === "Outlined") {
        return "#ffffff";
      } else {
        return "transparent";
      }
    }
  }};

  // Font
  ${({ size }) => {
    switch (size) {
      case "Large":
        return typography.get("InputLarge");
      case "Medium":
        return typography.get("InputMedium");
      case "Small":
        return typography.get("InputSmall");
      case "XSmall":
        return typography.get("InputXSmall");
      default:
        return;
    }
  }};
  color: ${({ color, variant }) => {
    if (variant === "Contained") {
      return COLOR_SYSTEM.get("Gray")[0];
    } else {
      switch (color) {
        case "Primary":
          return COLOR_SYSTEM.get("Skyblue")[400];
        case "Secondary":
          return COLOR_SYSTEM.get("Gray")[900];
        case "Tertiary":
          return COLOR_SYSTEM.get("Gray")[400];
        case "Quaternary":
          return COLOR_SYSTEM.get("Gray")[200];
        case "Negative":
          return COLOR_SYSTEM.get("Red")[300];
        default:
          return;
      }
    }
  }};

  // Border
  border: ${({ color, variant, size }) => {
    if (variant === "Outlined") {
      switch (color) {
        case "Primary":
          return `${size === "XSmall" ? 0.5 : 1}px solid ${
            COLOR_SYSTEM.get("Skyblue")[300]
          }`;
        case "Secondary":
        case "Tertiary":
          return `${size === "XSmall" ? 0.5 : 1}px solid ${
            COLOR_SYSTEM.get("Gray")[50]
          }`;
        case "Quaternary":
          return `${size === "XSmall" ? 0.5 : 1}px solid ${
            COLOR_SYSTEM.get("Gray")[10]
          }`;
        case "Negative":
          return `${size === "XSmall" ? 0.5 : 1}px solid ${
            COLOR_SYSTEM.get("Red")[300]
          }`;
        default:
          return;
      }
    } else {
      return "none";
    }
  }};
  border-radius: ${({ size }) => {
    switch (size) {
      case "Large":
        return "10px";
      case "Medium":
        return "8px";
      case "Small":
        return "4px";
      case "XSmall":
        return "2px";
      default:
        return;
    }
  }};

  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ disabled, disabledColor }) => {
    if (disabled && disabledColor) return "0.3";
    return "1";
  }};
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .ripple {
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    animation: 1s ripple linear;
    transform: scale(0);
  }

  transform: translateZ(0);

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(8);
    }
  }
`;

export const IconButtonWrapper = styled(ButtonWrapper)`
  padding: ${({ size }) => {
    switch (size) {
      case "Large":
        return "10px";
      case "Medium":
        return "8px";
      case "Small":
        return "6px";
      case "XSmall":
        return "2px";
      default:
        return;
    }
  }};
`;

export const TextButtonWrapper = styled(ButtonWrapper)`
  padding: ${({ size }) => {
    switch (size) {
      case "Large":
        return "11.5px 16px";
      case "Medium":
        return "8.5px 12px";
      case "Small":
        return "3.5px 8px";
      case "XSmall":
        return "1.5px 4px";
      default:
        return;
    }
  }};
`;

export const IconWithTextButtonWrapper = styled(ButtonWrapper)`
  padding: ${({ size, left, right }) => {
    if (left) {
      switch (size) {
        case "Large":
          return "11.5px 16px 11.5px 10px";
        case "Medium":
          return "8.5px 12px 8.5px 8px";
        case "Small":
          return "3.5px 8px 3.5px 6px";
        case "XSmall":
          return "1.5px 4px 1.5px 2px";
        default:
          return;
      }
    } else if (right) {
      switch (size) {
        case "Large":
          return "11.5px 10px 11.5px 16px";
        case "Medium":
          return "8.5px 8px 8.5px 12px";
        case "Small":
          return "3.5px 6px 3.5px 8px";
        case "XSmall":
          return "1.5px 2px 1.5px 4px";
        default:
          return;
      }
    }
  }};

  gap: ${({ size }) => {
    switch (size) {
      case "Large":
        return "10px";
      case "Medium":
        return "8px";
      case "Small":
        return "6px";
      case "XSmall":
        return "2px";
      default:
        return;
    }
  }};
`;
