import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BoardSummaryGroup from "../../components/templates/BoardSummaryGroup";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { RootState, actions } from "../../store";
import { UIServiceType } from "../../store/ui/types";
import { LogLevel, log } from "../../utils/LogUtil";
import {
  BoardContent,
  BoardContentSummaryGroup,
} from "../../models/Model.Board";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { GlobalKey, getGlobal } from "../../utils/GlobalUtil";
import * as API from "../../API.json";
import BoardUtil from "../../utils/BoardUtil";
import { refreshBoards, updateContent } from "../../store/board/actions";
import { BoardType } from "../../store/board/types";
import AdsUtil from "../../utils/AdsUtil";

const windowAny: any = window;

const BoardSummaryGroupPage: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();
  const keyword = useMemo(
    () => queryString.parse(location.search).keyword as string,
    [location.search]
  );

  const pageIndex = useMemo(() => {
    const qs = queryString.parse(location.search);
    return qs.page ? parseInt(qs.page as string) : -1;
  }, [location.search]);

  const me = useSelector((state: RootState) => state.user.me);
  const summaries = useSelector((state: RootState) => state.board.summaries);
  const freeSummaryCount = useSelector(
    (state: RootState) => state.user.freeSummaryCount
  );
  const backKeyControl = useSelector(
    (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
  );
  const summaryGroup: BoardContentSummaryGroup = useSelector(
    (state: RootState) => state.board.summaryGroups[keyword]
  );
  const summaryGroups: BoardContentSummaryGroup = useSelector(
    (state: RootState) => state.board.summaryGroups
  );
  const currentSummary = useMemo(() => {
    if (
      pageIndex === -1 ||
      !summaries ||
      !summaryGroup ||
      !summaryGroup.summaries ||
      !summaryGroup.summaries[pageIndex]
    )
      return null;
    return summaries[summaryGroup.summaries[pageIndex].id];
  }, [pageIndex]);

  const currentContent: BoardContent = useSelector(
    (state: RootState) =>
      state.board.contents[currentSummary ? currentSummary.id : 0]
  );

  // const [pageIndex, setPageIndex] = useState<number>(-1);
  useEffect(() => {
    if (backKeyControl)
      backKeyControl.setListener(() => {
        log(LogLevel.UI_LIFECYCLE, "BoardSummaryGroupPage:back key callback");
        goBack();
      });
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardSummaryGroupPage:useEffect []",
      keyword,
      summaryGroup
    );
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_START",
      "요약모아보기_진입",
      {
        keyword: keyword,
      }
    );
  }, []);

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardSummaryGroupPage:useEffect [keyword, summaryGroup] load group",
      keyword,
      summaryGroup
    );

    dispatch(actions.board.getSummaryGroup(keyword));
    // if (
    //   summaryGroup &&
    //   summaryGroup.summaries &&
    //   summaryGroup.summaries &&
    //   summaryGroup.summaries.length > 0
    // ) {
    //   for (let i = 0; i < summaryGroup.summaries.length; i++) {
    //     let summary = summaries[summaryGroup.summaries[i].id];
    //     if (summary && summary.keyword && summary.keyword.length > 0) {
    //       for (let j = 0; j < summary.keyword.length; j++) {
    //         dispatch(actions.board.getSummaryGroupCount(summary.keyword[j]));
    //       }
    //     }
    //   }
    // }
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_START",
      "요약모아보기_진입",
      {
        keyword: keyword,
      }
    );
  }, [keyword, summaryGroup]);

  useEffect(() => {
    if (
      summaryGroup &&
      summaryGroup.summaries &&
      summaryGroup.summaries.length > 0 &&
      pageIndex >= 0 &&
      summaryGroup.summaries[pageIndex] &&
      summaries &&
      summaries[summaryGroup.summaries[pageIndex].id]
    ) {
      let summary = summaries[summaryGroup.summaries[pageIndex].id];
      if (summary && summary.keyword && summary.keyword.length > 0) {
        for (let j = 0; j < summary.keyword.length; j++) {
          dispatch(actions.board.getSummaryGroupCount(summary.keyword[j]));
        }
      }
      log(
        LogLevel.UI_LIFECYCLE,
        "BoardSummaryGroupPage:useEffect [pageIndex] ",
        keyword,
        summaryGroup,
        summaries
      );
    }
  }, [pageIndex]);

  const goBack = () => {
    history.goBack();
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_END",
      "요약모아보기_나가기",
      {
        keyword: keyword,
        pageIndex: pageIndex,
      }
    );
  };

  const onChangePage = (index: number) => {
    // 무료요약 차감
    if (!summaryGroup.summaries[index]) return;
    const summary = summaries[summaryGroup.summaries[index].id];
    if (summary && summary.state != "START" && !summary.viewed) {
      log(
        LogLevel.UI_LIFECYCLE,
        "BoardSummaryPopupPage:useEffect [summary.state != START && !summary.viewed && freeSummaryCount > 0]",
        summary,
        freeSummaryCount
      );
      if (!me.premiumType && freeSummaryCount > 0) {
        dispatch(actions.user.decreaseFreeSummaryCount());

        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "GPT_SUMMARY_FREE",
          "무료요약차감",
          {
            from: "모아보기",

            게시물id: summary.id,
          }
        );
      }

      if (me.premiumType || freeSummaryCount > 0) {
        dispatch(actions.board.setSummary({ id: summary.id, viewed: true }));
        fetchAPI(
          API.ACTION_SUMMARY,
          summary.id,
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
      }
    }

    // 읽은 페이지 저장
    if (index >= 0) {
      fetchAPI(
        API.ACTION_SUMMARY_GROUP,
        "",
        null,
        {
          keyword: summaryGroup.keyword,
          value: index,
          type: "view",
        },
        getGlobal(GlobalKey.TOKEN)
      );

      dispatch(
        actions.board.setSummaryGroup({
          keyword: summaryGroup.keyword,
          lastViewed: index,
        })
      );
    }

    let qs = queryString.parse(location.search);
    qs["page"] = index.toString();
    // setPageIndex(index);

    history.replace(location.pathname + "?" + queryString.stringify(qs));
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_MOVE",
      "요약모아보기_페이지이동",
      {
        keyword: keyword,
        게시물id: summaryGroup.summaries[index].id,
        pageIndex: index,
      }
    );
  };

  const onClickReference = (focus: {
    contentId?: number;
    commentId?: number;
  }) => {
    const summary = summaries[summaryGroup.summaries[pageIndex].id];
    let url = "/boards/view?id=" + summary.contentId;
    if (focus) {
      if (focus.commentId) {
        url += `&fcmt=${focus.commentId}`;
      } else if (focus.contentId) {
        url += `&fcnt=${focus.contentId}`;
      }
    }
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_REFERENCE",
      "요약모아보기_원문보기",
      {
        keyword: keyword,
        게시물id: summary.contentId,
        url: url,
      }
    );
    history.push(url);
  };

  const onReport = (message: string) => {
    const summary = summaries[summaryGroup.summaries[pageIndex].id];
    fetchAPI(
      API.BOARDS_POST_SUMMARY_REPORT,
      "",
      null,
      { memo: message, contentId: summary.id },
      getGlobal(GlobalKey.TOKEN)
    );

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_REFERENCE",
      "요약모아보기_오류보고",
      {
        keyword: keyword,
        게시물id: summary.id,
        memo: message,
      }
    );
  };

  const onScrap = async () => {
    if (!currentSummary) return;
    let scrapped = false;
    let scrapCnt = 0;
    if (currentContent) {
      scrapped = currentContent.scrapped;
      scrapCnt = currentContent.scrapCnt;
    }

    log(LogLevel.NONE, "BoardSummaryPopupPage: onScrap", !scrapped);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    if (scrapped) {
      result = await fetchAPI(
        API.ACTION_CANCEL_SCRAP,
        currentSummary.id,
        null,
        null,
        token
      );
      scrapCnt--;
    } else {
      result = await fetchAPI(
        API.ACTION_SCRAP,
        currentSummary.id,
        null,
        null,
        token
      );
      scrapCnt++;
      // AMPLITUDE
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "GPT_SUMMARY_GROUP_SCRAP",
        "요약모아보기_스크랩",
        {
          게시물id: currentSummary.id,
        }
      );
    }
    if (result && !result.error) {
      let newContent = {
        id: currentSummary.id,
        scrapCnt: scrapCnt,
        scrapped: !scrapped,
      };

      //console.log("updateContent main", this.content);
      dispatch(updateContent(newContent));
      dispatch(refreshBoards(BoardType.MY_SCRAP));
    }
  };

  const unlock = () => {
    history.push("/premium/subscribe", {
      from: location.pathname + location.search,
    });
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_DONATION",
      "요약모아보기_후원페이지이동",
      {
        게시물id: currentSummary.id,
      }
    );
  };

  const onClickKeyword = (keyword: string) => {
    // setPageIndex(-1);
    history.push("/boards/summary?keyword=" + keyword);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_GROUP_KEYWORD",
      "요약모아보기_키워드선택",
      {
        keyword: keyword,
        게시물id: currentSummary.id,
      }
    );
  };

  const onClickAdsReward = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "요약모아보기_광고",
      {
        type: "reward",
        게시물id: summaryGroup.summaries[pageIndex].id,
      }
    );

    const summary = summaries[summaryGroup.summaries[pageIndex].id];

    AdsUtil.show(AdsUtil.TYPE_REWARD, {
      name: "요약보기",
      onRewarded: () => {},
    });
    fetchAPI(
      API.ACTION_SUMMARY,
      summaryGroup.summaries[pageIndex].id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    dispatch(
      actions.board.setSummary({
        id: summaryGroup.summaries[pageIndex].id,
        viewed: true,
      })
    );
  };

  log(
    LogLevel.UI_LIFECYCLE,
    "BoardSummaryGroupPage:render [index] ",
    pageIndex
  );

  return (
    <BoardSummaryGroup
      pageIndex={pageIndex}
      summaryGroup={summaryGroup}
      summaries={summaries}
      summary={currentSummary}
      onChangePage={onChangePage}
      onClickReference={onClickReference}
      onClickAdsReward={onClickAdsReward}
      onGoBack={goBack}
      onReport={onReport}
      onScrap={onScrap}
      me={me}
      freeSummaryCount={freeSummaryCount}
      onClickUnlock={unlock}
      onClickKeyword={onClickKeyword}
      summaryGroups={summaryGroups}
      scrapped={currentContent && currentContent.scrapped}
    />
  );
};

export default withRouter(BoardSummaryGroupPage);
