import React from 'react'
import styled, { StyledComponent } from 'styled-components'
import { OrderInfo, OrderStatus } from '../../models/Model.Sale'
import { getDateStringFromToday } from '../../utils/TimeUtil'
import Icon from '../atom/Icon'
import Text from '../atom/Text'
import TextRectangleButton from '../cell/TextRectangleButton'
import SaleOrderItemBody from './SaleOrderItemBody'
import COLOR from './../_design/color'

const Wrapper: StyledComponent<'div', React.FC> = styled.div`
  background-color: #fff;
  margin-bottom: 40px;
  .sale-order-list-item-header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  .sale-order-list-item-body-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .sale-order-list-item-footer-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .sale-order-list-item-footer-button-margin{
      width: 12px;
      min-width: 12px;
    }

    .sale-order-list-item-footer-2button{
      width: calc((100% - 12px) / 2);
    }

    .sale-order-list-item-footer-3button{
      width: calc((100% - 24px) / 3);
    }
  }
`

type Props = {
  order:OrderInfo
  onClickDetail?: (order:OrderInfo) => void
  onClickButtonCancel?: (order:OrderInfo) => void
  onClickButtonReturn?: (order:OrderInfo) => void
  onClickButtonInquiry?: (order:OrderInfo) => void
  onClickButtonTracking?: (order:OrderInfo) => void
}

const SaleOrderListItem: React.FC<Props> = ({
  order,
  onClickDetail,
  onClickButtonCancel,
  onClickButtonReturn,
  onClickButtonInquiry,
  onClickButtonTracking
}) => {
  let footer;
  
  switch (order.status){
    case OrderStatus.PAID : 
    case OrderStatus.ORDERED : 
    case OrderStatus.PREPARING_PRODUCT : 
    {
      footer = (
        <div className="sale-order-list-item-footer-container">
          <div className="sale-order-list-item-footer-2button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonCancel) onClickButtonCancel(order)}}>
              주문취소
            </TextRectangleButton>
          </div>
          <div className="sale-order-list-item-footer-button-margin"/>
          <div className="sale-order-list-item-footer-2button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonInquiry) onClickButtonInquiry(order)}}>
              문의하기
            </TextRectangleButton>
          </div>
        </div>
      )
      break;
    }
    case OrderStatus.PREPARING_DELIVER : 
    {
      footer = (
        <div className="sale-order-list-item-footer-container">
          <div className="sale-order-list-item-footer-2button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonReturn) onClickButtonReturn(order)}}>
              반품신청
            </TextRectangleButton>
          </div>
          <div className="sale-order-list-item-footer-button-margin"/>
          <div className="sale-order-list-item-footer-2button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonInquiry) onClickButtonInquiry(order)}}>
              문의하기
            </TextRectangleButton>
          </div>
        </div>
      )
      break;
    }
    case OrderStatus.DELIVERING : 
    case OrderStatus.DELIVERED : 
    {
      footer = (
        <div className="sale-order-list-item-footer-container">
          <div className="sale-order-list-item-footer-3button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonReturn) onClickButtonReturn(order)}}>
              반품신청
            </TextRectangleButton>
          </div>
          <div className="sale-order-list-item-footer-button-margin"/>
          <div className="sale-order-list-item-footer-3button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonInquiry) onClickButtonInquiry(order)}}>
              문의하기
            </TextRectangleButton>
          </div>
          <div className="sale-order-list-item-footer-button-margin"/>
          <div className="sale-order-list-item-footer-3button">
            <TextRectangleButton level="2" size="L" onClick={() => {if(onClickButtonTracking) onClickButtonTracking(order)}}>
              배송조회
            </TextRectangleButton>
          </div>
        </div>
      )
      break;
    }
    case OrderStatus.CANCELED : 
    case OrderStatus.RETURNING : 
    case OrderStatus.RETURN : 
    {
      footer = (
        <div className="sale-order-list-item-footer-container">
          <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonInquiry) onClickButtonInquiry(order)}}>
            문의하기
          </TextRectangleButton>
        </div>
      )
      break;
    }

  }

  return (
    <Wrapper>
      <div className="sale-order-list-item-header-container" onClick={() => {if(onClickDetail) onClickDetail(order)}}>
        <div className="common-flex-grow">
          <Text type="ButtonM" color="Black">
            {getDateStringFromToday({date:order.orderedAt, separator:"."})}
          </Text>
        </div>
        <Icon width="24" height="24" name="CaretRight" fill={COLOR["Gray3"]}/>
      </div>
      <div className="sale-order-list-item-body-container" onClick={() => {if(onClickDetail) onClickDetail(order)}}>
        <SaleOrderItemBody order={order}/>
      </div>
      {footer}
    </Wrapper>
  )
}

export default SaleOrderListItem
