import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./BoardManage.css";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import BoardContentManagePopup from "./component/BoardContentManagePopup";
import BoardContentEdit from "./component/BoardContentEdit";
import { setGlobal, GlobalKey, getGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import { BoardContent } from "../models/Model.Board";
import { fetchAPI } from "../utils/APIUtil";
import Truncate from "react-truncate";
const queryString = require("query-string");

const API = {
  GET_LIST: {
    method: "GET",
    path: "/admin/board/contents",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  search: string;
  category: number;
  contents: BoardContent[];
  selected: BoardContent;
};

class BoardManage extends Component<Props, State> {
  popup = null;

  constructor(props: Props) {
    super(props);
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardManage:constructor",
      this.props.location.search
    );
    let search = "";
    let qs = queryString.parse(this.props.location.search);
    if (qs.id) search = qs.id;

    this.state = {
      search,
      category: 1,
      contents: [],
      selected: null,
    };
    document.title = "게시글 관리";
  }
  componentDidMount() {
    this.fetchData();
  }

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({ search: keywords });
    this.fetchData();
  };

  onCategoryChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let category: number = parseInt(e.detail.value);
    this.setState({ category: category });
    this.fetchData();
  };

  onSelect = (content: BoardContent) => {
    log(LogLevel.UI_EVENT, "onSelect", content);
    var x = document.getElementById("admin-sub-conainer");
    this.setState({ selected: content });
    log(LogLevel.UI_DATA_LOAD, "onSelect", x, x.offsetParent);
    if (x.offsetParent === null) {
      this.popup.show(content);
    }
  };

  fetchData = async () => {
    let result = await fetchAPI(
      API.GET_LIST,
      "",
      {
        start: 0,
        count: 50,
        board: this.state.category,
        query: this.state.search,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    console.log(result);
    if (result && !result.error) {
      this.setState({ contents: result.data ? result.data : [] });
      if (result.data && result.data.length == 1) this.onSelect(result.data[0]);
    } else {
      this.setState({ contents: [] });
    }
  };

  fetchDataMore = async () => {
    let result = await fetchAPI(
      API.GET_LIST,
      "",
      {
        start: this.state.contents.length,
        count: 50,
        board: this.state.category,
        query: this.state.search,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    console.log(result);
    if (result && !result.error && result.data) {
      this.setState({ contents: [...this.state.contents, ...result.data] });
    } else {
      this.setState({ contents: this.state.contents });
    }
  };

  clearSearch = () => {
    this.setState({ search: "" });
  };

  onChange = () => {
    this.fetchData();
  };

  onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({ selected: null });
    this.fetchData();
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onCancel", this.state.selected);
    this.setState({ selected: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "Dashboard.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">게시글 관리 / 키워드 추출</IonText>
        </IonHeader>

        <IonContent>
          <BoardContentManagePopup
            onDone={this.onChange}
            ref={(ref) => {
              this.popup = ref;
            }}
          />

          <div className="admin-main-container">
            <div className="admin-container">
              <div className="admin-board-search-container">
                <IonInput
                  class="admin-board-search-input"
                  placeholder="검색"
                  value={this.state.search}
                  onIonChange={this.onSearchChange}
                />
                <IonButton
                  fill="clear"
                  color="primary"
                  onClick={this.clearSearch}
                >
                  <IonIcon name="close" />
                </IonButton>
                <IonSelect
                  class="admin-board-search-level"
                  value={this.state.category.toString()}
                  onIonChange={this.onCategoryChange}
                >
                  <IonSelectOption value={"1"}>Q&A</IonSelectOption>
                  <IonSelectOption value={"2"}>자유게시판</IonSelectOption>
                  <IonSelectOption value={"3"}>공지사항</IonSelectOption>
                  <IonSelectOption value={"4"}>FAQ</IonSelectOption>
                  <IonSelectOption value={"5"}>버그게시판</IonSelectOption>
                  <IonSelectOption value={"6"}>Test</IonSelectOption>
                  <IonSelectOption value={"7"}>제품</IonSelectOption>
                  <IonSelectOption value={"8"}>이슈토론</IonSelectOption>
                </IonSelect>
              </div>
              <div className="admin-board-list-container">
                <IonList>
                  {this.state.contents.map(
                    (item: BoardContent, index: number) => (
                      <div
                        key={index.toString()}
                        className="admin-board-list-item-container"
                        onClick={() => this.onSelect(item)}
                      >
                        <div
                          className={
                            "admin-row" +
                            (this.state.selected &&
                            item.id == this.state.selected.id
                              ? " common-color-highlight common-bold"
                              : "")
                          }
                        >
                          <div className="admin-grow">
                            {item.id} - {item.groupParent} - {item.groupId}{" "}
                            {item.deletedAt ? "[삭제]" : ""}
                          </div>
                          <IonIcon
                            name={
                              !item.managedAt ||
                              (item.modifiedAt &&
                                item.managedAt < item.modifiedAt)
                                ? "help"
                                : "checkmark"
                            }
                          />
                        </div>
                        <div>{item.subject} </div>
                        <Truncate lines={3} ellipsis="...">
                          {item.bodyText}
                        </Truncate>
                      </div>
                    )
                  )}
                </IonList>
                <IonButton expand="full" onClick={this.fetchDataMore}>
                  +
                </IonButton>
              </div>
            </div>
            <div id="admin-sub-conainer" className="admin-container">
              <BoardContentEdit
                content={this.state.selected}
                onDone={this.onDone}
                onCancel={this.onCancel}
              />
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardManage)
);
