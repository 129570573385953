import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import { RowDirectionButtonContainer, PopupBody } from "./style";

interface SettingEmptyErrorPopupProps extends PopupProps {
  icon?: React.ReactNode;
  description: React.ReactNode;
  leftButtonChildren: React.ReactNode;
  rightButtonChildren: React.ReactNode;
  onClickLeft: () => void;
  onClickRight: () => void;
}

const SettingEmptyErrorPopup: React.FC<SettingEmptyErrorPopupProps> = ({
  icon = false,
  description,
  leftButtonChildren,
  rightButtonChildren,
  onClose,
  onClickLeft,
  onClickRight,
}) => {
  return (
    <Popup onClose={onClose}>
      {icon}
      <PopupBody style={{ marginTop: icon ? "25px" : null }}>
        <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          {description}
        </Text>
      </PopupBody>
      <RowDirectionButtonContainer>
        <Button color="Secondary" size="Large" type="Text" variant="Tinted" onClick={onClickLeft}>
          {leftButtonChildren}
        </Button>
        <Button color="Primary" size="Large" type="Text" variant="Contained" onClick={onClickRight}>
          {rightButtonChildren}
        </Button>
      </RowDirectionButtonContainer>
    </Popup>
  );
};

export default SettingEmptyErrorPopup;
