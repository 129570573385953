import React, { Component } from "react";
import "./LinkMeta.css";
import { func } from "prop-types";
import { log, LogLevel } from "../utils/LogUtil";
import { RootState } from "../store";
import { connect } from "react-redux";
import { Reference } from "../models/Model.Board";
import { MY_ADDRESS } from "./../config.json";
import StringUtil from "../utils/StringUtil";
import emptyLink from "../assets/image/link_empty_image.jpg";
import arrow from "../assets/icon/arrow.png";
import ReactHtmlParser from "react-html-parser";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import styled from "styled-components";

var parse = require("url-parse");

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    reference?: Reference;
    link?: string;
    mediaType?: string;
    title?: string;
    description?: string;
    image?: string;
    onClick?: (url: string) => void;
  };

function youtubeParser(url) {
  var i,
    r,
    rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  r = url.match(rx);
  return r && r[1].length == 11 ? r[1] : false;
}

const BoardCardLinkWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  border-radius: 8px;
  overflow: hidden;

  .link__content {
    height: 70px;
    padding: 8px 12px;
    background-color: ${COLOR_SYSTEM.get("Gray")[10]};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & > img {
    width: 72px;
    height: 70px;
    object-fit: cover;
    object-position: center;
  }
`;

class LinkMeta extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onClick = (e) => {
    e.stopPropagation();
    if (this.props.onClick) this.props.onClick(this.props.link);
  };

  render() {
    log(LogLevel.NONE, "LinkMeta: render ", this.props);
    let link, title, description, image;

    if (!this.props.link && !this.props.image && !this.props.title && !this.props.description) return null;

    //if youtube
    if (this.props.link) {
      let youtubeId = youtubeParser(this.props.link);
      log(LogLevel.NONE, "LinkMeta: link ", this.props.link, youtubeId);
      if (youtubeId) {
        log(LogLevel.NONE, "LinkMeta: youtube:", youtubeId);
        const url = `https://www.youtube.com/embed/${youtubeId}`;
        return (
          <div className="linkmeta-youtube-container">
            <iframe className="linkmeta-youtube" src={url} />
          </div>
        );
      }
    }

    let imageElement = null;
    if (this.props.image) {
      let imageUrl = this.props.image;
      // console.log("!!!!!!!!!!!!", imageUrl)
      imageUrl = StringUtil.convertFilePath(imageUrl);

      if (imageUrl.startsWith("&&&")) {
        imageUrl = imageUrl.replace("&&&", MY_ADDRESS);
      }
      if (imageUrl.startsWith("./")) {
        imageUrl = imageUrl.substring(1);
      }
      if (imageUrl.startsWith("//")) {
        let parseUrl = parse(this.props.link);
        imageUrl = parseUrl.protocol + imageUrl;
      } else if (imageUrl.startsWith("/")) {
        let parseUrl = parse(this.props.link);

        imageUrl = parseUrl.protocol + (parseUrl.slashes ? "//" : "") + parseUrl.host + imageUrl;
      }

      if (imageUrl) {
        // let index = imageUrl.indexOf("?")
        // if(index > 0)
        //   imageUrl = imageUrl.substring(0, index);
        imageElement = <img className="linkmeta-image" src={imageUrl} />;
      } else {
        imageElement = <img className="linkmeta-image" src={emptyLink} />;
      }
    } else {
      imageElement = <img className="linkmeta-image" src={emptyLink} />;
    }

    let urlElement = null;
    if (this.props.link) {
      console.log(this.props.link);
      let link = this.props.link;

      if (link.startsWith("&&&")) {
        link = link.replace("&&&", MY_ADDRESS);
      }
      const url = new URL(link);

      urlElement = (
        // <div className="common-flex-row common-flex-align-center" style={{ marginBottom: "6px" }}>
        // <div className="linkmeta-link-icon-container">
        //     <img className="linkmeta-link-icon" src={arrow}/>
        //   </div>
        // <span className="linkmeta-url">
        // </span>
        // </div>

        <Text textType="InputXSmall" color={COLOR_SYSTEM.get("Blue")[400]} numberOfLines={1}>
          {url.protocol ? url.protocol + "//" : ""}
          {url.hostname}
        </Text>
      );
    }

    let titleElement = null;
    if (this.props.title) {
      // titleElement = <span className="linkmeta-title">{ReactHtmlParser(this.props.title, {})}</span>;
      titleElement = (
        <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[700]} numberOfLines={1}>
          {ReactHtmlParser(this.props.title, {})}
        </Text>
      );
    }

    let descriptionElement = null;
    if (this.props.description) {
      // descriptionElement = <span className="linkmeta-desc">{this.props.description.trim().substring(0, 60)}...</span>;
      descriptionElement = (
        <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[700]} numberOfLines={1}>
          {this.props.description.trim()}
        </Text>
      );
    }

    let rvalue = (
      // <div className="linkmeta-container" onClick={this.onClick}>
      <BoardCardLinkWrapper onClick={this.onClick}>
        <div className="link__content">
          {urlElement}
          {titleElement}
          {descriptionElement}
        </div>
        {imageElement}
      </BoardCardLinkWrapper>
    );
    return rvalue;
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  filePath: state.board.filePath,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(LinkMeta);
