import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "./store";
import ProgressPopup from "./components/popup/ProgressPopup";
import KeywordPopup from "./components/popup/KeywordPopup";
import { KeywordType } from "./models/Model.Medicine";
import { UIPopupType, UIServiceType } from "./store/ui/types";
import ImageViewerPopup from "./components/popup/ImageViewerPopup";
import DocumentViewerPopup from "./components/DocumentViewerPopup";
import ConfirmPopup from "./components/popup/ConfirmPopup";
import ToastPopup from "./components/ToastPopup";
import PopupManager from "./components/PopupManager";
import BackKeyControlService from "./components/BackKeyControlService";
import InformationPopup from "./components/popup/InformationPopup";
import SelectPopup from "./components/popup/SelectPopup";

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

class AppUIServices extends Component<Props> {
  constructor(prop) {
    super(prop);
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <BackKeyControlService
          ref={(ref) => {
            this.props.setService(ref, UIServiceType.BACK_CONTROLLER);
          }}
        />
        <ProgressPopup
          ref={(ref) => {
            this.props.setPopup(ref, UIPopupType.WAITING_POPUP);
          }}
        />
        <KeywordPopup
          ref={(ref) => {
            this.props.setPopup(ref, UIPopupType.KEYWORD_POPUP);
          }}
        />
        <ImageViewerPopup
          ref={(ref) => {
            this.props.setPopup(ref, UIPopupType.IMAGE_VIEWER_POPUP);
          }}
        />
        <ConfirmPopup
          ref={(ref) => {
            this.props.setPopup(ref, UIPopupType.CONFIRM_POPUP);
          }}
        />
        <ToastPopup
          ref={(ref) => {
            this.props.setPopup(ref, UIPopupType.TOAST_POPUP);
          }}
        />
        <InformationPopup
          ref={(ref) => {
            this.props.setPopup(ref, UIPopupType.INFORMATION_POPUP);
          }}
        />
        <SelectPopup
          ref={(ref) => {
            this.props.setPopup(ref, UIPopupType.SELECT_POPUP);
          }}
        />
        <PopupManager />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  medicine: state.medicine,
});

const mapDispatchToProps = {
  setPopup: (popup, type) => actions.ui.setPopup(popup, type),
  setService: (service, type) => actions.ui.setService(service, type),
  fetchKeyword: (type: KeywordType, id: number) =>
    actions.medicine.getMedicine(type, id),
};
export default connect(mapStateToProps, mapDispatchToProps)(AppUIServices);
