import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton, IonButtons, IonHeader, IonIcon, IonLabel, IonSpinner, IonToolbar } from '@ionic/react';
import {fetchAPI} from "../../utils/APIUtil"
import * as API from '../../API.json';
import {UserInfo} from '../../models/Model.User'
import processImg from './../../assets/image/jobpost_process_guide.jpg'
import {log, LogLevel} from '../../utils/LogUtil'
import {getGlobal, GlobalKey, setGlobal} from '../../utils/GlobalUtil'
import "./PollSimplePage.css"
import "./Common.css"
import ReactHtmlParser from 'react-html-parser'
import { PollInfo, PollResponseItemInfo, PollItemInfo } from '../../models/Model.Poll';
import Textarea from 'react-textarea-autosize';

// type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
type Props = {
    key?: string,
    item: PollItemInfo,
    response?: PollResponseItemInfo,
    index: number,
    onReponse?: (pollItemindex: number, response: PollResponseItemInfo) => void,
}

type State = {
    text: string;
}

class PollSimplePageItemText extends Component<Props, State> {
    input = null;
    
    constructor(props: Props) {
        super(props);
        log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemText:constructor",  props);
        let text = "";
        if(this.props.response)
            text = this.props.response.value;
        this.state = {
            text
        };
    }

    shouldComponentUpdate(nextProv, nextState){
        if(this.props.item.id != nextProv.item.id || this.state.text != nextState.text)
            return true;

        return false;
    }

    componentDidMount() { 
    }

    onTextChange = (e) => {
        this.setState({text:e.target.value})
        if(this.props.onReponse)
            this.props.onReponse(this.props.index, {
                pollItemId: this.props.item.id,
                value: e.target.value,
            })
    }

    onFocus = (e, input) => {
        log(LogLevel.UI_ACTION, "PollSimplePageItemText onFocus: ", input);
        if(input)
            input.focus();
        e.stopPropagation();
    }

    onKeyPress = (e) => {
        // log(LogLevel.UI_ACTION, "PollSimplePageItemText:onKeyPress", e.keyCode, e.shiftKey)
        if(!this.props.item.inputMultiline && e.keyCode == 13){
            e.preventDefault();
        }
    }

    render() {
        log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemText render: ", this.props.item, this.props.response);

        let descriptionElement;
        if(this.props.item.description)
            descriptionElement = (
                <div className="poll-simple-page-item-text poll-simple-page-item-margin">{ReactHtmlParser(this.props.item.description,{})}</div>
            );
        return (
            <div className="poll-simple-page-item-container" onClick={(e)=>{this.onFocus(e, this.input)}}>
                <div className="poll-simple-page-item-question">{this.props.item.question}</div>
                {descriptionElement}
                <div className="poll-simple-page-input-container poll-simple-page-item-margin" >
                    <Textarea inputRef={(ref) => {this.input = ref}} minRows={1} maxRows={5} className="poll-simple-page-input" onChange={this.onTextChange} value={this.state.text} onClick={(e)=>{e.stopPropagation();}}  onKeyDown={this.onKeyPress} />
                    <span className={this.state.text?"common-hidden":"poll-simple-page-input-placeholder"} >{this.props.item.inputPlaceholder}</span>
                </div>
            </div>
        );

        return null;
    }
}


// const mapStateToProps = (state: RootState) => ({
//   me: state.user.me,
//   informationPopup : state.ui.popups[UIPopupType.INFORMATION_POPUP],
// });

// const mapDispatchToProps = {

// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollSimplePageItemText));
export default PollSimplePageItemText;