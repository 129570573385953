import { getOS } from "./DeviceUtil";

export function sendSMSMessage(
  phone: any /*string[]|string*/,
  message: string = ""
) {
  let os = getOS();

  if (!phone) return;

  let isArray = Array.isArray(phone);

  if (isArray && !phone.length) return;

  if (!isArray) {
    if (os == "android" || os == "windows") {
      window.open(
        `sms:${phone}?body=${encodeURIComponent(message)}`,
        "_system"
      );
      // window.open(`com.onjourney.pharmacycafe://open?type=jobpost`,"_system");
      // window.open(`sms:${item.phone},01031507929?body=${encodeURIComponent(`${name} 안녕하세요. ${pname}`)}`,"_system");
    } else if (os == "ios" || os == "macos") {
      window.open(
        `sms:${phone}&body=${encodeURIComponent(message)}`,
        "_system"
      );
      // window.open(`com.onjourney.pharmacycafe://open?type=jobpost`,"_system");
    }
  } else if (phone.length == 1) {
    if (os == "android" || os == "windows") {
      window.open(
        `sms:${phone[0]}?body=${encodeURIComponent(message)}`,
        "_system"
      );
      // window.open(`com.onjourney.pharmacycafe://open?type=jobpost`,"_system");
      // window.open(`sms:${item.phone},01031507929?body=${encodeURIComponent(`${name} 안녕하세요. ${pname}`)}`,"_system");
    } else if (os == "ios" || os == "macos") {
      window.open(
        `sms:${phone[0]}&body=${encodeURIComponent(message)}`,
        "_system"
      );
      // window.open(`com.onjourney.pharmacycafe://open?type=jobpost`,"_system");
    }
  } else {
    let phones = phone.map((item) => item.phone).join(",");
    if (os == "android" || os == "windows") {
      window.open(
        `sms:${phones}?body=${encodeURIComponent(message)}`,
        "_system"
      );
      // window.open(`sms:${item.phone},01031507929?body=${encodeURIComponent(`${name} 안녕하세요. ${pname}`)}`,"_system");
    } else if (os == "ios" || os == "macos") {
      window.open(
        `sms:open?addresses=${phones}&body=${encodeURIComponent(message)}`,
        "_system"
      );
    }
  }
}
export function callPhone(phone: string) {
  let os = getOS();

  if (!phone) return;

  if (os == "android" || os == "ios" || os == "macos") {
    window.open(`tel:${phone}`, "_system");
  }
}

export function redireactApp(path, installIfNotExist: boolean = true) {
  exeDeepLink(path);
  if (installIfNotExist) checkInstallApp();
}

function exeDeepLink(path) {
  let os = getOS();

  if (os == "android") {
    location.href = "www.ymyd.co.kr://" + path;
  } else if (os == "ios" || os == "macos") {
    location.href = "kakao845dd8ac648986a8631ecadc339a7acf://" + path;
  }
}

const documentAny: any = document;
function redirectStore() {
  const ua = navigator.userAgent.toLowerCase();

  // if (window.confirm("스토어로 이동하시겠습니까?")) {
  location.href =
    ua.indexOf("android") > -1
      ? "https://play.google.com/store/apps/details?id=com.onjourney.pharmacycafe"
      : "https://apps.apple.com/us/app/%EC%95%BD%EB%AC%B8%EC%95%BD%EB%8B%B5/id1461728432";
  // }
}

function checkInstallApp() {
  function clearTimers() {
    clearInterval(check);
    clearTimeout(timer);
  }

  function isHideWeb() {
    if (documentAny.webkitHidden || documentAny.hidden) {
      clearTimers();
    }
  }
  const check = setInterval(isHideWeb, 2000);

  const timer = setTimeout(function () {
    redirectStore();
  }, 2000);
}
