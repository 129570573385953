import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import UserSelect from "./component/user/UserSelect";
import { timingSafeEqual } from "crypto";
import ProductSelect from "./component/sale/ProductSelect";
import { OrderInfo, ProductInfo } from "../models/Model.Sale";
import OrderList from "./component/sale/OrderList";
import OrderEdit from "./component/sale/OrderEdit";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selectingProduct: boolean;
  selectedProduct: any;
  selectingUser: boolean;
  selectedUser: UserInfo;
  selectedOrder: any;
};

class SaleOrderManage extends Component<Props, State> {
  projectList = null;
  orderList = null;

  state = {
    selectingProduct: false,
    selectingUser: false,
    selectedProduct: null,
    selectedUser: null,
    selectedOrder: null,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  onSelectProduct = (product: ProductInfo) => {
    log(LogLevel.UI_EVENT, "SaleOrderManage:onSelectProduct", product);
    this.setState({
      selectedProduct: product,
      selectingProduct: false,
      selectingUser: false,
    });
  };

  onSelectOrder = (order: any) => {
    log(LogLevel.UI_EVENT, "SaleOrderManage:onSelectOrder", order);
    this.setState({
      selectedOrder: order,
      selectingProduct: false,
      selectingUser: false,
    });
  };

  onSelectUser = (user: UserInfo) => {
    this.setState({
      selectedUser: user,
      selectingProduct: false,
      selectingUser: false,
    });
  };

  onOrderEdited = (order?: OrderInfo) => {
    log(
      LogLevel.UI_EVENT,
      "SaleOrderManage:onOrderEdited",
      this.orderList,
      order
    );

    if (this.orderList) this.orderList.refresh(order);
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "SaleOrderManage.render",
      this.props,
      this.state
    );

    let selectList;
    if (this.state.selectingProduct) {
      selectList = (
        <div id="admin-sub-conainer" className="admin-container">
          <ProductSelect
            onSelect={this.onSelectProduct}
            ref={(ref) => {
              this.projectList = ref;
            }}
            onClose={() => this.setState({ selectingProduct: false })}
          />
        </div>
      );
    } else if (this.state.selectingUser) {
      selectList = (
        <div id="admin-sub-conainer" className="admin-container">
          <UserSelect
            level={UserLevel.NORMAL_USER}
            onDone={this.onSelectUser}
            onClose={() => this.setState({ selectingUser: false })}
          />
        </div>
      );
    } else if (this.state.selectedOrder) {
      selectList = (
        <div id="admin-sub-conainer" className="admin-container">
          <OrderEdit
            onDone={this.onOrderEdited}
            order={this.state.selectedOrder}
            onClose={() => this.setState({ selectedOrder: null })}
          />
        </div>
      );
    }
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonText slot="start">
            구매 통계
            {this.state.selectedUser && (
              <span
                className="common-color-highlight"
                onClick={() => this.setState({ selectedUser: null })}
              >
                {this.state.selectedUser.name}({this.state.selectedUser.id})
              </span>
            )}
            {this.state.selectedProduct && (
              <span
                className="common-color-caution"
                onClick={() => this.setState({ selectedProduct: null })}
              >
                {this.state.selectedProduct.name}(
                {this.state.selectedProduct.id})
              </span>
            )}
          </IonText>
          <IonButtons slot="end">
            {/* <IonButton onClick={() => this.setState({selectingProduct:!this.state.selectingProduct, selectingUser:false})}>물품선택</IonButton> */}
            <IonButton
              onClick={() =>
                this.setState({
                  selectingUser: !this.state.selectingUser,
                  selectingProduct: false,
                })
              }
            >
              회원선택
            </IonButton>
            <IonButton
              onClick={() =>
                this.setState({
                  selectingUser: false,
                  selectingProduct: !this.state.selectingProduct,
                })
              }
            >
              제품선택
            </IonButton>
          </IonButtons>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <OrderList
              ref={(ref) => {
                this.orderList = ref;
              }}
              onSelect={this.onSelectOrder}
              selectedProduct={this.state.selectedProduct}
              selectedUser={this.state.selectedUser}
            />
            {selectList}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SaleOrderManage)
);
