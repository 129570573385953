import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonCheckbox, IonToggle, IonItem } from '@ionic/react';
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'

import {setGlobal, GlobalKey, getGlobal} from '../utils/GlobalUtil'
import { BoardType } from '../store/board/types';
import { UserLevel, UserWorkType, UserInfo, UserWorkTypeName, UserLevelName } from '../models/Model.User';
import { fetchAPI } from '../utils/APIUtil';
import * as API from './../API.json';
import {MY_ADDRESS} from './../config.json';
import UserSelect from './component/user/UserSelect';
import BoardContentSelect from './component/BoardContentSelect';
import { BoardContent } from '../models/Model.Board';
import { any } from 'prop-types';
import { UIPopupType } from '../store/ui/types';
import CheckToggleButton from '../components/CheckToggleButton';
import ProfileImage from '../components/ProfileImage';
import ProfileSimple from '../components/ProfileSimple';

const AdminAPI = {        
  "ADD_INVITE" : {"method":"POST", "path":"/admin/user/action/invite/", "contentType":"application/json"},
  "REMOVE_INVITE" : {"method":"DELETE", "path":"/admin/user/action/invite/", "contentType":"application/json"},
  "GET_INVITEE" : {"method":"GET", "path":"/admin/user/invitee/", "contentType":"application/json"},
  "GET_RANK" : {"method":"GET", "path":"/admin/user/invite/rank", "contentType":"application/json"},
  "DOWNLOAD_RANK" : {"method":"GET", "path":"/admin/user/invite/download", "contentType":"application/json", "accept":"text/csv"},
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  totalCount: number;
  rank: UserInfo[];
  manual: boolean;
  manualUsers: UserInfo[];
  startDate: string;
  endDate: string;
  count: number;
}

class EventInviteRank extends Component<Props, State> {

  state = {
    totalCount: 0,
    rank: [],
    manual: false,
    manualUsers: [],
    startDate: "2019-10-01",
    endDate: "2019-12-01",
    count: 0,
  };

  constructor(props: Props) {
    super(props);

  }

  componentDidMount() {
    this.onFetch();
  }

  onFetch = async () => {
    let result = await fetchAPI(AdminAPI.GET_RANK, "", {dateStart:this.state.startDate, dateEnd: this.state.endDate, count: this.state.count}, null, getGlobal(GlobalKey.TOKEN));
    log(LogLevel.UI_DATA_LOAD, "EventInviteRank:onFetch:", result);
    if(result && !result.error){
      let totalCount = 0;
      let rank =[];
      if(result.total)
        totalCount = result.total;
      if(result.data)
        rank = result.data;
      this.setState({totalCount, rank})
    }else{
      this.setState({totalCount:0, rank:[]})
    }
  }

  download = (filename, text) => {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
  }

  onDownload = () => {
    fetchAPI(AdminAPI.DOWNLOAD_RANK, "", {startDate:this.state.startDate, endDate: this.state.endDate, count: this.state.count}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      log(LogLevel.UI_DATA_LOAD, "Admin:UserManage", result);

      if(result)
        this.download(`Invite-Event-${this.state.startDate}-${this.state.endDate}-${this.state.count}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`, result);
    })    
    
  }

  onUserAdded = (user: UserInfo) => {
    if(!this.state.manualUsers.includes(user) && this.state.manualUsers.length < 2){
      log(LogLevel.UI_ACTION, "EventInviteRank.onUserAdded", user) 
      this.setState({manualUsers:[...this.state.manualUsers, user]});
    }
  }
    
  onUserRemove = (index:number) => {
    log(LogLevel.UI_ACTION, "EventInviteRank.onUserRemove", index) 
    let manualUsers = [...this.state.manualUsers];
    manualUsers.splice(index, 1);
    this.setState({manualUsers});
  }

  addInvite = async () => {
    if(this.state.manualUsers.length <2)
      return;
    let result = await fetchAPI(AdminAPI.ADD_INVITE, (this.state.manualUsers[0].id.toString() + "/" + this.state.manualUsers[1].id.toString()), null, null, getGlobal(GlobalKey.TOKEN));
    log(LogLevel.UI_DATA_LOAD, "EventInviteRank:addInvite:", result);
    if(result && !result.error){
      this.onFetch();
    }
  }

  removeInvite = async() => {
    if(this.state.manualUsers.length <2)
      return;
    let result = await fetchAPI(AdminAPI.REMOVE_INVITE, (this.state.manualUsers[0].id.toString() + "/" + this.state.manualUsers[1].id.toString()), null, null, getGlobal(GlobalKey.TOKEN));
    log(LogLevel.UI_DATA_LOAD, "EventInviteRank:removeInvite:", result);
    if(result && !result.error){
      this.onFetch();
    }
  }

  onDetail = async (index: number) => {
    let rank:UserInfo[] = [...this.state.rank];
    if(rank[index].invitees){
      rank[index].invitees = null;
    }
    else{
      let result = await fetchAPI(AdminAPI.GET_INVITEE, rank[index].id.toString(), null, null, getGlobal(GlobalKey.TOKEN));
      log(LogLevel.UI_DATA_LOAD, "EventInviteRank:onDetail:", result);
      if(result && !result.error){
        rank[index].invitees = result.data;
      }
    }
    this.setState({rank});
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "EventInviteRank.render", this.props, this.state);

    let selection;
    if(this.state.manual == true)
      selection = (
        <div id="admin-sub-conainer" className="admin-container">
          <UserSelect onDone={this.onUserAdded}/>
        </div>          
      );

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                친구초대 이벤트 Ranking
            </IonText>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <div className="common-container-row common-flex-align-center">
                <IonCheckbox color="html-composer-checkbox" checked={this.state.manual} onIonChange={(e) => {this.setState({manual:e.detail.checked});}}/>
                {this.state.manualUsers.map((user:UserInfo, index) => 
                  <div key={index.toString()} className="common-container-row common-flex-align-center">
                    <IonItem class="common-flex-grow" key={index.toString()}>
                      <IonLabel>{user.id}.{user.name}</IonLabel>
                      <IonButton onClick={() => this.onUserRemove(index)}><IonIcon name="close"/></IonButton>
                      <IonLabel>{index?"":" invites "}</IonLabel>
                    </IonItem>
                  </div>
                )}
                <IonButton disabled={!(this.state.manual && this.state.manualUsers.length == 2)} onClick={this.addInvite}>추가</IonButton>
                <IonButton disabled={!(this.state.manual && this.state.manualUsers.length == 2)} onClick={this.removeInvite}>삭제</IonButton>
              </div>
              <div className="admin-item-container">
                <div className="board-new-item-title">기간</div>
                <IonInput class="board-composer-input" value={this.state.startDate} placeholder="시작일" onIonChange={(e) => {this.setState({startDate:e.detail.value})}}/>
                <div className="board-new-item-title">~</div>
                <IonInput class="board-composer-input" value={this.state.endDate} placeholder="종료일" onIonChange={(e) => {this.setState({endDate:e.detail.value})}}/>
              </div>
              <div className="admin-item-container">
                <div className="board-new-item-title">갯수</div>
                <IonInput class="board-composer-input" placeholder="0 = all" value={this.state.count.toString()} type="number" onIonChange={(e) => {this.setState({count:parseInt(e.detail.value)})}}/>
              </div>
              <div className="common-container-row">
                <IonButton color="primary" onClick={this.onFetch}>확인하기</IonButton> 
                <IonButton color="primary" onClick={this.onDownload}>다운로드</IonButton>
              </div>
              <div className="common-container-column">
                <div>
                    총 {this.state.totalCount} 분이 초대되었습니다.
                </div>
                {this.state.rank.map((user:UserInfo, index) => {
                  let detail;
                  if(user.invitees){
                    detail = (
                      <div style={{paddingLeft:"50px"}}>
                        {user.invitees.map((user2:UserInfo, index2) => 
                          <div key={(index2.toString())} className="common-container-row common-flex-align-center">
                            <div>{(index2+1)}. </div>
                            <ProfileImage className="user-admin-list-item-profile" profileUrl={user2.profileUrl}/>
                            <div className="user-admin-list-item-container common-flex-grow">
                              <div>{user2.id} / {user2.name} / {user2.nickname}</div>
                            </div>
                          </div>
                        )}                     
                      </div>
                    );
                  }
                  return (
                    <div key={index.toString()} className="common-container-column">
                      <div className="common-container-row common-flex-align-center">
                        <ProfileImage className="user-admin-list-item-profile" profileUrl={user.profileUrl}/>
                        <div className="user-admin-list-item-container common-flex-grow">
                          <div>{user.inviteCnt} Invites - {user.id} / {user.name} / {user.nickname} / {user.phone} </div>
                        </div>
                        <IonButton onClick={() => this.onDetail(index)}>상세 {(user.invitees?"취소":"보기")}</IonButton>
                      </div>
                      {detail}
                    </div>
                  );
                })}
              </div>
            </div>
            {selection}
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventInviteRank));