import React from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { format } from "date-fns";
import {
  IonLabel,
  IonButton,
  IonIcon,
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonAlert,
} from "@ionic/react";
import "./BoardContentListSimpleItem.css";
import qustionIcon from "./../assets/image/question.png";
import { getDateTimeString, getDateTimeStringShort } from "./../utils/TimeUtil";
import commentIcon from "./../assets/icon/comment.svg";
import viewIcon from "./../assets/icon/view.svg";
import scappedIcon from "./../assets/image/scrapped.png";
import unscappedIcon from "./../assets/image/unscrapped.png";
import { BoardContent } from "./../models/Model.Board";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import { BoardType } from "./../store/board/types";
import { getGlobal, GlobalKey } from "./../utils/GlobalUtil";
import Truncate from "react-truncate";
import { LogLevel, log } from "../utils/LogUtil";

type Props = RouteComponentProps & {
  key: string;
  content: BoardContent;
  index: number;
  onClick?: (id) => void;
};

class BoardContentListSimpleItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  navigateToView = () => {
    if (this.props.onClick) this.props.onClick(this.props.content.id);
    this.props.history.push(`/boards/view?id=${this.props.content.id}`);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.content &&
      (!this.props.content || this.props.content.id != nextProps.content.id)
    )
      return true;
    return false;
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardContentListSimpleItem:render");
    let posting: BoardContent = this.props.content;

    if (!posting || !posting.id) return null;
    let body = "";
    if (posting.subject.length > 0) body = posting.subject;
    else body = posting.bodyText;

    let category = posting.boardsCategoriesName;
    if (category === "자유게시판") category = "자유 게시판";
    else if (category === "QnA") category = "학술 Q&A";
    return (
      <div className="boardcontentlist-simpleitem-container">
        <div
          className={
            this.props.index % 2 == 0
              ? "boardcontentlist-simpleitem-inner"
              : "boardcontentlist-simpleitem-inner boardcontentlist-simpleitem-odd"
          }
          onClick={this.navigateToView}
        >
          <div className="boardcontentlist-simpleitem-category">{category}</div>
          <div className="boardcontentlist-simpleitem-body-container">
            <div className="boardcontentlist-simpleitem-body">
              <Truncate lines={2} ellipsis="...">
                {body}
              </Truncate>
            </div>
            <div className="boardcontentlist-simpleitem-info-container">
              {/* <div className="boardcontentlist-simpleitem-info-nickname">{posting.usersNickname}</div> */}
              <div className="boardcontentlist-simpleitem-info-date">
                {getDateTimeStringShort(posting.createdAt)}
              </div>
              <div className="boardcontentlist-simpleitem-info-item">
                조회수
              </div>
              <div className="boardcontentlist-simpleitem-info-item-cnt">
                {posting.viewCnt}
              </div>
              <div className="boardcontentlist-simpleitem-info-item">
                좋아요
              </div>
              <div className="boardcontentlist-simpleitem-info-item-cnt">
                {posting.likeCntAll}
              </div>
              <div className="boardcontentlist-simpleitem-info-item">
                스크랩
              </div>
              <div className="boardcontentlist-simpleitem-info-item-cnt">
                {posting.scrapCnt}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(BoardContentListSimpleItem);
