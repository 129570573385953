import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../../../models/Model.Seminar";
import { UIPopupType } from "../../../store/ui/types";
import { STATUS_CODES } from "http";
import { idText, StringLiteralLike } from "typescript";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { timingSafeEqual } from "crypto";
import ReactHtmlParser from "react-html-parser";
import { getOS } from "../../../utils/DeviceUtil";
import { isThisHour } from "date-fns";
import * as API from "../../../API.json";
import StringUtil from "../../../utils/StringUtil";
import Textarea from "react-textarea-autosize";
import DownloadUtil from "../../../utils/DownloadUtil";
import { MY_ADDRESS } from "../../../config.json";
import { BoardContent } from "../../../models/Model.Board";
import { BoardSortType } from "../../../store/board/types";
import CheckBox from "../../../components/atoms/CheckBox/CheckBox";
import Button from "../../../components/atoms/Button";
import { List } from "immutable";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import ABTestUtil from "../../../utils/ABTestUtil";
import copy from "copy-to-clipboard";
import Input from "../../../components/atoms/Input";
import Switch from "../../../components/atoms/Switch/Switch";
import { has } from "lodash";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect: (id: number) => void;
  };

type State = {
  list: BoardContent[];
  order: BoardSortType;
  subOrder: string;
  subOrderDirect: string;
  selectedIndex: number;
  finished: boolean;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  search: string;
  unitCount: number;
  scoreBoard: number[]; // 전체좋아요수, 스크랩수, 나도궁금해요수, 답변수, 댓글수 , 공유수, 조회수
  category: string;
  editDate: string;
  editDateIndex: number;
  resultGap: number;
};

class NiceContentSelect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let score = getGlobal(GlobalKey.ADMIN_DIGEST_SCORE, true);
    let scoreBoard = score.score;
    if (!scoreBoard) scoreBoard = [10, 10, 0, 0, 0, 10, 1, 10000];

    let resultGap = score.resultGap;
    if (!resultGap) resultGap = 7;

    while (scoreBoard.length <= 7) scoreBoard.push(10000);

    this.state = {
      list: [],
      order: BoardSortType.NICE,
      subOrder: "",
      subOrderDirect: "asc",
      selectedIndex: -1,
      finished: false,
      dateStart: ABTestUtil.isTest()
        ? "2019-12-17"
        : getDateStringFromToday({ months: -1 }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      search: "",
      unitCount: 200,
      scoreBoard,
      category: "0",
      editDate: "",
      editDateIndex: -1,
      resultGap,
    };
  }
  loading = false;

  fetchList = async () => {
    if (this.loading) return;

    this.loading = true;
    this.props.progressPopup.show();

    let result = await fetchAPI(
      API.BOARD_LIST_REQUEST,
      this.state.category,
      {
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        sort: this.state.order,
        nice: true,
        search: this.state.search,
        categoryId: this.state.category,
        digestStatisticsGap:
          this.state.order == BoardSortType.DIGEST ? this.state.resultGap : "",
        hasQuiz: true,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        list: result.data,
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ list: [], finished: true });
    }
    this.props.progressPopup.hide();

    this.loading = false;
    log(LogLevel.UI_EVENT, "NiceContentSelect:fetchList", result);
  };

  fetchListMore = async () => {
    if (this.loading) return;
    this.loading = true;
    this.props.progressPopup.show();
    let result = await fetchAPI(
      API.BOARD_LIST_REQUEST,
      this.state.category,
      {
        start: this.state.list.length,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        sort: this.state.order,
        nice: true,
        search: this.state.search,
        categoryId: this.state.category,
        digestStatisticsGap:
          this.state.order == BoardSortType.DIGEST ? this.state.resultGap : "",
        hasQuiz: true,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        list: [...this.state.list, ...result.data],
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ finished: true });
    }
    this.props.progressPopup.hide();
    this.loading = false;
    log(LogLevel.UI_EVENT, "NiceContentSelect:fetchList", result);
  };

  onSelect = (detail, index) => {
    this.props.onSelect(detail.id);
    if (index == this.state.selectedIndex) index = -1;
    this.setState({ selectedIndex: index });
  };

  onOrder = (e, subOrder) => {
    e.stopPropagation();
    let subOrderDirect = "desc";
    if (subOrder == this.state.subOrder && this.state.subOrderDirect == "desc")
      subOrderDirect = "asc";

    let list = this.state.list.sort((a, b) => {
      return (
        (a[subOrder] < b[subOrder] || a[subOrder] == null
          ? -1
          : a[subOrder] > b[subOrder] || b[subOrder] == null
          ? 1
          : 0) * (subOrderDirect == "desc" ? -1 : 1)
      );
    });
    this.setState({ subOrder, subOrderDirect, list });
  };

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:NiceContentSelect:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:NiceContentSelect:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:NiceContentSelect:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  onDigestCandidateCheck = async (item: BoardContent, value, index, e) => {
    e.stopPropagation();
    if (item.digestCandidate == value) item.digestCandidate = 0;
    else item.digestCandidate = value;
    let newBoard: BoardContent = {
      id: item.id,
      digestCandidate: item.digestCandidate,
      managedAt: 1,
    };
    let result = await fetchAPI(
      API.BOARD_UPDATE,
      "",
      null,
      newBoard,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      let list = [...this.state.list];
      list[index] = { ...item };
      this.setState({ list });
    }
  };

  renderBoardContents() {
    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "digestCandidate")}
            >
              후보
              {this.state.subOrder == "digestCandidate"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "id")}
            >
              ID{" "}
              {this.state.subOrder == "id"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "boardsCategoriesId")}
            >
              게시판{" "}
              {this.state.subOrder == "boardsCategoriesId"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td className="admin-table-label-x">제목</td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "createdAt")}
            >
              작성일{" "}
              {this.state.subOrder == "createdAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "nicedAt")}
            >
              추천일{" "}
              {this.state.subOrder == "nicedAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "likeCntAll")}
            >
              좋아요수{" "}
              {this.state.subOrder == "likeCntAll"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "scrapCnt")}
            >
              스크랩수{" "}
              {this.state.subOrder == "scrapCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              나.궁.수{" "}
              {this.state.subOrder == "meTooCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "replyCnt")}
            >
              답변수{" "}
              {this.state.subOrder == "replyCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "commentCnt")}
            >
              댓글수{" "}
              {this.state.subOrder == "commentCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              공유수{" "}
              {this.state.subOrder == "meTooCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "viewCnt")}
            >
              조회수{" "}
              {this.state.subOrder == "viewCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.list.map((item: BoardContent, index) => {
            return (
              <tr
                key={index.toString()}
                className={
                  (item.hasQuiz ? "common-color-highlight common-bold" : "") +
                  (item.digestCandidate == -1
                    ? " admin-table-value-dimm"
                    : "") +
                  (item.digestCandidate == 1
                    ? " admin-table-value-picked"
                    : "") +
                  (this.state.selectedIndex == index
                    ? " admin-table-value-selected"
                    : "")
                }
                onClick={() => this.onSelect(item, index)}
              >
                <td className={"admin-table-value"}>
                  <ToggleButton
                    type="Icon"
                    active={item.digestCandidate == 1}
                    color={"Skyblue"}
                    size={"Small"}
                    variant={"Outlined"}
                    onClick={(e) =>
                      this.onDigestCandidateCheck(item, 1, index, e)
                    }
                    icon="ArrowUpLarge"
                  />
                  <ToggleButton
                    type="Icon"
                    active={item.digestCandidate == -1}
                    color={"Red"}
                    size={"Small"}
                    variant={"Outlined"}
                    onClick={(e) =>
                      this.onDigestCandidateCheck(item, -1, index, e)
                    }
                    icon="ArrowDownLarge"
                  />
                </td>
                <td className={"admin-table-value"}>{item.id}</td>
                <td className={"admin-table-value"}>
                  {item.boardsCategoriesName}
                </td>
                <td className={"admin-table-value"}>
                  {item.subject.substring(0, 30)}
                </td>
                <td className={"admin-table-value"}>
                  {item.createdAt
                    ? getDateStringFromToday({ date: item.createdAt })
                    : ""}
                </td>
                <td className={"admin-table-value"}>
                  {item.nicedAt
                    ? getDateStringFromToday({ date: item.nicedAt })
                    : ""}
                </td>
                <td className={"admin-table-value"}>{item.likeCntAll}</td>
                <td className={"admin-table-value"}>{item.scrapCnt}</td>
                <td className={"admin-table-value"}>{item.meTooCnt}</td>
                <td className={"admin-table-value"}>{item.replyCnt}</td>
                <td className={"admin-table-value"}>{item.commentCnt}</td>
                <td className={"admin-table-value"}>{item.sharedCnt}</td>
                <td className={"admin-table-value"}>{item.viewCnt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "NiceContentSelect.render",
      this.props,
      this.state
    );

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={this.fetchListMore}>
          더 불러오기
        </IonButton>
      );
    }

    return (
      <div className="admin-full-container">
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">기간</div>
          <div
            className={
              "admin-toggle" +
              (this.state.order == BoardSortType.DIGEST
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ order: BoardSortType.DIGEST })}
          >
            선정일
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.order == BoardSortType.TIME_DESC
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ order: BoardSortType.TIME_DESC })}
          >
            등록일
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.order == BoardSortType.NICE
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ order: BoardSortType.NICE })}
          >
            추천일
          </div>
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateStart}
            onChange={(e) => this.onDateChange(e, "dateStart")}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateEnd}
            onChange={(e) => this.onDateChange(e, "dateEnd")}
          />

          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -2 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            2주
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -1 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            1개월
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -3 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            3개월
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -6 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            6개월
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">게시판</div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "0" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "0" })}
          >
            전체
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "1" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "1" })}
          >
            학술
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "12" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "12" })}
          >
            약사라이프
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "2" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "2" })}
          >
            뉴스
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "3" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "3" })}
          >
            공지
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "13" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "13" })}
          >
            모임
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "14" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "14" })}
          >
            장터
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">검색어</div>
          <input
            placeholder="검색어(exact match)"
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">데이터 로딩 단위</div>
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 200 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 200 })}
          >
            200row
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 1000 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 1000 })}
          >
            1000row
          </div>
        </div>
        <div className="common-container">
          {more}
          {this.renderBoardContents()}
          {more}
          <div style={{ height: "100px" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NiceContentSelect)
);
