import React, { useState } from "react";
import Tag from "../../atoms/Tag";
import Badge from "../../atoms/Badge";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import Icon from "../../atoms/Icon";
import { COLOR_SYSTEM } from "../../design/design-system";
import {
  BottomButtons,
  BottomLeft,
  Tags,
  CardMain,
  CardMainBottom,
  CardMainDetails,
  CardTop,
  CardTopLeft,
  DetailContainer,
  DetailsDescription,
  DetailsTitle,
  JobPostCardWrapper,
  ButtonsContainer,
} from "./style";
import Button from "../../atoms/Button";
import {
  JobOfferInfo,
  JobOfferName,
  JobOfferType,
} from "../../../models/Model.JobPost";
import { getDistanceString } from "../../../utils/LocationUtil";
import { renderJobPostPay } from "../../../utils/JobPostUtil";
import Text from "../../atoms/Text";
import { CompanyType, UserInfo } from "../../../models/Model.User";
import { companyName, JobBizOfferName } from "../../../types/jobpost";
import { differenceInCalendarDays, differenceInDays, format } from "date-fns";
import { JOBOFFER_ALIM_FREE_MAX } from "./../../../config.json";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import {
  getDateStringFromToday,
  convertDateToDays,
} from "../../../utils/TimeUtil";

export type CardType = "All" | "Interest" | "Me" | "Premium" | "Biz";

export interface JobPostCardProps {
  me?: UserInfo;
  post: JobOfferInfo;
  isPremium?: boolean;
  /** 신규 게시물 여부 */
  isNew?: boolean;
  /** 급여 */
  pay: string;
  /** Card 타입 */
  cardType?: CardType;
  /** 개국약사 or 근무약사인지 체크 */
  isOwner?: boolean;
  isRead?: boolean;
  history?: boolean;
  /** 게시물 클릭 이벤트 */
  onClick?: (params?: any) => any;
  onRaise?: () => void;
  onAlimMessage?: () => void;
  onFinalize?: () => void;
  onScrap?: () => void;
  onUnscrap?: () => void;
  onReuse?: () => void;
  onPremium?: () => void;
}

const JobPostCard: React.FC<JobPostCardProps> = ({
  me,
  post,
  isPremium = false,
  isNew = false,
  cardType,
  isOwner,
  isRead = false,
  history = false,
  onClick,
  onRaise,
  onAlimMessage,
  onFinalize,
  onScrap,
  onUnscrap,
  onReuse,
  onPremium,
}) => {
  const [isLiked, setIsLiked] = useState(post.liked);

  const getTagColorByWorkType = (workType: JobOfferType | CompanyType) => {
    if (post && !history && post.endedAt) return "Gray";

    switch (workType) {
      case JobOfferType.FULL_TIME:
      case CompanyType.HOSPITAL:
        return "Blue";
      case CompanyType.PHARMACEUTICAL_COMPANY:
      case JobOfferType.PART_TIME:
        return "Green";
      case CompanyType.DISTRIBUTOR:
      case JobOfferType.TEMPORARY:
        return "Brown";
      case CompanyType.ETC:
        return "Gray";
      default:
        return "Red";
    }
  };

  const getElapsedTime = (startedAt: string) => {
    return Math.floor(
      (new Date(Date.now()).getTime() - new Date(startedAt).getTime()) /
        (1000 * 60 * 60 * 24)
    );
  };

  const handleScrapToggle = (e: any) => {
    e.stopPropagation();

    isLiked ? onUnscrap() : onScrap();
    setIsLiked(!isLiked);
  };

  const differenceDays =
    convertDateToDays(getDateStringFromToday({ date: post.applyEndAt })) -
    convertDateToDays(getDateStringFromToday());
  return (
    <JobPostCardWrapper
      isPremium={
        isPremium || (cardType === "Me" && post.order && post.order[0].endAt)
      }
      ended={!history && post.endedAt !== null}
      cardType={cardType}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {cardType === "Me" && post.order && post.order[0].endAt && (
        <CardTop>
          <Badge color="Black" content="Premium" />
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[400]}>
            광고 종료일 :{" "}
            {getDateStringFromToday({ date: post.order[0].endAt })}
          </Text>
        </CardTop>
      )}

      <CardTop>
        <CardTopLeft>
          {isNew && <Badge color="Red" content="N" />}
          {isPremium && <Badge color="Black" content="Premium" />}

          {post.companyType ? (
            <Tag
              color={getTagColorByWorkType(post.companyType)}
              variant="Tinted"
              style={{ opacity: post.endedAt || isRead ? 0.5 : 1 }}
            >
              {companyName[post.companyType]}
            </Tag>
          ) : (
            <Tag
              color={getTagColorByWorkType(post.workType)}
              variant="Tinted"
              style={{ opacity: post.endedAt || isRead ? 0.5 : 1 }}
            >
              {JobOfferName[post.workType]}
            </Tag>
          )}
        </CardTopLeft>

        {cardType === "Me" ? (
          <Tag
            color={(history && post.endedAt) || isRead ? "Gray" : "Red"}
            variant="Tinted"
          >
            {!history && post.endedAt
              ? "공고 종료"
              : post.raisedAt !== post.startedAt
              ? `끌어올리기 ${getElapsedTime(post.raisedAt)}일 경과`
              : `${getElapsedTime(post.startedAt)}일 경과`}
          </Tag>
        ) : (
          !isOwner && (
            <ToggleButton
              color={"Gray"}
              icon="BookmarkFilled"
              onClick={handleScrapToggle}
              size="Medium"
              type="Icon"
              variant="Ghost"
              active={isLiked}
            />
          )
        )}
      </CardTop>
      <CardMain
        ended={(!history && post.endedAt !== null) || isRead}
        cardType={cardType}
      >
        <div>
          <div>
            {post.companyType != CompanyType.PHARMACY && post.companyName && (
              <Text
                textType="Body2SemiBold"
                color={COLOR_SYSTEM.get("Skyblue")[400]}
              >
                {post.companyName}
              </Text>
            )}
          </div>
          <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
            {post.title}
          </Text>
        </div>
        <CardMainDetails>
          {(cardType === "All" ||
            cardType === "Interest" ||
            cardType === "Biz") && (
            <DetailContainer>
              <DetailsTitle>
                <Icon
                  width={16}
                  height={16}
                  name={"MapPin"}
                  fill={COLOR_SYSTEM.get("Gray")[200]}
                />
              </DetailsTitle>
              <DetailsDescription>
                <Text
                  textType="Body2Medium"
                  color={COLOR_SYSTEM.get("Gray")[600]}
                >
                  {post.pharmacy.region} ({getDistanceString(post.distance)})
                </Text>
              </DetailsDescription>
            </DetailContainer>
          )}
          <DetailContainer>
            {post.companyType ? (
              <>
                <DetailsTitle>
                  <Icon
                    width={16}
                    height={16}
                    name={"Job"}
                    fill={COLOR_SYSTEM.get("Gray")[200]}
                  />
                </DetailsTitle>
                <DetailsDescription>
                  <Text
                    textType="Body2Medium"
                    color={COLOR_SYSTEM.get("Gray")[600]}
                  >
                    {JobBizOfferName[post.workType]}{" "}
                    {post.workType === JobOfferType.EMPLOYED
                      ? post.contractPeriod
                        ? `(수습기간 ${post.contractPeriod}개월)`
                        : null
                      : post.contractPeriod
                      ? `(${post.contractPeriod}개월)`
                      : null}
                  </Text>
                </DetailsDescription>
              </>
            ) : (
              <>
                <DetailsTitle>
                  <Icon
                    width={16}
                    height={16}
                    name={"Calendar"}
                    fill={COLOR_SYSTEM.get("Gray")[200]}
                  />
                </DetailsTitle>
                <DetailsDescription>
                  <Text
                    textType="Body2Medium"
                    color={COLOR_SYSTEM.get("Gray")[600]}
                  >
                    {post.workDayOfWeek}
                  </Text>
                  {post.canNegoWorkDayOfWeek && (
                    <Tag color="Gray" variant="Tinted">
                      협의 가능
                    </Tag>
                  )}
                </DetailsDescription>
              </>
            )}
          </DetailContainer>
          <DetailContainer>
            <DetailsTitle>
              <Icon
                width={16}
                height={16}
                name={"Won"}
                fill={COLOR_SYSTEM.get("Gray")[200]}
              />
            </DetailsTitle>
            <DetailsDescription>
              {post.canNegoSalary && !post.salaryRangeHigh ? (
                <Text
                  textType="Body2Medium"
                  color={COLOR_SYSTEM.get("Gray")[600]}
                >
                  추후 협의
                </Text>
              ) : (
                <>
                  <Text
                    textType="Body2Medium"
                    color={COLOR_SYSTEM.get("Gray")[600]}
                  >
                    {renderJobPostPay(
                      post.salaryType,
                      post.taxType,
                      post.salaryRangeLow,
                      post.salaryRangeHigh,
                      cardType === "Biz"
                    )}
                  </Text>
                  {post.canNegoSalary && (
                    <Tag color="Gray" variant="Tinted">
                      협의 가능
                    </Tag>
                  )}
                </>
              )}
            </DetailsDescription>
          </DetailContainer>
        </CardMainDetails>
      </CardMain>
      <CardMainBottom>
        <BottomLeft>
          <Tags ended={(!history && post.endedAt !== null) || isRead}>
            <Tag color="Gray" icon="EyeFilled" left variant="Tinted">
              {post.viewCnt}
            </Tag>
            <Tag color="Gray" icon="BookmarkFilled" left variant="Tinted">
              {post.likeCnt}
            </Tag>
          </Tags>
          {cardType === "Biz" ? (
            post.applyEndAt ? (
              <Text
                textType="CaptionBold"
                color={COLOR_SYSTEM.get("Gray")[400]}
                className="period"
              >
                {differenceDays >= 0 ? differenceDays : 0}일 남음 (~
                {format(new Date(post.applyEndAt), "MM/dd")})
              </Text>
            ) : (
              <Text
                textType="CaptionBold"
                color={COLOR_SYSTEM.get("Gray")[400]}
                className="period"
              >
                채용시까지
              </Text>
            )
          ) : (
            !history &&
            (post.endedAt ? (
              <Text
                textType="CaptionBold"
                color={COLOR_SYSTEM.get("Gray")[400]}
              >
                공고 종료
              </Text>
            ) : (
              post.ownerId !== me.id && (
                <Text
                  textType="CaptionBold"
                  color={COLOR_SYSTEM.get("Gray")[400]}
                >
                  {post.raisedAt === post.startedAt
                    ? `${getElapsedTime(post.raisedAt)}일 경과`
                    : `끌어올리기 ${getElapsedTime(post.raisedAt)}일 경과`}
                </Text>
              )
            ))
          )}
        </BottomLeft>
        {!post.companyType && cardType === "Me" && (
          <BottomButtons>
            {onReuse ? (
              <>
                <div style={{ display: "flex", width: "100%" }}>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Outlined"
                    onClick={() => {
                      onReuse();
                    }}
                  >
                    이 공고 재사용
                  </Button>
                </div>
              </>
            ) : post.initCandidateCnt < JOBOFFER_ALIM_FREE_MAX ? (
              <>
                <ButtonsContainer>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRaise();
                    }}
                  >
                    끌어올리기
                  </Button>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Tinted"
                    onClick={(e) => {
                      e.stopPropagation();
                      onFinalize();
                    }}
                  >
                    공고 종료하기
                  </Button>
                </ButtonsContainer>
                {ABTestUtil.isTest(ABTestFeature.UI_PHARMACY_PREMIUM) && (
                  // !(post.order && post.order[0].endAt) &&
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "8px",
                    }}
                  >
                    <Button
                      color="Primary"
                      size="Medium"
                      type="Text"
                      variant="Outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        onPremium();
                      }}
                    >
                      Premium 광고
                      {cardType === "Me" &&
                        post.order &&
                        post.order[0].endAt &&
                        " 연장"}
                      하기{" "}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                <ButtonsContainer>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRaise();
                    }}
                  >
                    끌어올리기
                  </Button>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      onAlimMessage();
                    }}
                  >
                    알림톡 보내기
                  </Button>
                </ButtonsContainer>
                <div style={{ display: "flex", width: "100%" }}>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Tinted"
                    onClick={(e) => {
                      e.stopPropagation();
                      onFinalize();
                    }}
                  >
                    공고 종료하기
                  </Button>
                </div>
                {ABTestUtil.isTest(ABTestFeature.UI_PHARMACY_PREMIUM) && (
                  // !(post.order && post.order[0].endAt) &&
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "8px",
                    }}
                  >
                    <Button
                      color="Primary"
                      size="Medium"
                      type="Text"
                      variant="Outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        onPremium();
                      }}
                    >
                      Premium 광고
                      {cardType === "Me" &&
                        post.order &&
                        post.order[0].endAt &&
                        " 연장"}
                      하기{" "}
                    </Button>
                  </div>
                )}
              </>
            )}
          </BottomButtons>
        )}
      </CardMainBottom>
    </JobPostCardWrapper>
  );
};

export default JobPostCard;
