import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./UserManage.css";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import UserManagePopup from "./component/user/UserManagePopup";
import UserManageProfile from "./component/user/UserManageProfile";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import UserSelect from "./component/user/UserSelect";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selected: UserInfo;
};

class UserManage extends Component<Props, State> {
  popup = null;
  list = null;

  state = {
    selected: null,
  };

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
    document.title = "사용자관리";
  }

  componentDidMount() {}

  onSelect = (user: UserInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", user);
    var x = document.getElementById("admin-sub-conainer");
    this.setState({ selected: user });
    log(LogLevel.UI_DATA_LOAD, "onSelect", x, x.offsetParent);
    if (x.offsetParent === null) {
      this.popup.show(user);
    }
  };

  onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    if (this.list) this.list.refresh();
    this.setState({ selected: null });
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({ selected: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "UserManage.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">사용자관리</IonText>
        </IonHeader>
        <IonContent>
          <UserManagePopup
            onDone={this.onDone}
            ref={(ref) => {
              this.popup = ref;
            }}
          />

          <div className="admin-main-container">
            <div className="admin-container">
              <UserSelect
                onDone={this.onSelect}
                ref={(ref) => {
                  this.list = ref;
                }}
                me={this.props.user.me}
              />
            </div>
            <div id="admin-sub-conainer" className="admin-container">
              <UserManageProfile
                user={this.state.selected}
                onDone={this.onDone}
                onCancel={this.onCancel}
              />
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserManage)
);
