import React, { Component } from "react";
import "./../Admin.scss";
import "./../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
} from "@ionic/react";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../utils/LogUtil";
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from "../../models/Model.User";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { BoardContent, BoardInfo, BoardAttribute } from "../../models/Model.Board";
import Truncate from "react-truncate";

const AdminAPI = {
  GET_LIST: { method: "GET", path: "/admin/board/boards", contentType: "application/json" },
};

type Props = {
  onDone: (board: BoardInfo) => void;
};

type State = {
  boards: BoardInfo[];
};

class BoardSelect extends Component<Props, State> {
  state = {
    boards: [],
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSelect = (board: BoardInfo) => {
    log(LogLevel.UI_EVENT, "BoardSelect:onSelect", board);
    this.props.onDone(board);
  };

  fetchData = async () => {
    let result = await fetchAPI(AdminAPI.GET_LIST, "", {}, null, getGlobal(GlobalKey.TOKEN));
    if (result && !result.error) {
      this.setState({ boards: result.data ? result.data : [] });
    } else {
      this.setState({ boards: [] });
    }
    log(LogLevel.UI_EVENT, "BoardSelect:fetchData", result);
  };

  render() {
    return (
      <div className="admin-container">
        <div className="admin-board-list-container">
          <IonList>
            {this.state.boards.map((item: BoardInfo, index: number) => {
              return (
                <div
                  key={index.toString()}
                  className="admin-board-list-item-container"
                  onClick={() => this.onSelect(item)}
                >
                  <div>
                    {item.order} / {item.id} / {item.ids} / {item.name} / {item.fullname}{" "}
                  </div>
                  <div>
                    {" "}
                    type : {item.type} / level : {item.level} / Title:{" "}
                    {item.attribute.includes(BoardAttribute.HAS_TITLE) ? "Y" : "N"} / Reply:{" "}
                    {item.attribute.includes(BoardAttribute.CAN_REPLY) ? "Y" : "N"} / Share:{" "}
                    {item.attribute.includes(BoardAttribute.CAN_SHARE) ? "Y" : "N"}{" "}
                  </div>
                </div>
              );
            })}
          </IonList>
        </div>
      </div>
    );
  }
}

export default BoardSelect;
