import React, { Component } from 'react';
import './ActionButton.css';
import { IonPopover, IonTitle, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import { Mode } from '@ionic/core';

type Props = {
  src? : any;
  iconName?: string;
  iconMode?: Mode;
  onClick? : () => void;
  label : number;
  value : boolean;
  open?: boolean;
  title?: string;
  emphasis?: boolean;
};

class ActionButton extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  click = (e) => {
    if(!this.props.open)
      this.props.onClick();
    e.stopPropagation();
  }

  render() {
    let textTop, textBottom;
    if(this.props.title){
      if(!this.props.src && !this.props.iconMode){
        textBottom=<IonLabel class="action-button-text">{this.props.label?this.props.label:0}</IonLabel>;
        textTop = <IonLabel class="action-button-text-icon">{this.props.title}</IonLabel>;
      }else{
        textTop=<IonLabel class="action-button-text">{this.props.label?this.props.label:0}</IonLabel>;
        textBottom = <IonLabel class="action-button-text">{this.props.title}</IonLabel>;
      }
    }else{
      textBottom = <IonLabel class="action-button-text">{this.props.label?this.props.label:0}</IonLabel>;
    }
    let icon;
    if(this.props.src){
      icon = <IonIcon class="action-button-icon" src={this.props.src}/>;
    }else if(this.props.iconName){
      if(this.props.iconMode)
        icon = <IonIcon class="action-button-icon" name={this.props.iconName} mode={this.props.iconMode}/>;
      else
        icon = <IonIcon class="action-button-icon" name={this.props.iconName}/>;
    }

    let color = "action-button-inactive";
    if(!this.props.open && this.props.value)
      color = "action-button";
    else if(this.props.emphasis)
      color = "action-button-emphasis";
    return (
      <IonButton class={"action-button-"+ (this.props.title?"3":"2")} color={color} fill="clear" onClick={this.click}>
        <div className="action-button-container">
          {textTop}
          {icon}
          {textBottom}
        </div>
      </IonButton>
    );
  }
}

export default ActionButton;