import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  CompanyTypeName,
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "./../../../models/Model.User";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import "./style.scss";
import "./../../../Common.css";
import Scroll from "../../cell/Scroll";
import Icon from "../../atoms/Icon";
import PartnersCard from "../../organisms/AllInOne/PartnersCard";
import Button from "../../atoms/Button";
import { Header } from "../../atoms/Layout";
import Tag from "../../atoms/Tag";
import logo from "../../../../assets/image/logo.png";
import Body from "../../cell/Body";
import TabBar from "../../molecules/TabBar";
import Spinner from "../../atoms/Spinner";
import SwipeableViews from "react-swipeable-views";
import { getGlobal } from "../../../utils/GlobalUtil";
import ReactHtmlParser from "react-html-parser";
import Footer from "../../cell/Footer";
import StringUtil from "../../../utils/StringUtil";
import { BoardContent } from "../../../models/Model.Board";
import PostListItem from "../../organisms/AllInOne/PostListItem";
import Separator from "../../../component/atom/Separator";
import { Flex, Static } from "../../atoms/Layout";
import Input from "../../atoms/Input";
import UserUtil from "../../../utils/UserUtil";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import Dropdown from "../../molecules/Dropdown/Dropdown";
import useBottomSheet from "../../../hooks/useBottomSheet";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";

export interface PartnersConsultProps {
  me: UserInfo;
  onGoBack: () => void;
  onConsult: (time: string, message: string) => void;
  partner: AllInOnePartnerInfo;
}

const timeMessages = [
  "언제든 괜찮아요",
  "아침 (9시 이전)",
  "오전 (9시~12시)",
  "오후 (12시~18시)",
  "저녁 (18시 이후)",
  "직접 입력",
  "통화 어려움 (우선 문자로 답 주세요)",
];
const PartnersConsult: React.FC<PartnersConsultProps & RouteComponentProps> = ({
  // history,
  me,
  onGoBack,
  onConsult,
  partner,
}) => {
  try {
    const [agreed, setAgreed] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [time, setTime] = useState<string>("언제든 괜찮아요");
    const [timeManual, setTimeManual] = useState<string>("");
    const bottomSheet = useBottomSheet();

    const handleConsult = useCallback(() => {
      if (!agreed) {
        return;
      }

      let timeMessage = time;
      if (timeMessage == "직접 입력") timeMessage = timeManual;

      onConsult(timeMessage, message);
    }, [message, time, timeManual, agreed]);

    return (
      <div className="common-content common-position-relative">
        <Header>
          <TopAppBar
            title="문의 남기기"
            titleType="H4"
            leftButtons={[
              <Button
                color="Tertiary"
                icon="X"
                onClick={onGoBack}
                size="Medium"
                type="Icon"
                variant="Ghost"
              />,
            ]}
          />
        </Header>
        <Body footerHeight={88}>
          <div className="allinone-head-container">
            <PartnersCard partner={partner} onClick={() => {}} />
          </div>
          <Separator type={"Block"} />
          <Flex
            direction="column"
            customStyle={{ gap: "12px", padding: "20px" }}
          >
            <Static style={{ marginTop: "28px" }}>
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                문의 내용
              </Text>
            </Static>
            <Input
              placeholder={
                <Text
                  textType="InputMedium"
                  color={COLOR_SYSTEM.get("Gray")[300]}
                >
                  궁금한 점을 입력해주세요.
                  <br />
                  담당자가 답변을 준비해서 연락드리겠습니다.
                </Text>
              }
              value={message}
              multiLine
              style={{ marginBottom: "8px" }}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Static>
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                통화 가능 시간
              </Text>
            </Static>
            <Dropdown
              value={time}
              onClick={(e) => {
                bottomSheet.show({
                  title: {
                    value: "통화 가능 시간",
                    align: "left",
                  },
                  body: (
                    <BottomSheetList>
                      {timeMessages.map((timeMessage, index) => (
                        <BottomSheetListItem
                          key={index.toString()}
                          left={
                            <Text
                              textType="Body1Medium"
                              color={
                                COLOR_SYSTEM.get("Gray")[
                                  timeMessage == time ? 900 : 400
                                ]
                              }
                            >
                              {timeMessage}
                            </Text>
                          }
                          right={
                            timeMessage == time ? (
                              <Icon
                                name="Check"
                                width={24}
                                height={24}
                                color={COLOR_SYSTEM.get("Gray")[900]}
                              />
                            ) : null
                          }
                          onClick={() => {
                            setTime(timeMessage);
                            bottomSheet.close();
                          }}
                        />
                      ))}
                    </BottomSheetList>
                  ),
                });
              }}
            />
            {time == "직접 입력" && (
              <Input
                placeholder="통화 가능 시간을 입력해주세요"
                value={timeManual}
                onChange={(e) => setTimeManual(e.target.value)}
              />
            )}
            <ToggleButton
              icon="Check-Circle"
              left
              type="IconWithText"
              active={agreed}
              color={"Skyblue"}
              size={"Medium"}
              variant={"Ghost"}
              justfyContents={"none"}
              onClick={() => setAgreed(!agreed)}
              style={{ marginTop: "20px" }}
            >
              개인정보 제3자 제공 동의
            </ToggleButton>
            <Flex
              direction="column"
              customStyle={{
                padding: "12px 16px",
                background: COLOR_SYSTEM.get("Gray")[10],
                borderRadius: "8px",
              }}
            >
              <Static style={{ marginBottom: "8px" }}>
                <Text
                  textType="Body2SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[700]}
                >
                  문의에 답변드리기 위해 <br />
                  아래의 정보가 업체에 전달됩니다.
                </Text>
              </Static>
              <Static>
                <Text
                  textType="Body2SemiBold"
                  color={COLOR_SYSTEM.get("Skyblue")[400]}
                >
                  성함 : {me.name} <br />
                  연락처 : {UserUtil.getPhoneNumberString(me.phone)}
                </Text>
              </Static>
            </Flex>
          </Flex>
        </Body>
        <Footer>
          <div className="allinone-footer">
            <Button
              style={{ flexGrow: 1 }}
              size={"Medium"}
              type={"Text"}
              variant={"Tinted"}
              color={"Secondary"}
              onClick={onGoBack}
            >
              취소
            </Button>
            <Button
              style={{ flexGrow: 4 }}
              size={"Medium"}
              type={"Text"}
              variant={"Contained"}
              color={"Secondary"}
              onClick={handleConsult}
              disabled={!agreed}
              disabledColor={!agreed}
            >
              완료
            </Button>
          </div>
        </Footer>
      </div>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PartnersConsultTemplate",
      errorMessage: error,
    });
    return null;
  }
};

export default withRouter(PartnersConsult);
