import React from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import Text from "../Text";

type SwitchSize = "Large" | "Medium" | "Small";
type LabelPosition = "Left" | "Right";

export interface SwitchProps {
  size: SwitchSize;
  isActive: boolean;
  label?: string;
  labelPosition?: LabelPosition;
  className?: string;
  style?: React.CSSProperties;
  onClick: (params?: any) => any;
}

interface SwitchWrapperProps {
  size: SwitchSize;
  isActive: boolean;
}

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 12px;
  }
`;

const SwitchButton = styled.label<SwitchWrapperProps>`
  display: inline-block;
  width: ${({ size }) => {
    switch (size) {
      case "Large":
        return "68px";
      case "Medium":
        return "48px";
      case "Small":
      default:
        return "36px";
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case "Large":
        return "36px";
      case "Medium":
        return "28px";
      case "Small":
      default:
        return "20px";
    }
  }};
  background-color: ${({ isActive }) => (isActive ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[200])};
  position: relative;
  border-radius: 36px;
  transition: background-color 0.3s;
  cursor: pointer;

  input {
    display: none;
  }
`;

const Slider = styled.span<SwitchWrapperProps>`
  width: ${({ size }) => {
    switch (size) {
      case "Large":
        return "20px";
      case "Medium":
        return "12px";
      case "Small":
      default:
        return "8px";
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case "Large":
        return "20px";
      case "Medium":
        return "12px";
      case "Small":
      default:
        return "8px";
    }
  }};
  display: block;
  transform: ${({ isActive }) => `translateX(${isActive ? "260%" : "70%"}) translateY(75%)`};
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
`;

const Switch: React.FC<SwitchProps> = ({
  size,
  isActive,
  label,
  labelPosition = "Right",
  className,
  style,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <SwitchWrapper className={className} style={style}>
      {label && labelPosition === "Left" && (
        <Text
          textType={size === "Small" ? "InputMedium" : "InputLarge"}
          color={COLOR_SYSTEM.get("Gray")[isActive ? 700 : 400]}
        >
          {label}
        </Text>
      )}
      <SwitchButton size={size} isActive={isActive} onClick={handleClick}>
        <input type="checkbox" />
        <Slider size={size} isActive={isActive} />
      </SwitchButton>
      {label && labelPosition === "Right" && (
        <Text
          textType={size === "Small" ? "InputMedium" : "InputLarge"}
          color={COLOR_SYSTEM.get("Gray")[isActive ? 700 : 400]}
        >
          {label}
        </Text>
      )}
    </SwitchWrapper>
  );
};

export default Switch;
