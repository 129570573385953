import React, { useEffect } from "react";
import { Absolute, Fixed, Flex, Relative, Static } from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import Tag from "../../atoms/Tag";
import Icon from "../../atoms/Icon";
import { BoardContentSummaryGroup } from "../../../models/Model.Board";
import Skeleton from "react-loading-skeleton";
import ReactHtmlParser from "react-html-parser";
import { LogLevel, log } from "../../../utils/LogUtil";
import { getSummaryHtml } from "../BoardSummary";

const QustionWrapper = styled.div<{ index: number }>`
  padding: 16px;
  background: ${COLOR_SYSTEM.get("Gray")[10]};
  border-radius: 12px;
  transform: translateZ(0);
  overflow: show;

  * {
    opacity: ${({ index }) => (index > 0 ? 1 - 0.3 * index : 1)};
  }
`;

export interface BoardSummaryCoverProps {
  summaryGroup: BoardContentSummaryGroup;
  summaries: any;
  onStart: (showContinue: boolean) => void;
}

const BoardSummaryCover: React.FC<BoardSummaryCoverProps> = ({
  summaryGroup,
  summaries,
  onStart,
}) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap="60px"
      customStyle={{ width: "100%", marginTop: "10px", padding: "0px 30px" }}
    >
      <img
        src="/images/summary/cover-head.png"
        style={{ maxHeight: "35vh", width: "100%", objectFit: "contain" }}
      />

      <Relative customStyle={{ maxHeight: "35vh", width: "100%" }}>
        <img
          src="/images/summary/cover-title-bg.png"
          style={{ width: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
        <Absolute
          top={0}
          left={0}
          customStyle={{
            width: "100%",
            height: "100%",
            padding: "20% 10% 10% 10%",
          }}
        >
          <Flex
            direction="column"
            gap="20px"
            justifyContent="center"
            customStyle={{ width: "100%", height: "100%" }}
          >
            <Text textType="LargeTitle2" color={COLOR_SYSTEM.get("Gray")[0]}>
              # {summaryGroup.keyword}
            </Text>

            <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[0]}>
              {summaryGroup.keyword}에 대한 전문성을 <br />
              한단계 업그레이드하세요!
            </Text>
          </Flex>
        </Absolute>
      </Relative>

      <Fixed
        bottom={0}
        left={0}
        customStyle={{ width: "100%", paddingBottom: "20px" }}
      >
        <Flex
          direction="column"
          alignItems="center"
          gap="8px"
          customStyle={{ width: "100%", padding: "0 20px" }}
        >
          <Text textType="Body2Medium" color={COLOR_SYSTEM.get("Gray")[600]}>
            * 1개의 요약을 볼 때마다 무료 이용권이 차감됩니다.
          </Text>
          {summaryGroup &&
          summaryGroup.lastViewed > 0 &&
          summaryGroup.lastViewed != summaryGroup.count - 1 ? (
            <Flex gap="8px" customStyle={{ width: "100%" }}>
              <Button
                size={"Large"}
                type={"Text"}
                variant={"Outlined"}
                color={"Primary"}
                style={{ width: "100%" }}
                onClick={() => onStart(false)}
              >
                처음부터 보기
              </Button>
              <Button
                size={"Large"}
                type={"Text"}
                variant={"Contained"}
                color={"Primary"}
                style={{ width: "100%" }}
                onClick={() => onStart(true)}
              >
                이어보기
              </Button>
            </Flex>
          ) : (
            <Button
              size={"Large"}
              type={"Text"}
              variant={"Contained"}
              color={"Primary"}
              style={{ width: "100%" }}
              onClick={() => onStart(false)}
            >
              시작하기
            </Button>
          )}
        </Flex>
      </Fixed>
    </Flex>
  );
};

export default BoardSummaryCover;
