import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from './../API.json';;
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import { UserInterests } from "../models/Model.User";
import { JobOfferSalaryType, JobOfferTaxType } from "../models/Model.JobPost";

interface SalaryOption {
  taxOption: JobOfferTaxType,
  salaryOption: JobOfferSalaryType,

  salary : number,

} 

const GMYG = 0.045; // 2022 국민연급 요율
const GMYG_MAX = 524; // 2022 국민연급 상한

const BHYY = 0.040038365; // 2022 고용보험 + 건강보험 + 장기요양보험 (0.08 + (3.495 * 1.1227))

export default class SalaryUtil {
  static getAfterTaxMonthlySalary(options:SalaryOption){
    if(!options.salary)
      return 0;
        
    let salary = options.salary;
    if(options.salaryOption == JobOfferSalaryType.ANUAL)
      salary = Math.floor(salary / 12);

    if(options.taxOption == JobOfferTaxType.AFTER_TAX || options.salaryOption == JobOfferSalaryType.HOURLY)
      return salary;
    
    salary = salary - (salary * BHYY) - (((salary>GMYG_MAX)?GMYG_MAX:salary) * GMYG) - (SalaryUtil.getTax(salary)*1.1);

    return Math.floor(salary);
  }

  static getTax(salary){
    let startIndex = 0;
    let endIndex = GISA.salary.length - 1;
  
    while (startIndex < endIndex) {
      const middleIndex = startIndex + Math.round((endIndex - startIndex) / 2);
      if (GISA.salary[middleIndex] === salary) {
        // console.log("getTax 1", startIndex, endIndex, middleIndex, GISA.salary[middleIndex], salary)
        return GISA.tax[middleIndex];
      }
      if (GISA.salary[middleIndex] < salary) {
        // console.log("getTax 2", startIndex, endIndex, middleIndex, GISA[middleIndex].salary, salary)
        startIndex = middleIndex;
      } else {
        // console.log("getTax 3", startIndex, endIndex, middleIndex, GISA[middleIndex].salary, salary)
        endIndex = middleIndex - 1;
      }
    }
    // console.log("getTax 4", startIndex, endIndex, GISA[startIndex].salary, salary)
    return GISA.tax[startIndex];
  }
}
const GISA ={
  "salary": [
    106.0,
    106.5,
    107.0,
    107.5,
    108.0,
    108.5,
    109.0,
    109.5,
    110.0,
    110.5,
    111.0,
    111.5,
    112.0,
    112.5,
    113.0,
    113.5,
    114.0,
    114.5,
    115.0,
    115.5,
    116.0,
    116.5,
    117.0,
    117.5,
    118.0,
    118.5,
    119.0,
    119.5,
    120.0,
    120.5,
    121.0,
    121.5,
    122.0,
    122.5,
    123.0,
    123.5,
    124.0,
    124.5,
    125.0,
    125.5,
    126.0,
    126.5,
    127.0,
    127.5,
    128.0,
    128.5,
    129.0,
    129.5,
    130.0,
    130.5,
    131.0,
    131.5,
    132.0,
    132.5,
    133.0,
    133.5,
    134.0,
    134.5,
    135.0,
    135.5,
    136.0,
    136.5,
    137.0,
    137.5,
    138.0,
    138.5,
    139.0,
    139.5,
    140.0,
    140.5,
    141.0,
    141.5,
    142.0,
    142.5,
    143.0,
    143.5,
    144.0,
    144.5,
    145.0,
    145.5,
    146.0,
    146.5,
    147.0,
    147.5,
    148.0,
    148.5,
    149.0,
    149.5,
    150.0,
    151.0,
    152.0,
    153.0,
    154.0,
    155.0,
    156.0,
    157.0,
    158.0,
    159.0,
    160.0,
    161.0,
    162.0,
    163.0,
    164.0,
    165.0,
    166.0,
    167.0,
    168.0,
    169.0,
    170.0,
    171.0,
    172.0,
    173.0,
    174.0,
    175.0,
    176.0,
    177.0,
    178.0,
    179.0,
    180.0,
    181.0,
    182.0,
    183.0,
    184.0,
    185.0,
    186.0,
    187.0,
    188.0,
    189.0,
    190.0,
    191.0,
    192.0,
    193.0,
    194.0,
    195.0,
    196.0,
    197.0,
    198.0,
    199.0,
    200.0,
    201.0,
    202.0,
    203.0,
    204.0,
    205.0,
    206.0,
    207.0,
    208.0,
    209.0,
    210.0,
    211.0,
    212.0,
    213.0,
    214.0,
    215.0,
    216.0,
    217.0,
    218.0,
    219.0,
    220.0,
    221.0,
    222.0,
    223.0,
    224.0,
    225.0,
    226.0,
    227.0,
    228.0,
    229.0,
    230.0,
    231.0,
    232.0,
    233.0,
    234.0,
    235.0,
    236.0,
    237.0,
    238.0,
    239.0,
    240.0,
    241.0,
    242.0,
    243.0,
    244.0,
    245.0,
    246.0,
    247.0,
    248.0,
    249.0,
    250.0,
    251.0,
    252.0,
    253.0,
    254.0,
    255.0,
    256.0,
    257.0,
    258.0,
    259.0,
    260.0,
    261.0,
    262.0,
    263.0,
    264.0,
    265.0,
    266.0,
    267.0,
    268.0,
    269.0,
    270.0,
    271.0,
    272.0,
    273.0,
    274.0,
    275.0,
    276.0,
    277.0,
    278.0,
    279.0,
    280.0,
    281.0,
    282.0,
    283.0,
    284.0,
    285.0,
    286.0,
    287.0,
    288.0,
    289.0,
    290.0,
    291.0,
    292.0,
    293.0,
    294.0,
    295.0,
    296.0,
    297.0,
    298.0,
    299.0,
    300.0,
    302.0,
    304.0,
    306.0,
    308.0,
    310.0,
    312.0,
    314.0,
    316.0,
    318.0,
    320.0,
    322.0,
    324.0,
    326.0,
    328.0,
    330.0,
    332.0,
    334.0,
    336.0,
    338.0,
    340.0,
    342.0,
    344.0,
    346.0,
    348.0,
    350.0,
    352.0,
    354.0,
    356.0,
    358.0,
    360.0,
    362.0,
    364.0,
    366.0,
    368.0,
    370.0,
    372.0,
    374.0,
    376.0,
    378.0,
    380.0,
    382.0,
    384.0,
    386.0,
    388.0,
    390.0,
    392.0,
    394.0,
    396.0,
    398.0,
    400.0,
    402.0,
    404.0,
    406.0,
    408.0,
    410.0,
    412.0,
    414.0,
    416.0,
    418.0,
    420.0,
    422.0,
    424.0,
    426.0,
    428.0,
    430.0,
    432.0,
    434.0,
    436.0,
    438.0,
    440.0,
    442.0,
    444.0,
    446.0,
    448.0,
    450.0,
    452.0,
    454.0,
    456.0,
    458.0,
    460.0,
    462.0,
    464.0,
    466.0,
    468.0,
    470.0,
    472.0,
    474.0,
    476.0,
    478.0,
    480.0,
    482.0,
    484.0,
    486.0,
    488.0,
    490.0,
    492.0,
    494.0,
    496.0,
    498.0,
    500.0,
    502.0,
    504.0,
    506.0,
    508.0,
    510.0,
    512.0,
    514.0,
    516.0,
    518.0,
    520.0,
    522.0,
    524.0,
    526.0,
    528.0,
    530.0,
    532.0,
    534.0,
    536.0,
    538.0,
    540.0,
    542.0,
    544.0,
    546.0,
    548.0,
    550.0,
    552.0,
    554.0,
    556.0,
    558.0,
    560.0,
    562.0,
    564.0,
    566.0,
    568.0,
    570.0,
    572.0,
    574.0,
    576.0,
    578.0,
    580.0,
    582.0,
    584.0,
    586.0,
    588.0,
    590.0,
    592.0,
    594.0,
    596.0,
    598.0,
    600.0,
    602.0,
    604.0,
    606.0,
    608.0,
    610.0,
    612.0,
    614.0,
    616.0,
    618.0,
    620.0,
    622.0,
    624.0,
    626.0,
    628.0,
    630.0,
    632.0,
    634.0,
    636.0,
    638.0,
    640.0,
    642.0,
    644.0,
    646.0,
    648.0,
    650.0,
    652.0,
    654.0,
    656.0,
    658.0,
    660.0,
    662.0,
    664.0,
    666.0,
    668.0,
    670.0,
    672.0,
    674.0,
    676.0,
    678.0,
    680.0,
    682.0,
    684.0,
    686.0,
    688.0,
    690.0,
    692.0,
    694.0,
    696.0,
    698.0,
    700.0,
    702.0,
    704.0,
    706.0,
    708.0,
    710.0,
    712.0,
    714.0,
    716.0,
    718.0,
    720.0,
    722.0,
    724.0,
    726.0,
    728.0,
    730.0,
    732.0,
    734.0,
    736.0,
    738.0,
    740.0,
    742.0,
    744.0,
    746.0,
    748.0,
    750.0,
    752.0,
    754.0,
    756.0,
    758.0,
    760.0,
    762.0,
    764.0,
    766.0,
    768.0,
    770.0,
    772.0,
    774.0,
    776.0,
    778.0,
    780.0,
    782.0,
    784.0,
    786.0,
    788.0,
    790.0,
    792.0,
    794.0,
    796.0,
    798.0,
    800.0,
    802.0,
    804.0,
    806.0,
    808.0,
    810.0,
    812.0,
    814.0,
    816.0,
    818.0,
    820.0,
    822.0,
    824.0,
    826.0,
    828.0,
    830.0,
    832.0,
    834.0,
    836.0,
    838.0,
    840.0,
    842.0,
    844.0,
    846.0,
    848.0,
    850.0,
    852.0,
    854.0,
    856.0,
    858.0,
    860.0,
    862.0,
    864.0,
    866.0,
    868.0,
    870.0,
    872.0,
    874.0,
    876.0,
    878.0,
    880.0,
    882.0,
    884.0,
    886.0,
    888.0,
    890.0,
    892.0,
    894.0,
    896.0,
    898.0,
    900.0,
    902.0,
    904.0,
    906.0,
    908.0,
    910.0,
    912.0,
    914.0,
    916.0,
    918.0,
    920.0,
    922.0,
    924.0,
    926.0,
    928.0,
    930.0,
    932.0,
    934.0,
    936.0,
    938.0,
    940.0,
    942.0,
    944.0,
    946.0,
    948.0,
    950.0,
    952.0,
    954.0,
    956.0,
    958.0,
    960.0,
    962.0,
    964.0,
    966.0,
    968.0,
    970.0,
    972.0,
    974.0,
    976.0,
    978.0,
    980.0,
    982.0,
    984.0,
    986.0,
    988.0,
    990.0,
    992.0,
    994.0,
    996.0,
    998.0,
  ],
  "tax": [
    0.104,
    0.111,
    0.118,
    0.125,
    0.132,
    0.139,
    0.146,
    0.153,
    0.160,
    0.167,
    0.174,
    0.181,
    0.188,
    0.195,
    0.202,
    0.209,
    0.216,
    0.223,
    0.230,
    0.237,
    0.244,
    0.250,
    0.257,
    0.264,
    0.271,
    0.278,
    0.285,
    0.292,
    0.299,
    0.306,
    0.313,
    0.320,
    0.327,
    0.334,
    0.341,
    0.348,
    0.355,
    0.362,
    0.370,
    0.381,
    0.391,
    0.401,
    0.412,
    0.422,
    0.432,
    0.443,
    0.453,
    0.463,
    0.474,
    0.484,
    0.494,
    0.505,
    0.515,
    0.525,
    0.536,
    0.546,
    0.556,
    0.567,
    0.577,
    0.587,
    0.598,
    0.608,
    0.618,
    0.629,
    0.639,
    0.649,
    0.660,
    0.670,
    0.680,
    0.691,
    0.701,
    0.711,
    0.721,
    0.732,
    0.742,
    0.752,
    0.763,
    0.773,
    0.783,
    0.794,
    0.804,
    0.814,
    0.825,
    0.835,
    0.845,
    0.856,
    0.866,
    0.876,
    0.892,
    0.912,
    0.933,
    0.954,
    0.974,
    0.995,
    1.016,
    1.036,
    1.057,
    1.078,
    1.098,
    1.119,
    1.140,
    1.160,
    1.181,
    1.202,
    1.222,
    1.243,
    1.264,
    1.284,
    1.305,
    1.326,
    1.346,
    1.367,
    1.388,
    1.408,
    1.429,
    1.450,
    1.470,
    1.491,
    1.511,
    1.532,
    1.553,
    1.573,
    1.594,
    1.615,
    1.635,
    1.656,
    1.677,
    1.697,
    1.718,
    1.739,
    1.759,
    1.780,
    1.801,
    1.821,
    1.842,
    1.863,
    1.888,
    1.920,
    1.952,
    1.985,
    2.017,
    2.049,
    2.081,
    2.113,
    2.145,
    2.177,
    2.209,
    2.242,
    2.274,
    2.306,
    2.338,
    2.370,
    2.402,
    2.434,
    2.466,
    2.499,
    2.531,
    2.563,
    2.595,
    2.627,
    2.659,
    2.691,
    2.724,
    2.756,
    2.788,
    2.820,
    2.852,
    2.884,
    2.916,
    2.948,
    2.981,
    3.013,
    3.045,
    3.077,
    3.109,
    3.141,
    3.197,
    3.277,
    3.357,
    3.438,
    3.518,
    3.598,
    3.679,
    3.759,
    3.839,
    3.920,
    4.000,
    4.080,
    4.163,
    4.249,
    4.334,
    4.420,
    4.505,
    4.591,
    4.677,
    4.762,
    4.848,
    4.933,
    5.019,
    5.104,
    5.190,
    5.276,
    5.361,
    5.447,
    5.532,
    5.618,
    5.704,
    5.789,
    5.875,
    5.960,
    6.046,
    6.131,
    6.217,
    6.303,
    6.388,
    6.474,
    6.559,
    6.645,
    6.730,
    6.816,
    6.902,
    6.987,
    7.073,
    7.158,
    7.244,
    7.329,
    7.415,
    7.501,
    7.586,
    7.672,
    7.757,
    7.843,
    7.928,
    8.014,
    8.100,
    8.185,
    8.271,
    8.356,
    8.485,
    8.656,
    8.827,
    8.998,
    9.169,
    9.340,
    9.576,
    9.821,
    10.065,
    10.310,
    10.554,
    10.799,
    11.043,
    11.288,
    11.532,
    11.777,
    12.021,
    12.266,
    12.510,
    12.755,
    12.999,
    13.244,
    13.488,
    13.733,
    13.977,
    14.222,
    14.466,
    14.711,
    14.955,
    15.200,
    15.444,
    15.689,
    15.933,
    16.178,
    16.422,
    16.667,
    16.911,
    17.156,
    17.892,
    18.159,
    18.426,
    18.693,
    18.960,
    19.227,
    19.494,
    19.761,
    20.028,
    20.295,
    20.562,
    20.829,
    21.096,
    21.363,
    21.630,
    21.897,
    22.164,
    22.431,
    22.698,
    22.965,
    23.232,
    23.499,
    23.766,
    24.033,
    24.300,
    24.567,
    24.834,
    25.101,
    25.368,
    25.635,
    25.902,
    26.169,
    26.436,
    26.703,
    26.970,
    27.237,
    27.504,
    27.784,
    28.065,
    28.345,
    28.626,
    29.156,
    29.437,
    29.717,
    29.998,
    30.278,
    30.559,
    30.839,
    31.120,
    31.400,
    31.681,
    31.961,
    32.242,
    32.522,
    32.803,
    33.083,
    33.364,
    33.644,
    33.925,
    34.205,
    34.486,
    34.766,
    35.047,
    35.327,
    35.608,
    35.888,
    36.169,
    36.449,
    36.730,
    37.010,
    37.291,
    37.571,
    37.852,
    38.132,
    38.413,
    38.693,
    38.974,
    39.254,
    39.535,
    39.815,
    40.096,
    40.376,
    40.657,
    40.937,
    41.218,
    41.498,
    41.779,
    42.059,
    42.340,
    42.620,
    42.901,
    43.181,
    43.589,
    44.038,
    44.486,
    44.935,
    45.384,
    45.833,
    46.282,
    46.730,
    47.179,
    47.628,
    48.077,
    48.526,
    51.212,
    51.915,
    52.369,
    52.822,
    53.276,
    53.730,
    54.183,
    54.637,
    55.090,
    55.544,
    55.998,
    56.451,
    56.905,
    57.358,
    57.812,
    58.266,
    58.719,
    59.173,
    59.626,
    60.080,
    60.534,
    60.987,
    61.441,
    61.894,
    62.348,
    62.802,
    63.255,
    63.709,
    64.162,
    64.616,
    65.070,
    65.523,
    65.977,
    66.430,
    66.884,
    67.338,
    67.791,
    68.245,
    68.698,
    69.152,
    69.606,
    70.059,
    70.513,
    70.966,
    71.420,
    71.874,
    72.327,
    72.781,
    73.234,
    73.688,
    74.142,
    74.595,
    75.049,
    75.502,
    75.956,
    76.410,
    76.863,
    77.317,
    77.770,
    78.224,
    78.678,
    79.131,
    79.585,
    80.038,
    80.492,
    80.946,
    81.399,
    81.853,
    82.306,
    82.760,
    83.214,
    83.667,
    84.121,
    84.574,
    85.028,
    85.482,
    85.935,
    86.389,
    86.842,
    87.296,
    87.750,
    88.203,
    88.657,
    89.110,
    89.564,
    90.018,
    90.471,
    90.925,
    91.378,
    91.832,
    92.286,
    92.739,
    93.193,
    93.646,
    94.100,
    94.554,
    95.007,
    95.461,
    95.914,
    96.368,
    96.822,
    97.275,
    97.729,
    98.182,
    98.636,
    99.090,
    99.543,
    99.997,
    100.450,
    100.904,
    101.358,
    101.811,
    102.265,
    102.718,
    103.172,
    103.626,
    104.079,
    104.533,
    104.986,
    105.440,
    105.894,
    106.347,
    106.801,
    107.254,
    107.708,
    108.174,
    108.642,
    109.110,
    109.578,
    110.046,
    110.514,
    110.982,
    111.450,
    111.918,
    112.386,
    112.854,
    113.322,
    113.790,
    114.258,
    114.726,
    115.194,
    115.662,
    116.130,
    116.598,
    117.066,
    117.534,
    118.002,
    118.470,
    118.938,
    119.406,
    119.874,
    120.342,
    120.810,
    121.278,
    121.746,
    122.214,
    122.682,
    123.150,
    123.618,
    124.086,
    124.554,
    125.022,
    125.490,
    125.958,
    126.426,
    126.894,
    127.362,
    127.830,
    128.298,
    128.964,
    129.647,
    130.329,
    131.012,
    131.694,
    132.377,
    133.059,
    133.742,
    134.424,
    135.107,
    135.789,
    136.472,
    137.154,
    137.837,
    138.519,
    139.202,
    139.884,
    140.567,
    141.249,
    141.932,
    142.614,
    143.297,
    143.979,
    144.662,
    145.344,
    146.027,
    146.709,
    147.392,
    148.074,
    148.757,
    149.439,
    150.122,
    150.804,
    151.487,
    152.169,
    152.852,
    153.534,
    154.217,
    154.899,
  ]
}
