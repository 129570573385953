import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
} from "../models/Model.User";
import { ProductInfo } from "../models/Model.Sale";
import ProductSelect from "./component/sale/ProductSelect";
import ProductEdit from "./component/sale/ProductEdit";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selected: ProductInfo;
};

class ProductStatistics extends Component<Props, State> {
  list = null;

  state = {
    selected: null,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    document.title = "판매제품관리";
  }

  onSelect = (product: ProductInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", product);
    this.setState({ selected: product });
  };

  onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    if (this.list) this.list.refresh();
    this.setState({ selected: null });
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({ selected: null });
  };

  onChange = (product?: ProductInfo) => {
    if (this.list) this.list.refresh(product);
    this.setState({ selected: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductStatistics.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonText slot="middle">제품관리</IonText>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <ProductSelect
                ref={(ref) => (this.list = ref)}
                onSelect={this.onSelect}
              />
            </div>
            {this.state.selected && (
              <div id="admin-sub-conainer" className="admin-container">
                <ProductEdit
                  product={this.state.selected}
                  onDone={this.onChange}
                />
              </div>
            )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductStatistics)
);
