
import * as events from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { EventState} from "./types";
import { EventInfo } from '../../models/Model.Event';
import {log, LogLevel} from '../../utils/LogUtil'
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';

const defaultState: EventState = {
  events : [],
};

export default (state = defaultState, action: ActionType<typeof events>): EventState => {
  switch (action.type) {

    case getType(events.fetchEvents.request):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchEvents.request", action, state);
      if(action.payload){
        let events:EventInfo[] = action.payload;
        return {...state, events};
      }else{
        return {...state, events:[]};
      }
      break;
    case getType(events.fetchEvents.success):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchEvents.success", action, state);
      if(action.payload){
        let events:EventInfo[] = action.payload;
        return {...state, events};
      }else{
        return {...state, events:[]};
      }
    case getType(events.fetchEvents.failure):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchEvents.failure", action, state);
      return {...state, events:[]};
    case getType(events.ignoreEvent):
      log(LogLevel.REDUX_BOARD, "Reducer: updateContent", action, state);
      let newEvent: EventInfo[] = {...state.events};
      for(let i = 0; i<newEvent.length; i++){
        if(newEvent[i].id == action.payload){
          newEvent[i].ignoredAt = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));
          newEvent[i].notToday = true;
        }
      }
      return {...state, events:newEvent};
    default:
      return state;
  }

  return state;
}
