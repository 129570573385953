import React, { Component } from 'react';
import './NotificationBell.css';
import { IonButton, IonIcon, IonBadge, IonLabel } from '@ionic/react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { LogLevel, log } from '../utils/LogUtil';

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onClick? : () => void;
  className? : string;
};

class NotificationBell extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  onClick = () => {
    if(this.props.onClick){
      this.props.onClick();
    }
    else{
      this.props.check();
      this.props.history.push('/main/notification');
    }
      
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "NotificaitionBell:render", this.props.count)
    let badge, buttonStyleclass="notification-bell-button-inactive";
    let count:any = this.props.count;
    if(count > 99){
      count = "99+";
    }
    if(count){
      badge = (
        <IonBadge color="notification-bell-count">
          {count}
        </IonBadge>
      )
      buttonStyleclass="notification-bell-button-active";
    }
    return (
      <div className={"notification-bell-container" + (this.props.className?" "+this.props.className:"")}>
        <IonButton color="notification-button" onClick={this.onClick}>
          <IonIcon class={buttonStyleclass} name="notifications" slot="icon-only"/>
        </IonButton>
        {badge}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  count: state.notification.notiCount,
});

const mapDispatchToProps = {
  check:() => actions.notification.check()
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationBell));
