import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AllInOneConsultTemplate from "../../components/templates/AllInOneConsult";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import * as API from "./../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobListType } from "../../store/jobpost/types";
import ABTestUtil from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";
import { MY_ADDRESS } from "./../../config.json";
import Spinner from "../../components/atoms/Spinner";
import { UIPopupType, UIServiceType } from "../../store/ui/types";
import { BoardContent } from "../../models/Model.Board";
import { callPhone } from "../../utils/UrlSchemeUtil";
import useBottomSheet from "../../hooks/useBottomSheet";
import ShareList from "../../components/organisms/ShareList";
import useShare from "../../hooks/useShare";
import { Flex } from "../../components/atoms/Layout";
import { COLOR_SYSTEM } from "../../components/design/design-system";
import Text from "../../components/atoms/Text";
import Icon from "../../components/atoms/Icon";
import { BoardType } from "../../store/board/types";
import { AllInOnePartnerInfo } from "../../models/Model.User";

const windowAny: any = window;

const Consult: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  try {
    const dispatch = useDispatch();
    const id = useMemo(() => queryString.parse(location.search).id as string, [
      location.search,
    ]);
    const me = useSelector((state: RootState) => state.user.me);
    const partner: AllInOnePartnerInfo = useSelector(
      (state: RootState) => state.allinone.partners[id]
    );
    const backKeyControl = useSelector(
      (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
    );
    // const bottomSheet = useBottomSheet();
    const { allInOnePartnerShare } = useShare({ allInOnePartner: partner }, me);

    const partnerList = useSelector(
      (state: RootState) => state.allinone.partnerList
    );
    const posts = useSelector(
      (state: RootState) =>
        state.board.boards[
          BoardType.ALLINONE_PARTNER_START | (partner ? partner.usersId : 0)
        ]
    );
    const contents = useSelector((state: RootState) => state.board.contents);
    const toastPopup = useSelector(
      (state: RootState) => state.ui.popups[UIPopupType.TOAST_POPUP]
    );

    useEffect(() => {
      if (backKeyControl)
        backKeyControl.setListener(() => {
          log(LogLevel.UI_LIFECYCLE, "Consult:back key callback");
          goBack();
        });
      log(
        LogLevel.UI_LIFECYCLE,
        "Consult:useEffect []",
        partnerList,
        partner,
        posts
      );
    }, []);

    const goBack = () => {
      // bottomSheet.close();

      history.goBack();
    };

    const onConsult = async (time: string, message: string) => {
      await fetchAPI(
        API.ALLINONE_CONSULT,
        "",
        null,
        { partnerId: partner.id, availableTime: time, questionText: message },
        getGlobal(GlobalKey.TOKEN)
      );
      toastPopup.show("상담예약이 완료되었습니다.");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER_CONSULT",
        "약사파트너스_파트너_상담신청_완료",
        {
          회사: partner ? partner.companyName : "",
        }
      );
      goBack();
    };

    return (
      <AllInOneConsultTemplate
        me={me}
        onGoBack={goBack}
        onConsult={onConsult}
        partner={partner}
      />
    );
  } catch (error) {
    console.error(error.message);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "Consult",
      errorMessage: error,
    });
  }
};

export default withRouter(Consult);
