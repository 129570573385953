

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import * as API from './../../../API.json';
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../../models/Model.Board';
import Truncate from 'react-truncate';
import { BannerInfo, EMPTY_BANNER, BannerType } from '../../../models/Model.Banner';
import { PollInfo, EMPTY_POLL, PollItemInfo, PollItemType } from '../../../models/Model.Poll';
import ReactQuill from 'react-quill';
import { RootState } from '../../../store';
import { UIPopupType } from '../../../store/ui/types';
import { connect } from 'react-redux';
import PollSimplePageItem from '../../../components/poll/PollSimplePageItem';

const AdminAPI = {        
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onChange?: (index: number, pollItem: PollItemInfo) => void;
  onDelete?: (index: number) => void;
  pollItem: PollItemInfo;
  index: number;
  key: string;
};

type State = {
  pollItem: PollItemInfo
  editing: boolean,
}

class PollItemEdit extends Component<Props, State> {
  static modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'color': []}, {'background': []}],
      ['link'],
      ['clean']
    ]
  }

  static formats = [
    'header', 'font', 'size',  'color', 'background',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'link', 'image', 'video'
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      pollItem: {},
      editing: false,
    }
  }

  onCancel = () => {
    this.setState({editing:false});
    
  }

  onEdit = () => {
    this.setState({pollItem:{...this.props.pollItem}, editing:true});
  }

  onSave = () => {
    let newPollItem: PollItemInfo = {
      id: this.state.pollItem.id,
      alias: this.state.pollItem.alias,
      pollId: this.state.pollItem.pollId,
      order: this.state.pollItem.order,
      page: this.state.pollItem.page,
      mandatory: this.state.pollItem.mandatory,
      question: this.state.pollItem.question,
      type: this.state.pollItem.type,
      description: this.state.pollItem.description,
    }

    switch(this.state.pollItem.type){
      case PollItemType.NUMBER_LEVEL_SELECTION : 
        newPollItem.candidates = this.state.pollItem.candidates;
        break;
      case PollItemType.SELECTION : 
        newPollItem.candidates = this.state.pollItem.candidates;
        if(this.state.pollItem.defaultsSelection && this.state.pollItem.defaultsSelection.length) newPollItem.defaultsSelection = this.state.pollItem.defaultsSelection;
        if(this.state.pollItem.needInput){
          newPollItem.needInput = this.state.pollItem.needInput;
          if(this.state.pollItem.inputMultiline) newPollItem.inputMultiline = this.state.pollItem.inputMultiline;
          if(this.state.pollItem.inputTitle) newPollItem.inputTitle = this.state.pollItem.inputTitle;
          if(this.state.pollItem.inputPrefix) newPollItem.inputMultiline = this.state.pollItem.inputMultiline;
          if(this.state.pollItem.inputPostfix) newPollItem.inputPostfix = this.state.pollItem.inputPostfix;
          if(this.state.pollItem.inputPlaceholder) newPollItem.inputPlaceholder = this.state.pollItem.inputPlaceholder;
        }

        break;
      case PollItemType.MULTI_SELECTION : 
        newPollItem.candidates = this.state.pollItem.candidates;
        if(this.state.pollItem.defaultsSelection && this.state.pollItem.defaultsSelection.length) newPollItem.defaultsSelection = this.state.pollItem.defaultsSelection;
        if(this.state.pollItem.needInput){
          newPollItem.needInput = this.state.pollItem.needInput;
          if(this.state.pollItem.inputMultiline) newPollItem.inputMultiline = this.state.pollItem.inputMultiline;
          if(this.state.pollItem.inputTitle) newPollItem.inputTitle = this.state.pollItem.inputTitle;
          if(this.state.pollItem.inputPrefix) newPollItem.inputMultiline = this.state.pollItem.inputMultiline;
          if(this.state.pollItem.inputPostfix) newPollItem.inputPostfix = this.state.pollItem.inputPostfix;
          if(this.state.pollItem.inputPlaceholder) newPollItem.inputPlaceholder = this.state.pollItem.inputPlaceholder;
        }

        break;
    }

    if(this.props.onChange)
      this.props.onChange(this.props.index, newPollItem);

    this.setState({editing:false});
  } 

  onDelete = () => {
    if(this.props.onDelete)
      this.props.onDelete(this.props.index);
  }

  render() {
    if(this.state.editing)
      return this.renderEditor();
    
    return this.renderViewer();
  }

  renderViewer() {
    if(!this.props.pollItem)
      return null;

    return (
      <div className="admin-card-container">
        <div className="admin-title">ID : {this.props.pollItem.id} - <span className="common-color-highlight">{this.props.pollItem.order}</span> - <span className="common-color-caution">{this.props.pollItem.alias}</span></div>
        <div className="admin-content-manage-button-container">
          <IonButton color="admin-button" onClick={this.onEdit}>
            수정
          </IonButton>  
          <IonButton color="admin-button" onClick={this.onDelete}>
            삭제
          </IonButton>  
        </div>  
        <div className="admin-title">Type : {PollItemType[this.props.pollItem.type]}</div>
        <PollSimplePageItem item={this.props.pollItem}/>
      </div>  
    );
  }

  renderEditor() {
    if(!this.state.pollItem)
      return null;
    
    let editDetail;
    switch(this.state.pollItem.type){
      case PollItemType.TEXT : 
        editDetail = this.renderEditDetailText();
        break;
      case PollItemType.NUMBER : 
        break;
      case PollItemType.SELECTION : 
        editDetail = this.renderEditDetailSelection();
        break;
      case PollItemType.NUMBER_LEVEL_SELECTION : 
        editDetail = this.renderEditDetailNumberSelection();
        break;
      case PollItemType.MULTI_SELECTION : 
        editDetail = this.renderEditDetailMutlSelection();
        break;
    }

    return (
      <div className="admin-card-container">
        <div className="admin-title">ID : {this.state.pollItem.id}</div>
        <div className="admin-content-manage-button-container">
          <IonButton color="admin-button" onClick={this.onSave}>
            적용
          </IonButton>  
          <IonButton color="admin-button" onClick={this.onCancel}>
            취소
          </IonButton>  
        </div>  
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">순서 : </div>
          <input className="admin-input" placeholder="순서" type="number" value={this.state.pollItem.order} onChange={(e) => this.setState({pollItem:{...this.state.pollItem, order:Number(e.target.value)}})}/>
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">페이지 : </div>
          <input className="admin-input" placeholder="페이지" type="number" value={this.state.pollItem.page} onChange={(e) => this.setState({pollItem:{...this.state.pollItem, page:Number(e.target.value)}})}/>
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">별칭 : </div>
          <IonInput class="admin-input" placeholder="Alias" value={this.state.pollItem.alias} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, alias:e.detail.value}})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <IonCheckbox checked={this.state.pollItem.mandatory} onClick={() => {this.setState({pollItem:{...this.state.pollItem, mandatory:!this.state.pollItem.mandatory}})}} />
          <div className="admin-title">답변 필수</div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className={"admin-toggle" + ((this.state.pollItem.type==PollItemType.TEXT)?" admin-toggle-selected":"")} onClick={(e) => this.setState({pollItem:{...this.state.pollItem, type:PollItemType.TEXT}})}>TEXT</div>
          <div className={"admin-toggle" + ((this.state.pollItem.type==PollItemType.SELECTION)?" admin-toggle-selected":"")} onClick={(e) => this.setState({pollItem:{...this.state.pollItem, type:PollItemType.SELECTION}})}>SELECTION</div>
          <div className={"admin-toggle" + ((this.state.pollItem.type==PollItemType.MULTI_SELECTION)?" admin-toggle-selected":"")} onClick={(e) => this.setState({pollItem:{...this.state.pollItem, type:PollItemType.MULTI_SELECTION}})}>M_SELECTION</div>
          <div className={"admin-toggle" + ((this.state.pollItem.type==PollItemType.NUMBER_LEVEL_SELECTION)?" admin-toggle-selected":"")} onClick={(e) => this.setState({pollItem:{...this.state.pollItem, type:PollItemType.NUMBER_LEVEL_SELECTION}})}>NUMBER_LEVEL</div>
          <div className={"admin-toggle" + ((this.state.pollItem.type==PollItemType.NUMBER)?" admin-toggle-selected":"")} onClick={(e) => this.setState({pollItem:{...this.state.pollItem, type:PollItemType.NUMBER}})}>NUMBER</div>
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">질문 : </div>
          <IonInput class="admin-input" placeholder="질문" value={this.state.pollItem.question} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, question:e.detail.value}})}/>
        </div>
        <div className="admin-title admin-margin-bottom">질문 상세 설명</div>
        <div className="common-container admin-editor common-scroll admin-margin-bottom">
          <ReactQuill
            theme={'snow'}
            onChange={(value, delta, source, editor) => {this.setState({pollItem:{...this.state.pollItem, description:value}})}}
            value={this.state.pollItem.description?this.state.pollItem.description:""}
            modules={PollItemEdit.modules}
            formats={PollItemEdit.formats}
            placeholder="질문 상세 설명"
          />
        </div>
        {editDetail}
      </div>

    );

    return null;
  }

  onAddCandidate = () => {
    let candidates
    if(this.state.pollItem && this.state.pollItem.candidates)
      candidates = [...this.state.pollItem.candidates];
    else 
      candidates = [];
    candidates.push("");
    this.setState({pollItem:{...this.state.pollItem, candidates}});
  }

  onChangeCandidate = (index, value) => {
    let candidates
    if(this.state.pollItem && this.state.pollItem.candidates)
      candidates = [...this.state.pollItem.candidates];
    else 
      candidates = [];
    candidates[index] = value;
    this.setState({pollItem:{...this.state.pollItem, candidates}});
  }

  onDeleteCandidate = (index) => {
    let candidates
    if(this.state.pollItem && this.state.pollItem.candidates)
      candidates = [...this.state.pollItem.candidates];
    else 
      candidates = [];
    candidates.splice(index, 1);
    this.setState({pollItem:{...this.state.pollItem, candidates}});
  }

  renderEditDetailSelection = () => {
    let candidates = this.state.pollItem.candidates;
    if(!candidates)
      candidates = [];
    let defaults = this.state.pollItem.defaultsSelection;
    if(!defaults)
      defaults = [];

    let inputDetail;
    if(this.state.pollItem.needInput){
      inputDetail = (
        <div className="common-container" style={{paddingLeft:"15px"}}>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">항목명 : </div>
            <IonInput class="admin-input" placeholder="항목명" value={this.state.pollItem.inputTitle?this.state.pollItem.inputTitle:""} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputTitle:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">예시 : </div>
            <IonInput class="admin-input" placeholder="예시" value={this.state.pollItem.inputPlaceholder?this.state.pollItem.inputPlaceholder:""} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputPlaceholder:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">prefix : </div>
            <IonInput class="admin-input" placeholder="답변prefix" value={this.state.pollItem.inputPrefix?this.state.pollItem.inputPrefix:""} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputPrefix:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">postfix : </div>
            <IonInput class="admin-input" placeholder="답변postfix" value={this.state.pollItem.inputPostfix?this.state.pollItem.inputPostfix:""} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputPostfix:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <IonCheckbox checked={this.state.pollItem.inputMultiline} onClick={() => {this.setState({pollItem:{...this.state.pollItem, inputMultiline:!this.state.pollItem.inputMultiline}})}} />
            <div className="admin-title">여러줄입력</div>
          </div>
        </div>
      );
    }
    return (
      <div className="common-container">
        <IonButton expand="full" onClick={this.onAddCandidate}>
          <IonIcon name="add"/>
        </IonButton>
        {candidates.map((item, index) => {
          return(
            <div key={index.toString()} className="common-container-row common-flex-align-center">
              <input type="radio" checked={defaults.includes(index)} onChange={() => {this.setState({pollItem:{...this.state.pollItem, defaultsSelection:[index]}})}}/>
              <IonInput class="admin-input" placeholder="후보" value={item} onIonChange={(e) => this.onChangeCandidate(index, e.detail.value)}/>
              <IonButton fill="clear" color="primary" onClick={() => this.onDeleteCandidate(index)}>
                <IonIcon name="close"/>
              </IonButton>
            </div>
          );
        })}
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <IonCheckbox checked={this.state.pollItem.needInput} onClick={() => {this.setState({pollItem:{...this.state.pollItem, needInput:!this.state.pollItem.needInput}})}} />
          <div className="admin-title">사용자입력</div>
        </div>
        {inputDetail}
      </div>
    )
  }

  renderEditDetailText = () => {
    return (
      <div className="common-container">
        <div className="common-container-row common-flex-align-center">
          <IonCheckbox checked={this.state.pollItem.inputMultiline} onClick={() => {this.setState({pollItem:{...this.state.pollItem, inputMultiline:!this.state.pollItem.inputMultiline}})}} />
          <div className="admin-title">여러줄입력</div>
        </div>
      </div>
    )
  }

  renderEditDetailMutlSelection = () => {
    let candidates = this.state.pollItem.candidates;
    if(!candidates)
      candidates = [];
    let defaults = this.state.pollItem.defaultsSelection;
    if(!defaults)
      defaults = [];

    let inputDetail;
    if(this.state.pollItem.needInput){
      inputDetail = (
        <div className="common-container" style={{paddingLeft:"15px"}}>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">항목명 : </div>
            <IonInput class="admin-input" placeholder="항목명" value={this.state.pollItem.inputTitle} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputTitle:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">예시 : </div>
            <IonInput class="admin-input" placeholder="예시" value={this.state.pollItem.inputPlaceholder} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputPlaceholder:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">prefix : </div>
            <IonInput class="admin-input" placeholder="답변prefix" value={this.state.pollItem.inputPrefix} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputPrefix:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">postfix : </div>
            <IonInput class="admin-input" placeholder="답변postfix" value={this.state.pollItem.inputPostfix} onIonChange={(e) => this.setState({pollItem:{...this.state.pollItem, inputPostfix:e.detail.value}})}/>
          </div>
          <div className="common-container-row common-flex-align-center">
            <IonCheckbox checked={this.state.pollItem.inputMultiline} onClick={() => {this.setState({pollItem:{...this.state.pollItem, inputMultiline:!this.state.pollItem.inputMultiline}})}} />
            <div className="admin-title">여러줄입력</div>
          </div>
        </div>
      );
    }
    return (
      <div className="common-container">
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">선택수 제한 : </div>
          <input className="admin-input" placeholder="순서" type="number" value={this.state.pollItem.maxSelect} onChange={(e) => this.setState({pollItem:{...this.state.pollItem, maxSelect:Number(e.target.value)}})}/>
        </div>
        <IonButton expand="full" onClick={this.onAddCandidate}>
          <IonIcon name="add"/>
        </IonButton>
        {candidates.map((item, index) => {
          return(
            <div key={index.toString()} className="common-container-row common-flex-align-center">
              <IonCheckbox checked={defaults.includes(index)} 
                onClick={() => {
                  let defaultsSelection = [];
                  if(this.state.pollItem && this.state.pollItem.defaultsSelection) 
                    defaultsSelection = [...this.state.pollItem.defaultsSelection];
                  let itsIndex = defaultsSelection.indexOf(index);
                  if(itsIndex == -1)
                    defaultsSelection.push(index);
                  else
                    defaultsSelection.splice(index,1);
                  this.setState({pollItem:{...this.state.pollItem, defaultsSelection}})
                }
              }/>
              <IonInput class="admin-input" placeholder="후보" value={item} onIonChange={(e) => this.onChangeCandidate(index, e.detail.value)}/>
              <IonButton fill="clear" color="primary" onClick={() => this.onDeleteCandidate(index)}>
                <IonIcon name="close"/>
              </IonButton>
            </div>
          );
        })}
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <IonCheckbox checked={this.state.pollItem.needInput} onClick={() => {this.setState({pollItem:{...this.state.pollItem, needInput:!this.state.pollItem.needInput}})}} />
          <div className="admin-title">사용자입력</div>
        </div>
        {inputDetail}
      </div>
    )
  }

  renderEditDetailNumberSelection = () => {
    let candidates = this.state.pollItem.candidates;
    if(!candidates)
      candidates = [];
    let defaults = this.state.pollItem.defaultsSelection;
    if(!defaults)
      defaults = [];

    return (
      <div className="common-container">
        <IonButton expand="full" onClick={this.onAddCandidate}>
          <IonIcon name="add"/>
        </IonButton>
        {candidates.map((item, index) => {
          return(
            <div key={index.toString()} className="common-container-row common-flex-align-center">
              <IonInput class="admin-input" placeholder="후보" value={item} onIonChange={(e) => this.onChangeCandidate(index, e.detail.value)}/>
              <IonButton fill="clear" color="primary" onClick={() => this.onDeleteCandidate(index)}>
                <IonIcon name="close"/>
              </IonButton>
            </div>
          );
        })}
      </div>
    )
  }
}


const mapStateToProps = (state: RootState) => ({
  waitingPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
}


export default connect(mapStateToProps, mapDispatchToProps)(PollItemEdit);