import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { SHADOW } from "../../design/effects";

interface PopupStyledProps {
  height?: string;
  maxHeight?: string;
}

const PopupWrapper = styled.div<PopupStyledProps>`
  max-width: 350px;
  width: 90%;
  height: ${({ height }) => (height ? height : "auto")};
  max-height: ${({ maxHeight }) => maxHeight};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 40px 20px 20px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  border-radius: 20px;
  ${SHADOW.get("4")};
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow-y: auto;

  & > * + * {
    margin-top: 40px;
  }
`;

const Shadow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000;
  margin-top: 0 !important;
`;

export { PopupWrapper, Shadow };
