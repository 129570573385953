import { action, createAction, createAsyncAction } from 'typesafe-actions';
import { UIActionTypes, UIPopupType, UIServiceType} from './types'

export const setPopup = createAction(UIActionTypes.UI_SET_POPUP, resolve =>
  (popup:object, type: UIPopupType) => resolve(popup, type)
);

export const setService = createAction(UIActionTypes.UI_SET_SERVICE, resolve =>
  (service:object, type: UIServiceType) => resolve(service, type)
);

export const registerUrlAction = createAction(UIActionTypes.UI_REGISTER_URL_ACTION_CALLBACK, resolve =>
  (name:string, callback:Function) => resolve(name, callback)
);

export const unregisterUrlAction = createAction(UIActionTypes.UI_UNREGISTER_URL_ACTION_CALLBACK, resolve =>
  (name:string) => resolve(name)
);