export interface UIState {
  popups: any;
  services: any;
  urlActions: any;
}

export enum UIPopupType {
  WAITING_POPUP,
  KEYWORD_POPUP,
  IMAGE_VIEWER_POPUP,
  DOCUMENT_VIEWER_POPUP,
  CONFIRM_POPUP,
  TOAST_POPUP,
  INFORMATION_POPUP,
  SELECT_POPUP,
}

export enum UIServiceType {
  BACK_CONTROLLER,
}

export enum UIActionTypes{
  UI_SET_POPUP = '@@ui/UI_SET_POPUP',
  UI_SET_SERVICE = '@@ui/UI_SET_SERVICE',
  UI_REGISTER_URL_ACTION_CALLBACK = '@@ui/UI_REGISTER_URL_ACTION_CALLBACK',
  UI_UNREGISTER_URL_ACTION_CALLBACK = '@@ui/UI_UNREGISTER_URL_ACTION_CALLBACK'

}
