import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from "@ionic/react";
import "./Boards.css";
import BoardList from "./../components/BoardList";
import SwipeableViews from "react-swipeable-views";
import { log, LogLevel } from "../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "./../utils/GlobalUtil";
import { BoardType, BoardSortType } from "../store/board/types";
import { UserLevel, UserWorkType } from "../models/Model.User";
import NotificationBell from "../components/NotificationBell";
import { BoardAttribute, BoardContent } from "../models/Model.Board";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import { UIPopupType } from "../store/ui/types";
import { sendKakaoInviteMessage } from "../utils/KakaoUtil";
import {
  Absolute,
  Fixed,
  Flex,
  Relative,
  Sticky,
} from "../components/atoms/Layout";
import FloatingButton from "../components/atoms/FloatingButton";
import withBottomSheet from "../hoc/withBottomSheet";
import Text from "../components/atoms/Text";
import { COLOR_SYSTEM } from "../components/design/design-system";
import Icon from "../components/atoms/Icon";
import { BottomSheetState } from "../store/modal/bottomsheet";
import Logo from "../assets/image/new_logo.png";
import Button from "../components/atoms/Button";
import TabBar from "../components/molecules/TabBar";
import Badge from "../components/atoms/Badge";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    bottomSheet?: {
      show: (options?: Partial<BottomSheetState>) => void;
      close: () => void;
    };
  };

type State = {
  loadingMessage: string;
  segmentIndex: number;
  needMoveToTop: boolean;
};

const windowAny: any = window;

class Boards extends Component<Props, State> {
  state = {
    loadingMessage: "",
    segmentIndex: 0,
    needMoveToTop: false,
    contentIonScroll: null,
  };
  headerRef = createRef<HTMLDivElement>();
  tabbarRef = null;
  mainRef = createRef<HTMLDivElement>();
  fabRef = createRef<HTMLDivElement>();
  contentScroll = null;
  contentIonScroll = null;
  contentList = null;
  // segment = null;
  // segmentButtons = [
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  // ];

  headerHide: boolean = false;

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "Boards:constructor");

    // this.ionFabRef = React.createRef<HTMLIonFabElement>();

    // setTimeout(() => {
    if (
      !this.props.board.boards[BoardType.LATEST_NOTICE] ||
      !this.props.board.boards[BoardType.LATEST_NOTICE].contents
    )
      this.props.loadBoard(BoardType.LATEST_NOTICE);
    // },300);

    if (
      !this.props.board.boards[BoardType.QNA] ||
      !this.props.board.boards[BoardType.QNA].contents
    )
      this.props.loadBoard(BoardType.QNA);

    let index = getGlobal(GlobalKey.MAIN_SEGMENT);
    if (index) this.state.segmentIndex = parseInt(index) - 1;
    else {
      index = this.props.board.boardList.indexOf(BoardType.UNION_TAB);
      if (index >= 0) this.state.segmentIndex = index;
    }
    this.props.visitBoard(this.props.board.boardList[index]);
    // AMPLITUDE
    let name: string = "";
    if (
      this.props.board.boards[
        this.props.board.boardList[this.state.segmentIndex]
      ]
    )
      name = this.props.board.boards[
        this.props.board.boardList[this.state.segmentIndex]
      ].name;
    else name = this.state.segmentIndex.toString();

    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SELECT_BOARD", "게시판선택", {
      게시판이름: name,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    log(
      LogLevel.UI_LIFECYCLE,
      "Boards:componentDidUpdate",
      prevProps,
      prevState
    );
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "Boards:componentDidMount");
    document.addEventListener("resume", this.onResume, false);

    this.moveTabXPos(this.state.segmentIndex);

    // setTimeout(() => {
    //   if (
    //     windowAny.cordova &&
    //     windowAny.cordova.plugins &&
    //     windowAny.cordova.plugins.Keyboard
    //   )
    //     windowAny.cordova.plugins.Keyboard.hideKeyboardAccessoryBar(false);
    // }, 500);
  }

  componentWillUnmount() {
    log(LogLevel.UI_LIFECYCLE, "Boards:componentWillUnmount");
    document.removeEventListener("resume", this.onResume);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.board.boardList != nextProps.board.boardList) {
      log(
        LogLevel.UI_LIFECYCLE,
        "Boards:shouldComponentUpdate update : 1",
        nextProps.board.boardList.length
      );
      return true;
    }

    if (this.state === nextState) {
      log(LogLevel.UI_LIFECYCLE, "Boards:shouldComponentUpdate skip");
      return false;
    }
    log(LogLevel.UI_LIFECYCLE, "Boards:shouldComponentUpdate update : 2");
    return true;
  }

  onMoveToRecommend = () => {
    this.handleChangeIndex(1);
  };

  updateSegment = (boardListIndex: number) => () => {
    console.log(
      "Boards: updateSegment",
      this.props.board.boardList[boardListIndex]
    );
    // let name: string = e.detail.value;
    const name = this.props.board.boardList[boardListIndex];
    const type: BoardType = name;
    const index = this.props.board.boardList.indexOf(type);

    log(LogLevel.UI_ACTION, "Boards:updateSegment", name, type, index);
    this.handleChangeIndex(index);
  };

  moveTabXPos = (index: number) => {
    const tabListXPositions = [
      0,
      0,
      70,
      140,
      200,
      260,
      320,
      407,
      508,
      620,
      728,
      829,
    ];
    const tabListEl = document.querySelector("nav > ul");

    if (tabListEl) {
      tabListEl.scrollTo({
        left: tabListXPositions[index],
      });
    }
  };

  handleChangeIndex = (index: number) => {
    console.log("Boards:handleChangeIndex", index, this.state.segmentIndex);
    this.onHeaderHide(false);
    setTimeout(() => {
      if (this.tabbarRef) this.tabbarRef.scrollToCurrentFocusItem();
    }, 0);

    if (index === this.state.segmentIndex) return;
    for (let i = 0; i < this.props.board.boardList.length; i++) {
      this.props.updateBoard(this.props.board.boardList[i]);
    }
    this.setState({ segmentIndex: index });
    setGlobal(GlobalKey.MAIN_SEGMENT, index + 1);
    this.props.visitBoard(this.props.board.boardList[index]);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SELECT_BOARD", "게시판선택", {
      게시판이름: this.props.board.boards[this.props.board.boardList[index]]
        .name,
    });

    // if(this.segment)
    //   log(LogLevel.UI_ACTION, "Boards:handleChangeIndex: segment", this.segment.scrollLeft, this.segment.clientWidth, this.segment.clientHeight, this.segment.offsetLeft);
    // for(let i=0; i<this.segmentButtons.length; i++){
    //   if(!this.segmentButtons[i])
    //     break;
    //   log(LogLevel.UI_ACTION, "Boards:handleChangeIndex: segmentButtons[i]",i, this.segmentButtons[i].scrollLeft, this.segmentButtons[i].clientWidth, this.segmentButtons[i].clientHeight, this.segmentButtons[i].offsetLeft);
    // }

    // if (this.segment) {
    //   this.segment.scrollTo(
    //     this.segmentButtons[index].offsetLeft +
    //       this.segmentButtons[index].clientWidth / 2 -
    //       this.segment.clientWidth / 2,
    //     0
    //   );
    // }
  };

  onResume = () => {
    if (this.props && this.props.reloadBoard) {
      this.props.reloadBoard(
        this.props.board.boardList[this.state.segmentIndex]
      );
    }

    // if(ABTestUtil.isTest()){
    //   this.props.toastPopup.show("Boards onResume: list updated " + this.props.board.boardList[this.state.segmentIndex])
    // }
  };

  presentArarm = () => {
    // this.setState(() => ({
    // }));
  };

  // onNew = () => {
  //   log(LogLevel.UI_LIFECYCLE, "Boards.onNew", this.props.board.boardList, this.state.segmentIndex);
  //   // this.props.history.push( `/new?category=${this.props.board.boardList[this.state.segmentIndex]}`);
  //   let categories = [
  //     { text: "학술 - 처방, 조제, 복약지도 관련", category: BoardType.QNA },
  //     { text: "모임 - 행사나 스터디 참가자 모집", category: BoardType.PARTY },
  //     { text: "약사장터 - 약사 간 판매/구매/교환/나눔 목적 ", category: BoardType.MARKET },
  //     { text: "약사라이프 - 고민상담, 직장생활 관련", category: BoardType.CONSULT_QNA },
  //     { text: "뉴스&아티클 - 읽어볼만한 자료 공유", category: BoardType.POST },
  //   ];
  //   if (this.props.me.level >= UserLevel.MANAGER) {
  //     categories.push({ text: "공지사항", category: BoardType.NOTICE });
  //   }
  //   this.props.selectPopup.show({
  //     title: "게시판 선택",
  //     candidates: categories,
  //     onSelect: this.onNewCategorySelected,
  //     needRadioButton: true,
  //   });
  // };

  // onNewCategorySelected = (index, item) => {
  //   if (item && item.category) {
  //     this.props.history.push(`/new?category=${item.category}`);
  //   }
  // };

  onNew = () => {
    if (this.headerHide) return;
    this.props.bottomSheet.show({
      title: {
        value: "게시판 선택",
        align: "left",
      },
      body: (
        <Flex direction="column" gap="23px" customStyle={{ marginTop: "23px" }}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              this.props.history.push(`/new?category=${BoardType.QNA}`);
            }}
          >
            <Flex gap="12px">
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                학술
              </Text>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                처방, 조제, 복약지도 관련
              </Text>
            </Flex>
            <Icon
              name="CaretRight"
              width={24}
              height={24}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              this.props.history.push(`/new?category=${BoardType.PARTY}`);
            }}
          >
            <Flex gap="12px">
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                모임
              </Text>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                행사나 스터디 참가자 모집
              </Text>
            </Flex>
            <Icon
              name="CaretRight"
              width={24}
              height={24}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              this.props.history.push(`/new?category=${BoardType.MARKET}`);
            }}
          >
            <Flex gap="12px">
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                약사장터
              </Text>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                약사 간 판매/구매/교환/나눔 목적
              </Text>
            </Flex>
            <Icon
              name="CaretRight"
              width={24}
              height={24}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              this.props.history.push(`/new?category=${BoardType.CONSULT_QNA}`);
            }}
          >
            <Flex gap="12px">
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                약사라이프
              </Text>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                고민상담, 직장생활 관련
              </Text>
            </Flex>
            <Icon
              name="CaretRight"
              width={24}
              height={24}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              this.props.history.push(`/new?category=${BoardType.POST}`);
            }}
          >
            <Flex gap="12px">
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                뉴스&아티클
              </Text>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                읽어볼만한 자료 공유
              </Text>
            </Flex>
            <Icon
              name="CaretRight"
              width={24}
              height={24}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              this.props.history.push(`/new?category=${BoardType.JVM}`);
            }}
          >
            <Flex gap="12px">
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                JVM
              </Text>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                JVM 관련 질문/답변/팁
              </Text>
            </Flex>
            <Icon
              name="CaretRight"
              width={24}
              height={24}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
          </Flex>
          {this.props.me.level >= UserLevel.MANAGER && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              onClick={() => {
                this.props.history.push(`/new?category=${BoardType.NOTICE}`);
              }}
            >
              <Flex>
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[700]}
                >
                  공지사항
                </Text>
              </Flex>
              <Icon
                name="CaretRight"
                width={24}
                height={24}
                color={COLOR_SYSTEM.get("Gray")[200]}
              />
            </Flex>
          )}
          {this.props.me.level >= UserLevel.MANAGER && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              onClick={() => {
                this.props.history.push(`/new?category=${BoardType.ALLINONE}`);
              }}
            >
              <Flex>
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[700]}
                >
                  약사파트너스
                </Text>
              </Flex>
              <Icon
                name="CaretRight"
                width={24}
                height={24}
                color={COLOR_SYSTEM.get("Gray")[200]}
              />
            </Flex>
          )}
        </Flex>
      ),
    });
  };

  setNeedMoveToTop = (next: boolean) => {
    this.setState({ needMoveToTop: next });
  };

  onInvite = () => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "INVITE", "초대하기", {
      위치: "게시판",
    });
    sendKakaoInviteMessage(this.props.me);
  };

  onHeaderHide = (hide: boolean) => {
    log(
      LogLevel.UI_LIFECYCLE,
      "Boards.onHeaderHide",
      hide,
      this.headerRef.current.clientHeight
    );
    if (this.tabbarRef) this.tabbarRef.closeAccordion();

    if (this.headerHide == hide) return;

    this.headerHide = hide;
    if (hide) {
      // scroll down
      this.headerRef.current.style.transform = `translateY(-108px)`;
      this.mainRef.current.style.transform = `translateY(-108px)`;
      this.mainRef.current.style.height = "calc(100vh - 56px)";
      if (this.fabRef.current) this.fabRef.current.style.display = "none";
    } else {
      // scroll up
      this.headerRef.current.style.transform = `translateY(0px)`;
      this.mainRef.current.style.transform = `translateY(0px)`;
      this.mainRef.current.style.height = "calc(100vh - 164px)";
      if (this.fabRef.current) this.fabRef.current.style.display = "block";
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "Boards.render", this.props);
    let admin;
    if (this.props.me.level >= UserLevel.MANAGER) {
      admin = (
        // <IonButton class="boards-toolbar-admin" onClick={() => this.props.history.push("/admin")}>
        //   <IonIcon name="cog" slot="icon-only" />
        // </IonButton>
        <Button
          color="Quaternary"
          size="Medium"
          variant="Ghost"
          type="Icon"
          icon="SettingFilled"
          onClick={() => this.props.history.push("/admin")}
        />
      );
    }

    // let fabContent = <IonIcon class="boards-fab-icon" src={composeIcon}/>;
    let fab;
    let currentBoard = this.props.board.boards[
      this.props.board.boardList[this.state.segmentIndex]
    ];
    if (this.props.me.workType != UserWorkType.UNDERGRADUATE && currentBoard) {
      let currentAttribute = currentBoard.attribute;
      if (currentAttribute.includes(BoardAttribute.UI_LIST_FAB)) {
        fab = (
          <Fixed
            ref={this.fabRef}
            bottom="16px"
            left="50%"
            zIndex={100}
            customStyle={{ transform: "translateX(-50%)" }}
          >
            <FloatingButton
              size="Large"
              color="Primary"
              type="IconWithText"
              icon="Pencil"
              left
              onClick={this.onNew}
            >
              글쓰기
            </FloatingButton>
          </Fixed>
        );
      }
    }

    return (
      <>
        {/* <IonHeader class="boards-header" no-border>
          <IonToolbar color="boards-toolbar">
            <IonSegment
              ref={(ref) => (this.segment = ref)}
              mode="md"
              slot="start"
              color="boards-segment"
              onIonChange={this.updateSegment}
              scrollable={true}
            >
              {this.props.board.boardList.map((item, index) => {
                log(LogLevel.UI_LIFECYCLE, "Boards Loading segment", item, index);
                let newMark;
                if (ABTestUtil.isTest(ABTestFeature.UI_BOARD_UPDATED)) {
                  // log(LogLevel.UI_LIFECYCLE, "Boards:render", item, index, this.state.segmentIndex, this.props.board.boards[item]);

                  if (
                    this.state.segmentIndex != index &&
                    this.props.board.boards &&
                    this.props.board.boards[item] &&
                    this.props.board.boards[item].sort == BoardSortType.DEFAULT &&
                    this.props.board.boards[item].contents &&
                    this.props.board.boards[item].contents.length
                  ) {
                    let lastContent: BoardContent = this.props.board.contents[
                      this.props.board.boards[item].contents[0].id
                    ];
                    // log(LogLevel.UI_LIFECYCLE, "Boards:render N checker", index, "Content", lastContent.createdAt, "Visited", (this.props.board.boards[item].lastVisitedTime?new Date(this.props.board.boards[item].lastVisitedTime).toISOString():"None"), lastContent, this.props.board.boards[item]);
                    if (
                      this.props.board.boards[item].lastVisitedTime &&
                      lastContent.createdAt &&
                      lastContent.createdAt > new Date(this.props.board.boards[item].lastVisitedTime).toISOString()
                    ) {
                      newMark = <div className="boards-segment-button-new">N</div>;
                    }
                  }
                }

                return (
                  <IonSegmentButton
                    ref={(ref) => (this.segmentButtons[index] = ref)}
                    key={index.toString()}
                    mode="md"
                    class="boards-segment-button"
                    value={item.toString()}
                    checked={this.state.segmentIndex == index}
                  >
                    <div className="boards-segment-button-text">{this.props.board.boards[item].name}</div>
                    {newMark}
                  </IonSegmentButton>
                );
              })}
            </IonSegment>

            <IonButtons slot="end">
              {admin}
              <NotificationBell className="boards-bell" />
            </IonButtons>
          </IonToolbar>
        </IonHeader> */}

        <Sticky
          ref={this.headerRef}
          as="header"
          top="0"
          left="0"
          customStyle={{
            zIndex: 100,
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
            // paddingTop: "env(safe-area-inset-top)",
            paddingTop: "constant(safe-area-inset-top)",
            transition: "transform linear 0.2s",
          }}
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            customStyle={{ height: "60px", padding: "0 4px 0 20px" }}
          >
            <img src={Logo} alt="" width={100} height={24} />
            <Flex alignItems="center">
              {admin}
              <Relative>
                <Button
                  color="Quaternary"
                  size="Medium"
                  variant="Ghost"
                  type="Icon"
                  icon="Bell Filled"
                  onClick={() => {
                    this.props.check();
                    this.props.history.push("/main/notification");
                  }}
                />
                {this.props.count > 0 && (
                  <Absolute top="0" right="4px">
                    <Badge color="Red" />
                  </Absolute>
                )}
              </Relative>
            </Flex>
          </Flex>
          <TabBar
            ref={(ref) => (this.tabbarRef = ref)}
            size="Medium"
            type="Underlined"
            selectedIndex={this.state.segmentIndex}
            items={this.props.board.boardList.map((board, index) => ({
              badge: false,
              onClick: this.updateSegment(index),
              text: this.props.board.boards[board].name,
              id: board.toString(),
            }))}
            scrollable
            enableAccordion
          />
        </Sticky>

        {/* <IonContent forceOverscroll={false} scrollY={false}> */}
        <Absolute
          as="main"
          top="108px"
          customStyle={{
            width: "100%",
            height: "calc(100vh - 162px)",
            backgroundColor: COLOR_SYSTEM.get("Gray")[10],
            transform: "translateZ(0)",
            transition: "transform linear 0.2s",

            "& > div:last-child": {
              height: "100%",
            },
          }}
          ref={this.mainRef}
        >
          <SwipeableViews
            className="boards-swipe"
            enableMouseEvents
            hysteresis={0.1}
            index={this.state.segmentIndex}
            onChangeIndex={this.handleChangeIndex}
          >
            {this.props.board.boardList.map((item, index) => (
              <BoardList
                key={index.toString()}
                selected={index === this.state.segmentIndex}
                listType={item}
                onHeaderHide={this.onHeaderHide}
                onMoveToRecommend={this.onMoveToRecommend}
                // headerRef={this.headerRef}
                // mainRef={this.mainRef}
                // needMoveToTop={this.state.needMoveToTop}
                // setNeedMoveToTop={this.setNeedMoveToTop}
              />
            ))}
          </SwipeableViews>
        </Absolute>
        {/* </IonContent> */}
        {/* <IonFab slot="fixed" vertical="bottom" horizontal="start">
          <IonFabButton color="boards-fab-invite" onClick={this.onInvite}>
            <IonLabel class="boards-fab-invite-text">
              친구
              <br />
              초대
            </IonLabel>
          </IonFabButton>
        </IonFab> */}
        {fab}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  board: state.board,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  selectPopup: state.ui.popups[UIPopupType.SELECT_POPUP],
  count: state.notification.notiCount,
});

const mapDispatchToProps = {
  logOutUser: () => actions.user.logout(),
  loadBoard: (board: BoardType) => actions.board.getBoard(board, 15),
  updateBoard: (board: BoardType) => actions.board.getBoardUpdates(board),
  reloadBoard: (board: BoardType) => actions.board.getBoardReloadTop10(board),
  visitBoard: (board: BoardType) => actions.board.visitBoard(board),
  check: () => actions.notification.check(),
  refreshBoards: (board: BoardType) => actions.board.refreshBoards(board),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withBottomSheet(Boards))
);
