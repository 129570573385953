import { useMemo } from "react";
import { JobOfferInfo, JobOfferName } from "../models/Model.JobPost";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import { fetchAPI } from "../utils/APIUtil";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import useClipboard from "./useClipboard";
import API from "../API.json";
import { MY_ADDRESS } from "../config.json";
import {
  AllInOnePartnerInfo,
  CompanyTypeName,
  UserInfo,
} from "../models/Model.User";
import { BoardContent } from "./../models/Model.Board";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import BoardUtil from "../utils/BoardUtil";
import StringUtil from "../utils/StringUtil";
import { log, LogLevel } from "../utils/LogUtil";
import { useDispatch } from "react-redux";
import { updateContent } from "../store/board/actions";

const windowAny: any = window;

type Post = {
  jobPost?: JobOfferInfo;
  board?: BoardContent;
  allInOnePartner?: AllInOnePartnerInfo;
};

const useShare = (post: Post, me: UserInfo) => {
  const dispatch = useDispatch();
  const { copyToClipboard } = useClipboard();
  const isBiz = useMemo(() => window.location.pathname.includes("biz"), []);

  const jobPostShare = {
    handleClickKakao: () => {
      if (!post.jobPost || !post.jobPost.pharmacy) return;

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_상세보기_공유하기",
        {
          공고id: post.jobPost.id,
          type: "카카오",
        }
      );

      fetchAPI(
        API.JOBPOST_SHARE,
        post.jobPost.id,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      const os = getGlobal(GlobalKey.OS);
      const title = isBiz
        ? `${post.jobPost.companyName || post.jobPost.pharmacy.name}(${
            post.jobPost.companyRegion
          }) 약사 구인 추천`
        : `${post.jobPost.pharmacy.region} ${
            JobOfferName[post.jobPost.workType]
          } 약사 구인 추천`;
      const description = isBiz
        ? `${me.name} 약사님이 추천한 구인공고를 지금 확인해보세요 ^^`
        : `${me.name} 약사님이 추천한 약국 구인공고를 지금 확인해보세요 ^^`;
      const executionParams = isBiz
        ? `type=jobpost&bizOfferId=${post.jobPost.id}`
        : `type=jobpost&offerId=${post.jobPost.id}`;

      if (!os || os === "browser") {
        const feed = {
          objectType: "feed",
          content: {
            title,
            description,
            imageUrl: `${MY_ADDRESS}/images/ymyd_jobpost_kakao_share.jpg`,
            link: {
              androidExecParams: executionParams,
              iosExecParams: executionParams,
            },
          },
          social: {
            likeCount: post.jobPost.likeCnt,
            viewCount: post.jobPost.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecParams: executionParams,
                iosExecParams: executionParams,
              },
            },
          ],
        };

        try {
          windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
        } catch (e) {}
        windowAny.Kakao.Link.sendDefault(feed);
      } else if (windowAny.KakaoCordovaSDK) {
        const feed = {
          objectType: "feed",
          content: {
            title,
            desc: description,
            imageURL: MY_ADDRESS + "/images/ymyd_jobpost_kakao_share.jpg",
            link: {
              androidExecutionParams: executionParams,
              iosExecutionParams: executionParams,
            },
          },
          social: {
            likeCount: post.jobPost.likeCnt,
            viewCount: post.jobPost.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecutionParams: executionParams,
                iosExecutionParams: executionParams,
              },
            },
          ],
        };
        windowAny.KakaoCordovaSDK.sendLinkFeed(
          feed,
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share success",
              res,
              feed
            );
          },
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share fail",
              res,
              feed
            );
          }
        );
      }
    },
    handleClickSMS: () => {
      const title = isBiz
        ? `${post.jobPost.companyName || post.jobPost.pharmacy.name}(${
            post.jobPost.companyRegion
          }) 약사 구인 추천`
        : `${post.jobPost.pharmacy.region} ${
            JobOfferName[post.jobPost.workType]
          } 약사 구인 추천`;
      const description = isBiz
        ? `${me.name} 약사님이 추천한 구인공고를 지금 확인해보세요 ^^`
        : `${me.name} 약사님이 추천한 약국 구인공고를 지금 확인해보세요 ^^`;
      const isApple =
        navigator.userAgent.toLocaleLowerCase().includes("iphone") ||
        navigator.userAgent.toLocaleLowerCase().includes("mac");

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_상세보기_공유하기",
        {
          공고id: post.jobPost.id,
          type: "문자메세지",
        }
      );
      window.location.href = `sms:${
        isApple ? "&" : "?"
      }body=${title}%0D%0A${description}%0D%0A${window.location.href}`;

      // window.location.href = `sms:${isApple ? "&" : "?"}body=${title}%0D%0A${description}%0D%0A${window.location.href}`;
    },
    handleClickLink: () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_상세보기_공유하기",
        {
          공고id: post.jobPost.id,
          type: "링크복사",
        }
      );

      copyToClipboard(window.location.href)();
    },
  };

  const allInOnePartnerShare = {
    handleClickKakao: () => {
      if (!post.allInOnePartner || !post.allInOnePartner) return;
      log(
        LogLevel.UI_ACTION,
        "allInOnePartnerShare:handleClickKakao:",
        post.allInOnePartner
      );

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SHARE",
        "약사파트너스_업체_상세보기_공유하기",
        {
          회사: post.allInOnePartner.companyName,
          type: "카카오",
        }
      );

      // fetchAPI(API.JOBPOST_SHARE, post.jobPost.id, null, null, getGlobal(GlobalKey.TOKEN));
      const os = getGlobal(GlobalKey.OS);
      const title = `${
        post.allInOnePartner.companyProfileName ||
        post.allInOnePartner.companyName
      }(${post.allInOnePartner.companyRegion}) 업체 추천`;
      const description = `${me.name} 약사님이 추천한 ${
        CompanyTypeName[post.allInOnePartner.companyType]
      } 업체를 지금 확인해보세요 ^^`;
      const executionParams = `type=allinone&partnerId=${post.allInOnePartner.id}`;

      if (!os || os === "browser") {
        const feed = {
          objectType: "feed",
          content: {
            title,
            description,
            imageUrl: `${MY_ADDRESS}/images/ymydgi_20220719.jpg`,
            link: {
              androidExecParams: executionParams,
              iosExecParams: executionParams,
            },
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecParams: executionParams,
                iosExecParams: executionParams,
              },
            },
          ],
        };

        try {
          windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
        } catch (e) {}
        windowAny.Kakao.Link.sendDefault(feed);
      } else if (windowAny.KakaoCordovaSDK) {
        const feed = {
          objectType: "feed",
          content: {
            title,
            desc: description,
            imageURL: MY_ADDRESS + "/images/ymydgi_20220719.jpg",
            link: {
              androidExecutionParams: executionParams,
              iosExecutionParams: executionParams,
            },
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecutionParams: executionParams,
                iosExecutionParams: executionParams,
              },
            },
          ],
        };
        windowAny.KakaoCordovaSDK.sendLinkFeed(
          feed,
          (res) => {
            console.log(
              "allInOnePartnerShare: doKakaoShare: Kakao share success",
              res,
              feed
            );
          },
          (res) => {
            console.log(
              "allInOnePartnerShare: doKakaoShare: Kakao share fail",
              res,
              feed
            );
          }
        );
      }
    },
    handleClickSMS: () => {
      const title = `${
        post.allInOnePartner.companyProfileName ||
        post.allInOnePartner.companyName
      }(${post.allInOnePartner.companyRegion}) 업체 추천`;
      const description = `${me.name} 약사님이 추천한 ${
        CompanyTypeName[post.allInOnePartner.companyType]
      } 업체를 지금 확인해보세요 ^^`;
      const isApple =
        navigator.userAgent.toLocaleLowerCase().includes("iphone") ||
        navigator.userAgent.toLocaleLowerCase().includes("mac");

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SHARE",
        "약사파트너스_업체_상세보기_공유하기",
        {
          회사: post.allInOnePartner.companyName,
          type: "문자메세지",
        }
      );
      window.location.href = `sms:${
        isApple ? "&" : "?"
      }body=${title}%0D%0A${description}%0D%0A${window.location.href}`;

      // window.location.href = `sms:${isApple ? "&" : "?"}body=${title}%0D%0A${description}%0D%0A${window.location.href}`;
    },
    handleClickLink: () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SHARE",
        "약사파트너스_업체_상세보기_공유하기",
        {
          회사: post.allInOnePartner.companyName,
          type: "링크복사",
        }
      );

      copyToClipboard(window.location.href)();
    },
  };

  const boardShare = {
    handleClickKakao: async () => {
      // let post.board = this.props.contents[this.props.id];
      // if (!post.board) {
      //   post.board = this.props.post.board;
      // }

      if (!post.board) {
        return;
      }

      let webUrl;
      let result = await fetchAPI(
        API.ACTION_SHARE,
        post.board.id,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      if (!!ABTestUtil.isTest(ABTestFeature.DISABLE_OPEN_PAGE_SHARE)) {
        if (result && !result.error) {
          webUrl = MY_ADDRESS + "/open?id=" + result.data;
        }
      }

      post.board.sharedCnt = post.board.sharedCnt + 1;
      post.board.shared = true;
      //console.log("updateContent main", this.content);
      BoardUtil.checksum(post.board);
      BoardUtil.countAllLike(post.board);
      dispatch(updateContent(post.board));

      let os = getGlobal(GlobalKey.OS);

      let image;
      if (
        post.board.cards &&
        post.board.cards.length &&
        post.board.cards[0].url
      )
        image = StringUtil.convertFilePath(post.board.cards[0].url);
      else if (post.board.image)
        image = StringUtil.convertFilePath(post.board.image);
      else image = MY_ADDRESS + "/images/ymydgi_20220719.jpg";

      if (!ABTestUtil.isTest(ABTestFeature.DISABLE_OPEN_PAGE_SHARE) && webUrl) {
        if (!os || os === "browser") {
          let feed = {
            objectType: "feed",
            content: {
              title: post.board.subject,
              description: post.board.bodyText.substring(0,20),
              imageUrl: image,
              link: {
                mobileWebUrl: webUrl,
                webUrl: webUrl,
                androidExecParams: "type=view&id=" + post.board.id,
                iosExecParams: "type=view&id=" + post.board.id,
              },
            },
            social: {
              likeCount: post.board.likeCntAll,
              commentCount: post.board.replyCnt,
              viewCount: post.board.viewCnt,
            },
            buttons: [
              {
                title: "Web에서 보기",
                link: {
                  mobileWebUrl: webUrl,
                  webUrl: webUrl,
                },
              },
              {
                title: "App에서 보기",
                link: {
                  androidExecParams: "type=view&id=" + post.board.id,
                  iosExecParams: "type=view&id=" + post.board.id,
                },
              },
            ],
          };
          //console.log("BoardListItem: doKakaoShare", feed)
          try {
            windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
          } catch (e) {
            log(LogLevel.UI_EXCEPTION, e);
          }
          windowAny.Kakao.Link.sendDefault(feed);
        } else if (windowAny.KakaoCordovaSDK) {
          let feed = {
            objectType: "feed",
            content: {
              title: post.board.subject,
              desc: post.board.bodyText.substring(0,20),
              imageURL: image,
              link: {
                mobileWebURL: webUrl,
                webURL: webUrl,
                androidExecutionParams: "type=view&id=" + post.board.id,
                iosExecutionParams: "type=view&id=" + post.board.id,
              },
            },
            social: {
              likeCount: post.board.likeCntAll,
              commentCount: post.board.replyCnt,
              viewCount: post.board.viewCnt,
            },
            buttons: [
              {
                title: "Web에서 보기",
                link: {
                  mobileWebURL: webUrl,
                  webURL: webUrl,
                },
              },
              {
                title: "App에서 보기",
                link: {
                  androidExecutionParams: "type=view&id=" + post.board.id,
                  iosExecutionParams: "type=view&id=" + post.board.id,
                },
              },
            ],
          };
          windowAny.KakaoCordovaSDK.sendLinkFeed(
            feed,
            (res) => {
              console.log(
                "BoardListItem: doKakaoShare: Kakao share success",
                res,
                feed
              );
            },
            (res) => {
              console.log(
                "BoardListItem: doKakaoShare: Kakao share fail",
                res,
                feed
              );
            }
          );
        }
      } else {
        if (!os || os === "browser") {
          let feed = {
            objectType: "feed",
            content: {
              title: post.board.subject,
              description: post.board.bodyText.substring(0,20),
              imageUrl: image,
              link: {
                androidExecParams: "type=view&id=" + post.board.id,
                iosExecParams: "type=view&id=" + post.board.id,
              },
            },
            social: {
              likeCount: post.board.likeCnt,
              commentCount: post.board.replyCnt,
              viewCount: post.board.viewCnt,
            },
            buttons: [
              {
                title: "App에서 보기",
                link: {
                  androidExecParams: "type=view&id=" + post.board.id,
                  iosExecParams: "type=view&id=" + post.board.id,
                },
              },
            ],
          };
          //console.log("BoardListItem: doKakaoShare", feed)
          try {
            windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
          } catch (e) {
            log(LogLevel.UI_EXCEPTION, e);
          }
          windowAny.Kakao.Link.sendDefault(feed);
        } else if (windowAny.KakaoCordovaSDK) {
          let feed = {
            objectType: "feed",
            content: {
              title: post.board.subject,
              desc: post.board.bodyText.substring(0,20),
              imageURL: image,
              link: {
                androidExecutionParams: "type=view&id=" + post.board.id,
                iosExecutionParams: "type=view&id=" + post.board.id,
              },
            },
            social: {
              likeCount: post.board.likeCnt,
              commentCount: post.board.replyCnt,
              viewCount: post.board.viewCnt,
            },
            buttons: [
              {
                title: "App에서 보기",
                link: {
                  androidExecutionParams: "type=view&id=" + post.board.id,
                  iosExecutionParams: "type=view&id=" + post.board.id,
                },
              },
            ],
          };
          windowAny.KakaoCordovaSDK.sendLinkFeed(
            feed,
            (res) => {
              console.log(
                "boardShare: doKakaoShare: Kakao share success",
                res,
                feed
              );
            },
            (res) => {
              console.log(
                "boardShare: doKakaoShare: Kakao share fail",
                res,
                feed
              );
            }
          );
        }
      }

      // AMPLITUDE
      let body;
      if (post.board.subject) body = post.board.subject.substring(0, 10);
      else if (post.board.bodyText) body = post.board.bodyText.substring(0, 10);
      let name = post.board.usersNickname || post.board.usersCustomName;
      // if (post.board.usersNickname) name = post.board.usersNickname
      // else name = post.board.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_SHARE",
        "게시물_공유",
        {
          게시물id: post.board.id,
          게시물내용: body,
          글쓴이id: post.board.usersId,
          글쓴이닉네임: name,
          게시판이름: post.board.boardsCategoriesName,
          type: "카카오",
        }
      );
    },
    handleClickSMS: () => {
      const isApple =
        navigator.userAgent.toLocaleLowerCase().includes("iphone") ||
        navigator.userAgent.toLocaleLowerCase().includes("mac");

      let body;
      if (post.board.subject) body = post.board.subject.substring(0, 30) + "%0D%0A";
      if (post.board.bodyText) body += post.board.bodyText.substring(0, 30) + "%0D%0A";
      let name = post.board.usersNickname || post.board.usersCustomName;
      if (body === undefined) body = window.location.href 
      body += window.location.href

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_SHARE",
        "게시물_공유",
        {
          게시물id: post.board.id,
          게시물내용: body,
          글쓴이id: post.board.usersId,
          글쓴이닉네임: name,
          게시판이름: post.board.boardsCategoriesName,
          type: "문자메세지",
        }
      );

      window.location.href = `sms:${
        isApple ? "&" : "?"
      }body=${body}`;
    },
    handleClickLink: () => {
      let body;

      if (post.board.subject) body = post.board.subject.substring(0, 30) + " ";
      if (post.board.bodyText) body += post.board.bodyText.substring(0, 30) + " ";
      let name = post.board.usersNickname || post.board.usersCustomName;
      if (body === undefined) body = window.location.href 
      body += window.location.href
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_SHARE",
        "게시물_공유",
        {
          게시물id: post.board.id,
          게시물내용: body,
          글쓴이id: post.board.usersId,
          글쓴이닉네임: name,
          게시판이름: post.board.boardsCategoriesName,
          type: "링크복사",
        }
      );

      copyToClipboard(body)();
    },
  };

  return { jobPostShare, boardShare, allInOnePartnerShare };
};

export default useShare;
