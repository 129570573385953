import React, { useState } from 'react';
import { FC } from 'react'
import Text from '../../atom/Text';
import TextButton from '../../atom/TextButton';
import Body from '../../molecule/Body';
import Footer from '../../molecule/Footer';
import Header from '../../molecule/Header';
import './style.scss';
import './../../../Common.css';
import Icon from '../../atom/Icon';
import COLOR from '../../_design/color';
import IconButton from '../../atom/IconButton';

interface Props {
  onDone?: (agreeMarketing: boolean) => void;
  onClickContractDetail?: () => void;
  onClickPrivacyDetail?: () => void;
  onClickMarketingDetail?: () => void;
  hasNext?: boolean;
}

const UserContractUpdate: FC<Props> = ({
  onDone,
  onClickContractDetail,
  onClickPrivacyDetail,
  onClickMarketingDetail,
  hasNext,
}) => {
  const [all, setAll] = useState<boolean>(false);
  const [contract, setContract] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [marketing, setMarketing] = useState<boolean>(false);
  
  const onClickAll = () => {
    if(all){
      setAll(false);
      setContract(false);
      setPrivacy(false);
      setMarketing(false);
    }else{
      setAll(true);
      setContract(true);
      setPrivacy(true);
      setMarketing(true);
    }
  }
  return (
    <>
      <Body hasHeader={false}>
        <div className="common-block" style={{marginTop:"64px"}}>
          <div style={{marginBottom:"12px"}}>
            <Text type="H1" color="Secondary">약문약답 서비스 약관이업데이트 되었어요.</Text>
          </div>
          <Text type="Body1" color="Gray3">서비스 이용을 위해 아래 약관에 동의해주세요.</Text>
        </div>
        <div className="common-block">
          <div className={"contract-update-item-all " + (all?"checked":"unchecked")} onClick={onClickAll}>
            <div style={{marginRight:"12px"}}>
              <Icon name="Check Circle" width={22} height={22} fill={all?COLOR.Primary:COLOR.Gray5}/>
            </div>
            <Text type="Headline" color={all?"Primary":"Black"}>모두 동의</Text>
          </div>
          <div className="contract-update-item" onClick={() => {setAll(false); setContract(!contract)}}>
            <div style={{marginRight:"12px", height:"22px"}}>
              <Icon name="Check Circle" width={22} height={22} fill={contract?COLOR.Primary:COLOR.Gray5}/>
            </div>
            <div className="common-flex-grow" style={{height:"24px"}}>
              <Text type="Headline" color={contract?"Gray1":"Gray4"}>이용 약관</Text> <Text type="Headline" color="Red"><sup>*</sup></Text>
            </div>
            <IconButton variant="Ghost" size="M" iconName="CaretRight" onClick={(e) => {e.stopPropagation(); if(onClickContractDetail) onClickContractDetail();}}/>
          </div>
          <div className="contract-update-item" onClick={() => {setAll(false); setPrivacy(!privacy)}}>
            <div style={{marginRight:"12px", height:"22px"}}>
              <Icon name="Check Circle" width={22} height={22} fill={privacy?COLOR.Primary:COLOR.Gray5}/>
            </div>
            <div className="common-flex-grow" style={{height:"24px"}}>
              <Text type="Headline" color={privacy?"Gray1":"Gray4"}>개인정보방침</Text> <Text type="Headline" color="Red"><sup>*</sup></Text>
            </div>
            <IconButton variant="Ghost" size="M" iconName="CaretRight" onClick={(e) => {e.stopPropagation(); if(onClickPrivacyDetail) onClickPrivacyDetail();}}/>
          </div>
          <div className="contract-update-item" onClick={() => {setAll(false); setMarketing(!marketing)}}>
            <div style={{marginRight:"12px", height:"22px"}}>
              <Icon name="Check Circle" width={22} height={22} fill={marketing?COLOR.Primary:COLOR.Gray5}/>
            </div>
            <div className="common-flex-grow" style={{height:"24px"}}>
              <Text type="Headline" color={marketing?"Gray1":"Gray4"}>약문약답 혜택 및 마케팅 정보 수신 동의</Text>
            </div>
            <IconButton variant="Ghost" size="M" iconName="CaretRight" onClick={(e) => {e.stopPropagation(); if(onClickMarketingDetail) onClickMarketingDetail();}}/>
          </div>
          <div className="contract-update-comment" onClick={onClickAll}>
            <Text type="Body2" color="Primary">동의를 하시면 <b>약문약답의 우수 콘텐츠, 구인알림, 제품 소개 등</b>을 카카오톡으로 받아보실 수 있어요.</Text>

          </div>
        </div>
        <div className="common-bottom-footer-space"/>
      </Body>
      <Footer>
        <TextButton block={true} color="Primary" size="L" onClick={() => {if(onDone)onDone(marketing)}} disabled={!contract || !privacy}>{hasNext?"다음":"완료"}</TextButton>
      </Footer>
    </>
  )
}

export default UserContractUpdate
