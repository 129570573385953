
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { OrderInfo, OrderPayMethod, OrderStatus, ProductInfo, ProductStatus } from '../models/Model.Sale';
import { log, LogLevel } from '../utils/LogUtil';
import { UIPopupType, UIServiceType } from '../store/ui/types';
import Header from '../component/molecule/Header';
import Body from '../component/molecule/Body';
import AnalyticsUtil from '../utils/AnalyticsUtil';
import Input from '../component/atom/Input';
import TextRectangleButton from '../component/cell/TextRectangleButton';
import Footer from '../component/molecule/Footer';
import Text from '../component/atom/Text';
import COLOR from '../component/_design/color'
import Icon from '../component/atom/Icon';
import { fetchAPI } from '../utils/APIUtil';
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';
import * as API from './../API.json';
import RadioButton from '../component/cell/RadioButton';

const queryString = require('query-string');

type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  orderId?: number
}

type State = {
  step: number,
  reasonIndex: number
  reason: string
}

const windowAny : any = window;
const reasons: string[] = [
  "기타",
  "상품이 마음에 들지 않음",
  "다른 상품 추가 후 재주문 예정",
  "판매자로부터 품절 안내 받음",
]

class ProductSaleMyOrderCancel extends Component<Props, State> {
  id: number = 0;

  constructor(props: Props) {
    super(props);
    this.state ={
      step: 0,
      reasonIndex: -1,
      reason: "",
    }

    let qs = queryString.parse(this.props.location.search);
    let newId = 0
    try{
      newId = parseInt(qs.id);
    }catch(err) {
      newId = 0
    }
    if(!newId && this.props.orderId){
      newId = this.props.orderId;
    }

    log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID ", newId);
    if(this.id != newId){
      log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID DETECTED", newId);
      this.id = newId;
      this.props.loadOrder(this.id);
    
      if(this.props.orders){
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ORDER_DETAIL_START", '주문상세 시작', {"주문id":this.id});
      }
    }
  }

  componentDidMount() {
    this.props.backKeyControl.setListener(() => {
      this.goBack();
      return false;
    });

    if(!this.props.orders[this.id]){
      this.props.waitingPopup.show();
    }
  }

  componentWillUnmount() {
    // this.props.backKeyControl.popListener();
  }

  componentDidUpdate(prevProps, prevState) {

    if(!prevProps.orders[this.id] && this.props.orders[this.id] && this.props.waitingPopup.isShown()){
      this.props.waitingPopup.hide();
    }
  }

  goBack = () => {
    log(LogLevel.UI_ACTION, "ProductSaleMyOrderCancel:goBack");
    this.props.history.goBack();
  }

  onCancel = () => {
    let order:OrderInfo = this.props.orders[this.id];
    if(!order)
      return;

    let disabled:boolean = (order.status >= OrderStatus.PREPARING_DELIVER)|| (!this.state.reason && this.state.reasonIndex <= 0)
    if(disabled)
      return;

    log(LogLevel.UI_LIFECYCLE, "ProductSaleMyOrderCancel.onCancel", this.state);
    this.props.waitingPopup.show();

    let newOrder:OrderInfo = {
      id: order.id,
      status : OrderStatus.CANCELED,
      returnReason: reasons[this.state.reasonIndex] + (this.state.reason?("/" + this.state.reason):""),
    }

    fetchAPI(API.SALE_ORDER_CHANGE, "", null, newOrder, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        this.props.setOrder(newOrder);
        this.setState({step:1})
        this.props.waitingPopup.hide();
      }else{
        alert("취소 실패 : 약문약답 카카오플러스친구로 연락주세요.");
        this.props.waitingPopup.hide();
        return;
      }
    })
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductSaleMyOrderCancel.render", this.props.orders);
    if(!this.id || !this.props.orders[this.id])
      return null;

    let order:OrderInfo = this.props.orders[this.id];

    if(!order)
      return null;

    if(this.state.step == 0){
      let isCancel:boolean = order.status < OrderStatus.PREPARING_DELIVER;
      let disabled:boolean = (!isCancel || (!this.state.reason && this.state.reasonIndex <= 0))
      return (
        <>
          <Header goBack={this.goBack} name="주문 취소 사유"/>
          <Body>
            <div className="common-block">
              <div style={{marginBottom:"12px"}}>
                <RadioButton type="List" iconType="Radio" iconPosition="right" checked={this.state.reasonIndex==1} onClick={() => this.setState({reasonIndex:1})}>{reasons[1]}</RadioButton>
              </div>
              <div style={{marginBottom:"12px"}}>
                <RadioButton type="List" iconType="Radio" iconPosition="right" checked={this.state.reasonIndex==2} onClick={() => this.setState({reasonIndex:2})}>{reasons[2]}</RadioButton>
              </div>
              <div style={{marginBottom:"12px"}}>
                <RadioButton type="List" iconType="Radio" iconPosition="right" checked={this.state.reasonIndex==3} onClick={() => this.setState({reasonIndex:3})}>{reasons[3]}</RadioButton>
              </div>
              <RadioButton type="List" iconType="Radio" iconPosition="right" checked={this.state.reasonIndex==0} onClick={() => this.setState({reasonIndex:0})}>{reasons[0]}</RadioButton>
              {this.state.reasonIndex==0 &&
                <Input value={this.state.reason} allowMultiline={true} placeholder="취소 사유를 입력해주세요." minRows={3} maxRows={3} onChange={(e) => this.setState({reason: e.target.value})}/> 
              }
            </div>
            <Footer>
              <TextRectangleButton size="F" level="3" disabled={disabled} onClick={this.onCancel}>
                신청하기
              </TextRectangleButton>
            </Footer>
          </Body>
        </>
      );
    }
    else if(this.state.step == 1){
      let isCancel:boolean = order.status < OrderStatus.PREPARING_DELIVER || order.status == OrderStatus.CANCELED;
      return (
        <>
          <Header goBack={this.goBack} name={""} needClose={true} needBack={false}/>
          <Body>
            <div className="common-container-column common-flex-align-center">
              <div style={{marginTop:"60px", marginBottom:"60px"}}>
                <Icon width={80} height={80} fill={COLOR.Green} name="Check Circle"/>
              </div>
              <Text color="Black" type="H3">{isCancel?"주문 취소가 완료되었습니다.":"반품신청이 완료되었습니다."}</Text>
            </div>
            <Footer>
              <TextRectangleButton size="F" level="3" onClick={this.goBack}>
                완료
              </TextRectangleButton>
            </Footer>
          </Body>
        </>
      );
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  orders: state.sale.orders,
  links: state.board.links,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadOrder: (id) => actions.sale.getOrder(id),
  setOrder: (order:OrderInfo) => actions.sale.setOrder(order),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSaleMyOrderCancel));