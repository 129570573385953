import React from "react";
import styled, { StyledComponent } from "styled-components";
import {
  OrderInfo,
  OrderStatus,
  OrderStatusName,
} from "../../models/Model.Sale";
import Thumbnail from "./../atom/Thumbnail";
import Text from "./../atom/Text";
import ColorBadge from "../atom/ColorBadge";
import { getDateStringFromToday } from "../../utils/TimeUtil";

const Wrapper: StyledComponent<"div", React.FC> = styled.div`
  background-color: #fff;
  --size: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .sale-order-item-body-info-container {
    flex-grow: 1;
  }

  .sale-order-item-body-image {
    min-width: 80px;
    margin-left: 10px;
  }

  .sale-order-item-body-title {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;

type Props = {
  order: OrderInfo;
};

const OrderStatusColor = {
  0: "Gray",
  // 1: "Gray",
  // 2: "Gray",
  // 3: "Gray",
  // 4: "Gray",
  1: "Green",
  2: "Red",
  3: "Blue",
  4: "Green",
  5: "Green",
  6: "Blue",
  7: "Gray",
  8: "Gray",
  101: "Red",
  102: "Gray",
  103: "Green",
  104: "Red",
};

const SaleOrderItemBody: React.FC<Props> = ({ order }) => {
  if (!order || !order.product) return null;

  return (
    <Wrapper>
      <div className="sale-order-item-body-info-container">
        <div className="sale-order-item-body-title">
          <ColorBadge color={OrderStatusColor[order.status]}>
            {OrderStatusName[order.status]}
          </ColorBadge>

          {order.status < OrderStatus.DELIVERED && order.deliverPlannedAt && (
            <Text type="Body2" color="Gray2" numberOfLines={1}>
              배송예정일:{" "}
              {getDateStringFromToday({ date: order.deliverPlannedAt })}
            </Text>
          )}
        </div>
        <Text type="Body2" color="Gray1" numberOfLines={1}>
          {order.product.name}
        </Text>
        <Text type="Body2" color="Gray2">
          {order.paidAmount}원 / {order.amount}개
        </Text>
      </div>
      <Thumbnail
        className="sale-order-item-body-image"
        type="Thumbnail"
        src={order.product.image}
      />
    </Wrapper>
  );
};

export default SaleOrderItemBody;
