import React, { FC, ReactNode } from "react";
import { Flex } from "../atoms/Layout";

interface BottomSheetListProps {
  children: ReactNode;
  customStyle?: React.CSSProperties;
}

const BottomSheetList: FC<BottomSheetListProps> = ({
  children,
  customStyle,
}) => {
  return (
    <Flex
      direction="column"
      gap="23px"
      customStyle={{ marginTop: "23px", ...customStyle }}
    >
      {children}
    </Flex>
  );
};

export default BottomSheetList;
