import React, { Component } from "react";
import "./../Admin.scss";
import "./../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonIcon,
  IonToggle,
} from "@ionic/react";
import { fetchAPI } from "./../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
} from "../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../store/ui/types";
import { BoardContent } from "../../models/Model.Board";
import {
  ComposedChart,
  Bar,
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { getDateStringFromToday } from "../../utils/TimeUtil";
import { MY_ADDRESS } from "./../../config.json";

const API = {
  BOARD_EXCELLENT_STATISTICS: {
    method: "POST",
    path: "/admin/board/content/statistics/excellent",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect?: (id: number) => void;
    simple?: boolean;
  };

type State = {
  fold: boolean;
  loading: boolean;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  standard: number;
  accumulate: number;
  statistics?: BoardsExcellentStatistics;
  contents?: BoardContent[];
  showOptions: number;
  showGraph: boolean;
  graphSmooth: boolean;
};

export interface BoardsExcellentStatistics {
  startDate?: string;
  endDate?: string;
  standard?: number;
  accumulate?: BoardsExcellentStatisticsAccumulationType;
  items?: BoardsExcellentStatisticsItem[];
}

export enum BoardsExcellentStatisticsAccumulationType {
  ACCUMULATE_FULL_PERIOD = 0,
  ACCUMULATE_END_DATE = 1,
  NO_ACCUMULATE_WEEKLY = 2,
}

export interface BoardsExcellentStatisticsItem {
  startDate?: string;
  endDate?: string;
  date?: string;
  totalPostCount?: number;
  totalReplyCount?: number;
  totalCount?: number;
  excellentCount?: number;
  contents?: BoardContent[];
}

enum BoardStatisticsShowSelection {
  EXCELENT_COUNT = 0x01,
  TOTAL_POST_COUNT = 0x02,
  TOTAL_REPLY_COUNT = 0x04,
  TOTAL_COUNT = 0x08,
}

class BoardExcellentStatisticsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_DATA_LOAD, "BoardExcellentStatisticsView constructor");
    let showOptions = getGlobal(
      GlobalKey.ADMIN_STATISTICS_USER_SHOW_OPTION,
      true
    );

    let showGraph = this.props.simple ? true : false;
    this.state = {
      fold: true,
      loading: false,
      dateStart: getDateStringFromToday({
        weeks: -4,
        byWeek: true,
        weekStarts: 1,
      }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      accumulate: 0,
      standard: 25,
      showOptions: BoardStatisticsShowSelection.EXCELENT_COUNT,
      showGraph,
      graphSmooth: true,
    };
  }

  componentDidMount() {
    if (this.props.simple) this.fetchStatistics();
  }

  componentWillUpdate(nextProps, nextState) {}

  fetchStatistics = async () => {
    if (this.state.loading) {
      this.props.toastPopup.show("로딩중입니다.");
      return;
    }
    if (
      this.state.dateStartWarn ||
      this.state.dateEndWarn ||
      !this.state.standard
    ) {
      this.props.toastPopup.show("검색옵션을 확인해주세요.");
      return;
    }

    let request: BoardsExcellentStatistics = {};
    request.startDate = this.state.dateStart;
    request.endDate = this.state.dateEnd;
    request.accumulate = this.state.accumulate;
    request.standard = this.state.standard;

    fetchAPI(
      API.BOARD_EXCELLENT_STATISTICS,
      "",
      null,
      request,
      getGlobal(GlobalKey.TOKEN)
    )
      .then((result) => {
        if (result && !result.error && result.data) {
          log(
            LogLevel.UI_DATA_LOAD,
            "BoardExcellentStatisticsView Fetch success",
            request,
            result
          );
          this.setState({ statistics: result.data });
        } else {
          log(
            LogLevel.UI_EXCEPTION,
            "BoardExcellentStatisticsView Fetch failed",
            request,
            result
          );
        }
      })
      .catch((e) => {
        log(
          LogLevel.UI_EXCEPTION,
          "BoardExcellentStatisticsView Fetch excepted",
          request,
          e
        );
      });
  };

  onDateStartChange = (e) => {
    let dateStart = e.target.value;
    let dateStartWarn = false;
    try {
      let date = Date.parse(dateStart);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "UserStatisticsView:onDateStartChange",
          dateStart
        );
        dateStartWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "UserStatisticsView:onDateStartChange",
          dateStart,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "UserStatisticsView:onDateStartChange",
        dateStart,
        err
      );
      console.log(err);
      dateStartWarn = true;
    }
    this.setState({ dateStart, dateStartWarn });
  };

  onDateEndChange = (e) => {
    let dateEnd = e.target.value;
    let dateEndWarn = false;
    try {
      let date = Date.parse(dateEnd);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "UserStatisticsView:onDateEndChange",
          dateEnd
        );
        dateEndWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "UserStatisticsView:onDateEndChange",
          dateEnd,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "UserStatisticsView:onDateEndChange",
        dateEnd,
        err
      );
      dateEndWarn = true;
    }
    this.setState({ dateEnd, dateEndWarn });
  };

  setPeriod = (pid) => {
    let dateStart, dateEnd;
    switch (pid) {
      case 1: // 4주
        dateStart = getDateStringFromToday({
          weeks: -4,
          byWeek: true,
          weekStarts: 1,
        });
        dateEnd = getDateStringFromToday({ days: 1 });
        break;
      case 2: // 12주
        dateStart = getDateStringFromToday({
          weeks: -12,
          byWeek: true,
          weekStarts: 1,
        });
        dateEnd = getDateStringFromToday({ days: 1 });
        break;
      case 3: // 서비스개시부터
        dateStart = getDateStringFromToday({
          date: "2019-07-07",
          byWeek: true,
          weekStarts: 1,
        });
        dateEnd = getDateStringFromToday({ days: 1 });
        break;
    }
    this.setState({
      dateStart,
      dateStartWarn: false,
      dateEnd,
      dateEndWarn: false,
    });
  };

  onStandardChange = (e) => {
    let standardS = e.target.value;
    let standard = 0;
    try {
      standard = parseInt(standardS);
      if (!standard) {
        standard = 0;
      }
    } catch (err) {
      log(LogLevel.UI_EXCEPTION, "UserStatisticsView:onDateEndChange", err);
    }
    this.setState({ standard });
  };

  renderStatisticsTable = () => {
    if (
      this.state.showGraph ||
      !this.state.statistics ||
      !this.state.statistics.items ||
      !this.state.statistics.items.length
    )
      return null;

    return (
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <table className="admin-table">
            <tbody>
              <tr>
                <td />
                {this.state.statistics.items.map((item, index) => (
                  <td className="admin-table-label-x" key={index.toString()}>
                    {item.date}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="admin-table-label-y">전체글수</td>
                {this.state.statistics.items.map((item, index) => {
                  return (
                    <td
                      className="admin-table-value"
                      key={index.toString()}
                      onClick={() => this.setState({ contents: item.contents })}
                    >
                      {item.totalCount}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td className="admin-table-label-y">질문수</td>
                {this.state.statistics.items.map((item, index) => {
                  return (
                    <td
                      className="admin-table-value"
                      key={index.toString()}
                      onClick={() => this.setState({ contents: item.contents })}
                    >
                      {item.totalPostCount}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td className="admin-table-label-y">우수글</td>
                {this.state.statistics.items.map((item, index) => {
                  return (
                    <td
                      className={
                        "admin-table-value common-bold " +
                        (item.excellentCount > 10
                          ? "common-color-highlight"
                          : "common-color-caution")
                      }
                      key={index.toString()}
                      onClick={() => this.setState({ contents: item.contents })}
                    >
                      {item.contents.length}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  renderDetailTable = () => {
    if (
      this.props.simple ||
      !this.state.contents ||
      !this.state.contents.length
    )
      return null;

    return (
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <table className="admin-table">
            <tbody>
              <tr>
                <td className="admin-table-label-x">id</td>
                <td className="admin-table-label-x">작성자</td>
                <td className="admin-table-label-x">작성일</td>
                <td className="admin-table-label-x">이동</td>
                <td className="admin-table-label-x">scrap</td>
                <td className="admin-table-label-x">like</td>
                <td className="admin-table-label-x">제목</td>
              </tr>
              {this.state.contents.map((item, index) => {
                return (
                  <tr
                    key={index.toString()}
                    onClick={() => this.props.onSelect(item.id)}
                  >
                    <td className="admin-table-value">{item.id}</td>
                    <td className="admin-table-value">{item.usersNickname}</td>
                    <td className="admin-table-value">
                      {item.createdAt.substring(0, 10)}
                    </td>
                    <td
                      className="admin-table-value-text common-color-caution"
                      onClick={(e) => this.onClickJump(e, item)}
                    >
                      <IonIcon name="arrow-forward" />
                    </td>
                    <td className="admin-table-value">{item.scrapCnt}</td>
                    <td className="admin-table-value">{item.likeCnt}</td>
                    <td className="admin-table-value-text">{item.subject}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  onClickJump = (e, item) => {
    e.stopPropagation();
    window.open(MY_ADDRESS + "/boards/view?id=" + item.id, "_blank");
  };

  renderGraph = () => {
    if (
      !this.state.showGraph ||
      !this.state.statistics ||
      !this.state.statistics.items ||
      !this.state.statistics.items.length ||
      (this.state.fold && !this.props.simple)
    )
      return null;

    let width = 150 * this.state.statistics.items.length;
    if (width > 1000) width = 1000;

    let totalCountLine,
      totalPostCountLine,
      totalReplyCountLine,
      excellentCountLine;
    if (!!(this.state.showOptions & BoardStatisticsShowSelection.TOTAL_COUNT))
      totalCountLine = (
        <Area
          type={this.state.graphSmooth ? "monotone" : "linear"}
          dataKey="totalCount"
          fill="url(#color1)"
          stroke="#ff0000"
        />
      );
    if (
      !!(this.state.showOptions & BoardStatisticsShowSelection.TOTAL_POST_COUNT)
    )
      totalPostCountLine = (
        <Area
          type={this.state.graphSmooth ? "monotone" : "linear"}
          dataKey="totalPostCount"
          fill="url(#color2)"
          stroke="#00ff00"
        />
      );
    if (
      !!(
        this.state.showOptions & BoardStatisticsShowSelection.TOTAL_REPLY_COUNT
      )
    )
      totalReplyCountLine = (
        <Area
          type={this.state.graphSmooth ? "monotone" : "linear"}
          dataKey="totalReplyCount"
          fill="url(#color3)"
          stroke="#0000ff"
        />
      );
    if (
      !!(this.state.showOptions & BoardStatisticsShowSelection.EXCELENT_COUNT)
    )
      excellentCountLine = (
        <Area
          type={this.state.graphSmooth ? "monotone" : "linear"}
          dataKey="excellentCount"
          fill="url(#color4)"
          stroke="#08f"
        />
      );

    return (
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <ComposedChart
            width={width}
            height={500}
            data={this.state.statistics.items}
          >
            <defs>
              <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ff0000" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#ff0000" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00ff00" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#00ff00" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0000ff" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#0000ff" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#08f" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#08f" stopOpacity={0} />
              </linearGradient>
            </defs>
            {totalCountLine}
            {totalPostCountLine}
            {totalReplyCountLine}
            {excellentCountLine}
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
          </ComposedChart>
        </div>
      </div>
    );
  };

  onChangeShowOption = (option) => {
    let showOptions = this.state.showOptions ^ option;
    this.setState({ showOptions });
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardExcellentStatisticsView:render",
      this.props,
      this.state
    );

    if (this.state.fold) {
      return (
        <div className="common-container">
          <div
            className="admin-full-button-block"
            onClick={() => this.setState({ fold: false })}
          >
            <div className="common-flex-row">
              <div>우수게시물 통계</div>
              <IonIcon name="arrow-down" />
            </div>
          </div>
          {this.renderGraph()}
        </div>
      );
    }

    let smooth;
    if (this.state.showGraph)
      smooth = (
        <div className="common-flex-row">
          <IonCheckbox
            checked={this.state.graphSmooth}
            onIonChange={(e) =>
              this.setState({ graphSmooth: !this.state.graphSmooth })
            }
          />
          <div className="admin-margin-right">Smooth Line</div>
        </div>
      );

    return (
      <div className="common-container">
        <div
          className="admin-full-button-block"
          onClick={() =>
            this.setState({
              fold: true,
              showGraph: this.props.simple ? true : this.state.showGraph,
            })
          }
        >
          <div className="common-flex-row">
            <div>우수게시물 통계</div>
            <IonIcon name="arrow-up" />
          </div>
        </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Search Option</div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <input
                  className={
                    this.state.dateStartWarn ? "common-color-caution" : ""
                  }
                  placeholder="시작일시"
                  value={this.state.dateStart}
                  onChange={this.onDateStartChange}
                />
                <div>~</div>
                <input
                  className={
                    this.state.dateEndWarn ? "common-color-caution" : ""
                  }
                  placeholder="시작일시"
                  value={this.state.dateEnd}
                  onChange={this.onDateEndChange}
                />
                <div
                  className="admin-text-button"
                  onClick={() => this.setPeriod(1)}
                >
                  4주
                </div>
                <div
                  className="admin-text-button"
                  onClick={() => this.setPeriod(2)}
                >
                  12주
                </div>
                <div
                  className="admin-text-button"
                  onClick={() => this.setPeriod(3)}
                >
                  서비스개시부터
                </div>
              </div>
            </div>
          </div>
          <IonButton
            onClick={this.fetchStatistics}
            disabled={this.state.loading}
          >
            Load
          </IonButton>
        </div>
        <div className="common-container-column">
          <div className="admin-margin-bottom">Display Option</div>
          <div className="common-container-row">
            <div className="common-container-column">
              <div className="search-options-container">
                <div
                  className={
                    this.state.showGraph
                      ? "search-option-text-inactive"
                      : "search-option-text-active"
                  }
                  onClick={(e) => {
                    this.setState({ showGraph: false });
                  }}
                >
                  Table
                </div>
                <IonToggle
                  class="search-options-toggle"
                  disabled={!this.state.statistics}
                  mode="ios"
                  checked={this.state.showGraph}
                  onClick={(e) =>
                    this.setState({ showGraph: !this.state.showGraph })
                  }
                />
                <div
                  className={
                    this.state.showGraph
                      ? "search-option-text-active"
                      : "search-option-text-inactive"
                  }
                  onClick={(e) => {
                    this.setState({ showGraph: true });
                  }}
                >
                  Graph
                </div>
              </div>
              {smooth}
            </div>
          </div>
          <div className="common-flex-row-wrap common-flex-grow common-flex-align-center">
            <div className="common-flex-row">
              <IonCheckbox
                disabled={!this.state.statistics}
                checked={
                  !!(
                    this.state.showOptions &
                    BoardStatisticsShowSelection.EXCELENT_COUNT
                  )
                }
                onIonChange={(e) =>
                  this.onChangeShowOption(
                    BoardStatisticsShowSelection.EXCELENT_COUNT
                  )
                }
              />
              <div
                className="admin-margin-right"
                style={{ color: "#08f", fontWeight: "bold" }}
              >
                우수게시물수
              </div>
            </div>
            <div className="common-flex-row">
              <IonCheckbox
                disabled={!this.state.statistics}
                checked={
                  !!(
                    this.state.showOptions &
                    BoardStatisticsShowSelection.TOTAL_COUNT
                  )
                }
                onIonChange={(e) =>
                  this.onChangeShowOption(
                    BoardStatisticsShowSelection.TOTAL_COUNT
                  )
                }
              />
              <div
                className="admin-margin-right"
                style={{ color: "#f00", fontWeight: "bold" }}
              >
                총게시물수
              </div>
            </div>
            <div className="common-flex-row">
              <IonCheckbox
                disabled={!this.state.statistics}
                checked={
                  !!(
                    this.state.showOptions &
                    BoardStatisticsShowSelection.TOTAL_POST_COUNT
                  )
                }
                onIonChange={(e) =>
                  this.onChangeShowOption(
                    BoardStatisticsShowSelection.TOTAL_POST_COUNT
                  )
                }
              />
              <div
                className="admin-margin-right"
                style={{ color: "#0f0", fontWeight: "bold" }}
              >
                포스팅수
              </div>
            </div>
            <div className="common-flex-row">
              <IonCheckbox
                disabled={!this.state.statistics}
                checked={
                  !!(
                    this.state.showOptions &
                    BoardStatisticsShowSelection.TOTAL_REPLY_COUNT
                  )
                }
                onIonChange={(e) =>
                  this.onChangeShowOption(
                    BoardStatisticsShowSelection.TOTAL_REPLY_COUNT
                  )
                }
              />
              <div
                className="admin-margin-right"
                style={{ color: "#00f", fontWeight: "bold" }}
              >
                답변수
              </div>
            </div>
          </div>
        </div>
        {this.renderStatisticsTable()}
        {this.renderDetailTable()}
        {this.renderGraph()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardExcellentStatisticsView)
);
