import * as sales from './actions';
import { SaleState, SaleActionTypes, SaleOrderListState} from './types';
import { ActionType, getType } from 'typesafe-actions';
import { Middleware } from 'redux';
import { fetchAPI } from '../../utils/APIUtil';
import * as API from './../../API.json';
import {getGlobal, GlobalKey, setGlobal, clearGlobal} from './../../utils/GlobalUtil'
import { EventInfo } from '../../models/Model.Event';
import { ErrorCode } from '../../models/ErrorCode';
import {log, LogLevel} from '../../utils/LogUtil'
import { UserState } from '../user/types';
import { conditionCheck } from '../../utils/ConditionCheckerUtil';
import { OrderInfo } from '../../models/Model.Sale';


export const fetchSaleMiddleware: Middleware<{}, SaleState> = (store) => next => async (action: ActionType<typeof sales>) => {
  next(action);

  let states = store.getState();
  let state:SaleState = states["sale"];
  let today = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));
  if (action.type == getType(sales.getProduct)) {
    log(LogLevel.REDUX, "fetchSaleMiddleware : sales.getProduct ", action, today)
    next(sales.fetchProduct.request(action.payload));
    try {
      fetchAPI(API.SALE_PRODUCT_GET, '', {id:action.payload}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        log(LogLevel.REDUX, "fetchSaleMiddleware : sales.getProduct result", result)
        if(result && !result.error && result.data){
          let product = result.data;

          log(LogLevel.REDUX, "fetchSaleMiddleware : getProduct success", product)
          next(sales.fetchProduct.success(product));
        }
        else{
          log(LogLevel.REDUX, "fetchSaleMiddleware : getProduct failed", result);
          next(sales.fetchProduct.failure(action.payload));
        }
      }).catch((e) => {
        log(LogLevel.REDUX, "fetchSaleMiddleware getProduct failed", e);
        next(sales.fetchProduct.failure(action.payload));
      })
    } catch (e) {
      log(LogLevel.REDUX, "fetchSaleMiddleware getProduct failed", e);
      next(sales.fetchProduct.failure(action.payload));
    }
  }
  else if (action.type == getType(sales.getOrder)) {
    log(LogLevel.REDUX, "fetchSaleMiddleware : sales.getOrder ", action, today)
    next(sales.fetchOrder.request(action.payload));
    try {
      fetchAPI(API.SALE_ORDER_DETAIL, '', {id:action.payload}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        log(LogLevel.REDUX, "fetchSaleMiddleware : sales.getOrder result", result)
        if(result && !result.error && result.data){
          let order = result.data;

          log(LogLevel.REDUX, "fetchSaleMiddleware : getOrder success", order)
          next(sales.fetchOrder.success(order));
        }
        else{
          log(LogLevel.REDUX, "fetchSaleMiddleware : getOrder failed", result);
          next(sales.fetchOrder.failure(action.payload));
        }
      }).catch((e) => {
        log(LogLevel.REDUX, "fetchSaleMiddleware getOrder failed", e);
        next(sales.fetchOrder.failure(action.payload));
      })
    } catch (e) {
      log(LogLevel.REDUX, "fetchSaleMiddleware getOrder failed", e);
      next(sales.fetchOrder.failure(action.payload));
    }
  }
  else if (action.type == getType(sales.getOrders)) {
    log(LogLevel.REDUX, "fetchSaleMiddleware : getOrders ", action, state)
    if((!state.orderList || !state.orderList.loading) && (!state.orderList.end || state.orderList.needReload) ){
      let needReload = state.orderList?state.orderList.needReload:false;
      next(sales.fetchOrders.request());
      try {
        let currentOrderList:SaleOrderListState = state.orderList;
        let start = 0;
        if(!needReload && currentOrderList.orders && currentOrderList.orders.length)
          start = currentOrderList.orders.length;
        let count = 7;
        let param:any = {start, count, includeProduct:true}
       
        fetchAPI(API.SALE_ORDER_LIST, '', param, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
          if(result && !result.error){
            let orders:OrderInfo[] = [];
            if(!needReload && currentOrderList.orders)
              orders = [...currentOrderList.orders, ...result.data];
            else 
              orders = [...result.data];
            let end : boolean = (result.data.length<count)? true: false;
            let loading: boolean = false;
            
            let newList:SaleOrderListState = {orders, end, loading, needReload:false};
            
            log(LogLevel.REDUX, "fetchSaleMiddleware : getOrders result",  needReload, newList);
            next(sales.fetchOrders.success(newList));
          }
          else if(result && result.error == ErrorCode.ERROR_NOT_EXIST){
            let orders:OrderInfo[] = [];
            if(!needReload && currentOrderList.orders){
              orders = currentOrderList.orders;
            }

            let newList:SaleOrderListState = {orders, end:true, loading:false, needReload:false};
            log(LogLevel.REDUX, "fetchSaleMiddleware : getOrders result ERROR_NOT_EXIST", start,  newList);
            next(sales.fetchOrders.success(newList));
          }
          else{
            log(LogLevel.REDUX, "fetchSaleMiddleware : getOrders failed", result);
  
            let error : Error = {
              name : action.payload.toString(),
              message: "error code " + result.error,
            };
            next(sales.fetchOrders.failure(error));
          }

          // if(action.payload == BoardType.RECOMMEND && result){
          //   let today = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));
          //   setGlobal(GlobalKey.UNREAD_RECOMMENDS_LOADED_AT, today, true);
          //   let data = [];
          //   if(result.data)
          //     data = result.data;
          //   setGlobal(GlobalKey.UNREAD_RECOMMENDS, data, true);
          // }
        })

      } catch (e) {
        log(LogLevel.REDUX, "fetchSaleMiddleware : getOrders failed", e);
        let error : Error = {
          name : action.payload.toString(),
          message: e.name + " " + e.message,
        };
        next(sales.fetchOrders.failure(error));
      }
    }
  }
};
