import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonInput,
  IonTextarea,
} from "@ionic/react";
import "./MyProfileChange.css";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import { CheckStatus } from "../components/DynamicChecker";
import { log, LogLevel } from "../utils/LogUtil";
import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import { UserInfo, UserState, UserWorkType } from "../models/Model.User";
import UserUtil from "../utils/UserUtil";
import InterestSelectPopup from "../components/InterestSelectPopup";
import SearchAddress from "../components/SearchAddress";
import DynamicCheckerInput from "../components/DynamicCheckerInput";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import { JobOfferInfo } from "../models/Model.JobPost";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import ChannelTalkService from "../utils/ChannelTalkService";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import { differenceInDays } from "date-fns";
import withConfirm from "../hoc/withConfirm";
import { Static } from "../components/atoms/Layout";
import Button from "../components/atoms/Button";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    workTypeConfirm: {
      showCanNotChangeWorkTypeConfirm: (dday: number) => Promise<unknown>;
      showChangeWorkTypeWarningConfirm: (
        workType: UserWorkType
      ) => Promise<unknown>;
    };
  };

type State = {
  searchingAddress: boolean;
  nickname: string;
  name: string;
  nicknameCheckStatus: CheckStatus;
  nicknameWarning: string;
  description: string;
  workPlaceCheckStatus: CheckStatus;
  workPlaceWarning: string;
  workType: number;
  workPlace: string;
  workPlaceAddress: string;
  workPlaceRegion: string;
  workPlaceName: string;
  workPlacePhone: string;
  workPlacePosition: any;
  email: string;
  emailWarning: string;
  emailCheckStatus: CheckStatus;
  phone: string;
  interest: string;
};

class MyProfileChange extends Component<Props, State> {
  popupPharmacySelect = null;
  popupInterestSelect = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      searchingAddress: false,
      workType: this.props.me.workType,
      nickname: this.props.me.nickname,
      name: this.props.me.name,
      nicknameCheckStatus: CheckStatus.CHECKED,
      nicknameWarning: "",
      description: this.props.me.description,
      workPlace:
        this.props.me.workPlaceRegion + " " + this.props.me.workPlaceName,
      // workPlaceAddress: "",
      // workPlaceRegion: "",
      // workPlaceName: "",
      // workPlacePhone: "",
      workPlaceAddress: this.props.me.workPlaceAddress,
      workPlaceRegion: this.props.me.workPlaceRegion,
      workPlaceName: this.props.me.workPlaceName,
      workPlacePhone: this.props.me.workPlacePhone,
      workPlaceCheckStatus: CheckStatus.CHECKED,
      workPlacePosition: this.props.me.workPlacePosition,
      workPlaceWarning: "",
      email: this.props.me.email,
      emailWarning: "",
      emailCheckStatus: CheckStatus.CHECKED,
      phone: this.props.me.phone,
      interest: this.props.me.interest,
    };
    log(LogLevel.UI_LIFECYCLE, "MyProfileChange:constructor", this.props.me);
  }

  componentDidMount() {
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.onBack);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "MY_PROFILE_CHANGE",
      "내프로필수정진입"
    );
  }

  onBack = () => {
    this.props.history.goBack();
  };

  onDone = async () => {
    if (!this.isEditable()) {
      if (this.state.emailCheckStatus !== CheckStatus.CHECKED) {
        this.props.toast.show("이메일을 확인해주세요.");
      } else if (this.state.workPlaceCheckStatus !== CheckStatus.CHECKED) {
        this.props.toast.show("근무지를 확인해주세요.");
      } else if (this.state.nicknameCheckStatus !== CheckStatus.CHECKED) {
        this.props.toast.show("닉네임을 확인해주세요.");
      }

      return;
    }

    let newMe: UserInfo = {
      workType: this.state.workType,
      nickname: this.state.nickname.trim(),
      name: this.state.name,
      description: this.state.description,
      workPlaceAddress: this.state.workPlaceAddress,
      workPlaceRegion: this.state.workPlaceRegion,
      workPlaceName: this.state.workPlaceName,
      workPlacePhone: this.state.workPlacePhone,
      workPlacePosition: this.state.workPlacePosition,
      email: this.state.email,
      phone: this.state.phone,
      interest: this.state.interest,
    };

    let result = await fetchAPI(
      API.USER_ME_UPDATE,
      "",
      null,
      newMe,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      let token = result.data.token;
      setGlobal(GlobalKey.TOKEN, token, true);
      this.props.setUserToken(token);
      this.props.loginUser();
    }

    if (
      this.props.me.workPlaceName !== newMe.workPlaceName ||
      this.props.me.workPlaceAddress !== newMe.workPlaceAddress ||
      this.props.me.workPlacePosition.x !== newMe.workPlacePosition.x ||
      this.props.me.workPlacePosition.y !== newMe.workPlacePosition.y
    ) {
      log(LogLevel.UI_LIFECYCLE, "MyProfileChange: offerChange: ", this.state);
      let offer: JobOfferInfo = {
        pharmacy: {
          name: newMe.workPlaceName,
          address: newMe.workPlaceAddress,
          region: newMe.workPlaceRegion,
          phone: newMe.workPlacePhone,
          position: newMe.workPlacePosition,
        },
      };
      fetchAPI(
        API.JOBPOST_OFFERS_UPDATE_PHARMACY,
        "",
        null,
        offer,
        getGlobal(GlobalKey.TOKEN)
      );
    }
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "MY_PROFILE_CHANGE",
      "내프로필수정완료",
      newMe
    );

    this.props.history.goBack();
  };

  isEditable = () => {
    if (
      this.state.emailCheckStatus !== CheckStatus.CHECKED ||
      this.state.nicknameCheckStatus !== CheckStatus.CHECKED ||
      this.state.workPlaceCheckStatus !== CheckStatus.CHECKED
    ) {
      return false;
    }

    if (
      this.state.nickname.trim() === this.props.me.nickname &&
      this.state.workPlaceAddress === this.props.me.workPlaceAddress &&
      this.state.description === this.props.me.description &&
      this.state.email === this.props.me.email &&
      this.state.phone === this.props.me.phone &&
      this.state.workType === this.props.me.workType &&
      this.state.interest === this.props.me.interest
    )
      return false;
    return true;
  };

  validateNickname = (nickname) => {
    if (nickname === this.props.me.name || nickname === this.props.me.nickname)
      this.setState({ nicknameCheckStatus: CheckStatus.CHECKED });
    else if (!nickname) {
      this.setState({
        nicknameCheckStatus: CheckStatus.WARNING,
        nicknameWarning: "닉네임은 필수입력사항입니다.",
      });
    } else if (nickname.length < 2) {
      this.setState({
        nicknameCheckStatus: CheckStatus.WARNING,
        nicknameWarning: "닉네임은 2자이상 가능합니다.",
      });
    } else {
      let newNickname = nickname.trim();
      fetchAPI(
        API.USER_FIELD_VALIDATE,
        "nickname",
        null,
        { value: newNickname },
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          if (
            result &&
            !result.error &&
            result.data.value === nickname.trim()
          ) {
            this.setState({
              nicknameCheckStatus: CheckStatus.CHECKED,
              nicknameWarning: "",
            });
          } else if (
            result &&
            result.error &&
            result.data.value === nickname.trim()
          ) {
            this.setState({
              nicknameCheckStatus: CheckStatus.WARNING,
              nicknameWarning: "다른 멤버가 사용하고 있는 닉네임입니다.",
            });
          } else {
            log(LogLevel.NONE, result);
          }
        })
        .catch((e) => {
          log(LogLevel.NONE, e);
        });
    }
  };

  onNicknameChange = (e) => {
    // log(LogLevel.NONE, e);
    this.setState({
      nickname: e.target.value,
      nicknameCheckStatus: CheckStatus.CHECKING,
    });
    this.validateNickname(e.target.value);
  };

  validateEmail = (email) => {
    if (email === this.props.me.email)
      this.setState({ emailCheckStatus: CheckStatus.CHECKED });
    else if (!email) {
      this.setState({
        emailCheckStatus: CheckStatus.WARNING,
        emailWarning: "이메일은 필수입력사항입니다.",
      });
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      fetchAPI(
        API.USER_FIELD_VALIDATE,
        "email",
        null,
        { value: email },
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          log(LogLevel.UI_DATA_LOAD, "MyProfileChange:validateEmail", result);

          if (result && !result.error && result.data.value === email) {
            this.setState({
              emailCheckStatus: CheckStatus.CHECKED,
              emailWarning: "",
            });
          } else if (result && result.error && result.data.value === email) {
            this.setState({
              emailCheckStatus: CheckStatus.WARNING,
              emailWarning: "이미 사용중인 이메일주소입니다.",
            });
          } else {
            log(LogLevel.UI_DATA_LOAD, result);
          }
        })
        .catch((e) => {
          log(LogLevel.NONE, e);
        });
    } else {
      if (email.length > 10) {
        this.setState({ emailWarning: "이메일주소가 유효하지 않습니다." });
      }
      this.setState({ emailCheckStatus: CheckStatus.WARNING });
    }
  };

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailCheckStatus: CheckStatus.CHECKING,
    });
    this.validateEmail(e.target.value);
  };

  onDescriptionChange = (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    this.setState({ description: e.detail.value });
  };

  onClickWorkPlace = (e) => {
    if (this.props.me.workType === UserWorkType.UNDERGRADUATE) return;
    log(LogLevel.NONE, e);
    this.setState({ searchingAddress: true });
  };

  onPharmacySelected = (
    address: string,
    region: string,
    name: string,
    phone: string,
    position: any
  ) => {
    log(
      LogLevel.UI_ACTION,
      "MyProfileChange:onPharmacySelected",
      phone,
      address
    );
    this.setState({
      workPlaceCheckStatus: CheckStatus.CHECKED,
      workPlaceWarning: "",
      searchingAddress: false,
      workPlace: region + " " + name,
      workPlaceAddress: address,
      workPlaceRegion: region,
      workPlaceName: name,
      workPlacePhone: phone,
      workPlacePosition: position,
    });
  };

  onPharmacySelectCanceled = () => {
    log(LogLevel.UI_ACTION, "MyProfileChange:onPharmacySelectCanceled");
    this.setState({ searchingAddress: false });
  };

  onClickInterest = (e) => {
    this.popupInterestSelect.show(this.state.interest);
  };

  onInterestSelected = (interest: string) => {
    log(LogLevel.UI_ACTION, "MyProfileChange:onInterestSelected", interest);
    this.setState({ interest: interest });
  };

  private setWorkType(type: number) {
    let workPlaceCheckStatus = CheckStatus.CHECKED;
    let workPlaceWarning = "";
    if (
      type !== UserWorkType.LEAVE_OF_ABSENCE &&
      !this.state.workPlaceAddress
    ) {
      workPlaceCheckStatus = CheckStatus.WARNING;
      workPlaceWarning = "근무지를 입력해주세요.";
    }
    this.setState({ workType: type, workPlaceCheckStatus, workPlaceWarning });
  }

  onPressType = async (type: number) => {
    if (this.state.workType === type) return;

    const elapsedTime = differenceInDays(
      new Date(),
      new Date(this.props.me.workTypeEditedAt)
    );

    if (elapsedTime < 30) {
      const dday = 30 - elapsedTime;
      const response = await this.props.workTypeConfirm.showCanNotChangeWorkTypeConfirm(
        dday
      );
      if (!response) ChannelTalkService.show();
    } else {
      if (type !== this.props.me.workType) {
        const response = await this.props.workTypeConfirm.showChangeWorkTypeWarningConfirm(
          type
        );
        if (response) {
          this.setWorkType(type);
        }
      } else {
        this.setWorkType(type);
      }
    }
  };

  render() {
    if (this.state.searchingAddress) {
      let type = "manual";
      if (this.state.workType < 3) {
        type = "pharmacy";
      }
      return (
        <SearchAddress
          onSelected={this.onPharmacySelected}
          onCanceled={this.onPharmacySelectCanceled}
          searchType={type}
        />
      );
    }

    let buttonColor = "myprofile-change-toolbar-button";
    if (this.isEditable()) {
      buttonColor = "myprofile-change-toolbar-button-enable";
    }
    log(
      LogLevel.UI_LIFECYCLE,
      "MyProfileChange:render",
      this.state,
      this.props.me
    );
    return (
      <>
        <IonHeader class="myprofile-change-header" no-border>
          <IonToolbar color="myprofile-change-toolbar">
            <IonButtons slot="start">
              <IonButton
                class="myprofile-change-toolbar-button"
                onClick={this.onBack}
              >
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel onClick={this.onBack}>프로필 수정</IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton color={buttonColor} onClick={this.onDone}>
                완료
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="common-content-with-header common-scroll">
          <InterestSelectPopup
            ref={(ref) => {
              this.popupInterestSelect = ref;
            }}
            onDone={this.onInterestSelected}
          />

          <div className="myprofile-change-body-container">
            <div className="myprofile-change-title">회원정보</div>
            <div className="myprofile-change-item-description">
              <span className="myprofile-change-item-highlight">타회원</span>
              에게 보이는 정보입니다.
            </div>
            <DynamicCheckerInput
              title="닉네임"
              optional={true}
              status={this.state.nicknameCheckStatus}
              warnings={this.state.nicknameWarning}
              input={this.state.nickname}
              onChange={this.onNicknameChange}
              type="text"
              titleClassName="myprofile-change-item-name"
              inputClassName="myprofile-change-item-input"
            />

            {this.props.me.workType != UserWorkType.UNDERGRADUATE && (
              <DynamicCheckerInput
                title="근무지역"
                optional={true}
                status={this.state.workPlaceCheckStatus}
                warnings={this.state.workPlaceWarning}
                input={this.state.workPlaceRegion}
                type="text"
                titleClassName="myprofile-change-item-name"
                inputClassName="myprofile-change-item-input"
                withoutChecker={true}
                onClick={this.onClickWorkPlace}
              />
            )}
            <div className="myprofile-change-item-container">
              <div className="myprofile-change-item-name">자기소개</div>
              <IonTextarea
                class="myprofile-change-item-textarea"
                value={this.state.description}
                onIonChange={this.onDescriptionChange}
              />
            </div>

            {this.props.me.workType != UserWorkType.UNDERGRADUATE && (
              <div className="myprofile-change-item-container">
                <div className="myprofile-change-item-name">관심분야</div>
                <div
                  className="myprofile-change-item-textarea"
                  onClick={this.onClickInterest}
                >
                  {UserUtil.getInterestString(this.state.interest)}
                </div>
              </div>
            )}
          </div>
          <div className="myprofile-change-body-container">
            <div className="myprofile-change-title">가입정보</div>
            <div className="myprofile-change-item-description">
              아래 회원 가입 정보는 타회원에게{" "}
              <span className="myprofile-change-item-highlight">
                노출되지 않는
              </span>{" "}
              정보입니다.
            </div>
            <div className="myprofile-change-item-container">
              <div className="myprofile-change-item-name">이름</div>
              <IonInput
                disabled
                class="myprofile-change-item-input"
                value={this.state.name}
              />
            </div>

            <DynamicCheckerInput
              title="이메일"
              optional={true}
              status={this.state.emailCheckStatus}
              warnings={this.state.emailWarning}
              input={this.state.email}
              onChange={this.onEmailChange}
              type="text"
              titleClassName="myprofile-change-item-name"
              inputClassName="myprofile-change-item-input"
            />

            <div className="myprofile-change-item-container">
              <div className="myprofile-change-item-name">연락처</div>
              <IonInput
                disabled
                class="myprofile-change-item-input"
                value={this.state.phone}
              />
            </div>

            <div className="myprofile-change-item-container">
              <div className="myprofile-change-item-name" />
              <div className="common-flex-grow common-flex-row common-flex-align-center">
                <div className="myprofile-change-item-description common-flex-grow">
                  연락처를 변경하시려면 운영진에게 문의바랍니다.
                </div>
                <IonButton
                  color="myprofile-chat-button"
                  onClick={() => {
                    AnalyticsUtil.event(
                      AnalyticsUtil.TYPE_ALL,
                      "MY_PROFILE_CHANGE_CHANNEL",
                      "내프로필수정문의"
                    );

                    if (
                      ABTestUtil.isTest(ABTestFeature.UI_JOB_POST_CHANNEL_TALK)
                    )
                      ChannelTalkService.show();
                  }}
                >
                  문의하기
                </IonButton>
              </div>
            </div>
            {this.props.me.workType != UserWorkType.UNDERGRADUATE && (
              <div className="myprofile-change-work-type-container">
                <div
                  className={
                    this.state.workType === 1
                      ? "myprofile-change-work-type-selected"
                      : "myprofile-change-work-type-unselected"
                  }
                  onClick={() => this.onPressType(UserWorkType.PHARMACY_OWNER)}
                >
                  개국약사
                </div>
                <div
                  className={
                    this.state.workType === 2
                      ? "myprofile-change-work-type-selected"
                      : "myprofile-change-work-type-unselected"
                  }
                  onClick={() =>
                    this.onPressType(UserWorkType.PHARMACY_EMPLOYED)
                  }
                >
                  근무약사
                </div>
                <div
                  className={
                    this.state.workType === 3
                      ? "myprofile-change-work-type-selected"
                      : "myprofile-change-work-type-unselected"
                  }
                  onClick={() => this.onPressType(UserWorkType.HOSPITAL)}
                >
                  병원근무
                </div>
                <div
                  className={
                    this.state.workType === 4
                      ? "myprofile-change-work-type-selected"
                      : "myprofile-change-work-type-unselected"
                  }
                  onClick={() =>
                    this.onPressType(UserWorkType.PHARMACEUTICAL_COMPANY)
                  }
                >
                  제약사
                </div>
                <div
                  className={
                    this.state.workType === 5
                      ? "myprofile-change-work-type-selected"
                      : "myprofile-change-work-type-unselected"
                  }
                  onClick={() => this.onPressType(UserWorkType.GOVERMENT)}
                >
                  정부기관
                </div>
                <div
                  className={
                    this.state.workType === 7
                      ? "myprofile-change-work-type-selected"
                      : "myprofile-change-work-type-unselected"
                  }
                  onClick={() =>
                    this.onPressType(UserWorkType.LEAVE_OF_ABSENCE)
                  }
                >
                  휴직
                </div>
                <div
                  className={
                    this.state.workType === 8
                      ? "myprofile-change-work-type-selected"
                      : "myprofile-change-work-type-unselected"
                  }
                  onClick={() => this.onPressType(UserWorkType.ETC)}
                >
                  기타
                </div>
              </div>
            )}
            <div
              className="myprofile-change-item-container"
              onClick={this.onClickWorkPlace}
            >
              <div className="myprofile-change-item-name">근무지</div>
              <IonTextarea
                class="myprofile-change-item-textarea"
                value={this.state.workPlace}
                readonly
              />
            </div>
          </div>
          {this.props.me.workType == UserWorkType.UNDERGRADUATE && (
            <Static
              customStyle={{
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                style={{ width: "100%" }}
                size={"Medium"}
                type={"Text"}
                variant={"Contained"}
                color={"Primary"}
                onClick={() => {
                  AnalyticsUtil.event(
                    AnalyticsUtil.TYPE_ALL,
                    "PROFILE_CHANGE",
                    "대학생약사인증",
                    {
                      위치: "내프로필수정",
                    }
                  );
                  this.props.history.push("/license/check");
                }}
                disabled={this.props.me.state == UserState.LICENSE_CHECK}
                disabledColor={this.props.me.state == UserState.LICENSE_CHECK}
              >
                {this.props.me.state == UserState.LICENSE_CHECK
                  ? "약사 인증중..."
                  : "약사 인증 하기"}
              </Button>
            </Static>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  updateMe: (user: UserInfo) => actions.user.setMe(user),
  loginUser: () => actions.user.login(),
  logoutUser: () => actions.user.logout(),
  setUserToken: (token: string) => actions.user.setToken(token),
};
export default withConfirm(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfileChange))
);
