import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

export interface TickMarkSliderProps {
  rangeLabels?: string[];
  width?: string;
  selectedIndex?: number;
  active?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

interface TickMarkSliderStyledProps extends TickMarkSliderProps {
  progress?: number;
}

const TickMarkSliderWrapper = styled.div<TickMarkSliderStyledProps>`
  .range {
    position: relative;
    width: ${({ width }) => width};
    height: 8px;
    margin: 0 auto;
    z-index: 3;
  }

  .range input {
    width: ${({ width }) => width};
    position: absolute;
    top: 2px;
    height: 0;
    -webkit-appearance: none;
    margin: 0;
  }

  .range input::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    margin: -9px 0 0;
    border-radius: 50%;
    background-color: ${COLOR_SYSTEM.get("Skyblue")[400]};
    cursor: pointer;
    border: 0 !important;
    z-index: 3;
  }

  .range input::-moz-range-thumb {
    width: 24px;
    height: 24px;
    margin: -12px 0 0;
    border-radius: 50%;
    background-color: ${COLOR_SYSTEM.get("Skyblue")[400]};
    cursor: pointer;
    border: 0 !important;
  }

  .range input::-ms-thumb {
    width: 24px;
    height: 24px;
    margin: -12px 0 0;
    border-radius: 50%;
    background-color: ${COLOR_SYSTEM.get("Skyblue")[400]};
    cursor: pointer;
    border: 0 !important;
  }
  .range input::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    border-radius: 4px;
  }
  .range input::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  .range input::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  .range input:focus {
    background: none;
    outline: none;
  }
  .range input::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  .range-labels {
    position: relative;
    width: ${({ width }) => `calc(${width} - 16px)`};
    margin: 18px auto 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }

  .range-labels::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    background-color: ${COLOR_SYSTEM.get("Gray")[50]};
    border-radius: 4px;
    top: -28px;
  }

  .range-labels li {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .range-labels li div:first-child {
    width: 8px;
    height: 8px;
    background-color: ${COLOR_SYSTEM.get("Gray")[100]};
    border-radius: 50%;
    position: relative;
    top: -28px;
  }

  .range-labels li div:last-child {
    position: absolute;
    width: 40px;
    text-align: center;
  }

  .progress {
    position: absolute;
    width: ${({ progress }) => `${progress}%`};
    height: 8px;
    background-color: ${COLOR_SYSTEM.get("Skyblue")[400]};
    border-radius: 4px;
    top: -28px;
  }
`;

const TickMarkSlider: React.FC<TickMarkSliderProps> = ({
  width,
  selectedIndex,
  active = true,
  rangeLabels = [],
  onChange,
}) => {
  const [value, setValue] = useState(String(selectedIndex + 1));
  const [progress, setProgress] = useState((selectedIndex / (rangeLabels.length - 1)) * 100);

  useEffect(() => {
    setProgress((selectedIndex / (rangeLabels.length - 1)) * 100);
    setValue(String(selectedIndex + 1));
  }, [selectedIndex]);

  return (
    <TickMarkSliderWrapper width={width} progress={progress}>
      <div className="range">
        <input
          type="range"
          min="1"
          max={String(rangeLabels.length)}
          step="1"
          value={String(value)}
          onChange={(e) => {
            if (!active) return;

            setValue(e.target.value);
            setProgress(
              ((Number(e.target.value) - Number(e.target.min)) / (Number(e.target.max) - Number(e.target.min))) * 100
            );
            onChange(e);
          }}
        />
      </div>

      <ul className="range-labels">
        {rangeLabels.map((label, index) => (
          <li key={index}>
            <div></div>
            <div>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[300]}>
                {label}
              </Text>
            </div>
          </li>
        ))}

        <div className="progress"></div>
      </ul>
    </TickMarkSliderWrapper>
  );
};

export default TickMarkSlider;
