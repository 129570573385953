import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonHeader,
  IonIcon,
  IonButtons,
  IonButton,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { GlobalKey, getGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import * as API from "../../../API.json";
import { UIPopupType } from "../../../store/ui/types";
import { getDateTimeString } from "../../../utils/TimeUtil";
import { UserInfo, UserWorkTypeName } from "../../../models/Model.User";
import {
  OrderInfo,
  OrderStatus,
  OrderStatusName,
} from "../../../models/Model.Sale";
import StringUtil from "../../../utils/StringUtil";

const ADMIN_API = {
  // "ORDERS" : {"method":"get", "path":"/admin/sale/order/list", "contentType":"application/json"},
  USER_UPDATE: {
    method: "PUT",
    path: "/admin/user/user",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    order: OrderInfo;
    onClose: () => void;
    onDone: (order?: OrderInfo) => void;
  };

type State = {
  status: OrderStatus;
  payRefund: number; // 반품비
  deliveryCompany: string; // 택배사
  deliveryId: string; // 운송장 번호
  recipientName: string;
  recipientPostalCode: string;
  recipientAddress1: string;
  recipientAddress2: string;
  recipientPhone: string;
  returnAmount: number;
  returnReason: string;
  deliverPlannedAt: string;
  workPlaceRegistration: string;
  hideRegistration: boolean;
};

class OrderEdit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: this.props.order.status, // 반품비
      payRefund: this.props.order.payRefund ? this.props.order.payRefund : 0, // 반품비
      deliveryCompany: this.props.order.deliveryCompany, // 택배사
      deliveryId: this.props.order.deliveryId, // 운송장 번호
      recipientName: this.props.order.recipientName,
      recipientPostalCode: this.props.order.recipientPostalCode,
      recipientAddress1: this.props.order.recipientAddress1,
      recipientAddress2: this.props.order.recipientAddress2,
      recipientPhone: this.props.order.recipientPhone,
      returnAmount: this.props.order.returnAmount,
      returnReason: this.props.order.returnReason,
      deliverPlannedAt: this.props.order.deliverPlannedAt,
      workPlaceRegistration: this.props.order.user
        ? this.props.order.user.workPlaceRegistration
        : "",
      hideRegistration: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.order.id != this.props.order.id) {
      this.setState({
        status: this.props.order.status, // 반품비
        payRefund: this.props.order.payRefund ? this.props.order.payRefund : 0, // 반품비
        deliveryCompany: this.props.order.deliveryCompany, // 택배사
        deliveryId: this.props.order.deliveryId, // 운송장 번호
        recipientName: this.props.order.recipientName,
        recipientPostalCode: this.props.order.recipientPostalCode,
        recipientAddress1: this.props.order.recipientAddress1,
        recipientAddress2: this.props.order.recipientAddress2,
        recipientPhone: this.props.order.recipientPhone,
        returnAmount: this.props.order.returnAmount,
        deliverPlannedAt: this.props.order.deliverPlannedAt,
        workPlaceRegistration: this.props.order.user
          ? this.props.order.user.workPlaceRegistration
          : "",
        returnReason: this.props.order.returnReason,
      });
    }

    if (
      this.state.status != prevState.status &&
      this.state.status == OrderStatus.RETURN
    ) {
      this.setState({
        payRefund:
          this.props.order.product.price * this.props.order.returnAmount,
      });
    } else if (
      this.state.status != OrderStatus.RETURN &&
      this.props.order.status != OrderStatus.RETURN &&
      this.state.status != prevState.status
    ) {
      this.setState({ payRefund: 0 });
    }
  }

  onDone = () => {
    let order: OrderInfo;

    if (this.state.status != this.props.order.status) {
      if (!order) order = { id: this.props.order.id };
      order.status = this.state.status;
    }

    if (this.state.deliveryCompany != this.props.order.deliveryCompany) {
      if (!order) order = { id: this.props.order.id };
      order.deliveryCompany = this.state.deliveryCompany;
    }
    if (this.state.deliveryId != this.props.order.deliveryId) {
      if (!order) order = { id: this.props.order.id };
      order.deliveryId = this.state.deliveryId;
    }
    if (this.state.payRefund && !this.props.order.payRefund) {
      if (!order) order = { id: this.props.order.id };
      order.payRefund = this.state.payRefund;
    }
    if (this.state.recipientName != this.props.order.recipientName) {
      if (!order) order = { id: this.props.order.id };
      order.recipientName = this.state.recipientName;
    }
    if (this.state.recipientPhone != this.props.order.recipientPhone) {
      if (!order) order = { id: this.props.order.id };
      order.recipientPhone = this.state.recipientPhone;
    }
    if (
      this.state.recipientPostalCode != this.props.order.recipientPostalCode
    ) {
      if (!order) order = { id: this.props.order.id };
      order.recipientPostalCode = this.state.recipientPostalCode;
    }
    if (this.state.recipientAddress1 != this.props.order.recipientAddress1) {
      if (!order) order = { id: this.props.order.id };
      order.recipientAddress1 = this.state.recipientAddress1;
    }
    if (this.state.recipientAddress2 != this.props.order.recipientAddress2) {
      if (!order) order = { id: this.props.order.id };
      order.recipientAddress2 = this.state.recipientAddress2;
    }
    if (this.state.returnAmount != this.props.order.returnAmount) {
      if (!order) order = { id: this.props.order.id };
      order.returnAmount = this.state.returnAmount;
    }
    if (this.state.returnReason != this.props.order.returnReason) {
      if (!order) order = { id: this.props.order.id };
      order.returnReason = this.state.returnReason;
    }
    if (this.state.deliverPlannedAt != this.props.order.deliverPlannedAt) {
      if (!order) order = { id: this.props.order.id };
      order.deliverPlannedAt = this.state.deliverPlannedAt;
    }

    if (
      this.state.workPlaceRegistration !=
      this.props.order.user.workPlaceRegistration
    ) {
      let updateUser: UserInfo = {
        id: this.props.order.userId,
        workPlaceRegistration: this.state.workPlaceRegistration,
      };

      let result = fetchAPI(
        ADMIN_API.USER_UPDATE,
        "",
        null,
        updateUser,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          log(LogLevel.UI_EVENT, "OrderEdit:fetchCompanyData onDone", order);
          this.props.toastPopup.show("개인정보 수정이 완료되었습니다.");
        })
        .catch((error) => {
          log(LogLevel.UI_EVENT, "OrderEdit:fetchCompanyData fail", error);
          this.props.toastPopup.show("개인정보 수정이 실패하였습니다.");
        });
    }

    if (order) {
      fetchAPI(
        API.SALE_ORDER_CHANGE,
        "",
        null,
        order,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          log(LogLevel.UI_EVENT, "OrderEdit:fetchCompanyData onDone", order);
          this.props.toastPopup.show("주문 수정이 완료되었습니다.");
          if (result && !result.error && this.props.onDone) {
            this.props.onDone(order);
          }
        })
        .catch((error) => {
          log(LogLevel.UI_EVENT, "OrderEdit:fetchCompanyData fail", error);
          this.props.toastPopup.show("주문 수정이 실패하였습니다.");
        });
    }
  };

  onDelete = () => {
    fetchAPI(
      API.SALE_ORDER_DELETE,
      "",
      { id: this.props.order.id },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(
        LogLevel.UI_EVENT,
        "OrderEdit:fetchCompanyData onDone",
        this.props.order
      );
      this.props.toastPopup.show("주문이 삭제되었습니다.");
      if (result && !result.error && this.props.onDone) {
        this.props.onDone();
      }
    });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "OrderEdit.render", this.props, this.state);
    if (!this.props.order || !this.props.order.id) {
      return null;
    }

    return (
      <div className="admin-full-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                class="common-header-toolbar-button"
                onClick={this.props.onClose}
              >
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel
                class="common-header-title"
                onClick={this.props.onClose}
              >
                주문상세
              </IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={this.onDone}>
                수정
              </IonButton>
              <IonButton onClick={this.onDelete}>삭제</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div style={{ height: "20px" }} />
        <div className="admin-title admin-margin-bottom">
          주문ID : {this.props.order.id}{" "}
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">상태 :</div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.ORDERED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.ORDERED })}
          >
            {OrderStatusName[OrderStatus.ORDERED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.PAID
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.PAID })}
          >
            {OrderStatusName[OrderStatus.PAID]}PAID
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.PREPARING_PRODUCT
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ status: OrderStatus.PREPARING_PRODUCT })
            }
          >
            {OrderStatusName[OrderStatus.PREPARING_PRODUCT]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.PREPARING_DELIVER
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ status: OrderStatus.PREPARING_DELIVER })
            }
          >
            {OrderStatusName[OrderStatus.PREPARING_DELIVER]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.DELIVERING
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.DELIVERING })}
          >
            {OrderStatusName[OrderStatus.DELIVERING]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.DELIVERED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.DELIVERED })}
          >
            {OrderStatusName[OrderStatus.DELIVERED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.CANCELED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.CANCELED })}
          >
            {OrderStatusName[OrderStatus.CANCELED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.ORDER_FAILED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.ORDER_FAILED })}
          >
            {OrderStatusName[OrderStatus.ORDER_FAILED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.RETURNING
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.RETURNING })}
          >
            {OrderStatusName[OrderStatus.RETURNING]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.status == OrderStatus.RETURN
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ status: OrderStatus.RETURN })}
          >
            {OrderStatusName[OrderStatus.RETURN]}
          </div>
        </div>
        <div style={{ height: "20px" }} />
        {this.props.order.product && (
          <div className="admin-title admin-margin-bottom">
            제품 : {this.props.order.product.id} /{" "}
            {this.props.order.product.name}
          </div>
        )}
        {this.props.order.user && (
          <div className="admin-title admin-margin-bottom">
            구매자 : {this.props.order.user.id} / {this.props.order.user.name} /{" "}
            {this.props.order.user.phone}
          </div>
        )}
        <div style={{ height: "20px" }} />
        <div className="admin-title admin-margin-bottom">
          주문량 : {this.props.order.amount}
        </div>
        <div className="admin-title admin-margin-bottom">
          총상품가격 : {this.props.order.payProduct}
        </div>
        <div className="admin-title admin-margin-bottom">
          배송비 : {this.props.order.payDelivery}
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title"> 반품 수량 :</div>
          <IonInput
            class="admin-input"
            type="number"
            value={this.state.returnAmount.toString()}
            onIonChange={(e) =>
              this.setState({ returnAmount: parseInt(e.detail.value) })
            }
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">반품 사유 :</div>
          <IonInput
            class="admin-input"
            value={this.state.returnReason}
            onIonChange={(e) => this.setState({ returnReason: e.detail.value })}
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">반품비 :</div>
          <IonInput
            class="admin-input"
            type="number"
            value={this.state.payRefund.toString()}
            onIonChange={(e) =>
              this.setState({ payRefund: parseInt(e.detail.value) })
            }
          />
        </div>
        <div className="admin-title admin-margin-bottom">
          결제액 : {this.props.order.payAmount}
        </div>
        <div style={{ height: "20px" }} />
        <div className="admin-title admin-margin-bottom">배송정보</div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">택배사 :</div>
          <IonInput
            class="admin-input"
            value={this.state.deliveryCompany}
            onIonChange={(e) =>
              this.setState({ deliveryCompany: e.detail.value })
            }
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">송장번호 :</div>
          <IonInput
            class="admin-input"
            value={this.state.deliveryId}
            onIonChange={(e) => this.setState({ deliveryId: e.detail.value })}
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">수신인 :</div>
          <IonInput
            class="admin-input"
            value={this.state.recipientName}
            onIonChange={(e) =>
              this.setState({ recipientName: e.detail.value })
            }
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">수신인전화번호 :</div>
          <IonInput
            class="admin-input"
            value={this.state.recipientPhone}
            onIonChange={(e) =>
              this.setState({ recipientPhone: e.detail.value })
            }
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">수신인주소1 :</div>
          <IonInput
            class="admin-input"
            value={this.state.recipientAddress1}
            onIonChange={(e) =>
              this.setState({ recipientAddress1: e.detail.value })
            }
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">수신인주소2 :</div>
          <IonInput
            class="admin-input"
            value={this.state.recipientAddress2}
            onIonChange={(e) =>
              this.setState({ recipientAddress2: e.detail.value })
            }
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">수신인우편번호 :</div>
          <IonInput
            class="admin-input"
            value={this.state.recipientPostalCode}
            onIonChange={(e) =>
              this.setState({ recipientPostalCode: e.detail.value })
            }
          />
        </div>
        <div style={{ height: "20px" }} />
        <div className="admin-title admin-margin-bottom">주문자 상세 정보</div>
        <div className="admin-title admin-margin-bottom">
          이름 : {this.props.order.user.name}
        </div>
        <div className="admin-title admin-margin-bottom">
          닉네임 : {this.props.order.user.nickname}
        </div>
        <div className="admin-title admin-margin-bottom">
          근무형태 : {UserWorkTypeName[this.props.order.user.workType]}
        </div>
        <div className="admin-title admin-margin-bottom">
          전화번호 : {this.props.order.user.phone}
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title"> 사업자번호 :</div>
          <IonInput
            class="admin-input"
            value={this.state.workPlaceRegistration}
            onIonChange={(e) =>
              this.setState({ workPlaceRegistration: e.detail.value })
            }
          />
        </div>
        {this.state.hideRegistration ? (
          <div
            className="admin-title"
            onClick={() => this.setState({ hideRegistration: false })}
          >
            사업자등록증 V
          </div>
        ) : (
          <div
            className="admin-margin-bottom"
            onClick={() => this.setState({ hideRegistration: true })}
          >
            <div className="admin-title">사업자등록증 ^</div>
            <img
              src={StringUtil.convertFilePath(
                this.props.order.user.workPlaceRegistrationUrl
              )}
            />
          </div>
        )}

        <div style={{ height: "20px" }} />
        <div className="admin-title admin-margin-bottom">거래 진행일</div>
        <div className="admin-title admin-margin-bottom">
          생성일 : {getDateTimeString(this.props.order.createdAt)}
        </div>
        <div className="admin-title admin-margin-bottom">
          주문일 : {getDateTimeString(this.props.order.orderedAt)}
        </div>
        <div className="admin-title admin-margin-bottom">
          결제일 : {getDateTimeString(this.props.order.paidAt)}
        </div>
        <div className="admin-title admin-margin-bottom">
          배송시작일 : {getDateTimeString(this.props.order.deliverAt)}
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">배송예상일 :</div>
          <IonInput
            class="admin-input"
            value={this.state.deliverPlannedAt}
            onIonChange={(e) =>
              this.setState({ deliverPlannedAt: e.detail.value })
            }
          />
        </div>
        <div className="admin-title admin-margin-bottom">
          배송완료일 : {getDateTimeString(this.props.order.deliveredAt)}
        </div>
        <div className="admin-title admin-margin-bottom">
          취소일 : {getDateTimeString(this.props.order.canceledAt)}
        </div>
        <div className="admin-title admin-margin-bottom">
          반품신청일 : {getDateTimeString(this.props.order.returnAt)}
        </div>
        <div className="admin-title admin-margin-bottom">
          반품완료일 : {getDateTimeString(this.props.order.returnedAt)}
        </div>
        <div className="admin-title admin-margin-bottom">
          삭제일 : {getDateTimeString(this.props.order.deletedAt)}
        </div>
        <div style={{ height: "20px" }} />
        <div className="admin-title admin-margin-bottom common-color-highlight">
          결제결과
        </div>
        <div className="admin-title admin-margin-bottom">
          약문약답 결제ID : {this.props.order.payMerchantId}
        </div>
        <div className="admin-title admin-margin-bottom">
          아임포트 결제ID : {this.props.order.impUid}
        </div>
        <div className="admin-title admin-margin-bottom">
          결제방법 : {this.props.order.payMethod}
        </div>
        <div className="admin-title admin-margin-bottom">
          결제금액 : {this.props.order.paidAmount}
        </div>
        <div className="admin-title admin-margin-bottom">
          결제상태 : {this.props.order.payStatus}
        </div>
        <div className="admin-title admin-margin-bottom">
          카드사 승인번호 : {this.props.order.payApplyNumber}
        </div>
        <div className="admin-title admin-margin-bottom">
          거래매출전표 URL : {this.props.order.payRecieptUrl}
        </div>
        <div className="admin-title admin-margin-bottom">
          취소금액 : {this.props.order.canceledAmount}
        </div>
        <div style={{ height: "100px" }} />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderEdit)
);
