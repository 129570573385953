import axios from "axios"
import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter } from "react-router-dom";
import {
  IonButton,
  IonInput,
  IonList,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { bizFetchAPI, fetchAPI } from "../../../utils/APIUtil";
import ProfileImage from "../../../components/ProfileImage";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { log, LogLevel } from "../../../utils/LogUtil";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { UserType } from "../../../models/Model.User";
import { set } from "lodash";
// import { SERVER_ADDRESS, SERVER_API_PORT } from "./../config.json";

const USER_ROW_CNT = 30;

const API = {
  MEMBER_LIST: {
    method: "GET",
    path: "/cc/admin",
    contentType: "application/json",
  },
  UPDATE_MEMBER: {
    method: "PUT",
    path: "/cc/admin",
    contentType: "application/json",
  },
  DELETE_MEMBER: {
    method: "DELETE",
    path: "/cc/admin",
    contentType: "application/json",
  }
}

const CCUserSelect = (props) => {
  const [search, setSearch] = useState("");
  const [CCUsers, setCCUsers] = useState([]);
  const [showPartners, setShowPartners] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [pageSet, setPageSet] = useState(0);
  const itemsPerPage = 5;
  const pagesPerSet = 5; 
  const userListRef = useRef(null);
  let loading = false;
  let finished = false;


  const filterdUserList = search
  ? CCUsers.filter(partner => 
      [partner.userName, partner.email, partner.phone].some(value => 
        value.toLowerCase().includes(search.toLowerCase())
      )
    )
  : CCUsers;

  // 현재 페이지에 맞게 파트너 리스트를 분할하는 로직
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = filteredPartners.slice(indexOfFirstItem, indexOfLastItem);

  // 페이지 변경 핸들러
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    fetchData();
  }, [search, showPartners, props.selectedService]);

  const onSearchChange = (e) => {
    setSearch(e.detail.value);
  };

  const handleSelect = (service) => {
    props.setSelectedService(service);
  };


  const onDelete = (id, email) => {
      bizFetchAPI(
        API.DELETE_MEMBER,
        '',
        {
          id: id,
          email: email
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      )
      .then((response) => {
        // Show a success toast
        setToastMessage(null); // Add this line
        setToastMessage('삭제 완료');

        fetchData();
      })
      .catch((error) => {
        console.error('Error:', error);
        // Show an error toast
        setToastMessage(null); // Add this line
        setToastMessage('삭제 에러');
      });
  }


  const handleSaveChanges = (id, changes) => {
    const index = CCUsers.findIndex(partner => partner.id === id);
    if (index !== -1) {
      CCUsers.splice(index, 1, { ...CCUsers[index], ...changes });
    }
    onSave(CCUsers[index])
  };


  const onSave = (e) =>{
      bizFetchAPI(
        API.UPDATE_MEMBER,
        '',
        null,
        {
          id: e.id,
          userName: e.userName,
          email: e.email,
          phone: e.phone,
          division: e.division,
          password: e.password
        },
        getGlobal(GlobalKey.TOKEN)
      )
      .then((response) => {
        // Show a success toast
        setToastMessage(null); // Add this line
        setToastMessage('Changes saved successfully');

        fetchData();
      })
      .catch((error) => {
        console.error('Error:', error);
        // Show an error toast
        setToastMessage(null); // Add this line
        setToastMessage('Error saving changes');
      });
  }


  const fetchData = async () => {
    if (loading) return;
    loading = true;

      const result = await bizFetchAPI(
        API.MEMBER_LIST,
        '',
        {
          userType : props.selectedService
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      );

    log(LogLevel.ALL, "fetchData", result)

    if (result) {
      finished = Array.isArray(result.data) && result.data.length < USER_ROW_CNT;
      setCCUsers(Array.isArray(result.data) ? result.data : []);
    } else {
      finished = true;
      setCCUsers([]);
    }

    loading = false;
  };
  return (
    <div className="admin-container">
        {toastMessage && <Toast message={toastMessage} duration={2000} />}
      <IonInput
        class="admin-input"
        placeholder="검색"
        value={search}
        onIonChange={onSearchChange}
      />
      <IonSelect
        value={props.selectedService}
        placeholder="서비스 선택"
        onIonChange={e =>{
          if(e.detail.value === 'cc') {
            handleSelect(UserType.BIZ_CC)
          } else if (e.detail.value === 'partners') {
            handleSelect(UserType.BIZ_ALLINONE)
          } else if (e.detail.value === 'biz') {
            handleSelect(UserType.BIZ_JOBPOST)
          } 
        }}
      >
        <IonSelectOption value="biz">Biz</IonSelectOption>
        <IonSelectOption value="partners">Partners</IonSelectOption>
        <IonSelectOption value="cc">CC</IonSelectOption>
      </IonSelect>
      <IonList ref={userListRef}>
        <div className="partners-list">
          {filterdUserList.map((partner) => (
              <PartnerDetails key={partner.id} partner={partner} onSave={handleSaveChanges} onDelete={onDelete} />
          ))}
        </div>
        {/* <Pagination>
          {pageSet > 0 && (
            <PageButton onClick={() => setPageSet(pageSet - 1)}>
              Previous
            </PageButton>
          )}
          {[...Array(Math.ceil(filterdUserList.length / itemsPerPage)).keys()].slice(pageSet * pagesPerSet, (pageSet + 1) * pagesPerSet).map(number => (
            <PageButton key={number + 1} onClick={() => paginate(number + 1)}>
              {number + 1}
            </PageButton>
          ))}
          {Math.ceil(filterdUserList.length / itemsPerPage) > (pageSet + 1) * pagesPerSet && (
            <PageButton onClick={() => setPageSet(pageSet + 1)}>
              More
            </PageButton>
          )}
        </Pagination> */}
      </IonList>
    </div>
  );
};


const mapStateToProps = (state: RootState) => ({
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

// mapDispatchToProps remains the same

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CCUserSelect);

const PartnerDetailContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  padding-top: 60px;
  position: relative;
  margin-bottom: 20px;
  width: 90%; // Adjust this to your preferred width
  max-width: 1200px; // Adjust this to your preferred maximum width
  margin-left: auto;
  margin-right: auto;
`;

const EditableField = styled.div`
  margin: 5px 0;
  &:hover {
    background-color: #f0f0f0;
  }
`;



const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  border: none;
  background: none;
  padding: 0; // Remove the default padding
  padding-left: 20px;
  outline: none;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1em;
  background-color: #007BFF; // Change this to your preferred color
  color: white;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3; // Change this to your preferred hover color
  }

  &:focus {
    outline: none;
  }
`;


const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const PageButton = styled.button`
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;



function PartnerDetails({ partner, onSave, onDelete }) {
  const [editFields, setEditFields] = useState({
    userName: partner.userName,
    email: partner.email,
    division: partner.division,
    phone: partner.phone
  });

  const handleFieldChange = (field, value) => {
    setEditFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleSaveClick = () => {
    onSave(partner.id, editFields);
  };

  const handleDeleteClick = () => {
    onDelete(partner.id, partner.email);
  };

  return (
    <PartnerDetailContainer>
      <ButtonContainer>
        <Button onClick={handleSaveClick} style={{ backgroundColor: 'green', color: 'white' }}>저장</Button>
        <Button onClick={handleDeleteClick} style={{ backgroundColor: 'red', color: 'white' }}>삭제</Button>
      </ButtonContainer>
      <EditableField>
        <strong>ID:</strong> {partner.id}
      </EditableField>
      <EditableField>
        <strong>Name:</strong>
        <Input
          value={editFields.userName}
          onChange={(e) => handleFieldChange('userName', e.target.value)}
        />
      </EditableField>
      <EditableField>
        <strong>Email:</strong>
        <Input
          value={editFields.email}
          onChange={(e) => handleFieldChange('email', e.target.value)}
        />
      </EditableField>
      <EditableField>
        <strong>Division:</strong>
        <Input
          value={editFields.division}
          onChange={(e) => handleFieldChange('division', e.target.value)}
        />
      </EditableField>
      <EditableField>
        <strong>Phone:</strong>
        <Input
          value={editFields.phone}
          onChange={(e) => handleFieldChange('phone', e.target.value)}
        />
      </EditableField>
      {/* <EditableField onClick={() => this.inputRef && this.inputRef.focus()}>
        <strong>Name:</strong>
        <Input
          value={editFields.userName}
          onChange={(e) => handleFieldChange('userName', e.target.value)}
        />
      </EditableField>
      <EditableField onClick={() => this.inputRef && this.inputRef.focus()}>
        <strong>Email:</strong>
        <Input
          value={editFields.email}
          onChange={(e) => handleFieldChange('email', e.target.value)}
        />
      </EditableField> */}
    </PartnerDetailContainer>
  );
}



interface ToastProps {
  message: string;
  duration?: number;
}

const Toast: React.FC<ToastProps> = ({ message, duration = 2000 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  if (!visible) return null;

  return (
    <div style={{ position: 'fixed', top: '50px', right: 0, backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: '5px' }}>
      {message}
    </div>
  );
};
