import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  AllInOnePartnerLevel,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
  UserInfo,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import { BizUserInfo } from "../../../models/Mode.Biz";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import { Flex } from "../../../components/atoms/Layout";
import {
  PremiumPurchaedStatus,
  UserPremiumInfo,
  UserPremiumPurchasedInfo,
} from "../../../models/Model.User.Premium";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import copy from "copy-to-clipboard";
import * as API from "../../../API.json";
import Button from "../../../components/atoms/Button";
import Input from "../../../components/atoms/Input";

const AdminAPI = {
  PREMIUM_PURCHASE_LIST: {
    method: "GET",
    path: "/admin/user/premium/purchases",
    contentType: "application/json",
  },

  PREMIUM_ADD: {
    method: "POST",
    path: "/sale/premium",
    contentType: "application/json",
  },

  PREMIUM_UPDATE: {
    method: "PUT",
    path: "/sale/premium",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    premium: UserPremiumInfo;
    onClose?: () => void;
    onDone?: () => void;
  };

type State = {
  purchases: UserPremiumPurchasedInfo[];
  amount: string;
  endAt: string;
  edited: boolean;
  userId: string;
  status: PremiumPurchaedStatus;
};

class PremiumUsersEdit extends Component<Props, State> {
  loading = false;

  state = {
    purchases: [],
    amount: "",
    endAt: "",
    userId: "",
    edited: false,
    status: PremiumPurchaedStatus.SCHEDULED,
  };

  constructor(props: Props) {
    super(props);
    if (this.props.premium) {
      this.state = {
        amount: this.props.premium.amount.toString(),
        userId: this.props.premium.userId.toString(),
        endAt: getDateStringFromToday({ date: this.props.premium.endAt }),
        status: this.props.premium.status,
        purchases: [],
        edited: false,
      };
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (prevProps.premium != this.props.premium) {
      this.fetchData();

      this.setState({
        amount: this.props.premium.amount.toString(),
        userId: this.props.premium.userId.toString(),
        endAt: getDateStringFromToday({ date: this.props.premium.endAt }),
        status: this.props.premium.status,
      });
    }
  }

  onKeyPress = (e) => {
    log(
      LogLevel.UI_ACTION,
      "PremiumUsersEdit:onKeyPress",
      e.keyCode,
      e.shiftKey
    );
    if (e.keyCode == 13) {
      this.fetchData();
      e.preventDefault();
    }
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:PremiumUsersEdit:fetchData");
    if (!this.props.premium) return;
    this.loading = true;
    fetchAPI(
      AdminAPI.PREMIUM_PURCHASE_LIST,
      "",
      { premiumId: this.props.premium.id },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:PremiumUsersEdit:fetchData result",
          result
        );

        this.setState({ purchases: result.data });
        this.loading = false;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:PremiumUsersEdit:fetchData result",
          result
        );
        this.setState({ purchases: [] });
      }
    });
  };

  savePremium = async () => {
    log(LogLevel.UI_ACTION, "Admin:PremiumUsersEdit:savePremium");
    this.props.waitingPopup.show();

    let premium: UserPremiumInfo = {
      id: this.props.premium.id,
      userId: parseInt(this.state.userId),
      status: this.state.status,
      amount: parseInt(this.state.amount),
      endAt: this.state.endAt + " 23:59:59",
      type: this.props.premium.type,
    };

    try {
      if (this.props.premium.id) {
        await fetchAPI(
          AdminAPI.PREMIUM_UPDATE,
          "",
          null,
          premium,
          getGlobal(GlobalKey.TOKEN)
        );
      } else {
        await fetchAPI(
          AdminAPI.PREMIUM_ADD,
          "",
          null,
          premium,
          getGlobal(GlobalKey.TOKEN)
        );
      }
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "Admin:PremiumUsersEdit:savePremium", e);
    }
    this.props.waitingPopup.hide();
    this.props.onDone && this.props.onDone();
  };

  cancelPremium = () => {
    log(LogLevel.UI_ACTION, "Admin:PremiumUsersEdit:cancelPremium");
    this.props.confirmPopup.show({
      iconImage: null,
      title: (
        <div className="common-container">
          <span className="common-bold">
            Premium 회원을 당장 해지하겠습니까?
          </span>
        </div>
      ),
      body: <div className="common-container">원복이 불가능합니다.</div>,
      onDone: async () => {
        this.props.waitingPopup.show();
        try {
          await fetchAPI(
            AdminAPI.PREMIUM_UPDATE,
            "",
            null,
            {
              ...this.props.premium,
              status: "CANCEL",
              endAt: getDateStringFromToday(),
            },

            getGlobal(GlobalKey.TOKEN)
          );
          await fetchAPI(
            API.USER_PREMIUM_PAYMENT_DELETE,
            "",
            { premiumId: this.props.premium.id },
            null,
            getGlobal(GlobalKey.TOKEN)
          );
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, "Admin:PremiumUsersEdit:cancelPremium", e);
        }
        this.props.waitingPopup.hide();
        this.props.onDone && this.props.onDone();
      },
      doneText: "네",
      cancelText: "아니오",
    });
  };

  render() {
    log(LogLevel.UI_DATA_LOAD, "Admin:PremiumUsersEdit:render", this.state);
    return (
      <div className="admin-grow-container">
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  프리미엄회원 상세정보 및 결제내역
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <Flex gap="10px">
          <Button
            onClick={this.cancelPremium}
            size={"Large"}
            type={"Text"}
            variant={"Contained"}
            color={"Negative"}
            disabled={!this.props.premium.id}
            disabledColor={!this.props.premium.id}
          >
            즉시종료
          </Button>
          <Button
            onClick={this.savePremium}
            size={"Large"}
            type={"Text"}
            variant={"Contained"}
            color={"Primary"}
            disabled={!this.state.edited}
            disabledColor={!this.state.edited}
          >
            저장하기
          </Button>
        </Flex>
        <div className="common-container">
          <div className="user-manage-title">ID : {this.props.premium.id}</div>
          <Flex gap="20px" alignItems="center">
            <div className="user-manage-title">
              Status : {PremiumPurchaedStatus[this.props.premium.status]}
            </div>

            <div
              className={
                "admin-toggle" +
                (this.state.status == PremiumPurchaedStatus.SCHEDULED
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => {
                this.setState({
                  edited: true,
                  status: PremiumPurchaedStatus.SCHEDULED,
                });
              }}
            >
              {PremiumPurchaedStatus[PremiumPurchaedStatus.SCHEDULED]}
            </div>

            <div
              className={
                "admin-toggle" +
                (this.state.status == PremiumPurchaedStatus.CANCELED
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => {
                this.setState({
                  edited: true,
                  status: PremiumPurchaedStatus.CANCELED,
                });
              }}
            >
              {PremiumPurchaedStatus[PremiumPurchaedStatus.CANCELED]}
            </div>

            <div
              className={
                "admin-toggle" +
                (this.state.status == PremiumPurchaedStatus.FAILED
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => {
                this.setState({
                  edited: true,
                  status: PremiumPurchaedStatus.FAILED,
                });
              }}
            >
              {PremiumPurchaedStatus[PremiumPurchaedStatus.FAILED]}
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.status == PremiumPurchaedStatus.PROVIDED
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => {
                this.setState({
                  edited: true,
                  status: PremiumPurchaedStatus.PROVIDED,
                });
              }}
            >
              {PremiumPurchaedStatus[PremiumPurchaedStatus.PROVIDED]}
            </div>
          </Flex>
          <Flex gap="20px" alignItems="center">
            <div className="user-manage-title">UserId :</div>
            <Input
              type="number"
              value={this.state.userId}
              onChange={(e) => {
                this.setState({ userId: e.target.value, edited: true });
              }}
            />
          </Flex>
          <Flex gap="20px" alignItems="center">
            <div className="user-manage-title">Amount :</div>
            <Input
              type="number"
              value={this.state.amount}
              onChange={(e) => {
                this.setState({ amount: e.target.value, edited: true });
              }}
            />
          </Flex>
          <Flex gap="20px" alignItems="center">
            <div className="user-manage-title">EndAt :</div>
            <Input
              value={this.state.endAt}
              onChange={(e) => {
                this.setState({ endAt: e.target.value, edited: true });
              }}
            />
          </Flex>
          <table className="admin-table">
            <thead className="admin-table-fixed-header">
              <tr>
                <td className="admin-table-label-x">ID</td>
                <td className="admin-table-label-x">PID</td>
                <td className="admin-table-label-x">UID</td>
                <td className="admin-table-label-x">Status</td>
                <td className="admin-table-label-x">Card</td>
                <td className="admin-table-label-x">Amount</td>
                <td className="admin-table-label-x">CreatedAt</td>
                <td className="admin-table-label-x">ReservedAt</td>
                <td className="admin-table-label-x">PaidAt</td>
                <td className="admin-table-label-x">Start</td>
                <td className="admin-table-label-x">End</td>
                <td className="admin-table-label-x">영수증</td>
              </tr>
            </thead>
            <tbody>
              {this.state.purchases &&
                this.state.purchases.map(
                  (item: UserPremiumPurchasedInfo, index: number) => {
                    let route = [];
                    return (
                      <tr key={index.toString()}>
                        <td className="admin-table-value">{item.id}</td>
                        <td className="admin-table-value">
                          {item.userPremiumId}
                        </td>

                        <td className="admin-table-value">{item.userId}</td>
                        <td
                          className="admin-table-value"
                          title={item.payMerchantId}
                          onClick={() => copy(item.payMerchantId)}
                        >
                          {PremiumPurchaedStatus[item.status]}
                        </td>
                        <td className="admin-table-value">
                          {item.payCardName}
                        </td>
                        <td className="admin-table-value">{item.payAmount}</td>
                        <td
                          className="admin-table-value"
                          title={item.createdAt}
                        >
                          {getDateStringFromToday({ date: item.createdAt })}
                        </td>
                        <td
                          className="admin-table-value"
                          title={item.reservedAt}
                        >
                          {item.reservedAt &&
                            getDateStringFromToday({
                              date: item.reservedAt,
                              showTime: true,
                            })}
                        </td>
                        <td className="admin-table-value" title={item.paidAt}>
                          {item.paidAt &&
                            getDateStringFromToday({ date: item.paidAt })}
                        </td>
                        <td className="admin-table-value">
                          {item.startAt &&
                            getDateStringFromToday({ date: item.startAt })}
                        </td>
                        <td className="admin-table-value">
                          {item.endAt &&
                            getDateStringFromToday({ date: item.endAt })}
                        </td>
                        <td
                          className="admin-table-value"
                          onClick={() => {
                            if (item.payRecieptUrl)
                              window.open(item.payRecieptUrl);
                          }}
                        >
                          {item.payRecieptUrl && ">"}
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PremiumUsersEdit);
