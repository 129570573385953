import { CompanyType } from "./Model.User";

export interface BannerInfo {
  id?: number;
  name?: string;
  type?: BannerType;
  imageUrl?: string;
  targetUrl?: string;
  priority?: number;
  startAt?: any;
  endAt?: any;
  createdAt?: any;
  deletedAt?: any;
  companyType?: CompanyType;
  condition?: any;
  advertiserInfo?: any; //{code?: string; productCode?: string; ...logData?: any};
}

export enum BannerType {
  BOARD = 0,
  LECTURE = 1,
  PARTNERS = 2,
}

export const EMPTY_BANNER: BannerInfo = {
  id: 0,
  name: "",
  type: BannerType.BOARD,
  imageUrl: "",
  targetUrl: "",
  priority: 0,
  startAt: new Date(Date.now() + 9 * 60 * 60 * 1000)
    .toISOString()
    .substring(0, 10),
  endAt: new Date(Date.now() + (24 * 7 + 9) * 60 * 60 * 1000)
    .toISOString()
    .substring(0, 10),
  companyType: CompanyType.ALL,
};
