import * as allinones from "./actions";
import { AllInOneState, AllInOneActionTypes } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "../../API.json";
import {
  getGlobal,
  GlobalKey,
  setGlobal,
  clearGlobal,
} from "./../../utils/GlobalUtil";
import { BannerInfo, BannerType } from "./../../models/Model.Banner";
import { ErrorCode } from "./../../models/ErrorCode";
import { log, LogLevel } from "./../../utils/LogUtil";
import { conditionCheck } from "./../../utils/ConditionCheckerUtil";
import { shuffle } from "lodash";
import { CompanyType } from "./../../models/Model.User";
import allinoneDescription from "./assets/image/allinone-description.png";

export const fetchAllInOneMiddleware: Middleware<{}, AllInOneState> = (
  store
) => (next) => async (action: ActionType<typeof allinones>) => {
  next(action);

  if (!action) return;
  let states = store.getState();
  let state: AllInOneState = states["allinone"];
  if (action.type == getType(allinones.getPartnerList)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchAllInOneMiddleware : allinones.getPartnerList ",
      action
    );
    next(allinones.fetchPartnerList.request());
    try {
      fetchAPI(
        API.ALLINONE_PARTNERS,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          if (result && !result.error) {
            log(
              LogLevel.REDUX_BOARD,
              "fetchAllInOneMiddleware getPartnerList success",
              result.data
            );
            let partners = shuffle(result.data);

            next(allinones.fetchPartnerList.success(partners));
          } else {
            log(
              LogLevel.REDUX_BOARD,
              "fetchAllInOneMiddleware getPartnerList failed",
              result
            );
            let error: Error = {
              name: "error code " + result.error,
              message: "error code " + result.error,
            };
            next(allinones.fetchPartnerList.failure(error));
          }
        })
        .catch((e) => {
          log(
            LogLevel.REDUX_BOARD,
            "fetchAllInOneMiddleware getPartnerList failed",
            e
          );
          next(allinones.fetchPartnerList.failure(e));
        });
    } catch (e) {
      log(
        LogLevel.REDUX_BOARD,
        "fetchAllInOneMiddleware getPartnerList failed",
        e
      );
      next(allinones.fetchPartnerList.failure(e));
    }
  } else if (action.type == getType(allinones.getBanners)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBannerMiddleware : allinones.getBanners ",
      action
    );
    next(allinones.fetchBanners.request(null));
    try {
      fetchAPI(
        API.BANNER_GET,
        "",
        { type: BannerType.PARTNERS },
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          if (result && !result.error) {
            let props = { me: states["user"].me };
            let banners = shuffle(result.data);
            // let banners = [
            //   {
            //     type: CompanyType.ALL,
            //     imageSrc: allinoneDescription,
            //     url: "/allinone/introduction",
            //     name: "소개",
            //     item: "소개",
            //   },
            //   ...shuffle(result.data),
            // ];
            log(
              LogLevel.REDUX_BOARD,
              "fetchBannerMiddleware getBanners success",
              result.data,
              banners
            );
            next(allinones.fetchBanners.success(banners));
          } else {
            log(
              LogLevel.REDUX_BOARD,
              "fetchBannerMiddleware getBanners failed",
              result
            );
            let error: Error = {
              name: "error code " + result.error,
              message: "error code " + result.error,
            };
            next(allinones.fetchBanners.failure(error));
          }
        })
        .catch((e) => {
          log(
            LogLevel.REDUX_BOARD,
            "fetchBannerMiddleware getBanners failed",
            e
          );
          next(allinones.fetchBanners.failure(e));
        });
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBannerMiddleware getBanners failed", e);
      next(allinones.fetchBanners.failure(e));
    }
  }
};
