import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import {
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonContent,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import "./MyScrap.css";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import logoIcon from "./../assets/icon/logo.png";
import kakaoIcon from "./../assets/icon/kakao_logo.png";
import profile from "./../assets/image/profile_m_1.png";
import SimpleBoardList from "./../components/SimpleBoardList";
import { BoardType } from "./../store/board/types";
import { log, LogLevel } from "../utils/LogUtil";
import BoardContentList from "../components/BoardContentList";
import BoardContentListSimpleItem from "../components/BoardContentListSimpleItem";
import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import { UIServiceType } from "../store/ui/types";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  segment: string;
};

class MyScrap extends Component<Props> {
  state = {
    segment: "all",
  };
  contentList = null;
  contentScroll = [null];

  constructor(props: Props) {
    super(props);
    if (
      !this.props.boards[BoardType.MY_SCRAP] ||
      this.props.boards[BoardType.MY_SCRAP].needReload
    )
      this.props.loadBoard(BoardType.MY_SCRAP);
  }

  onBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "MYSCRAP_IN",
      "내스크랩진입",
      {}
    );
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.onBack);
    this.autoScroll();
  }

  autoScrollTryMax = 10;
  autoScrollTry = 0;
  autoScroll = () => {
    log(LogLevel.UI_ACTION, "OtherProfile:autoScroll");
    setTimeout(async () => {
      try {
        let scroll = getGlobal(GlobalKey.SCROLL + "scrap0");
        if (scroll) {
          let scrollDone = false;
          if (this.contentScroll[0]) {
            log(
              LogLevel.UI_ACTION,
              "OtherProfile:autoScroll 0 jump to " + scroll
            );
            scrollDone = await this.contentScroll[0].scrollTo(scroll);
          }
          if (!scrollDone) {
            this.autoScrollTry++;
            if (this.autoScrollTry < this.autoScrollTryMax) {
              this.autoScroll();
              return;
            }
          }
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "BoardList:autoScroll failed", e);
        this.autoScrollTry++;
        if (this.autoScrollTry < this.autoScrollTryMax) {
          this.autoScroll();
          return;
        }
      }
    }, 100);
  };

  onScroll = (index, event) => {
    let target: any = event.target;
    log(LogLevel.UI_ACTION, "OtherProfile:onScroll ", index, target.scrollTop);
    setGlobal(GlobalKey.SCROLL + "scrap" + index.toString(), target.scrollTop);

    if (
      !this.props.boards[BoardType.MY_SCRAP].end &&
      !this.props.boards[BoardType.MY_SCRAP].loading &&
      target.scrollTop + target.clientHeight >
        this.contentList.clientHeight * 0.9
    ) {
      log(
        LogLevel.UI_EVENT,
        "BoardList:Scroll bottom loads more contents",
        target.scrollTop,
        target.clientHeight,
        this.contentList.clientHeight
      );
      this.props.loadBoard(BoardType.MY_SCRAP);
    }
  };

  updateSegment = (e: CustomEvent) => {
    log(LogLevel.NONE, e);
    this.setState((prevState) => ({
      ...prevState,
      segment: e.detail.value,
    }));
  };

  render() {
    log(LogLevel.NONE, "MyScrap:render");

    let style = "myscrap-list-container-with-foot";
    if (ABTestUtil.isTest(ABTestFeature.UI_JOB_POST)) {
      style = "myscrap-list-container";
    }
    return (
      <>
        <IonHeader class="myscrap-header" no-border>
          <IonToolbar color="myscrap-toolbar">
            <IonButtons slot="start">
              <IonButton class="myprofiletoolbar-button" onClick={this.onBack}>
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel onClick={this.onBack}>내 스크랩</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="myscrap-content">
          <div>
            <IonSegment
              value={this.state.segment}
              mode="md"
              slot="start"
              color="myscrap-segment"
              onIonChange={this.updateSegment}
            >
              <IonSegmentButton
                mode="md"
                class="myscrap-segment-button"
                value="all"
                checked={this.state.segment === "all"}
              >
                <div className="myscrap-segment-button-text">전체 스크랩</div>
              </IonSegmentButton>
            </IonSegment>
            <div className="myscrap-segment-title" />
          </div>

          <div className={style} ref={(ref) => (this.contentList = ref)}>
            <BoardContentList
              ref={(ref) => {
                this.contentScroll[0] = ref;
              }}
              id={0}
              onScroll={this.onScroll}
              loading={this.props.boards[BoardType.MY_SCRAP].loading}
              contents={this.props.boards[BoardType.MY_SCRAP].contents}
              checksum={this.props.boards[BoardType.MY_SCRAP].checksum}
              component={BoardContentListSimpleItem}
            />
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  boards: state.board.boards,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadBoard: (id: number) => actions.board.getBoard(id, 30),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyScrap)
);
