import * as jobposts from "./actions";
import { ActionType, getType } from "typesafe-actions";
import { JobPostState, JobListType, JobListState } from "./types";
import { log, LogLevel } from "../../utils/LogUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import ABTestUtil, { ABTestFeature } from "../../utils/ABTestUtil";
import { UserWorkType } from "../../models/Model.User";
import * as API from "./../../API.json";
import { JobOfferInfo, JobApplyInfo } from "../../models/Model.JobPost";
const defaultState: JobPostState = {
  offers: {},
  applies: {},
  lists: {},
  newCount: 0,
  lastVisited: getGlobal(GlobalKey.JOBPOST_LAST_VISITED, true),
  coupon: null,
};

export default (
  state = defaultState,
  action: ActionType<typeof jobposts>
): JobPostState => {
  if (ABTestUtil.isTest(ABTestFeature.UI_JOB_POST)) {
    switch (action.type) {
      case getType(jobposts.init):
        log(LogLevel.REDUX, "Reducer: jobposts.init", action.payload, state);
        if (action.payload === UserWorkType.PHARMACY_OWNER) {
          let newLists = {};
          newLists[JobListType.OFFER | JobListType.MY] = {
            id: JobListType.OFFER | JobListType.MY, // JobListType
            options: {},
            items: [],
            loading: false,
            end: false,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.ALL] = {
            id: JobListType.OFFER | JobListType.ALL, // JobListType
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              ...getGlobal(GlobalKey.JOBPOST_SEARCH_LAST_OPTION, true),
              liked: false,
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.PHARMACY] = {
            id: JobListType.OFFER | JobListType.PHARMACY,
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              ...getGlobal(GlobalKey.JOBPOST_SEARCH_LAST_OPTION, true),
              liked: false,
              ownerType: 1,
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.BIZ] = {
            id: JobListType.OFFER | JobListType.BIZ, // JobListType
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              ...getGlobal(GlobalKey.JOBPOST_SEARCH_LAST_OPTION, true),
              liked: false,
              ownerType: 2,
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.HISTORY] = {
            id: JobListType.OFFER | JobListType.HISTORY, // JobListType
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              ...getGlobal(GlobalKey.JOBPOST_SEARCH_LAST_OPTION, true),
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.PREMIUM] = {
            id: JobListType.OFFER | JobListType.PREMIUM, // JobListType
          };
          log(LogLevel.REDUX, "Reducer: jobposts.init owner", newLists);
          return {
            ...state,
            lists: newLists,
          };
        } else {
          let newLists = {};

          if (action.payload === UserWorkType.HOSPITAL) {
            let newLists = {};
            newLists[JobListType.OFFER | JobListType.MY] = {
              id: JobListType.OFFER | JobListType.MY, // JobListType
              options: {},
              items: [],
              loading: false,
              end: false,
              needReload: true,
              checksum: 0,
            };
          }
          // newLists[(JobListType.APPLY|JobListType.PASSED)] = {
          //   id: (JobListType.APPLY|JobListType.PASSED),  // JobListType
          //   options: {
          //   },
          //   items: [],
          //   loading:false,
          //   end: false,
          //   needReload: true,
          //   checksum: 0,
          // }
          // newLists[(JobListType.OFFER|JobListType.RECOMMENDED)] = {
          //   id: (JobListType.OFFER|JobListType.RECOMMENDED),  // JobListType
          //   options: {
          //     distanceFree:false
          //   },
          //   items: [],
          //   loading:false,
          //   end: false,
          //   needReload: true,
          //   checksum: 0,
          // }
          newLists[JobListType.OFFER | JobListType.ALL] = {
            id: JobListType.OFFER | JobListType.ALL, // JobListType
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              status: "",
              ...getGlobal(GlobalKey.JOBPOST_SEARCH_LAST_OPTION, true),
              liked: false,
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.PHARMACY] = {
            id: JobListType.OFFER | JobListType.PHARMACY, // JobListType
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              ...getGlobal(GlobalKey.JOBPOST_SEARCH_LAST_OPTION, true),
              liked: false,
              ownerType: 1,
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.BIZ] = {
            id: JobListType.OFFER | JobListType.BIZ, // JobListType
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              ...getGlobal(GlobalKey.JOBPOST_SEARCH_LAST_OPTION, true),
              liked: false,
              ownerType: 2,
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.MY_LIKE] = {
            id: JobListType.OFFER | JobListType.MY_LIKE, // JobListType
            options: {
              liked: true,
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          newLists[JobListType.OFFER | JobListType.SEARCHED] = {
            id: JobListType.OFFER | JobListType.SEARCHED, // JobListType
            options: {
              type: "",
              distance: 0,
              address: "",
              order: "raised",
              status: "",
              liked: false,
              query: "",
            },
            items: [],
            loading: false,
            end: true,
            needReload: true,
            checksum: 0,
          };
          // newLists[(JobListType.APPLY|JobListType.HISTORY_LATEST)] = {
          //   id: (JobListType.APPLY|JobListType.HISTORY_LATEST),  // JobListType
          //   options: {
          //   },
          //   items: [],
          //   loading:false,
          //   end: false,
          //   needReload: true,
          //   checksum: 0,
          // }
          newLists[JobListType.OFFER | JobListType.PREMIUM] = {
            id: JobListType.OFFER | JobListType.PREMIUM, // JobListType
          };
          log(LogLevel.REDUX, "Reducer: jobposts.init employee", newLists);
          return {
            ...state,
            lists: newLists,
          };
        }
      case getType(jobposts.reloadList):
        log(
          LogLevel.REDUX,
          "Reducer: jobposts.reloadList",
          action.payload,
          action.meta,
          state
        );
        if (!action.meta) {
          let newLists = { ...state.lists };
          let keys = state.lists.keys();
          for (let i = 0; i < keys.length; i++) {
            newLists[keys[i]] = {
              ...state.lists[keys[i]],
              needReload: true,
              end: false,
              loading: false,
              checksum: 0,
            };
            if (action.payload)
              newLists[action.meta].options = {
                ...newLists[action.meta].options,
                ...action.payload,
              };
          }
          log(LogLevel.REDUX, "Reducer: jobposts.reloadList ALL", newLists);
          return {
            ...state,
            lists: newLists,
          };
        } else {
          let newLists = { ...state.lists };
          if (newLists[action.meta]) {
            newLists[action.meta].end = false;
            newLists[action.meta].loading = false;
            newLists[action.meta].checksum = 0;
            newLists[action.meta].needReload = true;

            if (action.payload)
              newLists[action.meta].options = {
                ...newLists[action.meta].options,
                ...action.payload,
              };
            log(LogLevel.REDUX, "Reducer: jobposts.reloadList done", newLists);
            return {
              ...state,
              lists: newLists,
            };
          } else {
            newLists[action.meta] = {
              id: action.meta, // JobListType
              options: {},
              items: [],
              loading: false,
              end: false,
              needReload: true,
              checksum: 0,
            };
            if (action.payload)
              newLists[action.meta].options = {
                ...newLists[action.meta].options,
                ...action.payload,
              };
            log(LogLevel.REDUX, "Reducer: jobposts.reloadList done", newLists);
            return {
              ...state,
              lists: newLists,
            };
          }
        }
      case getType(jobposts.fetchOffer.success):
      case getType(jobposts.updateOffer):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchOffer.success / jobposts.updateOffer",
            action.payload,
            state
          );
          let offers = { ...state.offers };
          let offer: JobOfferInfo;
          if (offers[action.payload.id])
            offer = {
              ...offers[action.payload.id],
              ...action.payload,
              pharmacy: {
                ...offers[action.payload.id].pharmacy,
                ...action.payload.pharmacy,
              },
            };
          else offer = { ...action.payload };
          offer.loading = false;
          offers[action.payload.id] = offer;
          return {
            ...state,
            offers,
          };
        }
        break;
      case getType(jobposts.fetchApply.success):
      case getType(jobposts.updateApply):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchApply.success / jobposts.updateApply",
            action.payload,
            state
          );
          let applies = { ...state.applies };
          let apply: JobApplyInfo;
          if (applies[action.payload.id])
            apply = { ...applies[action.payload.id], ...action.payload };
          else apply = { ...action.payload };
          apply.loading = false;
          applies[action.payload.id] = apply;
          return {
            ...state,
            applies,
          };
        }
        break;
      case getType(jobposts.fetchList.request):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchList.request",
            action.payload,
            state
          );
          let newLists = { ...state.lists };
          if (newLists[action.payload]) {
            newLists[action.payload].loading = true;
            return {
              ...state,
              lists: newLists,
            };
          }
        }
        break;
      case getType(jobposts.fetchList.failure):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchList.failure",
            action.payload,
            state
          );
          let newLists = { ...state.lists };
          if (newLists[action.payload]) {
            newLists[action.payload].loading = true;
            newLists[action.payload].end = true;
            return {
              ...state,
              lists: newLists,
            };
          }
        }
        break;

      case getType(jobposts.fetchList.success):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchList.success",
            action.payload,
            state
          );
          let newLists = { ...state.lists };
          newLists[action.payload.id] = action.payload;
          return {
            ...state,
            lists: newLists,
          };
        }
        break;
      case getType(jobposts.fetchOffer.request): {
        log(
          LogLevel.REDUX,
          "Reducer: jobposts.fetchOffer.request",
          action.payload,
          state
        );
        let offers = { ...state.offers };
        let offer: JobOfferInfo;
        if (offers[action.payload]) offer = { ...offers[action.payload] };
        else offer = { id: action.payload };
        offer.loading = true;
        offers[action.payload] = offer;
        return {
          ...state,
          offers,
        };
      }
      case getType(jobposts.fetchOffer.failure):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchOffer.failure",
            action.payload,
            state
          );
          let offers = { ...state.offers };
          let offer: JobOfferInfo;
          if (offers[action.payload]) offer = { ...offers[action.payload] };
          else offer = { id: action.payload };
          offer.loading = false;
          offers[action.payload] = offer;
          return {
            ...state,
            offers,
          };
        }
        break;
      case getType(jobposts.fetchApply.request): {
        log(
          LogLevel.REDUX,
          "Reducer: jobposts.fetchApply.request",
          action.payload,
          state
        );
        let applies = { ...state.applies };
        let apply: JobApplyInfo;
        if (applies[action.payload]) apply = { ...applies[action.payload] };
        else apply = { id: action.payload };
        apply.loading = true;
        applies[action.payload] = apply;
        return {
          ...state,
          applies,
        };
      }
      case getType(jobposts.fetchApply.failure):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchApply.failure",
            action.payload,
            state
          );
          let applies = { ...state.applies };
          let apply: JobApplyInfo;
          if (applies[action.payload]) apply = { ...applies[action.payload] };
          else apply = { id: action.payload };
          apply.loading = false;
          applies[action.payload] = apply;
          return {
            ...state,
            applies,
          };
        }
        break;
      case getType(jobposts.fetchCoupon.success): {
        log(
          LogLevel.REDUX,
          "Reducer: jobposts.fetchCoupon.success",
          action.payload,
          state
        );
        return {
          ...state,
          coupon: action.payload,
        };
      }
      case getType(jobposts.fetchCoupon.failure):
        {
          log(
            LogLevel.REDUX,
            "Reducer: jobposts.fetchCoupon.failure",
            action.payload,
            state
          );
          return {
            ...state,
            coupon: null,
          };
        }
        break;
      case getType(jobposts.visitLeave): {
        log(
          LogLevel.REDUX,
          "Reducer: jobposts.visitLeave",
          state,
          getGlobal(GlobalKey.JOBPOST_LAST_VISITED)
        );
        return {
          ...state,
          lastVisited: getGlobal(GlobalKey.JOBPOST_LAST_VISITED, true),
        };
      }
      case getType(jobposts.countNew): {
        log(
          LogLevel.REDUX,
          "Reducer: jobposts.countNew",
          action.payload,
          state
        );
        return {
          ...state,
          newCount: action.payload,
        };
      }

      case getType(jobposts.scrap): {
        // const scrapPost = state.
        console.log(state);
      }
      default:
        return state;
    }
  }

  return state;
};
