

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonLabel, IonSpinner } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import * as API from './../../../API.json';
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../../models/Model.Board';
import Truncate from 'react-truncate';
import { BannerInfo, EMPTY_BANNER, BannerType } from '../../../models/Model.Banner';
import { PollInfo, EMPTY_POLL, PollItemInfo, PollResponseInfo, PollResponseItemInfo } from '../../../models/Model.Poll';
import PollEdit from './PollEdit';

const AdminAPI = {        
};

type Props = {
  // onBack: () => void,
  // onPop: () => void,
  // onNext: (component:any, depth: number, data:any) => void, 
  // selectedPoll: PollInfo;
  // selectedPollItem: PollItemInfo,
  // depth: number;
  selectedResponse: PollResponseInfo;
};

type State = {
  response: PollResponseInfo,
}

class PollResponse extends Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      response: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps){
    log(LogLevel.UI_LIFECYCLE, "PollResponse:componentDidUpdate", prevProps.selectedResponse, this.props.selectedResponse);
    if(this.props.selectedResponse && this.props.selectedResponse.id && (!prevProps.selectedResponse || !prevProps.selectedResponse.id || prevProps.selectedResponse.id != this.props.selectedResponse.id)){
      this.setState({response: null});
      this.fetchData();
    }
  }

  fetchData = async () => {

    if(!this.props.selectedResponse || !this.props.selectedResponse.id)
      return;
    let result = await fetchAPI(API.POLL_RESPONSE_GET, this.props.selectedResponse.id, null, null, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error && result.data){
      this.setState({response: result.data});
    }
    else{
      this.setState({response: null});
    }
  }
  
  render() {
    log(LogLevel.UI_EVENT, "PollResponse:render", this.state.response);
    if(!this.state.response || !this.state.response.id)
      return (
        <div className="admin-container">
          <div className="common-container-column common-flex-align-center">
            <IonSpinner />
          </div>
        </div>
      );

    let userInfo;
    let responses;

    if(this.state.response.user){
      userInfo = (
        <div className="common-container">
          <div className="admin-title">이름 : {this.state.response.user.name}</div>
          <div className="admin-title">전화번호 : {this.state.response.user.phone}</div>
          <div className="admin-title">지역 : {this.state.response.user.workPlaceRegion}</div>
          <div className="admin-title">성별 : {this.state.response.user.gender}</div>
          <div className="admin-title">생년 : {this.state.response.user.birth}</div>
        </div>
      );
    }

    if(this.state.response.items && this.state.response.items.length){
      responses = (
        <div className="admin-card-container">
          {this.state.response.items.map((item:PollResponseItemInfo, index) => {
            return (
              <div key={index.toString()} className="common-container admin-margin-bottom">
                <div className="admin-normal-text">{item.pollItem.order}. {item.pollItem.question}</div>
                <div className="admin-normal-text common-bold common-color-highlight">{item.value?item.value:((item.values && item.values.length)?item.values.join(", "):"")}</div>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button">
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="viewer-title" >설문 답변 상세보기</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="admin-board-list-container">
          <div className="admin-title">ID : {this.state.response.id}</div>
          <div className="admin-title">유저ID : {this.state.response.userId}</div>
          {userInfo}
          {responses}
        </div>
      </div>
    );
  }
}

export default PollResponse;