import React, { memo, useEffect, useMemo } from "react"
import Tag from "../../atoms/Tag"
import VisibilitySensor from "react-visibility-sensor";
import Text from "../../atoms/Text"
import { COLOR_SYSTEM } from "../../design/design-system"
import styled from "styled-components"
import { SHADOW } from "../../design/effects"
import { Partner } from "../../../store/search"
import AnalyticsUtil from "../../../utils/AnalyticsUtil"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import { Flex } from "../../atoms/Layout"
import { CompanyTypeName } from "../../../models/Model.User"
import Avatar from "../../atoms/Avatar"
import { Link, RouteComponentProps, withRouter } from "react-router-dom"
import { vi } from "date-fns/locale";

interface SearchedPartnerCardProps {
  partners: Partner[]
}

interface IFlexProps {
  gap?: "string"
}

const FlexRowDiv = styled.div<IFlexProps>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
`


const TopDiv = styled(FlexRowDiv)`
  justify-content: space-between;
`

const PartnerListWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  min-width: 250px;
  display: flex;
  gap: 20px;
  max-width: 300px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  ${SHADOW.get("1")};
  border-radius: 8px;
  transform: translateZ(0);
  cursor: pointer;
  align-items: center;
  justify-content: left;
  padding: 12px;
  border: 8px;
`

// const PartnersHome: React.FC<PartnersHomeProps & RouteComponentProps> = ({

const SearchedPartnerCard: React.FC<SearchedPartnerCardProps & RouteComponentProps> = ({
  partners, 
  history
}) => {
  const currentSearchedKeyword = useSelector(
    (state: RootState) => state.newSearch.currentSearchedKeyword
  )

// `/partners/detail?id=${partner["partner"].id.substring(3,50)}`



  const handleClickCard = (partner: Partner) => {
    history.push(`/partners/detail?id=${partner.id.substring(3,50)}`)

    console.log("")

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "PARTNER_SEARCHED_RESULT_CLICK",
      "파트너검색결과선택",
      {
        검색모드: "BOARD",
        검색어: currentSearchedKeyword,
        회사이름: partner.title,
        회사ID: partner.id,
      }
    )
  }

  const onVisibleChange = (visible: boolean, partner: Partner) => {

    if (visible) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNER_SEARCHED_RESULT",
        "파트너검색결과노출",
        {
          검색모드: "BOARD",
          검색어: currentSearchedKeyword,
          회사이름: partner.title,
          회사ID: partner.id,
        }
      )
    }
  }

  return (
    <>
      <TopDiv>
        <Flex direction="column">
          <Flex>
            <Text
              textType="Body1Bold"
              color={COLOR_SYSTEM.get("Gray")[800]}
              numberOfLines={1}
            >
              "{currentSearchedKeyword}"관련 전문 콘텐츠
            </Text>
          </Flex>
          <Flex>
            <Text
              textType="Body2Medium"
              color={COLOR_SYSTEM.get("Gray")[600]}
              numberOfLines={1}
            >
              약사파트너스에서 확인하세요
            </Text>
          </Flex>
        </Flex>
      </TopDiv>
      <Flex gap="24px" customStyle={{ overflowX:"scroll", overscrollBehaviorX: "none", alignItem:"left"}} >
          <Flex direction="row"  customStyle={{ height : "100px", maxWidth: "100%",  display: "flex", flexFlow: "column wrap", gap: "20px 20px", alignItems : "center", justifyContent: "center" }} >
            {
              partners.map((partner, index) => (
                <VisibilitySensor onChange={(visible) => onVisibleChange(visible, partner['partner']) } key={index.toString()}>
                <div onClick={()=>{handleClickCard(partner['partner'])}} > 
                  <PartnerListWrapper key={partner["partner"].id}>
                        <Avatar
                          size="Large"
                          url={partner["partner"].imgUrl}
                          border={true}
                        />
                      <Flex
                        direction="column"
                        customStyle={{
                          gap: "4px",
                          justifyContent: "center",
                        }}
                      >
                          <Text
                            textType="Body2SemiBold"
                            color={COLOR_SYSTEM.get("Gray")[800]}
                            numberOfLines={1}
                          >
                            {partner["partner"].title}
                          </Text>
                        <Flex customStyle={{ gap: "4px", alignItems: "center" }}>
                          <Tag size="Small" variant="Ghost" color="Blue">
                            {CompanyTypeName[partner["partner"].category]}
                          </Tag>
                        </Flex>
                      </Flex>
                  </PartnerListWrapper>
                </div>
                </VisibilitySensor>
              ))
            }
          </Flex>
      </Flex>
    </>
  )
}

export default memo(withRouter(SearchedPartnerCard))
