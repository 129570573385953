
import React, { Component } from 'react';
import './../../Common.css';
import './ComposeCardnewsPopup.css'
import {log, LogLevel} from '../../utils/LogUtil'
import { Attachment } from '../../models/Model.Board';
import { IonModal, IonButton, IonIcon } from '@ionic/react';
import { loadImageBase64 } from '../../utils/ImageUtil';
import StringUtil from '../../utils/StringUtil';
import Textarea from 'react-textarea-autosize';

type Props = {
  hiddenText: string; 
  onDone: (hiddenText:string) => void;
  progressPopup: any;
};

type State = {
  visible: boolean;
  type: number;
  hiddenText: string; 
}

class ComposeCardnewsPopup extends Component<Props, State> {
  state={
    visible: false,
    type: 0,
    hiddenText: "",
};

  constructor(props:Props){
    super(props);

  }

  show = () => {
    log(LogLevel.UI_LIFECYCLE, "ComposeCardnewsPopup  :show")
    let hiddenText = this.props.hiddenText;
    if(!hiddenText)
      hiddenText = "";
    this.setState({visible:true, hiddenText});

  }

  hide = () => {
    log(LogLevel.UI_LIFECYCLE, "ComposeCardnewsPopup  :hide")
    this.setState({visible: false});
  }

  onDone = () => {
    log(LogLevel.UI_ACTION, "ComposeCardnewsPopup  :onDone", );
    if(this.props.onDone)
      this.props.onDone(this.state.hiddenText);
    this.hide();
  }

  isShown = () => {
    return this.state.visible;
  }
  
  render() {
    log(LogLevel.UI_LIFECYCLE, "ComposeCardnewsPopup  : render");

    return (
      <IonModal cssClass="common-modal-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {false}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >

        <div className="common-modal-popup-inner">
          <div className="common-modal-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
          <div className="common-modal-container">
            <div className="common-container-row common-flex-align-center">
              <div className="common-flex-grow">
                Hidden Text 편집기
              </div>
              <IonButton color="common-button" class="compose-cardnews-top-button compose-cardnews-top-button-other" onClick={this.hide}>
                취소
              </IonButton>
              <IonButton color="common-button" class="compose-cardnews-top-button compose-cardnews-top-button-done" onClick={this.onDone}>
                저장
              </IonButton>
            </div>

            <div className="common-container">
              <Textarea className="compose-cardnews-textarea" minRows={5} maxRows={20} value={this.state.hiddenText} onChange={(e) => {this.setState({hiddenText:e.target.value})}} onClick={(e)=>{e.stopPropagation();}}/>
            </div>
          </div>
          <div className="common-modal-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
        </div>
      </IonModal>
    );
  }
}

export default (ComposeCardnewsPopup);