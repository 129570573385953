import { action, createAction, createAsyncAction } from "typesafe-actions";
import { AllInOnePartnerInfo } from "../../models/Model.User";
import { AllInOneActionTypes } from "./types";

export const getPartnerList = createAction(
  AllInOneActionTypes.ALLINONE_PARTNERS_FETCH,
  (resolve) => () => resolve()
);

export const fetchPartnerList = createAsyncAction(
  AllInOneActionTypes.ALLINONE_PARTNERS_REQUEST,
  AllInOneActionTypes.ALLINONE_PARTNERS_SUCCESS,
  AllInOneActionTypes.ALLINONE_PARTNERS_ERROR
)<undefined, AllInOnePartnerInfo[], any>(); // <string, AllinPartner, Error>();

export const getBanners = createAction(
  AllInOneActionTypes.ALLINONE_BANNERS_FETCH,
  (resolve) => () => resolve()
);

export const fetchBanners = createAsyncAction(
  AllInOneActionTypes.ALLINONE_BANNERS_REQUEST,
  AllInOneActionTypes.ALLINONE_BANNERS_SUCCESS,
  AllInOneActionTypes.ALLINONE_BANNERS_ERROR
)<any, any, any>(); // <string, BannerList, Error>();
