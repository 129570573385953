import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { fetchPost, onChangeKeyword, setCurrentSearchedKeyword } from "../../../store/search";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Keyword from "./Keyword";
import KeywordList from "./KeywordList";

const HotKeywordList = () => {
  const dispatch = useDispatch();
  const hotKeywords = useSelector((state: RootState) => state.newSearch.post.hotKeywords);

  const handleClickKeyword = (hotKeyword: any) => () => {
    if (hotKeyword && hotKeyword.advertiserCode) {
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_CLICK", "광고클릭", {
        type: "추천키워드",
        code: hotKeyword.advertiserCode,
        productCode: hotKeyword.advertiserProductCode,
        contentType: "KEYWORD",
        contentId: hotKeyword.id,
        contentTitle: hotKeyword.keyword,
      });
    }
    dispatch(onChangeKeyword(hotKeyword.keyword));
    dispatch(setCurrentSearchedKeyword(hotKeyword.keyword));
    dispatch(fetchPost(true));
  };

  return (
    <div>
      <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
        인기 검색어
      </Text>
      <KeywordList>
        {hotKeywords.map((hotKeyword) => (
          <Keyword key={hotKeyword.id} {...hotKeyword} onClick={handleClickKeyword(hotKeyword)} />
        ))}
      </KeywordList>
    </div>
  );
};

export default HotKeywordList;
