import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useConfirm from "../../../hooks/useConfirm";
import { RootState } from "../../../store";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup from "../Popup";

const ConfirmLayout = styled.div`
  width: 100%;
  text-align: center;

  .title__text {
    white-space: pre-wrap;
  }
`;

const ConfirmIconBox = styled.div`
  margin-bottom: 22.5px;
`;

const ConfirmTitleBox = styled.div``;

const DescriptionBox = styled.div`
  margin-top: 12px;
`;

const ConfirmWarningBox = styled.div`
  margin-top: 24px;
  padding: 12px 16px;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  border-radius: 8px;

  ul {
    text-align: left;
    margin-top: 8px;
    padding-left: 32px;

    li {
      list-style: disc;

      &::marker {
        color: ${COLOR_SYSTEM.get("Gray")[600]};
        font-size: 11px;
      }
    }
  }
`;

const PopupButtonBox = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
  padding-top: 24px;

  button {
    flex: 1 1 33%;
  }
`;

const ConfirmDialog = () => {
  const { onCancel, onConfirm } = useConfirm();
  const { icon, img, title, description, descriptionType, warning, button } = useSelector((state: RootState) => state.confirm);
  const { cancel, confirm } = button;
  // const { name: iconName, width: iconWidth, height: iconHeight, color: iconColor } = icon;

  return (
    <Popup>
      <ConfirmLayout>
        {icon && (
          <ConfirmIconBox>
            <Icon name={icon.name} width={icon.width} height={icon.height} color={icon.color} />
          </ConfirmIconBox>
        )}
        {img && (
          <ConfirmIconBox>
            {img}
          </ConfirmIconBox>
        )}
        <ConfirmTitleBox>
          <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]} className="title__text">
            {title}
          </Text>
        </ConfirmTitleBox>
        <DescriptionBox>
          <Text textType={descriptionType || "Body1"} color={COLOR_SYSTEM.get("Gray")[600]} className="title__text">
            {description}
          </Text>
        </DescriptionBox>
        {warning && (
          <ConfirmWarningBox>
            <div>
              <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Gray")[800]} className="title__text">
                {warning.title}
              </Text>
            </div>
            <ul>
              {warning.descriptions.map((description, index) => (
                <li key={index}>
                  <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[800]} className="title__text">
                    {description}
                  </Text>
                </li>
              ))}
            </ul>
          </ConfirmWarningBox>
        )}
        <PopupButtonBox>
          {cancel && 
            <Button color={cancel.color || "Secondary"} size="Large" variant="Tinted" type="Text" onClick={onCancel}>
              {cancel.text}
            </Button>
          }
          <Button color={confirm.color || "Primary"} size="Large" variant={confirm.variant || "Contained"} type="Text" onClick={onConfirm}>
            {confirm.text}
          </Button>
        </PopupButtonBox>
      </ConfirmLayout>
    </Popup>
  );
};

export default ConfirmDialog;
