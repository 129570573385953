import { action, createAction, createAsyncAction } from "typesafe-actions";
import { UserWorkType } from "../../models/Model.User";
import { JobListState, JobPostActionTypes } from "./types";
import { JobOfferInfo, JobApplyInfo } from "../../models/Model.JobPost";
import { resolve } from "dns";
import { CouponInfo } from "../../models/Mode.Biz";

export const init = createAction(
  JobPostActionTypes.JOBPOST_INIT,
  (resolve) => (workType: UserWorkType) => resolve(workType)
);

export const reloadList = createAction(
  JobPostActionTypes.JOBPOST_RELOAD_LIST,
  (resolve) => (options: object, id: number) => resolve(options, id)
);

export const loadList = createAction(
  JobPostActionTypes.JOBPOST_LIST_FETCH,
  (resolve) => (id: number) => resolve(id)
);

export const updateOffer = createAction(
  JobPostActionTypes.JOBPOST_ORDER_UPDATE,
  (resolve) => (offer: JobOfferInfo) => resolve(offer)
);

export const visit = createAction(
  JobPostActionTypes.JOBPOST_VISIT,
  (resolve) => () => resolve()
);

export const countNew = createAction(
  JobPostActionTypes.JOBPOST_COUNT_NEW_RECOMMEND,
  (resolve) => (newCount: number) => resolve(newCount)
);

export const countNewApply = createAction(
  JobPostActionTypes.JOBPOST_COUNT_NEW_APPLY,
  (resolve) => () => resolve()
);

export const visitLeave = createAction(
  JobPostActionTypes.JOBPOST_VISIT_LEAVE,
  (resolve) => () => resolve()
);

export const updateApply = createAction(
  JobPostActionTypes.JOBPOST_APPLY_UPDATE,
  (resolve) => (apply: JobApplyInfo) => resolve(apply)
);

export const fetchList = createAsyncAction(
  JobPostActionTypes.JOBPOST_LIST_REQUEST,
  JobPostActionTypes.JOBPOST_LIST_SUCCESS,
  JobPostActionTypes.JOBPOST_LIST_ERROR
)<number, JobListState, number>(); // <string, EventList, Error>();

export const loadOffer = createAction(
  JobPostActionTypes.JOBPOST_ORDER_FETCH,
  (resolve) => (options: object, id: number) => resolve(options, id)
);

export const fetchOffer = createAsyncAction(
  JobPostActionTypes.JOBPOST_ORDER_REQUEST,
  JobPostActionTypes.JOBPOST_ORDER_SUCCESS,
  JobPostActionTypes.JOBPOST_ORDER_ERROR
)<number, JobOfferInfo, any>(); // <string, EventList, Error>();

export const loadApply = createAction(
  JobPostActionTypes.JOBPOST_APPLY_FETCH,
  (resolve) => (options: object, id: number) => resolve(options, id)
);

export const fetchApply = createAsyncAction(
  JobPostActionTypes.JOBPOST_APPLY_REQUEST,
  JobPostActionTypes.JOBPOST_APPLY_SUCCESS,
  JobPostActionTypes.JOBPOST_APPLY_ERROR
)<number, JobApplyInfo, any>(); // <string, EventList, Error>();

export const scrap = createAction(
  JobPostActionTypes.JOBPOST_SCRAP,
  (resolve) => (id: number) => resolve(id)
);

export const loadCoupon = createAction(
  JobPostActionTypes.JOBPOST_COUPON_FETCH,
  (resolve) => () => resolve()
);

export const fetchCoupon = createAsyncAction(
  JobPostActionTypes.JOBPOST_COUPON_REQUEST,
  JobPostActionTypes.JOBPOST_COUPON_SUCCESS,
  JobPostActionTypes.JOBPOST_COUPON_ERROR
)<any, CouponInfo, any>(); // <string, EventList, Error>();
