import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { IonSpinner } from "@ionic/react";
import "./ViewerReplyList.css";
import ViewerBody from "./ViewerBody";
import { BoardContent, BoardAttribute } from "./../models/Model.Board";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";
import { Flex, Static } from "./atoms/Layout";
import { COLOR_SYSTEM } from "./design/design-system";
import Text from "./atoms/Text";
import noReply from "../assets/image/no_reply.png";
import Button from "./atoms/Button";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    postingId?: number;
    posting?: BoardContent;
    noReply?: boolean;
    open?: boolean;
    onContentUpdate?: (posting: BoardContent, index: number) => void;
    findFocus?: any;
    onFindFocusComponent?: (focused: any) => void;
    noToolbar?: boolean;
    noComment?: boolean;
    noNavigate?: boolean;
    adminMode?: boolean;
  };

class ReplyList extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    log(LogLevel.NONE, "Viewer:componentDidMount");
  }

  onReply = () => {
    let postingId = this.props.postingId;
    if (!postingId && this.props.posting) {
      postingId = this.props.posting.id;
    }
    this.props.history.push(`/reply?id=${postingId}`);
  };

  render() {
    // if (this.props.noReply) {
    //   log(LogLevel.UI_LIFECYCLE, "ViewerReply, noReply");

    //   return null;
    // }

    let content;
    let replies;
    let anonymousNames;
    let rootAuthorId, rootAuthorName;
    if (this.props.posting) {
      content = this.props.posting;
      replies = this.props.posting.replies;
      anonymousNames = this.props.posting.anonymousNames;
      if (
        this.props.posting.boardsCategoriesAttribute &&
        !this.props.posting.boardsCategoriesAttribute.includes(BoardAttribute.UI_VIEWER_PROFILE_ON_POST) &&
        this.props.posting.boardsCategoriesAttribute.includes(BoardAttribute.UI_VIEWER_SHOW_ROOT_AUTHOR)
      ) {
        if (this.props.posting.boardsCategoriesAttribute.includes(BoardAttribute.IS_QNA)) rootAuthorName = "질문자";
        else if (this.props.posting.boardsCategoriesAttribute.includes(BoardAttribute.IS_DISCUSSION))
          rootAuthorName = "발제자";
        else rootAuthorName = "글쓴이";

        rootAuthorId = this.props.posting.usersId;
      }
    } else if (this.props.contents[this.props.postingId]) {
      content = this.props.contents[this.props.postingId];
      if (this.props.contents[this.props.postingId].replies) {
        replies = this.props.contents[this.props.postingId].replies;
        anonymousNames = this.props.contents[this.props.postingId].anonymousNames;
      }

      if (
        this.props.contents[this.props.postingId].boardsCategoriesAttribute &&
        !this.props.contents[this.props.postingId].boardsCategoriesAttribute.includes(
          BoardAttribute.UI_VIEWER_PROFILE_ON_POST
        ) &&
        this.props.contents[this.props.postingId].boardsCategoriesAttribute.includes(
          BoardAttribute.UI_VIEWER_SHOW_ROOT_AUTHOR
        )
      ) {
        if (this.props.contents[this.props.postingId].boardsCategoriesAttribute.includes(BoardAttribute.IS_QNA))
          rootAuthorName = "질문자";
        else if (
          this.props.contents[this.props.postingId].boardsCategoriesAttribute.includes(BoardAttribute.IS_DISCUSSION)
        )
          rootAuthorName = "발제자";
        else rootAuthorName = "글쓴이";

        rootAuthorId = this.props.contents[this.props.postingId].usersId;
      }
    }
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerReply.render",
      this.props.posting,
      this.props.postingId,
      this.props.contents[this.props.postingId],
      content,
      content ? content.replyCnt : 0,
      replies
    );

    if (!content) return null;

    if (content.replyCnt && !replies) {
      return (
        <div className="viewer-content-waiting-container">
          <IonSpinner class="viewer-content-waiting" name="crescent" />
        </div>
      );
    }
    if (!this.props.noReply  &&  (content.replyCnt === 0 || (replies && replies.length === 0))) {
      return (
        // null
        <Static
          customStyle={{
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
            marginTop: "24px",
            padding: "20px",
          }}
          onClick={() => {
            this.props.history.push(`/reply?id=${content.id}`);
          }}
        >
          <Flex
            alignItems="center"
            gap="20px"
          >
            <Static customStyle={{ flex: 1 }}>
              <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[700]} numberOfLines={2}>
                아직 등록된 의견이 없습니다. <br/>첫 번째 의견을 남겨주세요.
              </Text>
            </Static>
            <Button color="Primary" size="Medium" variant="Tinted" type="Text">
              의견 남기기
            </Button>
          </Flex>
          <Flex
            gap="12px"

            customStyle={{
              backgroundColor: COLOR_SYSTEM.get("Gray")[20],
              marginTop: "20px",
              padding: "16px",
              borderRadius: "12px",
            }}
          >
            <Static><Text textType={"Body2Bold"} color={COLOR_SYSTEM.get("Gray")[700]}>💡</Text></Static>
            <Flex direction="column" gap="4px"> 
              <Text textType={"Body2Bold"} color={COLOR_SYSTEM.get("Gray")[700]}>PC에서도 약문약답을 이용할 수 있어요!</Text>
              <Text textType={"Body2"} color={COLOR_SYSTEM.get("Gray")[700]}>
                포털사이트에서 약문약답을 검색해보세요.<br/>
                더 편리하게 의견을 남길 수 있어요.
              </Text>
            </Flex>
          </Flex>

        </Static>
      );
    } else if (!replies) return null;

    return (
      <div>
        {(!this.props.noReply  ||  content.replyCnt > 0 || replies && replies.length > 0) && 
          <Static
            customStyle={{
              padding: "40px 16px 16px",
              border: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
            }}
          >
            <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[700]}>
              <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Skyblue")[400]}>
                {content.replyCnt}
              </Text>
              개의 의견이 있습니다.
            </Text>
          </Static>
        }


        <Flex direction="column" gap="20px">
          {replies.map((item, index) => (
            <ViewerBody
              open={this.props.open}
              key={index.toString()}
              posting={item}
              postingId={this.props.postingId}
              replyIndex={index}
              noToolbar={this.props.noToolbar}
              noEmptySubject={true}
              noViewCnt={true}
              noKakaoShare={true}
              noScrap={true}
              noComment={this.props.noComment}
              onContentUpdate={this.props.onContentUpdate}
              findFocus={this.props.findFocus}
              onFindFocusComponent={this.props.onFindFocusComponent}
              anonymousNames={anonymousNames}
              noNavigate={this.props.noNavigate}
              adminMode={this.props.adminMode}
              rootAuthorId={rootAuthorId}
              rootAuthorName={rootAuthorName}
            />
          ))}
        </Flex>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  contents: state.board.contents,
});

const mapDispatchToProps = {
  // logOutUser: () => actions.user.logout(),
  // refreshBoards: () => actions.board.refreshBoards(),
  // loadBoard: (board:BoardType) => actions.board.getBoard(board.toString(), 50),
  loadContent: (id: number) => actions.board.getContent(id),
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReplyList));
