

import React, { Component } from 'react';
import './../Admin.scss';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonActionSheet, IonCheckbox } from '@ionic/react';
import {log, LogLevel} from '../../utils/LogUtil'
import 'react-quill/dist/quill.snow.css'; // ES6
import { EventInfo, EMPTY_EVENT } from '../../models/Model.Event';
import FileInputButton from '../../components/FileInputButton';
import { loadImageBase64 } from '../../utils/ImageUtil';
import { fetchAPI } from '../../utils/APIUtil';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import * as API from './../../API.json';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import EventPopup from '../../components/EventPopup';
import { getDateStringFromToday } from '../../utils/TimeUtil';
import StringUtil from '../../utils/StringUtil';
import Textarea from 'react-textarea-autosize';
import { CONDITION_USER_OWNER, CONDITION_USER_EMPLOYEE } from '../../utils/ConditionCheckerUtil';
import { UIPopupType } from '../../store/ui/types';


const ADMIN_API = {        
  "EVENT_ADD" : {"method":"post", "path":"/admin/event", "contentType":"application/json"},
  "EVENT_UPDATE" : {"method":"put", "path":"/admin/event", "contentType":"application/json"},
  "EVENT_DELETE" : {"method":"delete", "path":"/admin/event", "contentType":"application/json"},
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onDone: (event:EventInfo) => void;
  event?: EventInfo;
};

type State = {
  id?: number;
  name?: string;
  imageUrl?: string;
  imageBase64?: string;
  html?: string;
  isFull?: boolean;
  originUrl?: string;
  link?: string;
  startDate?: string;
  endDate?: string;
  bgColor?: string;
  ignoreDuration?: number;
  edited: boolean;
  condition?: any;
  conditionStr: string;
  log?: any;
  logStr: string;
}

class EventEdit extends Component<Props, State> {
  eventPopupPreview: any =  null;

  constructor(props:Props){
    super(props);
    this.state = {
      ...EMPTY_EVENT,
      edited: false,
      conditionStr: "",
      logStr:"",
    }

  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.event && (!this.props.event || nextProps.event.id != this.props.event.id)){
      let conditionStr = "";
      if(nextProps.event.condition)
        conditionStr = JSON.stringify(nextProps.event.condition, null, "\t");
      
      let logStr = "";
      if(nextProps.event.log)
        logStr = JSON.stringify(nextProps.event.log, null, "\t");

      this.setState({
        ...nextProps.event,
        startDate: getDateStringFromToday({date:nextProps.event.startDate}),
        endDate: getDateStringFromToday({date:nextProps.event.endDate}),
        conditionStr,
        logStr,
        edited: false,
      })
      log(LogLevel.UI_LIFECYCLE, "EventEdit:componentWillUpdate", nextProps.event, nextState, this.props, this.state);
    }else if (!nextProps.event && this.props.event){
      this.setState({
        ...EMPTY_EVENT,
        conditionStr:"",
        logStr:"",
        edited: false,
      })
      log(LogLevel.UI_LIFECYCLE, "EventEdit:componentWillUpdate", nextProps.event, nextState, this.props, this.state);
    }
  }

  onCancel = () => {
    this.props.onDone(null);
  }

  onDone = async () => {
    let condition;
    if(this.state.conditionStr){
      try{
        condition = JSON.parse(this.state.conditionStr);
      }catch(e){
        this.props.toastPopup.show("JSON Parsing Error")
        return;
      }
    }

    let logObj;
    if(this.state.logStr){
      try{
        logObj = JSON.parse(this.state.logStr);
      }catch(e){
        this.props.toastPopup.show("JSON Parsing Error")
        return;
      }
    }

    if(this.state.imageBase64){
      try {
        let data:any = {path:"event"}
        data.base64 = this.state.imageBase64;
        let result = await fetchAPI(API.UPLOAD_BASE64, '', null, data, getGlobal(GlobalKey.TOKEN));
        if(result && !result.error){
            let url = result.file;
            this.setState({imageUrl:url, imageBase64:""});
            log(LogLevel.UI_ACTION, 'EventEdit.onDone upload : ', result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        }else{
            log(LogLevel.UI_ACTION, "EventEdit.onDone failed", result);
        }

      } catch (error) {
          log(LogLevel.UI_ACTION, "EventEdit.onDone : failed", error);
          // this.props.navigation.push("AuthRegister");
      }
    }

    if(this.state.id){
      let event = {...this.state}
      if(condition)
        event.condition = condition;
      if(logObj)
        event.log = logObj;
      let result = await fetchAPI(ADMIN_API.EVENT_UPDATE, '', null, event, getGlobal(GlobalKey.TOKEN))
      this.props.onDone(event);
    }
    else{
      let event = {...this.state}
      if(condition)
        event.condition = condition;
      if(logObj)
        event.log = logObj;
      let result = await fetchAPI(ADMIN_API.EVENT_ADD, '', null, event, getGlobal(GlobalKey.TOKEN))
      this.setState({id:result.data})
      event.id = result.data;
      this.props.onDone(event);
    }
  }
  
  onDelete = async () => {
    //delete
    if(this.state.id){
      let result = await fetchAPI(ADMIN_API.EVENT_DELETE, '/'+this.state.id, null, null, getGlobal(GlobalKey.TOKEN))
      this.props.onDone(null);
    }
  }

  onPreview = () => {
    let event = {...this.state}
    this.eventPopupPreview.show(event);
  }


  onImageSelected = async (e) => {
    // console.dir(e);
    log(LogLevel.UI_ACTION, "ViewerCommentComposer:onImageSelected", e.target.files[0])
    console.dir(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0]).then((base64:string) => {
        this.setState({imageBase64:base64});
      });
    }
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "EventEdit:render", this.props.event, this.state);
    let image;
    if(this.state.imageBase64){
      image = <img src={this.state.imageBase64}/>
    }else if(this.state.imageUrl){
      image = <img src={StringUtil.convertFilePath(this.state.imageUrl)}/>
    }

    return (
      <div className="admin-content-manage-container">
        <div className="user-manage-title">ID: {this.state.id}</div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">이름 :</div>
          <IonInput class="user-search-input" placeholder="이벤트명" value={this.state.name} onIonChange={(e) => this.setState({name:e.detail.value, edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">이미지 :</div>
          <IonInput class="user-search-input" placeholder="Image URL" value={this.state.imageUrl} onIonChange={(e) => this.setState({imageUrl:e.detail.value, edited:true})}/>
          <FileInputButton className="admin-file-button" iconName="image" onSelect={this.onImageSelected} accept="image/*"/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">표시위치 :</div>
          <IonInput class="user-search-input" placeholder="표시 URL ex. /" value={this.state.originUrl} onIonChange={(e) => this.setState({originUrl:e.detail.value, edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">Link :</div>
          <IonInput class="user-search-input" placeholder="Link" value={this.state.link} onIonChange={(e) => this.setState({link:e.detail.value, edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <IonCheckbox checked={this.state.isFull} onIonChange={(e) => {log(LogLevel.UI_EVENT,"IonCheckbox changed", e.detail.checked);this.setState({isFull:e.detail.checked, edited:true});}}/>
          <div className="user-manage-title"> 전체화면 </div>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">시작일자 :</div>
          <IonInput class="user-search-input" placeholder="시작일자 ex) 2019-01-02" value={this.state.startDate} onIonChange={(e) => this.setState({startDate:e.detail.value, edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">종료일자 :</div>
          <IonInput class="user-search-input" placeholder="종료일자 ex) 2019-01-02" value={this.state.endDate} onIonChange={(e) => this.setState({endDate:e.detail.value, edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">배경색 :</div>
          <IonInput class="user-search-input" placeholder="배경색 ex) #FFF" value={this.state.bgColor} onIonChange={(e) => this.setState({bgColor:e.detail.value, edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">숨기기 간격 :</div>
          <IonInput class="user-search-input" placeholder="일단위" value={this.state.ignoreDuration?this.state.ignoreDuration.toString():"0"} onIonChange={(e) => this.setState({ignoreDuration:parseInt(e.detail.value), edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">조건 (Oscar 문의)</div>
          <div className="admin-text-button" onClick={() => this.setState({conditionStr:JSON.stringify(CONDITION_USER_OWNER, null, "\t")})}>약국장</div>
          <div className="admin-text-button" onClick={() => this.setState({conditionStr:JSON.stringify(CONDITION_USER_EMPLOYEE, null, "\t")})}>근무약사</div>
        </div>
        <Textarea className="admin-text-area" minRows={3} maxRows={15} value={this.state.conditionStr} onChange={(e) => {this.setState({conditionStr:e.target.value})}} onClick={(e)=>{e.stopPropagation();}}/>
        <div className="common-container-row common-flex-align-center">
          <div className="user-manage-title">로그 (Oscar 문의)</div>
          <div className="admin-text-button" onClick={() => this.setState({logStr:JSON.stringify({                  
                  type:"", 
                  code : "",
                  productCode: "",
                  contentType: "",
                  contentId: 0,
                  contentTitle: "",
                  gsid: "", 
                  gstid: "", 
                  shid: "",
              }, null, "\t")})}>기본로그</div>
        </div>
        <Textarea className="admin-text-area" minRows={3} maxRows={15} value={this.state.logStr} onChange={(e) => {this.setState({logStr:e.target.value})}} onClick={(e)=>{e.stopPropagation();}}/>
        
        <div className="admin-content-manage-button-container">
          <IonButton color="admin-content-manage-cancel-button" onClick={this.onCancel}>
            취소
          </IonButton>     
          <IonButton color="admin-content-manage-button" onClick={this.onPreview}>
            미리보기
          </IonButton>     
          <IonButton color="admin-content-manage-button" onClick={this.onDone}>
            저장
          </IonButton>  
          <IonButton color="admin-content-delete-button" onClick={this.onDelete}>
            삭제 {(this.props.event&&this.props.event.deletedAt)?"취소":""}
          </IonButton>  
        </div>   
        {image}
        <EventPopup ref={(ref) => this.eventPopupPreview = ref}/>
      </div>
    );

  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath : state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);