import React from "react";
import styled from "styled-components";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { COLOR_SYSTEM } from "../../design/design-system";
import SegmentedControl from "../../molecules/SegmentedControl/SegmentedControl";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";

export interface DistanceBottomSheetModalProps {
  distance?: number;
  onSelect: (distance?: number) => void;
  onClose: () => void;
  active?: boolean;
}

interface DistanceBottomSheetModalWrapperProps {
  active?: boolean;
}

const DistanceBottomSheetModalWrapper = styled.div<DistanceBottomSheetModalWrapperProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 360px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  transform: ${({ active }) => `translateY(${active ? "0" : "100%"})`};
  transition: transform 0.4s ease-in-out;
  border-radius: 20px 20px 0px 0px;
`;

const Header = styled.header`
  padding-left: 12px;
  height: 40px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0px 24px;
  margin-bottom: 8px;

  .item-text {
    flex-grow: 1;
  }
`;

const Container = styled.div<DistanceBottomSheetModalWrapperProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ active }) => (active ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)")};
  -moz-transition: all 300ms ease-in;
  /* WebKit */
  -webkit-transition: all 300ms ease-in;
  /* Opera */
  -o-transition: all 300ms ease-in;
  /* Standard */
  transition: all 300ms ease-in;

  align-items: flex-end;
  justify-content: center;
  display: ${({ active }) => (active ? "flex" : "none")};
`;

const DistanceBottomSheetModal = ({ active, distance, onClose, onSelect }: DistanceBottomSheetModalProps) => {
  return (
    <Container active={active} onClick={onClose}>
      <DistanceBottomSheetModalWrapper
        active={active}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Header></Header>
        <main>
          <Item onClick={() => onSelect(5000)}>
            <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[800]} className="item-text">
              약국 주변 5km 이내
            </Text>
            <Icon
              name="Check"
              size={24}
              color={distance == 5000 ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]}
            />
          </Item>
          <Item onClick={() => onSelect(10000)}>
            <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[800]} className="item-text">
              약국 주변 10km 이내
            </Text>
            <Icon
              name="Check"
              size={24}
              color={distance == 10000 ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]}
            />
          </Item>
          <Item onClick={() => onSelect(20000)}>
            <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[800]} className="item-text">
              약국 주변 20km 이내
            </Text>
            <Icon
              name="Check"
              size={24}
              color={distance == 20000 ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]}
            />
          </Item>
          <Item onClick={() => onSelect(30000)}>
            <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[800]} className="item-text">
              약국 주변 30km 이내
            </Text>
            <Icon
              name="Check"
              size={24}
              color={distance == 30000 ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]}
            />
          </Item>
        </main>
      </DistanceBottomSheetModalWrapper>
    </Container>
  );
};

export default DistanceBottomSheetModal;
