import React from "react";
import {
  getAfterTaxPayString,
  JobOfferInfo,
  JobPostRegistrationForm,
} from "../../../models/Model.JobPost";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import {
  PopupDetailInformationContainer,
  PopupDetailInformationItem,
} from "../../templates/JobPostRegistration/style";
import { PopupBody, PopupFooter } from "./style";

interface AfterTaxPopupProps extends PopupProps {
  preview: boolean;
  form: JobPostRegistrationForm;
  post: JobOfferInfo;
}

const AfterTaxPopup: React.FC<AfterTaxPopupProps> = ({
  preview,
  form,
  post,
  onClose,
}) => {
  return (
    <Popup onClose={onClose}>
      <PopupBody>
        <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          예상되는 세후 급여는 <br />
          <Text textType="H4" color={COLOR_SYSTEM.get("Skyblue")[400]}>
            {getAfterTaxPayString(
              preview
                ? {
                    salaryType: form.salaryType,
                    taxType: form.taxType,
                    salaryRangeLow:
                      Number(form.salaryRangeHigh.replace(/,/g, "")) / 10000,
                    salaryRangeHigh:
                      Number(form.salaryRangeHigh.replace(/,/g, "")) / 10000,
                  }
                : post
            )}
          </Text>
          <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
            &nbsp;입니다.
          </Text>
        </Text>

        <PopupDetailInformationContainer
          textType="H4"
          color={COLOR_SYSTEM.get("Gray")[800]}
        >
          <PopupDetailInformationItem>
            <div className={"title"}>
              <Text textType="Body2Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
                세금 공제 기준
              </Text>
            </div>
          </PopupDetailInformationItem>
          <PopupDetailInformationItem>
            <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[800]}>
              공제 가족수 : 1명 <br />
              비과세 급여 : 0원 <br />
              4대보험요율 : 2024년 기준
            </Text>
          </PopupDetailInformationItem>
        </PopupDetailInformationContainer>
      </PopupBody>
      <PopupFooter>
        <Button
          color="Primary"
          size="Large"
          type="Text"
          variant="Contained"
          onClick={onClose}
        >
          닫기
        </Button>
      </PopupFooter>
    </Popup>
  );
};

export default AfterTaxPopup;
