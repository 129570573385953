import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../store";
import { ButtonStyle } from "../../store/popup";
import Button from "../atoms/Button";
import { COLOR_SYSTEM } from "../design/design-system";
import { SHADOW } from "../design/effects";

const PopupLayout = styled.div<{ height?: string }>`
  max-width: 350px;
  width: 90%;
  height: ${({ height }) => (height ? height : "auto")};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 40px 24px 24px 24px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  border-radius: 20px;
  ${SHADOW.get("4")};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  & > * + * {
    margin-top: 40px;
  }
`;

const Shadow = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000;
  margin-top: 0 !important;
`;

const PopupButtonCol = styled.div`
  width: 100%;
`;

const PopupButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  & > button {
    flex: 1 1 33%;
  }
`;

const PopupTest = () => {
  const { body, buttons, height } = useSelector((state: RootState) => state.popup);

  return (
    <Shadow
      onClick={(e) => {
        e.stopPropagation();
        // if (closeOnBackDrop) onClose();
      }}
    >
      <PopupLayout height={height}>
        {body}
        <PopupButtonCol>
          {buttons.map((buttonRow: ButtonStyle[]) => (
            <PopupButtonRow>
              {buttonRow.map((button: ButtonStyle) => (
                <Button
                  key={button.value}
                  type={button.type || "Text"}
                  color={button.color || "Primary"}
                  size={button.size || "Large"}
                  variant={button.variant || "Contained"}
                  onClick={button.onClick}
                >
                  {button.value}
                </Button>
              ))}
            </PopupButtonRow>
          ))}
        </PopupButtonCol>
      </PopupLayout>
    </Shadow>
  );
};

export default PopupTest;
