import React, { useRef } from "react";
import { getPayString, JobOfferInfo } from "../../../models/Model.JobPost";
import { JobListState } from "../../../store/jobpost/types";
import { log, LogLevel } from "../../../utils/LogUtil";
import Button from "../../atoms/Button";
import JobPostCard from "../JobPostCard";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { throttle } from "lodash";

interface LoadJobPostHistoryProps {
  onClose?: (params?: any) => any;
  myHistory?: JobListState;
  onSelect?: (offer: JobOfferInfo) => void;
  offers?: any;
  onGetMorePosts: () => void;
}

const Header = styled.header`
  width: 100%;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Main = styled.main`
  padding: 60px 24px;
  overflow-y: auto;

  & > * + * {
    margin-top: 20px;
  }
`;

const LoadJobPostHistory: React.FC<LoadJobPostHistoryProps> = ({
  onClose,
  myHistory,
  onSelect,
  offers,
  onGetMorePosts,
}) => {
  log(LogLevel.UI_LIFECYCLE, "LoadJobPostHistory:render", myHistory);

  const HistoryListEl = useRef<HTMLDivElement>();

  const handlePostListThrottleScroll = throttle(async (e: any) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;

    if (clientHeight + scrollTop >= scrollHeight * 0.7) {
      onGetMorePosts();
    }
  }, 300);

  return (
    <>
      <Header>
        <TopAppBar
          leftButtons={[
            <Button color="Secondary" icon="CaretLeft" onClick={onClose} size="Medium" type="Icon" variant="Ghost" />,
          ]}
          title="이전 공고 불러오기"
          titleType="H4"
        />
      </Header>
      <Main
        ref={HistoryListEl}
        onScroll={(e) => {
          e.persist();
          handlePostListThrottleScroll(e);
        }}
      >
        {myHistory &&
          myHistory.items &&
          myHistory.items.length &&
          myHistory.items.map((item) => {
            return (
              <JobPostCard
                key={item.id}
                post={offers[item.id]}
                isOwner={true}
                pay={getPayString(item)}
                history
                cardType={"Me"}
                onReuse={() => {
                  onSelect(offers[item.id]);
                }}
              />
            );
          })}
      </Main>
    </>
  );
};

export default LoadJobPostHistory;
