

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../SeminarManage.css';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonToolbar, IonButtons, IonToggle, IonCheckbox } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey, setGlobal } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import ProfileImage from '../../../components/ProfileImage';
import { SeminarLecture } from '../../../models/Model.Seminar';
import { defaultNewLecture } from '../../SeminarManage';
import { threadId } from 'worker_threads';
import { loadImageBase64 } from '../../../utils/ImageUtil';
import * as API from '../../../API.json';import ReactQuill from 'react-quill';
import Textarea from 'react-textarea-autosize';
import { getDateStringFromToday, getDateTimeString } from '../../../utils/TimeUtil';
import DownloadUtil from '../../../utils/DownloadUtil';
import { PieChart, Pie, Cell, Line, ComposedChart , Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';


const AdminAPI = {        
  "USERS_STATISTICS" : {"method":"POST", "path":"/admin/user/distribute/statistics", "contentType":"application/json"},
  "USERS_STATISTICS_DOWNLOAD" : {"method":"POST", "path":"/admin/user/distribute/download", "contentType":"application/json", "accept":"text/csv"},
};

type Props = {
};

type State = {
  statisticsFold: boolean,
  statistics: UserDistributionStatistics,
  statisticsDateStart:string;
  statisticsDateStartWarn : boolean,
  statisticsDateEnd:string;
  statisticsDateEndWarn : boolean,
  statisticsActiveOnly : boolean,
  statisticsActiveStandard : number,
}

interface UserDistributionStatistics{
  startDate?:string,
  endDate?:string,
  all?:number,
  gender?: any[],
  workType?: any[],
  age?: any[],
  region?: any[],
}

class UserDistributionStatisticsView extends Component<Props, State> {
  statisticsLoading = false;
  statisticsLoaded = false;

  state = {
    statisticsFold:true,
    statistics: null,
    statisticsDateStart: getDateStringFromToday({byMonth: true, date:"2019-07-07"}),
    statisticsDateEnd: getDateStringFromToday({days:-4}),
    statisticsDateStartWarn:false,
    statisticsDateEndWarn:false,
    statisticsActiveOnly : false,
    statisticsActiveStandard : 10,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // this.fetchConsults();
  }
  
  onDateChange = (e, field) => {
    let value = e.target.value;
    let warn = false;
    try{
      let date = Date.parse(value);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "Admin:UserDistributionStatisticsView:onConsultsDateEndChange", value);
        warn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "Admin:UserDistributionStatisticsView:onConsultsDateEndChange", value, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "Admin:UserDistributionStatisticsView:onConsultsDateEndChange", value, err);
      warn = true;
    }

    let newState:any = {};
    newState[field] = value;
    newState[field+"Warn"] = warn;

    this.setState(newState);
  }

  setStatisticsPeriod = (startOption, endOption) => {
    let statisticsDateStart, statisticsDateEnd;
    statisticsDateStart= getDateStringFromToday(startOption);
    statisticsDateEnd= getDateStringFromToday(endOption);
    this.setState({
      statisticsDateStart,
      statisticsDateStartWarn : false,
      statisticsDateEnd,
      statisticsDateEndWarn : false,
    })
  }

  fetchStatistics = () => {
    if(this.state.statisticsDateStartWarn || this.state.statisticsDateEndWarn)
      return;
      
    log(LogLevel.UI_DATA_LOAD, "Admin:UserDistributionStatisticsView:fetchData");

    if(this.statisticsLoading)
      return;
    this.statisticsLoading = true;
    let request:any = {};
    request.startDate = this.state.statisticsDateStart;
    request.endDate = this.state.statisticsDateEnd;
    if(this.state.statisticsActiveOnly)
      request.active = this.state.statisticsActiveStandard;

    fetchAPI(AdminAPI.USERS_STATISTICS, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        log(LogLevel.UI_DATA_LOAD, "Admin:UserDistributionStatisticsView:fetchData result", result);
        this.setState({statistics: result.data});
      }
      else{
        log(LogLevel.UI_DATA_LOAD, "Admin:UserDistributionStatisticsView:fetchData result", result);
        this.setState({statistics: null});
      }
      this.statisticsLoaded = true;
      this.statisticsLoading = false;
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "Admin:UserDistributionStatisticsView:fetchData exception", e);
      this.setState({statistics: null});
      this.statisticsLoading = false;
    });
  }

  onDownloadDetail = () => {
    if(!this.state.statistics)
      return;

    if(this.statisticsLoading)
      return;
    this.statisticsLoading = true;

    let request:any = {};
    let activeString = ""
    request.startDate = this.state.statisticsDateStart;
    request.endDate = this.state.statisticsDateEnd;
    if(this.state.statisticsActiveOnly) {
      request.active = this.state.statisticsActiveStandard;
      activeString = "-Active-" + request.active.toString();
    }

    fetchAPI(AdminAPI.USERS_STATISTICS_DOWNLOAD, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      log(LogLevel.UI_DATA_LOAD, "Admin:UserManage", result);

      if(result)
        DownloadUtil.downloadCsv(result, `User-Statics-${this.state.statisticsDateStart.replace(/-/ig, '')}-${this.state.statisticsDateEnd.replace(/-/ig, '')}${(this.state.statisticsActiveOnly?"-Active"+this.state.statisticsActiveStandard:"")}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`);
    })  
    this.statisticsLoading = false;

  }

  download = (filename, text) => {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
  }
    
  onDownloadStatistics = () => {
    if(!this.state.statistics)
      return;
    

    let csv = "사용자 통계\r\n";
    csv += '검색기간,"'+ this.state.statisticsDateStart + "~" + this.state.statisticsDateEnd + '"\r\n';
    if(this.state.statisticsActiveOnly){
      csv += '기준,"활성사용자 (최소조회수:' + this.state.statisticsActiveStandard + ')"\r\n';
    }
    csv += '회원수,"'+ this.state.statistics.all + '"\r\n';
    csv += '\r\n';

    if(this.state.statistics.workType){
      csv += '근무타입 분포\r\n';
      csv += '근무타입,회원수,비율\r\n';
      csv += this.state.statistics.workType.map((item, index) => `"${item.name}","${item.value}","${item.ratio}%"`).join("\r\n");
      csv += '\r\n\r\n';
    }

    if(this.state.statistics.region){
      csv += '지역 분포\r\n';
      csv += '지역,회원수,비율\r\n';
      csv += this.state.statistics.region.map((item, index) => `"${item.name}","${item.value}","${item.ratio}%"`).join("\r\n");
      csv += '\r\n\r\n';
    }
    
    if(this.state.statistics.gender){
      csv += '성별 분포\r\n';
      csv += '성별,회원수,비율\r\n';
      csv += this.state.statistics.gender.map((item, index) => `"${item.name}","${item.value}","${item.ratio}%"`).join("\r\n");
      csv += '\r\n\r\n';
    }
    
    if(this.state.statistics.age){
      csv += '연령 분포\r\n';
      csv += '연령,회원수,비율,누적비율\r\n';
      csv += this.state.statistics.age.map((item, index) => `"${item.name}","${item.value}","${item.ratio}%","${item.ratioAccumulated}%"`).join("\r\n");
      csv += '\r\n\r\n';
    }
    
    // csv += this.state.statistics.items.map((item) => item.date).join(",");
    // csv += "\r\n";
    // csv += this.statisticsShowfilters.map((item, index) => {
    //   let results = this.state.statistics;
    //   return (
    //     item.name + "," + this.state.statistics.items.map((item2) => item2[item.field].toString()).join(",") + "\r\n"
    //   );
    // }).join("");

    DownloadUtil.downloadCsv(csv, `User-Statics-${this.state.statisticsDateStart.replace(/-/ig, '')}-${this.state.statisticsDateEnd.replace(/-/ig, '')}${(this.state.statisticsActiveOnly?"-Active"+this.state.statisticsActiveStandard:"")}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`);
  }

  openStatistics = () => {
    if(!this.statisticsLoaded)
      this.fetchStatistics();
    this.setState({statisticsFold:false})
  }

  render () {
      if(this.state.statisticsFold){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={this.openStatistics}>
            <div className="common-flex-row">
              <div>사용자 통계</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    log(LogLevel.UI_LIFECYCLE, "Admin:UserDistributionStatisticsView:render", this.state);

    let statisticsPeriodOptions:any[] =
      [
        {name:"4주간", start:{byWeek: true, weeks: -4}, end:{days:1}}, 
        {name:"8주간", start:{byWeek: true, weeks: -8}, end:{days:1}}, 
        {name:"4개월간", start:{byMonth: true, months: -4}, end:{days:1}}, 
        {name:"8개월간", start:{byMonth: true, months: -8}, end:{days:1}}, 
        {name:"12개월간", start:{byMonth: true, months: -12}, end:{days:1}}, 
        {name:"서비스개시일부터", start:{byMonth: true, date:"2019-07-07"}, end:{days:1}},
        {name:"지난달", start:{byMonth: true, months: -1}, end:{byMonth: true}}
      ];

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({statisticsFold:true})}>
          <div className="common-flex-row">
            <div>사용자 통계</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Search Option</div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <input className={(this.state.statisticsDateStartWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.statisticsDateStart} onChange={(e) => this.onDateChange(e, "statisticsDateStart")}/>
                <div>~</div>
                <input className={(this.state.statisticsDateEndWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.statisticsDateEnd} onChange={(e) => this.onDateChange(e, "statisticsDateEnd")}/>
              </div>
              <div className="common-container-row-wrap admin-margin-bottom"> 
                {statisticsPeriodOptions.map((item, index) => 
                  <div key={index.toString()} className="admin-text-button" onClick={() => this.setStatisticsPeriod(item.start, item.end)}>{item.name}</div>
                )}               
              </div>
              <div className="common-container-row-wrap admin-margin-bottom"> 
                <IonCheckbox checked={this.state.statisticsActiveOnly} onClick={(e) => {this.setState({statisticsActiveOnly: !this.state.statisticsActiveOnly});}}/>
                <div className="admin-margin-right">활성사용자 최소조회수:</div>    
                <input disabled={!this.state.statisticsActiveOnly} placeholder="최소조회수" type="number" value={this.state.statisticsActiveStandard} onChange={(e) => this.setState({statisticsActiveStandard:parseInt(e.target.value)})}/>
              </div>
            </div>
          </div>
          <div>
            <div className="common-container-column">
              <IonButton onClick={this.fetchStatistics}>Load</IonButton>
              <IonButton color="primary" disabled={!(this.state.statistics)} onClick={this.onDownloadStatistics}>Download</IonButton>
              <IonButton color="primary" disabled={!(this.state.statistics)} onClick={this.onDownloadDetail}>상세정보</IonButton>
            </div>
          </div>
        </div>
        {this.renderStatisticsGraph()}
      </div>
    );
  }

  COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#8088FE', '#80C49F', '#8F3B88', '#FF80ff'];

  renderStatisticsGraph = () => {
    if(!this.state.statistics)
      return null;

    return(
      <div className="common-container">
        <div className="admin-title admin-margin-bottom">전체 회원 : {this.state.statistics.all}</div>
        <div className="common-container-row admin-margin-bottom">
          <div className="common-flex-column common-flex-align-center">
            <div className="admin-title common-header-title-center">근무형태</div>
            <PieChart width={400} height={400}>
              {this.state.statistics.workType?
                <Pie data={this.state.statistics.workType} dataKey="value" labelLine={false} label={this.renderWorkTypeLabel}>
                  {
                    this.state.statistics.workType.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                  }
                </Pie>
              :null}
              <Tooltip content={this.renderTooltip}/>
              <Legend />
            </PieChart>
          </div>
          <div className="common-flex-column common-flex-align-center">
            <div className="admin-title common-header-title-center">근무지역</div>
            <PieChart width={400} height={400}>
              {this.state.statistics.region?
                <Pie data={this.state.statistics.region} dataKey="value" labelLine={false} label={this.renderRegionLabel}>
                  {
                    this.state.statistics.region.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                  }
                </Pie>
              :null}
              <Tooltip content={this.renderTooltip}/>
              <Legend />
            </PieChart>
          </div>
        </div>
        <div className="common-container-row admin-margin-bottom">
          <div className="common-flex-column common-flex-align-center">
            <div className="admin-title common-header-title-center">성별</div>
            <PieChart width={400} height={400}>
              {this.state.statistics.gender?
                <Pie data={this.state.statistics.gender} dataKey="value" labelLine={false} label={this.renderGenderLabel}>
                  {
                    this.state.statistics.gender.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                  }
                </Pie>
              :null}
              <Tooltip content={this.renderTooltip}/>
              <Legend />
            </PieChart>
          </div>
          <div className="common-flex-column common-flex-align-center">
            <div className="admin-title common-header-title-center">연령</div>
            <ComposedChart  width={400} height={400} data={this.state.statistics.age}>
              {this.state.statistics.age?
                <Bar yAxisId="left" dataKey="value" fill="#8884d8" barSize={5}/>
              :null}
              {this.state.statistics.age?
                <Line yAxisId="right" type="linear" dataKey="ratioAccumulated" stroke="#881313"/>
              :null}
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" scale="auto"/>
              <YAxis yAxisId="right" orientation="right" scale="auto"/>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={this.renderTooltip}/>
            </ComposedChart >
          </div>
        </div>
      </div>
    );
  }

  renderWorkTypeLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    if(percent < 0.1)
      return null;
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN)-50;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    log(LogLevel.UI_LIFECYCLE,"Admin:UserDistributionStatisticsView:renderWorkTypeLabel", cx, cy, radius, x, y)
    return (
      <text x={x} y={y} fill="white" textAnchor={"start"} dominantBaseline="central">
        {`${this.state.statistics.workType[index].name}\r\n${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderRegionLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    if(percent < 0.1)
      return null;
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN)-50;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    // log(LogLevel.UI_LIFECYCLE,"Admin:UserDistributionStatisticsView:renderWorkTypeLabel", cx, cy, radius, x, y)
    return (
      <text x={x} y={y} fill="white" textAnchor={"start"} dominantBaseline="central">
        {`${this.state.statistics.region[index].name}\r\n${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderGenderLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    if(percent < 0.1)
      return null;
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN)-50;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    // log(LogLevel.UI_LIFECYCLE,"Admin:UserDistributionStatisticsView:renderWorkTypeLabel", cx, cy, radius, x, y)
    return (
      <text x={x} y={y} fill="white" textAnchor={"start"} dominantBaseline="central">
        {`${this.state.statistics.gender[index].name}\r\n${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderTooltip = ({ active, payload, label }) => {
    // log(LogLevel.UI_LIFECYCLE, active, payload, label);
    if (active) {
      return (
        <div className="custom-tooltip">
          <p style={{color:"#000"}}>{`${payload[0].payload.name} : ${payload[0].payload.value} (${payload[0].payload.ratio}%${(payload[0].payload.ratioAccumulated?"-누적"+payload[0].payload.ratioAccumulated+"%":"")})`}</p>
        </div>
      );
    }
    return null;
  };

}

export default UserDistributionStatisticsView;