import { Map } from "immutable";

export type ColorSystem = "Gray" | "Skyblue" | "Blue" | "Purple" | "Green" | "Yellow" | "Orange" | "Red" | "Brown";

interface Saturation {
  0?: string;
  10: string;
  15?: string;
  20?: string;
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const COLOR_SYSTEM = Map<ColorSystem, Saturation>({
  Gray: {
    0: "#ffffff",
    10: "#f7f8f9",
    15: "#F2F4F5",
    20: "#eef3f5",
    50: "#e4ebed",
    100: "#cdd5d9",
    200: "#b7c0c6",
    300: "#9ba5af",
    400: "#7e8a95",
    500: "#5f6d79",
    600: "#4b5b69",
    700: "#3f4d5d",
    800: "#273648",
    900: "#0f2034",
  },
  Skyblue: {
    10: "#ebf9ff",
    50: "#c2edff",
    100: "#99e0ff",
    200: "#70d4ff",
    300: "#47c8ff",
    400: "#1fbcff",
    500: "#00abf5",
    600: "#008fcc",
    700: "#0072a3",
    800: "#00567a",
    900: "#003952",
  },
  Blue: {
    10: "#ebf2ff",
    50: "#c2d9ff",
    100: "#99c0ff",
    200: "#70a7ff",
    300: "#478eff",
    400: "#1f75ff",
    500: "#005ef5",
    600: "#0054db",
    700: "#0044b2",
    800: "#00358a",
    900: "#002352",
  },
  Purple: {
    10: "#edebff",
    50: "#cac2ff",
    100: "#a699ff",
    200: "#8370ff",
    300: "#6d57ff",
    400: "#531fff",
    500: "#5200f5",
    600: "#4400d6",
    700: "#3c00b2",
    800: "#30008f",
    900: "#1b0052",
  },
  Green: {
    10: "#f0fbf5",
    50: "#ccf5dd",
    100: "#aaeec6",
    200: "#88e7b0",
    300: "#67e09a",
    400: "#44d982",
    500: "#29cb6d",
    600: "#22aa5b",
    700: "#1c8849",
    800: "#156636",
    900: "#0e4424",
  },
  Yellow: {
    10: "#fffceb",
    50: "#fff5c2",
    100: "#ffef99",
    200: "#ffe870",
    300: "#ffe03d",
    400: "#ffd90f",
    500: "#f0c000",
    600: "#cca300",
    700: "#a38300",
    800: "#7a5e00",
    900: "#523c00",
  },
  Orange: {
    10: "#fbefe7",
    50: "#ffdac2",
    100: "#ffc299",
    200: "#ffa970",
    300: "#ff9147",
    400: "#ff781f",
    500: "#f56200",
    600: "#cc5200",
    700: "#a34100",
    800: "#7a3500",
    900: "#522600",
  },
  Red: {
    10: "#fff2f1",
    50: "#ffc5c2",
    100: "#ff9f99",
    200: "#ff707c",
    300: "#ff4757",
    400: "#ff1f31",
    500: "#f50014",
    600: "#cc0011",
    700: "#a30008",
    800: "#7a0004",
    900: "#520000",
  },
  Brown: {
    10: "#f7f2ee",
    50: "#e9e1d8",
    100: "#dbcdbd",
    200: "#ccb9a3",
    300: "#bea589",
    400: "#b0916e",
    500: "#9e7d56",
    600: "#846848",
    700: "#6a533a",
    800: "#4f3f2b",
    900: "#352a1d",
  },
});
