export function rippleEvent(e, el, rippleEl) {
    if(!el || !rippleEl)
        return;

    const size = el.offsetWidth;
    const pos = el.getBoundingClientRect();
    const x = e.pageX - pos.left - size;
    const y = e.pageY - pos.top - size;

    rippleEl.style = 'top:' + y + 'px; left:' + x + 'px; width: ' + size * 2 + 'px; height: ' + size * 2 + 'px;';
    rippleEl.classList.add('common-effect-start')
    rippleEl.classList.add('common-effect-active')
    setTimeout(() => {
    if(rippleEl && rippleEl){
        rippleEl.classList.remove('common-effect-active');
        rippleEl.classList.remove('common-effect-start');
    }
    }, 500);
}