import { SeminarLecture, SeminarLectureType } from "../../models/Model.Seminar";
import { BannerInfo } from "../../models/Model.Banner";

export interface SeminarState {
  initialized: boolean;
  lectures: any; // id-lecture
  categoryList: any[];
  lists: SeminarListState[]; // category id - SeminarListState
  features: BannerInfo[];
}

export enum SeminarActionTypes{
  SEMINAR_INIT = '@@seminar/SEMINAR_INIT',
  SEMINAR_INITIALIZED = '@@seminar/SEMINAR_INITIALIZED',
  SEMINAR_LECTURE_RELOAD_LIST = '@@seminar/SEMINAR_LECTURE_RELOAD_LIST',
  SEMINAR_LECTURE_FETCH = '@@seminar/SEMINAR_LECTURE_FETCH',
  SEMINAR_LECTURE_REQUEST = '@@seminar/SEMINAR_LECTURE_REQUEST',
  SEMINAR_LECTURE_SUCCESS = '@@seminar/SEMINAR_LECTURE_SUCCESS',
  SEMINAR_LECTURE_ERROR = '@@seminar/SEMINAR_LECTURE_ERROR',
  SEMINAR_LECTURE_RELOAD_FETCH = '@@seminar/SEMINAR_LECTURE_RELOAD_FETCH',
  SEMINAR_LECTURE_RELOAD_REQUEST = '@@seminar/SEMINAR_LECTURE_RELOAD_REQUEST',
  SEMINAR_LECTURE_RELOAD_SUCCESS = '@@seminar/SEMINAR_LECTURE_RELOAD_SUCCESS',
  SEMINAR_LECTURE_RELOAD_ERROR = '@@seminar/SEMINAR_LECTURE_RELOAD_ERROR',
  SEMINAR_LECTURE_VIEW_UPDATE = '@@seminar/SEMINAR_LECTURE_VIEW_UPDATE',
  SEMINAR_LECTURE_VIEW_TIME_UPDATE = '@@seminar/SEMINAR_LECTURE_VIEW_TIME_UPDATE',
  SEMINAR_LECTURE_LIST_FETCH = '@@seminar/SEMINAR_LECTURE_LIST_FETCH',
  SEMINAR_LECTURE_LIST_REQUEST = '@@seminar/SEMINAR_LECTURE_LIST_REQUEST',
  SEMINAR_LECTURE_LIST_SUCCESS = '@@seminar/SEMINAR_LECTURE_LIST_SUCCESS',
  SEMINAR_LECTURE_LIST_ERROR = '@@seminar/SEMINAR_LECTURE_LIST_ERROR',
  SEMINAR_FEATURED_FETCH = '@@seminar/SEMINAR_FEATURED_FETCH',
  SEMINAR_FEATURED_REQUEST = '@@seminar/SEMINAR_FEATURED_REQUEST',
  SEMINAR_FEATURED_SUCCESS = '@@seminar/SEMINAR_FEATURED_SUCCESS',
  SEMINAR_FEATURED_ERROR = '@@seminar/SEMINAR_FEATURED_ERROR',
}

export interface SeminarListState {
  id: number;

  // filter & sort 
  options?: {category?:number, orderBy?: "viewCount" | "createdAt", type?: SeminarLectureType};
  // category?:number;
  // orderBy?: "viewCount" | "createdAt"
  // type?: SeminarLectureType;

  // data
  categoryName?: string;
  lectures?: SeminarLecture[];
  end?: boolean;
  loading?: boolean;
  needReload?: boolean;
}
