import * as seminars from './actions';
import { SeminarState, SeminarActionTypes, SeminarListState} from './types';
import { ActionType, getType } from 'typesafe-actions';
import { Middleware } from 'redux';
import { fetchAPI } from '../../utils/APIUtil';
import * as API from './../../API.json';
import {getGlobal, GlobalKey, setGlobal, clearGlobal} from './../../utils/GlobalUtil'
import { ErrorCode } from '../../models/ErrorCode';
import {log, LogLevel} from '../../utils/LogUtil'
import { UserState } from '../user/types';
import { SeminarLecture } from '../../models/Model.Seminar';
import { BannerInfo, BannerType } from '../../models/Model.Banner';

const FETCH_LIST_COUNT_UNIT = 10;

export const fetchSeminarMiddleware: Middleware<{}, SeminarState> = (store) => next => async (action: ActionType<typeof seminars>) => {
  next(action);
  let states = store.getState();
  let state:SeminarState = states["seminar"];

  if(action.type == getType(seminars.init)){
    log(LogLevel.REDUX, "fetchJobPostMiddleware: seminars.init ", state)
    if(!state.initialized){
      next(seminars.initialized());
      await fetchList(-1, state, seminars, next);
    }
  }
  else if(action.type == getType(seminars.reloadList)){
    log(LogLevel.REDUX, "fetchJobPostMiddleware: seminars.reloadList ", state, action.meta)
    await fetchList(action.meta, state, seminars, next);
  }
  else if (action.type == getType(seminars.getSeminarLecture)) {
    log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware : seminars.getSeminarLecture ", action, getGlobal(GlobalKey.TOKEN, true))
    next(seminars.fetchSeminarLecture.request(action.payload));
    try {
      fetchAPI(API.SEMINAR_LECTURE_GET, '', {id:action.payload, option:action.meta}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error && result.data){
          let seminar:SeminarLecture = result.data;
          if(seminar.series && seminar.series.lectures && seminar.series.lectures.length){
            let data = seminar.series.lectures.map((item) => { return {id:item.id}});
            for(let j=0; j<seminar.series.lectures.length; j++){
              next(seminars.fetchSeminarLecture.success(seminar.series.lectures[j]));
            }
            seminar.series.lectures = data;
          }

          log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminars success", result.data)
          next(seminars.fetchSeminarLecture.success(result.data));
        }
        else{
          log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminars failed 1", result);
          let error : Error = {
            name : "error code " + result.error,
            message: "error code " + result.error,
          };
          next(seminars.fetchSeminarLecture.failure(error));
        }
      }).catch((e) => {
        log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminars failed 2", e);
        next(seminars.fetchSeminarLecture.failure(e));
      })
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminars failed 3", e);
      next(seminars.fetchSeminarLecture.failure(e));
    }
  }
  
  else if(action.type == getType(seminars.getSeminarLectures) ) {
    log(LogLevel.REDUX, "fetchSeminarMiddleware : seminars.getSeminarLectures, reloadSeminarLectures ", action, getGlobal(GlobalKey.TOKEN, true))
    await fetchList(action.payload, state, seminars, next);
  }
  
  else if (action.type == getType(seminars.getSeminarFeatured)) {
    log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware : getSeminarFeatured ", action)
    next(seminars.fetchSeminarFeatured.request());
    try {
      fetchAPI(API.BANNER_GET, '', {type:BannerType.LECTURE}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          let revents:BannerInfo[] = result.data;
          let newBanners = {}
          log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminarFeatured success", result.data, newBanners)
          next(seminars.fetchSeminarFeatured.success(revents));
        }
        else{
          log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminarFeatured failed", result);
          let error : Error = {
            name : "error code " + result.error,
            message: "error code " + result.error,
          };
          next(seminars.fetchSeminarFeatured.failure(error));
        }
      }).catch((e) => {
        log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminarFeatured failed", e);
        next(seminars.fetchSeminarFeatured.failure(e));
      })
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminarFeatured failed", e);
      next(seminars.fetchSeminarFeatured.failure(e));
    }
  }
  else if(action.type == getType(seminars.updateSeminarLectureView)) {
    log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware : seminars.updateSeminarLectureView ", action, getGlobal(GlobalKey.TOKEN, true))
    if(action.meta <= 100){
      try {
        fetchAPI(API.SEMINAR_LECTURE_VIEW, '', null, {lectureId:action.payload, value:action.meta}, getGlobal(GlobalKey.TOKEN));
      } catch (e) {
        log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware updateSeminarLectureView failed 3", e);
      }
    }
  }
  else if(action.type == getType(seminars.updateSeminarLectureViewLastPlayTime)) {
    log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware : seminars.updateSeminarLectureViewLastPlayTime ", action, getGlobal(GlobalKey.TOKEN, true))
    try {
      fetchAPI(API.SEMINAR_LECTURE_VIEW_TIME_UPDATE, '', null, {lectureId:action.payload, value:action.meta}, getGlobal(GlobalKey.TOKEN));
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware updateSeminarLectureViewLastPlayTime", e);
    }
  }
};



const fetchList = async (type:number, state:SeminarState, seminars, next) => {
  if(!state.lists){
    log(LogLevel.REDUX, "jobpost: fetchList: Error ", state);
    return;
  }
  log(LogLevel.REDUX, "jobpost: fetchList: Start ", state);

  for(let i = 0; i<state.lists.length; i++){
    if(type == -1 || i == type){
      let listState:SeminarListState = state.lists[i];
      log(LogLevel.REDUX, "jobpost: fetchList: Start list ", listState);
      if(listState && (listState.needReload || !listState.end) && !listState.loading){
        let rvalue = {...listState};
        next(seminars.fetchSeminarLectures.request(i));
        if(!listState.needReload && listState.end)
          return;

        try {
          fetchAPI(API.SEMINAR_LECTURES_LIST_GET, '', {...listState.options, start: listState.needReload?0:listState.lectures.length, count:FETCH_LIST_COUNT_UNIT}, null, getGlobal(GlobalKey.TOKEN)).then((listResult) => {
            if(listResult){
              if(!listResult.error && listResult.data && listResult.data.length){
                let data = listResult.data.map((item) => { return {id:item.id}});
                for(let j=0; j<listResult.data.length; j++){
                  next(seminars.fetchSeminarLecture.success(listResult.data[j]));
                }
                rvalue.lectures = [...(listState.needReload?[]:rvalue.lectures), ...data];
              }else{
                rvalue.lectures = listState.needReload?[]:rvalue.lectures;
              }
              rvalue.loading = false;
              rvalue.end = (listResult.data && listResult.data.length == FETCH_LIST_COUNT_UNIT)?false:true;
              rvalue.needReload = false;
              next(seminars.fetchSeminarLectures.success(rvalue));

            }else if(listResult && listResult.error == ErrorCode.ERROR_NOT_EXIST) {
              rvalue.lectures = listState.needReload?[]:rvalue.lectures;
              rvalue.loading = false;
              rvalue.end = true;
              rvalue.needReload = false;
              next(seminars.fetchSeminarLectures.success(rvalue));
            }
          }).catch((e) => {
            log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminarLectures failed 2", e);
            let error : any = {
              id : listState.id,
              message: "error code " + e,
            };
            next(seminars.fetchSeminarLectures.failure(error));
          })
      
        } catch (e) {
          log(LogLevel.REDUX_BOARD, "fetchSeminarMiddleware getSeminarLectures failed 3", e);
          let error : any = {
            id : listState.id,
            message: "error code " + e,
          };
          next(seminars.fetchSeminarLectures.failure(error));
        }
      }
    }
  }
}
