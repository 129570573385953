
import React, { Component } from 'react';
import './ComponentCompose.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem } from '@ionic/react';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';;
import Skeleton from 'react-loading-skeleton';
import smileIcon from './../assets/icon/smile.svg'
import { KeywordType, Medicine, Ingredient } from '../models/Model.Medicine';
import {compose} from 'redux'
import { connect, connectAdvanced } from 'react-redux';
import { RootState, actions} from '../store';
import {log, LogLevel} from '../utils/LogUtil'
import ComponentComposeJson from './ComponentComposeJson';
import ComponentComposeLink from './ComponentComposeLink';
import ComponentComposeImageBanner from './ComponentComposeImageBanner';
import ComponentComposeInvite from './ComponentComposeInvite';

type Props = {
  onDone: any;
};

type State = {
  visible: boolean;
  type: number;
}

class ComponentComposePopup extends Component<Props, State> {
  state={
    visible: false,
    type: 0,
  };

  constructor(props:Props){
    super(props);

  }

  show = () => {
    log(LogLevel.UI_LIFECYCLE, "ComponentComposePopup  :show")
    this.setState({visible:true});

  }

  hide = () => {
    log(LogLevel.UI_LIFECYCLE, "ComponentComposePopup  :hide")
    this.setState({visible: false});
  }

  onDone = (data) => {
    log(LogLevel.UI_ACTION, "ComponentComposePopup  :onDone", data);
    if(this.props.onDone)
      this.props.onDone(data);
    this.hide();
  }

  isShown = () => {
    return this.state.visible;
  }

  onSubmit = () => {

  }
  
  render() {
    log(LogLevel.UI_LIFECYCLE, "ComponentComposePopup  : render");

    let editor
    if(this.state.type == 0){
      editor = (
        <ComponentComposeJson onDone={this.onDone}/>
      );
    }
    else if(this.state.type == 1){
      editor = (
        <ComponentComposeImageBanner onDone={this.onDone}/>
      );
    }
    else if(this.state.type == 2){
      editor = (
        <ComponentComposeLink onDone={this.onDone}/>
      );
    }
    else if(this.state.type == 3){
      editor = (
        <ComponentComposeInvite onDone={this.onDone}/>
      );
    }

    return (
      <IonModal cssClass="component-composer-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >

        <div className="component-composer-popup-inner">
          <div className="component-composer-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
          <div className="component-composer-container">
            <div className="component-composer-title">
              Component 삽입기
            </div>
            <div className="component-composer-type-container">
              <div className={(this.state.type==0)?"register2-work-type-selected":"register2-work-type-unselected"} onClick={() => this.setState({type:0})}>JSON</div>
              <div className={(this.state.type==1)?"register2-work-type-selected":"register2-work-type-unselected"} onClick={() => this.setState({type:1})}>Banner</div>
              <div className={(this.state.type==2)?"register2-work-type-selected":"register2-work-type-unselected"} onClick={() => this.setState({type:2})}>Link</div>
              <div className={(this.state.type==3)?"register2-work-type-selected":"register2-work-type-unselected"} onClick={() => this.setState({type:3})}>Invite</div>
              <div className={(this.state.type==4)?"register2-work-type-selected":"register2-work-type-unselected"} onClick={() => this.setState({type:4})}>Comp4</div>
            </div>
            {editor}

          </div>
          <div className="component-composer-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
        </div>
      </IonModal>
    );
  }
}

export default (ComponentComposePopup);