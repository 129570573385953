import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import JobPostDetailTemplate from "../../components/templates/JobPostDetail";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import {
  ApplyMethod,
  applyMethodCoupler,
  getPayString,
  JobOfferName,
} from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import * as API from "./../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobListType } from "../../store/jobpost/types";
import ABTestUtil from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";
import { MY_ADDRESS } from "./../../config.json";
import Spinner from "../../components/atoms/Spinner";
import { UIServiceType } from "../../store/ui/types";
import AdsUtil from "../../utils/AdsUtil";

interface MatchParams {
  postId: string;
}

const windowAny: any = window;

const JobPostDetail: React.FC<RouteComponentProps<MatchParams>> = ({
  history,
  location,
  match,
}) => {
  try {
    const id = useMemo(() => queryString.parse(location.search).id as string, [
      location.search,
    ]);
    const backKeyControl = useSelector(
      (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
    );
    const offer = useSelector((state: RootState) => state.jobpost.offers[id]);
    const me = useSelector((state: RootState) => state.user.me);
    const dispatch = useDispatch();
    const lastVisited = useSelector(
      (state: RootState) => state.jobpost.lastVisited
    );
    const context = useSendbirdStateContext();
    // const sdkInstance = sendBirdSelectors.getSdk(context);

    // 스크랩
    const scrapPost = async () => {
      log(LogLevel.UI_LIFECYCLE, "JobPostDetail:scrapPost", offer);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SCRAP",
        "구인구직_약사_공지_상세보기_찜하기",
        {
          공고id: id,
        }
      );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SCRAP",
        "구인구직_약사_공지_찜하기",
        { 공고id: id }
      );

      try {
        await fetchAPI(
          API.JOBPOST_LIKE,
          offer.id.toString(),
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        dispatch(actions.jobpost.updateOffer({ id: offer.id, liked: true }));
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.MY_LIKE
          )
        );
      } catch (err) {
        console.error(err);
      }
    };

    // 스크랩 취소
    const UnScrapPost = async () => {
      log(LogLevel.UI_LIFECYCLE, "JobPostDetail:UnScrapPost", offer);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SCRAP_CANCEL",
        "구인구직_약사_공지_상세보기_찜하기_취소",
        {
          공고id: id,
        }
      );

      try {
        await fetchAPI(
          API.JOBPOST_LIKE_CANCEL,
          offer.id.toString(),
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        dispatch(actions.jobpost.updateOffer({ id: offer.id, liked: false }));
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.MY_LIKE
          )
        );
      } catch (err) {
        console.error(err);
      }
    };

    // 공고 내용 불러오기
    const loadPost = useCallback(async () => {
      if (id) {
        // log(LogLevel.UI_LIFECYCLE, "JobPostDetail:useEffect []", window.location.search, query, postId);
        try {
          await fetchAPI(
            API.JOBPOST_VIEW,
            id,
            null,
            null,
            getGlobal(GlobalKey.TOKEN)
          );
          dispatch(actions.jobpost.loadOffer({ chatlist: true }, Number(id)));
        } catch (err) {}
      }
    }, [id]);

    // 끌어올리기
    const raisePost = async () => {
      log(LogLevel.UI_ACTION, "JobPostDetail.raisePost", offer.id);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_상세보기_끌어올리기",
        { 공고id: id }
      );

      try {
        await fetchAPI(
          API.JOBPOST_RAISE,
          "",
          null,
          { id: offer.id },
          getGlobal(GlobalKey.TOKEN)
        );
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.PHARMACY
          )
        );
        dispatch(
          actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY)
        );
      } catch (err) {
        console.error(err);
      }
    };

    // 알림톡 보내기
    const sendAlimTalk = (postId: number) => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_상세보기_알림톡보내기",
        {
          공고id: id,
        }
      );
      history.push(`/jobpost/order/alimtalk?id=${postId}`, {
        returnUrl: location.pathname + location.search,
      });
    };

    // 수정페이지로 이동
    const goToEditPage = (postId: number) => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_상세보기_수정_알림톡보내기",
        {
          공고id: id,
        }
      );
      history.push(`/jobpost/edit?id=${postId}`);
    };

    const sendChat = async () => {
      if (!offer || !me) {
        log(LogLevel.UI_ACTION, "JobPostDetail:sendChat: not ready");
        return;
      }

      // 자동 스크랩
      try {
        await fetchAPI(
          API.JOBPOST_LIKE,
          offer.id.toString(),
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
      } catch (err) {
        console.error(err);
      }

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_APPLY_METHOD",
        "구인구직_공고_지원",
        {
          jobPostApplyMethod: applyMethodCoupler[offer.applyMethod],
          selectedApplyMethod: applyMethodCoupler[ApplyMethod.Chat],
        }
      );

      try {
        dispatch(actions.jobpost.updateOffer({ id: offer.id, liked: true }));
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.MY_LIKE
          )
        );
      } catch (e) {}

      history.push("/chat?offerId=" + offer.id);
      // log(LogLevel.UI_ACTION, "JobPostDetail:sendChat: ");

      // let target = "";
      // if (ABTestUtil.isDev()) {
      //   target = "DEV";
      // }

      // let name = target + "_jobpost_" + offer.id + "_" + me.id + "_";
      // const listQuery: GroupChannelListQuery = sdkInstance.GroupChannel.createMyGroupChannelListQuery();
      // listQuery.includeEmpty = false;
      // listQuery.channelNameContainsFilter = name; // 'all', 'joined_only', 'invited_only', 'invited_by_friend', and 'invited_by_non_friend'
      // listQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
      // listQuery.limit = 1; // The value of pagination limit could be set up to 100.

      // AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_APPLY_METHOD", "구인구직_공고_지원", {
      //   jobPostApplyMethod: applyMethodCoupler[offer.applyMethod],
      //   selectedApplyMethod: applyMethodCoupler[ApplyMethod.Chat],
      // });

      // try {
      //   if (listQuery.hasNext) {
      //     let groupChannels: Array<GroupChannel> = await listQuery.next(null);
      //     log(LogLevel.UI_ACTION, "JobPostDetail:sendChat:listQuery.next ", groupChannels, sdkInstance);

      //     if (groupChannels && groupChannels.length) {
      //       log(LogLevel.UI_ACTION, "JobPostDetail:sendChat:listQuery.next success ", groupChannels[0]);
      //       history.push("/chat?id=" + groupChannels[0].url);
      //       AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_CHAT", "구인구직_공고_채팅진입", { isNew: false });
      //       return;
      //     }
      //   }
      //   log(LogLevel.UI_ACTION, "JobPostDetail:sendChat:listQuery no existing chat thread -> create", offer);

      //   // no existing chat thread -> create
      //   let newChannel = await sdkInstance.GroupChannel.createChannelWithUserIds(
      //     [target + offer.ownerId.toString(), target + me.id.toString()],
      //     false,
      //     name,
      //     "",
      //     JSON.stringify({
      //       type: "JOBPOST",
      //       id: offer.id,
      //       pharmacyName: offer.pharmacy.name,
      //       ouid: offer.ownerId,
      //       auid: me.id,
      //       title: offer.title,
      //       salary: getPayString(offer),
      //     }),
      //     "",
      //     async (groupChannel: GroupChannel, error: SendBirdError) => {
      //       // if(ABTestUtil.isTest()){
      //       let message = {
      //         message_type: "ADMM",
      //         message: "[채팅팁]",
      //         custom_type: "notice",
      //         is_silent: true,
      //       };
      //       await axios.post(
      //         `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
      //         message,
      //         {
      //           headers: {
      //             "Content-Type": "application/json; charset=utf8",
      //             "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
      //           },
      //         }
      //       );
      //       message = {
      //         message_type: "ADMM",
      //         message: "채팅은 실명으로 진행되어요.",
      //         custom_type: "notice",
      //         is_silent: true,
      //       };
      //       await axios.post(
      //         `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
      //         message,
      //         {
      //           headers: {
      //             "Content-Type": "application/json; charset=utf8",
      //             "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
      //           },
      //         }
      //       );
      //       message = {
      //         message_type: "ADMM",
      //         message: "우측상단의 📞를 눌러서 상대방에게 전화를 걸 수 있어요.",
      //         custom_type: "notice",
      //         is_silent: true,
      //       };
      //       await axios.post(
      //         `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
      //         message,
      //         {
      //           headers: {
      //             "Content-Type": "application/json; charset=utf8",
      //             "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
      //           },
      //         }
      //       );
      //       message = {
      //         message_type: "ADMM",
      //         message: "전화걸기 전엔 꼭 상대의 동의를 구하세요 ^^",
      //         custom_type: "notice",
      //         is_silent: true,
      //       };
      //       await axios.post(
      //         `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
      //         message,
      //         {
      //           headers: {
      //             "Content-Type": "application/json; charset=utf8",
      //             "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
      //           },
      //         }
      //       );
      //       // }
      //     }
      //   );

      //   log(LogLevel.UI_LIFECYCLE, "JobPostDetail:sendChat:listQuery.create success ", newChannel);
      //   AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_CHAT", "구인구직_공고_채팅진입", { isNew: true });

      //   history.push("/chat?id=" + newChannel.url);

      //   try{
      //     dispatch(actions.jobpost.updateOffer({ id: offer.id, liked: true }));
      //     dispatch(actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY_LIKE));
      //   }catch(e){

      //   }
      // } catch (e) {
      //   log(LogLevel.UI_EXCEPTION, "JobPostDetail:sendChat:exception ", e);
      // }
    };

    // 카카오 공유하기
    const kakaoShare = async () => {
      if (!offer || !offer.pharmacy) return;

      log(LogLevel.UI_ACTION, "JobPostDetail: doKakaoShare");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_상세보기_공유하기",
        {
          공고id: offer.id,
        }
      );

      const result = await fetchAPI(
        API.JOBPOST_SHARE,
        offer.id,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      const os = getGlobal(GlobalKey.OS);
      const title = `${offer.pharmacy.region} ${
        JobOfferName[offer.workType]
      } 약사 구인 추천`;
      const description = `${me.name} 약사님이 추천한 약국 구인공고를 지금 확인해보세요 ^^`;
      const executionParams = `type=jobpost&offerId=${offer.id}`;

      if (!os || os === "browser") {
        const feed = {
          objectType: "feed",
          content: {
            title,
            description,
            imageUrl: `${MY_ADDRESS}/images/ymyd_jobpost_kakao_share.jpg`,
            link: {
              androidExecParams: executionParams,
              iosExecParams: executionParams,
            },
          },
          social: {
            likeCount: offer.likeCnt,
            viewCount: offer.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecParams: executionParams,
                iosExecParams: executionParams,
              },
            },
          ],
        };

        try {
          windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, e);
        }
        windowAny.Kakao.Link.sendDefault(feed);
      } else if (windowAny.KakaoCordovaSDK) {
        const feed = {
          objectType: "feed",
          content: {
            title,
            desc: description,
            imageURL: MY_ADDRESS + "/images/ymydgi_20220719.jpg",
            link: {
              androidExecutionParams: executionParams,
              iosExecutionParams: executionParams,
            },
          },
          social: {
            likeCount: offer.likeCnt,
            viewCount: offer.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecutionParams: executionParams,
                iosExecutionParams: executionParams,
              },
            },
          ],
        };
        windowAny.KakaoCordovaSDK.sendLinkFeed(
          feed,
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share success",
              res,
              feed
            );
          },
          (res) => {
            console.log(
              "ViewerBody: doKakaoShare: Kakao share fail",
              res,
              feed
            );
          }
        );
      }
    };

    const showMap = () => {
      let pharmacyPosition;
      if (offer && offer.pharmacy && offer.pharmacy.position)
        pharmacyPosition = offer.pharmacy.position;

      log(
        LogLevel.UI_ACTION,
        "LocalDealJobPostOfferDetail:showMap: ",
        pharmacyPosition
      );

      if (!pharmacyPosition || !pharmacyPosition.x || !pharmacyPosition.y)
        return;

      if (getGlobal(GlobalKey.OS) === "browser") {
        // history.push(url.substring(3));

        window.open(
          `http://map.naver.com/?dlevel=13&lat=${pharmacyPosition.y}&lng=${pharmacyPosition.x}`,
          "_blank"
        );
        // window.open(`https://map.kakao.com/link/map/${offer.pharmacy.name},${pharmacyPosition.x},${pharmacyPosition.y}`,"_blank");
      } else {
        // window.open(`nmap://route/public?slat=37.4640070&slng=126.9522394&sname=%EC%84%9C%EC%9A%B8%EB%8C%80%ED%95%99%EA%B5%90&dlat=37.5209436&dlng=127.1230074&dname=%EC%98%AC%EB%A6%BC%ED%94%BD%EA%B3%B5%EC%9B%90&appname=com.onjourney.pharmacycafe`,"_system");
        window.open(
          `nmap://place?lat=${pharmacyPosition.y}&lng=${
            pharmacyPosition.x
          }&name=${encodeURIComponent(
            offer.pharmacy.anonymous ? "약국" : offer.pharmacy.name
          )}&appname=com.onjourney.pharmacycafe`,
          "_system"
        );
      }
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_PHARMACIST_MAIN_MAP_VIEW",
        "구인구직_공고_지도_이동",
        {}
      );
    };

    const showMapPath = () => {
      let myPosition;
      if (me && me.homePosition) myPosition = me.homePosition;
      let pharmacyPosition;
      if (offer && offer.pharmacy && offer.pharmacy.position)
        pharmacyPosition = offer.pharmacy.position;

      log(
        LogLevel.UI_ACTION,
        "JobPostDetail:showMap: ",
        myPosition,
        pharmacyPosition
      );

      if (
        !myPosition ||
        !myPosition.x ||
        !myPosition.y ||
        !pharmacyPosition ||
        !pharmacyPosition.x ||
        !pharmacyPosition.y
      )
        return;

      if (getGlobal(GlobalKey.OS) === "browser") {
        // history.push(url.substring(3));
        // window.open(`https://map.kakao.com/link/to/우리집,126.9522394,37.4640070`,"_system");
        window.open(
          `https://map.kakao.com/link/to/${encodeURIComponent(
            offer.pharmacy.anonymous ? "약국" : offer.pharmacy.name
          )},${pharmacyPosition.y},${pharmacyPosition.x}`,
          "_blank"
        );
      } else {
        // window.open(`nmap://route/public?slat=37.4640070&slng=126.9522394&sname=%EC%84%9C%EC%9A%B8%EB%8C%80%ED%95%99%EA%B5%90&dlat=37.5209436&dlng=127.1230074&dname=%EC%98%AC%EB%A6%BC%ED%94%BD%EA%B3%B5%EC%9B%90&appname=com.onjourney.pharmacycafe`,"_system");
        window.open(
          `nmap://route/public?slat=${myPosition.y}&slng=${
            myPosition.x
          }&sname=${encodeURIComponent("우리집")}&dlat=${
            pharmacyPosition.y
          }&dlng=${pharmacyPosition.x}&dname=${encodeURIComponent(
            offer.pharmacy.anonymous ? "약국" : offer.pharmacy.name
          )}&appname=com.onjourney.pharmacycafe`,
          "_system"
        );
      }
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_PHARMACIST_MAIN_PATH_VIEW",
        "구인구직_공고_경로_이동",
        {}
      );
    };

    const jobListTypeCoupler = () => {
      switch (getGlobal("jobpost-post-type")) {
        case JobListType.ALL:
        case JobListType.UNREAD:
          return "모든 공고";
        case JobListType.SEARCHED:
          return "검색 공고";
        case JobListType.MY:
          return "나의 공고";
        case JobListType.MY_LIKE:
          return "관심 공고";
        default:
          return "링크";
      }
    };

    useEffect(() => {
      // show banner ad and hide when unmount
      AdsUtil.show(AdsUtil.TYPE_BANNER, { solution: AdsUtil.SOLUTION_ADMOB });

      return () => {
        AdsUtil.hide(AdsUtil.TYPE_BANNER);
      };
    }, []);

    useEffect(() => {
      if (id) {
        try {
          loadPost();
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, "JobPostDetail.useEffect error", e);
        }
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_OFFER_VIEW",
          "구인구직_약사_공지_진입",
          {
            id,
            from: jobListTypeCoupler(),
          }
        );
      }
    }, [id]);

    if (!offer || (offer.loading && !offer.title)) {
      return <Spinner />;
    }

    let isNew: boolean = false;
    let date = new Date(offer.createdAt);
    if (date) {
      let value = date.valueOf();
      isNew =
        value > lastVisited && Date.now() - value <= 2 * 24 * 60 * 60 * 1000;
      log(
        LogLevel.UI_LIFECYCLE,
        "JobPostDetail: new check ",
        offer.createdAt,
        date,
        value,
        lastVisited,
        isNew
      );
    }

    return (
      <JobPostDetailTemplate
        post={offer}
        me={me}
        backKeyControl={backKeyControl}
        onScrap={scrapPost}
        onUnScrap={UnScrapPost}
        onRaisePost={raisePost}
        onSendAlimTalk={sendAlimTalk}
        onGoToEdit={goToEditPage}
        onMapPath={showMapPath}
        onMap={showMap}
        onChat={sendChat}
        onKakaoShare={kakaoShare}
        isNew={isNew}
      />
    );
  } catch (error) {
    console.error(error.message);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_Detail_Error",
      "에러",
      {
        page: "DetailPage",
        errorMessage: error,
      }
    );
  }
};

export default withRouter(JobPostDetail);
