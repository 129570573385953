import React, { Component } from "react";
import { IonInput, IonCheckbox } from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import {
  OrderInfo,
  OrderStatus,
  OrderStatusName,
} from "../../../models/Model.Sale";

const ADMIN_API = {
  JOBPOST_OFFERS: {
    method: "get",
    path: "/admin/jobpost/offers",
    contentType: "application/json",
  },
  JOBPOST_APPLIES: {
    method: "get",
    path: "/admin/jobpost/applies",
    contentType: "application/json",
  },
  JOBPOST_CHATS: {
    method: "get",
    path: "/admin/jobpost/chats",
    contentType: "application/json",
  },
};

type Props = {
  order: OrderInfo;
  onSelect: (order: OrderInfo) => void;
  onCheck?: (order: OrderInfo) => void;
  onRefund?: (order: OrderInfo, index: number, refundAmount: number) => void;
  onDeliveryUpdate?: (
    order: OrderInfo,
    index: number,
    deliveryCompany: string,
    deliveryId: string
  ) => void;
  checked?: boolean;
  keys?: string;
  editMode?: boolean;
  index?: number;
  searchStatus: OrderStatus;
  searchKeyword: string;
};

type State = {
  deliveryCompany: string;
  deliveryId: string;
  refundAmount: number;
};

class OrderListItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deliveryCompany: this.props.order.deliveryCompany,
      deliveryId: this.props.order.deliveryId,
      refundAmount: this.props.order.payRefund ? this.props.order.payRefund : 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order.id != this.props.order.id) {
      this.setState({
        deliveryCompany: this.props.order.deliveryCompany,
        deliveryId: this.props.order.deliveryId,
      });
    }
  }

  onCheck = (e) => {
    e.stopPropagation();
    if (this.props.onCheck) this.props.onCheck(this.props.order);
  };

  onChangeCompany = (e) => {
    e.stopPropagation();
    let value = e.detail.value;
    switch (value) {
      case "처":
      case "cj":
        value = "CJ대한통운";
    }
    this.setState({ deliveryCompany: value });
  };

  onChangeDeliveryID = (e) => {
    e.stopPropagation();
    this.setState({ deliveryId: e.detail.value });
  };

  onChangeRefund = (e) => {
    e.stopPropagation();
    this.setState({ refundAmount: parseInt(e.detail.value) });
  };

  onDeliveryIDKeyPress = (e) => {
    if (e.keyCode == 13) {
      e.stopPropagation();
      let deliveryId = this.state.deliveryId.replace(/-/gi, "");
      this.props.onDeliveryUpdate(
        this.props.order,
        this.props.index,
        this.state.deliveryCompany,
        deliveryId
      );
    }
  };

  onRefundKeyPress = (e) => {
    if (e.keyCode == 13) {
      e.stopPropagation();
      this.props.onRefund(
        this.props.order,
        this.props.index,
        this.state.refundAmount
      );
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "OrderListItem.render", this.props, this.state);

    if (
      this.props.searchStatus != OrderStatus.NONE &&
      this.props.searchStatus != this.props.order.status
    )
      return null;
    if (
      this.props.searchKeyword &&
      !this.props.order.id.toString().includes(this.props.searchKeyword) &&
      !this.props.order.user.name.includes(this.props.searchKeyword) &&
      !this.props.order.user.phone.includes(this.props.searchKeyword) &&
      !this.props.order.userId.toString().includes(this.props.searchKeyword) &&
      !this.props.order.userId.toString().includes(this.props.searchKeyword) &&
      !this.props.order.recipientPhone.includes(this.props.searchKeyword) &&
      !this.props.order.recipientName.includes(this.props.searchKeyword)
    ) {
      return null;
    }
    let style = "";
    switch (this.props.order.status) {
      // case OrderStatus.IN_CART :
      // case OrderStatus.ORDERED :
      // case OrderStatus.PAID :
      // case OrderStatus.PREPARING_PRODUCT :
      // case OrderStatus.PREPARING_DELIVER :
      // case OrderStatus.DELIVERING :
      case OrderStatus.CANCELED: {
        style = " admin-table-value-caution";
        break;
      }
      case OrderStatus.RETURNING: {
        style = " admin-table-value-higlight";
        break;
      }

      case OrderStatus.NONE:
      case OrderStatus.RETURN:
      case OrderStatus.ORDER_FAILED:
      case OrderStatus.DELIVERED: {
        style = " admin-table-value-dimm";
        break;
      }
    }

    if (this.props.order.deletedAt) style = " admin-table-value-canceld";

    if (this.props.editMode) {
      return (
        <tr key={this.props.keys}>
          <td className={"admin-table-value" + style}>
            <IonCheckbox checked={this.props.checked} onClick={this.onCheck} />
          </td>
          <td className={"admin-table-value" + style}>{this.props.order.id}</td>
          <td className={"admin-table-value" + style}>
            {OrderStatusName[this.props.order.status] +
              (this.props.order.deletedAt ? "(삭제)" : "")}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.productId}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.amount}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.payProduct}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.payDelivery}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.payAmount}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.paidAmount}
          </td>
          <td className={"admin-table-value" + style}>
            <IonInput
              class="admin-board-search-input"
              placeholder="환불액"
              type="number"
              value={this.state.refundAmount.toString()}
              onIonChange={this.onChangeRefund}
              onKeyDown={this.onRefundKeyPress}
            />
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.userId}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.recipientName}
          </td>
          <td className={"admin-table-value" + style}>
            {getDateStringFromToday({ date: this.props.order.orderedAt })}
          </td>
          <td className={"admin-table-value" + style}>
            <IonInput
              class="admin-board-search-input"
              placeholder="배송사"
              value={this.state.deliveryCompany}
              onIonChange={this.onChangeCompany}
            />
          </td>
          <td className={"admin-table-value" + style}>
            <IonInput
              class="admin-board-search-input"
              placeholder="송장번호"
              value={this.state.deliveryId}
              onIonChange={this.onChangeDeliveryID}
              onKeyDown={this.onDeliveryIDKeyPress}
            />
          </td>
        </tr>
      );
    } else {
      return (
        <tr
          key={this.props.keys}
          onClick={() => this.props.onSelect(this.props.order)}
        >
          <td className={"admin-table-value" + style}>{this.props.order.id}</td>
          <td className={"admin-table-value" + style}>
            {this.props.order.userId}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.user.name}
          </td>
          <td className={"admin-table-value" + style}>
            {OrderStatusName[this.props.order.status]}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.productId}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.amount}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.payProduct}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.payDelivery}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.payAmount}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.paidAmount}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.payRefund}
          </td>

          <td className={"admin-table-value" + style}>
            {this.props.order.user.workPlaceRegistration}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.recipientName}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.recipientPhone}
          </td>
          <td className={"admin-table-value" + style}>
            {getDateStringFromToday({ date: this.props.order.orderedAt })}
          </td>
          <td className={"admin-table-value" + style}>
            {getDateStringFromToday({ date: this.props.order.deliveredAt })}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.recipientPostalCode}
          </td>
          <td className={"admin-table-value" + style}>
            {this.props.order.recipientAddress1 +
              " " +
              this.props.order.recipientAddress2}
          </td>
        </tr>
      );
    }
  }
}

export default OrderListItem;
