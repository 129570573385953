import React, { Component } from 'react';
import './../../Common.css'
import './ImageViewerPopup.css';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem, IonHeader, IonToolbar, IonButtons, IonLabel, IonContent, IonTitle } from '@ionic/react';
import {log, LogLevel} from '../../utils/LogUtil'
import SwipeableViews from 'react-swipeable-views';
import StringUtil from '../../utils/StringUtil';

type Props = {

};

type State = {
  visible: boolean;
  downloadable: boolean;
  images: string[];
  index: number;
  onDeleteImage: (index:number) => void;
}

class ImageViewerPopup extends Component<Props, State> {
  state={
    visible: false,
    downloadable: false,
    images: [],
    index: 0,
    onDeleteImage: null,
  };

  setDownloadable = (downloadable: boolean) => {
    this.setState({downloadable});
  }

  setDeletable = (onDeleteImage: (index:number) => void) => {
    this.setState({onDeleteImage});
  }

  setImages = (images: string[]) => {
    log(LogLevel.UI_ACTION, "ImageViewerPopup:show", this.state, images)
    if(!images)
      images = []
    this.setState({images});
  }

  isShown = () => {
    return this.state.visible;
  }

  clearImages = () => {
    this.setState({images:[]});
  }
  
  show = (index:number = 0) => {
    log(LogLevel.UI_ACTION, "ImageViewerPopup:show", this.state)
    this.setState({visible:true, index});
  }

  hide = () => {
    log(LogLevel.UI_ACTION, "ImageViewerPopup:hide", this.state)
    this.setState({visible: false, images:[], downloadable:false, onDeleteImage:null});
  }

  handleChangeIndex = (index: number) => {
    this.setState({index: index});
  }

  onDelete = () => {
    this.state.onDeleteImage(this.state.index);
    this.hide();
  } 

  render() {
    log(LogLevel.UI_LIFECYCLE, "ImageViewerPopup: render", this.state);
    let deleteButton;
    if(this.state.onDeleteImage){
      deleteButton = (
        <IonButton class="image-viewer-toolbar-button"  onClick={this.onDelete}>
          <IonIcon name="trash" slot="icon-only"/>
        </IonButton>
      );
    }

    let title = (this.state.index+1) + " / " + this.state.images.length;
    return (
      <IonModal cssClass="image-viewer-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="image-viewer-popup-inner">
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton class="common-header-toolbar-button" onClick={this.hide}>
                  <IonIcon name="arrow-back" mode="ios"/>
                </IonButton>
              </IonButtons>
              <IonTitle class="common-header-title-center">{title}</IonTitle>
              <IonButtons slot="end">
                {deleteButton}
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <SwipeableViews className="image-viewer-swipe" enableMouseEvents index={this.state.index} onChangeIndex={this.handleChangeIndex}>
              {this.state.images.map((image, index) => {
                let url;
                if(image){
                  url = StringUtil.convertFilePath(image);
                }
                if(url)
                  return (
                    <div className="image-view-container" key={index.toString()}>
                      <img className="image-view" src={url} />
                    </div>
                  );
                else 
                  return null;
              })}
            </SwipeableViews>
          </IonContent>
                  <div className="image-viewer-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
        </div>
      </IonModal>
    );
  }
}

export default ImageViewerPopup;