import React from "react";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import {
  clearGlobal,
  getGlobal,
  GlobalKey,
  setGlobal,
} from "../../../utils/GlobalUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
// import FloatingButton, { ButtonPosition } from '../../atom/FloatingButton';
import "./../../../Common.css";

export type Props = {
  onLoadMore?: () => void;
  onMoveTop?: () => void;
  needMoveToTop?: boolean;
  // moveTopPosition?: ButtonPosition,
  moveTopMin?: number;
  autoScrollName?: string;
  absolute?: boolean;
  handleScroll?: (isScrollUp: boolean) =>void;
};

interface ScrollHookEvent {
  scroll: {
    scrollY: number;
  };
  reach: {
    targetId: string;
  };
}

export interface Target {
  id: string;
  element?: HTMLElement | null;
  // margin?: number
}

export interface ScrollAction {
  clearAutoScroll: () => void;
  // isInit: boolean,
  // initScroll: (this.contentScroll?: HTMLElement | null) => void
  getScrollY: () => number | undefined;
  setMargin: (number) => void;
  getTargetById: (id: string) => Target | undefined;
  addTarget: (id: string, element: HTMLElement) => void;
  removeTargetById: (id: string) => void;
  scrollTo: (y: number) => void;
  scrollToTarget: (id: string) => void;
  subscribe: <K extends keyof ScrollHookEvent>(
    eventName: K,
    listener: (event: ScrollHookEvent[K]) => void
  ) => void;
  unsubscribe: <K extends keyof ScrollHookEvent>(
    eventName: K,
    listener: (event: ScrollHookEvent[K]) => void
  ) => void;
}

type State = {
  needMoveToTop: boolean;
};

class Scroll extends React.Component<Props, State> implements ScrollAction {
  contentScroll = null;
  content = null;
  targets: Map<string, HTMLElement | null> = new Map();
  scrollMargin = 0;
  scrollListeners: ((event: ScrollHookEvent["scroll"]) => void)[] = [];
  reachListeners: ((event: ScrollHookEvent["reach"]) => void)[] = [];

  requestIds: number[] = [];
  fps: number = 0;
  lastY: number = 0;
  frameCount: number = 0;
  isScrolling: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      needMoveToTop: false,
    };
  }

  componentDidMount = async () => {
    log(LogLevel.UI_LIFECYCLE, "Scroll:componentDidMount");
    this.autoScroll();
  };

  cancelScrolling = () => {
    this.requestIds.forEach((requestId) => cancelAnimationFrame(requestId));
    this.requestIds = [];
    this.isScrolling = false;
  };

  setMargin = (margin: number) => {
    log(LogLevel.UI_LIFECYCLE, "Scroll:setMargin", margin);
    this.scrollMargin = margin;
  };

  scrollTo = (y: number, time = 500) => {
    log(LogLevel.UI_LIFECYCLE, "Scroll:scrollTo", y);
    this.cancelScrolling();

    this.fps = 0;
    this.frameCount = 0;
    this.lastY = y;
    this.isScrolling = true;

    const ONE_SECOND = 1000; // 1s == 1000ms
    const startTime = performance.now();

    const frame = (now: number) => {
      this.frameCount++;

      const scrollingTime = now - startTime;
      const scrollingY = this.contentScroll.scrollTop;

      this.fps = (ONE_SECOND / scrollingTime) * this.frameCount;

      if (scrollingTime > time) time = scrollingTime;
      // 순간 이동할 y는 남은 이동Y(toY - scrollingY)를 남은시간(단위:s) * fps로 나눈다.
      if (scrollingTime == time) {
        this.contentScroll.scrollTop = y;
        this.isScrolling = false;
      } else {
        const dY =
          (y - scrollingY) / ((this.fps * (time - scrollingTime)) / ONE_SECOND);
        let scrollTop = this.contentScroll.scrollTop + dY;
        this.contentScroll.scrollTop = scrollTop;
        this.requestIds.push(requestAnimationFrame(frame));
      }
    };

    this.requestIds.push(requestAnimationFrame(frame));
  };

  getAbsoluteTop = (element: HTMLElement, scrollTop: number) => {
    if (!element) return 0;
    if (this.props.absolute)
      return (
        scrollTop +
        Math.ceil(element.getBoundingClientRect().top) -
        this.scrollMargin
      );
    else return element.offsetTop - this.scrollMargin;
  };

  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = async () => {
    if (this.props.autoScrollName) {
      try {
        let scroll = getGlobal(
          GlobalKey.SCROLL + this.props.autoScrollName.toString()
        );
        if (scroll) {
          if (this.contentScroll) {
            log(LogLevel.UI_EVENT, "Scroll:autoScroll", scroll);
            await this.contentScroll.scrollTo(0, scroll);
            setTimeout(() => {
              setGlobal(
                GlobalKey.SCROLL + this.props.autoScrollName.toString(),
                scroll
              );
            }, 0);
            if (
              this.props.needMoveToTop &&
              ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP) &&
              scroll > (this.props.moveTopMin ? this.props.moveTopMin : 500)
            )
              this.setState({ needMoveToTop: true });
          } else {
            this.autoScrollTry++;
            if (this.autoScrollTry < this.autoScrollTryMax) {
              setTimeout(() => this.autoScroll(), 10);
            } else {
              log(
                LogLevel.UI_EVENT,
                "Scroll:autoScroll Try FINISHED FAILED",
                this.autoScrollTry,
                this.contentScroll,
                scroll
              );
            }
          }
        } else {
          log(LogLevel.UI_EVENT, "Scroll:autoScroll no scroll ");
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "Scroll:autoScroll failed", e);
        this.autoScrollTry++;
        if (this.autoScrollTry < this.autoScrollTryMax) {
          log(
            LogLevel.UI_EVENT,
            "Scroll:autoScroll Try ",
            this.autoScrollTry,
            this.contentScroll,
            scroll
          );
          setTimeout(() => this.autoScroll(), 10);
        } else {
          log(
            LogLevel.UI_EVENT,
            "Scroll:autoScroll Try FINISHED FAILED",
            this.autoScrollTry,
            this.contentScroll,
            scroll
          );
        }
      }
    }
  };

  clearAutoScroll = () => {
    if (this.props.autoScrollName)
      clearGlobal(GlobalKey.SCROLL + this.props.autoScrollName.toString());
  };

  onMoveToTop = async () => {
    log(LogLevel.UI_EVENT, "Scroll:onMoveToTop", this.contentScroll);
    if (this.contentScroll) {
      await this.contentScroll.scrollTo(0, 0);
      this.setState({ needMoveToTop: false });
    }
    if (this.props.onMoveTop) this.props.onMoveTop();
  };

  lastRichTargetId = "";
  lastScrollPos = 0;

  onScroll = (event) => {
    let target: any = event.target;

    if (!target || !this.content) return;

    if (this.props.autoScrollName)
      setGlobal(
        GlobalKey.SCROLL + this.props.autoScrollName.toString(),
        target.scrollTop
      );

    if (
      this.props.onLoadMore &&
      target.scrollTop + target.clientHeight > this.content.clientHeight * 0.9
    ) {
      // log(LogLevel.UI_EVENT, "Scroll:onScroll", target.scrollTop, target.clientHeight, this.content.clientHeight);
      this.props.onLoadMore();
    }

    if (
      this.props.needMoveToTop &&
      ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP)
    ) {
      if (target.scrollTop > 500) {
        this.setState({ needMoveToTop: true });
      } else {
        this.setState({ needMoveToTop: false });
      }
    }

    const scrollY = this.contentScroll.scrollTop;
    log(LogLevel.UI_EVENT, "Scroll:onScroll", scrollY);

    this.scrollListeners.map((onScroll) => {
      onScroll({ scrollY });
    });

    let largestId = "";
    let largestTop = -99999999;
    if (target && !this.isScrolling) {
      this.targets.forEach((target, targetId) => {
        let top = this.getAbsoluteTop(target, scrollY);
        if (scrollY >= top && largestTop < top) {
          largestTop = top;
          largestId = targetId;
        }
        // log(LogLevel.UI_EVENT, "Scroll:onScroll each", scrollY, targetId, top, target.getBoundingClientRect().top);
      });
      // log(LogLevel.UI_EVENT, "Scroll:onScroll", largestTop, largestId, this.lastRichTargetId);

      if (largestId && largestId != this.lastRichTargetId) {
        this.lastRichTargetId = largestId;
        this.reachListeners.map((onReach) => {
          onReach({ targetId: largestId });
        });
      }
    }

    if(this.props.handleScroll){
      const currentScrollPos = event.currentTarget.scrollTop;
      const isScrollUp = currentScrollPos > this.lastScrollPos;
      this.lastScrollPos =  currentScrollPos
      
      this.props.handleScroll(isScrollUp);
    }
  };

  getScrollY = () => {
    return this.contentScroll ? this.contentScroll.scrollTop : 0;
  };

  getAllTarget = () => {
    const allTarget: Target[] = [];
    this.targets.forEach((target, id) => {
      allTarget.push(target);
    });
    return allTarget;
  };

  getTargetById = (id: string) => {
    return this.targets.get(id);
  };

  addTarget = (id: string, element: HTMLElement) => {
    log(LogLevel.UI_LIFECYCLE, "Scroll:addTarget", id);
    this.targets.set(id, element);
  };

  removeTargetById(id: string): void {
    this.targets.delete(id);
  }

  scrollToTarget(id: string, margin = 0) {
    const target = this.targets.get(id);
    if (!this.contentScroll || !target) {
      log(
        LogLevel.UI_LIFECYCLE,
        "Scroll:scrollTo no target",
        id,
        this.contentScroll,
        this.targets,
        target
      );
      return;
    }
    log(
      LogLevel.UI_LIFECYCLE,
      "Scroll:scrollTo",
      this.contentScroll.scrollTop,
      target.getBoundingClientRect().top,
      this.scrollMargin
    );

    this.scrollTo(
      this.getAbsoluteTop(target, this.contentScroll.scrollTop) - margin
    );
  }

  scrollToElement(el, margin = 0) {
    if (!this.contentScroll || !el) {
      return;
    }
    this.scrollTo(
      this.getAbsoluteTop(el, this.contentScroll.scrollTop) - margin
    );
  }

  subscribe = <K extends keyof ScrollHookEvent>(
    eventName: K,
    listener: (event: ScrollHookEvent[K]) => void
  ) => {
    switch (eventName) {
      case "scroll":
        const onScroll = listener as (event: ScrollHookEvent["scroll"]) => void;
        this.scrollListeners.push(onScroll);
        return;
      case "reach":
        const onReach = listener as (event: ScrollHookEvent["reach"]) => void;
        this.reachListeners.push(onReach);
        return;
    }
  };

  unsubscribe = <K extends keyof ScrollHookEvent>(
    eventName: K,
    listener: (event: ScrollHookEvent[K]) => void
  ) => {
    let index = -1;
    switch (eventName) {
      case "scroll":
        const onScroll = listener as (event: ScrollHookEvent["scroll"]) => void;
        index = this.scrollListeners.indexOf(onScroll);
        if (index >= 0)
          this.scrollListeners = this.scrollListeners.splice(index, 1);
        return;
      case "reach":
        const onReach = listener as (event: ScrollHookEvent["reach"]) => void;
        index = this.reachListeners.indexOf(onReach);
        if (index >= 0)
          this.reachListeners = this.reachListeners.splice(index, 1);
        return;
    }
  };

  render() {
    log(LogLevel.NONE, "Scroll:render");

    let fab;
    // if(this.props.needMoveToTop && ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP) && this.state.needMoveToTop){
    //   fab = (
    //     <FloatingButton size="I" color="White" iconLeftName="CaretUp" position={this.props.moveTopPosition?this.props.moveTopPosition:"TopCenter"}/>
    //   );
    // }

    return (
      <div className="common-content">
        <div
          className="common-content common-scroll"
          ref={(ref) => {
            this.contentScroll = ref;
          }}
          onScroll={this.onScroll}
        >
          <div
            ref={(ref) => {
              this.content = ref;
            }}
          >
            {this.props.children}
          </div>
        </div>
        {fab}
      </div>
    );
  }
}

export default Scroll;
