import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonModal,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Poc.css";
import BoardList from "../components/BoardList";
import composeIcon from "./../assets/icon/compose.svg";
import SwipeableViews from "react-swipeable-views";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";

type Props = RouteComponentProps;

const windowAny: any = window;

class Ceritificate extends Component<Props> {
  componentDidMount() {
    windowAny.IMP.init("imp68909306");
  }

  onClick = () => {
    console.log("onClick");
    windowAny.IMP.request_pay(
      {
        pg: "danal_tpay.9810030929",
        pay_method: "card",
        merchant_uid: "ymyd_premium_U1_O235",
        name: "프리미엄회원 월회비",
        customer_uid: "YP_U1_O235", // YMYD Premium
        amount: 1000,
        buyer_email: "oscar@ymyd.co.kr",
        buyer_name: "이성연",
        buyer_tel: "010-3150-7949",
        // buyer_addr : '서울특별시 강남구 삼성동',
        // buyer_postcode : '123-456'
        custom_data: {
          type: "ymyd_premium",
          userId: 1,
          orderId: 235,
        },
        notice_url: "", // webhook
      },
      function (rsp) {
        if (rsp.success) {
          var msg = "결제가 완료되었습니다.";
          msg += "고유ID : " + rsp.imp_uid;
          msg += "상점 거래ID : " + rsp.merchant_uid;
          msg += "결제 금액 : " + rsp.paid_amount;
          msg += "카드 승인번호 : " + rsp.apply_num;
          log(LogLevel.UI_ACTION, "onOrder:success", rsp);
          alert(msg);
        } else {
          var msg = "결제에 실패하였습니다.";
          msg += "에러내용 : " + rsp.error_msg;
          alert(msg);
          log(LogLevel.UI_ACTION, "onOrder:fail", rsp);
        }

        alert(msg);
      }
    );

    // windowAny.IMP.certification(
    //   {
    //     merchant_uid: "merchant_" + new Date().getTime(), //본인인증과 연관된 가맹점 내부 주문번호가 있다면 넘겨주세요,
    //     name: "이성연",
    //     phone: "01031507929",
    //   },
    //   function (rsp) {
    //     console.log(rsp);
    //     if (rsp.success) {
    //       // 인증성공
    //       console.log(rsp.imp_uid);
    //       console.log(rsp.merchant_uid);

    //       windowAny.$.ajax({
    //         type: "POST",
    //         url: "/certifications/confirm",
    //         dataType: "json",
    //         data: {
    //           imp_uid: rsp.imp_uid,
    //         },
    //       }).done(function (rsp) {
    //         // 이후 Business Logic 처리하시면 됩니다.
    //         console.log(rsp);
    //       });
    //     } else {
    //       // 인증취소 또는 인증실패
    //       var msg = "인증에 실패하였습니다.";
    //       msg += "에러내용 : " + rsp.error_msg;

    //       alert(msg);
    //       console.log("failed");
    //     }
    //   }
    // );
  };
  render() {
    log(LogLevel.UI_LIFECYCLE, "Home.render", this.props.match);

    return (
      <>
        abc
        <IonButton onClick={this.onClick}>결제시작</IonButton>
        <IonButton onClick={this.onClick}>취소</IonButton>
      </>
    );
  }
}

export default withRouter(Ceritificate);
