import React from "react";
import styled, { css } from "styled-components";
import { ColorSystem, COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";
import Icon from "../Icon";

type Variant = "Contained" | "Ghost" | "Tinted" | "Outlined";
type Size = "Small" | "Medium";

export interface TagProps {
  /** Tag 종류 */
  variant: Variant;
  /** Tag 사이즈 */
  size?: Size;
  /** Tag 색상 */
  color: ColorSystem | "Black";
  /** Tag 안의 내용 */
  children?: React.ReactNode;
  /** Tag 왼쪽 아이콘 존재 여부 */
  left?: boolean;
  /** Tag 오른쪽 아이콘 존재 여부 */
  right?: boolean;
  /** 아이콘 */
  icon?: string;
  style?: React.CSSProperties;
  className?: string;
}

interface TagWrapperProps {
  variant: Variant;
  color: ColorSystem;
  size: Size;
}

const tagColor = {
  Blue: {
    Ghost: {
      colors: css`
        color: ${COLOR_SYSTEM.get("Blue")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Blue")[400],
    },
    Tinted: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Blue")[10]};
        color: ${COLOR_SYSTEM.get("Blue")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Blue")[400],
    },
    Outlined: {
      colors: css`
        background-color: #ffffff;
        color: ${COLOR_SYSTEM.get("Blue")[400]};
        border: 1px solid ${COLOR_SYSTEM.get("Blue")[50]};
      `,
      iconColor: COLOR_SYSTEM.get("Blue")[400],
    },
    Contained: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Blue")[400]};
        color: #ffffff;
      `,
      iconColor: "#ffffff",
    },
  },
  Red: {
    Ghost: {
      colors: css`
        color: ${COLOR_SYSTEM.get("Red")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Red")[400],
    },
    Tinted: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Red")[10]};
        color: ${COLOR_SYSTEM.get("Red")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Red")[400],
    },
    Outlined: {
      colors: css`
        background-color: #ffffff;
        color: ${COLOR_SYSTEM.get("Red")[400]};
        border: 1px solid ${COLOR_SYSTEM.get("Red")[50]};
      `,
      iconColor: COLOR_SYSTEM.get("Red")[400],
    },
    Contained: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Red")[300]};
        color: #ffffff;
      `,
      iconColor: "#ffffff",
    },
  },
  Green: {
    Ghost: {
      colors: css`
        color: ${COLOR_SYSTEM.get("Green")[500]};
      `,
      iconColor: COLOR_SYSTEM.get("Green")[500],
    },
    Tinted: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Green")[10]};
        color: ${COLOR_SYSTEM.get("Green")[500]};
      `,
      iconColor: COLOR_SYSTEM.get("Green")[500],
    },
    Outlined: {
      colors: css`
        background-color: #ffffff;
        color: ${COLOR_SYSTEM.get("Green")[500]};
        border: 1px solid ${COLOR_SYSTEM.get("Green")[50]};
      `,
      iconColor: COLOR_SYSTEM.get("Green")[500],
    },
    Contained: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Green")[500]};
        color: #ffffff;
      `,
      iconColor: "#ffffff",
    },
  },
  Brown: {
    Ghost: {
      colors: css`
        color: ${COLOR_SYSTEM.get("Brown")[500]};
      `,
      iconColor: COLOR_SYSTEM.get("Brown")[500],
    },
    Tinted: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Brown")[10]};
        color: ${COLOR_SYSTEM.get("Brown")[500]};
      `,
      iconColor: COLOR_SYSTEM.get("Brown")[500],
    },
    Outlined: {
      colors: css`
        background-color: #ffffff;
        color: ${COLOR_SYSTEM.get("Brown")[500]};
        border: 1px solid ${COLOR_SYSTEM.get("Brown")[50]};
      `,
      iconColor: COLOR_SYSTEM.get("Brown")[500],
    },
    Contained: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Brown")[500]};
        color: #ffffff;
      `,
      iconColor: "#ffffff",
    },
  },
  Orange: {
    Ghost: {
      colors: css`
        color: ${COLOR_SYSTEM.get("Orange")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Orange")[400],
    },
    Tinted: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Orange")[10]};
        color: ${COLOR_SYSTEM.get("Orange")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Orange")[400],
    },
    Outlined: {
      colors: css`
        background-color: #ffffff;
        color: ${COLOR_SYSTEM.get("Orange")[400]};
        border: 1px solid ${COLOR_SYSTEM.get("Orange")[50]};
      `,
      iconColor: COLOR_SYSTEM.get("Orange")[400],
    },
    Contained: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Orange")[400]};
        color: #ffffff;
      `,
      iconColor: "#ffffff",
    },
  },
  Gray: {
    Ghost: {
      colors: css`
        color: ${COLOR_SYSTEM.get("Gray")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Gray")[400],
    },
    Tinted: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Gray")[20]};
        color: ${COLOR_SYSTEM.get("Gray")[400]};
      `,
      iconColor: COLOR_SYSTEM.get("Gray")[400],
    },
    Outlined: {
      colors: css`
        background-color: #ffffff;
        color: ${COLOR_SYSTEM.get("Gray")[400]};
        border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
      `,
      iconColor: COLOR_SYSTEM.get("Gray")[400],
    },
    Contained: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Gray")[400]};
        color: #ffffff;
      `,
      iconColor: "#ffffff",
    },
  },
  Black: {
    Ghost: {
      colors: css`
        color: ${COLOR_SYSTEM.get("Gray")[900]};
      `,
      iconColor: COLOR_SYSTEM.get("Gray")[900],
    },
    Tinted: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Gray")[15]};
        color: ${COLOR_SYSTEM.get("Gray")[900]};
      `,
      iconColor: COLOR_SYSTEM.get("Gray")[900],
    },
    Outlined: {
      colors: css`
        background-color: #ffffff;
        color: ${COLOR_SYSTEM.get("Gray")[900]};
        border: 1px solid ${COLOR_SYSTEM.get("Gray")[700]};
      `,
      iconColor: COLOR_SYSTEM.get("Gray")[900],
    },
    Contained: {
      colors: css`
        background-color: ${COLOR_SYSTEM.get("Gray")[900]};
        color: #ffffff;
      `,
      iconColor: "#ffffff",
    },
  },
};

const TagWrapper = styled.div<TagWrapperProps>`
  ${({ size }) =>
    typography.get(size == "Small" ? "CaptionBold" : "Body2SemiBold")};
  height: 20px;
  padding: 0 ${({ variant }) => (variant == "Ghost" ? "0px" : "6px")};
  ${({ variant, color }) => tagColor[color][variant]["colors"]};
  border-radius: 20px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  overflow: show;

  .left-tag__icon {
    margin-right: 4px;
  }
  .right-tag__icon {
    margin-left: 4px;
  }
`;

const Tag = ({
  variant,
  size = "Small",
  color,
  children,
  left = false,
  right = false,
  icon,
  style,
  className,
}: TagProps) => {
  return (
    <TagWrapper
      variant={variant}
      color={color}
      style={style}
      className={className}
      size={size}
    >
      {left && icon && (
        <Icon
          width={size == "Small" ? 12 : 14}
          height={size == "Small" ? 12 : 14}
          name={icon}
          fill={tagColor[color][variant]["iconColor"]}
          className="left-tag__icon"
        />
      )}
      {children}
      {right && icon && (
        <Icon
          width={size == "Small" ? 12 : 14}
          height={size == "Small" ? 12 : 14}
          name={icon}
          fill={tagColor[color][variant]["iconColor"]}
          className="right-tag__icon"
        />
      )}
    </TagWrapper>
  );
};

export default Tag;
