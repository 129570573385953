import { OrderInfo } from '../../models/Model.Sale';
import {EventInfo} from './../../models/Model.Event'

export interface SaleState {
  products: any, // id: ProductInfo;
  orders: any, // 
  orderList: any, //{orders:OrderInfo[], loading:boolean, finished: boolean, refresh: boolean}
}

export interface SaleOrderListState {
  orders?: OrderInfo[];
  end?: boolean;
  loading?: boolean;
  needReload?: boolean;
  checksum?: number;
}

export enum SaleActionTypes{
  SALE_PRODUCT_FETCH = '@@sale/SALE_PRODUCT_FETCH',
  SALE_PRODUCT_REQUEST = '@@sale/SALE_PRODUCT_REQUEST',
  SALE_PRODUCT_SUCCESS = '@@sale/SALE_PRODUCT_SUCCESS',
  SALE_PRODUCT_ERROR = '@@sale/SALE_PRODUCT_ERROR',
  SALE_ORDER_FETCH = '@@sale/SALE_ORDER_FETCH',
  SALE_ORDER_REQUEST = '@@sale/SALE_ORDER_REQUEST',
  SALE_ORDER_SUCCESS = '@@sale/SALE_ORDER_SUCCESS',
  SALE_ORDER_ERROR = '@@sale/SALE_ORDER_ERROR',
  SALE_ORDERS_REFRESH = '@@sale/SALE_ORDERS_REFRESH',
  SALE_ORDERS_FETCH = '@@sale/SALE_ORDERS_FETCH',
  SALE_ORDERS_REQUEST = '@@sale/SALE_ORDERS_REQUEST',
  SALE_ORDERS_SUCCESS = '@@sale/SALE_ORDERS_SUCCESS',
  SALE_ORDERS_ERROR = '@@sale/SALE_ORDERS_ERROR',
  SALE_ORDER_SET = '@@sale/SALE_ORDER_SET',
}
