import * as medicines from './actions';
import { MedicineState } from './types';
import { ActionType, getType } from 'typesafe-actions';
import { Middleware } from 'redux';
import { fetchAPI } from '../../utils/APIUtil';
import * as API from './../../API.json';
import {KeywordType} from './../../models/Model.Medicine'
import {log, LogLevel} from '../../utils/LogUtil'

import {getGlobal, GlobalKey, setGlobal} from './../../utils/GlobalUtil'

export const fetchMedicineMiddleware: Middleware<{}, MedicineState> = (store) => next => async (action: ActionType<typeof medicines>) => {
  next(action);

  if (action.type == getType(medicines.getMedicine)) {
    log(LogLevel.REDUX_MEDICINE, "medicines.getMedicine. Start Fetching");

    let needFetch:boolean = true;
    let states = store.getState();
    let state = states["medicine"];
    log(LogLevel.REDUX_MEDICINE, store, states, state)
    if(action.payload == KeywordType.MEDICINE) {
      if(state.medicines[action.meta] && state.medicines[action.meta].id == action.meta){
        log(LogLevel.REDUX_MEDICINE, "We already have ", state.medicines[action.meta])
        needFetch = false;
      }
    }
    else if(action.payload == KeywordType.INGRADIENT) {
      if(state.ingredients[action.meta] && state.ingredients[action.meta].id == action.meta){
        log(LogLevel.REDUX_MEDICINE, "We already have ", state.ingredients[action.meta])
        needFetch = false;
      }
    }
    else if(action.payload == KeywordType.ANIMAL_MEDICINE) {
      if(state.ingredients[action.meta] && state.ingredients[action.meta].id == action.meta){
        log(LogLevel.REDUX_MEDICINE, "We already have ", state.ingredients[action.meta])
        needFetch = false;
      }
    }

    if(needFetch){
      next(medicines.fetchMedicine.request());
    
      try {
        const result = await fetchAPI(API.MEDICINE_DETAIL, '', {type: action.payload, id:action.meta}, null)
        if(result && !result.error){
          log(LogLevel.REDUX_MEDICINE, "fetchSearchMiddleware QnA result",  result.data);
          next(medicines.fetchMedicine.success(result.data));
        }
        else{
          log(LogLevel.REDUX_MEDICINE, "fetchSearchMiddleware QnA failed", result);

          let error : Error = {
            name : "Error Code"  + result.error,
            message: "error code"
          };
          next(medicines.fetchMedicine.failure(error));
        }
      } catch (e) {
        log(LogLevel.REDUX_MEDICINE, "fetchSearchMiddleware QnA failed", e);
        next(medicines.fetchMedicine.failure(e));
      }

    }
  }
};
