import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AllInOneHomeTemplate from "../../components/templates/AllInOneHome";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import * as API from "./../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobListType } from "../../store/jobpost/types";
import ABTestUtil from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";
import { MY_ADDRESS } from "./../../config.json";
import Spinner from "../../components/atoms/Spinner";
import { UIServiceType } from "../../store/ui/types";
import { BoardContent } from "../../models/Model.Board";
import { BoardType } from "../../store/board/types";
import { AllInOnePartnerInfo, CompanyType } from "../../models/Model.User";
import allinoneDescription from "./../../assets/image/allinone-description.png";
import { set, shuffle } from "lodash";
import { AllInOneState } from "../../store/allinone/types";

const windowAny: any = window;

const categories = [
  { type: CompanyType.ALL, name: "홈" },
  { type: CompanyType.PHARMACEUTICAL_COMPANY, name: "제약사" },
  { type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY, name: "건강기능식품" },
  { type: CompanyType.QUASI_DRUG, name: "의약외품" },
  { type: CompanyType.FRENCHISE, name: "약국체인" },
  { type: CompanyType.EMR, name: "IT서비스" },
  { type: CompanyType.FINANCE, name: "대출" },
  { type: CompanyType.CONSULT, name: "컨설팅" },
  { type: CompanyType.LAW, name: "법률" },
];

// const bannersParteners = [
//   {
//     type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
//     imageUrl: "&&&/images/allinone-banner-hecto01.png",
//     url: "/allinone/detail?id=1bfde245-c8de-435f-988f-ac0da81a3f1c",
//     name: "헥토헬스케어",
//     item: "hecto01",
//   },
//   {
//     type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
//     imageUrl: "&&&/images/allinone-banner-mianutra01.png",
//     url: "/allinone/detail?id=039d5457-14e4-4dd2-950d-556749694805",
//     name: "엠아이에이뉴트라",
//     item: "mianutra01",
//   },
//   {
//     type: CompanyType.FRENCHISE,
//     imageUrl: "&&&/images/allinone-banner-hubase02.png",
//     url: "/allinone/detail?id=67db5448-332b-4395-a970-ae97dfb3280b",
//     name: "휴베이스",
//     item: "hubase02",
//   },
//   {
//     type: CompanyType.FRENCHISE,
//     imageUrl: "&&&/images/allinone-banner-onnuri02.png",
//     url: "https://bit.ly/yps_onnuri01",
//     name: "온누리H&C",
//     item: "onnuri02",
//   },
//   {
//     type: CompanyType.EMR,
//     imageUrl: "&&&/images/allinone-banner-teoul01.png",
//     url: "/allinone/detail?id=025e6103-10c5-4c5d-bd04-f0ebbc93c7d7",
//     name: "터울 주식회사",
//     item: "teoul01",
//   },
//   {
//     type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
//     imageUrl: "&&&/images/allinone-banner-knj02.jpg",
//     url: "/allinone/detail?id=c8764a41-6fb7-4830-93bf-f86079912121",
//     name: "김남주바이오",
//     item: "knj01",
//   },
//   {
//     type: CompanyType.FINANCE,
//     imageUrl: "&&&/images/allinone-banner-drandpharm01.png",
//     url: "/allinone/detail?id=6697c0c8-a957-4910-b63d-4ee8d30a6b02",
//     name: "닥터앤팜",
//     item: "drandpharm01",
//   },
//   {
//     type: CompanyType.EMR,
//     imageUrl: "&&&/images/allinone-banner-drx01.png",
//     url: "/allinone/detail?id=456038f0-cdeb-4965-ad35-92b50286f18d",
//     name: "내손안의약국",
//     item: "drx01",
//   },
//   {
//     type: CompanyType.EMR,
//     imageUrl: "&&&/images/allinone-banner-mango01.png",
//     url: "/boards/view?id=82229&partnerId=cb9ed578-f10b-423d-a24e-a8e170963431",
//     name: "망고스랩",
//     item: "mango01",
//   },
// ];

// const banners = [
//   {
//     type: CompanyType.ALL,
//     imageSrc: allinoneDescription,
//     url: "/allinone/introduction",
//     name: "소개",
//     item: "소개",
//   },
//   ...shuffle(bannersParteners),
// ];
// const defaultState: AllInOneState = {
//   partnerListLoading: false,
//   partnerList: null,
//   partners: {},
//   banners: null,
// }

const AllInOnePreviewHome: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const [partnerList, setPartnerList] = useState<any>({});
  const [posts, setPosts] = useState<BoardContent[]>();
  const [banner, setBanner] = useState<any>();
  const [partner, setPartner] = useState<any>();
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>();

  // let index = getGlobal(GlobalKey.YPARTNERS_CATEGORY_INDEX);

  const onSelectCategoryIndex = (index: number) => {
    setGlobal(GlobalKey.YPARTNERS_CATEGORY_INDEX, index);
    setSelectedCategoryIndex(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      const contentListResult = await fetchAPI(
        API.BOARD_PREVIEW_LIST_REQUEST,
        "id",
        { categories: 15 }
      );
      const partnerListResult = await fetchAPI(
        API.ALLINONE_PREVIEW_PARTNERS,
        ""
      );
      const bannerListResult = await fetchAPI(API.PREVIEW_BANNER_GET, "");

      const partners: AllInOnePartnerInfo = {};

      partnerListResult.data.map((item) => (partners[item.id] = item));

      const partnerListTemp = partnerListResult.data.map(
        (item, index) => item.id
      );

      setPartnerList(partnerListTemp);
      setPosts(contentListResult.data);
      setBanner(bannerListResult.data);
      setPartner(partners);
    };
    fetchData();
  }, []);

  const emptyFunction = () => {};

  try {
    return (
      <AllInOneHomeTemplate
        // partnerList={
        //   partnerList
        //     ? partnerList.filter((item, index) => {
        //         console.log(
        //           "hello filter world",
        //           partner[item].companyType,
        //           categories[selectedCategoryIndex].type
        //         )
        //         return true
        //         // categories[selectedCategoryIndex].type == CompanyType.ALL
        //         // ||
        //         // partner[item].companyType ==
        //         //   categories[selectedCategoryIndex].type
        //       })
        //     : []
        // }
        partnerList={partnerList}
        categories={categories}
        banners={banner}
        partners={partner}
        posts={posts}
        selectedCategoryIndex={selectedCategoryIndex}
        contents={undefined}
        onClickIntroduction={emptyFunction}
        onLoadMorePosts={emptyFunction}
        onClickPartner={emptyFunction}
        onClickPost={emptyFunction}
        onClickCategory={(index) => onSelectCategoryIndex(index)}
        // onClickCategory={emptyFunction}
        onRecommendPartner={emptyFunction}
        onClickBanner={emptyFunction} // selectedCategoryIndex={selectedCategoryIndex}
      />
    );
  } catch (error) {
    log(LogLevel.UI_EXCEPTION, "AllInOneHome:exception", error.message);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOneHome",
      errorMessage: error,
    });

    return null;
  }
};

export default withRouter(AllInOnePreviewHome);
