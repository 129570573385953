import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import Switch from "../../atoms/Switch/Switch";
import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import DistanceBottomSheetModal from "../../organisms/DistanceBottomSheetModal/DistanceBottomSheetModal";
import {
  getPayString,
  JobOfferInfo,
  JobOfferName,
  JobOfferNotificationOrderInfo,
} from "../../../models/Model.JobPost";
import { getDistanceString } from "../../../utils/LocationUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import Popup from "../../molecules/Popup";
import ReactHtmlParser from "react-html-parser";
import StringUtil from "../../../utils/StringUtil";
import { JOBOFFER_ALIM_PRICE, JOBOFFER_ALIM_MIN } from "../../../config.json";
import { CouponInfo } from "../../../models/Mode.Biz";
import { Flex, Static } from "../../atoms/Layout";
import CheckBox from "../../atoms/CheckBox/CheckBox";

export interface JobPostAlimtalkOrderProps {
  /** 알림톡 보내는 구인공고 */
  offer: JobOfferInfo;
  candidateCnt: number;
  doneCnt: number;
  distance: number;
  loading: boolean;
  onBack: () => void;
  onChangeDistance: (distance: number) => void;
  onOrder: (order: JobOfferNotificationOrderInfo) => void;
  payDone: boolean;
  onFinalize: () => void;
  coupon: CouponInfo;
}

interface JobPostAlimtalkOrderNumberInputProps {
  error?: boolean;
}

const JobPostAlimtalkOrderWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Main = styled.main`
  height: calc(100% - 108px);
  padding: 60px 0px 24px 0px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
`;

const Title = styled.div`
  padding: 20px 0px 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Option = styled.div`
  height: 47px;
  padding: 0px 24px;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  display: flex;
  align-items: center;
  .title {
    flex-grow: 1;
  }
`;

const NumberInput = styled.input<JobPostAlimtalkOrderNumberInputProps>`
  margin-top: 20px;
  ${typography.get("LargeTitle")}
  color: ${({ error }) =>
    `${error ? COLOR_SYSTEM.get("Red")[300] : COLOR_SYSTEM.get("Gray")[900]};`};
  outline: none;
  border: none;
  width: 100%;

  ::placeholder {
    color: ${COLOR_SYSTEM.get("Gray")[200]};
  }
`;

const Body = styled.div`
  margin-top: 24px;
  padding: 0px 24px;
  height: 100%;
  .error-text {
    margin-top: 6px;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  margin-bottom: 16px;
  align-items: center;
  .grow {
    flex-grow: 1;
  }
`;

const Footer = styled.footer`
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  left: 0;
`;

const FooterButton = styled.div`
  display: flex;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};

  & > * + * {
    margin-left: 20px;
  }

  button:last-child {
    flex: 1;
  }
`;
const PreviewBody = styled.div`
  overflow: scroll;
  max-height: 400px;
  padding-bottom: 88px;
`;
const DonePopupBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 88px;

  .title {
    margin-top: 25px;
  }

  .body {
    margin-top: 12px;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const ModalFooter = styled.footer`
  position: absolute;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  bottom: 0;
  width: 100%;
  padding: 20px;
  left: 0;

  button {
    width: 100%;
  }
`;

const JobPostAlimtalkOrder = ({
  offer,
  candidateCnt,
  doneCnt,
  distance,
  loading,
  payDone,
  coupon,
  onBack,
  onChangeDistance,
  onOrder,
  onFinalize,
}: JobPostAlimtalkOrderProps) => {
  const [distantPopupActive, setDistantPopupActive] = useState(false);
  const [includeDuplicate, setIncludeDuplicate] = useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [error, setError] = useState<number>(0);
  const [value, setValue] = useState<String>("");
  const [count, setCount] = useState<number>(0);
  const [useCoupon, setUseCoupon] = useState<boolean>(true);
  const inputRef = useRef(null);

  const content =
    "💌 구인 알림 📢\n집에서 #{offerDistance} 거리 약국의\n#{offerType} 공고가 등록되었습니다.\n\n📌 근무 일정\n#{offerTerms}\n\n📌 급여\n#{offerPay}\n\n#{offerDetail}\n※ 본 메시지는 수신 신청한 고객에게 발송되었습니다. 더 이상 수신을 원치 않으시면 구직 설정을 변경해주세요";

  const showDistancePopup = () => {
    setDistantPopupActive(true);
  };

  useEffect(() => {
    try {
      if (count) {
        if (count < JOBOFFER_ALIM_MIN) {
          setError(3);
        } else if (
          count > (includeDuplicate ? doneCnt + candidateCnt : candidateCnt)
        ) {
          setError(1);
        } else {
          setError(0);
        }
      } else {
      }
    } catch (err) {}

    return () => {
      // log(LogLevel.UI_EVENT, "ToggleSwitch:useEffect:checked:return", checked)
    };
  }, [candidateCnt, doneCnt, includeDuplicate]);

  const onNumberChange = (e) => {
    log(LogLevel.UI_DATA_LOAD, "JobPostAlimtalkOrder:onNumberChange", e);
    try {
      let values = e.target.value;
      setValue(values);
      if (values) {
        let count = parseInt(values);
        if (count < JOBOFFER_ALIM_MIN) {
          setError(3);
          setCount(count);
        } else if (
          count > (includeDuplicate ? doneCnt + candidateCnt : candidateCnt)
        ) {
          setError(1);
          setCount(count);
        } else {
          setError(0);
          setCount(count);
        }
      } else {
        setCount(0);
      }
    } catch {
      setError(2);
      setCount(0);
    }
  };

  const onDoneClick = () => {
    let order: JobOfferNotificationOrderInfo = {
      notiCount: count,
      payAmount:
        count * JOBOFFER_ALIM_PRICE -
          (coupon && useCoupon ? coupon.priceDiscount : 0) >
        0
          ? count * JOBOFFER_ALIM_PRICE -
            (coupon && useCoupon ? coupon.priceDiscount : 0)
          : 0,
      includeDuplicate: includeDuplicate,
      couponId: coupon && useCoupon ? coupon.id : 0,
      distance: distance,
    };

    onOrder(order);
  };

  // log(LogLevel.UI_LIFECYCLE, "JobPostAlimtalkOrder:template draw", doneCnt);

  return (
    <JobPostAlimtalkOrderWrapper>
      <header
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <TopAppBar
          title="알림톡 보내기"
          titleType="H4"
          leftButtons={[
            <Button
              color="Tertiary"
              icon="X"
              onClick={() => {
                console.log("onBack");
                onBack();
              }}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          rightButtons={[
            <Button
              color="Primary"
              onClick={() => {
                console.log("알림톡 미리보기");
                setShowPreview(true);
              }}
              size="Medium"
              type="Text"
              variant="Ghost"
            >
              알림톡 미리보기
            </Button>,
          ]}
        />
      </header>

      <Main>
        {doneCnt > 0 && (
          <Option
            onClick={() => {
              setIncludeDuplicate(!includeDuplicate);
            }}
          >
            <Text
              textType="InputMedium"
              color={COLOR_SYSTEM.get("Gray")[400]}
              className="title"
            >
              이전에 보냈던 사람 제외하기
            </Text>
            <Switch
              size="Small"
              isActive={!includeDuplicate}
              onClick={(e) => {}}
            />
          </Option>
        )}
        <Body
          onClick={() => {
            if (inputRef.current) inputRef.current.blur();
          }}
        >
          <Title>
            <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[400]}>
              약국 주변 &nbsp;
            </Text>
            <Text
              textType="H3"
              color={COLOR_SYSTEM.get("Gray")[700]}
              onClick={() => {
                showDistancePopup();
              }}
            >
              {getDistanceString(distance)} 이내
            </Text>
            <Icon
              name="PolygonDown"
              fill={COLOR_SYSTEM.get("Gray")[600]}
              width={24}
              height={24}
              onClick={() => {
                showDistancePopup();
              }}
            />
          </Title>

          <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[700]}>
            {includeDuplicate ? doneCnt + candidateCnt : candidateCnt}명
          </Text>
          <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[400]}>
            에게 보낼 수 있어요
          </Text>
          <br />
          <NumberInput
            ref={inputRef}
            error={error}
            type="number"
            value={value}
            onChange={onNumberChange}
            onClick={(e) => {
              e.stopPropagation();
            }}
            placeholder="몇 명에게 보낼까요?"
            onKeyDown={(e) => {
              if (e.keyCode == 13 && inputRef.current) inputRef.current.blur();
            }}
          />
          {error == 1 && (
            <Text
              textType="Body1"
              color={COLOR_SYSTEM.get("Red")[500]}
              onClick={() => {
                setValue(
                  (includeDuplicate
                    ? doneCnt + candidateCnt
                    : candidateCnt
                  ).toString()
                );
                setError(0);
                setCount(
                  includeDuplicate ? doneCnt + candidateCnt : candidateCnt
                );
              }}
              className="error-text"
            >
              최대 {includeDuplicate ? doneCnt + candidateCnt : candidateCnt}
              명에게만 보낼 수 있어요
            </Text>
          )}
          {error == 3 && (
            <Text
              textType="Body1"
              color={COLOR_SYSTEM.get("Red")[500]}
              onClick={() => {
                setValue(
                  (includeDuplicate
                    ? doneCnt + candidateCnt
                    : candidateCnt
                  ).toString()
                );
                setError(0);
                setCount(
                  includeDuplicate ? doneCnt + candidateCnt : candidateCnt
                );
              }}
              className="error-text"
            >
              최소 {JOBOFFER_ALIM_MIN}명 이상부터 보낼 수 있어요
            </Text>
          )}
        </Body>
      </Main>
      <Footer>
        {coupon && (
          <Flex
            justifyContent="space-between"
            customStyle={{ padding: "14px 0px" }}
            onClick={() => setUseCoupon(!useCoupon)}
          >
            <CheckBox checked={useCoupon} onClick={() => {}} size={"Small"}>
              쿠폰 적용
            </CheckBox>
            <Text
              textType={"Body1SemiBold"}
              color={COLOR_SYSTEM.get("Gray")[900]}
            >
              -{coupon.priceDiscount.toLocaleString()}원
            </Text>
          </Flex>
        )}
        <PriceInfo>
          <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[700]}>
            총 결제금액 &nbsp;
          </Text>
          <Text
            textType="Body1"
            color={COLOR_SYSTEM.get("Gray")[400]}
            className="grow"
          >
            *1건당 {JOBOFFER_ALIM_PRICE}원
          </Text>
          <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[900]}>
            {count * JOBOFFER_ALIM_PRICE -
              (coupon && useCoupon ? coupon.priceDiscount : 0) >
            0
              ? count * JOBOFFER_ALIM_PRICE -
                (coupon && useCoupon ? coupon.priceDiscount : 0)
              : 0}
            원
          </Text>
        </PriceInfo>
        <FooterButton>
          <Button
            color="Primary"
            onClick={onDoneClick}
            size="Large"
            type="Text"
            variant="Contained"
            disabled={!count || error != 0 || loading}
            disabledColor={!count || error != 0 || loading}
          >
            {count > 0 &&
            count * JOBOFFER_ALIM_PRICE -
              (coupon && useCoupon ? coupon.priceDiscount : 0) <=
              0
              ? "알림톡 보내기"
              : "결제하기"}
          </Button>
        </FooterButton>
      </Footer>
      {/* <Shadow distantPopupActive={distantPopupActive}></Shadow> */}
      <DistanceBottomSheetModal
        active={distantPopupActive}
        distance={distance}
        onClose={() => {
          setDistantPopupActive(false);
        }}
        onSelect={(distance) => {
          onChangeDistance(distance);
          setDistantPopupActive(false);
        }}
      />
      {showPreview && (
        <Popup
          closeOnBackDrop={true}
          onClose={() => {
            setShowPreview(false);
          }}
        >
          <PreviewBody>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
              {ReactHtmlParser(
                StringUtil.createStringFromTemplate(content, {
                  offerType: JobOfferName[offer.workType],
                  offerDistance: getDistanceString(4500),
                  offerPay: getPayString(offer),
                  offerTerms: offer.workTime,
                  offerDetail: offer.message
                    ? "📌 세부 내용\n" + offer.message + "\n\n"
                    : "",
                }).replace(/\n/gi, "<br/>")
              )}
            </Text>
          </PreviewBody>

          <ModalFooter>
            <Button
              color="Secondary"
              onClick={() => {
                setShowPreview(false);
              }}
              size="Large"
              type="Text"
              variant="Tinted"
            >
              닫기
            </Button>
          </ModalFooter>
        </Popup>
      )}
      {/* {/* {showPreview && */}
      {payDone && (
        <Popup
          onClose={() => {
            onFinalize();
          }}
        >
          <DonePopupBody>
            <Icon
              size={60}
              name={"Check Circle"}
              color={COLOR_SYSTEM.get("Skyblue")[300]}
            />
            <div className="title">
              <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
                결제가 완료됐어요
              </Text>
            </div>
            <div className="body">
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
                약국 주변 {getDistanceString(distance)}이내 구직 중인 약사{" "}
                <br />
                {count}명에게 알림톡을 발송했습니다.
              </Text>
            </div>
          </DonePopupBody>
          <ModalFooter>
            <Button
              color="Secondary"
              onClick={() => {
                onFinalize();
              }}
              size="Large"
              type="Text"
              variant="Tinted"
            >
              {/* <Button color="Secondary" onClick={()=>{setShowPreview(false);}} size="Large" type="Text" variant="Tinted"> */}
              닫기
            </Button>
          </ModalFooter>
        </Popup>
      )}
    </JobPostAlimtalkOrderWrapper>
  );
};

export default JobPostAlimtalkOrder;
