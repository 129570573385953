import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Icon from '../../atom/Icon'
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from './../../atom/Text'

type SeparatorType =
"Block" | 
"Line" 

const SeparatorStyle = {
  "Block":{
    height: "12px",
    color: "Gray6",
  }, 
  "Line" :{
    height: "1px",
    color: "Gray5",
  }, 
}

interface ContainerProps {
  type: SeparatorType,
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  height: ${(props) => SeparatorStyle[props.type].height};
  background: ${props => COLOR[SeparatorStyle[props.type].color]};
`

export interface Props {
  type: SeparatorType,
}

const Separator: FC<Props> = ({type}) => {
  return (
    <Container type={type}/>
  )
}

export default Separator
