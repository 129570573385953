import { ReactNode } from "react";
import { ButtonSize, ButtonType, ButtonUsage, ButtonVariant } from "../../components/atoms/Button";

export interface ButtonStyle {
  type?: ButtonType;
  value?: string;
  size?: ButtonSize;
  color?: ButtonUsage;
  variant?: ButtonVariant;
  onClick?: (...args: any) => any;
}

export interface PopupState {
  active: boolean;
  body: ReactNode;
  buttons: ButtonStyle[][];
  height?: string;
}

interface PopupAction {
  type: string;
  payload?: any;
}

const OPEN_POPUP = "popup/OPEN_POPUP";
const CLOSE_POPUP = "popup/CLOSE_POPUP";
const UPDATE_POPUP = "popup/UPDATE_POPUP";

export const openPopup = (body: ReactNode, buttons: ButtonStyle[][], height?: string) => ({
  type: OPEN_POPUP,
  payload: {
    body,
    buttons,
    height,
  },
});

export const closePopup = () => ({
  type: CLOSE_POPUP,
});

export const updatePopup = (body?: ReactNode, buttons?: ButtonStyle[][]) => ({
  type: UPDATE_POPUP,
  payload: {
    body,
    buttons,
  },
});

const initialState: PopupState = {
  active: false,
  body: "",
  buttons: [],
  height: "",
};

const reducer = (state = initialState, action: PopupAction) => {
  const { type } = action;

  switch (type) {
    case OPEN_POPUP: {
      const { payload } = action;
      const { body, buttons, height } = payload;

      return {
        ...state,
        active: true,
        body,
        buttons,
        height: height || "",
      };
    }
    case CLOSE_POPUP: {
      return {
        ...state,
        ...initialState,
      };
    }
    case UPDATE_POPUP: {
      const { payload } = action;
      const { body, buttons, height } = payload;
      const nextState = {
        ...state,
      };

      if (body) nextState.body = body;
      if (buttons) nextState.buttons = buttons;
      if (height) nextState.height = height;

      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
