import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import Icon from '../Icon'
import './../../../Common.css'
import IconButton from '../IconButton'


type ButtonVariant = 'Ghost' | 'Outlined' | 'Contained'
type ButtonSize = 'S' | 'M' | 'L'
type ButtonColor = 'Primary' | 'Secondary' | 'Gray'

interface WrapperProps {
  size: ButtonSize
  variant: ButtonVariant
  color: ButtonColor
  block: boolean
}

const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  outline: none;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
`

interface Props {
  size?: ButtonSize
  variant?: ButtonVariant
  color?: ButtonColor
  iconName?: string
  onFileSelect?: (e) => void;
  accept?: string
  multiple?: boolean
}
const IconFileButton: React.FC<Props> = ({
  size = 'S',
  variant = 'Contained',
  color = 'Primary',
  iconName,
  onFileSelect,
  accept,
  multiple = false,
}) => {

  const el = useRef(null);
  const rippleEl = useRef(null);
  return (
    <Wrapper>
      <IconButton size={size} variant={variant} color={color} iconName={iconName}/>
      <input multiple={multiple} type="file" accept={accept} onChange={onFileSelect}/>
    </Wrapper>
  )
}

export default IconFileButton
