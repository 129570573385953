import React from "react";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import styled from "styled-components";

const TitleDiv = styled.div`
  margin: 40px 0 28px;
`;

const NoSearchedMedicineContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px 80px;
  overflow-y: auto;
  background: #ffffff;
`;

const ManualListDiv = styled.div`
  width: 87%;
  min-width: 280px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  border-radius: 12px;
`;

const ManualItemDiv = styled.div`
  display: flex;
  gap: 12px;
`;

const ListStyleDiv = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_SYSTEM.get("Gray")[300]};
`;

const NoSearchedMedicine = () => {
  return (
    <NoSearchedMedicineContainer>
      <img
        src={`${process.env.PUBLIC_URL}/images/search_medicine.png`}
        alt="검색 결과가 없습니다."
        width={100}
        height={120}
      />
      <TitleDiv>
        <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[900]}>
          성분명 혹은 제품명으로 검색해보세요
        </Text>
      </TitleDiv>
      <ManualListDiv>
        <ManualItemDiv>
          <ListStyleDiv>
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[0]}>
              1
            </Text>
          </ListStyleDiv>
          <div>
            <div>
              <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Gray")[700]}>
                제품명으로 검색해보세요
              </Text>
            </div>
            <div>
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[900]}>
                성분 정보를 확인할 수 있어요.
              </Text>
            </div>
          </div>
        </ManualItemDiv>
        <ManualItemDiv>
          <ListStyleDiv>
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[0]}>
              2
            </Text>
          </ListStyleDiv>
          <div>
            <div>
              <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Gray")[700]}>
                동물의약품도 검색이 가능합니다
              </Text>
            </div>
            <div>
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[900]}>
                예시) 알팍산
              </Text>
            </div>
          </div>
        </ManualItemDiv>
        <ManualItemDiv>
          <ListStyleDiv>
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[0]}>
              3
            </Text>
          </ListStyleDiv>
          <div>
            <div>
              <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Gray")[700]}>
                초성으로 검색해보세요!
              </Text>
            </div>
            <div>
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[900]}>
                예시) ㅇㅁㅅㄱㄹ
              </Text>
            </div>
          </div>
        </ManualItemDiv>
      </ManualListDiv>
    </NoSearchedMedicineContainer>
  );
};

export default NoSearchedMedicine;
