import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'

import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from './../Text'

type ColorType =
"Blue" | 
"Green" | 
"Red" | 
"Orange" | 
"Brown" | 
"Gray" 

const BadgeColor = {
  "Blue": {
    background:COLOR["LightBlue"],
    color: "Blue",
  }, 
  "Green": {
    background:COLOR["LightGreen"],
    color: "Green",
  }, 
  "Red": {
    background:COLOR["LightRed"],
    color: "Red",
  }, 
  "Orange": {
    background:COLOR["LightOrange"],
    color: "Orange",
  }, 
  "Brown": {
    background:COLOR["LightBrown"],
    color: "Brown",
  }, 
  "Gray":{
    background: COLOR["Gray5"],
    color: "Gray2",
  }
}

interface ContainerProps {
  color: ColorType
  className?: string
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.a<ContainerProps>`
  padding: 2px 8px;
  background: ${(props) => BadgeColor[props.color].background};
  border-radius: 4px;
  display: inline-block;
`

export interface Props {
  color?: string
  className?: number
}
const ColorBadge: FC<Props> = ({ color="Blue", className, children,  }) => {
  return (
    <Container
      color={color}
      className={className}
    >
      <Text type="Caption1" color={BadgeColor[color].color}>
       {children}
      </Text>
    </Container>
  )
}

export default ColorBadge
