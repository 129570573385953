// V2
const grayScale = {
  Black: '#22282D',
  Gray1: '#3D4145',
  Gray2: '#616975',
  Gray3: '#949CA6',
  Gray4: '#B3BBC5',
  Gray5: '#C6CED7',
  Gray6: '#EAEBEC',
  Gray7: '#F1F2F3',
  Gray8: '#F7F8F9',
  White: '#FFFFFF',
}

const Transparent = "#0000";

const brandColor = {
  Primary: '#32C1FF',
  LightPrimary: '#EAF9FF',
  Secondary: '#1E2834',
  Point: '#FFEC86',
  Shadow50: '#0000007f',
}

const stateColor = {
  Blue: '#7D98FA',
  LightBlue: '#F2F5FF',
  Red: '#FF7D75',
  LightRed: '#FFF2F1',
  Green: '#67E0B2',
  LightGreen: '#F0FBF7',
  Brown: '#C2AB90',
  LightBrown: '#F7F2EE',
  Orange: '#FFB661',
  LightOrange: '#FBF6E7',
}

// V1
// const grayScale = {
//   Black: '#272D37',
//   Gray1: '#36414C',
//   Gray2: '#66737F',
//   Gray3: '#8F9CA9',
//   Gray4: '#B8C1CC',
//   Gray5: '#D5DDE5',
//   Gray6: '#E7ECF2',
//   Gray7: '#EEF2F6',
//   Gray8: '#F5F9FA',
//   White: '#FFFFFF',
// }

// const Transparent = "#0000";

// const brandColor = {
//   Primary: '#4264FE',
//   Secondary: '#14203A',
//   Point: '#F9E74C',
//   Shadow50: '#0000007f',
// }

// const stateColor = {
//   Blue: '#7A97FF',
//   LightBlue: '#EEF1FF',
//   Red: '#F96767',
//   LightRed: '#FEF3F3',
//   Green: '#3CDC9F',
//   LightGreen: '#EBFAF5',
//   Brown: '#C2AB90',
//   LightBrown: '#F7F2EE',
//   Orange: '#FFC36A',
//   LightOrange: '#FBF6E7',
// }

const COLOR = {
  ...grayScale,
  ...brandColor,
  ...stateColor,
  Transparent
}

export default COLOR
