import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import PartnersDetailTemplate from "../../components/templates/PartnersDetail";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import * as API from "./../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobListType } from "../../store/jobpost/types";
import ABTestUtil from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";
import { MY_ADDRESS } from "./../../config.json";
import Spinner from "../../components/atoms/Spinner";
import { UIPopupType, UIServiceType } from "../../store/ui/types";
import { BoardContent } from "../../models/Model.Board";
import { callPhone } from "../../utils/UrlSchemeUtil";
import useBottomSheet from "../../hooks/useBottomSheet";
import ShareList from "../../components/organisms/ShareList";
import useShare from "../../hooks/useShare";
import { Flex } from "../../components/atoms/Layout";
import { COLOR_SYSTEM } from "../../components/design/design-system";
import Text from "../../components/atoms/Text";
import Icon from "../../components/atoms/Icon";
import ConsultPopup from "../../components/templates/PartnersDetail/ConsultPopup";
import { BoardType } from "../../store/board/types";
import { AllInOnePartnerInfo } from "../../models/Model.User";

const windowAny: any = window;

const PartnersDetail: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  try {
    const dispatch = useDispatch();
    const id = useMemo(() => queryString.parse(location.search).id as string, [
      location.search,
    ]);
    const me = useSelector((state: RootState) => state.user.me);
    const partner: AllInOnePartnerInfo = useSelector(
      (state: RootState) => state.allinone.partners[id]
    );
    const backKeyControl = useSelector(
      (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
    );
    const bottomSheet = useBottomSheet();
    // log(LogLevel.UI_LIFECYCLE, "PartnersDetail:", partner);
    const { allInOnePartnerShare } = useShare({ allInOnePartner: partner }, me);

    const partnerList = useSelector(
      (state: RootState) => state.allinone.partnerList
    );
    const posts = useSelector((state: RootState) => state.board.bizBoards[id]);

    const contents = useSelector((state: RootState) => state.board.contents);
    const toastPopup = useSelector(
      (state: RootState) => state.ui.popups[UIPopupType.TOAST_POPUP]
    );

    useEffect(() => {
      if (backKeyControl)
        backKeyControl.setListener(() => {
          log(LogLevel.UI_LIFECYCLE, "PartnersDetail:back key callback");
          goBack();
        });
      log(
        LogLevel.UI_LIFECYCLE,
        "PartnersDetail:useEffect []",
        partnerList,
        partner,
        posts
      );

      if (!partnerList) dispatch(actions.allinone.getPartnerList());
    }, []);

    useEffect(() => {
      log(LogLevel.UI_LIFECYCLE, "PartnersDetail:useEffect [id]", partner);
      if (!posts) dispatch(actions.board.getBizBoard({ bizUseId: id }, 0));

      if (partner) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PARTNERS_SELECT_CONTENT",
          "약사파트너스_업체상세_진입",
          {
            회사id: partner.id,
            회사: partner ? partner.companyName : "",
          }
        );

        fetchAPI(
          API.ALLINONE_VISIT,
          partner.id,
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        ).catch((e) => {
          log(LogLevel.UI_EXCEPTION, "PartnersDetail:useEffect exception", e);
        });
      }
    }, [id, partner]);

    const onClickLink = (url: string) => {
      // if (props.noNavigate) return;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER",
        "약사파트너스_파트너_상세페이지_링크선택",
        {
          id: partner.id,
          회사: partner ? partner.companyName : "",
          url: url,
        }
      );
      if (url.startsWith("&&&")) {
        if (getGlobal(GlobalKey.OS) !== "browser") {
          history.push(url.substring(3));
        } else {
          window.open(
            url.replace("&&&", MY_ADDRESS),
            getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
          );
        }
      } else if (url.startsWith(MY_ADDRESS)) {
        if (getGlobal(GlobalKey.OS) !== "browser") {
          history.push(url);
        } else {
          window.open(
            url,
            getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
          );
        }
      } else if (url.startsWith("/")) {
        history.push(url);
      } else if (url.startsWith("tel") || url.startsWith("sms")) {
        window.open(url, "_system");
      } else
        window.open(
          url,
          getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
        );
    };

    const onClickPost = (post: BoardContent) => {
      try {
        log(
          LogLevel.UI_ACTION,
          "AllInOneHome:onClickPartner",
          post.id,
          post.usersBizId
        );
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PARTNERS_SELECT_CONTENT",
          "약사파트너스_파트너_상세페이지_콘텐츠_선택",
          {
            id: post.id,
            회사: partner ? partner.companyName : "",
            콘텐츠: post.subject,
          }
        );
        history.push(`/boards/view?id=${post.id}&partnerId=${partner.id}`);
      } catch (err) {
        console.error(err);
      }
    };

    const goBack = () => {
      bottomSheet.close();

      history.goBack();
    };

    const onShare = () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER_SHARE",
        "약사파트너스_파트너_상세페이지_공유_클릭",
        {
          회사: partner ? partner.companyName : "",
        }
      );
      bottomSheet.show({
        title: {
          value: "공유하기",
          align: "center",
        },
        body: (
          <ShareList
            onKakao={allInOnePartnerShare.handleClickKakao}
            onSMS={allInOnePartnerShare.handleClickSMS}
            onLink={allInOnePartnerShare.handleClickLink}
          />
        ),
      });
    };

    const onCall = () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER_CALL_START",
        "약사파트너스_파트너_상세페이지_전화하기_클릭",
        {
          회사: partner ? partner.companyName : "",
        }
      );
      if (partner.companyPhone) callPhone(partner.companyPhone);
    };

    const onConsult = () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER_CONSULT_START",
        "약사파트너스_파트너_상세페이지_상담신청_클릭",
        {
          회사: partner ? partner.companyName : "",
        }
      );

      history.push("/partners/consult?id=" + partner.id);
      // bottomSheet.show({
      //   body: <ConsultPopup me={me} onConsult={onConsultDone} />,
      // });
    };

    const onConsultDone = async (message: string) => {
      bottomSheet.close();
      await fetchAPI(
        API.ALLINONE_CONSULT,
        "",
        null,
        { partnerId: partner.id, questionText: message },
        getGlobal(GlobalKey.TOKEN)
      );
      toastPopup.show("상담예약이 완료되었습니다.");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER_CONSULT",
        "약사파트너스_파트너_상세페이지_상담신청_완료",
        {
          회사: partner ? partner.companyName : "",
        }
      );
    };
    log(LogLevel.UI_LIFECYCLE, "PartnersDetail:render", posts);

    return (
      <PartnersDetailTemplate
        me={me}
        onGoBack={goBack}
        onShare={onShare}
        onClickPost={onClickPost}
        onCall={onCall}
        onConsult={onConsult}
        onClickLink={onClickLink}
        partner={partner}
        posts={posts ? posts.contents : []}
        contents={contents}
      />
    );
  } catch (error) {
    console.error(error.message);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PartnersDetail",
      errorMessage: error,
    });
  }
};

export default withRouter(PartnersDetail);
