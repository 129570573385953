import React, { useEffect, useRef, useState } from "react";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import COLOR from "./../../_design/color";
import "./style.scss";
import StringUtil from "../../../utils/StringUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgressBar from "../../atom/ProgressBar";
import Badge from "../../atom/Badge";
import Icon from "../../atom/Icon";
import ReactVisibilitySensor from "react-visibility-sensor";

type Props = {
  items?: any[];
  imageUrlKey?: string;
  imageSrcKey?: string;
  backgroundKey?: string;
  onClick?: (item: any, index: number, position: any) => void;
  onProgress?: (index: number) => void;
  onShow?: (item: any, index: number) => void;
  className?: string;
  cardClassName?: string;
  style?: "normal" | "centered";
  indexPosition?: "none" | "left" | "right" | "center" | "centered-left";
  indexType?: "none" | "dot" | "number";
  autoScrollInterval?: number;
  infinite?: boolean;
  randomStart?: boolean;
  showProgressBar?: boolean;
  showNavigationButton?: boolean;
  disableSwipe?: boolean;
};

type ChildProps = {
  onClick: (e, index: number, coords: { x: number; y: number }) => void;
  // add other props if needed
};

const CardView: React.FC<Props> = ({
  items,
  style = "normal",
  imageUrlKey,
  imageSrcKey,
  backgroundKey,
  onClick,
  onProgress,
  onShow,
  className,
  cardClassName,
  indexPosition = "center",
  indexType = "none",
  autoScrollInterval = 0,
  infinite = false,
  randomStart = false,
  showProgressBar = false,
  showNavigationButton = false,
  children,
  disableSwipe = false,
}) => {
  const startIndex = useRef<number>(-1);

  if (startIndex.current < 0) {
    if (randomStart) {
      let childrenCount = 0;
      if (items) childrenCount = items.length;
      else if (children) childrenCount = React.Children.count(children);

      startIndex.current = Math.floor(Math.random() * childrenCount);
    } else startIndex.current = 0;
  }

  const [cardviewIndex, setCardviewIndex] = useState<number>(
    startIndex.current
  );
  const containerEl = useRef(null);
  const contentEl = useRef(null);
  const sliderEL = useRef(null);
  const initialized = useRef<boolean>(false);

  const requestIds = useRef<number[]>([]);
  const lastBG = useRef<number[]>(null);
  const frameCount = useRef<number>(0);
  const isAnimating = useRef(false);

  useEffect(() => {
    log(
      LogLevel.UI_EVENT,
      "CardView:userEffect []",
      containerEl.current,
      cardviewIndex
    );
    if (backgroundKey && !initialized.current) {
      initialized.current = true;

      if (items[cardviewIndex][backgroundKey]) {
        let maxWidthS = window
          .getComputedStyle(contentEl.current, null)
          .getPropertyValue("max-width");
        let maxWidth = parseInt(maxWidthS.replace("px", ""));
        let isMax: boolean = contentEl.current.clientWidth >= maxWidth;

        if (isMax) {
          const color = parseInt(
            items[cardviewIndex][backgroundKey].replace("#", "0x")
          );
          const r = color >>> 16; // 0x12
          const g = (color >>> 8) & 0xff; // 0x34
          const b = color & 0xff; // 0x56
          lastBG.current = [r, g, b];
          containerEl.current.style.background =
            "rgb(" + lastBG.current.join(",") + ")";
        }
      }
    }
  });

  useEffect(() => {
    log(
      LogLevel.UI_EVENT,
      "CardView:userEffect [cardviewIndex]",
      containerEl.current,
      cardviewIndex
    );
    // if(autoScrollInterval){
    //   if(autoScrollTimer.current) clearTimeout(autoScrollTimer.current)
    //   autoScrollTimer.current = setTimeout(() => {
    //       let newIndex = (cardviewIndex + 1) % items.length;
    //       onCardIndexChanged(newIndex);
    //     }, autoScrollInterval
    //   );
    // }
    if (onShow) onShow(items[cardviewIndex], cardviewIndex);
  }, [cardviewIndex]);

  const afterChange = (index) => {};
  const beforeChange = (oldIndex, newIndex) => {
    if (backgroundKey) {
      if (
        items[newIndex][backgroundKey] &&
        items[newIndex][backgroundKey] != items[oldIndex][backgroundKey]
      ) {
        if (!lastBG.current) {
          lastBG.current = window
            .getComputedStyle(containerEl.current, null)
            .getPropertyValue("background")
            .replace("rgb(", "")
            .replace(")", "")
            .split(",")
            .map((item) => parseInt(item));
        }

        const color = parseInt(
          items[newIndex][backgroundKey].replace("#", "0x")
        );
        const r = color >>> 16; // 0x12
        const g = (color >>> 8) & 0xff; // 0x34
        const b = color & 0xff; // 0x56
        const bg = [r, g, b];

        // start background animation
        const duration = 200; // 1s == 1000ms
        const startTime = performance.now();
        const fromBG = lastBG.current;
        const toBG = bg;
        log(
          LogLevel.UI_EVENT,
          "CardView:onCardIndexChanged",
          oldIndex,
          newIndex,
          items[newIndex][backgroundKey],
          lastBG.current,
          toBG
        );

        lastBG.current = toBG;

        const frame = (now: number) => {
          frameCount.current++;

          const scrollingTime = now - startTime;

          // 순간 이동할 y는 남은 이동Y(toLeft - scrollingLeft)를 남은시간(단위:s) * fps로 나눈다.
          const ratio = scrollingTime / duration;
          const cbg = [
            fromBG[0] + (toBG[0] - fromBG[0]) * ratio,
            fromBG[1] + (toBG[1] - fromBG[1]) * ratio,
            fromBG[2] + (toBG[2] - fromBG[2]) * ratio,
          ];

          // log(LogLevel.UI_EVENT, "ToggleSwitch:switchTo animate:", cbg, fromBG, toBG, ratio)
          //TODO: 마지막 프레임에 최종 목적지보다 1프레임 더 움직인 뒤 뒤로 가는 버그 수정하기
          if (scrollingTime >= duration) {
            containerEl.current.style.background =
              "rgb(" + lastBG.current.join(",") + ")";
            isAnimating.current = false;
          } else {
            containerEl.current.style.background = "rgb(" + cbg.join(",") + ")";
            requestIds.current.push(requestAnimationFrame(frame));
          }
        };
        requestIds.current.push(requestAnimationFrame(frame));
      }
    }
    setCardviewIndex(newIndex);
    if (onProgress) onProgress(newIndex);
  };

  let sliderProps: any = {};
  if (infinite) sliderProps.infinite = true;
  else sliderProps.infinite = false;

  if (style == "centered") {
    sliderProps.centerMode = true;
    sliderProps.centerPadding = "5%";
  }

  if (autoScrollInterval) {
    sliderProps.autoplay = true;
    sliderProps.autoplaySpeed = autoScrollInterval;
  }

  sliderProps.initialSlide = startIndex.current;

  let itemsCount = items && items.length ? items.length : 0;

  const handleEvent = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div
      ref={containerEl}
      className={(className ? className + " " : "") + "cardview-container"}
    >
      <div
        ref={contentEl}
        className="cardview-content-container"
        // onTouchStart={handleEvent}
        // onTouchMove={handleEvent}
        // onTouchEnd={handleEvent}
        // onTouchStartCapture={handleEvent}
        // onTouchMoveCapture={handleEvent}
        // onTouchEndCapture={handleEvent}
        onClick={handleEvent}
        // onMouseDown={handleEvent}
        // onMouseMove={handleEvent}
        // onMouseUp={handleEvent}
        // onMouseDownCapture={handleEvent}
        // onMouseMoveCapture={handleEvent}
        // onMouseUpCapture={handleEvent}
      >
        {showProgressBar && itemsCount > 0 && (
          <ProgressBar
            progress={((cardviewIndex + 1) * 100) / itemsCount}
            rounded={false}
          />
        )}
        <Slider
          ref={sliderEL}
          className="cardview-content-slider"
          beforeChange={beforeChange}
          afterChange={afterChange}
          dots={false}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          speed={500}
          swipe={false}
          {...sliderProps}
          // onTouchStart={handleEvent}
          // onTouchMove={handleEvent}
          // onTouchEnd={handleEvent}
          // onTouchStartCapture={handleEvent}
          // onTouchMoveCapture={handleEvent}
          // onTouchEndCapture={handleEvent}
          // onClick={handleEvent}
          // onMouseDown={handleEvent}
          // onMouseMove={handleEvent}
          // onMouseUp={handleEvent}
          // onMouseDownCapture={handleEvent}
          // onMouseMoveCapture={handleEvent}
          // onMouseUpCapture={handleEvent}
        >
          {items &&
            items.length > 0 &&
            items.map((item, index) => {
              let src = "";
              if (imageUrlKey)
                src = StringUtil.convertFilePath(item[imageUrlKey]);
              if (!src && imageSrcKey && item[imageSrcKey])
                src = item[imageSrcKey];
              if (!src) src = StringUtil.convertFilePath(item);
              let element = (
                <div
                  className={
                    (cardClassName ? cardClassName + " " : "") +
                    "cardview-content-image-container"
                  }
                  key={index.toString()}
                  onClick={(e) => {
                    var rect = e.currentTarget.getBoundingClientRect();
                    var x = (e.clientX - rect.left) / rect.width; //x position within the element.
                    var y = (e.clientY - rect.top) / rect.height; //y position within the element.
                    // log(LogLevel.UI_EVENT, "CardView:onClick", x, y);
                    if (onClick) onClick(item, index, { x, y });
                  }}
                >
                  <img className="cardview-content-image" src={src} />
                </div>
              );
              // if (onShow) {
              //   return (
              //     <ReactVisibilitySensor
              //       onChange={onVisibilityChange(item, index)}
              //     >
              //       {element}
              //     </ReactVisibilitySensor>
              //   );
              // } else {
              return element;
              // }
            })}
          {!!children &&
            React.Children.map(children, (child, myIndex) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  onClick: (e) => {
                    var rect = e.currentTarget.getBoundingClientRect();
                    var x = (e.clientX - rect.left) / rect.width; //x position within the element.
                    var y = (e.clientY - rect.top) / rect.height; //y position within the element.
                    if (onClick) onClick(null, itemsCount + myIndex, { x, y });
                  },
                } as React.DOMAttributes<HTMLElement>);
              }
              return child;
            })}
        </Slider>
        {indexType == "dot" && indexPosition != "none" && itemsCount && (
          <div className={"cardview-page-index-container-" + indexPosition}>
            <div className="common-flex-row common-flex-align-center">
              {items.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={
                      cardviewIndex == index
                        ? "cardview-page-index-selected"
                        : "cardview-page-index"
                    }
                  />
                );
              })}
            </div>
          </div>
        )}
        {indexType == "number" && indexPosition != "none" && (
          <div className={"cardview-page-index-container-" + indexPosition}>
            <Badge size="S" variant="Contained" color="Shadow">
              {cardviewIndex + 1}/{itemsCount}
            </Badge>
          </div>
        )}
        {showNavigationButton && cardviewIndex > 0 && (
          <div
            className={"cardview-page-button-previous"}
            onClick={(e) => {
              e.stopPropagation();
              sliderEL.current.slickPrev();
            }}
          >
            <Icon name="CaretLeft" width="24" height="24" fill={COLOR.White} />
          </div>
        )}
        {showNavigationButton && cardviewIndex < itemsCount - 1 && (
          <div
            className={"cardview-page-button-next"}
            onClick={(e) => {
              e.stopPropagation();
              sliderEL.current.slickNext();
            }}
          >
            <Icon name="CaretRight" width="24" height="24" fill={COLOR.White} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardView;
