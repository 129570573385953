import React, { Component } from "react";
import { IonCheckbox } from "@ionic/react";
import { log, LogLevel } from "../../utils/LogUtil";
import "./PollSimplePage.css";
import "./Common.css";
import ReactHtmlParser from "react-html-parser";
import { PollResponseItemInfo, PollItemInfo } from "../../models/Model.Poll";
import Textarea from "react-textarea-autosize";

// type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
type Props = {
  key?: string;
  item: PollItemInfo;
  response?: PollResponseItemInfo;
  index: number;
  onReponse?: (pollItemindex: number, response: PollResponseItemInfo) => void;
};

type State = {
  indices: number[];
  text: string;
};

class PollSimplePageItemMultiSelection extends Component<Props, State> {
  input = null;

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemMultiSelection:constructor", props);
    let indices = [],
      text = "";
    if (this.props.response && this.props.response.values) {
      let texts = [...this.props.response.values];
      for (let i = texts.length - 1; i >= 0; i--) {
        let index = this.props.item.candidates.indexOf(this.props.response.values[i]);
        if (index >= 0) {
          indices.push(index);
          texts.splice(i, 1);
        }
      }

      if (texts.length && this.props.item.needInput) {
        indices.push(-1);
        text = texts.join(", ");
      }
    } else if (this.props.item.defaultsSelection && this.props.item.defaultsSelection.length) {
      indices = this.props.item.defaultsSelection;
    }
    this.state = {
      indices,
      text,
    };
  }

  shouldComponentUpdate(nextProv, nextState) {
    if (
      this.props.item.id != nextProv.item.id ||
      this.state.text != nextState.text ||
      this.state.indices != nextState.indices
    )
      return true;

    return false;
  }

  componentDidMount() {}

  onTextChange = (e) => {
    this.setState({ text: e.target.value });
  };

  componentDidUpdate(prevProp, prevState) {
    if (this.state.text != prevProp.text || this.state.indices != prevState.indices) {
      if (!this.responseTimer) {
        this.responseTimer = setTimeout(this.doResponse, 300);
      }
    }
  }

  responseTimer = null;
  doResponse = () => {
    let values = [];
    for (let i = 0; i < this.state.indices.length; i++) {
      if (this.state.indices[i] == -1) values.push(this.state.text);
      else if (this.props.item.candidates.length > this.state.indices[i])
        values.push(this.props.item.candidates[this.state.indices[i]]);
    }
    if (this.props.onReponse)
      this.props.onReponse(this.props.index, {
        ...this.props.response,
        pollItemId: this.props.item.id,
        value: values.join(", "),
        values,
      });
    this.responseTimer = null;
  };

  onKeyPress = (e) => {
    // log(LogLevel.UI_ACTION, "PollSimplePageItemMultiSelection:onKeyPress", e.keyCode, e.shiftKey)
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  };

  onSelect = (e, index) => {
    let indices = [...this.state.indices];
    let idx = indices.indexOf(index);
    if (idx == -1) {
      if (!this.props.item.maxSelect || this.props.item.maxSelect > indices.length) indices.push(index);
    } else indices.splice(idx, 1);

    this.setState({ indices });

    if (index == -1) {
      if (this.input) this.input.focus;
      e.stopPropagation();
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemMultiSelection render: ", this.props.item, this.props.response);

    if (!this.props.item.candidates || !this.props.item.candidates.length) return null;

    let descriptionElement;
    if (this.props.item.description)
      descriptionElement = (
        <div className="poll-simple-page-item-text poll-simple-page-item-margin">
          {ReactHtmlParser(this.props.item.description, {})}
        </div>
      );

    let inputElement, title, prefix, postfix;
    if (this.props.item.needInput) {
      if (this.props.item.inputTitle)
        title = (
          <div
            className={"poll-simple-page-item-text" + (this.state.indices.includes ? " common-bold" : "")}
            style={{ marginRight: "15px" }}
          >
            {this.props.item.inputTitle}
          </div>
        );
      if (this.props.item.inputPrefix)
        prefix = (
          <div className={"poll-simple-page-item-text"} style={{ marginRight: "5px" }}>
            {this.props.item.inputPrefix}
          </div>
        );
      if (this.props.item.inputPostfix)
        postfix = (
          <div className={"poll-simple-page-item-text"} style={{ marginLeft: "5px" }}>
            {this.props.item.inputPostfix}
          </div>
        );

      inputElement = (
        <div
          className="common-container-row common-flex-align-center poll-simple-page-item-margin"
          onClick={(e) => this.onSelect(e, -1)}
        >
          <IonCheckbox color="poll-simple-page-checkbox" checked={this.state.indices.includes(-1)} />
          {title}
          {prefix}
          <Textarea
            inputRef={(ref) => {
              this.input = ref;
            }}
            minRows={1}
            maxRows={1}
            className="poll-simple-page-input-inline"
            onChange={this.onTextChange}
            value={this.state.text}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => this.onKeyPress(e)}
          />
          {postfix}
        </div>
      );
    }

    return (
      <div className="poll-simple-page-item-container">
        <div className="poll-simple-page-item-question">{this.props.item.question}</div>
        {descriptionElement}
        {this.props.item.candidates.map((item, index) => {
          return (
            <div
              key={index.toString()}
              className="common-container-row common-flex-align-center poll-simple-page-item-margin"
              onClick={(e) => this.onSelect(e, index)}
            >
              <IonCheckbox color="poll-simple-page-checkbox" checked={this.state.indices.includes(index)} />
              <div className={"poll-simple-page-item-text" + (this.state.indices.includes ? " common-bold" : "")}>
                {item}
              </div>
            </div>
          );
        })}
        {inputElement}
      </div>
    );

    return null;
  }
}

// const mapStateToProps = (state: RootState) => ({
//   me: state.user.me,
//   informationPopup : state.ui.popups[UIPopupType.INFORMATION_POPUP],
// });

// const mapDispatchToProps = {

// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollSimplePageItemMultiSelection));
export default PollSimplePageItemMultiSelection;
