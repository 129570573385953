import React, { Component } from 'react';
import './ViewerComments.css';
import {BoardContent, BoardComment, Reference} from './../models/Model.Board'
import {log, LogLevel} from '../utils/LogUtil'
import ProfileImage from './ProfileImage';
import { IonInput, IonButton, IonIcon } from '@ionic/react';
import FileInputButton from './FileInputButton';
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';
import { fetchAPI } from '../utils/APIUtil';
import * as API from './../API.json';
import { loadImageBase64 } from '../utils/ImageUtil';
import Textarea from 'react-textarea-autosize';
// import * as loadImage from 'blueimp-load-image';


const windowAny : any = window;
const md5 = require('js-md5');

type Props = {
  profile?: string;
  id?: number;
  groupId?: number;
  comment?: BoardComment;
  onDone?: (BoardComment) => any;
  onCancel?: () => void;
};

type States = {
  text : string;
  name : string;
  password : string;
}

class ViewerCommentOpenComposer extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ViewerCommentOpenComposer.constructor", this.props);
    let text = '', name="", password=""
    if(this.props.comment){
      text = this.props.comment.bodyText;
      name = this.props.comment.usersCustomName;
    }
    this.state = {
      text, name, password,
    }
  }

  onTextchange = (e) => {
    log(LogLevel.UI_EVENT, "ViewerCommentOpenComposer:onTextchange", e.target.value);
    let text = e.target.value;
    this.setState({text: text});
  }

  onTextDone = () => {
    if(!this.isEnabled())
      return;

    let comment:BoardComment = {
      ...this.props.comment,
      isAnonymous: false, // 익명 게시 여부
      bodyText: this.state.text, // 본문 텍스트
      usersCustomName: this.state.name,
      usersPassword: md5(this.state.password)
    }
    this.props.onDone(comment);
    this.setState({text:'', name:'', password:''})
  }

  onKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "ViewerCommentOpenComposer:onKeyPress", e.keyCode)
    if(e.keyCode == 13)
      this.onTextDone();
  }

  isEnabled = () => {
    if(this.state.text && this.props.onDone && this.state.name && this.state.password)
      return true;
    return false;
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ViewerCommentOpenComposer.render");    
    let cancelButton;
    if(this.props.onCancel){
      cancelButton = (
        <IonButton color="viewer-comment-composer-done" onClick={this.props.onCancel}>
          <IonIcon class="viewer-comment-composer-done-icon" name="close"/>
        </IonButton> 
      );
    }
    return (
      <>
        <div className="viewer-comment-container">
          <ProfileImage className="viewer-comment-profile" profileUrl={this.props.profile}/>
          <div className="viewr-comment-composer-conteainer">
            <div className="viewer-comment-composer-open-writer-info">
              <div className="viewer-comment-composer-open-writer-info-sub">
                <div className="viewer-comment-nickname">닉네임</div>
                <IonInput class="viewer-comment-input-open-writer-info" placeholder="닉네임" value={this.state.name} onIonChange={(e) => {this.setState({name: e.detail.value})}}/>
              </div>
              <div className="viewer-comment-composer-open-writer-info-sub">
                <div className="viewer-comment-nickname">임시비밀번호</div>
                <IonInput class="viewer-comment-input-open-writer-info" placeholder="임시비밀번호" type="password" value={this.state.password} onIonChange={(e) => {this.setState({password: e.detail.value})}}/>
              </div>

            </div>
            <div className="viewer-comment-composer">
              <Textarea  placeholder="댓글을 입력해주세요" minRows={1} className="viewer-comment-composer-body" onChange={this.onTextchange} value={this.state.text}/>
              <IonButton color={this.isEnabled()?"viewer-comment-composer-done":"viewer-comment-composer-done-disabled"} onClick={this.onTextDone}>
                <IonIcon class="viewer-comment-composer-done-icon" name="arrow-up"/>
              </IonButton> 
              {cancelButton}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewerCommentOpenComposer;