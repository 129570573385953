import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import {
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonInput,
  IonTextarea,
  IonToggle,
} from "@ionic/react";
import "./SettingNotification.css";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import logoIcon from "./../assets/icon/logo.png";
import kakaoIcon from "./../assets/icon/kakao_logo.png";
import profile from "./../assets/image/profile_m_1.png";
import BoardList from "./../components/BoardList";
import DynamicChecker from "../components/DynamicChecker";
import { log, LogLevel } from "../utils/LogUtil";

import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import CheckToggleButton from "../components/CheckToggleButton";
import { NotiOption } from "../models/Model.User";
import { UIServiceType } from "../store/ui/types";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {};

type State = {
  notiOption: string;
  a: boolean;
};

class SettingNotification extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let notiOption = getGlobal(GlobalKey.NOTIFICATION_OPTION, true);
    this.state = {
      notiOption,
      a: true,
    };
  }

  async componentDidMount() {
    if (getGlobal(GlobalKey.OS) != "browser") {
      let notificationToken = getGlobal(GlobalKey.NOTIFICATION_KEY, true);
      log(
        LogLevel.UI_LIFECYCLE,
        "SettingNotification:componentDidMount",
        notificationToken,
        this.state.notiOption
      );
      if (notificationToken) {
        let result = await fetchAPI(
          API.USER_NOTIFICATION_GET,
          "",
          {
            platform: getGlobal(GlobalKey.OS),
            token: getGlobal(GlobalKey.NOTIFICATION_KEY, true),
            option: this.state.notiOption,
          },
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        if (
          result &&
          !result.error &&
          result.data &&
          result.data.notiOption != this.state.notiOption
        ) {
          this.setState({ notiOption: result.data.notiOption });
        }
      }
    }
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.onBack);
    // document.addEventListener("backbutton", this.save);
  }

  save = () => {
    setGlobal(GlobalKey.NOTIFICATION_OPTION, this.state.notiOption, true);
    if (getGlobal(GlobalKey.OS) != "browser") {
      let notificationToken = getGlobal(GlobalKey.NOTIFICATION_KEY, true);
      log(
        LogLevel.UI_ACTION,
        "SettingNotification:onBack",
        notificationToken,
        this.state.notiOption
      );
      if (notificationToken) {
        fetchAPI(
          API.USER_NOTIFICATION_SET,
          "",
          null,
          {
            platform: getGlobal(GlobalKey.OS),
            token: getGlobal(GlobalKey.NOTIFICATION_KEY, true),
            option: this.state.notiOption,
          },
          getGlobal(GlobalKey.TOKEN)
        );
      }
    }
  };

  onBack = () => {
    this.save();
    this.props.history.goBack();
  };

  componentWillUnmount() {
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    log(
      LogLevel.UI_EVENT,
      "AppStack:componentWillUnmount backbutton unregister"
    );

    // document.removeEventListener("backbutton", this.save);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.onBack);
  }

  onNotiChange = (checked: boolean, value: string) => {
    let previous = this.state.notiOption;
    let re = new RegExp("[" + value + "]", "gi");
    let notiOption = previous.replace(re, "");
    if (checked) {
      notiOption += value;
    }

    if (value == NotiOption.ALL && !checked) notiOption = NotiOption.NONE;

    log(
      LogLevel.UI_ACTION,
      "SettingNotification:onToggleChange",
      checked,
      value,
      previous,
      notiOption
    );
    this.setState({ notiOption });
  };

  checkChecked = (value: string) => {
    if (!value) return false;
    let re = new RegExp("[" + value + "]", "gi");
    return re.test(this.state.notiOption);
  };

  render() {
    log(LogLevel.NONE, "SettingNotification:render");
    return (
      <>
        <IonHeader class="setting-notification-header" no-border>
          <IonToolbar color="setting-notification-toolbar">
            <IonButtons slot="start">
              <IonButton
                class="setting-notification-toolbar-button"
                onClick={this.onBack}
              >
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel onClick={this.onBack}>알림설정</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="setting-notification-content">
          {/* <div className="setting-notification-body-container">
            <div className="setting-notification-title">
              카카오톡 알림
            </div>
            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">내 작성글 새 댓글 알림</div>
              <IonToggle class="setting-notification-toggle" mode="ios" onChange={() => {}}/>
            </div>
            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">스크랩 글 새 댓글 알림</div>
              <IonToggle class="setting-notification-toggle" mode="ios" onChange={() => {}}/>
            </div>
            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">좋아요 글 새 댓글 알림</div>
              <IonToggle class="setting-notification-toggle" mode="ios" onChange={() => {}}/>
            </div>
          </div> */}
          <div className="setting-notification-body-container">
            <div className="common-container-row">
              <div className="setting-notification-title">앱 알림</div>
              <IonToggle
                class="setting-notification-toggle"
                mode="ios"
                checked={this.checkChecked(NotiOption.ALL)}
                onClick={(e) =>
                  this.onNotiChange(
                    !this.checkChecked(NotiOption.ALL),
                    NotiOption.ALL
                  )
                }
              />
            </div>
            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">학술</div>
              {/* <IonToggle
                class="setting-notification-toggle"
                mode="ios"
                checked={this.checkChecked(NotiOption.ALL_QNA)}
                onClick={(e) => this.onNotiChange(!this.checkChecked(NotiOption.ALL_QNA), NotiOption.ALL_QNA)}
              /> */}
            </div>
            <div className="setting-notification-subitem-container">
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_QUESTION)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.ALL_QUESTION)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.ALL_QUESTION),
                      NotiOption.ALL_QUESTION
                    )
                  }
                >
                  질문
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_ANSWER)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.ALL_ANSWER)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.ALL_ANSWER),
                      NotiOption.ALL_ANSWER
                    )
                  }
                >
                  답변
                </div>
              </div>
              {/* <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_POSTING)}
                  onClick={(checked) => this.onNotiChange(checked, NotiOption.ALL_POSTING)}
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() => this.onNotiChange(!this.checkChecked(NotiOption.ALL_POSTING), NotiOption.ALL_POSTING)}
                >
                  게시물
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_CONSULT_QUESTION)}
                  onClick={(checked) => this.onNotiChange(checked, NotiOption.ALL_CONSULT_QUESTION)}
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.ALL_CONSULT_QUESTION),
                      NotiOption.ALL_CONSULT_QUESTION
                    )
                  }
                >
                  상담질문
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_CONSULT_ANSWER)}
                  onClick={(checked) => this.onNotiChange(checked, NotiOption.ALL_CONSULT_ANSWER)}
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(!this.checkChecked(NotiOption.ALL_CONSULT_ANSWER), NotiOption.ALL_CONSULT_ANSWER)
                  }
                >
                  상담답변
                </div>
              </div> */}
            </div>

            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">약사라이프</div>
              {/* <IonToggle
                class="setting-notification-toggle"
                mode="ios"
                checked={this.checkChecked(NotiOption.ALL_QNA)}
                onClick={(e) => this.onNotiChange(!this.checkChecked(NotiOption.ALL_QNA), NotiOption.ALL_QNA)}
              /> */}
            </div>
            <div className="setting-notification-subitem-container">
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_CONSULT_QUESTION)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.ALL_CONSULT_QUESTION)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.ALL_CONSULT_QUESTION),
                      NotiOption.ALL_CONSULT_QUESTION
                    )
                  }
                >
                  질문
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_CONSULT_ANSWER)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.ALL_CONSULT_ANSWER)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.ALL_CONSULT_ANSWER),
                      NotiOption.ALL_CONSULT_ANSWER
                    )
                  }
                >
                  답변
                </div>
              </div>
            </div>
            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">뉴스&아티클</div>
              {/* <IonToggle
                class="setting-notification-toggle"
                mode="ios"
                checked={this.checkChecked(NotiOption.ALL_QNA)}
                onClick={(e) => this.onNotiChange(!this.checkChecked(NotiOption.ALL_QNA), NotiOption.ALL_QNA)}
              /> */}
            </div>
            <div className="setting-notification-subitem-container">
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.ALL_POSTING)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.ALL_POSTING)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.ALL_POSTING),
                      NotiOption.ALL_POSTING
                    )
                  }
                >
                  게시물
                </div>
              </div>
            </div>
            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">내 작성글</div>
            </div>
            <div className="setting-notification-subitem-container">
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.MY_POST_REPLY)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.MY_POST_REPLY)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.MY_POST_REPLY),
                      NotiOption.MY_POST_REPLY
                    )
                  }
                >
                  답변
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.MY_POST_COMMENT)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.MY_POST_COMMENT)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.MY_POST_COMMENT),
                      NotiOption.MY_POST_COMMENT
                    )
                  }
                >
                  댓글
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.MY_POST_LIKE)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.MY_POST_LIKE)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.MY_POST_LIKE),
                      NotiOption.MY_POST_LIKE
                    )
                  }
                >
                  좋아요
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.MY_POST_SHARE)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.MY_POST_SHARE)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.MY_POST_SHARE),
                      NotiOption.MY_POST_SHARE
                    )
                  }
                >
                  공유
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.MY_POST_SCRAP)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.MY_POST_SCRAP)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.MY_POST_SCRAP),
                      NotiOption.MY_POST_SCRAP
                    )
                  }
                >
                  스크랩
                </div>
              </div>
            </div>
            {/* <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">관심주제 새글 (준비중)</div>
              <IonToggle
                class="setting-notification-toggle"
                mode="ios"
                checked={this.checkChecked(NotiOption.MY_INTEREST_POST)}
                onClick={(e) =>
                  this.onNotiChange(!this.checkChecked(NotiOption.MY_INTEREST_POST), NotiOption.MY_INTEREST_POST)
                }
              />
            </div> */}
            {/* <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">좋아요 한 글</div>
              <IonToggle class="setting-notification-toggle" mode="ios" checked={this.checkChecked(NotiOption.MY_LIKE)} onClick={(e) => this.onNotiChange(!this.checkChecked(NotiOption.MY_LIKE),NotiOption.MY_LIKE)}/>
            </div>
            <div className="setting-notification-subitem-container">
              <div className="setting-notification-subitem">
                <CheckToggleButton checked={this.checkChecked(NotiOption.MY_LIKE_REPLY)} onClick={(checked) => this.onNotiChange(checked, NotiOption.MY_LIKE_REPLY)}/> 
                <div className="setting-notification-subitem-text" onClick={() => this.onNotiChange(!this.checkChecked(NotiOption.MY_LIKE_REPLY), NotiOption.MY_LIKE_REPLY)}>답변</div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton checked={this.checkChecked(NotiOption.MY_LIKE_COMMENT)} onClick={(checked) => this.onNotiChange(checked, NotiOption.MY_LIKE_COMMENT)}/> 
                <div className="setting-notification-subitem-text" onClick={() => this.onNotiChange(!this.checkChecked(NotiOption.MY_LIKE_COMMENT), NotiOption.MY_LIKE_COMMENT)}>댓글</div>
              </div>
            </div> */}
            <div className="setting-notification-item-container">
              <div className="setting-notification-item-name">스크랩 한 글</div>
              {/* <IonToggle
                class="setting-notification-toggle"
                mode="ios"
                checked={this.checkChecked(NotiOption.MY_SCRAP)}
                onClick={(e) => this.onNotiChange(!this.checkChecked(NotiOption.MY_SCRAP), NotiOption.MY_SCRAP)}
              /> */}
            </div>
            <div className="setting-notification-subitem-container">
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.MY_SCRAP_REPLY)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.MY_SCRAP_REPLY)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.MY_SCRAP_REPLY),
                      NotiOption.MY_SCRAP_REPLY
                    )
                  }
                >
                  답변
                </div>
              </div>
              <div className="setting-notification-subitem">
                <CheckToggleButton
                  checked={this.checkChecked(NotiOption.MY_SCRAP_COMMENT)}
                  onClick={(checked) =>
                    this.onNotiChange(checked, NotiOption.MY_SCRAP_COMMENT)
                  }
                />
                <div
                  className="setting-notification-subitem-text"
                  onClick={() =>
                    this.onNotiChange(
                      !this.checkChecked(NotiOption.MY_SCRAP_COMMENT),
                      NotiOption.MY_SCRAP_COMMENT
                    )
                  }
                >
                  댓글
                </div>
              </div>
            </div>
          </div>
          <div className="setting-notification-description-container">
            <div className="setting-notification-description-title">TIP</div>
            <div className="setting-notification-description">
              알림은 내가 관심있는 활동을 놓치지 않도록 알려줍니다. <br />{" "}
              카카오톡 혹은 앱 알림 중 원하는 방식으로 설정해보세요.
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingNotification)
);
